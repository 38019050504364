import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RecordImportActions } from '../../+state/actions/record-import.actions';
import { RecordImportFacade } from '../../+state/facades/record-import.facade';

/**
 * The record import success component.
 * @export
 * @class RecordImportSuccessComponent
 */
@Component({
  selector: 'record-import-success',
  templateUrl: './record-import-success.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordImportSuccessComponent {
  /**
   * Creates an instance of RecordImportSuccessComponent.
   * @param {RecordImportFacade} recordImportFacade The record import facade.
   * @memberof RecordImportSuccessComponent
   */
  constructor(private recordImportFacade: RecordImportFacade) {}

  /**
   * When click on close button.
   * @memberof RecordImportSuccessComponent
   */
  close(): void {
    this.recordImportFacade.dispatch(RecordImportActions.cancelRecordImportModal());
  }
}
