<div class="user-avatar-chip align-items-center" [ngClass]="{ 'user-avatar-chip__border': hasBorder }">
  <sc-user-avatar size="sm" [initials]="userAvatarChip.initials" [hexColour]="userAvatarChip.colour"></sc-user-avatar>
  <p i18n>{{ userAvatarChip.display }}</p>
  <sc-icon
    *ngIf="isRemovable"
    class="user-avatar-chip__icon"
    key="close"
    size="small"
    height="8px"
    width="8px"
    (click)="remove.emit(userAvatarChip)"></sc-icon>
</div>
