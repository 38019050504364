import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CommonActions,
  CommonRoutesEnum,
  EntityInterface,
  fromPageState,
  makeUUID,
  StandaloneRouteNamesEnum,
  UNTITLED,
} from '@surecloud/common';
import { EntitySelectors } from '@surecloud/entity-state';
import { RecordActions } from '@surecloud/record';
import { filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { StandaloneRecordListActions } from '../actions/standalone-record-list.actions';

/**
 * The Effects/side effects for the Record Entity Feature.
 * @export
 * @class EntityStateEffects
 */
@Injectable({ providedIn: 'root' })
export class StandaloneRecordListEffects {
  /**
   * When a user enters the entity record list page.
   * Then dispatch a recordListEnter action.
   * @memberof StandaloneRecordListEffects
   */
  enterRecordList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.standaloneRecordListEnter),
      map(() => RecordActions.recordListEnter())
    )
  );

  /**
   * When leaving a standalone record list view.
   * Then reset the record data store.
   * @memberof StandaloneRecordListEffects
   */
  resetRecordsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.standaloneRecordListLeave),
      map(() => RecordActions.recordListLeave())
    )
  );

  /**
   * When leaving a standalone record list view.
   * Then reset the record data store.
   * @memberof StandaloneRecordListEffects
   */
  resetEntityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.standaloneRecordListLeave),
      map(() => CommonActions.unselectEntity())
    )
  );

  /**
   * When a user update a record in the entity records page.
   * Then dispatch a recordUpdate action.
   * @memberof StandaloneRecordListEffects
   */
  recordUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.recordListUpdateRecord),
      map((recordComponentValue) => CommonActions.recordUpdate(recordComponentValue))
    )
  );

  /**
   * When a user adds a record in the entity records page.
   * Then dispatch a recordUpdate action.
   * @memberof StandaloneRecordListEffects
   */
  recordCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.recordListCreateRecord),
      map(({ requestId }) => CommonActions.createNewRecordWithEntityIdForRecords({ requestId }))
    )
  );

  /**
   * When a user adds a record in the entity records page.
   * Then dispatch a startDeleteRecords action.
   * @memberof StandaloneRecordListEffects
   */
  recordDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.recordListStartDeleteRecords),
      map((recordIds) => CommonActions.startDeleteRecords(recordIds))
    )
  );

  /**
   * When a user clicks export all.
   * Then dispatch a exportRecords action.
   * @memberof StandaloneRecordListEffects
   */
  exportRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.recordListExportRecords),
      map(({ entityId, attributeIds, exportName, recordIds }) =>
        CommonActions.exportRecords({ entityId, attributeIds, exportName, recordIds })
      )
    )
  );

  /**
   * When a user enter the record list.
   * Then dispatch a breadcrumb action.
   * @memberof StandaloneRecordListEffects
   */
  setRecordListBreadcrumb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandaloneRecordListActions.standaloneRecordListEnter),
      switchMap(() =>
        this.store.select(EntitySelectors.getSelected).pipe(
          filter((entity): entity is EntityInterface => !!entity),
          takeUntil(
            this.actions$.pipe(
              ofType(StandaloneRecordListActions.standaloneRecordListEnter),
              ofType(StandaloneRecordListActions.standaloneRecordListLeave)
            )
          )
        )
      ),
      withLatestFrom(this.store.select(fromPageState.getBreadcrumbs)),
      map(([{ entityId, name }, breadcrumbs]) => {
        const lastTabId = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].tabId : undefined;
        const suffix = lastTabId ? ` ${lastTabId}` : '';
        return fromPageState.BreadcrumbActions.setBreadcrumbs({
          breadcrumbs: [
            {
              label: $localize`My Applications${suffix}`,
              route: ['/', CommonRoutesEnum.ApplicationList],
              id: makeUUID(),
              tabId: lastTabId,
            },
            {
              label: `${name ?? UNTITLED} Records`,
              route: ['/', StandaloneRouteNamesEnum.RecordList, entityId],
              id: entityId,
            },
          ],
        });
      })
    )
  );

  /**
   * Creates an instance of StandaloneRecordListEffects.
   * @param {Actions} actions$ The entity actions.
   * @param {Store} store The ngrx store.
   * @memberof StandaloneRecordListEffects
   */
  constructor(private readonly actions$: Actions, private readonly store: Store) {}
}
