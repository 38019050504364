import { EntityAttributeTypeEnum } from '../interfaces/entity-attribute-type/entity-attribute-type.enum';

/**
 *  Public method to get the attribute types and localized text from the EntityAttributeTypeEnum.
 *
 * @param {string} value the enum key
 * @return {string} localized text of the EntityAttributeTypeEnum.
 */
export function getTranslatedAttributeText(value: string): string {
  const attribute = Object.entries(EntityAttributeTypeEnum).find((dictionary) => dictionary[1] === value)?.[0];
  return attribute ? $localize`${attribute}` : '';
}
