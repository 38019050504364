import { z } from 'zod';

/**
 * Fill options available within the Kendo UI library.
 */
export const ThemeFillEnum = z.enum(['solid', 'flat', 'outline', 'link', 'clear', 'none']);

/**
 * Themes options available within the Kendo UI library.
 */
export const ThemeTypeEnum = z.enum([
  'base',
  'primary',
  'secondary',
  'tertiary',
  'info',
  'success',
  'warning',
  'error',
  'dark',
  'light',
  'inverse',
  'none',
]);
