import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { E2eHookDirective, NotRequiredBoolean, NotRequiredNumber, NotRequiredString } from '@surecloud/common';
import { Subject, takeUntil } from 'rxjs';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { InputTextComponent } from '../input-text/input-text.component';
import { SliderComponent } from '../slider/slider.component';
import { TitleDescriptionComponent } from '../title-description/title-description.component';

@Component({
  selector: 'sc-edit-score',
  templateUrl: './edit-score.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitleDescriptionComponent,
    SliderComponent,
    CheckboxComponent,
    InputTextComponent,
    ReactiveFormsModule,
    E2eHookDirective,
  ],
})
export class EditScoreComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * The slider number
   * @type {(NotRequiredNumber)}
   * @memberof EditScoreComponent
   */
  @Input() slider: NotRequiredNumber;

  /**
   * Is the checkbox selected
   * @type {(NotRequiredBoolean)}
   * @memberof EditScoreComponent
   */
  @Input() checkboxChecked: NotRequiredBoolean = false;

  /**
   * The edit score component input text
   * @type {(NotRequiredString)}
   * @memberof EditScoreComponent
   */
  @Input() justification: NotRequiredString = '';

  /**
   * Automation testhook to add to the edit score.
   * @type {(string)}
   * @memberof EditScoreComponent
   */
  @Input() testhook = 'edit-score';

  /**
   * Emit an event when the popup hierarch dropdown menu is destroyed so the toplevel data can be reset.
   * @memberof EditScoreComponent
   */
  @Output() formValues = new EventEmitter<
    FormGroup<{
      slider: FormControl<number>;
      checkboxChecked: FormControl<boolean>;
      justification: FormControl<string>;
    }>
  >();

  /**
   * The edit score form group.
   * @type {FormGroup}
   * @memberof EditScoreComponent
   */
  form: FormGroup<{
    slider: FormControl<number>;
    checkboxChecked: FormControl<boolean>;
    justification: FormControl<string>;
  }> = this.formBuilder.group({
    slider: new FormControl<number>(
      { value: 3, disabled: false },
      {
        nonNullable: true,
      }
    ),
    checkboxChecked: new FormControl<boolean>(
      { value: false, disabled: false },
      {
        nonNullable: true,
      }
    ),
    justification: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
      }
    ),
  });

  /**
   * When the component is destroyed.
   * Then emit so that other observables may tear down.
   * @type {Subject<void>}
   * @memberof EditScoreComponent
   */
  destroyed$: Subject<void> = new Subject();

  /**
   * Creates an instance of EditScoreComponent .
   * @param {FormBuilder} formBuilder  The Angular form builder.
   * @memberof EditScoreComponent
   */
  constructor(private readonly formBuilder: FormBuilder) {}

  /**
   * When the component is initialised.
   * Then setup form values
   * @memberof EditScoreComponent
   */
  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.setSliderFormValue(this.form.controls.checkboxChecked.value);
      this.formValues.emit(this.form);
    });
  }

  /**
   * When the inputs change.
   * Then setup form values
   * @memberof EditScoreComponent
   */
  ngOnChanges(): void {
    this.setFormValues();
  }

  /**
   * When the component is destroyed.
   * Then emit so other observables can tear down.
   * @memberof EditScoreComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Set's the form values
   * @private
   * @memberof EditScoreComponent
   */
  private setFormValues(): void {
    if (this.slider) {
      this.form.controls.slider.patchValue(this.slider, { emitEvent: false });
    }
    if (this.justification) {
      this.form.controls.justification.patchValue(this.justification, { emitEvent: false });
    }
    if (this.checkboxChecked) {
      this.form.controls.checkboxChecked.patchValue(this.checkboxChecked, { emitEvent: false });
      this.form.controls.slider.disable({ emitEvent: false });
    }

    // Emits the initials values
    this.formValues.emit(this.form);
  }


  /**
   * Enables/Disables the slider based on the checkbox value
   * @private
   * @param {boolean} checkbox edit score checkbox value
   * @memberof EditScoreComponent
   */
  private setSliderFormValue(checkbox: boolean): void {
    if (checkbox) {
      this.form.controls.slider.disable({ emitEvent: false });
    } else {
      this.form.controls.slider.enable({ emitEvent: false });
    }
  }
}
