import { DocumentUploadStatusEnum, DocumentVirusScanStatusEnum } from '@surecloud/common';
import { z } from 'zod';

export const documentVirusScanStatusZodEnum = z.nativeEnum(DocumentVirusScanStatusEnum);

export const documentUploadStatusZodEnum = z.nativeEnum(DocumentUploadStatusEnum);

export const basicRecordAttributeValueZodSchema = z.object({
  value: z.string().nullable(),
  display: z.string().nullable(),
});

export const userRecordAttributeValueZodSchema = z.object({
  value: z.string(),
  display: z.string(),
  initials: z.string(),
  colour: z.string(),
  type: z.string(),
});

export const documentRecordAttributeValueZodSchema = z.object({
  value: z.string(),
  fileName: z.string(),
  virusScanStatus: documentVirusScanStatusZodEnum.nullable(),
  uploadStatus: documentUploadStatusZodEnum.nullable(),
});

export const singleRecordDataAttributeZodSchema = z.object({
  singleAttribute: z.union([basicRecordAttributeValueZodSchema, userRecordAttributeValueZodSchema]),
});

export const multipleRecordDataAttributeZodSchema = z.object({
  multiAttributes: z.array(
    z.union([
      basicRecordAttributeValueZodSchema,
      userRecordAttributeValueZodSchema,
      documentRecordAttributeValueZodSchema,
    ])
  ),
});

export const normalisedRecordZodSchema = z.object({
  recordId: z.string(),
  attributes: z.array(z.union([singleRecordDataAttributeZodSchema, multipleRecordDataAttributeZodSchema])),
  viewerCanDelete: z.boolean(),
});

export type NormalisedRecord = z.infer<typeof normalisedRecordZodSchema>;

export const columnAttributeZodSchema = z.object({
  name: z.string(),
});

export const recordApiResponseZodSchema = z
  .object({
    columnAttributes: z.array(columnAttributeZodSchema),
    records: z.array(normalisedRecordZodSchema),
    viewerCanCreate: z.boolean(),
    canBeMultiple: z.boolean().nullable().optional(),
    totalItems: z.number(),
  })
  .refine(
    (val) => val.records.find((r) => Object.keys(r.attributes).length !== val.columnAttributes.length) === undefined,
    {
      message: 'Number of columnAttributeIds should match the number of records',
    }
  );

export type RecordApiResponse = z.infer<typeof recordApiResponseZodSchema>;

export const isRecordApiResponseGuard = (map: unknown): map is RecordApiResponse =>
  recordApiResponseZodSchema.safeParse(map).success;

export const getRecordApiResponseError = (map: unknown): z.SafeParseReturnType<unknown, RecordApiResponse> =>
  recordApiResponseZodSchema.safeParse(map);
