import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloErrorService, Auth0AndGuestUserAuthService } from '@surecloud/common';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createApolloClient } from './factory/create-apollo';

export const APP_API_URI = new InjectionToken<string>('App Api Uri');
export const APP_SUBSCRIPTIONS_URI = new InjectionToken<string>('App Subscriptions Uri');

/**
 * The module encapsulating GraphQL Apollo configuration for the workspace
 * every application using GraphQL should import this module in the root.
 * @export
 * @class DataModule
 */
@NgModule({
  imports: [CommonModule, ApolloModule, HttpClientModule],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        {
          provide: APOLLO_OPTIONS,
          useFactory: createApolloClient,
          deps: [ApolloErrorService, HttpLink, Auth0AndGuestUserAuthService, APP_API_URI, APP_SUBSCRIPTIONS_URI],
        },
      ],
    };
  }
}
