<ng-container [formGroup]="parentFormGroup">
  <label class="sc-font-field-label" for="colour">{{ label }}</label>
  <section class="sc-colour-picker">
    <div #colour *ngFor="let colour of colourOptions; trackBy: trackByFunction">
      <input
        type="radio"
        class="sc-colour-picker__custom_radio"
        [ngClass]="{
          selected: parentFormGroup.controls[controlName].value === colour
        }"
        [formControlName]="controlName"
        [value]="colour"
        [style]="{ background: colour }" />
    </div>
  </section>
  <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
</ng-container>
