import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NAVIGATE_ROW_COLUMN,
  RecordInterface,
  SCGridSchemaInterface,
  SCGridSchemaTypeEnum,
  SELECT_ROW_COLUMN,
  parseTypeCol,
} from '@surecloud/common';
import { ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { NormalisedRecord } from '../../services/normalise-get-record/normalise-get-record.validations';
import { isRecordDataMultipleCellInterface } from '../models/record-input-type-guards';
import { RECORDS_FEATURE_KEY, StateInterface, recordAdapter } from '../reducers/record.reducer';

// Lookup the 'Record' feature state managed by NgRx
export const getRecordState = createFeatureSelector<StateInterface>(RECORDS_FEATURE_KEY);

const { selectAll, selectEntities } = recordAdapter.getSelectors();

/**
 * Get all records.
 */
export const getRecords = createSelector(getRecordState, (state: StateInterface) => selectAll(state));

/**
 * Get all column attribute ids
 */
export const getColumnAttribute = createSelector(getRecordState, (state: StateInterface) => state.columnAttributes);

/**
 * Get the Record entity map from component state.
 */
export const getRecordEntities = createSelector(getRecordState, (state: StateInterface) => selectEntities(state));

/**
 * Get records grid data.
 */
export const getRecordGridInput = createSelector(
  getRecords,
  getRecordState,
  (records: NormalisedRecord[], state: StateInterface) => ({
    columnAttributes: state.columnAttributes,
    records: records as RecordInterface[],
    viewerCanCreate: state.viewerCanCreate,
    totalItems: state.totalItems,
  })
);

/**
 * Get the columnDefs for the records
 */
export const getColumnDefs = createSelector(getRecordState, (state: StateInterface) => {
  const colDefs: SCGridSchemaInterface[] = state.columnAttributes.map(({ name, type }, index) => ({
    headerName: name || undefined,
    colId: `${index}`,
    editable: false,
    type: parseTypeCol(type),
    valueGetter: (params: ValueGetterParams): string => {
      const { colDef, data } = params;
      if (!colDef.colId) return '';

      if (isRecordDataMultipleCellInterface(data.attributes[colDef.colId])) {
        return data.attributes[colDef.colId].multiAttributes;
      }

      return data.attributes[colDef.colId].singleAttribute.display;
    },
    valueSetter: (params: ValueSetterParams): boolean => {
      const { colDef, data } = params;
      if (colDef.colId) data.attributes[colDef.colId].singleAttribute.display = params.newValue;
      return true;
    },
  }));

  return [
    { type: SCGridSchemaTypeEnum.Select, colId: SELECT_ROW_COLUMN },
    { type: SCGridSchemaTypeEnum.Navigate, colId: NAVIGATE_ROW_COLUMN },
    ...colDefs,
  ];
});
