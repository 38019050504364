import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { POPUP_CLOSE_ON_ANY_CLICK, PopupCloseOnType, PopupComponent } from '../popup/popup.component';
import { PopupMenuItemInterface } from './popup-menu.component.interface';

/**
 * Surecloud Popup Menu Component that wraps the Surecloud popup component.
 *
 *
 * @export
 * @class PopupMenuComponent
 */
@Component({
  selector: 'sc-popup-menu',
  standalone: true,
  templateUrl: './popup-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./popup-menu.component.scss'],
  imports: [CommonModule, PopupComponent, CommonIconModule],
  encapsulation: ViewEncapsulation.None,
})
export class PopupMenuComponent {
  /**
   * Menu Items to display in the popup.
   *
   * @type {PopupMenuItemInterface[]}
   * @memberof PopupMenuComponent
   */
  @Input()
  menuItems!: PopupMenuItemInterface[];

  /**
   * Emit up to the parent when a menu item has been clicked.
   *
   * @memberof PopupMenuComponent
   */
  @Output() itemClicked = new EventEmitter<string>();

  /**
   * Expose the track by function to the view template.
   *
   * @memberof PopupMenuComponent
   */
  trackById = PopupMenuComponent.trackById;

  /**
   * Track the menu items in the Angular for loop with this function.
   *
   * @private
   * @static
   * @param {number} _index The index in the loop.
   * @param {PopupMenuItemInterface} item The menu item item we are looping over.
   * @return {string} The ID of the menu item being looped over.
   * @memberof PopupMenuComponent
   */
  private static trackById = (_index: number, item: PopupMenuItemInterface): string => `${item.id}`;

  /**
   * Popup close on setting.
   *
   * @memberof PopupMenuComponent
   */
  popupCloseOnSetting: PopupCloseOnType = POPUP_CLOSE_ON_ANY_CLICK;
}
