import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { COLOURS } from '@surecloud/common';
/**
 * Surecloud Sign Post Component.
 * @export
 * @class MatrixSidePanelBlockComponent
 */
@Component({
  selector: 'sc-matrix-side-panel-block',
  standalone: true,
  styleUrls: ['./matrix-side-panel-block.component.scss'],
  templateUrl: './matrix-side-panel-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class MatrixSidePanelBlockComponent {
  /**
   * Common colour palette.
   * @memberof MatrixSidePanelBlockComponent
   */
  public COLOURS = COLOURS;

  /**
   *The label to show on the Sign Post component.
   * @memberof MatrixSidePanelBlockComponent
   */
  @Input() public label = '';

  /**
   * The background colour of the Sign Post component.
   * @type {(string | undefined)}
   * @memberof MatrixSidePanelBlockComponent
   */
  @Input() public background: string | undefined;

  /**
   * The font colour of the Sign Post component.
   * @type {(string | undefined)}
   * @memberof MatrixSidePanelBlockComponent
   */
  @Input() public colour: string | undefined;
}
