import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to determine if an input text list item delete icon should be displayed.
 * @export
 * @class InputTextListItemDeletablePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'isInputTextListItemDeletable',
  standalone: true,
})
export class InputTextListItemDeletablePipe implements PipeTransform {
  /**
   * Transform the index of an input text list item to determine if the delete icon should be displayed.
   * @param {number} index The indsex of the input text list item.
   * @param {number} length The length of the input text list.
   * @param {number} minimum The minimum number of input text list items.
   * @return {boolean} Can the input list item be deleted.
   * @memberof InputTextListItemDeletablePipe
   */
  // eslint-disable-next-line class-methods-use-this
  public transform(index: number, length: number, minimum: number): boolean {
    return index === length - 1 && index + 1 !== minimum;
  }
}
