import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UNTITLED } from '@surecloud/common';
import { UserAvatarMultiselectComponent } from '../user-selects/user-avatar-multiselect/user-avatar-multiselect.component';
import { UserAvatarSelectComponent } from '../user-selects/user-avatar-select/user-avatar-select.component';
import { UserGroupAvatarInterface } from './user-attribute.model';

/**
 * Render a design User Attribute Component.
 * @export
 * @class UserAttributeComponent
 */
@Component({
  selector: 'sc-design-user-attribute',
  templateUrl: './user-attribute.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UserAvatarSelectComponent, UserAvatarMultiselectComponent],
})
export class UserAttributeComponent implements OnInit {
  /**
   * Flag that sets the user user multi select component
   * @type {(boolean | null)}
   * @memberof UserAttributeComponent
   */
  @Input() multiple!: boolean | null;

  /**
   * the user attribute label
   * @type {(string | null)}
   * @memberof UserAttributeComponent
   */
  @Input() label!: string | null;

  /**
   * Text to display an additional description to the user.
   * @type {string}
   * @memberof UserAttributeComponent
   */
  @Input() description!: string;

  /**
   * The list of users to display
   * @type {UserGroupAvatarInterface[]}
   * @memberof UserAttributeComponent
   */
  @Input() userList: UserGroupAvatarInterface[] = [];

  /**
   * Sets the select dropdown readonly mode.
   * @memberof UserAttributeComponent
   */
  @Input() readonly = false;

  /**
   * Filter change Emitter
   * @memberof UserAttributeComponent
   */
  @Output() filterChangeEmitter = new EventEmitter<string>();

  /**
   * User attribute form
   * @type {FormGroup<{
   *     select: FormControl<string>;
   *     multiSelect: FormControl<string[]>;
   *   }>}
   * @memberof UserAttributeComponent
   */
  form!: FormGroup<{
    select: FormControl<string>;
    multiSelect: FormControl<string[]>;
  }>;

  /**
   * Show untitled variable if label is missing
   * @type {string}
   * @memberof UserAttributeComponent
   */
  UNTITLED: string = UNTITLED;

  /**
   * Creates an instance of UserAttributeComponent.
   * @param {ControlContainer} controlContainer the forms module control container
   * @memberof UserAttributeComponent
   */
  constructor(private readonly controlContainer: ControlContainer) {}

  /**
   * Runs OnInit when the component is initialised.
   * @memberof UserAttributeComponent
   */
  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  /**
   * Emits the new query value when the query changes
   * @param {string} value the query
   * @memberof UserAttributeComponent
   */
  filterChange(value: string): void {
    this.filterChangeEmitter.emit(value);
  }
}
