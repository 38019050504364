import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { KEYDOWN_SPACE, SCGridSchemaTypeEnum } from '@surecloud/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { CheckboxComponent } from '../../../checkbox/checkbox.component';
import { GridEventEnum } from '../../grid-events.enum';

/**
 * GridCheckboxRendererComponent - custom AgGrid component for a check box column.
 * This is different from the built in 'select' column and cannot be used together in the same grid
 * @export
 * @class GridCheckboxRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CheckboxComponent, ReactiveFormsModule],
  selector: 'sc-checkbox-renderer',
  templateUrl: './grid-checkbox-renderer.component.html',
})
export class GridCheckboxRendererComponent implements OnInit, OnDestroy, ICellRendererAngularComp {
  /**
   * Ag Grids API
   * @type {(GridApi | undefined)} the AG Grid api
   * @memberof GridCheckboxRendererComponent
   */
  public agGridApi: GridApi | undefined;

  /**
   * Row Index
   * @memberof GridCheckboxRendererComponent
   */
  rowIndex = 0;

  /**
   * Reference to the column id of the input data
   * @type {(string| undefined)}
   * @memberof GridCheckboxRendererComponent
   */
  colId: string | undefined;

  /**
   * Initial boolean value
   * @memberof GridCheckboxRendererComponent
   */
  public cellValue = false;

  /**
   * Cell renderer params
   * @type {*}
   * @memberof GridCheckboxRendererComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;

  /**
   * Checkbox control
   * @type {FormControl<boolean>}
   * @memberof GridCheckboxRendererComponent
   */
  control: FormControl<boolean> = new FormControl();

  /**
   * The reference to the checkbox cell element.
   * @type {ElementRef}
   * @memberof GridCheckboxRendererComponent
   */
  @ViewChild('checkbox', { read: ElementRef })
  public checkbox!: ElementRef;

  /**
   * Listen for host clicks
   * @param {PointerEvent} event event
   */
  @HostListener('click', ['$event'])
  public cellClick(event: PointerEvent): void {
    this.handleCellClicks(event);
  }

  /**
   * Property to complete and unsubscribe control changes when component be destroyed
   * @private
   * @memberof GridCheckboxRendererComponent
   */
  private destroy$ = new Subject<boolean>();

  /**
   * Type of the checkbox
   * @private
   * @type {SCGridSchemaTypeEnum}
   * @memberof GridCheckboxRendererComponent
   */
  private type: SCGridSchemaTypeEnum = SCGridSchemaTypeEnum.Checkbox;

  /**
   * Handle with control changes
   * @memberof GridCheckboxRendererComponent
   */
  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((newValue) => this.setParams(newValue));
  }

  /**
   * Handle to complete subject destroy$
   * @memberof GridCheckboxRendererComponent
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Handles the ag init and sets the value of the checkbox initial value
   * @param {ICellRendererParams} params cell renderer params
   * @memberof GridCheckboxRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    this.agGridApi = params.api;
    this.rowIndex = params.node?.rowIndex || 0;
    this.params = params;
    // @ts-expect-error ICellRendererParams doesn't contains eGridHeader
    const currentEl = params.eGridHeader || params.eGridCell;
    currentEl.onkeydown = (event: KeyboardEvent): void => this.checkAndUpdateCheckboxValue(event);
    this.colId = params.column?.getColId();

    if (this.params.column.colDef.type) {
      this.type = this.params.column.colDef.type;
    }

    this.setGridEventListeners();
    const controlValue = this.type === SCGridSchemaTypeEnum.CheckboxEditable ? params.value.value : params.value;
    const isControlDisabled = this.type === SCGridSchemaTypeEnum.CheckboxEditable ? params.value.disabled : false;
    this.control.setValue(controlValue);

    if (isControlDisabled) {
      this.control.disable();
    }
  }

  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   * @memberof GridCheckboxRendererComponent
   * @return {boolean} isRefresh
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }

  /**
   * Set params based on control changes
   * @param {boolean} newValue new selected value
   * @memberof GridCheckboxRendererComponent
   */
  setParams(newValue: boolean): void {
    const { colId } = this.params.column;
    this.params.value = newValue;

    switch (this.type) {
      case SCGridSchemaTypeEnum.HeaderSelect:
        return this.handleHeaderCheckboxSelect();
      case SCGridSchemaTypeEnum.Select:
        return this.params.node.setSelected(newValue);
      case SCGridSchemaTypeEnum.CheckboxEditable:
        this.params.node.setDataValue(colId, newValue);
        return this.params.node.setSelected(newValue);
      case SCGridSchemaTypeEnum.Checkbox:
      default:
        this.params.node.setDataValue(colId, newValue);
        return this.agGridApi?.setFocusedCell(this.rowIndex, colId);
    }
  }

  /**
   * If clicked on cell, but outside of the checkbox
   * @memberof GridCheckboxRendererComponent
   */
  onCellClick(): void {
    if (this.control.enabled) {
      this.control.setValue(!this.control.value);
    }
  }

  /**
   * Handles checkbox clicks
   * Handled like this to avoid the double handling of the event, caused by
   * the cellClick event listener and the checkbox click event both firing
   * when the checkbox was clicked (as resulting in a double toggle of the checkbox value)
   * stop.propagation wasn't an option as it would stop our global outside click handlers
   * from working.
   * @private
   * @param {Event} event event data
   * @memberof GridCheckboxRendererComponent
   */
  handleCellClicks(event: Event): void {
    const keyDownSpace = (event as KeyboardEvent).code && (event as KeyboardEvent).code === KEYDOWN_SPACE;
    const checkboxContainsClickedElement = this.checkbox.nativeElement.contains(event.target);
    const checkboxIsClickedElement = event.target === this.checkbox.nativeElement;
    const checkboxClicked = checkboxContainsClickedElement || checkboxIsClickedElement;
    // ignore the event if the checkbox itself was clicked, because the event will
    // bubble and be handled by the checkbox anyway.
    if (checkboxClicked && !keyDownSpace) return;
    this.onCellClick();
  }

  /**
   * Listens to the space key down event so update the boolean checkboxes.
   * This is a workaround specifically for the checkboxes and shouldn't be copied
   * @private
   * @param {KeyboardEvent} event keyboard event data
   * @memberof GridCheckboxRendererComponent
   */
  private checkAndUpdateCheckboxValue(event: KeyboardEvent): void {
    if (event.code === KEYDOWN_SPACE && this.colId) {
      this.agGridApi?.startEditingCell({ rowIndex: this.rowIndex, colKey: this.colId });
      const currentValue = this.control.value;
      this.control.setValue(!currentValue);
      this.agGridApi?.stopEditing();
      event.preventDefault();
    }
  }

  /**
   * Handles header checkbox select event
   * @private
   * @memberof GridCheckboxRendererComponent
   */
  private handleHeaderCheckboxSelect(): void {
    const controlValue = this.control.value;

    if (this.params.displayName) {
      const functionName = controlValue ? 'selectAll' : 'deselectAll';
      this.agGridApi?.[functionName]();
    } else {
      this.params.node.setSelected(controlValue);
    }
  }

  /**
   * Handles header checkbox select all event
   * @private
   * @memberof GridCheckboxRendererComponent
   */
  private handleHeaderCheckboxSelectAll(): void {
    const selectedNodes = this.agGridApi?.getSelectedNodes();
    const currentRow = selectedNodes?.find((node) => node.rowIndex === this.rowIndex);
    if (!this.params.displayName) {
      this.control.setValue(!!currentRow || false, { emitEvent: false });
    }
  }

  /**
   * Handles event select row and updates header select
   * @private
   * @memberof GridCheckboxRendererComponent
   */
  private handleSelectRow(): void {
    // if select all is clicked, but then some of the rows is deselected
    if (this.params.displayName) {
      this.control.setValue(false, { emitEvent: false });
    }
  }

  /**
   * Handles event select row single and deselects every row except the selected one
   * @private
   * @memberof GridCheckboxRendererComponent
   */
  private handleSelectRowSingle(): void {
    const selectedNodes = this.agGridApi?.getSelectedNodes();
    const isNodeSelected = selectedNodes?.find((node) => node.rowIndex === this.rowIndex);
    if (selectedNodes && !isNodeSelected) {
      this.control.setValue(false, { emitEvent: false });
    }
  }

  /**
   * Sets grid event listeners for checkboxes of type HeaderSelect and Select
   * @private
   * @memberof GridCheckboxRendererComponent
   */
  private setGridEventListeners(): void {
    if (this.type === SCGridSchemaTypeEnum.HeaderSelect) {
      this.agGridApi?.addEventListener(GridEventEnum.SELECT_ALL, () => this.handleHeaderCheckboxSelectAll());
    }

    if (this.type === SCGridSchemaTypeEnum.Select || this.type === SCGridSchemaTypeEnum.HeaderSelect) {
      this.agGridApi?.addEventListener(GridEventEnum.SELECT_ROW, () => this.handleSelectRow());
      this.agGridApi?.addEventListener(GridEventEnum.SELECT_ROW_SINGLE_SELECT, () => this.handleSelectRowSingle());
    }

    this.agGridApi?.addEventListener(GridEventEnum.SELECTION_CHANGED, () => this.handleHeaderCheckboxSelectAll());
  }
}
