import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TaskSummaryInterface } from '@surecloud/common';
import { TaskInterface, TaskTotalsInterface } from '../../models/task/task.models';
import { StateInterface, TASK_FEATURE_KEY, taskAdapter } from '../../reducers/task/task.reducer';
// Lookup the Task feature state managed by NgRx
export const getTaskState = createFeatureSelector<StateInterface>(TASK_FEATURE_KEY);

const { selectAll, selectEntities } = taskAdapter.getSelectors();

/**
 * Standard state selectors
 */
export const getTasks = createSelector(getTaskState, (state: StateInterface) => selectAll(state));
export const getTaskEntities = createSelector(getTaskState, (state: StateInterface) => selectEntities(state));
export const getTaskLoaded = createSelector(getTaskState, (state: StateInterface) => state.loaded);
export const getSelectedTaskId = createSelector(getTaskState, (state: StateInterface) => state.selectedId);
export const getSelectedTask = createSelector(getTaskEntities, getSelectedTaskId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

/**
 * Get the new Task count.
 */
export const getTaskTotals = createSelector(
  getTaskState,
  ({ totalNewTasks, totalTasks }): TaskTotalsInterface => ({
    totalNewTasks,
    totalTasks,
  })
);

/**
 * Get the new Task count.
 */
export const getTotalNewTasks = createSelector(getTaskTotals, ({ totalNewTasks }): number => totalNewTasks);

/**
 * Get the Tasks as an array of TaskSummaryInterface.
 */
export const getTaskSummaries = createSelector(getTasks, (tasks: TaskInterface[]) =>
  tasks.map(
    (task): TaskSummaryInterface => ({
      date: task.dueDate,
      description: task.summary,
      id: task.taskId,
      new: task.newTask,
      tags: task.recordName ? [task.recordName] : [],
    })
  )
);

/**
 * Get a Task by Task ID.
 * @param {string} taskId The Task ID to get the Task for.
 * @return {TaskInterface | undefined} The full Task object.
 */
export const getTaskById = (
  taskId: string
): MemoizedSelector<object, TaskInterface | undefined, (s1: Dictionary<TaskInterface>) => TaskInterface | undefined> =>
  createSelector(getTaskEntities, (tasks) => tasks[taskId]);
