/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { UserGroupApiInterface } from '@surecloud/api-types';
import { LoggerService } from '../../logger/logger.service';
import {
  NormalisedUserGroupsList,
  getNormalisedUserGroupsListError,
  isNormalisedUserGroupsListGuard,
} from './normalise-get-user-groups.validation';

/**
 * This service is used for both user groups and record view user groups.
 * Service encapsulating the schema and configuration for extracting the
 * userGroups API response.
 * @export
 * @class NormaliseGetUserGroupsService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetUserGroupsService {
  /**
   * Creates an instance of NormaliseGetUserGroupsService.
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetUserGroupsService
   */
  constructor(private readonly logger: LoggerService) {}

  extract(apiUserGroups: UserGroupApiInterface[]): NormalisedUserGroupsList {
    if (!isNormalisedUserGroupsListGuard(apiUserGroups)) {
      this.logger.logEvent(
        'Record View Feature',
        'Normalise User Groups list Data',
        getNormalisedUserGroupsListError(apiUserGroups)
      );

      throw new Error('The result of the normalised User Group List does not match the expected format.');
    }
    return apiUserGroups;
  }
}
