import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CommonActions, EntityInterface } from '@surecloud/common';
import { EntityActions } from '../../actions/entity.actions';

export const ENTITY_FEATURE_KEY = 'entity';

export interface StateInterface extends EntityState<EntityInterface> {
  selectedId?: string; // which Entity record has been selected
  loaded: boolean; // has the Entity list been loaded
  error?: string | null; // last known error (if any)
}

export interface EntityPartialStateInterface {
  readonly [ENTITY_FEATURE_KEY]: StateInterface;
}

export const entityAdapter: EntityAdapter<EntityInterface> = createEntityAdapter<EntityInterface>({
  selectId: (entity: EntityInterface) => entity.entityId,
});

export const initialState: StateInterface = entityAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const entityReducer = createReducer(
  initialState,
  on(EntityActions.entityListEnter, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(EntityActions.readEntityListSuccess, (state: StateInterface, { entityList }: { entityList: EntityInterface[] }) =>
    entityAdapter.setAll(entityList, { ...state, loaded: true })
  ),
  on(EntityActions.readEntityListFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
  on(EntityActions.selectEntity, (state: StateInterface, { entityId }: { entityId?: string }) => ({
    ...state,
    selectedId: entityId,
  })),
  on(CommonActions.unselectEntity, (state: StateInterface) => ({
    ...state,
    selectedId: undefined,
  })),
  on(EntityActions.readEntitySuccess, (state: StateInterface, { entities }) => entityAdapter.addMany(entities, state)),
  on(CommonActions.readAndSelectEntity, (state: StateInterface, { entity }) =>
    entityAdapter.addMany([entity], {
      ...state,
      selectedId: entity.entityId,
    })
  ),
  on(EntityActions.updateEntitySuccess, (state: StateInterface, { entity }: { entity: EntityInterface }) =>
    entityAdapter.updateOne(
      {
        id: entity.entityId,
        changes: entity,
      },
      state
    )
  ),
  on(EntityActions.deleteEntitySuccess, (state: StateInterface, { entityId }) =>
    entityAdapter.removeOne(entityId, state)
  ),
  on(EntityActions.createEntitySuccess, (state: StateInterface, { entity }) => entityAdapter.addOne(entity, state))
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific entity action.
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX entity action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return entityReducer(state, action);
}
