import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectDropdownComponentOptionInterface, UNTITLED } from '@surecloud/common';
import {
  RendererAllComponentUnion,
  RendererFormControlComponentUnion,
} from '../component-renderer-component.interface';
import { RendererControlConfigUnion } from '../component-renderer-config.interface';
import { isRendererOptionListConfig, isRendererOptionListFormControlComponentRef } from '../component-renderer.guards';
import { RendererOptionListItemInterface } from '../models/option-list.model';
import { formControlComponentCreator } from './form-control-creator.config';
import { OptionSelectListTypeEnum } from './option-list.constants';

/**
 * Extends the formControlComponentCreator to add additional properties related to the
 * option-list component.
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererControlConfigUnion | undefined} componentData - Dynamic component data
 * @param {Type<RendererAllComponentUnion>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<RendererFormControlComponentUnion> | undefined} - Dynamic Component
 */
export const optionListComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererControlConfigUnion | undefined,
  component: Type<RendererAllComponentUnion>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = false
): ComponentRef<RendererFormControlComponentUnion> | undefined => {
  const formComponentRef = formControlComponentCreator(viewContainerRef, componentData, component, control, readonly);
  if (!componentData) return undefined;
  if (!isRendererOptionListConfig(componentData)) return undefined;

  if (formComponentRef && !isRendererOptionListFormControlComponentRef(formComponentRef)) return undefined;

  if (formComponentRef && isRendererOptionListFormControlComponentRef(formComponentRef)) {
    formComponentRef.instance.selectType =
      componentData.multiple === true ? OptionSelectListTypeEnum.MULTI : OptionSelectListTypeEnum.SINGLE;
    const options: SelectDropdownComponentOptionInterface[] | undefined = componentData?.optionList?.items?.map(
      (item: RendererOptionListItemInterface): SelectDropdownComponentOptionInterface => ({
        text: item.value ? item.value : UNTITLED,
        value: item.optionListItemId,
      })
    );
    formComponentRef.instance.options = options ?? [];
  }
  return formComponentRef;
};
