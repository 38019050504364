import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Wrapper component around the [Kendo UI NumericTextBoxComponent](https://www.telerik.com/kendo-angular-ui/components/inputs/api/NumericTextBoxComponent/).
 *
 * @example
 * <sc-input-number
 *  [controlName]="formControlName"
 *  [description]="Description for input"
 *  [increment]="10"
 *  [label]="Label for input"
 *  [placeholder]="Placeholder for input"
 *  [readonly]="true">
 * </sc-input-number>
 * @export
 * @class InputNumberComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-input-number',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    LabelModule,
    ReactiveFormsModule,
    CommonIconModule,
  ],
  templateUrl: './input-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent implements OnInit {
  /**
   * The form control name if using a reactive form.
   *
   * @memberof InputNumberComponent
   */
  @Input() controlName = '';

  /**
   * The number of decimals a user is allowed to input.
   *
   * @memberof InputNumberComponent
   */
  @Input() decimals = 0;

  /**
   * Text to display an additional description to the user.
   *
   * @memberof InputNumberComponent
   */
  @Input() description = '';

  /**
   * The value can be formatted in a variety of ways to support currency, decimals and various formatting options.
   * See the [NumberFormatOptions interface](https://www.telerik.com/kendo-angular-ui/components/globalization/internationalization/api/NumberFormatOptions/).
   *
   * @type {(NumberFormatOptions | string | null)}
   * @memberof InputNumberComponent
   */
  @Input() format: NumberFormatOptions | string | null = 'n0';

  /**
   * The amount to increase/decrease the input value by when clicking the increase and decrease buttons.
   *
   * @memberof InputNumberComponent
   */
  @Input() increment = 1;

  /**
   * The label text for the input.
   *
   * @memberof InputNumberComponent
   */
  @Input() label = '';

  /**
   * The placeholder text for the input.
   *
   * @memberof InputNumberComponent
   */
  @Input() placeholder = '';

  /**
   * Set the input to readonly mode.
   *
   * @memberof InputNumberComponent
   */
  @Input() readonly = false;

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   *
   * @type {string}
   * @memberof InputNumberComponent
   */
  @Input() staticWidth!: string;

  /**
   * Automation testhook to add to the input.
   *
   * @memberof InputNumberComponent
   */
  @Input() testhook = 'input-number';

  /**
   *  Sets if we want a success validation style to display.
   *
   * @memberof InputNumberComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   *  Sets a fixed min and max value in the input.
   *
   * @memberof InputNumberComponent
   */
  @Input() autoCorrect = false;

  /**
   *  Sets a min in the input.
   *
   * @memberof InputNumberComponent
   */
  @Input() min = 0;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   *
   * @memberof InputNumberComponent
   */
  @Input() marginBottom = false;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   *
   * @type {FormGroup}
   * @memberof InputNumberComponent
   */
  @Input() parentFormGroup!: FormGroup;

  /**
   * Creates an instance of InputNumberComponent.
   *
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @memberof InputNumberComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Runs when the component is started.
   * Sets the parent formgroup form the component.
   *
   * @memberof InputNumberComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}

