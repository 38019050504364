import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TabInterface, UNTITLED } from '@surecloud/common';
import {
  HOMEPAGE_APPLICATIONS_FEATURE_KEY,
  StateInterface,
  homepageApplicationsAdapter,
} from '../../reducers/homepage-applications/homepage-applications.reducer';

// Lookup the HomepageApplications feature state managed by NgRx
export const getHomepageApplicationsState = createFeatureSelector<StateInterface>(HOMEPAGE_APPLICATIONS_FEATURE_KEY);

const { selectAll, selectEntities } = homepageApplicationsAdapter.getSelectors();

/**
 * Get the Homepage Application loaded status
 */
export const getHomepageApplicationsLoaded = createSelector(
  getHomepageApplicationsState,
  (state: StateInterface) => state.loaded
);

/**
 * Get the Homepage Application Entities
 */
export const getHomepageApplicationsEntities = createSelector(getHomepageApplicationsState, (state: StateInterface) =>
  selectEntities(state)
);

/**
 * Get the loaded Homepage Applications.
 */
export const getHomepageApplicationList = createSelector(getHomepageApplicationsState, (state: StateInterface) =>
  selectAll(state)
);

/**
 * Get all the Homepage Application Tabs
 */
export const getHomepageApplicationTabs = createSelector(getHomepageApplicationList, (homepageApplications) =>
  homepageApplications.map<TabInterface>((homepageApplication, index) => ({
    name: homepageApplication.applicationName,
    id: index,
  }))
);

/**
 * Get the selected Homepage Application
 */
export const getSelectedId = createSelector(getHomepageApplicationsState, (state: StateInterface) => state.selectedId);

/**
 * Get the selected Homepage Application
 */
export const getSelected = createSelector(getHomepageApplicationsEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId]?.applicationEntities : undefined
);

/**
 * Get the selected Homepage Application in the grid format
 */
export const getSelectedForGrid = createSelector(getSelected, getSelectedId, (selected) =>
  selected
    ? selected.map((entity) => ({
        ...entity,
        entityName: entity.entityName || UNTITLED,
        entityDescription: entity.entityDescription || UNTITLED,
      }))
    : undefined
);

/**
 * Get active tab
 */
export const getActiveTabIndex = createSelector(
  getHomepageApplicationList,
  getSelectedId,
  (homepageApplications, selectedId) =>
    homepageApplications.findIndex((homepageApplication) => homepageApplication.applicationName === selectedId)
);
