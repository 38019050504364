import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { UNTITLED } from '@surecloud/common';
import { RENDERER_ALL_CONTROLS_CONFIG } from './component-renderer-all-controls.config';
import { RendererAllComponentUnion } from './component-renderer-component.interface';
import {
  RendererCalculationConfigInterface,
  RendererControlConfigUnion,
  RendererFormControlConfigInterface,
  RendererInformationControlConfigInterface,
  RendererOptionListControlConfigInterface,
  RendererQuestionControlConfigInterface,
  RendererTableControlConfigInterface,
  RendererTypeEnum,
  RendererViewFormInterface,
  makeNoTypeComponentConfig,
} from './component-renderer-config.interface';
import {
  isRendererAttributeConfig,
  isRendererCalculationAttributeConfig,
  isRendererFormControlConfig,
  isRendererInformationConfig,
  isRendererOptionListConfig,
  isRendererQuestionConfig,
  isRendererTableConfig,
  isRendererTextConfig,
} from './component-renderer.guards';

@Injectable({
  providedIn: 'root',
})
export class ComponentRendererService {
  /**
   * Additional helper to unpack an ATTRIBUTE viewComponent
   * to a viable RendererControlConfig.
   *
   * @param {RendererControlConfigUnion} viewComponent - The view component passed in.
   * @return {RendererControlConfigUnion | undefined} - Render Control Config passed back
   */
  static convertViewComponent(viewComponent?: unknown): RendererControlConfigUnion | undefined {
    // If needed convert from attribute to normal viewComponent.
    if (isRendererAttributeConfig(viewComponent)) {
      const { attribute } = viewComponent;
      const { type, name, guidanceText } = attribute;
      const rendererConfig: RendererFormControlConfigInterface = {
        type: type || RendererTypeEnum.NoType,
        label: name || '',
        guidanceText: guidanceText || '',
        placeholder: name || '',
        attribute,
      };
      return ComponentRendererService.mapViewComponentToRendererConfig(rendererConfig);
    }
    return ComponentRendererService.mapViewComponentToRendererConfig(viewComponent);
  }

  /**
   * Helper to convert the viewComponent (whatever form its in)
   * to a viable RendererControlConfig.
   *
   * @param {unknown} viewComponent - The view component passed in.
   * @return {RendererControlConfigUnion | undefined} - Render Control Config passed back.
   */
  // eslint-disable-next-line max-lines-per-function
  static mapViewComponentToRendererConfig(viewComponent?: unknown): RendererControlConfigUnion | undefined {
    if (!viewComponent) return undefined;

    // Convert to RendererCalculationConfigInterface
    if (isRendererCalculationAttributeConfig(viewComponent)) {
      const { label, guidanceText, type, attribute, direction } = viewComponent;
      const rendererComponent: RendererCalculationConfigInterface = {
        type,
        direction,
        label,
        guidanceText,
        attribute,
      };
      return rendererComponent;
    }

    // Convert to RendererTableControlConfigInterface
    if (isRendererTableConfig(viewComponent)) {
      const { type } = viewComponent;
      const rendererComponent: RendererTableControlConfigInterface = {
        tabs: viewComponent.tabs,
        type,
      };
      return rendererComponent;
    }

    // Convert to RendererInformationControlConfigInterface
    if (isRendererInformationConfig(viewComponent)) {
      const { type } = viewComponent;
      const rendererComponent: RendererInformationControlConfigInterface = {
        label: UNTITLED,
        type,
      };
      return rendererComponent;
    }

    if (isRendererQuestionConfig(viewComponent)) {
      const { type, tag } = viewComponent;
      const rendererComponent: RendererQuestionControlConfigInterface = {
        label: '',
        tag: tag || '',
        type,
      };
      return rendererComponent;
    }

    // Add type guard a nd create component config for each type
    if (isRendererOptionListConfig(viewComponent)) {
      const { type, multiple, label, placeholder, guidanceText, attribute, optionList } = viewComponent;
      const rendererComponent: RendererOptionListControlConfigInterface = {
        type,
        optionList,
        multiple,
        label,
        placeholder,
        guidanceText,
        attribute,
      };
      return rendererComponent;
    }

    // Convert to RendererFormControlConfigInterface
    // This needs to be at the end
    if (isRendererFormControlConfig(viewComponent)) {
      const { label, placeholder, guidanceText, type, attribute } = viewComponent;

      const rendererComponent: RendererFormControlConfigInterface = {
        label,
        placeholder,
        guidanceText,
        type,
        attribute,
      };
      return rendererComponent;
    }

    // Add type guard and create component config for each type
    // (unless end result is the same as an existing type)
    // ...

    return undefined;
  }

  /**
   * Help the view create the component.
   *
   * @param {ViewContainerRef} viewContainerRef - The viewContainerRef to create a component within.
   * @param {RendererControlConfigUnion} componentData - Component configuration from the view.
   * @param {RendererViewFormInterface} control - The control created in the view.
   * @param {boolean} readonly - whether the component should render in readonly mode.
   * @return {ComponentRef<RendererAllComponentUnion> | undefined} - component reference to send back to the view.
   */
  // eslint-disable-next-line class-methods-use-this
  public createComponent(
    viewContainerRef: ViewContainerRef,
    componentData?: RendererControlConfigUnion,
    control?: RendererViewFormInterface,
    readonly = false
  ): ComponentRef<RendererAllComponentUnion> | undefined {
    // We always return a component, so if there's no config data

    if (!componentData) {
      const noTypeComponentData = makeNoTypeComponentConfig();
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[RendererTypeEnum.NoType];
      return creatorFn(viewContainerRef, noTypeComponentData, component);
    }
    if (isRendererOptionListConfig(componentData)) {
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[componentData.type];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    if (isRendererCalculationAttributeConfig(componentData)) {
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[componentData.type];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    if (isRendererTextConfig(componentData)) {
      const type = componentData.attribute?.large ? RendererTypeEnum.TextArea : RendererTypeEnum.Text;
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[type];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    if (isRendererFormControlConfig(componentData)) {
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[componentData.type];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    if (isRendererTableConfig(componentData)) {
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[componentData.type];
      return creatorFn(viewContainerRef, componentData, component, readonly);
    }

    if (isRendererQuestionConfig(componentData)) {
      const { creatorFn, component } = RENDERER_ALL_CONTROLS_CONFIG[RendererTypeEnum.Question];
      return creatorFn(viewContainerRef, componentData, component);
    }
    return undefined;
  }
}
