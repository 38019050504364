import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackByIndex } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { StepBannerComponentStepInterface } from './step-banner.component.interface';

/**
 * Surecloud Step Banner Component.
 *
 * @export
 * @class StepBannerComponent
 */
@Component({
  selector: 'sc-step-banner',
  standalone: true,
  templateUrl: './step-banner.component.html',
  styleUrls: ['./step-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CommonIconModule],
})
export class StepBannerComponent {
  /**
   * The index of the current step.
   *
   * @memberof StepBannerComponent
   */
  @Input() currentStep = 0;

  /**
   * The different steps.
   *
   * @type {StepBannerComponentStepInterface[]}
   * @memberof StepBannerComponent
   */
  @Input() steps!: StepBannerComponentStepInterface[];

  /**
   * Track by function for Angular loops.
   *
   * @memberof StepBannerComponent
   */
  trackByFunction = trackByIndex;
}
