import { makeHumanReadableZodErrors } from '@surecloud/common';
import { z } from 'zod';

/**
 * Normalise Get recentRecords Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetRecentRecordsService.
 */

/**
 * Recent Record Zod schema.
 */
export const recentRecordZodSchema = z.object({
  lastViewed: z.string(),
  lastViewedDate: z.string(),
  recordId: z.string(),
  recordName: z.string().nullable(),
});

/**
 * Normalised Recent Records Zod schema.
 */
export const normalisedRecentRecordsZodSchema = z.object({
  recentRecords: z.array(recentRecordZodSchema),
});

/**
 * Normalised Recent Records data interface.
 */
export type NormalisedRecentRecords = z.infer<typeof normalisedRecentRecordsZodSchema>;

/**
 * Guard to check the normalised recentRecords data matches the schema.
 *
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedRecentRecords.
 */
export const isNormalisedRecentRecordsGuard = (map: unknown): map is NormalisedRecentRecords =>
  normalisedRecentRecordsZodSchema.safeParse(map).success;

/**
 * When the normalised Recent Records data does not match the schema
 * then get the error as to show where the problem is.
 *
 * @param {unknown} map Map of the data to check.
 * @return {string} The error on where the map of the data does not match a NormalisedRecentRecords.
 */
export const getNormalisedRecentRecordsError = (map: unknown): string => {
  const zodResult = normalisedRecentRecordsZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};
