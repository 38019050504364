import { DocumentUploadStatusEnum, DocumentVirusScanStatusEnum } from '@surecloud/common';

/**
 * The Item File status enum
 */
export enum FileItemStatusEnum {
  'Failed',
  'SuccessfullyUploaded',
  'InProgress',
  'VirusScanInProgress',
  'VirusScanFailed',
  'Completed',
}

/**
 * Converts the DocumentUploadStatusEnum to a number
 * @export
 * @param {DocumentUploadStatusEnum} uploadedStatus The status of the file
 * @param {DocumentVirusScanStatusEnum} virusScanStatus The status of the virus scan
 * @return {number} The status of the file as a number
 */
export const convertDocumentUploadStatusToNumber = (uploadedStatus: DocumentUploadStatusEnum, virusScanStatus: DocumentVirusScanStatusEnum ): FileItemStatusEnum => {
  if(virusScanStatus === DocumentVirusScanStatusEnum.Error || virusScanStatus === DocumentVirusScanStatusEnum.Infected) {
    return FileItemStatusEnum.VirusScanFailed;
  }

  if (uploadedStatus === DocumentUploadStatusEnum.InProgress) {
    return FileItemStatusEnum.InProgress;
  }

  if(virusScanStatus === DocumentVirusScanStatusEnum.Pending && uploadedStatus === DocumentUploadStatusEnum.Completed) {
    return FileItemStatusEnum.VirusScanInProgress;
  }

  if (uploadedStatus === DocumentUploadStatusEnum.Failed) {
    return FileItemStatusEnum.Failed;
  }

  if(uploadedStatus === DocumentUploadStatusEnum.SuccessfullyUploaded) {
    return FileItemStatusEnum.SuccessfullyUploaded;
  }

  return FileItemStatusEnum.Completed;
};

/**
 * Converts the FileItemStatusEnum to a CSS class
 * @export
 * @param {FileItemStatusEnum} status The status of the file
 * @return {string} The CSS class for the status
 */
export const convertFileItemStatusEnumToCSSClass = (status: FileItemStatusEnum): string => {
  switch (status) {
    case FileItemStatusEnum.Failed:
      return 'failed';
    case FileItemStatusEnum.SuccessfullyUploaded:
      return 'success';
    case FileItemStatusEnum.InProgress:
      return 'in-progress';
    case FileItemStatusEnum.VirusScanInProgress:
      return 'in-progress';
    case FileItemStatusEnum.VirusScanFailed:
      return 'failed';
    default:
      return 'none';
  }
};
