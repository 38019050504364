<div class="flex justify-content-between align-items-center w-full sc-condition-display">
  <button
    sc-button
    type="button"
    class="flex justify-content-between align-items-center sc-border sc-border-radius-8 flex-grow-1 sc-pt-4 sc-pb-4 sc-condition-display__edit-button"
    (click)="edit.emit()"
    [disabled]="disable">
    <div class="flex justify-content-start flex-wrap align-items-center sc-ml-12">
      <p class="sc-mr-8">{{ text }}</p>
      <sc-tag *ngFor="let tag of tags; trackBy: trackBy" size="md">{{ tag }}</sc-tag>
    </div>
    <sc-icon key="edit" size="x-large" colour="grey-600" class="sc-ml-6 sc-mr-8"></sc-icon>
  </button>
  <div class="sc-ml-4">
    <sc-icon-button
      [buttonType]="'button'"
      shape="rounded"
      size="large"
      themeColour="red"
      icon="trash"
      [disabled]="disable"
      (click)="delete.emit()">
    </sc-icon-button>
  </div>
</div>
