import { Injectable } from '@angular/core';
import { EnvironmentConfigInterface } from '@surecloud/common';
import { RUNTIME_ENV_CONFIG_URL } from '../../core-app.constants';
import { getIsValidEnvironmentConfigErrors, isValidEnvironmentConfigGuard } from './environment-config.validation';

/**
 * A service to preload the environment runtime config before
 * the rest of the Application bootstraps.
 * @export
 * @class EnvironmentConfigService
 */
@Injectable({ providedIn: 'any' })
export class EnvironmentConfigService {
  /**
   * Property to store the environmentConfig
   * @private
   * @static
   * @type {EnvironmentConfigInterface}
   * @memberof EnvironmentConfigService
   */
  private static environmentConfig: EnvironmentConfigInterface;

  /**
   * Mechanism for loading the local config json file prior
   * to Angular bootstrapping.
   * @static
   * @return {Promise<void>} Empty promise which either load the environment configuration file or throw an error on failure.
   * @memberof EnvironmentConfigService
   */
  static loadEnvironmentConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      fetch(RUNTIME_ENV_CONFIG_URL).then((response) => {
        if (!response.ok) {
          const err = new Error(`Request to load the environment file: ${RUNTIME_ENV_CONFIG_URL} has failed.`);
          reject(err);
          throw err;
        }
        const configPromise: Promise<EnvironmentConfigInterface> = response.json();
        configPromise.then((runtimeEnvConfig) => {
          EnvironmentConfigService.validateAndSetConfig(runtimeEnvConfig);
          resolve();
        });
      });
    });
  }

  /**
   * Run strict validation of the config file as it can't be type checked
   * at build time.
   * @private
   * @static
   * @param {EnvironmentConfigInterface} runtimeEnvConfig - the result of the runtime config file
   * @memberof EnvironmentConfigService
   */
  private static validateAndSetConfig(runtimeEnvConfig: EnvironmentConfigInterface): void {
    if (!isValidEnvironmentConfigGuard(runtimeEnvConfig)) {
      const validationError = getIsValidEnvironmentConfigErrors(runtimeEnvConfig);
      // eslint-disable-next-line no-console
      console.error('Runtime Environment Config Error \n', validationError);
      throw new Error(
        `Validation of the runtime environment config failed. The app can't run without it. Check that the file is in the correct format.`
      );
    }

    const { auth0 } = runtimeEnvConfig;
    const url = new URL(window.location.href);
    const redirectUri = auth0.redirectUri || url.origin;
    const configWithRedirectUri: Required<EnvironmentConfigInterface> = {
      ...runtimeEnvConfig,
      ...{ auth0: { ...auth0, redirectUri } },
    };
    EnvironmentConfigService.environmentConfig = configWithRedirectUri;
  }

  /**
   * Obtain the current set config from the Instance
   * @return {EnvironmentConfigInterface} The environment configuration.
   * @memberof EnvironmentConfigService
   */
  // eslint-disable-next-line class-methods-use-this
  getEnvironmentConfig(): EnvironmentConfigInterface {
    return EnvironmentConfigService.environmentConfig;
  }
}
