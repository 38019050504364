import { Injectable } from '@angular/core';
import { ValidateGuestTokenApiInterfaceQueryService } from '@surecloud/api-types';
import { Observable, map, of, tap } from 'rxjs';
import { GuestTokenService } from './guest-token.service';

/**
 * Service to handle guest authentication.
 * Intentionally kept separate from the guest token service to avoid circular dependencies.
 * @export
 * @class GuestAuthService
 */
@Injectable({
  providedIn: 'root',
})
export class GuestAuthService {
  /**
   * Creates an instance of GuestAuthService.
   * @param {GuestTokenService} guestUserTokenService - the synchronous, no-dependency guest token service
   * @param {ValidateGuestTokenApiInterfaceQueryService} validateGuestTokenService - the Apollo query service to validate the guest token
   * @memberof GuestAuthService
   */
  constructor(
    private guestUserTokenService: GuestTokenService,
    private validateGuestTokenService: ValidateGuestTokenApiInterfaceQueryService
  ) {}

  /**
   * Validates and initialises the guest token.
   * @return {Observable<boolean>} Observable of the validation result.
   * @memberof GuestAuthService
   */
  public validateAndInitialise(): Observable<boolean> {
    const token = this.guestUserTokenService.initToken();
    if (token) {
      return this.validateGuestToken(token).pipe(
        tap((recordId) => {
          if (recordId) {
            this.guestUserTokenService.saveToken(token, true, recordId);
          }
        }),
        map((recordId) => !!recordId)
      );
    }
    return of(false);
  }

  /**
   * Take the tenant entered by the user and do a query to check if the org exists
   * @param {string} token The guest token.
   * @return {Observable<string>} Apollo query result for recordId.
   * @memberof GuestAuthService
   */
  private validateGuestToken(token: string): Observable<string> {
    return this.validateGuestTokenService
      .fetch({ input: { token } })
      .pipe(map((result) => result.data?.validateGuestToken.recordId));
  }
}
