import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AttributePermissionDeleteInputInterface,
  AttributePermissionInterface,
} from '../models/permission/attribute-permission/attribute-permission.models';

/**
 *  Attribute Permission API actions map.
 */
const ATTRIBUTE_PERMISSION_API_EVENTS_MAP = {
  'Read Attribute Permission List': emptyProps(),
  'Read Attribute Permission List Success': props<{ permissions: AttributePermissionInterface[] }>(),
  'Read Attribute Permission Failure': props<{ error: string }>(),
  'Create Attribute Permission Success': props<{
    entityAttributePermission: AttributePermissionInterface;
  }>(),
  'Create Attribute Permission Failure': props<{ error: string }>(),
  'Update Attribute Permission Success': props<{
    entityAttributePermission: AttributePermissionInterface;
  }>(),
  'Update Attribute Permission Failure': props<{ error: string }>(),
  'Delete Attribute Permission': props<{
    entityAttributeDeletePermission: AttributePermissionDeleteInputInterface;
  }>(),
  'Delete Attribute Permission Success': props<{ deletedIds: string[] }>(),
  'Delete Attribute Permission Failure': props<{ error: string }>(),
};

/**
 *  Attribute Permission API actions.
 */
export const AttributePermissionApiActions = createActionGroup({
  source: ' Attribute Permission/API',
  events: ATTRIBUTE_PERMISSION_API_EVENTS_MAP,
});

/**
 *  Attribute Permission List actions.
 */
export const AttributePermissionListActions = createActionGroup({
  source: ' Attribute Properties Page',
  events: {
    'Create Attribute Permission': emptyProps(),
    'Update Attribute Permission': props<{ entityAttributePermission: AttributePermissionInterface }>(),
    'Start Delete Attribute Permission': props<{
      entityAttributePermission: AttributePermissionInterface;
    }>(),
  },
});

/**
 *  Attribute Permission actions.
 */
export const AttributePermissionActions = {
  ...AttributePermissionApiActions,
  ...AttributePermissionListActions,
};
