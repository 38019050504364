import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import * as calcConsts from './calculation.constants';
import {
  DirectionCorrelationInterface,
  DirectionDataInterface,
  DirectionInterface,
  DirectionTrendInterface,
} from './calculation.type';

/**
 * Calculation component that display value changes.
 *
 * @export
 * @class CalculationComponent
 */
@Component({
  selector: 'sc-calculation',
  standalone: true,
  styleUrls: ['./calculation.component.scss'],
  imports: [CommonModule, CommonIconModule, LabelModule, InputsModule],
  templateUrl: './calculation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationComponent implements OnInit {
  /**
   * The current calculation value.
   *
   * @memberof CalculationComponent
   */
  @Input() value: string | undefined;

  /**
   * Text to display an additional description to the user.
   *
   * @memberof CalculationComponent
   */
  @Input() description = '';

  /**
   *
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof CalculationComponent
   */
  @Input() controlName = '';

  /**
   * The parent form group of the calculation component.
   *
   * @type {FormGroup}
   * @memberof CalculationComponent
   */
  @Input() parentFormGroup?: FormGroup;

  /**
   * The label text
   * Sets the text value for the label.
   *
   * @memberof CalculationComponent
   */
  @Input() label = '';

  /**
   * Sets if we want the usual form margin bottom on the form field.
   *
   * @memberof CalculationComponent
   */
  @Input() marginBottom = false;

  /**
   * Controls if we show th Trend component or not
   *
   * @memberof CalculationComponent
   */
  @Input() trendAnalysis = false;

  /**
   * Controls if we show the Trend component or not
   *
   * @memberof CalculationComponent
   */
  @Input() trendDirection: DirectionTrendInterface = 'STABLE';

  /**
   * Controls if we show the Trend component or not
   *
   * @memberof CalculationComponent
   */
  @Input() direction: DirectionInterface = 'STABLE';

  /**
   * The trend correlation value used to drive the arrow
   *
   * @type {DirectionCorrelationInterface}
   * @memberof CalculationComponent
   */
  correlation: DirectionCorrelationInterface = 'none';

  /**
   * The data for the differences of the directions: colour and direction.
   *
   * @type {(DirectionDataInterface | null)}
   * @memberof CalculationComponent
   */
  public directionData: DirectionDataInterface | null = null;

  /**
   * Assign imported consts for use in view.
   *
   * @memberof CalculationComponent
   */
  public calcConsts = calcConsts;

  /**
   * Runs when the component is started.
   * Sets the direction data for the arrow and colour.
   *
   * @memberof CalculationComponent
   */
  ngOnInit(): void {
    const directionInfo: DirectionCorrelationInterface = this.getDirectionInfo();

    if (directionInfo && calcConsts.CALC_DIRECTION_DATA[directionInfo]) {
      this.directionData = calcConsts.CALC_DIRECTION_DATA[directionInfo];
    } else {
      this.directionData = null;
    }
  }

  /**
   * Sets the correlation and returns the direction info
   *
   * @private
   * @return {*}  {DirectionCorrelationInterface}
   * @memberof CalculationComponent
   */
  private getDirectionInfo(): DirectionCorrelationInterface {
    if (this.trendDirection === 'DECREASING' && this.direction === 'DECREASED') {
      this.correlation = 'positive';
      return 'negative';
    }
    if ((this.trendDirection === 'DECREASING' || this.trendDirection === 'INCREASING') && this.direction === 'STABLE') {
      this.correlation = 'none';
      return 'none';
    }
    if ((this.trendDirection === 'DECREASING' || this.trendDirection === 'STABLE') && this.direction === 'INCREASED') {
      this.correlation = 'negative';
      return 'positive';
    }
    if (this.trendDirection === 'STABLE' && this.direction === 'STABLE') {
      this.correlation = 'positive';
      return 'none';
    }
    if ((this.trendDirection === 'STABLE' || this.trendDirection === 'INCREASING') && this.direction === 'DECREASED') {
      this.correlation = 'negative';
      return 'negative';
    }
    if (this.trendDirection === 'INCREASING' && this.direction === 'INCREASED') {
      this.correlation = 'positive';
      return 'positive';
    }
    return null;
  }
}
