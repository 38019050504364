/**
 * Enum for the status of a matrix cell
 * @export
 * @enum {number}
 */
export enum MatrixCellStatusEnum {
  EMPTY = 'empty',
  NEW = 'new',
  FILLED = 'filled',
  SELECTED = 'selected',
  LABELLED = 'labelled',
}
