<div
  *ngIf="task"
  class="sc-task-summary sc-px-16 sc-py-12 sc-border-bottom--grey-100 cursor-pointer"
  (click)="select(task)"
  (keypress)="select(task)">
  <p class="sc-task-summary__status" *ngIf="task.new" title="New Task" i18n-title>
    <span i18n class="sc-visually-hidden">New Task</span>
  </p>
  <p class="sc-font-body-text sc-text sc-mb-4" *ngIf="task.description">{{ task.description }}</p>
  <p i18n class="sc-font-body-text sc-text-grey-600" *ngIf="task.date">Due {{ task.date | date : 'dd/MM/yyyy' }}</p>
  <div class="sc-pt-4" *ngIf="task.tags?.length">
    <sc-tag *ngFor="let tag of task.tags; trackBy: trackByFunction" size="md" theme="light-grey" [attr.title]="tag">
      {{ tag }}
    </sc-tag>
  </div>
</div>
