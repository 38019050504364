/**
 * utf8 const for base64 encoding.
 */
export const UTF8 = 'utf8';

/**
 * Const for base64 encoding.
 */
export const BASE64 = 'base64';

/**
 * Const for base64 decoding.
 */
export const BINARY = 'binary';
