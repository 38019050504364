import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { ScTagSize, ScTagTheme } from './tag.constants';
/**
 * Surecloud TagComponent Component.
 * @export
 * @class TagComponent
 */
@Component({
  selector: 'sc-tag',
  standalone: true,
  templateUrl: './tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, CommonIconModule],
})
export class TagComponent {
  /**
   * Sets the size of the tag.
   * @type {ScTagSize}
   * @memberof TagComponent
   */
  @Input() size: ScTagSize = 'sm';

  /**
   * Sets the colour of the tag.
   * @type {ScTagTheme}
   * @memberof TagComponent
   */
  @Input() theme: ScTagTheme = 'blue';

  /**
   * Sets the icon remove option.
   * @type {boolean}
   * @memberof TagComponent
   */
  @Input() isRemovable = false;

  /**
   * Emit the remove icon click
   * @memberof TagComponent
   */
  @Output() remove = new EventEmitter<void>();

  /**
   * Attach classes to the component element based on the data provided to the inputs.
   * @readonly
   * @type {string}
   * @memberof TagComponent
   */
  get classes(): string {
    return ['sc-tags', 'sc-mr-4', `sc-tag--${this.size}`, `theme ${this.theme}`, 'sc-text'].filter(Boolean).join(' ');
  }
}
