import { IdpUrlParserService } from '../idp-url-parser/idp-url-parser.service';

/**
 * This function initialises the IDP parser.
 * @param {IdpUrlParserService} idpUrlParserService The IDP URL parser service.
 * @return {() => Promise<void>} A function that returns a promise.
 */
export const initialiseURLFactory = (idpUrlParserService: IdpUrlParserService): (() => Promise<void>) => {
  idpUrlParserService.parse();
  return () => Promise.resolve();
};
