import { MutationOptions } from '@apollo/client';
import {
  LinkedEntityCreateInputApiInterface,
  LinkedEntityDeleteInputApiInterface,
  LinkedEntityTagCreateInputApiInterface,
  LinkedEntityTagDeleteInputApiInterface,
  LinkedEntityUpdateInputApiInterface,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';

/**
 * Create linked entity GraphQL mutation.
 */
const LINKED_ENTITY_CREATE_MUTATION = gql`
  mutation entityLinkCreate($entityId: UUID!, $linkedEntityIds: [UUID!]!) {
    entityLinkCreate(input: { entityId: $entityId, linkedEntityIds: $linkedEntityIds }) {
      createdIds
      linkedEntities {
        linkedEntityId
      }
    }
  }
`;

/**
 * Delete linked entity GraphQL mutation.
 */
const LINKED_ENTITY_DELETTE_MUTATION = gql`
  mutation entityLinkDelete($entityId: UUID!, $linkedEntityIds: [UUID!]!) {
    entityLinkDelete(input: { entityId: $entityId, linkedEntityIds: $linkedEntityIds }) {
      deletedIds
      entity {
        entityId
      }
    }
  }
`;

/**
 * Create linked entity tag GraphQL mutation.
 */
const LINKED_ENTITY_TAG_CREATE_MUTATION = gql`
  mutation entityLinkTagCreate($entityId: UUID!, $linkedEntityId: UUID!, $label: String) {
    entityLinkTagCreate(input: { entityId: $entityId, linkedEntityId: $linkedEntityId, label: $label }) {
      createdId
    }
  }
`;

/**
 * Delete linked entity tag GraphQL mutation.
 */
const LINKED_ENTITY_TAG_DELETE_MUTATION = gql`
  mutation entityLinkTagDelete($entityId: UUID!, $linkedEntityId: UUID!, $tagId: UUID!) {
    entityLinkTagDelete(input: { entityId: $entityId, linkedEntityId: $linkedEntityId, tagId: $tagId }) {
      deletedId
    }
  }
`;

/**
 * Update linked entity GraphQL mutation.
 */
const LINKED_ENTITY_UPDATE_MUTATION = gql`
  mutation entityLinkUpdate($entityId: UUID!, $linkedEntityId: UUID!, $type: LinkedEntityType!) {
    entityLinkUpdate(input: { entityId: $entityId, linkedEntityId: $linkedEntityId, type: $type }) {
      linkedEntity {
        linkedEntityId
        type
      }
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for creating a linked entity.
 * Linked entity is created using the entity ids.
 * @param {LinkedEntityCreateInputApiInterface} linkedEntityCreateInput the input params for create linked entity.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloLinkedEntityCreateMutation = (
  linkedEntityCreateInput: LinkedEntityCreateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: LINKED_ENTITY_CREATE_MUTATION,
  variables: { ...linkedEntityCreateInput },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Function to create the GraphQL mutation options for deleting a linked entity.
 * Linked Entity is deleted using the entity ids.
 * @param {LinkedEntityCreateInputApiInterface} linkedEntityDeleteInput the input params for delete linked entity.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloLinkedEntityDeleteMutation = (
  linkedEntityDeleteInput: LinkedEntityDeleteInputApiInterface
): MutationOptions => ({
  mutation: LINKED_ENTITY_DELETTE_MUTATION,
  variables: { ...linkedEntityDeleteInput },
});

/**
 * Function to create the GraphQL mutation options for creating a linked entity tag.
 * Linked entity tag is created using the entity id and linked entity id.
 * @param {LinkedEntityTagCreateInputApiInterface} linkedEntityTagCreateInput the input params for create linked entity tag.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloLinkedEntityTagCreateMutation = (
  linkedEntityTagCreateInput: LinkedEntityTagCreateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: LINKED_ENTITY_TAG_CREATE_MUTATION,
  variables: { ...linkedEntityTagCreateInput },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Function to create the GraphQL mutation options for deleting a linked entity tag.
 * Linked entity tag is deleted using the entity id, linked entity id and tag id.
 * @param {LinkedEntityTagDeleteInputApiInterface} linkedEntityTagDeleteInput the input params for delete linked entity tag.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloLinkedEntityTagDeleteMutation = (
  linkedEntityTagDeleteInput: LinkedEntityTagDeleteInputApiInterface
): MutationOptions => ({
  mutation: LINKED_ENTITY_TAG_DELETE_MUTATION,
  variables: { ...linkedEntityTagDeleteInput },
});

/**
 * Function to create the GraphQL mutation options for update a linked entity type.
 * @param {LinkedEntityUpdateInputApiInterface} linkedEntityUpdateInput the input params for update linked entity.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloLinkedEntityUpdateMutation = (
  linkedEntityUpdateInput: LinkedEntityUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: LINKED_ENTITY_UPDATE_MUTATION,
  variables: { ...linkedEntityUpdateInput },
  ...makeContextWithRequestIdHeader(requestId),
});
