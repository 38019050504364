import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { HierarchyNodeInterface } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Surecloud Hierarchy Component.
 * @export
 * @implements {OnDestroy}
 * @class HierarchyComponent
 */
@Component({
  selector: 'sc-hierarchy',
  standalone: true,
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CommonIconModule],
})
export class HierarchyComponent implements OnDestroy {
  /**
   * The reference to the hierarchy element.
   * @type {ElementRef}
   * @memberof HierarchyComponent
   */
  @ViewChild('hierarchy', { read: ElementRef })
  public hierarchy!: ElementRef;

  /**
   * The top level node.
   * @type {HierarchyNodeInterface}
   * @memberof HierarchyComponent
   */
  @Input()
  topLevelNode!: HierarchyNodeInterface;

  /**
   * The current node level we are on.
   * @type {HierarchyNodeInterface}
   * @memberof HierarchyComponent
   */
  @Input()
  currentNode!: HierarchyNodeInterface;

  /**
   * The event triggered when an node is selected.
   * @memberof HierarchyComponent
   */
  @Output() selected = new EventEmitter<{ id: string; name: string }>();

  /**
   * The event triggered when we want to get and set a new current node.
   * @memberof HierarchyComponent
   */
  @Output() requestNewCurrentNode = new EventEmitter<string>();

  /**
   * Emit an event when the component is destroyed.
   * @memberof HierarchyComponent
   */
  @Output() destroyed = new EventEmitter();

  /**
   * The path taken through the hierarchy nodes to get to the selected value.
   * The path is represented as an array of node IDs.
   * @type {EventEmitter<string[]>}
   * @memberof HierarchyComponent
   */
  @Output() hierarchyPath: EventEmitter<string[]> = new EventEmitter();

  /**
   * Keep track of all the nodes that have been navigated down through.
   * @type {string[]}
   * @memberof HierarchyComponent
   */
  previousNodes: string[] = [];

  trackByFunction = HierarchyComponent.trackByFn;

  /**
   * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
   * The function takes the iteration index and item ID, and in this case Angular will track changes by the item.id
   * @static
   * @param {number} _ Index number
   * @param {HierarchyNodeInterface} hierarchyNode A node in an Iterable
   * @return {string} Value to track the Angular looped item by.
   * @memberof HierarchyComponent
   */
  static trackByFn(_: number, hierarchyNode: HierarchyNodeInterface): string {
    return hierarchyNode.id;
  }

  /**
   * If we want to go up a level prevent any further click events (in dropdown-hierarchy component prevent document:click toggling the display of it)
   * then remove and pass the previous node ID up to parent to request it's data.
   * @param {MouseEvent} [$event] The mouse click event if triggered via a click.
   * @memberof HierarchyComponent
   */
  public goToPreviousNode($event?: MouseEvent): void {
    $event?.preventDefault();
    $event?.stopPropagation();
    this.requestNewCurrentNode.emit(this.previousNodes.pop());
  }

  /**
   * When selecting a new hierarchy node, mark it is as selected for the UI and then emit the selected node if it is selectable or
   * Keep track of it's ID and emit an event to the parent to request the new node that should become the currentNode.
   * @param {HierarchyNodeInterface} selectedNode The hierarchy node that has been selected.
   * @param {MouseEvent} [$event] A mouse click event.
   * @memberof HierarchyComponent
   */
  public selectNode(selectedNode: HierarchyNodeInterface, $event?: MouseEvent): void {
    if (selectedNode.isSelectable) {
      this.updateSelectedNode(selectedNode);
      this.selected.emit({ id: selectedNode.id, name: selectedNode.name });
      this.hierarchyPath.emit(this.previousNodes);
    } else {
      // Prevent default mouse event which would cause the dropdown to close when used in the hierarchy dropdown component.
      $event?.preventDefault();
      $event?.stopPropagation();
      this.previousNodes.push(this.currentNode.id);
      this.requestNewCurrentNode.emit(selectedNode.id);
    }
  }

  /**
   * Update the node that is 'selected' in the current nodes.
   * @private
   * @param {HierarchyNodeInterface} selectedNode The hierarchy node that has been selected.
   * @memberof HierarchyComponent
   */
  private updateSelectedNode(selectedNode: HierarchyNodeInterface): void {
    this.currentNode.nodes = this.currentNode.nodes?.reduce((arr: HierarchyNodeInterface[], node) => {
      // eslint-disable-next-line no-param-reassign
      node.selected = selectedNode.id === node.id;
      arr.push(node);
      return arr;
    }, []);
  }

  /**
   * When the component is destroyed.
   * Then emit an event up so the current node can be reset.
   * @memberof HierarchyComponent
   */
  ngOnDestroy(): void {
    this.destroyed.emit();
  }
}
