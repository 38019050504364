import { LinkedEntityTypeApiInterface } from '@surecloud/api-types';
import {
  EntityAttributeRulTemporalUnitTypeEnum,
  EntityAttributeRuleConditionTypeEnum,
  EntityAttributeRuleTemporalOperatorTypeEnum,
  EntityAttributeRuleValueTypeEnum,
  EntityAttributeTypeEnum,
  EntityAttributeUserModeTypeEnum,
  makeHumanReadableZodErrors,
  roleZodSchema,
} from '@surecloud/common';
import { z } from 'zod';
import { questionSetZodSchema } from '@surecloud/question-set-state';
import { EntityAttributeFormatTypesEnum } from '../../+state/models/format/format.model.enum';

/**
 * Normalise Get Entities Validation Constants
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetEntitiesService
 */

/**
 * Linked Entity Tag Zod schema.
 */
export const linkedEntityTagZodSchema = z.object({
  linkedEntityId: z.string(),
  entityId: z.string(),
  tagId: z.string(),
  label: z.string().nullable(),
});

/**
 * Entity Zod schema
 */
export const entityZodSchema = z.object({
  entityId: z.string(),
  name: z.string().nullable(),
  created: z.string(),
  description: z.string().nullable().optional(),
});

/**
 * Entity feature Zod enums
 */
export const linkedEntityTypeEnum = z.nativeEnum(LinkedEntityTypeApiInterface);
export const attributeTypeZodEnum = z.nativeEnum(EntityAttributeTypeEnum);
export const attributeRuleConditionTypeZodEnum = z.nativeEnum(EntityAttributeRuleConditionTypeEnum);
export const attributeRuleValueTypeZodEnum = z.nativeEnum(EntityAttributeRuleValueTypeEnum);
export const attributeFormatTypeZodEnum = z.nativeEnum(EntityAttributeFormatTypesEnum);
export const attributeUserModeTypeEnum = z.nativeEnum(EntityAttributeUserModeTypeEnum);
export const attributeRulePeriodTypeZodEnum = z.nativeEnum(EntityAttributeRulTemporalUnitTypeEnum);
export const attributeRuleTemporalTypeEnum = z.nativeEnum(EntityAttributeRuleTemporalOperatorTypeEnum);

/**
 * Linked Entity Zod schema.
 */
export const linkedEntityZodSchema = z.object({
  linkedEntityId: z.string(),
  entityId: z.string(),
  type: linkedEntityTypeEnum,
});

/**
 * Used by the Date attribute where Number is not a valid value
 */
export const attributeRuleMinusNumberValueTypeZodEnum = z
  .nativeEnum(EntityAttributeRuleValueTypeEnum)
  .refine(
    (value): value is Exclude<EntityAttributeRuleValueTypeEnum, EntityAttributeRuleValueTypeEnum.Number> =>
      value !== EntityAttributeRuleValueTypeEnum.Number
  );

/**
 * Entity attribute validation rule Zod schema.
 */
export const attributeRuleZodSchema = z.union([
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.GreaterThan),
    valueType: z.literal(attributeRuleValueTypeZodEnum.enum.Attribute),
    linkedAttributeId: z.string().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.LessThan),
    valueType: z.literal(attributeRuleValueTypeZodEnum.enum.Attribute),
    linkedAttributeId: z.string().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.GreaterThan),
    valueType: z.literal(attributeRuleValueTypeZodEnum.enum.Number),
    limit: z.number().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.LessThan),
    valueType: z.literal(attributeRuleValueTypeZodEnum.enum.Number),
    limit: z.number().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.MaximumCharacterLimit),
    valueType: z.literal(attributeRuleValueTypeZodEnum.enum.Number),
    limit: z.number().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.MinimumCharacterLimit),
    valueType: z.literal(attributeRuleValueTypeZodEnum.enum.Number),
    limit: z.number().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.Unique),
    valueType: z.literal(null),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.Before),
    temporalUnit: attributeRulePeriodTypeZodEnum.nullable(),
    temporalOperator: attributeRuleTemporalTypeEnum.nullable(),
    linkedAttributeId: z.string().nullable(),
    valueType: attributeRuleMinusNumberValueTypeZodEnum,
    amount: z.number().nullable(),
    date: z.string().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(attributeRuleConditionTypeZodEnum.enum.After),
    temporalUnit: attributeRulePeriodTypeZodEnum.nullable(),
    temporalOperator: attributeRuleTemporalTypeEnum.nullable(),
    linkedAttributeId: z.string().nullable(),
    valueType: attributeRuleMinusNumberValueTypeZodEnum,
    amount: z.number().nullable(),
    date: z.string().nullable(),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    ruleId: z.string(),
    conditionType: z.literal(null),
    valueType: z.literal(null),
  }),
]);

/**
 * Entity attribute format Zof schema.
 */
export const attributeFormatZodSchema = z.discriminatedUnion('type', [
  z.object({
    attributeId: z.string(),
    currencyCode: z.string().nullable(),
    entityId: z.string(),
    formatId: z.string(),
    type: z.literal(attributeFormatTypeZodEnum.enum.Currency),
  }),
  z.object({
    attributeId: z.string(),
    decimalPlaces: z.number().nullable(),
    entityId: z.string(),
    formatId: z.string(),
    type: z.literal(attributeFormatTypeZodEnum.enum.DecimalNumber),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    formatId: z.string(),
    type: z.literal(attributeFormatTypeZodEnum.enum.Percentage),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    formatId: z.string(),
    type: z.literal(attributeFormatTypeZodEnum.enum.WholeNumber),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    formatId: z.string(),
    type: z.literal(null),
  }),
]);

/**
 * Entity permission Zod schema.
 */
export const permissionEntityZodSchema = z.object({
  entityId: z.string(),
  entityPermissionId: z.string(),
  createPermission: z.boolean(),
  readPermission: z.boolean(),
  updatePermission: z.boolean(),
  deletePermission: z.boolean(),
  role: roleZodSchema.optional().nullable(),
});

/**
 * Entity Attribute permission Zod schema.
 */
export const permissionAttributeZodSchema = z.object({
  entityId: z.string(),
  attributeId: z.string(),
  entityAttributePermissionId: z.string(),
  readPermission: z.boolean(),
  updatePermission: z.boolean(),
  role: roleZodSchema.optional().nullable(),
});

/**
 * Entity attribute Zod schema.
 */
export const attributeZodSchema = z.discriminatedUnion('type', [
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    trendAnalysis: z.boolean().optional().nullable(),
    trendDirection: z.string().optional().nullable(),
    formula: z.string().optional().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Calculation),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Context),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Date),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Document),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Email),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Hierarchy),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    calculation: z.string().optional().nullable(),
    calculated: z.boolean().optional().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Name),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Number),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    multiple: z.boolean().optional().nullable(),
    extendable: z.boolean().optional().nullable(),
    optionListId: z.string().optional().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.OptionList),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Owner),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    prefix: z.string().optional().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Sequence),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.State),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    large: z.boolean().optional().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Text),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Toggle),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.Url),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    userMode: attributeUserModeTypeEnum.optional().nullable(),
    multiple: z.boolean().optional().nullable(),
    type: z.literal(attributeTypeZodEnum.enum.User),
  }),
  z.object({
    attributeId: z.string(),
    entityId: z.string(),
    name: z.string().nullable(),
    guidanceText: z.string().nullable(),
    type: z.literal(null),
  }),
]);

export const entityListZodSchema = z.array(entityZodSchema);

/**
 * Entity normalised list Zod schema.
 */
export const normalisedEntityListZodSchema = z.object({
  entities: entityListZodSchema,
  attributes: z.array(attributeZodSchema),
  rules: z.array(attributeRuleZodSchema),
  formats: z.array(attributeFormatZodSchema),
  permissions: z.array(permissionEntityZodSchema),
  attributePermissions: z.array(permissionAttributeZodSchema),
  links: z.array(linkedEntityZodSchema).optional(),
  tags: z.array(linkedEntityTagZodSchema).optional(),
  questionSets: z.array(questionSetZodSchema).optional(),
});

export type NormalisedEntityList = z.infer<typeof normalisedEntityListZodSchema>;

export const isNormalisedEntityListGuard = (map: unknown): map is NormalisedEntityList =>
  normalisedEntityListZodSchema.safeParse(map).success;

export const getNormalisedEntityListError = (map: unknown): string => {
  const zodResult = normalisedEntityListZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};

export type NormalisedEntitySummaryList = z.infer<typeof entityListZodSchema>;

export const isNormalisedEntitySummaryListGuard = (map: unknown): map is NormalisedEntitySummaryList =>
  entityListZodSchema.safeParse(map).success;

export const getNormalisedEntitySummaryListError = (map: unknown): string => {
  const zodResult = entityListZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};
