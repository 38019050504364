import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ControlValueAccessorConnector } from '../../utils/classes/control-value-accessor';
import {
  RendererAllComponentUnion,
  RendererFormControlComponentUnion,
} from '../component-renderer-component.interface';
import { RendererControlConfigUnion } from '../component-renderer-config.interface';
import { isRendererFormControlComponentRef, isRendererFormControlConfig } from '../component-renderer.guards';

/**
 * Dynamically creates a component with a form control
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererControlConfigUnion | undefined} componentData - Dynamic component data
 * @param {Type<RendererAllComponentUnion>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<RendererFormControlComponentUnion> | undefined} - Dynamic Component
 */
export const formControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererControlConfigUnion | undefined,
  component: Type<RendererAllComponentUnion>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = false
): ComponentRef<RendererFormControlComponentUnion> | undefined => {
  if (!componentData) return undefined;
  if (!isRendererFormControlConfig(componentData)) return undefined;

  const { label, placeholder, guidanceText } = componentData;
  const componentRef = viewContainerRef.createComponent(component);

  if (component.prototype instanceof ControlValueAccessorConnector) {
    (componentRef as ComponentRef<RendererFormControlComponentUnion>).instance.formControl = control?.group.controls[
      control.controlId
    ] as FormControl;
  }

  if (!isRendererFormControlComponentRef(componentRef)) return undefined;

  componentRef.instance.description = guidanceText || '';
  componentRef.instance.label = label || '';
  componentRef.instance.placeholder = placeholder || '';
  componentRef.instance.readonly = readonly;

  if (!control) return componentRef;

  componentRef.instance.controlName = control.controlId;
  componentRef.instance.parentFormGroup = control.group;

  return componentRef;
};
