import { normalize, schema } from 'normalizr';
import {
  NormalisationOutputStrategyInterface,
  TypedMapInterface,
  UnknownMapInterface,
} from './normalise-class.interface';

/**
 * A class of methods for flattening API data into a suitable format that can be passed
 * to NgRx Entity for simple state management.
 *
 * @export
 * @class NormaliseService
 */
export class Normalise {
  /**
   * The primary method to call to flatten your API data.
   * Various configs need to be passed: data array, schema
   * and output processing strategies.
   *
   * To flatten the nested entities we first normalise them,
   * then with post-processing, we convert them back to arrays
   * in preparation for the @ngrx/entity library.
   *
   * @static
   * @template T
   * @param {T[]} data - Any type of data array that need to be process, must match the schema provided.
   * @param {schema.Entity<unknown>[]} normalizationSchema - defined schema using the normalize library schema builder helpers.
   * @param {NormalisationOutputStrategyInterface[]} customOutputStrategies - define how to structure and process the output data.
   * @return {*}  {UnknownMapInterface}
   * @memberof NormaliseService
   */
  static flatten<T>(
    data: T[],
    normalizationSchema: schema.Entity<unknown>[],
    customOutputStrategies: NormalisationOutputStrategyInterface[]
  ): UnknownMapInterface {
    if (customOutputStrategies.length === 0)
      throw Error('Coding Error, You must provide output strategies to receive data back.');
    const { entities: normalisedData } = normalize(data, normalizationSchema);
    return Normalise.customiseOutput(normalisedData, customOutputStrategies);
  }

  /**
   * Customise the output of the normalisation processing. This will at the very least
   * convert every entity key you pass, to an array.
   *
   * Additionally you can process each item in the entity list to reformat as needed.
   *
   * @static
   * @param {UnknownMapInterface} dataMap - the map of entities returned by normalize function.
   * @param {NormalisationOutputStrategyInterface[]} customOutputStrategies - define structue and processing on outputed data.
   * @return {*} {UnknownMapInterface}
   * @memberof NormaliseService
   */
  static customiseOutput(
    dataMap: UnknownMapInterface,
    customOutputStrategies: NormalisationOutputStrategyInterface[]
  ): UnknownMapInterface {
    const strategyReducer = Normalise.getOutputStrategyReducer(dataMap);
    return customOutputStrategies.reduce<UnknownMapInterface>(strategyReducer, {});
  }

  /**
   * Get the reducer function for processing the output strategies.
   *
   * @static
   * @param {UnknownMapInterface} dataMap - the map of entities returned from the normalize function
   * @return {*}  {(accumulatedMap: UnknownMapInterface, strategy: NormalisationOutputStrategyInterface) => UnknownMapInterface}
   * @memberof NormaliseService
   */
  static getOutputStrategyReducer(
    dataMap: UnknownMapInterface
  ): (accumulatedMap: UnknownMapInterface, strategy: NormalisationOutputStrategyInterface) => UnknownMapInterface {
    const dataMapHasValues = Object.keys(dataMap).length > 0;
    if (dataMapHasValues) {
      return function dataMapHasValuesReducer(
        accumulatedMap: UnknownMapInterface,
        strategy: NormalisationOutputStrategyInterface
      ): UnknownMapInterface {
        const { key, process } = strategy;
        const value = dataMap[key] as UnknownMapInterface;
        const newMap = { ...accumulatedMap };
        newMap[key] = Normalise.toArray(value, process);
        return newMap;
      };
    }

    // No dataMap content?
    // Still produce the map structure as specified.
    return function dataMapHasNoValuesReducer(
      accumulatedMap: UnknownMapInterface,
      strategy: NormalisationOutputStrategyInterface
    ): UnknownMapInterface {
      const { key } = strategy;
      const newMap = { ...accumulatedMap };
      newMap[key] = [];
      return newMap;
    };
  }

  /**
   * Convert the entityMap (as map of normalised entities) to an Array.
   * If a process function is provided, then run it against each entity in
   * the Array.
   *
   * @static
   * @template T
   * @param {TypedMapInterface<T>} entityMap - A map for a single entity type
   * @param {(value: T) => unknown} [process] - a function to process each entity in the map.
   * @return {unknown[]} The array entities.
   * @memberof NormaliseService
   */
  static toArray<T>(entityMap: TypedMapInterface<T>, process?: (value: T) => unknown): unknown[] {
    if (!entityMap) return [];
    return Object.keys(entityMap).map((key) => (process ? process(entityMap[key]) : entityMap[key]));
  }

  /**
   * Create a reusuable function for the common use case of removing a
   * property from the output, such as removing a nested entity array.
   * Meant to be used in creating a NormalisationOutputStrategyInterface.
   *
   * @static
   * @param {string} name - The name of the object property to remove.
   * @return {(value: UnknownMapInterface) => UnknownMapInterface} The created createRemovePropertyFn function.
   * @memberof NormaliseService
   */
  static createRemovePropertyFn(name: string): (value: UnknownMapInterface) => UnknownMapInterface {
    return (value: UnknownMapInterface) => {
      const newValue: UnknownMapInterface = { ...value };
      delete newValue[name];
      return newValue;
    };
  }

  /**
   * Create a reusuable function for the common use case of removing an array
   * properties from the output, such as removing a nested entity array.
   * Meant to be used in creating a NormalisationOutputStrategyInterface.
   *
   * @static
   * @param {string[]} names Array of properties to be removed.
   * @return {(value: UnknownMapInterface) => UnknownMapInterface} The created createRemovePropertyFn function
   * @memberof Normalise
   */
  static createRemovePropertiesFn(names: string[]): (value: UnknownMapInterface) => UnknownMapInterface {
    return (value: UnknownMapInterface) => {
      const newValue: UnknownMapInterface = { ...value };
      names.forEach((name) => delete newValue[name]);
      return newValue;
    };
  }

  /**
   * Create a function for adding a parent id to an entity.
   * Menat to be used to create a "process strategy" in the
   * "normalize" schema defintion.
   *
   * @static
   * @param {string} newKey - The name of the key to assign the parentdId to.
   * @param {string} [parentIdKey='id'] - the parentId key (if not id)
   * @return {*}  {(value: UnknownMapInterface, parent: UnknownMapInterface) => UnknownMapInterface}
   * @memberof NormaliseService
   */
  static createAddParentIdProcessStrategy(
    newKey: string,
    parentIdKey = 'id'
  ): (value: UnknownMapInterface, parent: UnknownMapInterface) => UnknownMapInterface {
    return (value: UnknownMapInterface, parent: UnknownMapInterface) => {
      const newValue = { ...value };
      newValue[newKey] = parent[parentIdKey];
      return newValue;
    };
  }
}
