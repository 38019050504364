import { FileUploadInterface } from '../interfaces/file-upload.interface';
import { isFileGuard, isFileInterfaceGuard } from './is-file.guard';

/**
 * Type Guard for File Arrays
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfFilesOrEmptyGuard = (value: unknown): value is File[] =>
  Array.isArray(value) && value.every((file) => isFileGuard(file));

/**
 * Type Guard for FileUploadInterface Arrays
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfFilesUploadInterfaceOrEmptyGuard = (value: unknown): value is FileUploadInterface[] =>
  Array.isArray(value) && value.every((file) => isFileInterfaceGuard(file));

/**
 * Type Guard for FileUploadInterface and File Arrays
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfFilesOrFileInterfacesOrEmptyGuard = (
  value: unknown
): value is (FileUploadInterface | File)[] =>
  Array.isArray(value) &&
  value.every(
    (item) => isFileGuard(item) || isFileInterfaceGuard(item)
  );