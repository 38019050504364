<ng-container [formGroup]="parentFormGroup">
  <sc-input-text
    class="sc-input-url"
    [controlName]="controlName"
    [description]="description"
    [label]="label"
    [readonly]="readonly"
    [placeholder]="localPlaceholder"
    [enableSuccessValidationStyle]="enableSuccessValidationStyle"
    prefix="url"
    [marginBottom]="marginBottom"
    [clearButton]="true"
    [staticWidth]="staticWidth"
    (blurred)="blurred.emit()"></sc-input-text>
</ng-container>
