<ng-container *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
  <kendo-formfield
    showErrors="initial"
    [ngClass]="{ 'sc-mb-20': marginBottom }"
    class="sc-select-dropdown sc-select-dropdown--colour-palette">
    <kendo-label
      [text]="label"
      class="sc-label flex-column"
      [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
      <kendo-dropdownlist
        #dropdownlist
        [formControlName]="controlName"
        [data]="colours"
        size="none"
        [style.width.px]="staticWidth"
        [ngClass]="{
          'sc-input--readonly': readonly && !parentFormGroup.controls[controlName].disabled
        }"
        [textField]="optionTextProperty"
        [valueField]="optionValueProperty"
        [valuePrimitive]="valuePrimitive"
        [readonly]="readonly"
        [popupSettings]="{
          appendTo: viewContainerRef,
          popupClass: 'sc-select-dropdown__popup sc-select-dropdown__popup--colour-palette sc-scrollbar'
        }">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <div class="flex sc-colour-palette__selected_colours" *ngIf="dataItem && dataItem.colours">
            <div
              *ngFor="let colour of dataItem.colours; trackBy: trackByFunction"
              [ngStyle]="{ 'background-color': colour }"
              class="sc-colour-palette__colour-block"></div>
          </div>
        </ng-template>

        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <div
            *ngFor="let colour of dataItem.colours; trackBy: trackByFunction"
            [ngStyle]="{ 'background-color': colour }"
            class="sc-colour-palette__colour-block"></div>
        </ng-template>
      </kendo-dropdownlist>
      <div class="flex">
        <div
          i18n
          *ngIf="enableSuccessValidationStyle && parentFormGroup.controls[controlName].valid"
          class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
          </div>
          Success
        </div>
        <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="red-300" class="sc-mr-4"></sc-icon>
          </div>
          Incomplete
        </div>
        <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
      </div>
    </kendo-label>
  </kendo-formfield>
</ng-container>
