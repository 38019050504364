import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import {
  GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION,
  makeAttributeConfigComponent,
  makeAttributeConfigSectionId,
} from '../global-attribute-config.model';

/**
 * Makes the global text attribute configuration
 */
export const GLOBAL_TEXT_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Text,
  key: EntityAttributeTypeEnum.Text,
  type: EntityAttributeTypeEnum.Text,
  label: $localize`Text`,
  uniqueSections: [
    {
      sectionComponent: makeAttributeConfigComponent('Text'),
      sectionId: makeAttributeConfigSectionId('Text'),
      sectionName: $localize`Text Properties`,
    },
    GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION,
  ],
});
