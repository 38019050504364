import { HttpErrorResponse } from '@angular/common/http';
import { HttpErrorResponseSchema } from '../models/http.model';

/**
 * Type guard to check if the object type is HttpErrorResponse
 *
 * @export
 * @param {unknown} value Check if this value is an error.
 * @return {value is HttpErrorResponse} Is the passed value an error?
 */
export function isHttpErrorResponse(value: unknown): value is HttpErrorResponse {
  const error = HttpErrorResponseSchema.safeParse(value);
  return error.success;
}
