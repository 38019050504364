<div class="block" [ngClass]="{ 'sc-mb-20': marginBottom }">
  <kendo-label [text]="label" class="sc-label flex-column">
    <ul class="flex align-items-center sc-px-12 sc-py-10">
      <li class="calculation__value" i18n>{{ value }}</li>
      <li
        *ngIf="directionData"
        class="calculation__correlation flex align-items-center sc-ml-12"
        [ngClass]="{
          'sc-text-success': correlation === calcConsts.CALC_CORRELATION_POSITIVE,
          'sc-text-error': correlation === calcConsts.CALC_CORRELATION_NEGATIVE,
          'sc-text-info': correlation === calcConsts.CALC_CORRELATION_NONE
        }"
        i18n>
        <sc-icon
          *ngIf="trendAnalysis"
          key="arrow-up"
          class="sc-mr-4"
          [ngClass]="{
            'rotate-90': directionData.direction === calcConsts.CALC_DIRECTION_RIGHT,
            'rotate-180': directionData.direction === calcConsts.CALC_DIRECTION_DOWN
          }"
          size="medium"
          [height]="11"
          [width]="10"></sc-icon
        >{{ directionData.text }}
      </li>
    </ul>
    <div class="flex">
      <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
    </div>
  </kendo-label>
</div>
