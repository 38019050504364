/**
 * Route names for the Auth area of the app
 *
 * @export
 * @enum {number}
 */
export enum AuthRoutesEnum {
  EnterTenant = 'enter-tenant',
  Login = 'login',
  Root = 'auth',
}
