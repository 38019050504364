import { createAction, props } from '@ngrx/store';
import { EntityAttributeFormatOptionInterface } from '../../services/format-options/format-options.service.interface';
import {
  EntityAttributeCreateFormatInterface,
  EntityAttributeFormatInterface,
  EntityAttributeFormatUpdatedInterface,
} from '../models/format/format.models';

export const EntityAttributeFormatActions = {
  /**
   * User entity attribute format event actions
   */

  cancelUpdateFormat: createAction('[Entity Attribute Page] Cancel Update Entity Attribute Format'),
  completeDeleteFormat: createAction(
    '[Entity Attribute Page] Complete Delete Entity Attribute Format',
    props<{ format: EntityAttributeFormatInterface }>()
  ),
  completeUpdateFormat: createAction(
    '[Entity Attribute Page] Complete Update Entity Attribute Format',
    props<{ format: EntityAttributeFormatInterface }>()
  ),
  createFormat: createAction(
    '[Entity Attribute Page] Create Entity Attribute Format',
    props<{ format: EntityAttributeCreateFormatInterface }>()
  ),
  startDeleteFormat: createAction(
    '[Entity Attribute Page] Start Delete Entity Attribute Format',
    props<{ format: EntityAttributeFormatInterface }>()
  ),
  startUpdateFormat: createAction(
    '[Entity Attribute Page] Start Update Format',
    props<{ format: EntityAttributeFormatInterface }>()
  ),

  /**
   * API entity attribute format event actions
   */
  createFormatFailure: createAction('[Entity/API] Create Entity Attribute Format Failure', props<{ error: string }>()),
  createFormatSuccess: createAction(
    '[Entity/API] Create Entity Attribute Format Success',
    props<{ format: EntityAttributeFormatInterface }>()
  ),
  deleteFormatFailure: createAction('[Entity/API] Delete Entity Attribute Format Failure', props<{ error: string }>()),
  deleteFormatSuccess: createAction(
    '[Entity/API] Delete Entity Attribute Format Success',
    props<EntityAttributeFormatUpdatedInterface>()
  ),
  readFormatListSuccess: createAction(
    '[Entity/API] Read Entity Attribute Format List Success',
    props<{ formatList: EntityAttributeFormatInterface[] }>()
  ),
  readFormatOptionsSuccess: createAction(
    '[Entity Attribute Page] Update Entity Attribute Format Options',
    props<{
      formatOptions: EntityAttributeFormatOptionInterface[];
    }>()
  ),
  updateFormatFailure: createAction('[Entity/API] Update Entity Attribute Format Failure', props<{ error: string }>()),
  updateFormatSuccess: createAction(
    '[Entity/API] Update Entity Attribute Format Success',
    props<{ format: EntityAttributeFormatInterface }>()
  ),
};
