import { ButtonSize } from '@progress/kendo-angular-buttons';

export const K_BUTTON_PREFIX = 'k-button';
export const K_BUTTON_SOLID_PREFIX = `${K_BUTTON_PREFIX}-solid`;
export const K_BUTTON_FLAT_PREFIX = `${K_BUTTON_PREFIX}-flat`;
export const K_BUTTON_OUTLINE_PREFIX = `${K_BUTTON_PREFIX}-outline`;

export const buttonSizeToClassName = new Map<ButtonSize, string>([
  ['none', 'none'],
  ['small', 'sm'],
  ['medium', 'md'],
  ['large', 'lg'],
]);

export const buttonFillModeToClassName = new Map<ScButtonFillMode, string>([
  ['fill', K_BUTTON_SOLID_PREFIX],
  ['outline', K_BUTTON_OUTLINE_PREFIX],
  ['text', K_BUTTON_FLAT_PREFIX],
]);

export type ScButtonThemeColour =
  | 'brand'
  | 'primary'
  | 'green'
  | 'red'
  | 'white'
  | 'outline'
  | 'grey-800'
  | 'global-nav'
  | 'global-nav--design-v2';

export type ScButtonBorder = 'default' | 'dashed';

export type ScButtonFillMode = 'fill' | 'outline' | 'text';

export type ScIconButtonShape = 'circular' | 'rounded';

export type ScButtonSize = 'small' | 'medium' | 'large' | 'x-large';
