import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { ToggleComponent } from '../toggle/toggle.component';
/**
 * Surecloud labelled toggle Component that wraps the toggle component.
 *
 * @export
 * @class LabelledToggleComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-labelled-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule, ToggleComponent, ReactiveFormsModule, E2eHookDirective, LabelModule],
  styleUrls: ['./labelled-toggle.component.scss'],
  templateUrl: './labelled-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelledToggleComponent implements OnInit {
  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof LabelledToggleComponent
   */
  @Input() controlName = '';

  /**
   * Set the text to label.
   *
   * @memberof LabelledToggleComponent
   */
  @Input() label?: string;

  /**
   * Set the text to display when the toggle is 'on'.
   *
   * @memberof LabelledToggleComponent
   */
  @Input() onLabel = '';

  /**
   * Set the text to display when the toggle is 'off'.
   *
   * @memberof LabelledToggleComponent
   */
  @Input() offLabel = '';

  /**
   * The test hook to pass in.
   *
   * @memberof LabelledToggleComponent
   */
  @Input()
  testhook!: string;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   *
   * @type {FormGroup}
   * @memberof LabelledToggleComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Creates an instance of LabelledToggleComponent.
   *
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof LabelledToggleComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
