import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject, debounceTime, takeUntil } from 'rxjs';
import { TextButtonComponent } from '../button/text-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { NavigationListComponent } from '../navigation-list/navigation-list.component';
import { NavigationListItemInterface } from '../navigation-list/navigation-list.component.interface';
import { PageFooterComponent } from '../page-footer/page-footer.component';
import { SectionComponent } from '../section/section.component';
import { SidebarLayoutComponent } from '../sidebar-layout/sidebar-layout.component';
import {
  SCROLL_DEBOUNCE_TIME,
  SCROLL_MINIMUM_POSITION,
  SCROLL_OFFSET_AMOUNT,
} from './page-navigatable.component.constants';

/**
 * Scrollable page with left hand navigation that will scroll the main content region to the active sc-section.
 * Whatever ID you give to the navigation list items Input ensure to add this as IDs to your sc-sections within the main content region.
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 * @export
 * @class PageNavigatableComponent
 */
@Component({
  standalone: true,
  selector: 'sc-page-navigatable',
  templateUrl: './page-navigatable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SidebarLayoutComponent,
    NavigationListComponent,
    CommonIconModule,
    PageFooterComponent,
    TextButtonComponent,
  ],
})
export class PageNavigatableComponent implements AfterViewInit, OnChanges, OnDestroy {
  /**
   * Child SidebarLayoutComponent so that we can access the scrollable regions within.
   * @type {(SidebarLayoutComponent)}
   * @memberof PageNavigatableComponent
   */
  @ViewChild(SidebarLayoutComponent) private sidebar: SidebarLayoutComponent | undefined;

  /**
   * Query list of sc-sections so that we can evaluate which one is selected on scroll.
   * @private
   * @type {QueryList<ElementRef<HTMLElement>>}
   * @memberof PageNavigatableComponent
   */
  @ContentChildren(SectionComponent, { descendants: true, read: ElementRef }) private sections:
    | QueryList<ElementRef<HTMLElement>>
    | undefined;

  /**
   * When the component is destroyed.
   * Then emit so that other observables may tear down.
   * @type {Subject<boolean>}
   * @memberof PageNavigatableComponent
   */
  private destroyed$: Subject<boolean> = new Subject();

  /**
   * The active section ID.
   * @memberof PageNavigatableComponent
   */
  activeNavigationItem$ = new BehaviorSubject('');

  /**
   * Sidebar navigation list items.
   * @type {NavigationListItemInterface[]}
   * @memberof PageNavigatableComponent
   */
  @Input() navigationItems: NavigationListItemInterface[] | null = null;

  /**
   * Should the sidebar be example by default.
   * @memberof PageNavigatableComponent
   */
  @Input() expanded = true;

  /**
   * Outputs the active section ID.
   * @type {EventEmitter<string>}
   * @memberof PageNavigatableComponent
   */
  @Output() activeSection: EventEmitter<string> = new EventEmitter();

  /**
   * @type { boolean } Determines to show "Back to top" button
   * @memberof PageNavigatableComponent
   */
  isBackToTopButtonVisible = false;

  /**
   * When the view has rendered.
   * Then listen to the scroll events on the main content region.
   * And update the active navigation item ID.
   * @memberof PageNavigatableComponent
   */
  ngAfterViewInit(): void {
    if (this.sidebar?.mainScrollRegion) {
      this.sidebar.mainScrollRegion.scrolled
        .pipe(debounceTime(SCROLL_DEBOUNCE_TIME), takeUntil(this.destroyed$))
        .subscribe(({ target }) => this.findActiveSection(target));
    }
  }

  /**
   * When the navigation items change.
   * And an active navigation item has not been set.
   * Then set the first navigation item as active.
   * @param {SimpleChanges} changes The Input changes for the component.
   * @memberof PageNavigatableComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const navigationItems: NavigationListItemInterface[] = changes['navigationItems'].currentValue;

    if (navigationItems?.length && !this.activeNavigationItem$.value) {
      this.setActiveSection(navigationItems[0].id);
    }
  }

  /**
   * When a user has left the UserEditComponent.
   * Then complete the active navigation item observable.
   * @memberof PageNavigatableComponent
   */
  ngOnDestroy(): void {
    this.activeNavigationItem$.complete();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * When a user or we have programmatically scrolled to a position within the main content.
   * Find the sc-section in view.
   * And set the active navigation item to the ID of the sc-section in view.
   * @private
   * @param {(EventTarget | null)} target The HTML element the scroll event happened on. In this case the main content region.
   * @memberof PageNavigatableComponent
   */
  private findActiveSection(target: EventTarget | null): void {
    let sectionId: string | null = null;
    let hasUserScrolledToBottom = false;

    if (this.sections?.length && target && target instanceof Element) {
      // Get the current scrolled position on the main content region.
      const { scrollTop, scrollHeight, clientHeight } = target;
      const scrollPosition = Math.ceil(scrollTop);
      // if the container has a scroll and the user has scrolled to the bottom of the page
      hasUserScrolledToBottom = scrollHeight !== clientHeight && scrollHeight - clientHeight === scrollPosition;
      this.isBackToTopButtonVisible = scrollPosition > SCROLL_MINIMUM_POSITION;

      // Set the first section as active by default.
      const [firstSection] = this.sections;
      const lastSection = this.sections.last;

      sectionId = hasUserScrolledToBottom ? lastSection.nativeElement.id : firstSection.nativeElement.id;

      // Loop through the sections and see which section is closest to the current scrolled position of the main content region.
      this.sections.forEach(({ nativeElement }) => {
        const condition = nativeElement.offsetTop - SCROLL_OFFSET_AMOUNT <= scrollPosition;

        // If our condition is met - set the section ID.
        if (condition && !hasUserScrolledToBottom) {
          sectionId = nativeElement.id;
        }
      });
    }

    if (sectionId) {
      this.setActiveSection(sectionId);
    }
  }

  /**
   * When called.
   * Then update the active navigation item with the section ID.
   * @private
   * @param {string} sectionId The active section ID.
   * @memberof PageNavigatableComponent
   */
  private setActiveSection(sectionId: string): void {
    this.activeNavigationItem$.next(sectionId);
    this.activeSection.emit(sectionId);
  }

  /**
   * When a section navigation item is clicked.
   * Then scroll to that section in the main content view.
   * @param {string} sectionId The section ID of the selected section.
   * @memberof PageNavigatableComponent
   */
  scrollToSection(sectionId: string): void {
    if (this.sidebar?.mainScrollRegion) {
      this.sidebar.mainScrollRegion.scrollToElement(`#${sectionId}`);
    }
  }

  /**
   * Scroll to the top of the main content region.
   * @memberof PageNavigatableComponent
   */
  scrollToTop(): void {
    if (this.sidebar) {
      this.sidebar.scrollToMainScrollRegionTop();
    }
  }
}
