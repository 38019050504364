import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RoleEffects } from './+state/effects/role.effects';
import { ROLE_FEATURE_KEY, reducer } from './+state/reducers/role.reducer';

/**
 * Role State Module.
 * @export
 * @class RoleStateModule
 */
@NgModule({
  imports: [EffectsModule.forFeature([RoleEffects]), StoreModule.forFeature(ROLE_FEATURE_KEY, reducer)],
})
export class RoleStateModule {}
