import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { trackByIndex } from '@surecloud/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TagComponent } from '../../../tag/tag.component';
import { GridTagsReadonlyInterface } from './grid-tags-readonly-renderer.interface';
import { isArrayOfTagsGuard } from './is-grid-tags-readonly.guard';

/**
 * AgGrid rendering component for rendering a readonly tags column cell.
 * The data structure of your column definition field must be of type GridTagsReadonlyInterface.
 *
 * @export
 * @class GridTagsReadonlyRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TagComponent],
  selector: 'sc-tags-readonly-renderer',
  templateUrl: './grid-tags-readonly-renderer.component.html',
})
export class GridTagsReadonlyRendererComponent implements ICellRendererAngularComp {
  /**
   * Array of tags to render.
   *
   * @type {GridTagsReadonlyInterface}
   * @memberof GridTagsReadonlyRendererComponent
   */
  tags: GridTagsReadonlyInterface = [];

  /**
   * Track by function to pass to any Angular View ngFor loops.
   *
   * @memberof GridTagsReadonlyRendererComponent
   */
  trackByIndex = trackByIndex;

  /**
   * Handles the ag init and sets the values for the tags.
   * The data structure of your column definition field must be of type GridTagsReadonlyInterface.
   *
   * @param {ICellRendererParams} params Cell renderer params
   * @memberof GridTagsReadonlyRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    const { value } = params;
    if (!value) return;

    this.tags = isArrayOfTagsGuard(value) ? value.map(({ display }) => display) : [value.display];
  }

  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridTagsReadonlyRendererComponent
   * @return {boolean} Should the cell refresh.
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
