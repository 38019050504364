import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { RecordLinkInterface, RecordLinkWithTableRecordsInterface } from '../models/record-link/record-link.model';
import { TableRecordInterface } from '../models/table-record/table-record.model';
import { RECORD_LINKS_FEATURE_KEY, recordLinksAdapter, StateInterface } from '../reducers/link-records.reducer';
import * as TableRecordSelectors from './table-record.selectors';
// Lookup the 'Record Links Entity' feature state managed by NgRx
export const getRecordLinksState = createFeatureSelector<StateInterface>(RECORD_LINKS_FEATURE_KEY);

const { selectAll, selectEntities } = recordLinksAdapter.getSelectors();

/**
 * Get all Record Links.
 */
export const getRecordLinksList = createSelector(getRecordLinksState, (state: StateInterface) => selectAll(state));

/**
 * Get the selected Record Link tab ID.
 */
export const getSelectedId = createSelector(getRecordLinksState, (state: StateInterface) => state.selectedId);

/**
 * Get the Record Links entity map from component state.
 */
export const getRecordLinksEntities = createSelector(getRecordLinksState, (state: StateInterface) =>
  selectEntities(state)
);

export const getSelectedRecordLink = createSelector(getRecordLinksEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const getSelectedRecordLinkViewerCanCreate = createSelector(getSelectedRecordLink, (selected) =>
  selected ? selected.viewerCanCreate : false
);

export const getRecordLinksForTableTabWithId = (
  tabId: string,
  isLinked: boolean
): MemoizedSelector<
  object,
  RecordLinkWithTableRecordsInterface | undefined,
  (
    s1: Dictionary<TableRecordInterface>,
    s2: Dictionary<RecordLinkInterface>
  ) => RecordLinkWithTableRecordsInterface | undefined
> =>
  createSelector(
    TableRecordSelectors.getTableRecordEntities,
    getRecordLinksEntities,
    (tableRecordEntities, recordLinksEntities) => {
      const id = isLinked ? `${tabId}.linked` : `${tabId}.linkable`;

      const recordLink = recordLinksEntities[id] ? recordLinksEntities[id] : undefined;

      if (recordLink) {
        const { recordIds, viewerCanCreate } = recordLink;

        return {
          tabId,
          isLinked,
          viewerCanCreate,
          tableRecords: recordIds
            .map((recordId) => tableRecordEntities[`${id}.${recordId}`])
            .filter((record): record is TableRecordInterface => !!record),
        };
      }
      return undefined;
    }
  );

export const getRecordDataRowForTableTabWithId = (
  tabId: string,
  isLinked: boolean,
  row: number
): MemoizedSelector<
  object,
  TableRecordInterface | undefined,
  (s1: Dictionary<TableRecordInterface>, s2: Dictionary<RecordLinkInterface>) => TableRecordInterface | undefined
> =>
  createSelector(
    TableRecordSelectors.getTableRecordEntities,
    getRecordLinksEntities,
    (tableRecordEntities, recordLinksEntities) => {
      const id = isLinked ? `${tabId}.linked` : `${tabId}.linkable`;

      const recordLinks = recordLinksEntities[id] ? recordLinksEntities[id] : undefined;

      if (recordLinks) {
        const { recordIds } = recordLinks;

        return tableRecordEntities[`${id}.${recordIds[row]}`];
      }
      return undefined;
    }
  );

export const getSelectedIdWithoutLabel = createSelector(getSelectedId, (selectedId) => {
  if (selectedId) {
    const [tabId] = selectedId.split('.');
    return tabId;
  }
  return selectedId;
});

export const getLinkableRecordsForSelectedTableTab = createSelector(
  getSelectedIdWithoutLabel,
  TableRecordSelectors.getTableRecordEntities,
  getRecordLinksEntities,
  (tabId, tableRecordEntities, recordLinksEntities) => {
    if (!tabId) return undefined;

    const id = `${tabId}.linkable`;

    const recordLink = recordLinksEntities[id] ? recordLinksEntities[id] : undefined;

    if (recordLink) {
      const { recordIds, viewerCanCreate } = recordLink;

      return {
        tabId,
        isLinked: false,
        viewerCanCreate,
        tableRecords: recordIds
          .map((recordId) => tableRecordEntities[`${id}.${recordId}`])
          .filter((record): record is TableRecordInterface => !!record),
      };
    }
    return undefined;
  }
);


export const getRecordLinkViewerCanCreateByTabId = (
  tabId: string
): MemoizedSelector<object, boolean, (s1: Dictionary<RecordLinkInterface>) => boolean> =>
  createSelector(getRecordLinksEntities, (entities) => {
    const id = `${tabId}.linked`;
    const recordLink = entities[id] ? entities[id] : undefined;
    return recordLink ? recordLink.viewerCanCreate : false;
  });
