import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CommonIconModule,
  FilePickerComponent,
  LoadingButtonComponent,
  ModalButtonsComponent,
  TextButtonComponent,
} from '@surecloud/design';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { RecordImportEffects } from './+state/effects/record-import.effects';
import { RecordImportSuccessComponent } from './components/record-import-success/record-import-success.component';
import { RecordImportComponent } from './components/record-import/record-import.component';

@NgModule({
  declarations: [RecordImportComponent, RecordImportSuccessComponent],
  imports: [
    CommonModule,
    ModalButtonsComponent,
    FilePickerComponent,
    ReactiveFormsModule,
    CommonIconModule,
    LoadingButtonComponent,
    TextButtonComponent,
  ],
  providers: [EffectsBootstrapProvider.provide([RecordImportEffects])],
})
export class RecordImportModule {}
