/**
 * Enum with the grid selection options
 * @export
 */
export enum GridRowSelectionEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

/**
 * Grid checkbox select event options
 * @export
 */
export enum GridEventEnum {
  SELECT_ALL = 'selectAll',
  SELECT_ROW = 'selectRow',
  SELECT_ROW_SINGLE_SELECT = 'selectRowSingleSelect',
  SELECTION_CHANGED = 'selectionChanged',
}

/**
 * Grid rowSelection events
 * @export
 */
export enum GridEventSourceEnum {
  API = 'api',
  API_SELECT_ALL = 'apiSelectAll',
}
