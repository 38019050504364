import { Injectable } from '@angular/core';
import {
  ImportLinkedRecordsApiInterfaceMutationService,
  ImportRecordsApiInterfaceMutationService,
} from '@surecloud/api-types';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { LinkedRecordImportInterface, RecordImportInterface } from '../../models/record-import';

/**
 * Record Import service.
 * @export
 * @class RecordImportService
 */
@Injectable({ providedIn: 'root' })
export class RecordImportService {
  /**
   * Creates an instance of RecordImportService.
   * @param {ImportRecordsApiInterfaceMutationService} importRecordsApiInterfaceMutationService The API service to import records.
   * @param {ImportLinkedRecordsApiInterfaceMutationService} importLinkedRecordsApiInterfaceMutationService The API service to import linked records.
   * @memberof RecordImportService
   */
  constructor(
    private readonly importRecordsApiInterfaceMutationService: ImportRecordsApiInterfaceMutationService,
    private readonly importLinkedRecordsApiInterfaceMutationService: ImportLinkedRecordsApiInterfaceMutationService
  ) {}

  /**
   * Import a Record
   * @param {RecordImportInterface} recordFile The record file to import.
   * @return {Observable<boolean>} The result of the import.
   * @memberof RecordImportService
   */
  importRecords(recordFile: RecordImportInterface): Observable<boolean> {
    return this.importRecordsApiInterfaceMutationService
      .mutate({ input: recordFile })
      .pipe(
        switchMap(({ data }) =>
          data?.importRecords
            ? of(data.importRecords.success)
            : throwError(() => 'No import records response data from API')
        )
      );
  }

  /**
   * Import linked records
   * @param {LinkedRecordImportInterface} recordFile The linked record file to import.
   * @return {*}  {Observable<boolean>}
   * @memberof RecordImportService
   */
  importLinkedRecords(recordFile: LinkedRecordImportInterface): Observable<boolean> {
    return this.importLinkedRecordsApiInterfaceMutationService
      .mutate({ input: recordFile })
      .pipe(
        switchMap(({ data }) =>
          data?.importLinkedRecords
            ? of(data.importLinkedRecords.success)
            : throwError(() => 'No import linked records response data from API')
        )
      );
  }
}
