import { ComponentRef } from '@angular/core';
import { EntityAttributeTypeEnum } from '@surecloud/common';
import {
  CalculationComponentInterface,
  DocumentFormControlComponentInterface,
  OptionListControlComponentInterface,
  RendererAllComponentUnion,
  RendererFormControlComponentUnion,
} from './component-renderer-component.interface';
import {
  RENDERER_CONFIG_ATTRIBUTE_TYPE,
  RendererAttributeConfigInterface,
  RendererCalculationConfigInterface,
  RendererFormControlConfigInterface,
  RendererInformationControlConfigInterface,
  RendererNoTypeControlConfigInterface,
  RendererOptionListControlConfigInterface,
  RendererQuestionControlConfigInterface,
  RendererTableControlConfigInterface,
  RendererTextControlConfigInterface,
  RendererTypeEnum,
} from './component-renderer-config.interface';

export const isRendererFormControlConfig = (
  componentConfig: unknown
): componentConfig is RendererFormControlConfigInterface =>
  Object.values(EntityAttributeTypeEnum).indexOf(
    (componentConfig as RendererFormControlConfigInterface).type as EntityAttributeTypeEnum
  ) > -1;

export const isRendererAttributeConfig = (
  componentConfig: unknown
): componentConfig is RendererAttributeConfigInterface =>
  (componentConfig as RendererAttributeConfigInterface).type === RENDERER_CONFIG_ATTRIBUTE_TYPE;

export const isRendererTableConfig = (
  componentConfig: unknown
): componentConfig is RendererTableControlConfigInterface =>
  (componentConfig as RendererTableControlConfigInterface).type === `${RendererTypeEnum.Table}`;

export const isRendererInformationConfig = (
  componentConfig: unknown
): componentConfig is RendererInformationControlConfigInterface =>
  (componentConfig as RendererInformationControlConfigInterface).type === `${RendererTypeEnum.Information}`;

export const isRendererQuestionConfig = (
  componentConfig: unknown
): componentConfig is RendererQuestionControlConfigInterface =>
  (componentConfig as RendererQuestionControlConfigInterface).type === `${RendererTypeEnum.Question}`;

export const isRendererCalculationAttributeConfig = (
  componentConfig: unknown
): componentConfig is RendererCalculationConfigInterface =>
  (componentConfig as RendererCalculationConfigInterface).type === `${RendererTypeEnum.Calculation}`;

export const isRendererOptionListConfig = (
  componentConfig: unknown
): componentConfig is RendererOptionListControlConfigInterface =>
  (componentConfig as RendererOptionListControlConfigInterface).type === `${RendererTypeEnum.OptionList}`;

export const isRendererTextConfig = (componentConfig: unknown): componentConfig is RendererTextControlConfigInterface =>
  (componentConfig as RendererTextControlConfigInterface).type === `${RendererTypeEnum.Text}`;

export const isRendererNoTypeConfig = (
  componentConfig: unknown
): componentConfig is RendererNoTypeControlConfigInterface =>
  (componentConfig as RendererNoTypeControlConfigInterface).type === `${RendererTypeEnum.NoType}`;

/**
 * Typescript guard to check if a ComponentRef<T> is RendererFormControlComponentUnion.
 *
 * @param {ComponentRef<RendererFormControlComponentUnion>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<RendererFormControlComponentUnion>.
 */
export const isRendererFormControlComponentRef = (
  componentRef: ComponentRef<RendererAllComponentUnion>
): componentRef is ComponentRef<RendererFormControlComponentUnion> =>
  (componentRef as ComponentRef<RendererFormControlComponentUnion>).instance.controlName !== undefined ||
  (componentRef as ComponentRef<RendererFormControlComponentUnion>).instance.formControl !== undefined;

/**
 * Typescript guard to check if a ComponentRef<T> is DocumentFormControlComponentInterface.
 *
 * @param {ComponentRef<RendererFormControlComponentUnion>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<DocumentFormControlComponentInterface>.
 */
export const isRendererDocumentFormControlComponentRef = (
  componentRef: ComponentRef<RendererFormControlComponentUnion>
): componentRef is ComponentRef<DocumentFormControlComponentInterface> =>
  (componentRef as ComponentRef<DocumentFormControlComponentInterface>).instance.allowedExtensions !== undefined;

/**
 * Typescript guard to check if a ComponentRef<T> is OptionListFormControlComponentInterface.
 *
 * @param {ComponentRef<RendererFormControlComponentUnion>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<OptionListControlComponentInterface>.
 */
export const isRendererOptionListFormControlComponentRef = (
  componentRef: ComponentRef<RendererAllComponentUnion>
): componentRef is ComponentRef<OptionListControlComponentInterface> =>
  Array.isArray((componentRef as ComponentRef<OptionListControlComponentInterface>).instance.options);

/**
 * Typescript guard to check if a ComponentRef<T> is CalculationComponentInterface.
 *
 * @param {ComponentRef<RendererFormControlComponentUnion>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<CalculationComponentInterface>.
 */
export const isRendererCaclulationControlComponentRef = (
  componentRef: ComponentRef<RendererAllComponentUnion>
): componentRef is ComponentRef<CalculationComponentInterface> =>
  (componentRef as ComponentRef<CalculationComponentInterface>).instance.controlName !== undefined;
