import { Injectable, Injector } from '@angular/core';
import {
  FrontendErrorCreateMutationApiInterfaceMutationService,
  FrontendErrorInputApiInterface,
} from '@surecloud/api-types';
import { LoggerService } from '@surecloud/common';
import { tap } from 'rxjs';
import { ErrorDetails } from '../model/error.models';

/**
 * Error service.
 * @export
 * @class ErrorService
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  frontendErrorMutationService: FrontendErrorCreateMutationApiInterfaceMutationService | undefined;
  /**
   * Creates an instance of ErrorService.
   * @param {Injector} injector angular injector to avoid circular dependancy on FrontendErrorCreateMutationApiInterfaceMutationService
   * @param {LoggerService} loggerService service to log to console
   * @memberof ErrorService
   */
  constructor(private injector: Injector, private loggerService: LoggerService) {}

  /**
   * returns the frontend error mutation service
   * @private
   * @return {(FrontendErrorCreateMutationApiInterfaceMutationService | undefined)} the Frontend Error Create Mutation Service
   * @memberof ErrorService
   */
  private getFrontendErrorMutationService(): FrontendErrorCreateMutationApiInterfaceMutationService | undefined {
    if (!this.frontendErrorMutationService)
      this.frontendErrorMutationService = this.injector.get<FrontendErrorCreateMutationApiInterfaceMutationService>(
        FrontendErrorCreateMutationApiInterfaceMutationService
      );
    return this.frontendErrorMutationService;
  }

  /**
   * Sends the errors details to the backend
   * @param {ErrorDetails} error the error details
   * @memberof ErrorService
   */
  logError(error: ErrorDetails): void {
    const frontendErrorMutationService = this.getFrontendErrorMutationService();
    if (!frontendErrorMutationService)
      this.loggerService.logConsoleError(
        'FrontendErrorCreateMutationApiInterfaceMutationService can not be injected in the ErrorService'
      );
    else {
      const frontendErrorInput: FrontendErrorInputApiInterface = {
        guid: error.guid,
        userAgent: error.userAgent,
        fileName: error.fileName,
        line: error.line,
        url: error.url,
        message: error.message,
        stackTrace: error.stackTrace,
        actions: error.actions,
      };

      frontendErrorMutationService
        .mutate({ frontendErrorInput })
        .pipe(
          tap(({ data }) => {
            if (!data?.frontendError?.createdId)
              this.loggerService.logConsoleError('API CreateFrontEndError did not return a createdId');
          })
        )
        .subscribe({ error: () => this.loggerService.logConsoleError('Error posting error to API') });
    }
  }
}
