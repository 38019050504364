import { Injectable } from '@angular/core';
import { HomepageApplicationsApiInterfaceQueryService } from '@surecloud/api-types';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { NormaliseGetHomepageApplicationsService } from '../normalise-get-homepage-applications/normalise-get-homepage-applications.service';
import { NormalisedHomepageApplications } from '../normalise-get-homepage-applications/normalise-get-homepage-applications.validation';

/**
 * HomepageApplications API service.
 * @export
 * @class HomepageApplicationsService
 */
@Injectable({
  providedIn: 'root',
})
export class HomepageApplicationsService {
  /**
   * Creates an instance of HomepageApplicationsService
   * @param {HomepageApplicationsApiInterfaceQueryService} homepageApplicationsApiInterfaceQueryService The service to fetch all HomepageApplicationss.
   * @param {NormaliseGetHomepageApplicationsService} normaliseGetHomepageApplicationsService The HomepageApplications data normalising service.
   * @memberof HomepageApplicationsService
   */
  constructor(
    private readonly homepageApplicationsApiInterfaceQueryService: HomepageApplicationsApiInterfaceQueryService,
    private readonly normaliseGetHomepageApplicationsService: NormaliseGetHomepageApplicationsService
  ) {}

  /**
   * Query to get all Homepage Applicationss.
   * @return {Observable<NormalisedHomepageApplications>} The HomepageApplications normalised data.
   * @memberof HomepageApplicationsService
   */
  readAll(): Observable<NormalisedHomepageApplications> {
    return this.homepageApplicationsApiInterfaceQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.homepageApplications
            ? of(this.normaliseGetHomepageApplicationsService.extract(data.homepageApplications))
            : throwError(() => 'No response data from read all Homepage Applications returned from API.')
        )
      );
  }
}
