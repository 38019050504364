import { createAction, props } from '@ngrx/store';
import { EntityCreateInputInterface, EntityInterface, RequestIdInterface } from '@surecloud/common';
import { NormalisedEntityList } from '../../services/normalise-get-entities/normalise-get-entities.validation';

/**
 * An object grouping all the Attribute Feature Actions
 * @export
 */
export const EntityActions = {
  // [Entity List Page]
  entityListEnter: createAction('[Entity List Page] Enter'),
  entityListLeave: createAction('[Entity List Page] Leave'),
  startCreateFirstEntity: createAction('[Entity List Page] Start Create First Entity'),
  startCreateEntity: createAction('[Entity List Page] Start Create Entity'),
  createEntityLeave: createAction('[Entity List Page] Leave Create Entity'),
  completeCreateEntity: createAction('[Entity List Page] Complete Create Entity', props<RequestIdInterface>()),
  cancelCreateEntity: createAction('[Entity List Page] Cancel Create Entity'),
  startDeleteEntity: createAction('[Entity List Page] Start Delete Entity', props<{ entity: EntityInterface }>()),
  completeDeleteEntity: createAction('[Entity List Page] Complete Delete Entity', props<{ entity: EntityInterface }>()),

  // [Entity Page]
  entityEnterLib: createAction('[Entity Page] Enter Entity Library'),
  entityEnter: createAction('[Entity Page] Enter'),
  entityLeave: createAction('[Entity Page] Leave'),
  entityReEnter: createAction('[Entity Page] ReEnter'),
  completeUpdateEntity: createAction('[Entity Page] Complete Update Entity', props<{ entity: EntityInterface }>()),
  cancelUpdateEntity: createAction('[Entity Page] Cancel Update Entity'),

  selectEntity: createAction('[Entity Page] Select Entity', props<{ entityId?: string }>()),

  selectEntityTab: createAction(
    '[Entity Page] Select Entity Tab',
    props<{
      route: string | undefined;
    }>()
  ),

  // [Entity/API]
  createEntity: createAction('[Entity/API] Create Entity', props<{ entityCreateInput: EntityCreateInputInterface }>()),
  createEntitySuccess: createAction('[Entity/API] Create Entity Success', props<{ entity: EntityInterface }>()),
  createEntityFailure: createAction('[Entity/API] Create Entity Failure', props<{ error: string }>()),

  readEntity: createAction('[Entity/API] Read Entity', props<{ entityId: string }>()),
  readOneEntityDataSuccess: createAction(
    '[Entity/API] Read One Entity Data Success',
    props<{ normalisedEntityList: NormalisedEntityList }>()
  ),
  readEntitySuccess: createAction('[Entity/API] Read Entity Success', props<{ entities: EntityInterface[] }>()),
  readEntityFailure: createAction('[Entity/API] Read Entity Failure', props<{ error: string }>()),

  updateEntity: createAction('[Entity/API] Update Entity', props<{ partialEntity: Partial<EntityInterface> }>()),
  updateEntitySuccess: createAction('[Entity/API] Update Entity Success', props<{ entity: EntityInterface }>()),
  updateEntityFailure: createAction('[Entity/API] Update Entity Failure', props<{ error: string }>()),

  deleteEntity: createAction('[Entity/API] Delete Entity', props<{ entityId: string }>()),
  deleteEntitySuccess: createAction('[Entity/API] Delete Entity Success', props<{ entityId: string }>()),
  deleteEntityFailure: createAction('[Entity/API] Delete Entity Failure', props<{ error: string }>()),

  readEntityList: createAction('[Entity/API] Read Entity List') /** Require some params like org/user? */,
  readEntitySummaryList: createAction('[Entity/API] Read Entity Summary List'),
  readEntityListSuccess: createAction(
    '[Entity/API] Read Entity List Success',
    props<{ entityList: EntityInterface[] }>()
  ),
  readEntityListFailure: createAction('[Entity/API] Read Entity List Failure', props<{ error: string }>()),

  readAllEntityDataSuccess: createAction(
    '[Entity/API] Read All Entity Data Success',
    props<{ normalisedEntityList: NormalisedEntityList }>()
  ),
  readAllEntityDataFailure: createAction('[Entity/API] Read All Entity Data Failure', props<{ error: string }>()),

  readAllEntitySummaryDataSuccess: createAction(
    '[Entity/API] Read All Entity Summary Data Success',
    props<{ entityList: EntityInterface[] }>()
  ),
  readAllEntitySummaryDataFailure: createAction(
    '[Entity/API] Read All Entity Summary Data Failure',
    props<{ error: string }>()
  ),
};
