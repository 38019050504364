import { makeHumanReadableZodErrors } from '@surecloud/common';
import { z } from 'zod';
import {
  multipleRecordDataAttributeZodSchema,
  singleRecordDataAttributeZodSchema,
} from '../normalise-get-record/normalise-get-record.validations';

export const recordLinkZodSchema = z.object({
  tabId: z.string(),
  isLinked: z.boolean(),
  recordIds: z.array(z.string()),
  viewerCanCreate: z.boolean(),
});

export const tableRecordZodSchema = z.object({
  parentId: z.string(),
  recordId: z.string(),
  attributes: z.array(z.union([singleRecordDataAttributeZodSchema, multipleRecordDataAttributeZodSchema])),
  viewerCanDelete: z.boolean(),
});

export const normalisedRecordLinksZodSchema = z.object({
  link: recordLinkZodSchema,
  tableRecords: z.array(tableRecordZodSchema),
});

export type NormalisedRecordLinkInterface = z.infer<typeof normalisedRecordLinksZodSchema>;

export type NormalisedTableRecordInterface = z.infer<typeof tableRecordZodSchema>;

export const isRecordLinksGuard = (map: unknown): map is NormalisedRecordLinkInterface =>
  normalisedRecordLinksZodSchema.safeParse(map).success;

export const getRecordLinksError = (map: unknown): z.SafeParseReturnType<unknown, NormalisedRecordLinkInterface> =>
  normalisedRecordLinksZodSchema.safeParse(map);

export const getNormalisedRecordLinksError = (map: unknown): string => {
  const zodResult = normalisedRecordLinksZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};
