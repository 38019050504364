import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RuleActionInterface } from '../../models/rule-action.models';
import { RuleActionActions } from './rule-action.actions';

export const RULE_ACTION_FEATURE_KEY = 'ruleAction';

export interface StateInterface extends EntityState<RuleActionInterface> {
  selectedId?: string; // which Workflow RuleAction has been selected
  loaded: boolean; // has the Workflow RuleAction list been loaded
  error?: string | null; // last known error (if any)
}

export interface RuleActionPartialStateInterface {
  readonly [RULE_ACTION_FEATURE_KEY]: StateInterface;
}

export const ruleActionAdapter: EntityAdapter<RuleActionInterface> = createEntityAdapter<RuleActionInterface>({
  selectId: (value) => value.actionId,
});

export const initialState: StateInterface = ruleActionAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const ruleActionReducer = createReducer(
  initialState,
  on(RuleActionActions.readRuleActionList, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(RuleActionActions.readRuleActionListSuccess, (state: StateInterface, { ruleActionList }) =>
    ruleActionAdapter.setAll(ruleActionList, { ...state, loaded: true })
  ),
  on(RuleActionActions.readRuleActionListFailure, (state: StateInterface, { error }) => ({
    ...state,
    error,
  })),
  on(RuleActionActions.selectRuleAction, (state: StateInterface, { actionId }) => ({
    ...state,
    selectedId: actionId,
  })),
  on(RuleActionActions.deleteRuleActionSuccess, (state: StateInterface, { actionId }) =>
    ruleActionAdapter.removeOne(actionId, state)
  ),
  on(RuleActionActions.updateRuleActionSuccess, (state: StateInterface, { partialRuleAction }) =>
    partialRuleAction.actionId
      ? ruleActionAdapter.updateOne({ id: partialRuleAction.actionId, changes: partialRuleAction }, state)
      : state
  )
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific state action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX state action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return ruleActionReducer(state, action);
}
