import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ColumnFilterInterface,
  ColumnSortInterface,
  DEFAULT_PAGE_SIZE,
  GridStateInterface,
  PaginationInterface,
  PaginationTypeEnum,
  SCGridSchemaTypeEnum,
  TabInterface,
} from '@surecloud/common';
import {
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDragEndEvent,
  RowDragMoveEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { timer } from 'rxjs';
import { LoadingButtonStateInterface } from '../../button/loading-button-state.interface';
import { TextButtonComponent } from '../../button/text-button.component';
import { DeleteButtonComponent } from '../../delete-button/delete-button.component';
import { PopupMenuComponent } from '../../popup-menu/popup-menu.component';
import { PopupMenuItemInterface } from '../../popup-menu/popup-menu.component.interface';
import { TabsComponent } from '../../tabs/tabs.component';
import { BasicGridComponent } from '../basic-grid/basic-grid.component';
import { GridEventEnum, GridEventSourceEnum, GridRowSelectionEnum } from '../grid-events.enum';
import { GRID_HEIGHT_MD } from '../grid-height.constants';
import { GridQuickFilterComponent } from '../quick-filter/grid-quick-filter.component';
import {
  GRID_MENU_DELETE_OPTION,
  GRID_MENU_EXPORT_ALL_OPTION,
  GRID_MENU_EXPORT_SELECTED_OPTION,
  GRID_MENU_IMPORT_OPTION,
  doFilter,
  getColumnType,
  getFilterData,
  getOrderType,
  handleMenuOptions,
  setMenuItemsSequence,
} from './advanced-grid.constants';
import { AdvancedGridButtonType } from './button/advanced-grid-button.component';
import { ADVANCED_GRID_EXCEL_EXPORT_PARAMS } from './export/advanced-grid-excel-export-params';
import { ADVANCED_GRID_EXCEL_STYLES } from './export/advanced-grid-excel-export-styles';
import { AdvancedGridFooterComponent } from './footer/advanced-grid-footer.component';

/**
 * Surecloud advanced grid wrapper around sureCloud basic grid.
 *
 * Avoid using get & set in this file. (Too many issues using these).
 */
@Component({
  selector: 'sc-advanced-grid',
  templateUrl: './advanced-grid.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BasicGridComponent,
    GridQuickFilterComponent,
    DeleteButtonComponent,
    CommonModule,
    TextButtonComponent,
    TabsComponent,
    AdvancedGridFooterComponent,
    PopupMenuComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AdvancedGridComponent implements OnChanges {
  /**
   * The height of the table.
   * @memberof AdvancedGridComponent
   */
  @Input() height = GRID_HEIGHT_MD;

  /**
   * Set this if we want to display the grid but remove functionality so it can be previewed.
   * @memberof AdvancedGridComponent
   */
  @Input() readonly = false;

  /**
   * Enables row drag and drop functionality
   * @memberof AdvancedGridComponent
   */
  @Input() rowDrag = false;

  /**
   *Theme to be applied
   * @memberof AdvancedGridComponent
   */
  @Input() theme = 'ag-theme-alpine';

  /**
   *The type of row selection, singular or multiple
   * @type {('single' | 'multiple' | undefined)}
   * @memberof AdvancedGridComponent
   */
  @Input() rowSelection: 'single' | 'multiple' | undefined = GridRowSelectionEnum.SINGLE;

  /**
   * The data source
   * @type {unknown[]} the data set
   * @memberof AdvancedGridComponent
   */
  @Input() rowData: unknown[] = [];

  /**
   * Array of columns to display and their definitions
   * @type {ColDef[]}
   * @memberof AdvancedGridComponent
   */
  localColumnDefs: ColDef[] = [];
  @Input() columnDefs: ColDef[] = [];

  /**
   * Filter string to use to restrict data
   * @type {string|null}
   * @memberof AdvancedGridComponent
   */
  @Input() quickFilterText = '';

  /**
   * When enabled this component will no longer filter the data.
   * It will emit an event to the parent component to handle the filtering which allows filter terms to be sent to the API.
   * @memberof AdvancedGridComponent
   */
  @Input() enableExternalFilter = false;

  /**
   * The delay in milliseconds to wait before the filtering logic is executed.
   * This is good when enableExternalFilter is true and you want to prevent multiple calls to the server for every keystroke.
   * @memberof AdvancedGridComponent
   */
  @Input() filterChangeDebounceDelay = 0;

  /**
   * Should the grid NOT scroll to the top when new data has been input.
   * @memberof AdvancedGridComponent
   */
  @Input()
  suppressScrollOnNewData = true;

  /**
   * Set this to true when we want to display a footer (Advanced Grid).
   * Applies a class that helps style the grid correctly to line up with a footer.
   * @memberof AdvancedGridComponent
   */
  @Input() footer = true;

  /**
   * Array of tabs.
   * @type {(TabInterface[]  | undefined)}
   * @memberof AdvancedGridComponent
   */
  @Input() tabs: TabInterface[] | undefined;

  /**
   * The name of the Primary Button
   * @memberof AdvancedGridComponent
   */
  @Input() primaryButtonName = '';

  /**
   * The name of the Secondary Button
   * @memberof AdvancedGridComponent
   */
  @Input() secondaryButtonName = '';

  /**
   * Show the Primary Button.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridComponent
   */
  @Input() showPrimaryButton: boolean | null = true;

  /**
   * Show the Secondary Button.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridComponent
   */
  @Input() showSecondaryButton: boolean | null = false;

  /**
   * The type of the primary button.
   * @type {AdvancedGridButtonType}
   * @memberof AdvancedGridComponent
   */
  @Input() primaryButtonType: AdvancedGridButtonType = 'text';

  /**
   * The type of the secondary button.
   * @type {AdvancedGridButtonType}
   * @memberof AdvancedGridComponent
   */
  @Input() secondaryButtonType: AdvancedGridButtonType = 'text';

  /**
   * Show delete button
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridComponent
   */
  @Input() canDelete: boolean | null = true;

  /**
   * Show row export options in the grid menu, usually driven by the user's permissions.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridComponent
   */
  @Input() canExport: boolean | null = true;

  /**
   * Show row import options in the grid menu, usually driven by the user's permissions.
   * @type {(boolean | null)}
   * @memberof AdvancedGridComponent
   */
  @Input() canImport: boolean | null = true;

  /**
   * The name to add into the title of the exported file.
   * example: 'aurora_[exportName]_export_2021-01-01.xlsx'
   */
  @Input() exportName = '';

  /**
   * If set to false any select columns will be filtered out of the column definitions.
   * Useful for removing them depending on User Profile Permissions.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridComponent
   */
  @Input() canSelect: boolean | null = true;

  /**
   * Enable the search filter grid
   * @memberof AdvancedGridComponent
   */
  @Input() canFilter = true;

  /**
   * Two way data bind the active tab between this component and it's parent.
   * @type {number}
   * @memberof AdvancedGridComponent
   */
  @Input() activeTab: number | undefined;

  /**
   * The state of the primary button if it's loading type, should be undefined otherwise
   * @type {LoadingButtonStateInterface}
   * @memberof AdvancedGridComponent
   */
  @Input() primaryLoadingButtonState?: LoadingButtonStateInterface;

  /**
   * The state of the secondary button if it's loading type, should be undefined otherwise
   * @type {LoadingButtonStateInterface}
   * @memberof AdvancedGridComponent
   */
  @Input() secondaryLoadingButtonState?: LoadingButtonStateInterface;

  /**
   * Provide a parent context to the grid.
   * @type {{ componentParent: unknown }}
   * @memberof AdvancedGridComponent
   */
  @Input() context: { componentParent: unknown } = { componentParent: null };

  /**
   * Determines should the loading state be shown.
   * @type {{ componentParent: unknown }}
   * @memberof AdvancedGridComponent
   */
  @Input() showLoadingState = false;

  /**
   * Are the table rows loaded.
   * @type {{ componentParent: unknown }}
   * @memberof AdvancedGridComponent
   */
  @Input() isLoaded = false;

  /**
   * The page size to use for pagination
   * @memberof AdvancedGridComponent
   */
  @Input() pageSize = DEFAULT_PAGE_SIZE;

  /**
   * The total number of items used for pagination
   * @memberof AdvancedGridComponent
   */
  @Input() totalItems = 0;

  /**
   * The row model type to use.
   * For server side pagination use 'serverSide'
   * For client side pagination use 'clientSide'
   * Client side pagination should be used for testing purposes
   * @memberof AdvancedGridComponent
   */
  @Input() paginationType: PaginationTypeEnum | null = null;

  /**
   * The message to display when there are no results.
   * @memberof AdvancedGridComponent
   */
  @Input() noResultsMessage = $localize`Use the button below to add to this table`;

  /**
   * Handles the cell edited event
   * @memberof AdvancedGridComponent
   */
  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();

  /**
   * Handles the clicked from the primary button
   * @memberof AdvancedGridComponent
   */
  @Output() primaryButtonClicked = new EventEmitter();

  /**
   * Handles the clicked from the secondary button
   * @memberof AdvancedGridComponent
   */
  @Output() secondaryButtonClicked = new EventEmitter();

  /**
   * Handles the rows deleted event
   * @memberof AdvancedGridComponent
   */
  @Output() rowsDeleted = new EventEmitter<unknown[]>();

  /**
   * Handles row drag event - The mouse has moved while dragging.
   * @memberof AdvancedGridComponent
   */
  @Output() rowDragMove = new EventEmitter<RowDragMoveEvent>();

  /**
   * Handles row drag event- The drag has finished over the grid.
   * @memberof AdvancedGridComponent
   */
  @Output() rowDragEnd = new EventEmitter<RowDragEndEvent>();

  /**
   * Handles the row selection change event
   * @memberof AdvancedGridComponent
   */
  @Output() selectionChanged = new EventEmitter<unknown[]>();

  /**
   * Handles the cell clicked event
   * @memberof AdvancedGridComponent
   */
  @Output() cellClicked = new EventEmitter<CellClickedEvent>();

  /**
   * Handles the tab change
   * @memberof AdvancedGridComponent
   */
  @Output() activeTabChange = new EventEmitter<number>();

  /**
   * When enableExternalFilter is true, this event will be emitted with the filter text.
   * @memberof AdvancedGridComponent
   */
  @Output() filterChanged = new EventEmitter<string>();

  /**
   * Export All Rows (Not just the ones on the current page)
   * @memberof AdvancedGridComponent
   */
  @Output() exportAll = new EventEmitter<void>();

  /**
   * Export Selection - just the rows selected.
   * @memberof AdvancedGridComponent
   */
  @Output() exportSelectionEvent = new EventEmitter<unknown[]>();

  /**
   * Handles the import clicked event
   * @memberof AdvancedGridComponent
   */
  @Output() importClickedEvent = new EventEmitter<void>();
  /**
   * Grid State Changed
   * @memberof AdvancedGridComponent
   */
  @Output() gridStateChangedEvent = new EventEmitter<GridStateInterface>();

  /**
   * Pagination type visible to the template
   * @memberof AdvancedGridComponent
   */
  PaginationTypeEnum = PaginationTypeEnum;

  /**
   * Grid api of the detail grid.
   * @type {GridApi<unknown>}
   * @memberof AdvancedGridComponent
   */
  gridApi!: GridApi<unknown>;

  /**
   * Disabled button delete
   * @memberof AdvancedGridComponent
   */
  gridMenuItems: PopupMenuItemInterface[] = [];

  /**
   * The default excel export params.
   */
  defaultExcelExportParams = ADVANCED_GRID_EXCEL_EXPORT_PARAMS;

  /**
   * The excel export styles.
   */
  excelStyles = ADVANCED_GRID_EXCEL_STYLES;

  /**
   * The pagination object to be passed to the pagination component
   * @type {PaginationInterface | null}
   * @memberof AdvancedGridComponent
   */
  pagination: PaginationInterface | null = null;

  /**
   * The quick filter text for the pagination
   * @memberof AdvancedGridComponent
   */
  paginationQuickFilterText = '';

  /**
   * The pagination properties storing
   * the state of the grid
   * @memberof AdvancedGridComponent
   */
  paginationProperties = {
    isSortPresent: false,
    isFilterPresent: false,
    isQuickFilterTextPresent: false,
  };

  /**
   * Handle changes to the Input data.
   * @param {SimpleChanges} changes - the changes via @Inputs.
   * @return {void}
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.handleRowDataChanges(changes);
    this.handleColumnDefs(changes);
    this.setGridMenu(changes);
  }

  /**
   * Specifically handle changes to columnDefs.
   * @param {SimpleChanges} changes - the changes via @Inputs.
   * @return {void}
   */
  private handleColumnDefs(changes: SimpleChanges): void {
    const columnDefsChanged = changes['columnDefs'];
    const canSelectChanged = changes['canSelect'];
    if (!columnDefsChanged && !canSelectChanged) return;
    if (this.columnDefs) {
      const filteredColumnDefs = this.columnDefs.filter(
        (column) => !(column.type === SCGridSchemaTypeEnum.Select && !this.canSelect)
      );
      this.columnDefs = filteredColumnDefs;
    }
  }

  /**
   * Specifically handle changes to rowData.
   * @param {SimpleChanges} changes - the changes via @Inputs.
   * @return {void}
   */
  private handleRowDataChanges(changes: SimpleChanges): void {
    if (!this.gridApi) return;
    const rowDataChange = changes['rowData'];
    if (!rowDataChange || rowDataChange.isFirstChange() || !this.rowData) return;

    this.setRowData();

    const currentLengthMinusOne = rowDataChange.currentValue.length - 1;

    if (rowDataChange.previousValue.length === currentLengthMinusOne)
      timer(500).subscribe(() => this.gridApi.ensureIndexVisible(currentLengthMinusOne));
  }

  /**
   * Specifically handle changes to canDelete, canExport and canImport for the grid menu array.
   * @param {SimpleChanges} changes - the changes via @Inputs.
   * @return {void}
   */
  private setGridMenu(changes: SimpleChanges): void {
    let gridMenuItems: PopupMenuItemInterface[] = this.gridMenuItems || [];

    const { canDelete, canImport, canExport } = changes;

    if (canExport)
      gridMenuItems = handleMenuOptions(canExport.currentValue, gridMenuItems, [
        GRID_MENU_EXPORT_ALL_OPTION,
        GRID_MENU_EXPORT_SELECTED_OPTION,
      ]);

    if (canImport) gridMenuItems = handleMenuOptions(canImport.currentValue, gridMenuItems, GRID_MENU_IMPORT_OPTION);

    if (canDelete?.currentValue) {
      const deleteOption = { ...GRID_MENU_DELETE_OPTION, footerItem: gridMenuItems.length > 0 };
      gridMenuItems.push(deleteOption);
    }

    const deleteOptionIndex = gridMenuItems.findIndex((item) => item.id === GRID_MENU_DELETE_OPTION.id);
    if (deleteOptionIndex !== -1) {
      gridMenuItems = gridMenuItems.filter((item) => item.id !== GRID_MENU_DELETE_OPTION.id);
      gridMenuItems.push({ ...GRID_MENU_DELETE_OPTION, footerItem: gridMenuItems.length > 0 });
    }

    this.gridMenuItems = setMenuItemsSequence(gridMenuItems);
  }

  /**
   *Emits when the grid is ready
   * @param {GridReadyEvent} params grid ready params, columns api and grid api
   * @memberof AdvancedGridComponent
   */
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.setRowData();

    if (this.paginationType === PaginationTypeEnum.ServerSide) {
      this.deactivateAGGridFilterAndSort();
    }
  }

  /**
   *
   * @param {CellValueChangedEvent} cellValueChange The value changed event.
   */
  onCellValueChanged(cellValueChange: CellValueChangedEvent): void {
    const { node } = cellValueChange;
    this.gridApi.refreshCells({ rowNodes: [node] });
    this.cellValueChanged.emit(cellValueChange);
  }

  /**
   * Emits the rows deleted data
   * @memberof AdvancedGridComponent
   */
  deleteRows(): void {
    this.rowsDeleted.emit(this.gridApi.getSelectedRows());
  }

  /**
   * Emits the rows deleted data
   * @param {SelectionChangedEvent} event selection changed event data
   * @memberof AdvancedGridComponent
   */
  rowSelectionChange(event: SelectionChangedEvent): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.toggleGridMenuDisabledStates();
    this.selectionChanged.emit(selectedRows);

    if (event.source === GridEventSourceEnum.API_SELECT_ALL) {
      this.gridApi.dispatchEvent({
        type: GridEventEnum.SELECT_ALL,
      });
    } else if (event.source === GridEventSourceEnum.API) {
      const type =
        this.rowSelection === GridRowSelectionEnum.SINGLE
          ? GridEventEnum.SELECT_ROW_SINGLE_SELECT
          : GridEventEnum.SELECT_ROW;
      this.gridApi.dispatchEvent({
        type,
      });
    }
  }

  /**
   * Control the state of the grid menu buttons.
   * @memberof AdvancedGridComponent
   */
  toggleGridMenuDisabledStates(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    const hasRowsSelected = selectedRows.length > 0;
    const menuItemIdsDependentOnSelection = [GRID_MENU_DELETE_OPTION.id, GRID_MENU_EXPORT_SELECTED_OPTION.id];

    this.gridMenuItems = this.gridMenuItems.map((item) => {
      if (menuItemIdsDependentOnSelection.includes(item.id)) {
        return { ...item, disabled: !hasRowsSelected };
      }
      return item;
    });
  }

  /**
   * When enableExternalFilter is true, this method will emit the filterBy event with the search value.
   * Otherwise, it will set the quickFilterText.
   * @param {string} text the search value
   * @memberof AdvancedGridComponent
   */
  setQuickFilter(text: string): void {
    if (this.enableExternalFilter) {
      this.filterChanged.emit(text);
    } else if (this.paginationType === PaginationTypeEnum.ServerSide) {
      this.paginationQuickFilterText = text;
      this.gridStateChanged();
    } else {
      this.quickFilterText = text;
    }
  }

  /**
   * Catching the event of the mouse moving while dragging.
   * @param {RowDragMoveEvent} event - Row Drag Move event
   * @memberof AdvancedGridComponent
   */
  onRowDragMove(event: RowDragMoveEvent): void {
    this.rowDragMove.emit(event);
  }

  /**
   * Catching the event of the drag finishing.
   * @param {RowDragEndEvent} event - Row Drag End event
   * @memberof AdvancedGridComponent
   */
  onRowDragEnd(event: RowDragEndEvent): void {
    this.rowDragEnd.emit(event);
  }

  /**
   * Handle the gridMenuClicked event
   * @param {string} id - menu item id
   * @memberof AdvancedGridComponent
   */
  public gridMenuClicked(id: string): void {
    const item = this.gridMenuItems.find((menuItem) => menuItem.id === id);
    if (!item) return;
    switch (item.id) {
      case GRID_MENU_DELETE_OPTION.id: {
        this.deleteRows();
        break;
      }
      case GRID_MENU_EXPORT_ALL_OPTION.id: {
        this.exportAll.emit();
        this.gridApi.deselectAll();
        break;
      }
      case GRID_MENU_EXPORT_SELECTED_OPTION.id: {
        this.exportSelection();
        break;
      }
      case GRID_MENU_IMPORT_OPTION.id: {
        this.importClickedEvent.emit();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Export selected rows as excel using in built ag-grid method.
   * @memberof AdvancedGridComponent
   */
  public exportSelection(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.exportSelectionEvent.emit(selectedRows);
    this.gridApi.deselectAll();
  }

  /**
   * Handles go to first page event
   * @memberof AdvancedGridComponent
   */
  onGoToFirstPage(): void {
    if (!this.pagination) {
      return;
    }

    this.pagination.currentPage = 0;
    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.paginationGoToFirstPage();
    }
    this.paginationChanged();
  }

  /**
   * Handles go to last page event
   * @memberof AdvancedGridComponent
   */
  onGoToLastPage(): void {
    if (!this.pagination) {
      return;
    }

    this.pagination.currentPage = Math.ceil(this.pagination.totalItems / this.pagination.pageSize) - 1;
    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.paginationGoToLastPage();
    }
    this.paginationChanged();
  }

  /**
   * Handles go to next page event
   * @memberof AdvancedGridComponent
   */
  onGoToNextPage(): void {
    if (!this.pagination) {
      return;
    }

    this.pagination.currentPage += 1;
    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.paginationGoToNextPage();
    }
    this.paginationChanged();
  }

  /**
   * Handles go to previous page event
   * @memberof AdvancedGridComponent
   */
  onGoToPreviousPage(): void {
    if (!this.pagination) {
      return;
    }

    this.pagination.currentPage -= 1;
    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.paginationGoToPreviousPage();
    }
    this.paginationChanged();
  }

  /**
   * Handles on page size change event
   * @param {number} pageSize selected page size
   * @memberof AdvancedGridComponent
   */
  onPageSizeChange(pageSize: number): void {
    if (!this.pagination) {
      return;
    }
    this.pageSize = pageSize;
    this.pagination.pageSize = pageSize;
    this.pagination.currentPage = 0;

    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.setGridOption('paginationPageSize', pageSize);
      this.gridApi.paginationGoToFirstPage();
    }
    this.paginationChanged();
  }

  /**
   * Handles go to page event
   * @param {number} page selected page number
   */
  onGoToPage(page: number): void {
    if (!this.pagination) {
      return;
    }
    // page index starts from 0
    const updatedPage = page - 1;
    this.pagination.currentPage = updatedPage;

    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.paginationGoToPage(updatedPage);
    }

    this.paginationChanged();
  }

  /**
   * Handles changes to the grid state
   * @memberof AdvancedGridComponent
   */
  gridStateChanged(): void {
    if (this.paginationType !== PaginationTypeEnum.ServerSide) {
      return;
    }

    this.gridApi.showLoadingOverlay();
    const colState = this.gridApi.getColumnState();
    const sortState = colState
      .filter((s) => s.sort != null)
      .map((s) => {
        const order = getOrderType(s.sort);
        const type = this.columnDefs.find((c) => c.colId === s.colId)?.type;
        const columnType = getColumnType(type as string);

        return { columnId: s.colId, order, columnType };
      });
    const filters = getFilterData(this.gridApi.getFilterModel());

    // set pagination currentPage to 0 if filters or sort state is changed for the first time
    if (
      (filters.length > 0 && !this.paginationProperties.isFilterPresent) ||
      (sortState.length > 0 && !this.paginationProperties.isSortPresent) ||
      (this.paginationQuickFilterText.length > 0 && !this.paginationProperties.isQuickFilterTextPresent)
    ) {
      this.pagination = {
        currentPage: 0,
        pageSize: this.pagination?.pageSize || this.pageSize,
        totalItems: this.pagination?.totalItems || this.totalItems,
      };
    }

    this.updatePaginationProperties(filters, sortState);

    const gridState: GridStateInterface = {
      pagination: this.pagination,
      sorting: sortState,
      filters,
      quickFilter: this.paginationQuickFilterText,
    };
    this.gridStateChangedEvent.emit(gridState);
  }

  /**
   * Sets row data for the gird
   * depending on the pagination state
   * @memberof AdvancedGridComponent
   */
  private setRowData(): void {
    if (this.rowData) {
      this.gridApi.setGridOption('rowData', this.rowData);
      this.gridApi.hideOverlay();
    }

    if (this.rowData.length === 0) {
      this.handleNoRows();
    }

    if (this.paginationType) {
      this.updatePagination();
    }

    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.gridApi.updateGridOptions({
        paginationPageSize: this.pageSize,
      });
    }
  }

  /**
   * Handles no rows in the grid
   * @private
   * @memberof AdvancedGridComponent
   */
  private handleNoRows(): void {
    if (this.totalItems === 0) {
      this.gridApi.showNoRowsOverlay();
    } else {
      this.onGoToPreviousPage();
    }
  }

  /**
   * Updates the pagination data
   * depending on the pagination type
   * @private
   * @memberof AdvancedGridComponent
   */
  updatePagination(): void {
    if (this.paginationType && this.rowData.length === 0) {
      this.resetPagination();
    } else if (this.paginationType === PaginationTypeEnum.ClientSide && this.gridApi) {
      this.pagination = {
        currentPage: this.gridApi.paginationGetCurrentPage() || 0, // grid page is 0 based
        pageSize: this.gridApi.paginationGetPageSize() || this.pageSize,
        totalItems: this.gridApi.paginationGetRowCount() || this.rowData.length,
      };
    } else if (this.paginationType === PaginationTypeEnum.ServerSide) {
      const maxPage = Math.ceil(this.totalItems / this.pageSize) - 1;
      const currentPage = this.pagination?.currentPage || 0;
      const correctPage = currentPage > maxPage ? maxPage : currentPage; // prevents going over the max page;

      this.pagination = {
        currentPage: correctPage > 0 ? correctPage : 0, // prevents setting the page to negative value
        pageSize: this.pagination?.pageSize || this.pageSize,
        totalItems: this.totalItems || 0,
      };
    }
  }

  /**
   * Resets the pagination data
   * @private
   * @memberof AdvancedGridComponent
   */
  private resetPagination(): void {
    this.pagination = {
      currentPage: 0,
      pageSize: this.pagination?.pageSize || this.pageSize,
      totalItems: 0,
    };
  }

  /**
   * Handles pagination changes
   * @private
   * @memberof AdvancedGridComponent
   */
  private paginationChanged(): void {
    if (this.paginationType === PaginationTypeEnum.ClientSide) {
      this.updatePagination();
    } else {
      this.gridStateChanged();
    }
  }

  /**
   * Updates the pagination properties
   * @param {ColumnFilterInterface[]} filter Filter data
   * @param {ColumnSortInterface[]} sort Sort data
   */
  private updatePaginationProperties(filter: ColumnFilterInterface[], sort: ColumnSortInterface[]): void {
    this.paginationProperties.isFilterPresent = Object.keys(filter).length > 0;
    this.paginationProperties.isSortPresent = Object.keys(sort).length > 0;
    this.paginationProperties.isQuickFilterTextPresent = this.paginationQuickFilterText.length > 0;
  }

  /**
   * Deactivates AG Grid Filter and Sort
   * Original implementation on NG-4711 but has been refactored when updating to AG Grid 31.3.4 on NG-2930
   * @private
   * @memberof AdvancedGridComponent
   */
  private deactivateAGGridFilterAndSort(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const model = this.gridApi['rowModel'];

    model.doFilter = doFilter;
    model.doSort = (): void => {
      model.rootNode.childrenAfterSort = model.rootNode.childrenAfterFilter.slice(0);
    };
  }
}
