/**
 * Entity attribute rule types.
 *
 * @export
 * @enum {number}
 */
export enum EntityAttributeRuleConditionTypeEnum {
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  MaximumCharacterLimit = 'MAXIMUM_CHARACTER_LIMIT',
  MinimumCharacterLimit = 'MINIMUM_CHARACTER_LIMIT',
  Unique = 'UNIQUE',
  Before = 'BEFORE',
  After = 'AFTER',
}

/**
 * Entity Attribute rule Value Type
 *
 * @export
 * @enum {number}
 */
export enum EntityAttributeRuleValueTypeEnum {
  Attribute = 'ATTRIBUTE',
  Number = 'NUMBER',
  Date = 'DATE',
  Period = 'PERIOD',
}

/**
 * Entity Attribute rule Temporal Unit Type
 *
 * @export
 * @enum {number}
 */
export enum EntityAttributeRulTemporalUnitTypeEnum {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS',
}

/**
 * Entity Attribute rule Temporal Operator Type
 *
 * @export
 * @enum {number}
 */
export enum EntityAttributeRuleTemporalOperatorTypeEnum {
  Minus = 'MINUS',
  Plus = 'PLUS',
}
