import { EditorComponentFormatIdTypes, EditorComponentFormatInterface } from './editor.component.interface';

/**
 * The default selected format.
 */
export const EDITOR_DEFAULT_FORMAT: EditorComponentFormatIdTypes = 'body';

/**
 * No background highlight quill value.
 * This constant is in the CSS as well so ensure to change there manually if updated here.
 */
export const EDITOR_NO_HIGHTLIGHT = 'no-highlight';

/**
 * We apply a format ID to our custom formats so we can reformat them when the HTML comes back from the API.
 * Quill by default will strip out attributes on elements so we use this ID and our custom blot format method to
 * generate the correct HTML with additional classes.
 */
export const EDITOR_FORMAT_ID_ATTRIBUTE = 'data-sc-format-id';

/**
 * The custom editor format types available.
 */
export const EDITOR_FORMATS: EditorComponentFormatInterface[] = [
  {
    id: 'title',
    tagName: 'h1',
    type: 'title',
  },
  {
    id: 'subtitle',
    tagName: 'h2',
    type: 'subtitle',
  },
  {
    id: EDITOR_DEFAULT_FORMAT,
    type: EDITOR_DEFAULT_FORMAT,
    tagName: 'p',
  },
  {
    id: 'guidance',
    type: 'guidance',
    tagName: 'p',
  },
  {
    id: 'information',
    tagName: 'div',
    type: 'notification',
  },
  {
    id: 'favourite',
    tagName: 'div',
    type: 'notification',
  },
  {
    id: 'success',
    tagName: 'div',
    type: 'notification',
  },
  {
    id: 'alert',
    tagName: 'div',
    type: 'notification',
  },
  {
    id: 'summary',
    tagName: 'div',
    type: 'notification',
  },
];

/**
 * Surecloud background colours for the editor component.
 * Our SCSS variables are converted to CSS variables so we can map them here.
 */
export const EDITOR_BACKGROUND_COLOURS = [
  'var(--red-300)',
  'var(--orange-300)',
  'var(--yellow-300)',
  'var(--green-300)',
  'var(--blue-300)',
  'var(--purple-300)',
  EDITOR_NO_HIGHTLIGHT,
];

