import { Injectable } from '@angular/core';
import { ModalInterface, ModalService } from '@surecloud/design';
import { RecordImportSuccessComponent } from '../../components/record-import-success/record-import-success.component';
import { RecordImportComponent } from '../../components/record-import/record-import.component';
import { RecordLinkedImportModalInterface } from '../../models/record-import';

/**
 * The record import modal service.
 * @export
 * @class RecordImportModalService
 */
@Injectable({ providedIn: 'root' })
export class RecordImportModalService {
  /**
   * Reference to the current opened record import modal.
   * @type {(ModalInterface<RecordImportComponent | RecordImportSuccessComponent> | null)}
   * @memberof RecordImportModalService
   */
  public recordImportModalViewReference: ModalInterface<RecordImportComponent | RecordImportSuccessComponent> | null =
    null;

  /**
   * The modal details.
   * @type {(RecordLinkedImportModalInterface | null)}
   * @memberof RecordImportModalService
   */
  public modalDetails: RecordLinkedImportModalInterface | null = null;

  /**
   * Creates an instance of RecordImportModalService.
   * @param {ModalService} modal - The common modal service.
   * @memberof RecordImportModalService
   */
  constructor(private readonly modal: ModalService) {}

  /**
   * Open the record import modal.
   * @param {RecordImportComponent} modalDetails - The modal details.
   * @memberof RecordImportModalService
   */
  openImportModal(modalDetails: RecordLinkedImportModalInterface): void {
    this.modalDetails = modalDetails;
    this.recordImportModalViewReference = this.modal.open<RecordImportComponent>({
      content: RecordImportComponent,
      height: 380,
      title: 'Import Data',
      width: 450,
    });
  }

  /**
   * Open the success modal.
   * @memberof RecordImportModalService
   */
  openSuccessModal(): void {
    this.recordImportModalViewReference = this.modal.open<RecordImportSuccessComponent>({
      content: RecordImportSuccessComponent,
      height: 250,
      width: 450,
      title: 'Success',
    });
  }
}
