<button
  *ngIf="buttonType === 'text'"
  sc-text-button
  size="small"
  themeColour="primary"
  (click)="buttonClicked.emit($event)"
  [disabled]="readonly">
  {{ buttonName }}
</button>

<sc-loading-button
  *ngIf="buttonType === 'loading' && loadingButtonState"
  size="small"
  themeColour="primary"
  [disabled]="readonly"
  [loading]="loadingButtonState.loading"
  [error]="loadingButtonState.error"
  (startLoad)="buttonClicked.emit($event)">
  {{ buttonName }}
</sc-loading-button>
