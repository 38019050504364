/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines-per-function */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UNTITLED } from '@surecloud/common';
import { dia, highlighters, linkTools, shapes } from 'jointjs';
import * as diagramConstants from './canvas.constants';
import { LineInterface, NodeInterface, ParentElementInterface, PortNameEnum } from './canvas.interface';

/**
 * Workflow Canvas Component
 * @export
 * @class WorkflowCanvasComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'sc-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO - rename to WorkflowDiagramComponent (its specific not generic)
export class CanvasComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('canvas', { read: ElementRef })
  public canvas!: ElementRef;
  private graph!: dia.Graph;
  private paper!: dia.Paper;

  // We want to name based on the workflow terms not the underlying library
  // diagramStage might be good.
  @Input()
  parentElements: ParentElementInterface[] = [];

  lines: LineInterface[] = [];
  nodes: NodeInterface[] = [];
  nodeIdToIndex: Record<string, number> = {};
  height = diagramConstants.DIAGRAM_HEIGHT;
  childWidth = diagramConstants.DIAGRAM_STEP_WIDTH; // Set the static width for child elements
  gapBetweenParentAndChild = diagramConstants.DIAGRAM_STEP_Y_OFFSET; // Set the gap between parent and child elements
  gapBetweenSiblings = diagramConstants.DIAGRAM_STEP_SPACE_X; // Set the gap between sibling child elements
  gapBetweenParents = diagramConstants.DIAGRAM_GROUP_SPACE_X; // Set the gap between parent rectangles
  portRadius = diagramConstants.DIAGRAM_PORT_RADIUS; // Set the port circle radius
  vertexRadius = diagramConstants.DIAGRAM_LINE_CURVE; // Adjust the radius to control the curvature
  rowHeight = diagramConstants.DIAGRAM_ROW_HEIGHT; // Adjust the row height
  startX = diagramConstants.DIAGRAM_START_X;
  startY = diagramConstants.DIAGRAM_START_Y;
  linesByRelationship: {
    direct: LineInterface[];
    distant: LineInterface[];
  } = { direct: [], distant: [] };
  linesByTarget: Record<string, LineInterface[]> = {};
  linesBySource: Record<string, LineInterface[]> = {};
  distantLinesByTarget: Record<string, LineInterface[]> = {};
  distantLinesBySource: Record<string, LineInterface[]> = {};
  distantLinesByTargetWithRowIndex: Record<string, LineInterface[]> = {};
  distantLinesBySourceWithRowIndex: Record<string, LineInterface[]> = {};
  backwardLinesByTargetWithRowIndex: Record<string, LineInterface[]> = {};
  backwardLinesBySourceWithRowIndex: Record<string, LineInterface[]> = {};
  forwardDirectLines: LineInterface[] = [];
  forwardRowIndex = 0;
  backwardRowIndex = 0;
  mapForwardRowIndexToSource: Record<number, string> = {};
  mapForwardRowIndexToUseNodeIdToIndex: Record<number, number[]> = {};
  mapBackwardRowIndexToSource: Record<number, string> = {};
  mapBackwardRowIndexToUseNodeIdToIndex: Record<number, number[]> = {};
  portMarkup = '<circle class="port-body" magnet="true"></circle>';
  childrenMap: Record<string, shapes.standard.Rectangle> = {}; // Map to store child rectangles based on their IDs
  curveOffset = 7.5;
  width = 0;

  @Output()
  newLink: EventEmitter<{ source: string; target: string }> = new EventEmitter();

  @Output()
  updateLink: EventEmitter<{ target: string; linkId: string }> = new EventEmitter();

  @Output()
  selectParent: EventEmitter<{ parentId: string }> = new EventEmitter();

  @Output()
  selectChild: EventEmitter<{ childId: string }> = new EventEmitter();

  @Output()
  selectLink: EventEmitter<{ linkId: string }> = new EventEmitter();

  /**
   * Returns an array of lines in the chosen direction
   * @static
   * @param {LineInterface[]} lines the lines to check
   * @param {boolean} isForward if the direction is forward
   * @return {*}  {LineInterface[]}
   * @memberof CanvasComponent
   */
  static getAllLinesByDirection(lines: LineInterface[], isForward: boolean): LineInterface[] {
    return lines.filter((line) => line.isForward === isForward);
  }

  /**
   * Returns an array from the starting point to the end point
   * @static
   * @param {number} start the starting number
   * @param {number} end the end number
   * @return {*}  {number[]}
   * @memberof CanvasComponent
   */
  static getRange(start: number, end: number): number[] {
    return [...Array(end - start)].map((_, i) => start + i);
  }

  /**
   * Checks if there's an available path in the current row indexes and returns it
   * @static
   * @param {Record<number, number[]>} mapRowIndexToUsedNodeIndexes the row index used nodes indexes
   * @param {number[]} range the nodes indexes needed to build a path
   * @return {(number | undefined)} returns the row index if there's a path available for that row index else returns undefined
   * @memberof CanvasComponent
   */
  static findAvailableRowIndex(
    mapRowIndexToUsedNodeIndexes: Record<number, number[]>,
    range: number[]
  ): number | undefined {
    const record = Object.entries(mapRowIndexToUsedNodeIndexes)
      .sort(([indexA], [indexB]) => Number(indexA) - Number(indexB))
      .find(([, numbers]) => !range.some((number) => numbers.includes(number)));
    if (record) return Number(record[0]);
    return undefined;
  }

  /**
   * Returns the distant in jointjs units from the port to the element center
   * @static
   * @param {PortNameEnum} port the port location
   * @return {number} returns the offset
   * @memberof CanvasComponent
   */
  static getPortXoffset(port: PortNameEnum): number {
    if (port === PortNameEnum.Bottom || port === PortNameEnum.Top) {
      return 0;
    }
    return 20;
  }

  static initZoom(): void {
    // Prototype zoom code, doesn't work yet.
    const canvas = document.querySelector<HTMLElement>('.canvas');
    if (!canvas) return;
    let zoomScaleAmount = 1;
    document.addEventListener('wheel', (event) => {
      const wheelEvent = event;
      if (wheelEvent.shiftKey) {
        const zoomDirection = wheelEvent.deltaY > 0 ? -0.1 : 0.1;
        zoomScaleAmount += zoomDirection;
        canvas.style.transform = `scale(${zoomScaleAmount})`;
      }
    });
  }

  static initScroll(): void {
    const slider = document.querySelector<HTMLElement>('.parent');
    if (!slider) return;
    let mouseDown = false;
    let startX: number;
    let scrollLeft: number;

    const startDragging = function (e: MouseEvent): void {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const stopDragging = function (): void {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }

  ngOnInit(): void {
    this.graph = new dia.Graph({}, { cellNamespace: shapes });
    this.initialiseJointJs();
    this.setJointJsEvents();
    this.generateDiagram();
  }

  ngAfterViewInit(): void {
    this.canvas.nativeElement.appendChild(this.paper.el);
    this.paper.unfreeze();
    CanvasComponent.initScroll();
    CanvasComponent.initZoom();
  }

  ngOnChanges(): void {
    if (this.paper && this.graph) {
      this.graph.clear();
      this.paper.setDimensions(this.calculateCanvasWidth(), this.height);
      this.generateDiagram();
    }
  }

  /**
   * Initialises the jointjs
   * @memberof CanvasComponent
   */
  initialiseJointJs(): void {
    // jointjs config
    this.paper = new dia.Paper({
      width: this.calculateCanvasWidth(),
      height: this.height,
      model: this.graph,
      frozen: true,
      async: true,
      sorting: dia.Paper.sorting.APPROX,
      cellViewNamespace: shapes,
      interactive: {
        elementMove: false,
        linkMove: false,
        useLinkTools: false,
        labelMove: false,
        vertexMove: true,
        addLinkFromMagnet: true, // Allow creating new links from magnets of elements
      },
      markAvailable: true,
      highlighting: false,
    });
  }

  /**
   * Calculate the canvas width based on the parent elements and their children
   * @memberof CanvasComponent
   */
  private calculateCanvasWidth(): number {
    const totalParentsWidth = this.parentElements.reduce((acc, parent) => acc + this.calculateParentWidth(parent), 0);

    const totalParentsGapWidth = this.gapBetweenParents * this.parentElements.length; // including gap between start and first parent

    return this.startX + this.childWidth + totalParentsWidth + totalParentsGapWidth + this.startX; // with startX gap on both sides
  }

  /**
   * Calculate the parent width based on its children
   * @param {ParentElementInterface} parent the parent element
   * @memberof CanvasComponent
   */
  private calculateParentWidth(parent: ParentElementInterface): number {
    const totalChildrenWidth = this.childWidth * parent.children.length;
    const totalGapWidth = this.gapBetweenSiblings * Math.max(0, parent.children.length - 1);
    return Math.max(this.childWidth, totalChildrenWidth + totalGapWidth);
  }

  /**
   * Set the joint js events to react to
   * @memberof CanvasComponent
   */
  setJointJsEvents(): void {
    // When a cell (stage) is highlighted
    this.paper.on('cell:highlight', (cellView, node, options) => {
      if (
        options.type === dia.CellView.Highlighting.CONNECTING &&
        this.nodes.find((child) => child.nodeId === cellView.model.id)
      ) {
        highlighters.stroke.removeAll(this.paper);
        highlighters.stroke.add(cellView, node, cellView.model.id.toString(), {
          attrs: { stroke: 'blue' },
        });
      }
    });

    // When a link enters a linkable element
    this.paper.on('link:connect', (linkView: dia.LinkView) => {
      const linkModel = linkView.model;
      const previousTarget: string = linkModel.get('previousTargetId');
      const linkId: string | undefined = linkModel.get('linkId');
      const source = linkModel.source().id?.toString();
      const target = linkModel.target().id?.toString();

      if (!source || !target || target === source || !this.nodes.find((child) => child.nodeId === target)) {
        // Update the link's target to be the newly connected target element
        linkModel.set('target', { id: previousTarget });

        // Trigger the 'change:target' event to update the link's appearance
        linkModel.trigger('change:target', linkModel, linkModel.get('target'));

        highlighters.stroke.removeAll(this.paper);

        return;
      }

      if (!previousTarget && !linkId) {
        linkModel.remove();
        this.newLink.emit({ source, target });
      }

      if (previousTarget && linkId) {
        this.updateLink.emit({ target, linkId });
      }
    });

    // When mouse hovers link

    this.paper.on('link:mouseenter', (linkView: dia.LinkView) => {
      if (linkView.model.source().id?.toString() === 'start-id') return;

      const toolsView = new dia.ToolsView({
        tools: [new linkTools.TargetArrowhead({ scale: 0 })],
      });
      linkView.addTools(toolsView);
      linkView.showTools();
    });

    // When mouse leaves link

    this.paper.on('link:mouseleave', (linkView: dia.LinkView) => {
      linkView.removeTools();

      const linkModel = linkView.model;
      const previousTarget: string | undefined = linkModel.get('previousTargetId');
      const source = linkModel.source().id?.toString();
      const target = linkModel.target().id?.toString();

      if (!previousTarget && !target) {
        linkModel.remove();
        highlighters.stroke.removeAll(this.paper);
        return;
      }

      if (!source || !target || target === source || !this.nodes.find((child) => child.nodeId === target)) {
        // Update the link's target to be the newly connected target element
        linkModel.set('target', { id: previousTarget });

        // Trigger the 'change:target' event to update the link's appearance
        linkModel.trigger('change:target', linkModel, linkModel.get('target'));

        highlighters.stroke.removeAll(this.paper);
      }
    });

    // When a element (rectangle) is clicked

    this.paper.on('element:pointerclick', (elementView: dia.ElementView) => {
      const element = elementView.model; // Get the element model

      const id = element.get('id');

      if (!id || id === 'start-id') return;

      // if it's a child else it's a parent
      if (element.hasPorts()) {
        this.selectChild.emit({ childId: id });
      } else {
        this.selectParent.emit({ parentId: id });
      }
    });

    this.paper.on('link:pointerclick', (linkView: dia.LinkView) => {
      const linkModel = linkView.model;
      const linkId = linkModel.get('linkId');
      this.selectLink.emit({ linkId });
    });
  }

  /**
   * Draws the canvas
   * @memberof CanvasComponent
   */
  drawCanvas(): void {
    let parentX = this.startX;

    // Draw the start rectangle
    this.drawStartRectangle(
      parentX,
      this.startY,
      diagramConstants.DIAGRAM_GROUP_ELEMENT_HEIGHT,
      this.childWidth,
      'Start'
    );

    parentX += this.childWidth + this.gapBetweenParents;
    this.parentElements.forEach((parent) => {
      const totalParentWidth = this.calculateParentWidth(parent);
      // Draw a state rectangle
      this.drawStateRectangle(
        parentX,
        this.startY,
        diagramConstants.DIAGRAM_GROUP_ELEMENT_HEIGHT,
        totalParentWidth,
        parent.name,
        parent.parentId
      );

      let childrenX = parentX;
      const childrenY = this.startY + diagramConstants.DIAGRAM_GROUP_ELEMENT_HEIGHT + this.gapBetweenParentAndChild;
      parent.children.forEach((child) => {
        // Draw a stage rectangle from the state
        this.drawStageRectangle(
          childrenX,
          childrenY,
          diagramConstants.DIAGRAM_STEP_ELEMENT_HEIGHT,
          this.childWidth,
          child.name,
          child.childId
        );

        childrenX += this.childWidth + this.gapBetweenSiblings;
      });

      parentX += totalParentWidth + this.gapBetweenParents;
    });

    const firstChild = this.parentElements
      .find((parent) => parent.children.length > 0)
      ?.children.find((child) => !!child.childId);

    if (firstChild) {
      // Draw the first line from start to the first child
      this.drawStartLink(firstChild.childId);
    }

    // Draw straight forward lines
    this.forwardDirectLines.forEach((line) => {
      if (!line.source.portId || !line.target.portId) return;
      this.drawStraightForwardLink(line.source.nodeId, line.target.nodeId, line.label, line.linkId);
    });

    // Draw distant forward lines
    Object.keys(this.distantLinesBySourceWithRowIndex)
      .flatMap((sourceId) => this.distantLinesBySourceWithRowIndex[sourceId])
      .forEach((line) => {
        if (!line.source.portId || !line.target.portId || !line.rowIndex) return;
        this.drawDistantForwardLink(
          line.source.nodeId,
          line.target.nodeId,
          line.source.portId,
          line.target.portId,
          line.rowIndex,
          line.label,
          line.linkId
        );
      });

    // Draw distant backward lines
    Object.keys(this.backwardLinesBySourceWithRowIndex)
      .flatMap((sourceId) => this.backwardLinesBySourceWithRowIndex[sourceId])
      .forEach((line) => {
        if (!line.source.portId || !line.target.portId || !line.rowIndex) return;
        this.drawDistantBackwardLink(
          line.source.nodeId,
          line.target.nodeId,
          line.source.portId,
          line.target.portId,
          line.rowIndex,
          line.label,
          line.linkId
        );
      });
  }

  /**
   * Set's all the nodes and nodes indexes
   * @memberof CanvasComponent
   */
  setNodes(): void {
    this.nodes = this.parentElements.flatMap((parent) =>
      parent.children.flatMap((child) => ({ nodeId: child.childId }))
    );

    this.nodeIdToIndex = {};
    this.nodes.forEach((node, index) => {
      this.nodeIdToIndex[node.nodeId] = index;
    });
  }

  /**
   * Set all the necessary lines without ports and row index
   * @memberof CanvasComponent
   */
  setDefaultLines(): void {
    this.lines = this.parentElements.flatMap((parent) =>
      parent.children.flatMap((child) =>
        child.connection.flatMap((connection) => ({
          parentId: parent.parentId,
          source: { nodeId: child.childId, portId: null },
          target: { nodeId: connection.destination, portId: null },
          rowIndex: null,
          isForward: this.isForwardDirection(child.childId, connection.destination),
          distanceByIndex: this.getDistance(child.childId, connection.destination),
          label: connection.label,
          linkId: connection.connectionId,
        }))
      )
    );

    this.linesByRelationship = this.lines.reduce<{ direct: LineInterface[]; distant: LineInterface[] }>(
      (acc, curr) => {
        const updated = { ...acc };
        if (this.isAdjacentSibling(curr.source.nodeId, curr.target.nodeId)) {
          updated.direct.push(curr);
        } else {
          updated.distant.push(curr);
        }
        return updated;
      },
      { direct: [], distant: [] }
    );

    this.linesByTarget = this.lines.reduce<Record<string, LineInterface[]>>((acc, curr) => {
      const updated = { ...acc };
      if (!updated[curr.target.nodeId]) {
        updated[curr.target.nodeId] = [];
      }
      updated[curr.target.nodeId].push(curr);
      return updated;
    }, {});

    this.linesBySource = this.lines.reduce<Record<string, LineInterface[]>>((acc, curr) => {
      const updated = { ...acc };
      if (!updated[curr.source.nodeId]) {
        updated[curr.source.nodeId] = [];
      }
      updated[curr.source.nodeId].push(curr);
      return updated;
    }, {});

    this.distantLinesByTarget = this.linesByRelationship.distant.reduce<Record<string, LineInterface[]>>(
      (acc, curr) => {
        const updated = { ...acc };
        if (!updated[curr.target.nodeId]) {
          updated[curr.target.nodeId] = [];
        }
        updated[curr.target.nodeId].push(curr);
        return updated;
      },
      {}
    );

    this.distantLinesBySource = this.linesByRelationship.distant.reduce<Record<string, LineInterface[]>>(
      (acc, curr) => {
        const updated = { ...acc };
        if (!updated[curr.source.nodeId]) {
          updated[curr.source.nodeId] = [];
        }
        updated[curr.source.nodeId].push(curr);
        return updated;
      },
      {}
    );
  }

  /**
   * Add ports and row index to lines
   * @memberof CanvasComponent
   */
  addPortsAndRowIndexesToLines(): void {
    this.resetMappingValues();

    this.nodes.forEach((node) => {
      this.addAllForwardDirectLines();
      this.addForwardDistantLines(node);
    });

    [...this.nodes].reverse().forEach((node) => {
      this.addBackwardLines(node);
    });
  }

  /**
   * Get the distance in terms of index from source to target
   * @param {string} sourceId the source node id
   * @param {string} targetId the target node id
   * @return {*}  {number}
   * @memberof CanvasComponent
   */
  getDistance(sourceId: string, targetId: string): number {
    return Math.abs(this.nodeIdToIndex[sourceId] - this.nodeIdToIndex[targetId]);
  }

  /**
   * Checks is the source node is adjacent to the target node
   * @param {string} sourceId the source id
   * @param {string} targetId the target id
   * @return {boolean} returns true if the elements are adjacent
   * @memberof CanvasComponent
   */
  isAdjacentSibling(sourceId: string, targetId: string): boolean {
    return (
      this.nodeIdToIndex[sourceId] + 1 === this.nodeIdToIndex[targetId] ||
      this.nodeIdToIndex[sourceId] - 1 === this.nodeIdToIndex[targetId]
    );
  }

  /**
   * Checks if the target node is in front of the source node
   * @param {string} sourceId the source id
   * @param {string} targetId the target id
   * @return {boolean} returns true if the direction is forward
   * @memberof CanvasComponent
   */
  isForwardDirection(sourceId: string, targetId: string): boolean {
    return this.nodeIdToIndex[sourceId] < this.nodeIdToIndex[targetId];
  }

  /**
   * Adds in memory all the forward straight lines for all nodes
   * @memberof CanvasComponent
   */
  addAllForwardDirectLines(): void {
    this.forwardDirectLines = this.linesByRelationship.direct
      .filter((line) => line.isForward)
      .map((line) => ({
        ...line,
        source: { ...line.source, portId: PortNameEnum.Right },
        target: { ...line.target, portId: PortNameEnum.Left },
      }));
  }

  /**
   * Adds in memory all the forward distant lines for the node
   * @param {NodeInterface} node the node to add forward distant lines
   * @return {void}
   * @memberof CanvasComponent
   */
  addForwardDistantLines(node: NodeInterface): void {
    if (!this.distantLinesBySource[node.nodeId]) return;

    const filterLinesByDirectionAndSortByDistance = this.distantLinesBySource[node.nodeId]
      .filter((line) => !!line.isForward)
      .sort((lineA, lineB) => lineA.distanceByIndex - lineB.distanceByIndex);

    filterLinesByDirectionAndSortByDistance.forEach((line) => {
      if (!this.nodes.find((nodes) => nodes.nodeId === line.target.nodeId)) return;

      const sourcePort = this.getSourceForwardPort(line.source.nodeId);
      const targetPort = this.getTargetForwardPort(line.target.nodeId);

      if (!this.distantLinesBySourceWithRowIndex[line.source.nodeId]) {
        this.distantLinesBySourceWithRowIndex[line.source.nodeId] = [];
      }

      if (!this.distantLinesByTargetWithRowIndex[line.target.nodeId]) {
        this.distantLinesByTargetWithRowIndex[line.target.nodeId] = [];
      }

      if (this.distantLinesByTargetWithRowIndex[line.target.nodeId].length > 0) {
        this.distantLinesBySourceWithRowIndex[line.source.nodeId].push({
          ...line,
          rowIndex: this.distantLinesByTargetWithRowIndex[line.target.nodeId][0].rowIndex,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
        this.distantLinesByTargetWithRowIndex[line.target.nodeId].push({
          ...line,
          rowIndex: this.distantLinesByTargetWithRowIndex[line.target.nodeId][0].rowIndex,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
      } else {
        const key = this.getAvailableForwardRowIndex(line.source.nodeId, line.target.nodeId);

        this.distantLinesBySourceWithRowIndex[line.source.nodeId].push({
          ...line,
          rowIndex: key,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
        this.distantLinesByTargetWithRowIndex[line.target.nodeId].push({
          ...line,
          rowIndex: key,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
      }
    });
  }

  /**
   * Adds in memory all the backward lines for the node
   * @param {NodeInterface} node the node to add backward lines
   * @return {void}
   * @memberof CanvasComponent
   */
  addBackwardLines(node: NodeInterface): void {
    if (!this.linesBySource[node.nodeId]) return;

    const filterLinesByDirectionAndSortByDistance = this.linesBySource[node.nodeId]
      .filter((line) => !line.isForward)
      .sort((lineA, lineB) => lineA.distanceByIndex - lineB.distanceByIndex);

    filterLinesByDirectionAndSortByDistance.forEach((line) => {
      if (!this.nodes.find((nodes) => nodes.nodeId === line.target.nodeId)) return;

      const sourcePort = this.getSourceBackwardPort(line.source.nodeId);
      const targetPort = this.getTargetBackwardPort(line.target.nodeId);

      if (!this.backwardLinesBySourceWithRowIndex[line.source.nodeId]) {
        this.backwardLinesBySourceWithRowIndex[line.source.nodeId] = [];
      }

      if (!this.backwardLinesByTargetWithRowIndex[line.target.nodeId]) {
        this.backwardLinesByTargetWithRowIndex[line.target.nodeId] = [];
      }

      if (this.backwardLinesByTargetWithRowIndex[line.target.nodeId].length > 0) {
        this.backwardLinesBySourceWithRowIndex[line.source.nodeId].push({
          ...line,
          rowIndex: this.backwardLinesByTargetWithRowIndex[line.target.nodeId][0].rowIndex,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
        this.backwardLinesByTargetWithRowIndex[line.target.nodeId].push({
          ...line,
          rowIndex: this.backwardLinesByTargetWithRowIndex[line.target.nodeId][0].rowIndex,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
      } else {
        const key = this.getAvailableBackwardRowIndex(line.source.nodeId, line.target.nodeId);

        this.backwardLinesBySourceWithRowIndex[line.source.nodeId].push({
          ...line,
          rowIndex: key,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
        this.backwardLinesByTargetWithRowIndex[line.target.nodeId].push({
          ...line,
          rowIndex: key,
          source: { ...line.source, portId: sourcePort },
          target: { ...line.target, portId: targetPort },
        });
      }
    });
  }

  /**
   * Get the correct top port for the forward line source
   * @param {string} sourceId the source id
   * @return {PortNameEnum} returns the correct source port
   * @memberof CanvasComponent
   */
  getSourceForwardPort(sourceId: string): PortNameEnum {
    // Checks if the source element is the source of more forward lines
    if (CanvasComponent.getAllLinesByDirection(this.distantLinesByTarget[sourceId] || [], true).length > 0) {
      return PortNameEnum.BottomRight;
    }
    return PortNameEnum.Bottom;
  }

  /**
   * Get the correct top port for the forward line target
   * @param {string} targetId the target id
   * @return {PortNameEnum} returns the correct target port
   * @memberof CanvasComponent
   */
  getTargetForwardPort(targetId: string): PortNameEnum {
    // Checks if the target element is the target of more forward lines
    if (CanvasComponent.getAllLinesByDirection(this.distantLinesBySource[targetId] || [], true).length > 0) {
      return PortNameEnum.BottomLeft;
    }
    return PortNameEnum.Bottom;
  }

  /**
   * Get the correct top port for the backward line source
   * @param {string} sourceId the source id
   * @return {PortNameEnum} returns the correct source port
   * @memberof CanvasComponent
   */
  getSourceBackwardPort(sourceId: string): PortNameEnum {
    // Checks if the source element is the source of more backward lines
    if (CanvasComponent.getAllLinesByDirection(this.linesByTarget[sourceId] || [], false).length > 0) {
      return PortNameEnum.TopLeft;
    }
    return PortNameEnum.Top;
  }

  /**
   * Get the correct top port for the backward line target
   * @param {string} targetId the target id
   * @return {PortNameEnum} returns the correct target port
   * @memberof CanvasComponent
   */
  getTargetBackwardPort(targetId: string): PortNameEnum {
    // Checks if the target element is the target of more backward lines
    if (CanvasComponent.getAllLinesByDirection(this.linesBySource[targetId] || [], false).length > 0) {
      return PortNameEnum.TopRight;
    }
    return PortNameEnum.Top;
  }

  /**
   * Get the available row index from source to target in the forward direction
   * @param {string} sourceId the id of the source element
   * @param {string} targetId the id of the target element
   * @return {number} returns the row index
   * @memberof CanvasComponent
   */
  getAvailableForwardRowIndex(sourceId: string, targetId: string): number {
    const range = CanvasComponent.getRange(this.nodeIdToIndex[sourceId], this.nodeIdToIndex[targetId]);

    const rowIndex = CanvasComponent.findAvailableRowIndex(this.mapForwardRowIndexToUseNodeIdToIndex, range);
    if (!rowIndex) {
      // if no row index is available create a new one
      this.forwardRowIndex += 1;
      this.mapForwardRowIndexToUseNodeIdToIndex[this.forwardRowIndex] = range;
      return this.forwardRowIndex;
    }
    this.mapForwardRowIndexToUseNodeIdToIndex[rowIndex] =
      this.mapForwardRowIndexToUseNodeIdToIndex[rowIndex].concat(range);
    return rowIndex;
  }

  /**
   * Get the available row index from source to target in the backward direction
   * @param {string} sourceId the id of the source element
   * @param {string} targetId the id of the target element
   * @return {number} returns the row index
   * @memberof CanvasComponent
   */
  getAvailableBackwardRowIndex(sourceId: string, targetId: string): number {
    const range = CanvasComponent.getRange(this.nodeIdToIndex[targetId], this.nodeIdToIndex[sourceId]);

    const rowIndex = CanvasComponent.findAvailableRowIndex(this.mapBackwardRowIndexToUseNodeIdToIndex, range);
    if (!rowIndex) {
      // if no row index is available create a new one
      this.backwardRowIndex += 1;
      this.mapBackwardRowIndexToUseNodeIdToIndex[this.backwardRowIndex] = range;
      return this.backwardRowIndex;
    }
    this.mapBackwardRowIndexToUseNodeIdToIndex[rowIndex] = range.concat(
      this.mapBackwardRowIndexToUseNodeIdToIndex[rowIndex]
    );
    return rowIndex;
  }

  /**
   * Draws the start rectangle in the paper model
   * @param {number} x the rectangle x starting axis point
   * @param {number} y the rectangle y starting axis point
   * @param {number} height the rectangle height
   * @param {number} width the rectangle width
   * @param {string} label the label of the rectangle
   * @memberof CanvasComponent
   */
  drawStartRectangle(x: number, y: number, height: number, width: number, label: string | null): void {
    const start = new shapes.standard.Rectangle({
      id: 'start-id',
      position: { x, y },
      size: { width, height },
      interactive: false,
      attrs: {
        body: {
          fill: diagramConstants.DIAGRAM_STEP_FILL_COLOUR,
          strokeWidth: 2,
          stroke: diagramConstants.DIAGRAM_ELEMENT_BORDER_COLOUR,
          rx: diagramConstants.DIAGRAM_GROUP_ELEMENT_RADIUS,
        },
        label: {
          text: label || UNTITLED,
          fill: diagramConstants.DIAGRAM_STEP_TEXT_COLOUR,
          fontSize: diagramConstants.DIAGRAM_FONT_SIZE,
          fontWeight: diagramConstants.DIAGRAM_FONT_WEIGHT,
        },
      },
      ports: {
        groups: {
          bottom: {
            position: {
              name: PortNameEnum.Bottom,
            },
            markup: this.portMarkup,
          },
        },
        items: [
          {
            id: PortNameEnum.Bottom,
            group: PortNameEnum.Bottom,
          },
        ],
      },
    });

    this.paper.model.addCell(start);
  }

  /**
   * Draws a state rectangle in the paper model
   * @param {number} x the rectangle x starting axis point
   * @param {number} y the rectangle y starting axis point
   * @param {number} height the rectangle height
   * @param {number} width the rectangle width
   * @param {string} label the label of the rectangle
   * @memberof CanvasComponent
   */
  drawStateRectangle(x: number, y: number, height: number, width: number, label: string | null, id: string): void {
    const parentRect = new shapes.standard.Rectangle({
      position: { x, y },
      size: { width, height },
      interactive: false,
      attrs: {
        body: {
          fill: diagramConstants.DIAGRAM_GROUP_FILL_COLOUR,
          strokeWidth: 2,
          rx: diagramConstants.DIAGRAM_GROUP_ELEMENT_RADIUS,
          stroke: diagramConstants.DIAGRAM_ELEMENT_BORDER_COLOUR,
        },
        label: {
          text: label || UNTITLED,
          fill: diagramConstants.DIAGRAM_GROUP_TEXT_COLOUR,
          fontSize: diagramConstants.DIAGRAM_FONT_SIZE,
          fontWeight: diagramConstants.DIAGRAM_FONT_WEIGHT,
        },
      },
      id,
    });

    this.paper.model.addCell(parentRect);
  }

  /**
   * Draws a stage rectangle in the paper model
   * @param {number} x the rectangle x starting axis point
   * @param {number} y the rectangle y starting axis point
   * @param {number} height the rectangle height
   * @param {number} width the rectangle width
   * @param {string} label the label of the rectangle
   * @memberof CanvasComponent
   */
  drawStageRectangle(x: number, y: number, height: number, width: number, label: string | null, id: string): void {
    const childRect = new shapes.standard.Rectangle({
      id,
      position: { x, y },
      size: { width, height },
      attrs: {
        body: {
          fill: diagramConstants.DIAGRAM_STEP_FILL_COLOUR,
          strokeWidth: 2,
          stroke: diagramConstants.DIAGRAM_ELEMENT_BORDER_COLOUR,
          magnet: true,
          rx: diagramConstants.DIAGRAM_STEP_ELEMENT_RADIUS,
        },
        label: {
          text: label || UNTITLED,
          fill: diagramConstants.DIAGRAM_STEP_TEXT_COLOUR,
          fontSize: diagramConstants.DIAGRAM_FONT_SIZE,
          fontWeight: diagramConstants.DIAGRAM_FONT_WEIGHT,
        },
      },
      ports: {
        groups: {
          left: {
            position: {
              name: PortNameEnum.Left,
            },
            markup: this.portMarkup,
          },
          right: {
            position: {
              name: PortNameEnum.Right,
            },
            markup: this.portMarkup,
          },
          top: {
            position: {
              name: PortNameEnum.Top,
            },
            markup: this.portMarkup,
          },
          bottom: {
            position: {
              name: PortNameEnum.Bottom,
            },
            markup: this.portMarkup,
          },
        },
        items: [
          {
            id: PortNameEnum.Right,
            group: PortNameEnum.Right,
          },
          {
            id: PortNameEnum.Left,
            group: PortNameEnum.Left,
          },
          {
            group: PortNameEnum.Top,
            id: PortNameEnum.TopLeft,
          },
          {
            group: PortNameEnum.Top,
            id: PortNameEnum.Top,
          },
          {
            group: PortNameEnum.Top,
            id: PortNameEnum.TopRight,
          },
          {
            group: PortNameEnum.Bottom,
            id: PortNameEnum.BottomLeft,
          },
          {
            group: PortNameEnum.Bottom,
            id: PortNameEnum.Bottom,
          },
          {
            group: PortNameEnum.Bottom,
            id: PortNameEnum.BottomRight,
          },
        ],
      },
    });

    // Store child rectangles in the map
    this.childrenMap[id] = childRect;

    this.paper.model.addCell(childRect);
  }

  /**
   * Draw a line from the start element to the first stage
   * @param {string} targetId the id of the target rectangle
   * @memberof CanvasComponent
   */
  drawStartLink(targetId: string): void {
    const childrenY =
      this.startY +
      diagramConstants.DIAGRAM_GROUP_ELEMENT_HEIGHT +
      this.gapBetweenParentAndChild +
      this.childrenMap[targetId].size().height / 2;

    const startLink = new shapes.standard.Link({
      source: {
        id: 'start-id',
        port: PortNameEnum.Bottom,
      },
      target: {
        id: this.childrenMap[targetId].id,
        port: PortNameEnum.Left,
      },
      attrs: {
        line: {
          stroke: diagramConstants.DIAGRAM_LINE_COLOUR,
          strokeWidth: 2,
        },
        connection: { stroke: 'blue', 'stroke-width': 2 },
      },
      vertices: [{ x: this.startX + this.childrenMap[targetId].size().width / 2, y: childrenY }],
      connector: {
        name: 'straight',
        args: {
          cornerRadius: this.vertexRadius, // Adjust the radius to control the curvature
          cornerType: 'cubic',
        },
      },
    });

    this.paper.model.addCell(startLink);
  }

  /**
   * Draw a straight line in the forward direction
   * @param {string} sourceId the id of the source rectangle
   * @param {string} targetId the id of the target rectangle
   * @memberof CanvasComponent
   */
  drawStraightForwardLink(sourceId: string, targetId: string, label: string | null, id: string): void {
    const sourceChildRect = this.childrenMap[sourceId];
    const targetChildRect = this.childrenMap[targetId];

    const link = new shapes.standard.Link({
      source: {
        id: sourceChildRect.id,
        port: PortNameEnum.Right,
      },
      target: {
        id: targetChildRect.id,
        port: PortNameEnum.Left,
      },
      attrs: {
        line: {
          stroke: diagramConstants.DIAGRAM_LINE_COLOUR,
          strokeWidth: diagramConstants.DIAGRAM_LINE_WIDTH,
        },
      },
      labels: [
        {
          attrs: {
            rect: {
              fill: diagramConstants.DIAGRAM_LINE_LABEL_FILL_COLOUR,
              stroke: diagramConstants.DIAGRAM_LINE_LABEL_BORDER_COLOUR,
            },
            text: {
              text:
                label ??
                `${sourceChildRect.attributes.attrs?.label?.text} - ${targetChildRect.attributes.attrs?.label?.text}`,
              fill: diagramConstants.DIAGRAM_LINE_LABEL_TEXT_COLOUR,
              fontSize: diagramConstants.DIAGRAM_FONT_SIZE,
              fontWeight: diagramConstants.DIAGRAM_FONT_WEIGHT,
            },
          },
        },
      ],
      previousTargetId: targetChildRect.id,
      linkId: id,
    });

    this.paper.model.addCell(link);
  }

  /**
   * Draw a distant line in the forward direction
   * @param {string} sourceId the id of the source rectangle
   * @param {string} targetId the id of the target rectangle
   * @param {PortNameEnum} sourcePortId source port id
   * @param {PortNameEnum} targetPortId target port id
   * @param {number} rowIndex the line source index
   * @memberof CanvasComponent
   */
  drawDistantForwardLink(
    sourceId: string,
    targetId: string,
    sourcePortId: PortNameEnum,
    targetPortId: PortNameEnum,
    rowIndex: number,
    label: string | null,
    id: string
  ): void {
    const rightToLeftRouteVertices = (sourcePoint: dia.Point, targetPoint: dia.Point, index = 1): dia.Point[] => [
      { x: sourcePoint.x, y: sourcePoint.y + this.rowHeight * index },
      { x: targetPoint.x, y: targetPoint.y + this.rowHeight * index },
    ];

    const sourceChildRect = this.childrenMap[sourceId];
    const targetChildRect = this.childrenMap[targetId];

    // Get the position of the specific port on the source child element
    const sourceChildRectCenter = sourceChildRect.getBBox().center();

    const sourceChildPort = sourceChildRect.getPortsPositions(PortNameEnum.Bottom)[sourcePortId];

    const sourceXOffset =
      sourceChildPort.x - sourceChildRect.getPortsPositions(PortNameEnum.Bottom)[PortNameEnum.Bottom].x;

    // Get the position of the specific port on the target child element
    const targetChildRectCenter = targetChildRect.getBBox().center();

    const targetChildPort = targetChildRect.getPortsPositions(PortNameEnum.Bottom)[targetPortId];

    const targetXOffset =
      targetChildPort.x - targetChildRect.getPortsPositions(PortNameEnum.Bottom)[PortNameEnum.Bottom].x;

    const distance =
      this.rowHeight * rowIndex - sourceXOffset - this.curveOffset + (this.gapBetweenSiblings + this.childWidth) / 2;

    const link = new shapes.standard.Link({
      source: {
        id: sourceChildRect.id,
        port: sourcePortId,
      },
      target: {
        id: targetChildRect.id,
        port: targetPortId,
      },
      attrs: {
        line: {
          stroke: diagramConstants.DIAGRAM_LINE_COLOUR,
          strokeWidth: diagramConstants.DIAGRAM_LINE_WIDTH,
        },
      },
      vertices: rightToLeftRouteVertices(
        {
          x: sourceChildRectCenter.x + sourceXOffset,
          y: sourceChildRectCenter.y + sourceChildRect.size().height / 2,
        },
        {
          x: targetChildRectCenter.x + targetXOffset,
          y: targetChildRectCenter.y + sourceChildRect.size().height / 2,
        },
        rowIndex
      ),
      labels: [
        {
          position: {
            distance,
          },
          attrs: {
            text: {
              text:
                label ??
                `${sourceChildRect.attributes.attrs?.label?.text} - ${targetChildRect.attributes.attrs?.label?.text}`,
              fill: diagramConstants.DIAGRAM_STEP_TEXT_COLOUR,
              fontSize: diagramConstants.DIAGRAM_FONT_SIZE,
              fontWeight: diagramConstants.DIAGRAM_FONT_WEIGHT,
            },
          },
        },
      ],
      connector: {
        name: 'straight',
        args: {
          cornerRadius: this.vertexRadius, // Adjust the radius to control the curvature
          cornerType: 'cubic',
        },
      },
      previousTargetId: targetChildRect.id,
      linkId: id,
    });

    this.paper.model.addCell(link);
  }

  /**
   * Draw a distant line in the backward direction
   * @param {string} sourceId the id of the source rectangle
   * @param {string} targetId the id of the target rectangle
   * @param {PortNameEnum} sourcePortId source port id
   * @param {PortNameEnum} targetPortId target port id
   * @param {number} rowIndex the line source index
   * @memberof CanvasComponent
   */
  drawDistantBackwardLink(
    sourceId: string,
    targetId: string,
    sourcePortId: PortNameEnum,
    targetPortId: PortNameEnum,
    rowIndex: number,
    label: string | null,
    id: string
  ): void {
    const leftToRightRouteVertices = (sourcePoint: dia.Point, targetPoint: dia.Point, index = 1): dia.Point[] => [
      { x: sourcePoint.x, y: sourcePoint.y - this.rowHeight * index },
      { x: targetPoint.x, y: targetPoint.y - this.rowHeight * index },
    ];

    const sourceChildRect = this.childrenMap[sourceId];
    const targetChildRect = this.childrenMap[targetId];

    // Get the position of the specific port on the source child element
    const sourceChildRectCenter = sourceChildRect.getBBox().center();

    const sourceChildPort = sourceChildRect.getPortsPositions(PortNameEnum.Top)[sourcePortId];

    const sourceXOffset = sourceChildPort.x - sourceChildRect.getPortsPositions(PortNameEnum.Top)[PortNameEnum.Top].x;

    // Get the position of the specific port on the target child element
    const targetChildRectCenter = targetChildRect.getBBox().center();

    const targetChildPort = targetChildRect.getPortsPositions(PortNameEnum.Top)[targetPortId];

    const targetXOffset = targetChildPort.x - targetChildRect.getPortsPositions(PortNameEnum.Top)[PortNameEnum.Top].x;

    const distance =
      this.rowHeight * rowIndex + sourceXOffset - this.curveOffset + (this.gapBetweenSiblings + this.childWidth) / 2;

    const link = new shapes.standard.Link({
      source: {
        id: sourceChildRect.id,
        port: sourcePortId,
      },
      target: {
        id: targetChildRect.id,
        port: targetPortId,
      },
      attrs: {
        line: {
          stroke: diagramConstants.DIAGRAM_LINE_COLOUR,
          strokeWidth: diagramConstants.DIAGRAM_LINE_WIDTH,
        },
      },
      vertices: leftToRightRouteVertices(
        {
          x: sourceChildRectCenter.x + sourceXOffset,
          y: sourceChildRectCenter.y - sourceChildRect.size().height / 2,
        },
        {
          x: targetChildRectCenter.x + targetXOffset,
          y: targetChildRectCenter.y - sourceChildRect.size().height / 2,
        },
        rowIndex
      ),
      labels: [
        {
          position: {
            distance,
          },
          attrs: {
            text: {
              text:
                label ??
                `${sourceChildRect.attributes.attrs?.label?.text} - ${targetChildRect.attributes.attrs?.label?.text}`,
              fill: diagramConstants.DIAGRAM_STEP_TEXT_COLOUR,
              fontSize: diagramConstants.DIAGRAM_FONT_SIZE,
              fontWeight: diagramConstants.DIAGRAM_FONT_WEIGHT,
            },
          },
        },
      ],
      connector: {
        name: 'rounded',
        args: {
          radius: this.vertexRadius, // Adjust the radius to control the curvature
        },
      },
      previousTargetId: targetChildRect.id,
      linkId: id,
    });

    this.paper.model.addCell(link);
  }

  /**
   * Reset all mapping values to default
   * @memberof CanvasComponent
   */
  resetMappingValues(): void {
    this.forwardRowIndex = 0;
    this.backwardRowIndex = 0;
    this.distantLinesByTargetWithRowIndex = {};
    this.distantLinesBySourceWithRowIndex = {};
    this.backwardLinesByTargetWithRowIndex = {};
    this.backwardLinesBySourceWithRowIndex = {};
    this.mapForwardRowIndexToSource = {};
    this.mapForwardRowIndexToUseNodeIdToIndex = {};
    this.mapBackwardRowIndexToSource = {};
    this.mapBackwardRowIndexToUseNodeIdToIndex = {};
    this.childrenMap = {}; // Map to store child rectangles based on their IDs
  }

  /**
   * Generate the diagram based on the parentElements data
   * @memberof CanvasComponent
   */
  generateDiagram(): void {
    this.setNodes();
    this.setDefaultLines();
    this.addPortsAndRowIndexesToLines();
    this.drawCanvas();
  }
}
