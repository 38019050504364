import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

import { BaseFacade, RequestErrorInterface } from '@surecloud/common';
import * as RequestErrorSelectors from '../selectors/request-error.selectors';

@Injectable({
  providedIn: 'root',
})
export class RequestErrorFacade extends BaseFacade {
  /**
   * Subject that emits a void value when the `getErrorById` method is called.
   * @memberof RequestErrorFacade
   */
  private getErrorByIdCalled$ = new Subject<void>();
  /**
   * Creates an instance of RequestErrorFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof RequestErrorFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }

  /**
   * Get the error entity by request id, if it exists. If not, does not emit.
   * @param {string} requestId The unique id of a request to the API.
   * @return {Observable<RequestErrorInterface>} Stream of one error.
   * @memberof RequestErrorFacade
   */
  getErrorById$ = (requestId: string): Observable<RequestErrorInterface> =>
    this.store.pipe(
      select(RequestErrorSelectors.getRequestErrorById(requestId)),
      filter((error): error is RequestErrorInterface => !!error)
    );

  /**
   * Get the error entity by request id, if it exists. If not, does not emit.
   * Auto closes the previous stream when a new one is requested.
   * ********************** NOTE **********************
   * While this implementation does some good cleaning up excessive streams,
   * there is a limitation to it. In the following scenario:
   * 1. You have more than one control on the page with Async Validation.
   * 2. The server returns a validation error for the first control very slowly.
   * 3. The user causes a validation error on the second control prior to the first control's validation error being returned.
   * 4. AND the validation error on the second control is returned very quickly (faster than the first control's validation error).
   * In this scenario, the first control's validation error will be ignored and the second control's validation error will be returned.
   * ********************** NOTE **********************
   * @param {string} requestId The unique id of a request to the API.
   * @return {Observable<RequestErrorInterface>} Stream of one error.
   * @memberof RequestErrorFacade
   */
  // TODO - rename to getErrorById$, captured in this ticket: https://surecloud.atlassian.net/browse/NG-3301
  getErrorByIdWithEnforcedSingleStream$ = (requestId: string): Observable<RequestErrorInterface> => {
    this.getErrorByIdCalled$.next();
    return this.getErrorById$(requestId).pipe(
      switchMap((error) => of(error)),
      takeUntil(this.getErrorByIdCalled$)
    );
  };
}
