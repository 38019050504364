<sc-advanced-grid
  rowSelection="multiple"
  [height]="height"
  [tabs]="tabs"
  [rowData]="rowData"
  [context]="context"
  [showPrimaryButton]="showPrimaryButton"
  [showSecondaryButton]="showSecondaryButton"
  [primaryButtonType]="primaryButtonType"
  [secondaryButtonType]="secondaryButtonType"
  [primaryLoadingButtonState]="primaryLoadingButtonState"
  [secondaryLoadingButtonState]="secondaryLoadingButtonState"
  [readonly]="readonly"
  [canDelete]="canDelete"
  [canExport]="canExport"
  [canImport]="canImport"
  [exportName]="exportName"
  [activeTab]="activeTab"
  [columnDefs]="columnDefs"
  [quickFilterText]="quickFilterText"
  [suppressScrollOnNewData]="suppressScrollOnNewData"
  [primaryButtonName]="primaryButtonName"
  [secondaryButtonName]="secondaryButtonName"
  [totalItems]="gridRecordData?.totalItems || 0"
  [paginationType]="paginationType"
  (cellClicked)="cellClicked.emit($event)"
  (rowsDeleted)="rowsDeleted.emit($event)"
  (primaryButtonClicked)="primaryButtonClicked.emit($event)"
  (secondaryButtonClicked)="secondaryButtonClicked.emit($event)"
  (activeTabChange)="activeTabChange.emit($event)"
  (selectionChanged)="selectionChanged.emit($event)"
  (cellValueChanged)="cellValueChanged.emit($event)"
  (exportAll)="exportAll.emit()"
  (exportSelectionEvent)="exportSelection.emit($event)"
  (importClickedEvent)="importClicked.emit()"
  (gridStateChangedEvent)="gridStateChanged.emit($event)"></sc-advanced-grid>
