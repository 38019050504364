import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NAVIGATE_ROW_COLUMN,
  RecordViewRoutesEnum,
  SCGridSchemaTypeEnum,
  StandaloneRouteNamesEnum,
  formatHumanReadable,
  getTimeOfDay,
} from '@surecloud/common';
import { GridRelativeDateRendererComponent } from '@surecloud/design';
import { FeatureFlagFacade, NG_4784_UPDATING_BRANDING_FOR_GARTNER } from '@surecloud/feature-flag';
import { HomepageApplicationsActions, HomepageApplicationsFeatureFacade } from '@surecloud/homepage-applications-state';
import { PriorityTasksFeatureFacade } from '@surecloud/priority-tasks-state';
import { RecentRecordsFeatureFacade } from '@surecloud/recent-records-state';
import { ToolbarTasksPageActions } from '@surecloud/toolbar-feature';
import { UserProfileFeatureFacade } from '@surecloud/user-profile';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable, first, map } from 'rxjs';
import { HomepageActions } from './+state/actions/homepage.actions';

@Component({
  selector: 'sc-standalone-homepage',
  templateUrl: './homepage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  /**
   * Is the Design Version 2 feature enabled.
   * @private
   * @memberof HomepageComponent
   */
  private readonly isDesignV2Enabled$ = this.features.featureIsEnabled$(NG_4784_UPDATING_BRANDING_FOR_GARTNER);

  /**
   * Determines whether to show or hide the secondary nav.
   * This is off on the Homepage if the Design Version 2 feature is enabled.
   * @memberof HomepageComponent
   */
  public readonly isSecondaryNavEnabled$ = this.isDesignV2Enabled$.pipe(map((isDesignV2Enabled) => !isDesignV2Enabled));

  /**
   * Column Definitions for the priority tasks grid
   * @type {ColDef[]}
   * @memberof HomepageComponent
   */
  priorityTasksColDefs: ColDef[] = [
    {
      field: NAVIGATE_ROW_COLUMN,
      type: SCGridSchemaTypeEnum.Navigate,
      headerName: '',
    },
    {
      field: $localize`summary`,
      type: SCGridSchemaTypeEnum.Text,
      headerName: $localize`Summary`,
      flex: 3,
      suppressSizeToFit: true,
    },
    {
      editable: false,
      field: $localize`due`,
      headerName: $localize`Due`,
      hide: false,
      type: SCGridSchemaTypeEnum.RelativeDate,
      cellRenderer: GridRelativeDateRendererComponent,
      suppressSizeToFit: true,
      flex: 1,
    },
  ];

  /**
   * Column Definitions for the Recent Records grid
   * @type {ColDef[]}
   * @memberof HomepageComponent
   */
  recentRecordsColDefs: ColDef[] = [
    {
      field: NAVIGATE_ROW_COLUMN,
      type: SCGridSchemaTypeEnum.Navigate,
      headerName: '',
    },
    {
      field: $localize`recordName`,
      type: SCGridSchemaTypeEnum.Text,
      headerName: $localize`Record`,
      flex: 3,
      suppressSizeToFit: true,
    },
    {
      editable: false,
      field: $localize`due`,
      headerName: $localize`Last viewed`,
      hide: false,
      type: SCGridSchemaTypeEnum.RelativeDate,
      cellRenderer: GridRelativeDateRendererComponent,
      suppressSizeToFit: true,
      flex: 2,
    },
  ];

  /**
   * Column Definitions for the Homepage Applications grid
   * @type {ColDef[]}
   * @memberof HomepageComponent
   */
  homepageApplicationsColDefs: ColDef[] = [
    {
      field: NAVIGATE_ROW_COLUMN,
      type: SCGridSchemaTypeEnum.Navigate,
      headerName: '',
    },
    {
      field: $localize`entityName`,
      type: SCGridSchemaTypeEnum.Text,
      headerName: $localize`Name`,
    },
    {
      field: $localize`entityDescription`,
      type: SCGridSchemaTypeEnum.Text,
      headerName: $localize`Description`,
    },
  ];

  /**
   * Homepage formatted date
   * @type {string}
   * @memberof HomepageComponent
   */
  formattedDate!: string;

  /**
   * Homepage message
   * @type {string}
   * @memberof HomepageComponent
   */
  morningOrAfternoon!: string;

  /**
   * Homepage greeting message
   * @memberof HomepageComponent
   */
  greeting$!: Observable<string>;

  /**
   * Creates an instance of HomepageComponent.
   * @param {FeatureFlagFacade} features The Feature Flag Service.
   * @param {UserProfileFeatureFacade} userProfileFacade The user profile facade.
   * @param {PriorityTasksFeatureFacade} priorityTasksFeatureFacade The priority tasks facade.
   * @param {RecentRecordsFeatureFacade} recentRecordsFeatureFacade The recent records facade.
   * @param {HomepageApplicationsFeatureFacade} homepageApplicationsFeatureFacade The homepage applications facade.
   * @param {Router} router The router.
   * @memberof HomepageComponent
   */
  constructor(
    private features: FeatureFlagFacade,
    private userProfileFacade: UserProfileFeatureFacade,
    public priorityTasksFeatureFacade: PriorityTasksFeatureFacade,
    public recentRecordsFeatureFacade: RecentRecordsFeatureFacade,
    public homepageApplicationsFeatureFacade: HomepageApplicationsFeatureFacade,
    private router: Router
  ) {}

  /**
   * On init dispatch an event to load entities.
   * @memberof HomepageComponent
   */
  ngOnInit(): void {
    this.priorityTasksFeatureFacade.dispatch(HomepageActions.homepageEnter());
    this.greeting$ = this.getGreeting();
    const currentDateTime = new Date();
    this.morningOrAfternoon = getTimeOfDay(currentDateTime);
    this.formattedDate = formatHumanReadable(currentDateTime);
  }

  /**
   * Navigates to the Entity.
   * @param {CellClickedEvent} event - the cell click event
   * @param {ColDef<unknown>} event.colDef - the cell column definition
   * @param {unknown} event.data - the cell data
   * @memberof HomepageComponent
   */
  entityCellClicked({ colDef, data }: CellClickedEvent): void {
    if (colDef.field === NAVIGATE_ROW_COLUMN && data.entityId) {
      const { entityId } = data;
      this.router.navigate([StandaloneRouteNamesEnum.RecordList, entityId]);
    }
  }

  /**
   * Navigates to the record.
   * @param {CellClickedEvent} event - the cell click event
   * @param {ColDef<unknown>} event.colDef - the cell column definition
   * @param {unknown} event.data - the cell data
   * @memberof HomepageComponent
   */
  recordCellClicked({ colDef, data }: CellClickedEvent): void {
    if (colDef.field === NAVIGATE_ROW_COLUMN && data.recordId) {
      const { recordId } = data;
      this.router.navigate(['/', RecordViewRoutesEnum.Root, recordId]);
    }
  }

  /**
   * When the Task List has been toggled to show.
   * Then update the service that controls the visibility of the Task List component.
   * And dispatch an action to get the latest tasks if it is about to be shown.
   * @param {Event} event The click event on the Task List Icon.
   * @memberof HomepageComponent
   */
  openToolbarTasks(event: Event): void {
    event.stopPropagation();
    this.priorityTasksFeatureFacade.dispatch(ToolbarTasksPageActions.toggle({ open: true }));
  }

  /**
   * When a Homepage Application Tab is clicked then select that tab
   * @param {number} tabIndex the tab index
   * @memberof HomepageComponent
   */
  changeTab(tabIndex: number): void {
    this.homepageApplicationsFeatureFacade.getTabs$.pipe(first()).subscribe((tabs) => {
      if (tabIndex > -1 && tabs.length > tabIndex) {
        this.homepageApplicationsFeatureFacade.dispatch(
          HomepageApplicationsActions.selectHomepageApplication({ applicationName: tabs[tabIndex].name })
        );
      }
    });
  }

  /**
   * Constructs the greeting message.
   * @return {string} the greeting string
   * @memberof HomepageComponent
   */
  getGreeting(): Observable<string> {
    return this.userProfileFacade.firstName$.pipe(
      map((firstName) =>
        firstName ? `Good ${this.morningOrAfternoon}, ${firstName}.` : `Good ${this.morningOrAfternoon}.`
      )
    );
  }
}
