/**
 * Types of components that can be shown in the components drag list.
 *
 * @export
 * @enum {number}
 */
export enum ComponentDragListItemTypeEnum {
  Information = 'INFORMATION',
  Report = 'REPORT',
  Table = 'TABLE',
}
