<header class="text-center sc-py-80 sc-bg-brand-500">
  <img class="sc-mb-40" i18n-alt alt="Aurora Logo" height="126" width="380" src="./assets/logos/aurora.png" />
  <h1 class="sc-text-lighest" i18n>Oops! Something went wrong</h1>
</header>

<main class="text-center sc-bg-disabled-bg sc-p-40 sc-font-heading-xl sc-text-grey-800">
  <p i18n>We have encountered an unexpected error.</p>
  <p i18n>
    We track these errors automatically, but if the problem persists please contact us using the support details.
  </p>
  <p class="sc-mb-32" i18n>For more information check the technical details below.</p>
  <a i18n sc-text-button themeColour="brand" size="large" fillMode="fill" [routerLink]="['/']">Go Home</a>
</main>

<sc-error-details
  [errorMessage]="errorMessage$ | async"
  [trackingId]="trackingId$ | async"
  [copyToClipboard]="copyToClipboard"></sc-error-details>
