import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { StandaloneRouteNamesEnum } from '@surecloud/common';
import { Observable, first, of, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
/**
 * Implements a route guard for the IDP login flow.
 * @export
 * @class IDPLoginGuard
 */
@Injectable({
  providedIn: 'root',
})
export class IDPLoginGuard {
  /**
   * Creates an instance of IDPLoginGuard.
   * @param {AuthService} authService - auth service
   * @param {Router} router - angular router
   * @memberof IDPLoginGuard
   */
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Implements canActivate to check that user is authenticated, otherwise logs them in.
   * @param {ActivatedRouteSnapshot} route - route snapshot
   * @return {*}  {(Observable<boolean | UrlTree>)}
   * @memberof IDPLoginGuard
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated().pipe(
      first(),
      switchMap((isAuthenticated) => {
        /**
         *  -------------------
         *   NOT AUTHENTICATED
         *  -------------------
         *  Handle the non-authenticated user by logging them in, if they have a code and orgId params.
         */
        const { organization: orgId, code } = route.queryParams;

        if (!isAuthenticated && code && orgId) {
          this.authService.tenantLogin(orgId).subscribe();
          return of(false);
        }
        /**
         *  ---------------
         *   AUTHENTICATED
         *  ---------------
         *  Send to the home page.
         */
        return of(this.router.parseUrl(`/${StandaloneRouteNamesEnum.Home}`));
      })
    );
  }
}
