import { PermissionApiInterface } from '@surecloud/api-types';

/**
 * RolePermissionInterface
 * @export
 * @interface RolePermissionInterface
 */
export type RolePermissionInterface = PermissionApiInterface;

/**
 * Role Interface.
 * @export
 * @property {string} roleId The role ID.
 * @property {string | null} description The role description.
 * @property {string | null} name The role name.
 * @property {string[] | undefined} permissions The role permissions.
 * @interface RoleInterface
 */
export interface RoleInterface {
  roleId: string;
  description?: string | null;
  name: string | null;
  permissions?: RolePermissionInterface[];
  availableToGuestUsers?: boolean | null;
}

/**
 * Makes the view entity with defaults and updates the viewId to the input
 * @param {string} roleId the roleId ypu are creating
 * @param {Partial<Omit<RoleInterface, 'roleId'>>} options Additional RoleInterface properties that may be passed as options.
 * @return {RoleInterface} populated view object
 */
export const makeRole = (roleId: string, options: Partial<Omit<RoleInterface, 'roleId'>> = {}): RoleInterface => ({
  roleId,
  name: options.name || null,
  description: options.description || null,
  permissions: options.permissions || [],
  availableToGuestUsers: options.availableToGuestUsers || null,
});

/**
 * Checks if object is an role.
 * @param {unknown} value The object to check.
 * @return {boolean} Whether the unknown object is an role.
 */
export const isRoleInterface = (value: unknown): value is RoleInterface => {
  const role = value as RoleInterface;
  return role?.roleId !== undefined;
};

/**
 * Type guard to check if an array is an array of Roles.
 * @param {unknown[]} array The array to check.
 * @return {boolean} if all items in the array are Roles.
 */
export const isRolesInterface = (array: unknown[]): array is RoleInterface[] => array.every(isRoleInterface);
