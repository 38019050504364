import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, mergeMap, of, switchMap } from 'rxjs';
import { LinkableRecordService } from '../../../services/linkable-records/linkable-record.service';
import { RecordLinkableActions } from '../../actions/linkable-records.actions';
/**
 * The Effects/side effects for LinkableRecords.
 *
 * @export
 * @class LinkableRecordsEffects
 */
@Injectable({ providedIn: 'root' })
export class LinkableRecordsEffects {
  /**
   * When the Record Linkable List is loaded,
   * Request all Linkable Records
   *
   * @memberof LinkableRecordsEffects
   */
  readRecordLinkableList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkableActions.readLinkableRecordList),
      switchMap(({ linkableRecordInputList }) =>
        from(linkableRecordInputList).pipe(
          mergeMap((data) =>
            this.linkableRecordService.read(data).pipe(
              map((normalisedRecordLink) =>
                RecordLinkableActions.readLinkableRecordsDataSuccess({ normalisedRecordLink })
              ),
              catchError((error: unknown) =>
                of(RecordLinkableActions.readLinkableRecordsDataFailure({ error: `${error}` }))
              )
            )
          )
        )
      )
    )
  );

  /**
   * When a user selects a new Record View Table tab,
   * Request Linkable Records for that Tab
   *
   * @memberof LinkableRecordsEffects
   */
  readLinkableRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkableActions.readLinkableRecord),
      switchMap(({ linkableRecordInput }) =>
        this.linkableRecordService.read(linkableRecordInput).pipe(
          map((normalisedRecordLink) => RecordLinkableActions.readLinkableRecordsDataSuccess({ normalisedRecordLink })),
          catchError((error: unknown) =>
            of(RecordLinkableActions.readLinkableRecordsDataFailure({ error: `${error}` }))
          )
        )
      )
    )
  );

  /**
   * When the linkable records data is read
   * Then store all the linked records
   *
   * @memberof LinkableRecordsEffects
   */
  loadLinkableRecordsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkableActions.readLinkableRecordsDataSuccess),
      map(({ normalisedRecordLink }) =>
        RecordLinkableActions.readLinkableRecordsSuccess({ recordLink: normalisedRecordLink.link })
      )
    )
  );

  /**
   * When the User confirm the records to link
   * Then the records are requested to the API,
   * And the records are stored locally
   *
   * @memberof LinkableRecordsEffects
   */
  linkRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkableActions.linkRecords),
      switchMap(({ tabId, linkRecords }) =>
        this.linkableRecordService.link(linkRecords).pipe(
          map(() => ({ recordsIds: linkRecords.linkedRecordIds })),
          map(({ recordsIds }) => RecordLinkableActions.linkRecordsSuccess({ tabId, recordsIds })),
          catchError((error: unknown) => of(RecordLinkableActions.linkFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * Creates an instance of LinkableRecordsEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {LinkableRecordService} linkableRecordService The Linkable Records service.
   * @memberof RecordViewEffects
   */
  constructor(private readonly actions$: Actions, private readonly linkableRecordService: LinkableRecordService) {}
}
