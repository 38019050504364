import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { HomepageApplicationsEffects } from './+state/effects/homepage-applications/homepage-applications.effects';
import {
  HOMEPAGE_APPLICATIONS_FEATURE_KEY,
  reducer,
} from './+state/reducers/homepage-applications/homepage-applications.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(HOMEPAGE_APPLICATIONS_FEATURE_KEY, reducer)],
  providers: [EffectsBootstrapProvider.provide([HomepageApplicationsEffects])],
})
export class HomepageApplicationsStateModule {}
