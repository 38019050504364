import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective, EntityAttributeRuleConditionTypeEnum, trackByIndex } from '@surecloud/common';
import { IconButtonComponent } from '../button/icon-button.component';
import { TextButtonComponent } from '../button/text-button.component';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';
import { TagComponent } from '../tag/tag.component';
import { convertConditionToLabel } from '../validation/validation-conditions.config';

/**
 * Component that displays a validation
 * @export
 * @class ConditionDisplayComponent
 */
@Component({
  selector: 'sc-condition-display',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LabelModule,
    TextBoxModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    E2eHookDirective,
    TextButtonComponent,
    SvgIconsModule,
    TagComponent,
    IconButtonComponent,
  ],
  templateUrl: './condition-display.component.html',
  styleUrls: ['./condition-display.component.scss'],
})
export class ConditionDisplayComponent {
  /**
   * Text label to display.
   * @memberof ConditionDisplayComponent
   */
  text = '';

  /**
   * The trackby function for Angular Loops.
   * @memberof ConditionDisplayComponent
   */
  trackBy = trackByIndex;

  /**
   * Text to display an additional description to the user.
   * @memberof ConditionDisplayComponent
   */
  @Input() set description(description: EntityAttributeRuleConditionTypeEnum | string | null) {
    this.text = convertConditionToLabel(description);
  }

  /**
   * Should the validation display component be disabled?
   * @memberof ConditionDisplayComponent
   */
  @Input()
  disable = false;

  /**
   * Sets the tag values for the tags.
   * @memberof ConditionDisplayComponent
   */
  @Input() tags: string[] = [];

  /**
   * Emit an event when the edit button is clicked.
   * @memberof ConditionDisplayComponent
   */
  @Output() edit = new EventEmitter();

  /**
   * Emit an when the delete button is clicked.
   * @memberof ConditionDisplayComponent
   */
  @Output() delete = new EventEmitter();
}
