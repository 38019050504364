import { Action, createReducer, on } from '@ngrx/store';
import { BreadcrumbInterface } from '../../models/breadcrumb/breadcrumb.model';
import { BreadcrumbActions, PageStateActions } from './page-state.actions';

export const PAGE_STATE_FEATURE_KEY = 'pageState';

export interface StateInterface {
  dashboardEditMode: boolean;
  breadcrumbs: BreadcrumbInterface | null;
}

export interface PageStatePartialStateInterface {
  readonly [PAGE_STATE_FEATURE_KEY]: StateInterface;
}

export const initialState: StateInterface = {
  dashboardEditMode: false,
  breadcrumbs: null,
};

/**
 * Page State reducer.
 */
const pageStateReducer = createReducer(
  initialState,

  on(PageStateActions.setDashboardEditMode, (state: StateInterface, { dashboardEditMode }) => ({
    ...state,
    dashboardEditMode,
  })),
  on(BreadcrumbActions.setBreadcrumbs, (state: StateInterface, { breadcrumbs }) => ({ ...state, breadcrumbs })),
  on(BreadcrumbActions.resetBreadcrumbs, () => initialState),
  on(BreadcrumbActions.addBreadcrumb, (state: StateInterface, { breadcrumb }) => ({
    ...state,
    breadcrumbs: state.breadcrumbs ? [...state.breadcrumbs, breadcrumb] : [breadcrumb],
  })),
  on(BreadcrumbActions.removeBreadcrumb, (state: StateInterface) => ({
    ...state,
    breadcrumbs: state.breadcrumbs ? state.breadcrumbs.slice(0, -1) : null,
  })),
  on(BreadcrumbActions.removeBreadcrumbs, (state: StateInterface, { index }) => ({
    ...state,
    breadcrumbs: state.breadcrumbs ? state.breadcrumbs.slice(0, index) : null,
  }))
);

/**
 * Perform reducer logic on the page state NGRX state store for a specific page state action.
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX page state action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return pageStateReducer(state, action);
}
