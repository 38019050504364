import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { PriorityTasksEffects } from './+state/effects/priority-tasks/priority-tasks.effects';
import { PRIORITY_TASKS_FEATURE_KEY, reducer } from './+state/reducers/priority-tasks/priority-tasks.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PRIORITY_TASKS_FEATURE_KEY, reducer),
  ],
  providers: [
    EffectsBootstrapProvider.provide([PriorityTasksEffects]),
  ],
})
export class PriorityTasksStateModule {}
