import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LinkedEntityTypeApiInterface } from '@surecloud/api-types';
import {
  EntityAttributeInterface,
  EntityAttributeTypeEnum,
  EntityInterface,
  HierarchyNodeInterface,
  UNTITLED,
} from '@surecloud/common';
import * as EntitySelectors from '../entity/entity.selectors';

import { LinkedEntityTagInterface } from '../../models/linked-entity-tag/linked-entity-tag.model';
import {
  LinkedEntityInterface,
  LinkedEntityTypeEnum,
  LinkedEntityWithTagsInterface,
} from '../../models/linked-entity/linked-entity.model';
import {
  LINKED_ENTITY_FEATURE_KEY,
  linkedEntityAdapter,
  StateInterface,
} from '../../reducers/linked-entity/linked-entity.reducer';
import * as AttributeSelectors from '../attribute/attribute.selectors';
import * as LinkedEntityTagsSelectors from '../linked-entity-tag/linked-entity-tag.selectors';
import { LINKED_ENTITY_ATTRIBUTES_NODE_ID } from './linked-entity.selectors.constants';

// Lookup the 'Entity Linked Entity' feature state managed by NgRx
export const getLinkedEntityState = createFeatureSelector<StateInterface>(LINKED_ENTITY_FEATURE_KEY);

const { selectAll, selectEntities } = linkedEntityAdapter.getSelectors();

/**
 * Get all Linked Entities.
 */
export const getLinkedEntityList = createSelector(getLinkedEntityState, (state: StateInterface) => selectAll(state));

/**
 * Get the Linked Entity NGRX entity map from Linked Entity state.
 */
export const getLinkedEntityEntities = createSelector(getLinkedEntityState, (state: StateInterface) =>
  selectEntities(state)
);

export const getSelectedLinkedEntityList = createSelector(
  EntitySelectors.getSelectedId,
  getLinkedEntityList,
  (selectedId, linkedEntityList) => linkedEntityList.filter((linkedEntity) => linkedEntity.entityId === selectedId)
);

export const getSelectedLinkedEntityTagList = createSelector(
  getSelectedLinkedEntityList,
  LinkedEntityTagsSelectors.getLinkedEntityTagList,
  EntitySelectors.getEntityEntities,
  (selectedLinkedEntityList, linkedEntityTagList, entities) => {
    const tagsList: LinkedEntityTagInterface[] = [];
    selectedLinkedEntityList.forEach((linkedEntity) => {
      const entity = entities[linkedEntity.linkedEntityId];
      const entityTags = linkedEntityTagList.filter((tag) => tag.linkedEntityId === linkedEntity.linkedEntityId);
      tagsList.push(
        {
          ...linkedEntity,
          label: entity?.name ? entity.name : UNTITLED,
          tagId: linkedEntity.linkedEntityId,
        },
        ...entityTags
      );
    });
    return tagsList;
  }
);

/**
 * Get a list of linked entities by entity ID.
 * @param {string} entityId The ID of the entity.
 * @param {LinkedEntityTypeApiInterface} linkType The link type of linked entities to get.
 * @return {EntityInterface[]} A list of linked entities for the entity ID.
 */
export const getLinkedEntitiesByEntityId = (
  entityId: string,
  linkType?: LinkedEntityTypeApiInterface
): MemoizedSelector<
  object,
  EntityInterface[],
  (s1: LinkedEntityInterface[], s2: Dictionary<EntityInterface>) => EntityInterface[]
> =>
  createSelector(getLinkedEntityList, EntitySelectors.getEntityEntities, (links, entities) =>
    links
      .filter((link) => link.entityId === entityId)
      .filter((link) => (linkType ? link.type === linkType : link))
      .map(({ linkedEntityId }) => entities[linkedEntityId])
      .filter((entity): entity is EntityInterface => !!entity)
  );

/**
 * Get a list of linked entities
 * @return {EntityInterface[]} A list of linked entities for the entity ID.
 */
export const getLinkedEntities = (): MemoizedSelector<
  object,
  LinkedEntityWithTagsInterface[],
  (
    s1: LinkedEntityInterface[],
    s2: Dictionary<EntityInterface>,
    s3: LinkedEntityTagInterface[]
  ) => LinkedEntityWithTagsInterface[]
> =>
  createSelector(
    getSelectedLinkedEntityList,
    EntitySelectors.getEntityEntities,
    LinkedEntityTagsSelectors.getLinkedEntityTagList,
    (links, entities, tags) =>
      links
        .map(({ linkedEntityId }) => entities[linkedEntityId])
        .filter((entity): entity is EntityInterface => !!entity)
        .map(({ entityId, name, description }, index) => ({
          entityId,
          name,
          description,
          type:
            links[index].type === LinkedEntityTypeApiInterface.MultipleApiInterface
              ? LinkedEntityTypeEnum.MULTIPLE
              : LinkedEntityTypeEnum.SINGLE,
          tags: tags.filter((tag) => tag.linkedEntityId === entityId),
        }))
        .filter((entity): entity is LinkedEntityWithTagsInterface => !!entity)
  );

/**
 * Get a list of non linked entities.
 * @return {EntityInterface[]} A list of linked entities for the entity ID.
 */
export const getLinkableEntities = (): MemoizedSelector<
  object,
  EntityInterface[],
  (s1: LinkedEntityInterface[], s2: EntityInterface[], s3: string) => EntityInterface[]
> =>
  createSelector(
    getSelectedLinkedEntityList,
    EntitySelectors.getEntityList,
    EntitySelectors.getSelectedId,
    (links, entities, entityId) => {
      const linkedEntityIds: string[] = links.map((le) => le.linkedEntityId);

      return entities.filter((e) => e.entityId !== entityId && !linkedEntityIds.includes(e.entityId));
    }
  );

/**
 * Get a list of linked entities.
 * @return {EntityInterface[]} A list of linked entities for the entity ID.
 */
export const getLinkedEntitiesOptions = createSelector(
  getSelectedLinkedEntityList,
  EntitySelectors.getEntityList,
  (links, entities) => {
    const linkedEntities = links.map((linkedEntity) =>
      entities.find((entity) => entity.entityId === linkedEntity.linkedEntityId)
    );

    return linkedEntities.map((entity) => ({
      text: entity?.name || UNTITLED,
      value: entity?.entityId,
    }));
  }
);

/**
 * Get a list of linked entities and a link to a entities attributes by entityId in HierarchyNodeInterface.
 * @param {string} entityId The entity ID to get the linked entities for.
 * @param {LinkedEntityTypeApiInterface} linkType The link type of linked entities to get.
 * @return {HierarchyNodeInterface} Data structure of linked entities with entity link to attributes.
 */
export const getLinkedEntitiesHierarchyByEntityId = (
  entityId: string,
  linkType?: LinkedEntityTypeApiInterface
): MemoizedSelector<
  object,
  HierarchyNodeInterface | undefined,
  (s1: EntityInterface | undefined, s2: EntityInterface[]) => HierarchyNodeInterface | undefined
> =>
  createSelector(
    EntitySelectors.getEntityById(entityId),
    getLinkedEntitiesByEntityId(entityId, linkType),
    (entity, entities) => {
      let hierarchy: HierarchyNodeInterface | undefined;

      if (entity) {
        const name = entity.name || UNTITLED;

        hierarchy = {
          name,
          id: entityId,
          nodes: [
            {
              name: $localize`${name} Attributes`,
              id: `${LINKED_ENTITY_ATTRIBUTES_NODE_ID}${entityId}`,
              icon: 'calculation',
            },

            ...entities.map(
              (item): HierarchyNodeInterface => ({
                name: item.name || UNTITLED,
                id: item.entityId,
                icon: 'link',
              })
            ),
          ],
        };
      }

      return hierarchy;
    }
  );

/**
 * Single entry point selector to get either a hierarchy interface of attributes from a linked entity
 * or a list of hierarchy interface of linked entities
 * depending on the ID that is passed to this selector.
 * @param {string} nodeId The node ID of the item that was clicked in the HierarchyComponent. Entity ID or LINKED_ENTITY_ATTRIBUTES_NODE_ID.
 * @param {EntityAttributeTypeEnum | EntityAttributeTypeEnum[] | undefined} type Attribute type(s) - get only attributes by a specific type.
 * @param {LinkedEntityTypeApiInterface} linkType The link type of linked entities to get.
 * @return {HierarchyNodeInterface} Returns a HierarchyNodeInterface with either linked entity attributes or linked entities.
 */
export const getLinkedEntityHierarchyByNodeId = (
  nodeId: string,
  type: EntityAttributeTypeEnum | EntityAttributeTypeEnum[] | undefined = undefined,
  linkType: LinkedEntityTypeApiInterface | undefined = undefined
):
  | MemoizedSelector<
      object,
      HierarchyNodeInterface | undefined,
      (s1: EntityInterface | undefined, s2: EntityAttributeInterface[]) => HierarchyNodeInterface | undefined
    >
  | MemoizedSelector<
      object,
      HierarchyNodeInterface | undefined,
      (s1: EntityInterface | undefined, s2: EntityInterface[]) => HierarchyNodeInterface | undefined
    > => {
  const entityId = nodeId.replace(LINKED_ENTITY_ATTRIBUTES_NODE_ID, '');

  // If the node contains the link to attributes constant - get the attributes for the entityID, else get the linked entities.
  return nodeId.includes(LINKED_ENTITY_ATTRIBUTES_NODE_ID)
    ? AttributeSelectors.getAttributesHierarchyByEntityId(entityId, type)
    : getLinkedEntitiesHierarchyByEntityId(entityId, linkType);
};
