<ng-container [formGroup]="parentFormGroup">
  <div class="flex">
    <sc-select-dropdown
      testhook="logic-builder:condition"
      class="flex-grow-1"
      controlName="condition"
      [marginBottom]="true"
      [options]="conditionOptions"
      [defaultItem]="defaultPlaceholder.condition"></sc-select-dropdown>
    <sc-delete-button
      size="large"
      class="sc-ml-10"
      (deleteClicked)="deleteLogicBuilder.emit()"
      testhook="logic-builder"></sc-delete-button>
  </div>

  <sc-multiselect-dropdown
    class="block"
    testhook="logic-builder:condition-values"
    controlName="conditionValues"
    [marginBottom]="true"
    [options]="conditionValueOptions"
    [placeholder]="defaultPlaceholder.conditionValues"></sc-multiselect-dropdown>

  <sc-select-dropdown
    class="block sc-mb-24"
    testhook="logic-builder:comparator"
    controlName="comparator"
    [options]="comparatorOptions"
    [valuePrimitive]="false"
    [marginBottom]="true"
    [defaultItem]="defaultPlaceholder.comparator"></sc-select-dropdown>

  <kendo-combobox
    *ngIf="parentFormGroup.controls['comparator'].value && parentFormGroup.controls['comparator'].value.condition"
    class="sc-select-dropdown sc-input--no-success-validation k-input-solid"
    [ngClass]="{
      'combobox-dropdown-open': comboboxPopupOpen
    }"
    [data]="comparatorValueOptions"
    [placeholder]="defaultPlaceholder.comparatorValue"
    (close)="comboboxPopupOpen = false"
    (open)="comboboxPopupOpen = true"
    [valuePrimitive]="false"
    formControlName="comparatorValue"
    scCommonE2eHook="logic-builder:comparator-value:form:combobox"
    textField="text"
    valueField="value"
    [valueNormalizer]="valueNormalizer"
    [popupSettings]="{
      popupClass: 'sc-select-dropdown__popup'
    }"
    [allowCustom]="true">
  </kendo-combobox>

  <div
    *ngIf="parentFormGroup.controls['comparator'].value && parentFormGroup.controls['comparator'].value.dateCondition"
    class="d-flex">
    <sc-input-number
      controlName="unit"
      [increment]="1"
      [autoCorrect]="true"
      [min]="0"
      class="w-full sc-mr-8"
      placeholder="Amount"
      i18n-placeholder>
    </sc-input-number>

    <sc-select-dropdown class="w-full" controlName="duration" [options]="DATE_UNIT_OPTIONS"> </sc-select-dropdown>
  </div>
</ng-container>
