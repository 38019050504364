import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fromPageState } from '@surecloud/common';
import { HomepageApplicationsActions } from '@surecloud/homepage-applications-state';
import { PriorityTasksActions } from '@surecloud/priority-tasks-state';
import { RecentRecordsActions } from '@surecloud/recent-records-state';
import { map } from 'rxjs';
import { ApplicationListActions } from '../../../application-list/+state/actions/application-list.actions';
import { HomepageActions } from '../actions/homepage.actions';

/**
 * The Effects/side effects for the Entity List Feature.
 * @export
 * @class HomepageEffects
 */
@Injectable({ providedIn: 'root' })
export class HomepageEffects {
  /**
   * When a user enters the home page.
   * Then dispatch a load Priority tasks action.
   * @memberof HomepageEffects
   */
  loadPriorityTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.homepageEnter),
      map(() => PriorityTasksActions.readPriorityTasks())
    )
  );

  /**
   * When a user enters the home page.
   * Then dispatch an action to unselect the homepage applications tab.
   * @memberof HomepageEffects
   */
  unSelectHomepageTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.homepageEnter),
      map(() => HomepageApplicationsActions.unSelectHomepageApplicationTab())
    )
  );

  /**
   * When a user enters the home page.
   * Then dispatch a load Recent Records action.
   * @memberof HomepageEffects
   */
  loadRecentRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.homepageEnter),
      map(() => RecentRecordsActions.readRecentRecords())
    )
  );

  /**
   * When a user enters the home page.
   * Then dispatch a reset breadcrumb action.
   * @memberof HomepageEffects
   */
  resetBreadcrumb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.homepageEnter),
      map(() => fromPageState.BreadcrumbActions.resetBreadcrumbs())
    )
  );

  /**
   * When a user enters the home page.
   * Then dispatch a load Homepage Applications action.
   * @memberof HomepageEffects
   */
  loadHomeApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.homepageEnter, ApplicationListActions.applicationListEnter),
      map(() => HomepageApplicationsActions.readHomepageApplications())
    )
  );

  /**
   * Creates an instance of HomepageEffects.
   * @param {Actions} actions$ The entity actions.
   * @memberof HomepageEffects
   */
  constructor(private readonly actions$: Actions) {}
}
