import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';

/**
 * The facade for the Record Import feature sits between the component layer and the
 * state layer and is responsible for providing store observables to,
 * and dispatching actions from the view layer
 * @export
 * @class RecordImportFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class RecordImportFacade extends BaseFacade {
  /**
   * Creates an instance of RecordImportFacade.
   * @param {Store} store The store
   * @memberof RecordImportFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
