import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { E2eHookDirectiveEnum } from './e2e-hook.directive.enum';

/**
 * This directive will add our data-[selector] attribute to the element for the E2E tests to use as a hook.
 * This keeps our test hook selector in a signle place and allows us to change it all over the app in one go if ever needed.
 *
 * @export
 * @class E2eHookDirective
 * @implements {OnInit}
 * @example
 * <div scCommonE2eHook="string-selector"></div>
 * <div [scCommonE2eHook]="variable"></div>
 * <div scCommonE2eHook="{{variable}}"></div>
 *
 * ## Result
 * <div data-test="string-selector"></div>
 */
@Directive({
  selector: '[scCommonE2eHook]',
  standalone: true,
})
export class E2eHookDirective implements OnInit {
  /**
   * Input the value for the test hook attribute.
   *
   * @type {string}
   * @memberof E2eHookDirective
   */
  @Input()
  scCommonE2eHook = '';

  /**
   * Creates an instance of E2eHookDirective.
   *
   * @param {ElementRef} element The host element the directive was added to.
   * @param {Renderer2} renderer The angular renderer to render the element.
   * @memberof E2eHookDirective
   */
  constructor(private readonly element: ElementRef, private readonly renderer: Renderer2) {}

  /**
   * On init add the test hook attribute to the host element.
   *
   * @memberof E2eHookDirective
   */
  ngOnInit(): void {
    if (this.scCommonE2eHook) {
      this.renderer.setAttribute(this.element.nativeElement, E2eHookDirectiveEnum.HOOK, this.scCommonE2eHook);
    }
  }
}
