import { createAction, props } from '@ngrx/store';
import { RequestErrorInterface } from '../../interfaces/request-error.interface';

/**
 * Actions to track error messages by request id.
 * @export
 */
export const RequestErrorActions = {
  errorOccurred: createAction('[Error] Occurred', props<RequestErrorInterface>()),
};
