import { Injectable } from '@angular/core';
import { ApplicationResultApiInterface } from '@surecloud/api-types';
import { LoggerService } from '@surecloud/common';
import {
  NormalisedHomepageApplications,
  getNormalisedHomepageApplicationsError,
  isNormalisedHomepageApplicationsGuard,
} from './normalise-get-homepage-applications.validation';

/**
 * Service encapsulating the schema and configuration for flattening the get Homepage Applications API response.
 *
 * @export
 * @class NormaliseGetHomepageApplicationsService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetHomepageApplicationsService {
  /**
   * Creates an instance of NormaliseGetHomepageApplicationsService
   *
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetHomepageApplicationsService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the Homepage Applications data from the HomepageApplications API response.
   *
   * @param {ApplicationResultApiInterface} homepageApplications The HomepageApplications data structure as it comes from the API.
   * @return {NormalisedHomepageApplications} UI HomepageApplications data structure.
   * @memberof NormaliseGetHomepageApplicationsService
   */
  extract(homepageApplications: ApplicationResultApiInterface): NormalisedHomepageApplications {
    if (!isNormalisedHomepageApplicationsGuard(homepageApplications)) {
      this.logger.logEvent(
        'Homepage Applications Feature',
        'Normalise Homepage Applications Data',
        getNormalisedHomepageApplicationsError(homepageApplications)
      );

      throw new Error('The result of the normalise Homepage Applications does not match the expected format.');
    }

    return homepageApplications;
  }
}
