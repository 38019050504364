import { UserProfilePermissionsEnum } from '@surecloud/common';

/**
 * User Profile Type enum.
 * @export
 * @enum {number}
 */
export enum UserProfileTypeEnum {
  GUEST = 'GUEST',
  USER = 'USER',
}

export interface FeatureFlagContextAttributeInterface {
  name: string;
  value: string;
}

export interface FeatureFlagContextInterface {
  kind: string;
  key: string;
  attributes: FeatureFlagContextAttributeInterface[];
}

/**
 * User Profile interface.
 * @property {string} firstName First name of the user.
 * @property {string} lastName Last name of the user.
 * @property {string} emailAddress Email Address of the user.
 * @property {string} userId The user's user ID.
 * @export
 * @interface UserProfileInterface
 */
export interface UserProfileInterface {
  type: UserProfileTypeEnum;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress: string;
  colour: string;
  initials: string;
  createPermissions: UserProfilePermissionsEnum[];
  deletePermissions: UserProfilePermissionsEnum[];
  readPermissions: UserProfilePermissionsEnum[];
  updatePermissions: UserProfilePermissionsEnum[];
  userId?: string | null;
  featureFlagSecureModeHash?: string | null;
  featureFlagContexts?: FeatureFlagContextInterface[];
}

/**
 * Checks if object is UserProfile.
 * @param {unknown} value The object to check.
 * @return {boolean} Whether the unknown object is a UserProfile.
 */
export const isUserProfile = (value: unknown): value is UserProfileInterface => {
  const user = value as UserProfileInterface;

  return (
    !!user.emailAddress &&
    !!user.createPermissions &&
    !!user.deletePermissions &&
    !!user.readPermissions &&
    !!user.updatePermissions &&
    !!user.colour
  );
};
