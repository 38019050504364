import { gql, QueryOptions } from '@apollo/client/core';

const AUTH_ORGANISATION_ID_QUERY = gql`
  query auth0OrganisationId($subdomain: String!) {
    auth0OrganisationId(subdomain: $subdomain)
  }
`;

export const apolloAuth0OrganisationIdQuery = (subdomain: string): QueryOptions => ({
  query: AUTH_ORGANISATION_ID_QUERY,
  variables: { subdomain },
});
