import { NgModule } from '@angular/core';
import { ColumnComponent } from './column/column.component';
import { RowComponent } from './row/row.component';
import { SectionComponent } from './section.component';

/**
 * Section Module
 *
 * @export
 * @class SectionModule
 */
@NgModule({
  imports: [SectionComponent, RowComponent, ColumnComponent],
  exports: [SectionComponent, RowComponent, ColumnComponent],
})
export class SectionModule {}
