import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectHierarchyNodeInterface } from '@surecloud/common';
import { Subject, takeUntil } from 'rxjs';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { RadioButtonsComponent } from '../radio-buttons/radio-buttons.component';
import { SELECT_HIERARCHY_NODES_FORM_CONTROLS } from './select-hierarchy-nodes.constants';
import { getSelectHierarchyNodesAsFlatArray } from './select-hierarchy.shared';

/**
 * Surecloud Select Hierarchy Node Component.
 * @export
 * @class SelectHierarchyNodesComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'sc-select-hierarchy-nodes',
  standalone: true,
  templateUrl: './select-hierarchy-nodes.component.html',
  styleUrls: ['./select-hierarchy-nodes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CheckboxComponent, CommonModule, CommonIconModule, RadioButtonsComponent, ReactiveFormsModule],
  providers: [FormBuilder],
})
export class SelectHierarchyNodesComponent implements OnChanges, OnDestroy {
  /**
   * When the component is destroyed.
   * Then update this stream so that other observables can tear down.
   * @private
   * @type {Subject<void>}
   * @memberof SelectHierarchyNodesComponent
   */
  private destroyed$: Subject<void> = new Subject();

  /**
   * Constants we need use within the Select Hierarchy Node class and view.
   * @memberof SelectHierarchyNodesComponent
   */
  public CONSTANTS = { FORM_CONTROLS: SELECT_HIERARCHY_NODES_FORM_CONTROLS };

  /**
   * The current selected hierarchy node we are on.
   * @type {SelectHierarchyNodeInterface}
   * @memberof SelectHierarchyNodesComponent
   */
  public currentNode: SelectHierarchyNodeInterface | undefined;

  /**
   * Keep track of all the nodes that have been navigated through.
   * The starting reference point is an array of all the base level nodes.
   * @type {SelectHierarchyNodeInterface[]}
   * @memberof SelectHierarchyNodesComponent
   */
  public previousNodes: (SelectHierarchyNodeInterface | SelectHierarchyNodeInterface[])[] = [];

  /**
   * Dynamically created form to track which hierarchy nodes have been (un)selected.
   * @type {FormGroup<{ [key: string]: FormControl }>}
   * @memberof SelectHierarchyNodesComponent
   */
  public form: FormGroup<{ [key: string]: FormControl }> = this.formBuilder.group({});

  /**
   * Expose the static track by function to the template.
   * @memberof SelectHierarchyNodesComponent
   */
  public trackByFunction = SelectHierarchyNodesComponent.trackByFn;

  /**
   * In order to support multiple single select hierarchy nodes on a page with radio buttons.
   * You must input a unique name for each component so that the radio buttons are grouped.
   * @type {string}
   * @memberof SelectHierarchyNodesComponent
   */
  @Input()
  public name: string = SELECT_HIERARCHY_NODES_FORM_CONTROLS.SINGLE_SELECT;

  /**
   * The hierarchy node data to display.
   * @type {SelectHierarchyNodeInterface[]}
   * @memberof SelectHierarchyNodesComponent
   */
  @Input()
  public nodes: SelectHierarchyNodeInterface[] = [];

  /**
   * Is a user able to select single or multiple node values.
   * @memberof SelectHierarchyNodesComponent
   */
  @Input()
  public singleSelect = true;

  /**
   * The ID value(s) of the currently selected nodes.
   * @type {string[]}
   * @memberof SelectHierarchyNodesComponent
   */
  @Input()
  public values: string[] = [];

  /**
   * When a user has selected a node.
   * Then emit an event with an array of the selected node IDs.
   * If it is single select - this array length will be 1.
   * @memberof SelectHierarchyNodesComponent
   */
  @Output()
  public selected = new EventEmitter<string[]>();

  /**
   * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
   * The function takes the iteration index and item ID, and in this case Angular will track changes by the item.id
   * @static
   * @param {number} index Index number
   * @param {SelectHierarchyNodeInterface} hierarchyNode A node in an Iterable
   * @return {string} Value to track the Angular looped item by.
   * @memberof SelectHierarchyNodesComponent
   */
  public static trackByFn(index: number, hierarchyNode: SelectHierarchyNodeInterface): string {
    return hierarchyNode.id;
  }

  /**
   * Creates an instance of SelectHierarchyNodesComponent.
   * @param {FormBuilder} formBuilder The Angular Form Builder service.
   * @memberof SelectHierarchyNodesComponent
   */
  constructor(private formBuilder: FormBuilder) {}

  /**
   * When new nodes, values or a change in the selection type of the hierarchy node component has been input.
   * Then make sure to reset any form state and update the current selected hierarchy node values.
   * @param {SimpleChanges} changes The simple changes being input into the component.
   * @memberof SelectHierarchyNodesComponent
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const { nodes, singleSelect, values } = changes;

    if (nodes || singleSelect) {
      this.reset();
      this.changes();
    }

    if (values) {
      this.setValues();
    }
  }

  /**
   * When the component has been destroyed.
   * Then update the destroyed$ stream so that other observables can tear down.
   * @memberof SelectHierarchyNodesComponent
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * When the select hierarchy node form has changed.
   * Then emit any selected values.
   * @private
   * @memberof SelectHierarchyNodesComponent
   */
  private changes(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      const selected: string[] = this.singleSelect
        ? [value[this.CONSTANTS.FORM_CONTROLS.SINGLE_SELECT]]
        : Object.keys(value).filter((id) => value[id]);

      this.selected.emit(selected);
    });
  }

  /**
   * When input data has changed.
   * Then update the form, current node and options.
   * @private
   * @memberof SelectHierarchyNodesComponent
   */
  private reset(): void {
    const options = this.nodes
      .flatMap((nodes) => getSelectHierarchyNodesAsFlatArray(nodes))
      .filter((node) => node.isSelectable);

    this.currentNode = undefined;
    this.previousNodes = [];

    // Destroy the current form and subscriptions as we will rebuild it
    this.destroyed$.next();
    this.form = this.formBuilder.group({});

    // Depending on single or multiple selection type - create the form controls
    if (this.singleSelect) {
      this.form.addControl(this.CONSTANTS.FORM_CONTROLS.SINGLE_SELECT, new FormControl());
    } else {
      options.forEach((option) => this.form.addControl(option.id, new FormControl()));
    }
  }

  /**
   * When new selected values have been input into the hierarchy node component.
   * Then ensure these values are set on the correct form control.
   * @private
   * @memberof SelectHierarchyNodesComponent
   */
  private setValues(): void {
    const { controls } = this.form;
    const options = { emitEvent: false };

    if (this.singleSelect) {
      controls[this.CONSTANTS.FORM_CONTROLS.SINGLE_SELECT].setValue(this.values[0], options);
    } else {
      Object.keys(controls).forEach((id) => controls[id].setValue(this.values.includes(id), options));
    }
  }

  /**
   * When a user clicks back to a previous hierarchy node.
   * Then update the currentNode to the previous hierarchy node.
   * @param {MouseEvent} [event] The mouse click event if triggered via a mouse click.
   * @memberof SelectHierarchyNodesComponent
   */
  public goToPreviousNode(event?: MouseEvent): void {
    const previousNode = this.previousNodes.pop();

    event?.stopPropagation();

    // We are at the beginning of the hierarchy data structure - show all top level hierarchy nodes
    if (Array.isArray(previousNode)) {
      this.currentNode = undefined;
      this.nodes = previousNode;
    }
    // Show the nodes of the previous hierarchy node
    else {
      this.currentNode = previousNode;
      this.nodes = previousNode?.nodes || [];
    }
  }

  /**
   * When a user clicks a hierarchy node that has child nodes.
   * Then update list of hierarchy nodes with the clicked hierarchy nodes child nodes.
   * @param {SelectHierarchyNodeInterface} node The clicked hierarchy node.
   * @param {MouseEvent} [event] The mouse event.
   * @memberof SelectHierarchyNodesComponent
   */
  public selectNode(node: SelectHierarchyNodeInterface, event?: MouseEvent): void {
    event?.stopPropagation();

    // Don't do anything here as the form control has been clicked so we will keep a user on the current node
    if (event?.target instanceof Element && event.target instanceof HTMLInputElement) {
      return;
    }

    // If the click hierarchy node has child nodes - update to them
    if (node.nodes?.length) {
      this.previousNodes.push(this.currentNode || this.nodes);
      this.currentNode = node;
      this.nodes = node.nodes;
    }
  }
}
