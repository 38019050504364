import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import { map } from 'rxjs';
import * as GlobalNavSelectors from '../selectors/global-nav/global-nav-list.selectors';
import * as ToolbarSelectors from '../selectors/toolbar/toolbar.selectors';

/**
 * The facade for the Toolbar feature
 * @export
 * @class ToolbarFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class ToolbarFeatureFacade extends BaseFacade {
  /**
   * Get the User Menu configuration.
   * @memberof ToolbarFeatureFacade
   */
  navItems$ = this.store.pipe(select(GlobalNavSelectors.getNavItemsNested));

  /**
   * Get the User Menu configuration.
   * @memberof ToolbarFeatureFacade
   */
  userMenuConfig$ = this.store.pipe(select(ToolbarSelectors.getUserMenuConfig));

  /**
   * Show Global Menu.
   * @memberof ToolbarFeatureFacade
   */
  canViewGlobalMenu$ = this.store.pipe(select(ToolbarSelectors.getCanViewGlobalMenu));

  /**
   * Show User Menu.
   * @memberof ToolbarFeatureFacade
   */
  canViewUserMenu$ = this.store.pipe(select(ToolbarSelectors.getCanViewUserMenu));

  /**
   * Show Tasks button.
   * @memberof ToolbarFeatureFacade
   */
  canViewTasks$ = this.store.pipe(select(ToolbarSelectors.getCanViewTasks));

  /**
   * Show Tasks button.
   * @memberof ToolbarFeatureFacade
   */
  canViewSecondaryNav$ = this.store.pipe(select(ToolbarSelectors.getCanViewSecondaryNav));

  /**
   * Show Tasks button.
   * @memberof ToolbarFeatureFacade
   */
  globalNavIsStandalone$ = this.store.pipe(
    select(ToolbarSelectors.getCanViewSecondaryNav),
    map((canViewSecondaryNav) => !canViewSecondaryNav)
  );

  /**
   * Creates an instance of ToolbarFeatureFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof ToolbarFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
