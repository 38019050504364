<ng-container *ngIf="logicItem">
  <span *ngIf="logicItem.title" class="block sc-mb-12 sc-mt-4 sc-font-guidance-text">{{ logicItem.title }}</span>
  <div class="flex justify-content-between">
    <div>
      <ng-container *ngIf="direction === 'start'; else reverse">
        <sc-tag *ngFor="let tag of logicItem.tagNames; trackBy: trackByIndex" size="lg" class="inline-block sc-mr-4">{{
          tag
        }}</sc-tag>
        {{ logicItem.text }}
      </ng-container>
      <ng-template #reverse>
        {{ logicItem.text }}
        <sc-tag *ngFor="let tag of logicItem.tagNames; trackBy: trackByIndex" size="lg" class="inline-block sc-ml-4">{{
          tag
        }}</sc-tag>
      </ng-template>
    </div>
    <sc-icon-button
      *ngIf="editable"
      (click)="startEdit.emit()"
      themeColour="white"
      icon="pencil"
      class="d-inline-block"
      size="small"
      shape="rounded">
    </sc-icon-button>
  </div>
</ng-container>
