<div class="sc-rating-scaling-colour sc-p-16" [ngClass]="{ 'sc-rating-scaling-colour__editing': !isEditing }">
  <p i18n class="sc-rating-scaling-colour__title sc-pb-16 sc-mb-4">Cell Colour Picker</p>
  <data
    *ngFor="let rating of ratingList; trackBy: trackByFunction"
    class="sc-rating-scaling-colour__content flex w-full align-items-center sc-mb-8">
    <option
      [disabled]="!isEditing"
      (keydown.enter)="colourSelected.emit(rating)"
      (click)="colourSelected.emit(rating)"
      class="sc-rating-scaling-colour__content--circle"
      [ngStyle]="{ 'background-color': rating.colour }"></option>
    <span i18n class="sc-rating-scaling-colour__content--label">{{ rating.label }}</span>
  </data>
</div>
