import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FeatureFlagActions } from '../actions/feature-flag.actions';

export const FEATURE_FLAG_FEATURE_KEY = 'featureFlag';

export interface StateInterface extends EntityState<boolean> {
  loaded: boolean;
  selectedId?: string;
  error?: string | null;
}

export interface FeatureFlagPartialStateInterface {
  readonly [FEATURE_FLAG_FEATURE_KEY]: StateInterface;
}

export const featureFlagAdapter: EntityAdapter<boolean> = createEntityAdapter<boolean>();

export const initialState: StateInterface = featureFlagAdapter.getInitialState({
  loaded: false,
});

/**
 * Feature Flag reducer.
 */
const featureFlagReducer = createReducer(
  initialState,

  on(FeatureFlagActions.readFeatureFlags, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(FeatureFlagActions.readFeatureFlagsSuccess, (state: StateInterface, { featureFlags }) => ({
    ...state,
    loaded: true,
    entities: featureFlags,
  })),
  on(FeatureFlagActions.readFeatureFlagsFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  }))
);

/**
 * Perform reducer logic on the Feature Flag NGRX state store for a specific Feature Flag actions.
 *
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX Feature Flag action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return featureFlagReducer(state, action);
}
