import { isStringGuard } from './is-string.guard';

/**
 * Type Guard for Arrays
 * Uses a check on the .toISOString prop to verify its a array object.
 *
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfStringsGuard = (value: unknown): value is Array<string> =>
  Array.isArray(value) && value.every((item) => isStringGuard(item));

/**
 * Type Guard for Arrays
 *
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);
