<sc-sidebar-layout [expanded]="expanded">
  <div sidebar-content>
    <sc-navigation-list
      [navigationItems]="navigationItems"
      [activeNavigationItemId]="activeNavigationItem$ | async"
      (activeNavigationItem)="scrollToSection($event.id)">
    </sc-navigation-list>
  </div>
  <div sidebar-footer>
    <ng-content select="[footer-sidebar]"></ng-content>
  </div>
  <div main-content>
    <div class="sc-pb-80">
      <ng-content></ng-content>
    </div>
  </div>
</sc-sidebar-layout>
<sc-page-footer>
  <div class="flex justify-content-between align-items-center w-full">
    <button
      [style.visibility]="isBackToTopButtonVisible ? 'visible' : 'hidden'"
      i18n
      sc-text-button
      fillMode="outline"
      size="large"
      themeColour="brand"
      (click)="scrollToTop()"
      class="sc-min-width-none--mobile cursor-pointer">
      <sc-icon colour="brand-500" key="arrow-up"></sc-icon>
      <span class="sc-visually-hidden--mobile sc-ml-6">Back to top</span>
    </button>
    <ng-content select="[footer-main]"></ng-content>
  </div>
</sc-page-footer>
