import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { UserProfilePermissionsEnum } from '@surecloud/common';
import { UserProfileInterface, UserProfileTypeEnum } from '../models/user-profile.models';
import { StateInterface, USER_PROFILE_FEATURE_KEY } from '../reducers/user-profile.reducer';
import { UserProfilePermissionTypesEnum } from './user-profile.selectors.enum';

// Lookup the User Profile feature state managed by NgRx
export const getUserProfileState = createFeatureSelector<StateInterface>(USER_PROFILE_FEATURE_KEY);

/**
 * Standard state selectors
 */
export const getUserProfile = createSelector(getUserProfileState, (state: StateInterface) => state.userProfile);
export const getUserProfileLoaded = createSelector(getUserProfileState, (state: StateInterface) => state.loaded);
export const getFirstName = createSelector(
  getUserProfileState,
  (state: StateInterface) => state.userProfile?.firstName
);

/**
 * Check if a user has a specific permission.
 * @param type
 * @param {UserProfilePermissionsEnum} permission The permission to check if a user has.
 * @return {boolean} Whether the user has the permission.
 */
export const hasUserProfilePermission = (
  type: UserProfilePermissionTypesEnum,
  permission: UserProfilePermissionsEnum
): MemoizedSelector<object, boolean, (s1: UserProfileInterface | undefined) => boolean> =>
  createSelector(getUserProfile, (userProfile) => (userProfile ? userProfile[type].includes(permission) : false));

/**
 * Get the full name of a user from the User Profile.
 */
export const getUserProfileFullName = createSelector(getUserProfile, (profile) => {
  if (!profile) return '';

  const { firstName, lastName } = profile;

  let fullName = '';

  if (firstName) {
    fullName += `${firstName}`;
  }

  if (lastName && firstName) {
    fullName += ' ';
  }

  if (lastName) {
    fullName += `${lastName}`;
  }

  return fullName;
});

/**
 * Get the full name of a user from the User Profile.
 */
export const getIsStandardUserType = createSelector(
  getUserProfile,
  (userProfile) => userProfile?.type === UserProfileTypeEnum.USER
);
