import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '@progress/kendo-angular-buttons';
import { ScButtonBorder, ScButtonFillMode, ScButtonThemeColour } from './button.constants';
import { TextButtonComponent } from './text-button.component';

@Component({
  selector: 'sc-modal-buttons',
  standalone: true,
  imports: [CommonModule, TextButtonComponent],
  templateUrl: './modal-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalButtonsComponent {
  /**
   * Event output when the secondary button is clickeed.
   * @type {EventEmitter<void>}
   * @memberof ModalButtonsComponent
   */
  @Output() secondaryClicked: EventEmitter<void> = new EventEmitter();

  /**
   * Should the secondary button be disabled.
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonDisabled = false;

  /**
   * Should the secondary button display.
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonShow = true;

  /**
   * Set the title for the secondary button
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonTitle = 'Cancel';

  /**
   * Sets the preset size of the secondary button.
   * @type {ButtonSize}
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonSize: ButtonSize = 'large';

  /**
   * Set the theme colour for the secondary button.
   * @type {ScButtonThemeColour}
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonTheme: ScButtonThemeColour = 'primary';

  /**
   * Set the fill mode for the secondary button
   * @type {ScButtonFillMode}
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonFillmode: ScButtonFillMode = 'outline';

  /**
   * Sets the preset border of the secondary button.
   * @type {ScButtonBorder}
   * @memberof ModalButtonsComponent
   */
  @Input() secondaryButtonBorder: ScButtonBorder = 'default';

  /**
   * Event output when the primary button is clickeed.
   * @type {EventEmitter<void>}
   * @memberof ModalButtonsComponent
   */
  @Output() primaryClicked: EventEmitter<void> = new EventEmitter();

  /**
   * Should the primary button show.
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonShow = true;

  /**
   * Should the primary button be disabled.
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonDisabled = false;

  /**
   * Set the title for the primary button
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonTitle = 'Confirm';

  /**
   * Sets the preset size of the primary button.
   * @type {ButtonSize}
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonSize: ButtonSize = 'large';

  /**
   * Set the theme colour for the primary button.
   * @type {ScButtonThemeColour}
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonTheme: ScButtonThemeColour = 'primary';

  /**
   * Set the fill mode for the primary button
   * @type {ScButtonFillMode}
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonFillmode: ScButtonFillMode = 'fill';

  /**
   * Sets the preset border of the primary button.
   * @type {ScButtonBorder}
   * @memberof ModalButtonsComponent
   */
  @Input() primaryButtonBorder: ScButtonBorder = 'default';
}
