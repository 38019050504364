export const PDF_EXTENSION = '.pdf';
export const DOC_EXTENSION = '.doc';
export const DOT_EXTENSION = '.dot';
export const WBK_EXTENSION = '.wbk';
export const DOCX_EXTENSION = '.docx';
export const DOCM_EXTENSION = '.docm';
export const DOTX_EXTENSION = '.dotx';
export const DOTM_EXTENSION = '.dotm';
export const DOCB_EXTENSION = '.docb';
export const XLS_EXTENSION = '.xls';
export const XLT_EXTENSION = '.xlt';
export const XLM_EXTENSION = '.xlm';
export const XLSX_EXTENSION = '.xlsx';
export const XLSM_EXTENSION = '.xlsm';
export const XSLT_EXTENSION = '.xltx';
export const XLTM_EXTENSION = '.xltm';
export const XLSB_EXTENSION = '.xlsb';
export const XLA_EXTENSION = '.xla';
export const XLAM_EXTENSION = '.xlam';
export const MPP_EXTENSION = '.mpp';
export const XLL_EXTENSION = '.xll';
export const XLW_EXTENSION = '.xlw';
export const PPT_EXTENSION = '.ppt';
export const POT_EXTENSION = '.pot';
export const PPS_EXTENSION = '.pps';
export const PPTX_EXTENSION = '.pptx';
export const PPTM_EXTENSION = '.pptm';
export const POTX_EXTENSION = '.potx';
export const POTM_EXTENSION = '.potm';
export const PPAM_EXTENSION = '.ppam';
export const PPSX_EXTENSION = '.ppsx';
export const PPSM_EXTENSION = '.ppsm';
export const SLDX_EXTENSION = '.sldx';
export const SLDM_EXTENSION = '.sldm';
export const PUB_EXTENSION = '.pub';
export const VSD_EXTENSION = '.vsd';
export const VSDX_EXTENSION = '.vsdx';
export const ODT_EXTENSION = '.odt';
export const XML_EXTENSION = '.xml';
export const CSV_EXTENSION = '.csv';
export const WPS_EXTENSION = '.wps';
export const SEVEN_ZIP_EXTENSION = '.7zip';
export const RTF_EXTENSION = '.rtf';
export const RAR_EXTENSION = '.rar';
export const ZIP_EXTENSION = '.zip';
export const TXT_EXTENSION = '.txt';
export const MP4_EXTENSION = '.mp4';
export const MP3_EXTENSION = '.mp3';
export const AVI_EXTENSION = '.avi';
export const FLV_EXTENSION = '.flv';
export const WMV_EXTENSION = '.wmv';
export const MOV_EXTENSION = '.mov';
export const TIF_EXTENSION = '.tif,';
export const TIFF_EXTENSION = '.tiff';
export const GIF_EXTENSION = '.gif';
export const JPEG_EXTENSION = '.jpeg';
export const JPG_EXTENSION = '.jpg';
export const JIF_EXTENSION = '.jif';
export const JFIF_EXTENSION = '.jfif';
export const JP2_EXTENSION = '.jp2';
export const JPX_EXTENSION = '.jpx';
export const J2K_EXTENSION = '.j2k';
export const J2C_EXTENSION = '.j2c';
export const FPX_EXTENSION = '.fpx';
export const PCD_EXTENSION = '.pcd';
export const PNG_EXTENSION = '.png';
export const ONE_EXTENSION = '.one';
export const XPS_EXTENSION = '.xps';
export const MSG_EXTENSION = '.msg';
export const NESSUS_EXTENSION = '.nessus';

export const ALLOWED_EXTENSIONS = [
  PDF_EXTENSION,
  DOC_EXTENSION,
  DOT_EXTENSION,
  WBK_EXTENSION,
  DOCX_EXTENSION,
  DOCM_EXTENSION,
  DOTX_EXTENSION,
  DOTM_EXTENSION,
  DOCB_EXTENSION,
  XLS_EXTENSION,
  XLT_EXTENSION,
  XLM_EXTENSION,
  XLSX_EXTENSION,
  XLSM_EXTENSION,
  XSLT_EXTENSION,
  XLTM_EXTENSION,
  XLSB_EXTENSION,
  XLA_EXTENSION,
  XLAM_EXTENSION,
  MPP_EXTENSION,
  XLL_EXTENSION,
  XLW_EXTENSION,
  PPT_EXTENSION,
  POT_EXTENSION,
  PPS_EXTENSION,
  PPTX_EXTENSION,
  PPTM_EXTENSION,
  POTX_EXTENSION,
  POTM_EXTENSION,
  PPAM_EXTENSION,
  PPSX_EXTENSION,
  PPSM_EXTENSION,
  SLDX_EXTENSION,
  SLDM_EXTENSION,
  PUB_EXTENSION,
  VSD_EXTENSION,
  VSDX_EXTENSION,
  ODT_EXTENSION,
  XML_EXTENSION,
  CSV_EXTENSION,
  WPS_EXTENSION,
  SEVEN_ZIP_EXTENSION,
  RTF_EXTENSION,
  RAR_EXTENSION,
  ZIP_EXTENSION,
  TXT_EXTENSION,
  MP4_EXTENSION,
  MP3_EXTENSION,
  AVI_EXTENSION,
  FLV_EXTENSION,
  WMV_EXTENSION,
  MOV_EXTENSION,
  TIF_EXTENSION,
  TIFF_EXTENSION,
  GIF_EXTENSION,
  JPEG_EXTENSION,
  JPG_EXTENSION,
  JIF_EXTENSION,
  JFIF_EXTENSION,
  JP2_EXTENSION,
  JPX_EXTENSION,
  J2K_EXTENSION,
  J2C_EXTENSION,
  FPX_EXTENSION,
  PCD_EXTENSION,
  PNG_EXTENSION,
  ONE_EXTENSION,
  XPS_EXTENSION,
  MSG_EXTENSION,
  NESSUS_EXTENSION,
];
