import { MutationOptions } from '@apollo/client';
import { RoleDeleteInputApiInterface, RoleUpdateInputApiInterface } from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';

/**
 * Create role GraphQL mutation.
 */
const ROLE_CREATE_MUTATION = gql`
  mutation roleCreateMutation {
    roleCreate {
      createdId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for creating a role.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloRoleCreateMutation = (requestId: string | null): MutationOptions => ({
  mutation: ROLE_CREATE_MUTATION,
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Delete role GraphQL mutation.
 */
const ROLE_DELETE_MUTATION = gql`
  mutation roleDeleteMutation($input: RoleDeleteInput!) {
    roleDelete(input: $input) {
      deletedId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for deleting a role.
 * @param {RoleDeleteInputApiInterface} input The role to delete.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloRoleDeleteMutation = (input: RoleDeleteInputApiInterface): MutationOptions => ({
  mutation: ROLE_DELETE_MUTATION,
  variables: { input },
});

/**
 * Update role GraphQL mutation.
 */
const ROLE_UPDATE_MUTATION = gql`
  mutation roleUpdateMutation($input: RoleUpdateInput!) {
    roleUpdate(input: $input) {
      role {
        roleId
      }
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for updating a role.
 * @param {RoleUpdateInputApiInterface} input The role to update.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloRoleUpdateMutation = (
  input: RoleUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ROLE_UPDATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});
