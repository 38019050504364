import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TextButtonComponent } from '../button/text-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Feedback message section component
 * @export
 * @class FeedbackMessageSectionComponent
 */
@Component({
  selector: 'sc-feedback-message-section',
  templateUrl: './feedback-message-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./feedback-message-section.component.scss'],
  standalone: true,
  imports: [CommonModule, TextButtonComponent, CommonIconModule],
})
export class FeedbackMessageSectionComponent {
  /**
   * Heading to display
   * @type {string}
   * @memberof FeedbackMessageSectionComponent
   */
  @Input() heading!: string;

  /**
   * Message to display
   * @type {string}
   * @memberof FeedbackMessageSectionComponent
   */
  @Input() message!: string;

  /**
   * Button text
   * @type {string}
   * @memberof FeedbackMessageSectionComponent
   */
  @Input() buttonText!: string;

  /**
   * Emits the button click event
   * @memberof FeedbackMessageSectionComponent
   */
  @Output() buttonClick = new EventEmitter();
}
