import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TaskApiActions, TaskEffectsActions } from '../../actions/task.actions';
import { TaskInterface } from '../../models/task/task.models';

export const TASK_FEATURE_KEY = 'task';

export interface StateInterface extends EntityState<TaskInterface> {
  error?: string | null;
  loaded: boolean;
  selectedId?: string;
  totalNewTasks: number;
  totalTasks: number;
}

export interface TaskPartialStateInterface {
  readonly [TASK_FEATURE_KEY]: StateInterface;
}

export const taskAdapter: EntityAdapter<TaskInterface> = createEntityAdapter<TaskInterface>({
  selectId: (task: TaskInterface) => task.taskId,
});

export const initialState: StateInterface = taskAdapter.getInitialState({
  loaded: false,
  totalNewTasks: 0,
  totalTasks: 0,
});

/**
 * Task reducer.
 */
const taskReducer = createReducer(
  initialState,

  on(TaskApiActions.readTasks, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(TaskApiActions.readTasksSuccess, (state: StateInterface, { tasks }) =>
    taskAdapter.setAll(tasks, { ...state, loaded: true })
  ),
  on(TaskApiActions.readTasksFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(TaskApiActions.readTask, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(TaskApiActions.readTaskSuccess, (state: StateInterface, { task }) =>
    taskAdapter.addOne(task, { ...state, loaded: true })
  ),
  on(TaskApiActions.readTaskFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(TaskEffectsActions.setTaskTotals, (state: StateInterface, { totalTasks, totalNewTasks }) => ({
    ...state,
    totalTasks,
    totalNewTasks,
  })),
  on(TaskApiActions.readTaskTotalsFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
  on(TaskApiActions.updateTask, (state: StateInterface, { task }) =>
    taskAdapter.updateOne({ id: task.taskId, changes: task }, state)
  ),
  on(TaskApiActions.updateTaskFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  }))
);

/**
 * Perform reducer logic on the Task NGRX state store for a specific Task actions.
 *
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX Task action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return taskReducer(state, action);
}
