import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { QuestionSetApiActions, QuestionSetEffectsActions } from '../../actions/question-set.actions';
import { QuestionSetInterface } from '../../models/question-set/question-set.models';

export const QUESTION_SET_FEATURE_KEY = 'questionSet';

export interface StateInterface extends EntityState<QuestionSetInterface> {
  selectedId?: string;
  loaded: boolean;
  error?: string | null;
}

export interface QuestionSetPartialStateInterface {
  readonly [QUESTION_SET_FEATURE_KEY]: StateInterface;
}

export const questionSetAdapter: EntityAdapter<QuestionSetInterface> = createEntityAdapter<QuestionSetInterface>({
  selectId: (questionSet: QuestionSetInterface) => questionSet.questionSetId,
});

export const initialState: StateInterface = questionSetAdapter.getInitialState({
  loaded: false,
});

/**
 * Question Set reducer.
 */
const questionSetReducer = createReducer(
  initialState,

  on(QuestionSetApiActions.createQuestionSetSuccess, (state: StateInterface, { questionSet }) =>
    questionSetAdapter.addOne(questionSet, state)
  ),
  on(QuestionSetApiActions.createQuestionSetFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(QuestionSetApiActions.readQuestionSets, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(QuestionSetApiActions.readQuestionSetsSuccess, (state: StateInterface, { questionSets }) =>
    questionSetAdapter.setAll(questionSets, { ...state, loaded: true })
  ),
  on(QuestionSetApiActions.readQuestionSetsFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(QuestionSetApiActions.readQuestionSet, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(QuestionSetApiActions.readQuestionSetSuccess, (state: StateInterface, { questionSet }) =>
    questionSetAdapter.addOne(questionSet, { ...state, loaded: true })
  ),
  on(QuestionSetApiActions.readQuestionSetFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(QuestionSetApiActions.deleteQuestionSet, (state: StateInterface, { questionSet }) =>
    questionSetAdapter.removeOne(questionSet.questionSetId, state)
  ),
  on(QuestionSetApiActions.deleteQuestionSetFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(QuestionSetApiActions.updateQuestionSet, (state: StateInterface, { questionSet }) =>
    questionSetAdapter.updateOne({ id: questionSet.questionSetId, changes: questionSet }, state)
  ),
  on(QuestionSetApiActions.updateQuestionSetFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
  on(QuestionSetEffectsActions.reset, () => initialState),
  on(
    QuestionSetEffectsActions.selectQuestionSet,
    (state: StateInterface, { questionSetId }: { questionSetId: string | undefined }) => ({
      ...state,
      selectedId: questionSetId,
    })
  )
);

/**
 * Perform reducer logic on the Question Set NGRX state store for a specific Question Set actions.
 *
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX Question Set action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return questionSetReducer(state, action);
}
