import { Type } from '@angular/core';
import { ResolveFn, Route } from '@angular/router';
import { BreadcrumbInterface } from '../models/breadcrumb/breadcrumb.model';
import { PageTitleType } from './page-title.interface';

/**
 * Application routes are additionally configured with the below interface.
 * Ensures we enforce a common pattern for our page titles.
 * Ensures we enforce a common pattern for our breadcrumb data.
 */
export interface ApplicationRouteInterface extends Route {
  title?:
    | Type<{
        resolve: ResolveFn<PageTitleType>;
      }>
    | PageTitleType;
  data?: ApplicationRouteDataInterface;
}

/**
 * Application Route Data Interface.
 */
export interface ApplicationRouteDataInterface {
  activeRouteLabel?: string;
  breadcrumbData?: BreadcrumbInterface;
  canViewAsGuest?: boolean;
}

/**
 * The Navigation Link Labels.
 */
export const NavigationLinkLabels = {
  Home: $localize`Home`,
  MyApplications: $localize`My Applications`,
  SystemSetUp: $localize`System Set-Up`,
  Reporting: $localize`Reporting`,
  ManageUsers: $localize`Manage Users`,
  Workflows: $localize`Workflows`,
  ViewBuilder: $localize`View Builder`,
  OptionLists: $localize`Option Lists`,
  QuestionLibrary: $localize`Question Library`,
  RiskMatrix: $localize`Risk Matrix`,
  CustomHierarchy: $localize`Custom Hierarchies`,
  Integration: $localize`Integrations`,
  RecordLists: $localize`Record Lists`,
};
