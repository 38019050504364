export const UNTITLED = $localize`Untitled`;
export const INCOMPLETE = $localize`Incomplete`;
export const UNSUPPORTED = $localize`Unsupported`;
export const MORE_LABEL = $localize` more`;

export const NONE_OPTION = {
  text: $localize`None`,
  value: null,
};

/**
 * The temporary document id prefix
 */
export const UPLOAD_PENDING_ID_PREFIX = 'upload-pending-';
