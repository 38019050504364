/**
 * Checks to see if a value is a number.
 * Handy as 0 in JavaScript is falsy.
 * @example isNumberGuard(0) // Returns true
 * @param {unknown} value The value to check for a number.
 * @return {boolean} Is the value a number.
 */
export const isNumberGuard = (value: unknown): value is number => typeof value === 'number';

/**
 * Checks to see if a string value is a number.
 * Handy as 0 in JavaScript is falsy.
 * @example isStringNumberGuard(0) // Returns true
 * @param {unknown} value The value to check for a number.
 * @return {boolean} Is the value a number.
 */
export const isStringNumberGuard = (value: string): boolean =>
  !Number.isNaN(parseFloat(value)) && Number.isFinite(Number(value));
