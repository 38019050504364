import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionType } from '@ngrx/store';
import { CommonActionDispatcher } from '@surecloud/common';
import { TextButtonComponent } from '../button/text-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Confirm the deletion of an entity attribute component.
 * @export
 * @class ConfirmationModalComponent
 */
@Component({
  selector: 'sc-common-confirmation-modal',
  standalone: true,
  imports: [CommonModule, TextButtonComponent, CommonIconModule],
  templateUrl: './confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  /**
   * Content of the modal.
   * @memberof ConfirmationModalComponent
   */
  @Input() content = $localize`Please confirm your action.`;

  /**
   * Confirm button label.
   * @memberof ConfirmationModalComponent
   */
  @Input() confirmButtonLabel = $localize`Confirm`;

  /**
   * If this modal is for removing/deleting something.
   * @memberof ConfirmationModalComponent
   */
  @Input() remove = true;

  /**
   * The action to dispatch when a user clicks cancel.
   * @memberof ConfirmationModalComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() cancelAction!: ActionType<any>;

  /**
   * The action to dispatch when a user clicks confirm.
   * @memberof ConfirmationModalComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() confirmAction!: ActionType<any>;

  /**
   * Creates an instance of ConfirmationModalComponent.
   * @param {CommonActionDispatcher} commonDispatcher The common action dispatcher.
   * @memberof ConfirmationModalComponent
   */
  constructor(private readonly commonDispatcher: CommonActionDispatcher) {}

  /**
   * Dispatch the cancel action.
   * @memberof ConfirmationModalComponent
   */
  cancel(): void {
    if (this.cancelAction) {
      this.commonDispatcher.dispatch(this.cancelAction);
    }
  }

  /**
   * Dispatch the confirm action.
   * @memberof ConfirmationModalComponent
   */
  confirm(): void {
    if (this.confirmAction) {
      this.commonDispatcher.dispatch(this.confirmAction);
    }
  }
}
