import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackByIndex } from '@surecloud/common';
import { IconListItemComponent } from '../icon-list-item/icon-list-item.component';
import {
  COMPONENTS_DRAG_LIST_ITEM_ICON_MAP,
  COMPONENTS_DRAG_LIST_ITEM_LABEL_MAP,
  COMPONENTS_DRAG_LIST_ITEM_SELECTOR,
} from './components-drag-list.component.constants';
import { ComponentsDragListItemInterface } from './components-drag-list.component.interface';
import { ComponentDragListItemTypeEnum } from './components-drag-list.components.enum';

/**
 * Surecloud Components Drag List Component.
 * Used for dragging visual components onto dropzones.
 *
 * @export
 * @class ComponentsDragListComponent
 */
@Component({
  selector: 'sc-components-drag-list',
  standalone: true,
  templateUrl: './components-drag-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconListItemComponent],
})
export class ComponentsDragListComponent {
  /**
   * The items we want to drag and drop.
   *
   * @type {ComponentDragListItemTypeEnum[]}
   * @memberof ComponentsDragListComponent
   */
  components: ComponentsDragListItemInterface[] = [];

  /**
   * Constants we need to expose to the view.
   *
   * @memberof ComponentsDragListComponent
   */
  readonly CONSTANTS = {
    COMPONENTS_DRAG_LIST_ITEM_SELECTOR,
  };

  /**
   * Expose the track by function to the view template.
   *
   * @memberof ComponentsDragListComponent
   */
  trackBy = trackByIndex;

  /**
   * When the items input changes, map them them to our components drag list item interface.
   *
   * @memberof ComponentsDragListComponent
   */
  @Input() set items(items: ComponentDragListItemTypeEnum[]) {
    this.components = items.map((type) => ({
      icon: COMPONENTS_DRAG_LIST_ITEM_ICON_MAP[type],
      label: COMPONENTS_DRAG_LIST_ITEM_LABEL_MAP[type],
      type,
    }));
  }
}
