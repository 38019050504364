import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService } from '@surecloud/common';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { QuestionSetService } from '../../../services/question-set/question-set.service';
import { QuestionSetApiActions, QuestionSetEffectsActions } from '../../actions/question-set.actions';

/**
 * The Effects/side effects for Question Set.
 *
 * @export
 * @class QuestionSetEffects
 */
@Injectable({ providedIn: 'root' })
export class QuestionSetEffects {
  /**
   * When reading a Question Set list.
   * Then load all Question Set data from the API.
   *
   * @memberof QuestionSetEffects
   */
  loadQuestionSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.readQuestionSets),
      switchMap(({ entityId }) =>
        this.questionSetService.readAll(entityId).pipe(
          map((normalisedQuestionSetList) =>
            QuestionSetApiActions.readQuestionSetsDataSuccess({
              normalisedQuestionSetList,
            })
          ),
          catchError((error: unknown) => of(QuestionSetApiActions.readQuestionSetsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the Question Set list has received a response.
   * Then load the result into state.
   *
   * @memberof QuestionSetEffects
   */
  setQuestionSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.readQuestionSetsDataSuccess),
      map(({ normalisedQuestionSetList }) =>
        QuestionSetApiActions.readQuestionSetsSuccess({
          questionSets: normalisedQuestionSetList.questionSets,
        })
      )
    )
  );

  /**
   * When reading an Question Set.
   * Then load the Question Set data from the API.
   *
   * @memberof QuestionSetEffects
   */
  loadQuestionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.readQuestionSet),
      switchMap(({ questionSetId }) =>
        this.questionSetService.read(questionSetId).pipe(
          map((normalisedQuestionSetList) =>
            QuestionSetApiActions.readQuestionSetDataSuccess({
              normalisedQuestionSetList,
            })
          ),
          catchError((error: unknown) => of(QuestionSetApiActions.readQuestionSetFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the Question Set has received a response.
   * Then load the result into state.
   *
   * @memberof QuestionSetEffects
   */
  setQuestionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.readQuestionSetDataSuccess),
      map(({ normalisedQuestionSetList }) =>
        QuestionSetApiActions.readQuestionSetSuccess({
          questionSet: normalisedQuestionSetList.questionSets[0],
        })
      )
    )
  );

  /**
   * When a Question Set needs to be created on the API.
   * Then call the create method on the Question Set service.
   *
   * @memberof QuestionSetEffects
   */
  createQuestionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.createQuestionSet),
      switchMap(({ requestId, entityId }) =>
        this.questionSetService.create(entityId, requestId).pipe(
          map((questionSet) => QuestionSetApiActions.createQuestionSetSuccess({ questionSet })),
          catchError((error: unknown) => of(QuestionSetApiActions.createQuestionSetFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a Question Set is successfully created on the API.
   * Then navigate to the Question Set page.
   * @memberof QuestionSetEffects
   */
  navigateToQuestionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.createQuestionSetSuccess),
      map(({ questionSet }) =>
        QuestionSetEffectsActions.navigateToQuestionSet({ questionSetId: questionSet.questionSetId })
      )
    )
  );

  /**
   * When a Question Set needs to be updated on the API.
   * Then call the update method on the Question Set service.
   *
   * @memberof QuestionSetEffects
   */
  updateQuestionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.updateQuestionSet),
      switchMap(({ questionSet }) =>
        this.questionSetService.update(questionSet).pipe(
          map((updatedQuestionSet) =>
            QuestionSetApiActions.updateQuestionSetSuccess({ questionSet: updatedQuestionSet })
          ),
          catchError((error: unknown) => of(QuestionSetApiActions.updateQuestionSetFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a Question Set needs to be deleted on the API.
   * Then call the delete method on the Question Set service.
   *
   * @memberof QuestionSetEffects
   */
  deleteQuestionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionSetApiActions.deleteQuestionSet),
      switchMap(({ questionSet }) =>
        this.questionSetService.delete(questionSet).pipe(
          map(({ questionSetId }) => QuestionSetApiActions.deleteQuestionSetSuccess({ questionSetId })),
          catchError((error: unknown) => of(QuestionSetApiActions.deleteQuestionSetFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a Question Set API call failed.
   * Then log the error.
   *
   * @memberof QuestionSetEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          QuestionSetApiActions.readQuestionSetsFailure,
          QuestionSetApiActions.readQuestionSetFailure,
          QuestionSetApiActions.updateQuestionSetFailure,
          QuestionSetApiActions.deleteQuestionSetFailure,
          QuestionSetApiActions.createQuestionSetFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Question Set', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of QuestionSetEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {QuestionSetService} questionSetService The Question Set API service.
   * @param {LoggerService} logger The common logger service.
   * @memberof QuestionSetEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly questionSetService: QuestionSetService,
    private readonly logger: LoggerService
  ) {}
}
