import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabInterface } from '@surecloud/common';

/**
 * Surecloud Tabs Component.
 * @export
 * @class TabsComponent
 */
@Component({
  selector: 'sc-tabs',
  standalone: true,
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class TabsComponent {
  /**
   * The different tabs to display.
   * @type {TabInterface[]}
   * @memberof TabsComponent
   */
  @Input()
  tabs: TabInterface[] = [];

  /**
   * Two way data bind the active tab between this component and it's parent.
   * @type {number}
   * @memberof TabsComponent
   */
  @Input() activeTab: number | undefined;

  /**
   * Emits when the active tab has changed.
   * @memberof TabsComponent
   */
  @Output() activeTabChange = new EventEmitter<number>();

  /**
   * Function to track the tab's loop by.
   * @memberof TabsComponent
   */
  trackByFunction = TabsComponent.trackByFn;

  /**
   * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
   * The function takes the iteration index and item ID, and in this case Angular will track changes by the item.id
   * @static
   * @param {number} _ Index number
   * @param {TabInterface} tab  An item in an Iterable
   * @param {string} tab.name The tab name value
   * @return {string} The property to track the Angular loop by.
   * @memberof TabsComponent
   */
  static trackByFn(_: number, tab: TabInterface): number {
    return tab.id;
  }

  /**
   * Set the active tab by ID.
   * @param {number} id ID of the tab to set as active.
   * @memberof TabsComponent
   */
  setActiveTab(id: number): void {
    this.activeTab = id;
    this.activeTabChange.emit(this.activeTab);
  }
}
