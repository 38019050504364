import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonActions } from '@surecloud/common';
import { ModalService } from '@surecloud/design';
import { tap } from 'rxjs';

/**
 * The Effects/side effects for a Modal.
 * @export
 * @class ModalEffects
 */
@Injectable({ providedIn: 'root' })
export class ModalEffects {
  /**
   * When Show Confirmation Modal
   * Then open modal
   * @memberof ModalEffects
   */
  showConfirmationModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonActions.showConfirmationModal),
        tap(({ title, content, confirmAction, cancelAction }) => {
          this.modal.openConfirmation(title, content);
          const component = this.modal.confirmationReference?.component;

          if (component) {
            component.confirmAction = confirmAction;
            component.cancelAction = cancelAction || CommonActions.closeConfirmationModal();
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * When a close modal action is dispatched.
   * Then close the modal.
   * @memberof ModalEffects
   */
  closeConfirmationModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonActions.closeConfirmationModal),
        tap(() => {
          if (this.modal.confirmationReference) {
            this.modal.confirmationReference.close();
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of ModalEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {ModalService} modal The generic modal service.
   * @memberof ModalEffects
   */
  constructor(private readonly actions$: Actions, private readonly modal: ModalService) {}
}
