import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService } from '@surecloud/common';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { FeatureFlagActions } from '../actions/feature-flag.actions';

/**
 * The Effects/side effects for Feature Flag.
 * @export
 * @class FeatureFlagEffects
 */
@Injectable({ providedIn: 'root' })
export class FeatureFlagEffects {
  /**
   * When reading a Feature Flag list.
   * Then load all Feature Flag data from the API.
   * @memberof FeatureFlagEffects
   */
  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagActions.readFeatureFlags),
      switchMap(({ userProfile }) =>
        this.featureFlagService.init(userProfile).pipe(
          map((featureFlags) =>
            FeatureFlagActions.readFeatureFlagsSuccess({
              featureFlags,
            })
          ),
          catchError((error: unknown) => of(FeatureFlagActions.readFeatureFlagsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a Feature Flag API call failed.
   * Then log the error.
   * @memberof FeatureFlagEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagActions.readFeatureFlagsFailure),
        tap(({ error, type }) => this.logger.logEvent('Feature Flag', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of FeatureFlagEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {FeatureFlagService} featureFlagService The Feature Flag API service.
   * @param {LoggerService} logger The common logger service.
   * @memberof FeatureFlagEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly featureFlagService: FeatureFlagService,
    private readonly logger: LoggerService
  ) {}
}
