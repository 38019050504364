import { ComponentRef } from '@angular/core';
import {
  ValidationDateControlComponentInterface,
  ValidationDropdownControlComponentInterface,
  ValidationFormControlComponentInterface,
  ValidationNumberAndDropdownControlComponentInterface,
  ValidationRendererAllComponentUnion,
} from './validation-component-renderer-component.interface';
import {
  ValidationRendererDateControlConfigInterface,
  ValidationRendererDropdownControlConfigInterface,
  ValidationRendererFormControlConfigInterface,
  ValidationRendererNumberAndDropdownControlConfigInterface,
  ValidationRendererTypeEnum,
} from './validation-component-renderer-config.interface';

export const isValidationRendererNumberConfig = (
  componentConfig: unknown
): componentConfig is ValidationRendererFormControlConfigInterface =>
  (componentConfig as ValidationRendererFormControlConfigInterface).type === `${ValidationRendererTypeEnum.Number}`;

export const isValidationRendererNumberAndDropdownConfig = (
  componentConfig: unknown
): componentConfig is ValidationRendererNumberAndDropdownControlConfigInterface =>
  (componentConfig as ValidationRendererNumberAndDropdownControlConfigInterface).type ===
  `${ValidationRendererTypeEnum.NumberAndDropdown}`;

export const isValidationRendereDropdownConfig = (
  componentConfig: unknown
): componentConfig is ValidationRendererDropdownControlConfigInterface =>
  (componentConfig as ValidationRendererDropdownControlConfigInterface).type ===
  `${ValidationRendererTypeEnum.Dropdown}`;

export const isValidationRendereDateConfig = (
  componentConfig: unknown
): componentConfig is ValidationRendererDateControlConfigInterface =>
  (componentConfig as ValidationRendererDateControlConfigInterface).type === `${ValidationRendererTypeEnum.Date}`;

/**
 * Typescript guard to check if a ComponentRef<T> is RendererInputComponentUnion.
 *
 * @param {ComponentRef<ValidationFormControlComponentInterface>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<RendererInputComponentUnion>.
 */
export const isValidationRendererFormControlComponentRef = (
  componentRef: ComponentRef<ValidationRendererAllComponentUnion>
): componentRef is ComponentRef<ValidationFormControlComponentInterface> =>
  (componentRef as ComponentRef<ValidationFormControlComponentInterface>).instance.controlName !== undefined;

/**
 * Typescript guard to check if a ComponentRef<T> is RendererInputComponentUnion.
 *
 * @param {ComponentRef<ValidationDropdownControlComponentInterface>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<RendererInputComponentUnion>.
 */
export const isValidationRendererDropdownControlComponentRef = (
  componentRef: ComponentRef<ValidationRendererAllComponentUnion>
): componentRef is ComponentRef<ValidationDropdownControlComponentInterface> =>
  (componentRef as ComponentRef<ValidationDropdownControlComponentInterface>).instance.controlName !== undefined;

/**
 * Typescript guard to check if a ComponentRef<T> is NumberAndDropdownControlComponentInterface.
 *
 * @param {ComponentRef<ValidationNumberAndDropdownControlComponentInterface>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<NumberAndDropdownControlComponentInterface>.
 */
export const isValidationRendererNumberAndDropdownControlComponentRef = (
  componentRef: ComponentRef<ValidationRendererAllComponentUnion>
): componentRef is ComponentRef<ValidationNumberAndDropdownControlComponentInterface> =>
  (componentRef as ComponentRef<ValidationNumberAndDropdownControlComponentInterface>).instance.inputControlName !==
    undefined &&
  (componentRef as ComponentRef<ValidationNumberAndDropdownControlComponentInterface>).instance.dropdownControlName !==
    undefined;

/**
 * Typescript guard to check if a ComponentRef<T> is ValidationDateControlComponentInterface.
 *
 * @param {ComponentRef<ValidationDateControlComponentInterface>} componentRef The Record View Component to type check against.
 * @return {boolean} Is the componentRef ComponentRef<RendererInputComponentUnion>.
 */
export const isValidationRendererDateControlComponentRef = (
  componentRef: ComponentRef<ValidationRendererAllComponentUnion>
): componentRef is ComponentRef<ValidationDateControlComponentInterface> =>
  (componentRef as ComponentRef<ValidationDateControlComponentInterface>).instance.controlName !== undefined;
