<section class="sc-section sc-pt-16 sc-pl-20 sc-pr-20 sc-pb-20 sc-p-0--mobile sc-m-0--mobile" [ngClass]="classname">
  <div class="flex flex-row justify-content-between align-items-center mb-12">
    <div class="flex justify-content-center align-items-center">
      <sc-icon *ngIf="key" class="sc-mr-4" [key]="key" [height]="17" [width]="22"></sc-icon>
      <h4 *ngIf="heading" class="sc-text-brand-500 sc-font-heading-xl" [ngClass]="classnameHeading">
        {{ heading }}
      </h4>
    </div>
    <ng-content select="[header-content]"></ng-content>
  </div>
  <ng-content></ng-content>
</section>
