import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { fromPageState, PageStateFacade } from '@surecloud/common';
import {
  FeatureFlagFacade,
  NG_4199_BREADCRUMB_FOR_NAVIGATION,
  NG_4784_UPDATING_BRANDING_FOR_GARTNER,
} from '@surecloud/feature-flag';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import { ToolbarFeatureFacade } from '../../+state/facades/toolbar.facade';
/**
 * Toolbar Feature Component.
 * Parent to all smart an dumb components for the Global Toolbar Feature.
 * @export
 * @class ToolbarFeatureComponent
 */
@Component({
  selector: 'toolbar-feature',
  templateUrl: './toolbar-feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarFeatureComponent implements OnInit, OnDestroy {
  /**
   * When the component is destroyed.
   * Then emit so that other observables may tear down.
   * @type {Subject<void>}
   * @private
   * @memberof ToolbarFeatureComponent
   */
  private destroyed$: Subject<void> = new Subject();

  /**
   * Determines whether to show or hide the secondary nav.
   * Some pages we hide it completely even though a user has access to it with permission.
   * eg. Design V2 of the Home Page.
   * @memberof ToolbarFeatureComponent
   */
  @Input()
  public isSecondaryNavEnabled = true;

  /**
   * Get the breadcrumb data from the route.
   * @memberof ToolbarFeatureComponent
   */
  public breadcrumbs$ = this.pageStateFacade.routeData$.pipe(
    switchMap((route) => {
      const { breadcrumbData } = route;
      return breadcrumbData ? of(breadcrumbData) : this.pageStateFacade.breadcrumbs$;
    })
  );

  /**
   * Is Design Version 2 Feature enabled.
   * @memberof ToolbarFeatureComponent
   */
  public isDesignV2Enabled$ = this.features.featureIsEnabled$(NG_4784_UPDATING_BRANDING_FOR_GARTNER);

  /**
   * Feature flag to enable the Breadcrumb Navigation (NG-4199) feature.
   * @memberof ToolbarFeatureComponent
   */
  public enabledForNg4199$ = this.features.featureIsEnabled$(NG_4199_BREADCRUMB_FOR_NAVIGATION);

  /**
   * Creates an instance of ToolbarFeatureComponent.
   * @param {FeatureFlagFacade} features The Feature Flag service.
   * @param {ToolbarFeatureFacade} toolbarFacade The Toolbar Feature Facade service.
   * @param {Window} window The window object
   * @param {PageStateFacade} pageStateFacade - The page state facade.
   * @memberof ToolbarFeatureComponent
   */
  constructor(
    private readonly features: FeatureFlagFacade,
    public readonly toolbarFacade: ToolbarFeatureFacade,
    @Inject(WINDOW) private readonly window: Window,
    public readonly pageStateFacade: PageStateFacade
  ) {}

  /**
   * When the component is initialised.
   * And there is already Breadcrumb data on the route.
   * Then reset the breadcrumbs.
   * @memberof ToolbarFeatureComponent
   */
  ngOnInit(): void {
    this.pageStateFacade.routeData$.pipe(takeUntil(this.destroyed$)).subscribe((route) => {
      const { breadcrumbData } = route;
      if (breadcrumbData) {
        this.pageStateFacade.dispatch(fromPageState.BreadcrumbActions.resetBreadcrumbs());
      }
    });
  }

  /**
   * When the component is destroyed
   * Then emit so that other observables may tear down.
   * @memberof ToolbarFeatureComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Navigate back once in the History API
   * @memberof ToolbarFeatureComponent
   */
  goBack(): void {
    this.window.history.back();
  }
}
