import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectDropdownComponentOptionInterface } from '@surecloud/common';
import { ROLE_FEATURE_KEY, StateInterface, roleAdapter } from '../reducers/role.reducer';

// Lookup the 'Role' feature state managed by NgRx
export const getRoleState = createFeatureSelector<StateInterface>(ROLE_FEATURE_KEY);

const { selectAll, selectEntities } = roleAdapter.getSelectors();
const untitled = $localize`Unnamed Role`;

/**
 * Standard state selectors
 */
export const getRoleList = createSelector(getRoleState, (state: StateInterface) => selectAll(state));
export const getRoleEntities = createSelector(getRoleState, (state: StateInterface) => selectEntities(state));
export const getRoleLoaded = createSelector(getRoleState, (state: StateInterface) => state.loaded);
export const getSelectedRoleId = createSelector(getRoleState, (state: StateInterface) => state.selectedId);
export const getSelectedRole = createSelector(getRoleEntities, getSelectedRoleId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

/**
 * The current value of guest role permission
 */
export const availableToGuestUsers = createSelector(getSelectedRole, (role) => role?.availableToGuestUsers);

/**
 * Additional selectors
 */
export const getSelectedRoleName = createSelector(getSelectedRole, (role) => role?.name || untitled);

/**
 * Get the list of roles in a SelectDropdownComponentOptionInterface[] interface.
 */
export const getRoleListAsSelectDropdownOptions = createSelector(getRoleList, (roles) =>
  roles.map(
    (role): SelectDropdownComponentOptionInterface => ({
      text: role.name || untitled,
      value: role.roleId,
    })
  )
);
