import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { E2eHookDirective } from '@surecloud/common';
import { Subject, takeUntil } from 'rxjs';
import { InputNumberComponent } from '../input-number/input-number.component';
import { RadioButtonsComponent } from '../radio-buttons/radio-buttons.component';
import { RadioOptionWithInputInterface } from './radio-buttons-with-input.interface';

/**
 * A radio button selection that also has the option to display inputs within the radio selections text.
 *
 * @example
 * <sc-radio-buttons-with-input
 *  [controlName]="formControlName"
 *  [options]="options"
 * </sc-radio-buttons-with-input>
 * @export
 * @class RadioButtonsWithInputComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-radio-buttons-with-input',
  standalone: true,
  templateUrl: './radio-buttons-with-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, E2eHookDirective, ReactiveFormsModule, InputNumberComponent, RadioButtonsComponent],
})
export class RadioButtonsWithInputComponent implements OnInit, OnDestroy {
  /**
   * Expose the track by function to the view template.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  trackByFunction = RadioButtonsWithInputComponent.trackByFn;

  /**
   * The form control name if using a reactive form.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  @Input() controlName = '';

  /**
   * The width of any inputs.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  @Input() inputWidth = 100;

  /**
   *  Sets a fixed min and max value in the input.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  @Input() inputAutoCorrect = true;

  /**
   * Sets a min in the input.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  @Input() inputMin = 1;

  /**
   * The list of value options.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  @Input()
  options!: RadioOptionWithInputInterface[];

  /**
   * The form group to build based on parent component
   *
   * @type {FormGroup}
   * @memberof RadioButtonsWithInputComponent
   */
  formGroup!: FormGroup;

  /**
   * When the component is destroyed.
   * Then emit so other observables can tear down.
   *
   * @private
   * @type {Subject<boolean>}
   * @memberof RadioButtonsWithInputComponent
   */
  private readonly destroyed$: Subject<boolean> = new Subject();

  /**
   * Track by function for Angular template loops.
   *
   * @static
   * @param {number} _ Index number
   * @param {RadioOptionWithInputInterface} option The radio option.
   * @return {string} Value to track the Angular looped item by.
   * @memberof RadioButtonsWithInputComponent
   */
  static trackByFn(_: number, option: RadioOptionWithInputInterface): string {
    return option.value;
  }

  /**
   * Creates an instance of RadioButtonsWithInputComponent.
   */
  constructor(private controlContainer: ControlContainer) {}

  /**
   * Runs when the component is started.
   * Sets the parent formgroup form the component.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;

    this.enableDisableInputs(this.formGroup.controls[this.controlName].value);

    this.formGroup.controls[this.controlName].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newRadioValue) => {
        this.enableDisableInputs(newRadioValue);
      });
  }

  /**
   * When the component is destroyed.
   * Then emit so other observables can tear down.
   *
   * @memberof RadioButtonsWithInputComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * When the radio value changes,
   * Disable inputs for any options that are not selected.
   * Enable the input for the selected option.
   *
   * @private
   * @memberof RadioButtonsWithInputComponent
   */
  private enableDisableInputs(value: string): void {
    this.options.forEach((optionInput) => {
      if (optionInput.input && value !== optionInput.value) {
        this.formGroup.controls[optionInput.input].disable({ emitEvent: false });
      } else if (optionInput.input) {
        this.formGroup.controls[optionInput.input].enable({ emitEvent: false });
      }
    });
  }
}
