import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LinkedEntityTypeApiInterface } from '@surecloud/api-types';
import {
  BaseFacade,
  EntityAttributeInterface,
  EntityAttributeTypeEnum,
  EntityInterface,
  HierarchyNodeInterface,
  isPlaceholderEntityAttribute,
} from '@surecloud/common';
import { filter, map, Observable } from 'rxjs';
import { EntityAttributeRuleInterface } from '../models/rule/rule.models';
import * as AttributeSelectors from '../selectors/attribute/attribute.selectors';
import * as EntitySelectors from '../selectors/entity/entity.selectors';
import * as FormatSelectors from '../selectors/format/format.selectors';
import * as LinkedEntityTagSelectors from '../selectors/linked-entity-tag/linked-entity-tag.selectors';
import * as LinkedEntitySelectors from '../selectors/linked-entity/linked-entity.selectors';
import * as PermissionAttributeSelectors from '../selectors/permission/attribute-permission/attribute-permission.selectors';
import * as PermissionSelectors from '../selectors/permission/permission-entity/permission-entity.selectors';
import * as QuestionSelectors from '../selectors/question/question.selectors';
import * as RuleSelectors from '../selectors/rule/rule.selectors';
/**
 * The facade for the Entity feature sits between the component layer and the
 * state layer and is responsible for providing store observables to,
 * and dispatching actions from the view layer
 * @export
 * @class EntityStateFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class EntityStateFacade extends BaseFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  loaded$ = this.store.pipe(select(EntitySelectors.getEntityLoaded));
  entityList$ = this.store.pipe(select(EntitySelectors.getEntityList));
  selectedEntity$ = this.store.pipe(select(EntitySelectors.getSelected));
  selectedEntityId$ = this.store.pipe(select(EntitySelectors.getSelectedId));
  selectedEntityAttributes$ = this.store.pipe(select(AttributeSelectors.getSelectedAttributes));
  selectedAttribute$ = this.store.pipe(select(AttributeSelectors.getSelectedAttribute));
  isSelectedPlaceholderAttribute$ = this.selectedAttribute$.pipe(
    filter((attribute): attribute is EntityAttributeInterface => !!attribute),
    map((attribute) => isPlaceholderEntityAttribute(attribute))
  );
  selectedNumberAttributes$ = this.store.pipe(select(AttributeSelectors.getSelectedNumberAttributes));
  attributeList$ = this.store.pipe(select(AttributeSelectors.getAttributeList));
  attributeEntities$ = this.store.pipe(select(AttributeSelectors.getAttributeEntities));
  attributeFormatOptions$ = this.store.pipe(select(FormatSelectors.getFormatOptions));
  attributeRuleOptions$ = this.store.pipe(select(RuleSelectors.getRuleOptions));
  selectedAttributeFormats$ = this.store.pipe(select(FormatSelectors.getSelectedAttributeFormats));
  selectedAttributeRule$ = this.store.pipe(select(RuleSelectors.getSelectedRule));
  selectedAttributeRules$ = this.store.pipe(select(RuleSelectors.getSelectedAttributeRules));
  selectedAttributeRulesConditionList$ = this.store.pipe(select(RuleSelectors.getSelectedAttributeRulesConditionList));
  selectedAttributeRulesForDataValidation$ = this.store.pipe(
    select(RuleSelectors.getSelectedAttributeRulesForDataValidation)
  );
  selectedAttributeValidationAttributeIdsForDataValidation$ = this.store.pipe(
    select(RuleSelectors.getSelectedValidationAttributeIdsForDataValidation)
  );
  selectedEntityRoleOptions$ = this.store.pipe(select(PermissionSelectors.getSelectedEntityRoleOptions));
  selectedEntityRolePermissions$ = this.store.pipe(select(PermissionSelectors.getSelectedEntityRolePermissions));
  selectedLinkedEntityList$ = this.store.pipe(select(LinkedEntitySelectors.getSelectedLinkedEntityList));
  selectedLinkedEntityTagList$ = this.store.pipe(select(LinkedEntityTagSelectors.getSelectedLinkedEntityTagList));
  selectLinkedEntities$ = this.store.pipe(select(LinkedEntitySelectors.getLinkedEntities()));
  getLinkableEntities$ = this.store.pipe(select(LinkedEntitySelectors.getLinkableEntities()));
  getLinkedEntitiesOptions$ = this.store.pipe(select(LinkedEntitySelectors.getLinkedEntitiesOptions));

  getSelectedEntityAttributesForQuestionComponent$ = this.store.pipe(
    select(AttributeSelectors.getSelectedEntityAttributesForQuestionComponent)
  );

  availableSelectedEntityRoles$ = this.store.pipe(select(PermissionSelectors.getAvailableSelectedEntityRoles));
  selectedAttributeRolePermissions$ = this.store.pipe(
    select(PermissionAttributeSelectors.getSelectedAttributeRolePermissions)
  );
  /**
   * Get the linked entity hierarchy node data by node ID.
   * @param {string[]} entityIds The array of entity IDs to get.
   * @return {Observable<EntityInterface | undefined>} Stream of entities.
   * @memberof EntityStateFacade
   */
  getEntitiesById$ = (entityIds: string[]): Observable<(EntityInterface | undefined)[]> =>
    this.store.pipe(select(EntitySelectors.getEntitiesById(entityIds)));

  /**
   * Get the linked entity hierarchy node data by node ID.
   * @param {string} nodeId The entity ID to get the hierarchy node for.
   * This can be prfixed with a constant so we know if the user is looking for attributes on the current entity hierarchy node.
   * @param {EntityAttributeTypeEnum | EntityAttributeTypeEnum[]} [type] The attribute type(s) to get specific attribute types on a entity.
   * @param {LinkedEntityTypeApiInterface} linkType The link type of linked entities to get.
   * @return {Observable<HierarchyNodeInterface | undefined>} Stream of hierarchy node data.
   * @memberof EntityStateFacade
   */
  linkedEntityHierarchyByNodeId$ = (
    nodeId: string,
    type: EntityAttributeTypeEnum | EntityAttributeTypeEnum[] | undefined = undefined,
    linkType: LinkedEntityTypeApiInterface | undefined = undefined
  ): Observable<HierarchyNodeInterface | undefined> =>
    this.store.pipe(select(LinkedEntitySelectors.getLinkedEntityHierarchyByNodeId(nodeId, type, linkType)));

  /**
   * Get the rule by rule ID.
   * @param {string} ruleId the rule to get.
   * @return {Observable<EntityAttributeRuleInterface | undefined>} Stream of entities.
   * @memberof EntityStateFacade
   */
  getRuleById$ = (ruleId: string): Observable<EntityAttributeRuleInterface | undefined> =>
    this.store.pipe(select(RuleSelectors.getRuleById(ruleId)));

  /**
   * Get an array of selected entity attributes by attribute type.
   * @param {EntityAttributeTypeEnum[]} types The attribute types you want to select.
   * @return {Observable<EntityAttributeInterface[]>} The selected Entity attributes by attribute types.
   * @memberof EntityStateFacade
   */
  getSelectedEntityAttributesByTypes$ = (types: EntityAttributeTypeEnum[]): Observable<EntityAttributeInterface[]> =>
    this.store.pipe(select(AttributeSelectors.getSelectedEntityAttributesByTypes(types)));

  /**
   * The selected Entities Questions Sets in an interface we can pass to a Advanced Grid.
   * @memberof EntityStateFacade
   */
  selectedEntityQuestionSetsForGrid$ = this.store.pipe(select(QuestionSelectors.getSelectedEntityQuestionSetsForGrid));

  /**
   * The selected Entities Questions Sets in an interface we can pass to a Dropdown Select Component.
   * @memberof EntityStateFacade
   */
  selectedEntityQuestionSetsForDropdown$ = this.store.pipe(
    select(QuestionSelectors.getSelectedEntityQuestionSetsForDropdown)
  );

  /**
   * Creates an instance of EntityStateFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof EntityStateFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
