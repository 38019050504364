import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Page Footer component.
 * Creates a fixed position footer component at the bottom of the page that you can content project into.
 *
 * @export
 * @class PageFooterComponent
 */
@Component({
  standalone: true,
  selector: 'sc-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent {}
