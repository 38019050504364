import { Injectable } from '@angular/core';
import { Offset } from '@progress/kendo-angular-popup';
import { BehaviorSubjectService } from '@surecloud/common';

/**
 * User Menu Component Service.
 * Uses this service to show and hide the User Menu Component.
 * @export
 * @class UserMenuComponentService
 * @extends {(BehaviorSubjectService<Offset | false>)}
 */
@Injectable({
  providedIn: 'root',
})
export class UserMenuComponentService extends BehaviorSubjectService<Offset | false> {
  /**
   * Creates an instance of UserMenuComponentService.
   * The User Menu Component is hidden by default so we update the intial stream to false.
   * @memberof UserMenuComponentService
   */
  constructor() {
    super(false);
  }
}
