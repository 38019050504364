import { Action, select, Store } from '@ngrx/store';
import { selectCurrentRoute, selectRouteData, selectUrl } from '../+state/selectors/router.selectors';

/**
 * Base Class for Facades to extend in order to make use of common functionality.
 * @export
 * @abstract
 * @class BaseFacade
 */
export abstract class BaseFacade {
  /**
   * Get the current URL.
   * @memberof BaseFacade
   */
  currentUrl$ = this.store.pipe(select(selectUrl));

  /**
   * Get the current route.
   * @memberof BaseFacade
   */
  currentRoute$ = this.store.pipe(select(selectCurrentRoute));

  /**
   * Get the current route data.
   * @memberof BaseFacade
   */
  routeData$ = this.store.pipe(select(selectRouteData));

  /**
   * Creates an instance of BaseFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof BaseFacade
   */
  constructor(protected readonly store: Store) {}

  /**
   * Pass any action to the dispatch method of the store.
   * @param {Action} action The NGRX action to be dispatched.
   * @memberof BaseFacade
   */
  public dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}
