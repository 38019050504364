export const uniqueBy = <T>(arr: T[], key: keyof T): T[] => {
  const uniqueKeys = new Set();
  return arr.filter((item) => {
    if (uniqueKeys.has(item[key])) {
      return false;
    }
    uniqueKeys.add(item[key]);
    return true;
  });
};
