<ng-container
  *ngIf="{
    breadcrumb: breadcrumbs$ | async,
    canViewBreadcrumb: (enabledForNg4199$ | async) === true,
    canViewSecondaryNav: (toolbarFacade.canViewSecondaryNav$ | async) === true,
    isDesignV2Enabled: (isDesignV2Enabled$ | async) === true
  } as streams">
  <sc-toolbars
    [canViewSecondaryNav]="streams.canViewSecondaryNav && isSecondaryNavEnabled"
    [isDesignV2Enabled]="streams.isDesignV2Enabled">
    <toolbar-feature-navigation toolbars-navigation [isDesignV2Enabled]="streams.isDesignV2Enabled">
    </toolbar-feature-navigation>
    <ng-container toolbars-primary>
      <ng-container *ngIf="streams.canViewBreadcrumb && streams.breadcrumb; else backButton">
        <design-v2-breadcrumb
          [breadcrumb]="streams.breadcrumb"
          *ngIf="streams.isDesignV2Enabled; else breadcrumbDesignV1">
        </design-v2-breadcrumb>
        <ng-template #breadcrumbDesignV1>
          <sc-breadcrumb [breadcrumb]="streams.breadcrumb"></sc-breadcrumb>
        </ng-template>
      </ng-container>
      <ng-template #backButton>
        <button
          (click)="goBack()"
          sc-text-button
          themeColour="brand"
          fillMode="text"
          size="large"
          class="sc-back-button min-w-0 sc-mr-4">
          <sc-icon key="back" colour="brand-500" [height]="15" [width]="16"></sc-icon>
          <span i18n class="sc-ml-6">Back</span>
        </button>
      </ng-template>
    </ng-container>
    <ng-content select="[toolbar-secondary]" toolbars-secondary></ng-content>
    <ng-content toolbars-content></ng-content>
  </sc-toolbars>
</ng-container>
