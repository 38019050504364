import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { E2eHookDirective } from '@surecloud/common';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';
import { SliderClassTypeEnum } from './slider.enum';

/**
 * Wrapper component around the [Kendo UI slider](https://www.telerik.com/kendo-angular-ui/components/inputs/slider/).
 *
 * @export
 * @class SliderComponent
 * @extends {ControlValueAccessorConnector<boolean>}
 */
@Component({
  selector: 'sc-slider',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    E2eHookDirective,
  ],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent extends ControlValueAccessorConnector<number> {
  /**
   * The slider mininum value
   *
   * @memberof SliderComponent
   */
  @Input() min = 1;

  /**
   * The slider maxinum value
   *
   * @memberof SliderComponent
   */
  @Input() max = 5;

  /**
   * The slider small step
   *
   * @memberof SliderComponent
   */
  @Input() smallStep = 1;

  /**
   * The slider large step
   *
   * @memberof SliderComponent
   */
  @Input() largeStep = 1;

  /**
   * Automation testhook to add to the slider.
   *
   * @memberof SliderComponent
   */
  @Input() testhook = 'slider';

  /**
   * Slider component classname.
   *
   * @memberof SliderComponent
   */
  @Input() classname: SliderClassTypeEnum = SliderClassTypeEnum.Risk;
}
