import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';

/**
 * Makes the global email attribute configuration
 */
export const GLOBAL_EMAIL_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Email,
  key: EntityAttributeTypeEnum.Email,
  type: EntityAttributeTypeEnum.Email,
  label: $localize`Email`,
  uniqueSections: [],
});
