import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CommonIconModule } from '../../icon/icons/common-icon.module';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { ControlValueAccessorConnector } from '../../utils/classes/control-value-accessor';
import { UserAvatarChipComponent } from '../user-avatar-chip/user-avatar-chip.component';
import { UserAvatarType } from '../user-avatar.model';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-user-avatar-multiselect',
  templateUrl: './user-avatar-multiselect.component.html',
  styleUrls: ['./user-avatar-multiselect.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DropDownsModule,
    UserAvatarChipComponent,
    ReactiveFormsModule,
    LabelModule,
    CommonIconModule,
    InputsModule,
    UserAvatarComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserAvatarMultiselectComponent),
      multi: true,
    },
  ],
})
export class UserAvatarMultiselectComponent extends ControlValueAccessorConnector<string[]> implements OnChanges {
  /**
   *  The label text for the multiselect dropdown.
   * @type {string}
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() label!: string;

  /**
   * Sets the multiselect dropdown readonly mode.
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() readonly = false;

  /**
   * Text to display an additional description to the user.
   * @type {string}
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() description!: string;

  /**
   * Sets if we want a success validation style to display.
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Text to display in dropdown input.
   * @type {string}
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() placeholder!: string;

  /**
   * The full list of items [UserAvatarType] in the select dropdown.
   * @type {UserAvatarType[]}
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() options: UserAvatarType[] = [];

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof UserAvatarMultiselectComponent
   */
  @Input() staticWidth!: string;

  /**
   * The [Kendo Mulitselect](https://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselect/) dropdown component.
   * @type {(MultiSelectComponent | undefined)}
   * @memberof UserAvatarMultiselectComponent
   */
  @ViewChild(MultiSelectComponent, { static: true }) multiSelectComponent: MultiSelectComponent | undefined;

  /**
   * Emmits string typed on user select
   * @memberof UserAvatarMultiselectComponent
   */
  @Output() filterChange = new EventEmitter<string>();

  /**
   * MultiSelectComponent element reference
   * @type {MultiSelectComponent}
   * @memberof UserAvatarMultiselectComponent
   */
  @ViewChild('multiselect', { static: true }) dropdown!: MultiSelectComponent;

  /**
   * Is the dropdown open
   * @memberof UserAvatarMultiselectComponent
   */
  public isPopupOpen = false;

  /**
   * Remove tag user clicked
   * @param userRemoved
   * When the options have changed.
   * Then ensure the first item in the options is focused.
   * @param {SimpleChanges} changes The Angular Simple Changes.
   * @memberof UserAvatarMultiselectComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.multiSelectComponent) {
      setTimeout(() => this.multiSelectComponent?.focusItemAt(0));
    }
  }

  /**
   * Remove tag user clicked
   * @param {UserAvatarType} userRemoved The user to be removed.
   * @memberof UserAvatarMultiselectComponent
   */
  public removeTag(userRemoved: UserAvatarType): void {
    const users = this.control.value.filter((userValue: string) => userValue !== userRemoved.value) as UserAvatarType[];
    this.control.setValue(users);
  }

  /**
   * Toggle the dropdown
   * @param status
   * @memberof UserAvatarMultiselectComponent
   */
  public toggle(status: boolean): void {
    this.dropdown.toggle(status);
    this.isPopupOpen = status;
  }
}
