import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseFacade } from '../../facades/base.facade';

/**
 * Used to dispatch common actions.
 * It just needs to expose the dispatch method from BaseFacade and should not contain any other methods.
 */
@Injectable({
  providedIn: 'root',
})
export class CommonActionDispatcher extends BaseFacade {
  /**
   * Creates an instance of CommonActionDispatcher.
   * @param {Store} store The NGRX application state store.
   * @memberof CommonActionDispatcher
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
