import { z } from 'zod';

/**
 * Normalise Get Groups Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetGroupService.
 */

/**
 * Group Zod schema.
 */
export const groupZodSchema = z.object({
  groupId: z.string(),
  name: z.string().nullable(),
  users: z.array(z.string()).optional(),
  description: z.string().nullable().optional(),
  roles: z.array(z.string()).nullable().optional(),
  viewerCanDelete: z.boolean().optional(),
  viewerCanUpdate: z.boolean().optional(),
});

/**
 * Normalised Group list Zod schema.
 */
export const normalisedGroupListZodSchema = z.object({
  groups: z.array(groupZodSchema),
});

/**
 * Normalised Group list data interface.
 */
export type NormalisedGroupList = z.infer<typeof normalisedGroupListZodSchema>;

/**
 * Normalised Group.
 */
export type NormalisedGroup = z.infer<typeof groupZodSchema>;

/**
 * Guard to check the normalised Group list data matches the schema.
 *
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedGroupList.
 */
export const isNormalisedGroupListGuard = (map: unknown): map is NormalisedGroupList =>
  normalisedGroupListZodSchema.safeParse(map).success;

/**
 * When the normalised Group list data does not match the schema
 * then get the error as to show where the problem is.
 *
 * @param {unknown} map Map of the data to check.
 * @return {z.SafeParseReturnType<unknown, NormalisedGroupList>} The error on where the map of the data does not match a NormalisedGroupList.
 */
export const getNormalisedGroupListError = (map: unknown): z.SafeParseReturnType<unknown, NormalisedGroupList> =>
  normalisedGroupListZodSchema.safeParse(map);
