import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { UPDATE_DELAY } from '../../constants/magic-numbers';
import { WINDOW_SIZES, WindowSizeEnum } from './window-events.service.constants';

/**
 * The window event service.
 * Window events as observables so that we can debounce, throttle etc.
 * @export
 * @class WindowEventsService
 */
@Injectable({
  providedIn: 'root',
})
export class WindowEventsService {
  /**
   * Private current window size as an enum description Behaviour Subject.
   * @private
   * @memberof WindowEventsService
   */
  private readonly windowSizeStream$ = new BehaviorSubject<WindowSizeEnum>(this.getWindowSize());

  /**
   * The current window size as a public observable enum description.
   * @type {Observable<WindowSizeEnum>}
   * @memberof WindowEventsService
   */
  public windowSize$: Observable<WindowSizeEnum> = this.windowSizeStream$.asObservable();

  /**
   * Emits when the window size is large.
   * @memberof WindowEventsService
   */
  public isLargeWindowSize$ = this.windowSize$.pipe(
    distinctUntilChanged(),
    map((size) => size !== WindowSizeEnum.ExtraSmall && size !== WindowSizeEnum.Small && size !== WindowSizeEnum.Medium)
  );

  /**
   * The window resize event as a stream. Use this to debounce etc.
   * @type {Observable<Event>}
   * @memberof WindowEventsService
   */
  public resize$: Observable<Event> = fromEvent(window, 'resize');

  /**
   * Creates an instance of WindowEventsService.
   * When the window changes size.
   * Then update the current window size observable.
   * @param {Window} windowRef The Window Reference.
   * @memberof WindowEventsService
   */
  constructor(@Inject(WINDOW) private readonly windowRef: Window) {
    this.resize$.pipe(debounceTime(UPDATE_DELAY)).subscribe(() => {
      this.windowSizeStream$.next(this.getWindowSize());
    });
  }

  /**
   * Get the current window size.
   * Described as an Enum value.
   * @private
   * @return {WindowSizeEnum} The description of the current window size.
   * @memberof WindowEventsService
   */
  private getWindowSize(): WindowSizeEnum {
    const sizes = Object.keys(WINDOW_SIZES) as WindowSizeEnum[];
    const size = sizes.find((resolution) => this.windowRef.innerWidth > WINDOW_SIZES[resolution]);

    return size || WindowSizeEnum.ExtraSmall;
  }
}
