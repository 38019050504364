<span
  class="sc-radio-button flex sc-py-4 align-items-center"
  *ngFor="let option of options; let index = index; trackBy: trackByFunction">
  <span class="sc-radio-button__input flex align-items-center">
    <input
      #optionRef
      i18n-value
      type="radio"
      [name]="name"
      [formControl]="control"
      [value]="option.value"
      [checked]="control.value === option.value" />
  </span>
  <label class="sc-pl-4 sc-label" [for]="optionRef">{{ option.label }}</label>
</span>
