import { QueryOptions } from '@apollo/client';
import { gql } from 'apollo-angular';

/**
 * GraphQL query for getting all roles.
 */
const ROLES_QUERY = gql`
  query roles {
    roles {
      roleId
      description
      name
      availableToGuestUsers
      permissions {
        create
        delete
        grant
        install
        read
        type
        update
      }
    }
  }
`;

/**
 * Function to create the GraphQL query options for getting all roles.
 * @return {QueryOptions} The GraphQL query options.
 */
export const apolloRolesQuery = (): QueryOptions => ({
  query: ROLES_QUERY,
});

/**
 * GraphQL query for getting a role.
 */
const ROLE_QUERY = gql`
  query role($roleId: UUID!) {
    role(roleId: $roleId) {
      roleId
      description
      name
      availableToGuestUsers
      permissions {
        create
        delete
        grant
        install
        read
        type
        update
      }
    }
  }
`;

/**
 * Function to create the GraphQL query options for getting a role.
 * @param {string} roleId The ID of the role to get.
 * @return {QueryOptions} The GraphQL query options.
 */
export const apolloRoleQuery = (roleId: string): QueryOptions => ({
  query: ROLE_QUERY,
  variables: { roleId },
});
