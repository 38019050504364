import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RecordLinkableActions } from '../actions/linkable-records.actions';
import { RecordLinkedActions } from '../actions/linked-records.actions';
import { LINKABLE, LINKED } from '../constants/record.constants';
import { RecordLinkInterface } from '../models/record-link/record-link.model';

export const RECORD_LINKS_FEATURE_KEY = 'recordLink';

export interface StateInterface extends EntityState<RecordLinkInterface> {
  selectedId?: string; // which Entity View Table Component Column record has been selected
  loaded: boolean; // has the Entity View Table Component Column list been loaded
  error?: string | null; // last known error (if any)
}

export interface RecordLinksPartialStateInterface {
  readonly [RECORD_LINKS_FEATURE_KEY]: StateInterface;
}

export const recordLinksAdapter: EntityAdapter<RecordLinkInterface> = createEntityAdapter<RecordLinkInterface>({
  selectId: (recordLinks: RecordLinkInterface) =>
    recordLinks.isLinked ? `${recordLinks.tabId}.${LINKED}` : `${recordLinks.tabId}.${LINKABLE}`,
});

export const initialState: StateInterface = recordLinksAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Record Links reducer.
 */
const recordLinksReducer = createReducer(
  initialState,
  on(
    RecordLinkableActions.readLinkableRecordsSuccess,
    RecordLinkedActions.readLinkedRecordsSuccess,
    (state: StateInterface, { recordLink }) => recordLinksAdapter.upsertOne(recordLink, state)
  ),
  on(RecordLinkedActions.selectLinkedRecordTab, (state: StateInterface, { tabId }: { tabId: string }) => ({
    ...state,
    selectedId: `${tabId}.${LINKED}`,
  })),
  on(RecordLinkableActions.selectLinkableRecordTab, (state: StateInterface, { tabId }: { tabId: string }) => ({
    ...state,
    selectedId: `${tabId}.${LINKABLE}`,
  })),
  on(
    RecordLinkableActions.linkRecordsSuccess,
    (state: StateInterface, { tabId, recordsIds }: { tabId: string; recordsIds: string[] }) => {
      const linkableRecords =
        state.entities[`${tabId}.${LINKABLE}`]?.recordIds.filter((id) => !recordsIds.includes(id)) || [];
      const linkedRecords = state.entities[`${tabId}.${LINKED}`]?.recordIds.concat(recordsIds) || [];

      const newState = recordLinksAdapter.updateOne(
        {
          id: `${tabId}.${LINKABLE}`,
          changes: { recordIds: linkableRecords },
        },
        state
      );

      return recordLinksAdapter.updateOne(
        {
          id: `${tabId}.${LINKED}`,
          changes: { recordIds: linkedRecords },
        },
        newState
      );
    }
  ),
  on(
    RecordLinkedActions.unlinkRecordsSuccess,
    (state: StateInterface, { tabId, recordsIds }: { tabId: string; recordsIds: string[] }) => {
      const linkedRecords =
        state.entities[`${tabId}.${LINKED}`]?.recordIds.filter((id) => !recordsIds.includes(id)) || [];

      const linkableEntity = state.entities[`${tabId}.${LINKABLE}`]
        ? state.entities[`${tabId}.${LINKABLE}`]
        : { tabId, isLinked: false, recordIds: [], viewerCanCreate: false };

      let newState = state;

      if (linkableEntity) {
        linkableEntity.recordIds = linkableEntity.recordIds.concat(recordsIds);
        newState = recordLinksAdapter.upsertOne(linkableEntity, newState);
      }

      return recordLinksAdapter.updateOne(
        {
          id: `${tabId}.${LINKED}`,
          changes: { recordIds: linkedRecords },
        },
        newState
      );
    }
  )
);

/**
 * Perform reducer logic on record Links NGRX state store for a specific record link action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return recordLinksReducer(state, action);
}
