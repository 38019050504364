import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UNTITLED } from '@surecloud/common';
import { InputNumberAndDropdownsComponent } from '../../../input-number-and-dropdown/input-number-and-dropdowns.component';
import { ValidationRendererNumberAndDropdownControlConfigInterface } from '../validation-component-renderer-config.interface';
import { isValidationRendererNumberAndDropdownControlComponentRef } from '../validation-component-renderer.guards';

/**
 * Dynamically creates a validation NumberAndDropdownComponent with a form control
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {ValidationRendererNumberAndDropdownControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<InputNumberAndDropdownsComponent>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<InputNumberAndDropdownsComponent> | undefined} - Dynamic Component
 */
export const validationNumberAndDropdownControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: ValidationRendererNumberAndDropdownControlConfigInterface | undefined,
  component: Type<InputNumberAndDropdownsComponent>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = false
): ComponentRef<InputNumberAndDropdownsComponent> | undefined => {
  if (!componentData) return undefined;

  const {
    inputLabel,
    dropdownLabel,
    inputDescription,
    dropdownDescription,
    inputControlName,
    dropdownControlName,
    options,
    secondDropdownOptions,
    secondDropdownLabel,
    secondDropdownDescription,
    secondDropdownControlName,
  } = componentData;
  const componentRef = viewContainerRef.createComponent(component);

  if (!isValidationRendererNumberAndDropdownControlComponentRef(componentRef)) return undefined;

  componentRef.instance.inputDescription = inputDescription ?? UNTITLED;
  componentRef.instance.dropdownDescription = dropdownDescription ?? UNTITLED;
  componentRef.instance.inputLabel = inputLabel ?? UNTITLED;
  componentRef.instance.dropdownLabel = dropdownLabel ?? UNTITLED;
  componentRef.instance.readonly = readonly;
  componentRef.instance.options = options;
  componentRef.instance.secondDropdownDescription = secondDropdownDescription ?? UNTITLED;
  componentRef.instance.secondDropdownLabel = secondDropdownLabel ?? UNTITLED;
  componentRef.instance.secondDropdownOptions = secondDropdownOptions;
  componentRef.instance.secondDropdownControlName = secondDropdownControlName;

  if (!control) return componentRef;

  componentRef.instance.inputControlName = inputControlName;
  componentRef.instance.dropdownControlName = dropdownControlName;

  componentRef.instance.parentFormGroup = control.group;

  return componentRef;
};
