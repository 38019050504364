/**
 * User Profile Permissions Enum
 */
export enum UserProfilePermissionsEnum {
  application = 'APPLICATION',
  customHierarchy = 'CUSTOMHIERARCHY',
  dashboard = 'DASHBOARD',
  entity = 'ENTITY',
  group = 'GROUP',
  hierarchy = 'HIERARCHY',
  integration = 'INTEGRATION',
  notification = 'NOTIFICATION',
  optionlist = 'OPTIONLIST',
  question = 'QUESTION',
  role = 'ROLE',
  sandbox = 'SANDBOX',
  tenant = 'TENANT',
  user = 'USER',
  view = 'VIEW',
  workflow = 'WORKFLOW',
  riskmatrix = 'RISKMATRIX',
}
