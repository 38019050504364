/**
 * Type Guard for Grid Tags
 *
 * @param {unknown} tag - The tag to check.
 * @return {boolean} - true or false
 */
export const isTagsGuard = (tag: unknown): tag is { value: number, display: string} => tag !== undefined && (tag as {value: number, display: string})?.value !== undefined && (tag as {value: number, display: string})?.display !== undefined;

/**
 * Type Guard for Arrays of Tags
 *
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfTagsGuard = (value: unknown): value is Array<{ value: number, display: string}> =>
  Array.isArray(value) && value.every((item) => isTagsGuard(item) );
