import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonIconModule } from '../../../icon/icons/common-icon.module';
import { GridRelativeDateInterface } from './relative-date-renderer.interface';
import { DUE_TO_ARIA_LABEL_MAP, DUE_TO_ICON_MAP, DUE_TO_STRING_MAP } from './relative-date-renderer.mapper';

/**
 * GridRelativeDateRendererComponent - custom AgGrid component for a attribute type column.
 *
 * @export
 * @class GridRelativeDateRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CommonIconModule],
  styleUrls: ['./relative-date-renderer.component.scss'],
  selector: 'sc-relative-date-renderer',
  templateUrl: './relative-date-renderer.component.html',
})
export class GridRelativeDateRendererComponent implements ICellRendererAngularComp {
  /**
   * Icon and optional label to render.
   * Icon defaults to alert with no label.
   *
   * @type {GridRelativeDateInterface}
   * @memberof GridRelativeDateRendererComponent
   */
  relativeDate: GridRelativeDateInterface | undefined = undefined;

  /**
   * Handles the ag init and sets the value of the checkbox initial value
   *
   * @param {ICellRendererParams} params cell renderer params
   * @memberof GridRelativeDateRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    const field = params.colDef?.field;

    const relativeDateAndUrgency = field && params.data[field] ? params.data[field] : undefined;

    if (relativeDateAndUrgency) {
      const { label, urgency, dueDate } = relativeDateAndUrgency;
      const icon = DUE_TO_ICON_MAP[urgency];
      this.relativeDate = {
        label,
        urgency: DUE_TO_STRING_MAP[urgency],
        ...icon,
        ariaLabel: DUE_TO_ARIA_LABEL_MAP[urgency],
        dueDate,
      };
    }
  }
  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridRelativeDateRendererComponent
   * @return {boolean} isRefresh
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
