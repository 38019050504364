<ng-container [ngSwitch]="selectType">
  <sc-select-dropdown
    *ngSwitchCase="CONSTANTS.OPTION_LIST_SELECT_TYPE.SINGLE"
    [controlName]="controlName"
    [description]="description"
    [readonly]="readonly"
    [label]="label"
    [parentFormGroup]="parentFormGroup"
    [options]="options"
    [defaultItem]="defaultItem"></sc-select-dropdown>
  <sc-multiselect-dropdown
    *ngSwitchCase="CONSTANTS.OPTION_LIST_SELECT_TYPE.MULTI"
    [controlName]="controlName"
    [description]="description"
    [readonly]="readonly"
    [placeholder]="multiSelectPlaceholder"
    [label]="label"
    [options]="options"></sc-multiselect-dropdown>
  <p *ngSwitchDefault i18n>Options list for type {{ selectType }} not currently supported.</p>
</ng-container>
