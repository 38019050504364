import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastSeverityEnum } from '../../models/toast.enum';
import { defaultMessageConfig } from './toast.constants';

/**
 * Surecloud Toast service that wrappers primeNG message service.
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /**
   * Creates an instance of MessageService.
   * @param {MessageService} messageService The PrimeNG Message service.
   * @memberof MessageService
   */
  constructor(private messageService: MessageService) {}

  /**
   * Show a toast message.
   * @param {string} message The message to display.
   * @param {ToastSeverityEnum} severity The severity of the message. (The default is ERROR)
   */
  handleMessage(message: string, severity: ToastSeverityEnum = ToastSeverityEnum.ERROR): void {
    this.messageService.add({
      ...defaultMessageConfig,
      detail: message,
      severity,
    });
  }
}
