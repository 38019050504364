import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityInterface } from '@surecloud/common';
import { ENTITY_FEATURE_KEY, entityAdapter, StateInterface } from '../../reducers/entity/entity.reducer';

// Lookup the 'Entity' feature state managed by NgRx
export const getEntityState = createFeatureSelector<StateInterface>(ENTITY_FEATURE_KEY);

const { selectAll, selectEntities } = entityAdapter.getSelectors();

export const getEntityLoaded = createSelector(getEntityState, (state: StateInterface) => state.loaded);
export const getEntityError = createSelector(getEntityState, (state: StateInterface) => state.error);
export const getEntityList = createSelector(getEntityState, (state: StateInterface) => selectAll(state));
export const getEntityEntities = createSelector(getEntityState, (state: StateInterface) => selectEntities(state));
export const getSelectedId = createSelector(getEntityState, (state: StateInterface) => state.selectedId);

export const getSelected = createSelector(getEntityEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

/**
 * Get a entity by ID.
 * @param {string} entityId The ID of the entity to get.
 * @return {EntityInterface | undefined} The Entity if it exists in the state.
 */
export const getEntityById = (
  entityId: string
): MemoizedSelector<
  object,
  EntityInterface | undefined,
  (s1: Dictionary<EntityInterface>) => EntityInterface | undefined
> => createSelector(getEntityEntities, (entities) => entities[entityId]);

/**
 * Get a entity by ID.
 * @param {string []} entityIds The list ID of the entities to get.
 * @return {EntityInterface | undefined} The Entity if it exists in the state.
 */
export const getEntitiesById = (
  entityIds: string[]
): MemoizedSelector<
  object,
  (EntityInterface | undefined)[],
  (s1: Dictionary<EntityInterface>) => (EntityInterface | undefined)[]
> => createSelector(getEntityEntities, (entities) => entityIds.map((entityId) => entities[entityId]));
