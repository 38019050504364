import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { FontService } from '@surecloud/common';
import { first } from 'rxjs';
import { coreApplicationFonts } from './core-app.fonts';

/**
 * Core wrapper application component.
 * @export
 * @class CoreAppComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'core-app-root',
  templateUrl: './core-app.component.html',
  styleUrls: ['./core-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreAppComponent implements AfterViewInit {
  /**
   * Creates an instance of CoreAppComponent.
   * @param {FontService} fonts The full feature font loading service.
   * @memberof CoreAppComponent
   */
  constructor(private readonly fonts: FontService) {}

  /**
   * Runs after Angular initiates the view. Here we will load the full custom FontFaces we need. Our initial load of the font via CSS
   * was as light as possible to increase performance so not all the font features were included.
   * @memberof CoreAppComponent
   */
  ngAfterViewInit(): void {
    this.fonts.load(coreApplicationFonts).pipe(first()).subscribe();
  }
}
