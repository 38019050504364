import { createActionGroup, emptyProps } from '@ngrx/store';
/**
 * Toolbar User menu Page actions map.
 */
const TOOLBAR_USER_MENU_PAGE_ACTIONS_MAP = {
  toggle: emptyProps(),
  open: emptyProps(),
  close: emptyProps(),
};

/**
 * Toolbar User menu page actions.
 * @export
 */
export const ToolbarUserMenuPageActions = createActionGroup({
  source: 'Toolbar User Menu/Page',
  events: TOOLBAR_USER_MENU_PAGE_ACTIONS_MAP,
});
