<kendo-formfield class="block" showErrors="initial" [ngClass]="{ 'sc-mb-20': marginBottom }">
  <kendo-label
    [text]="label"
    class="sc-label flex-column"
    [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
    <input
      type="hidden"
      name="date-hidden"
      class="hidden"
      [formControl]="control"
      [ngClass]="{ 'k-disabled': control.disabled }" />
    <kendo-datepicker
      class="sc-date-picker flex-row-reverse"
      #datePicker
      name="date"
      [placeholder]="placeholder"
      i18n-placeholder
      [navigation]="false"
      [ngClass]="{
        'sc-input--readonly': readonly && !control.disabled
      }"
      [disabled]="control.disabled"
      [format]="format"
      [formatPlaceholder]="formatPlaceholder"
      [style.width.px]="staticWidth"
      [readonly]="readonly"
      [size]="size"
      [value]="datePickerValue"
      (valueChange)="handleDateChange($event)"
      [popupSettings]="{ popupClass: 'sc-date-picker__popup' }">
    </kendo-datepicker>
    <div class="flex">
      <div
        i18n
        *ngIf="enableSuccessValidationStyle && control.valid"
        class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
        <div class="sc-pt-2 sc-pl-2">
          <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
        </div>
        Success
      </div>
      <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
        <div class="sc-pt-2 sc-pl-2">
          <sc-icon key="alert-circle-outline" size="medium" colour="red-300" class="sc-mr-4"></sc-icon>
        </div>
        Incomplete
      </div>
      <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
    </div>
  </kendo-label>
</kendo-formfield>
