import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TableRecordActions } from '../actions/table-records.actions';
import { TableRecordInterface } from '../models/table-record/table-record.model';

export const TABLE_RECORD_FEATURE_KEY = 'tableRecord';

export interface StateInterface extends EntityState<TableRecordInterface> {
  selectedId?: string; // which Table Record has been selected
  loaded: boolean; // has Table Record been loaded
  error?: string | null; // last known error (if any)
}

export interface TableRecordPartialStateInterface {
  readonly [TABLE_RECORD_FEATURE_KEY]: StateInterface;
}

export const tableRecordAdapter: EntityAdapter<TableRecordInterface> = createEntityAdapter<TableRecordInterface>({
  selectId: (tableRecord: TableRecordInterface) => tableRecord.parentId,
});

export const initialState: StateInterface = tableRecordAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Table Record reducer.
 */
const tableRecordReducer = createReducer(
  initialState,
  on(TableRecordActions.updateTableRecordSuccess, (state: StateInterface, { record }) =>
    tableRecordAdapter.upsertOne(record, state)
  ),
  on(TableRecordActions.updateTableRecordsSuccess, (state: StateInterface, { records }) =>
    tableRecordAdapter.upsertMany(records, state)
  ),
  on(
    TableRecordActions.addLinkedTableRecord,
    TableRecordActions.addLinkableTableRecord,
    (state: StateInterface, { tableRecord }) => tableRecordAdapter.addOne(tableRecord, state)
  ),
  on(
    TableRecordActions.removeLinkedTableRecord,
    TableRecordActions.removeLinkableTableRecord,
    (state: StateInterface, { tableRecord }) => tableRecordAdapter.removeOne(tableRecord.parentId, state)
  )
);

/**
 * Perform reducer logic on Table Record NGRX state store for a specific Table Record action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return tableRecordReducer(state, action);
}

