import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconButtonComponent } from '../button/icon-button.component';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';

/**
 * Surecloud Infobox Component.
 *
 * @export
 * @class InfoboxComponent
 */
@Component({
  selector: 'sc-infobox',
  standalone: true,
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconButtonComponent, SvgIconsModule],
})
export class InfoboxComponent {}
