<kendo-formfield class="block sc-user-avatar-multiselect" showErrors="initial">
  <kendo-label
    [text]="label"
    class="sc-label flex-column"
    [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
    <kendo-multiselect
      #multiselect
      textField="display"
      valueField="value"
      [autoClose]="true"
      [filterable]="true"
      [clearButton]="false"
      [valuePrimitive]="true"
      [checkboxes]="{ checkOnClick: false }"
      [data]="options"
      [readonly]="readonly"
      [formControl]="control"
      [placeholder]="placeholder"
      [style.width.px]="staticWidth"
      (open)="isPopupOpen = true"
      (close)="isPopupOpen = false"
      [attr.aria-expanded]="isPopupOpen"
      [class.multiselect__chip]="control.value?.length"
      [popupSettings]="{
        popupClass: 'sc-scrollbar sc-user-avatar-multiselect-popup'
      }"
      [ngClass]="{
        'sc-input--readonly': readonly && !control.disabled
      }"
      (filterChange)="filterChange.emit($event)">
      <ng-template kendoMultiSelectTagTemplate let-user>
        <sc-user-avatar-chip
          class="sc-mr-4"
          [userAvatarChip]="user"
          (remove)="removeTag($event)"
          [isRemovable]="!readonly"></sc-user-avatar-chip>
      </ng-template>
      <ng-template kendoMultiSelectItemTemplate let-user>
        <div class="flex align-items-center user-avatar-multiselect__list-item">
          <sc-user-avatar [initials]="user?.initials" [hexColour]="user?.colour"></sc-user-avatar>
          <div class="flex-column">
            <div>{{ user?.display }}</div>
            <div class="user-avatar-multiselect__subtitle">{{ user?.subtitle }}</div>
          </div>
        </div>
      </ng-template>
    </kendo-multiselect>
    <div class="flex">
      <div
        i18n
        *ngIf="enableSuccessValidationStyle && control.valid"
        class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
        <div class="sc-pt-2 sc-pl-2">
          <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
        </div>
        Success
      </div>
      <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
        <div class="sc-pt-2 sc-pl-2">
          <sc-icon key="checkmark-outline" size="small" colour="red-300" class="sc-mr-4"></sc-icon>
        </div>
        Incomplete
      </div>
      <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
    </div>
  </kendo-label>
</kendo-formfield>
