import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import { makeAttributeConfigComponent, makeAttributeConfigSectionId } from '../global-attribute-config.model';

/**
 * Makes the global calculation attribute configuration
 */
export const GLOBAL_CALCULATION_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Calculation,
  key: EntityAttributeTypeEnum.Calculation,
  type: EntityAttributeTypeEnum.Calculation,
  label: $localize`Calculation`,
  uniqueSections: [
    {
      sectionComponent: makeAttributeConfigComponent('Calculation'),
      sectionId: makeAttributeConfigSectionId('calculation'),
      sectionName: $localize`Additional Properties`,
    },
  ],
});
