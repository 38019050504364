import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ATTRIBUTE_ICON_MAP,
  EntityAttributeTypeEnum,
  NO_ATTRIBUTE_TYPE,
  getTranslatedAttributeText,
} from '@surecloud/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonIconModule } from '../../../icon/icons/common-icon.module';
import { GridAttributeTypeRendererInterface } from './grid-attribute-type-renderer.inteface';

/**
 * GridAttributeTypeRendererComponent - custom AgGrid component for a attribute type column.
 *
 * @export
 * @class GridAttributeTypeRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CommonIconModule],
  selector: 'sc-attribute-type-renderer',
  templateUrl: './grid-attribute-type-renderer.component.html',
})
export class GridAttributeTypeRendererComponent implements ICellRendererAngularComp {
  icon: GridAttributeTypeRendererInterface = { name: undefined, icon: undefined };
  /**
   * Handles the ag init and sets the value of the checkbox initial value
   *
   * @param {ICellRendererParams} params cell renderer params
   * @memberof GridAttributeTypeRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    const attType = (params.data.type as EntityAttributeTypeEnum) || NO_ATTRIBUTE_TYPE;
    const iconName = getTranslatedAttributeText(attType);
    const type = ATTRIBUTE_ICON_MAP[attType];
    this.icon = { name: iconName, icon: type };
  }
  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridAttributeTypeRendererComponent
   * @return {boolean} isRefresh
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
