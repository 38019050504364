import {
  BooleanColumnFilterTypeUIEnum,
  ColumnFilterInterface,
  ColumnFilterTypeUIEnum,
  ColumnSortTypeUIEnum,
  ColumnTypesUIEnum,
  DateColumnFilterInterface,
  getDateString,
  JoinColumnFilterTypeUIEnum,
  NumberColumnFilterInterface,
  TEXT_BRAND_500,
  TEXT_RED_300,
  TextColumnFilterTypeUIEnum,
  ValueOf,
} from '@surecloud/common';
import { ChangedPath, RowNode } from 'ag-grid-community';
import { PopupMenuItemInterface } from '../../popup-menu/popup-menu.component.interface';

/**
 * Type for the AG Grid model
 * used for Grid model type guard
 */
type GridModel = {
  doFilter: (changedPath: ChangedPath) => void;
  doSort: () => void;
  rootNode: {
    childrenAfterSort: unknown[];
    childrenAfterFilter: unknown[];
  };
};

/**
 * Type for the filter data.
 */
export type FilterData = {
  filter: string;
  filterTo?: string; // used for number Between filter
  filterType: string;
  dateFrom?: string; // used for date Between filter
  dateTo?: string;
  type: ValueOf<typeof ColumnFilterTypeUIEnum>;
};

/**
 * Type for the filter model.
 */
export type FilterModel = FilterData & {
  key: string;
  filterData?: FilterData;
  conditions?: FilterData[];
  operator?: string;
};

export const GRID_MENU_DELETE_OPTION: PopupMenuItemInterface = {
  id: 'DELETE',
  text: $localize`Delete`,
  icon: 'trash',
  colourClass: TEXT_RED_300,
  size: 'x-large',
  disabled: true,
};

const DOWNLOAD_ICON = 'download';

export const GRID_MENU_EXPORT_ALL_OPTION: PopupMenuItemInterface = {
  id: 'EXPORT_ALL',
  text: $localize`Export All`,
  icon: DOWNLOAD_ICON,
  colourClass: TEXT_BRAND_500,
  size: 'x-large',
};

export const GRID_MENU_EXPORT_SELECTED_OPTION: PopupMenuItemInterface = {
  id: 'EXPORT_SELECTION',
  text: $localize`Export Selection`,
  icon: DOWNLOAD_ICON,
  colourClass: TEXT_BRAND_500,
  size: 'x-large',
  disabled: true,
};

export const GRID_MENU_IMPORT_OPTION: PopupMenuItemInterface = {
  id: 'IMPORT',
  text: $localize`Import`,
  icon: 'upload',
  colourClass: TEXT_BRAND_500,
  size: 'x-large',
  disabled: false,
};

export const MENU_SEQUENCE_ID = ['EXPORT_ALL', 'EXPORT_SELECTION', 'IMPORT', 'DELETE'];

/**
 * Function to order the menu
 * @param {PopupMenuItemInterface[]} items menu items
 * @return {PopupMenuItemInterface[]}  the items ordered
 */
export const setMenuItemsSequence = (items: PopupMenuItemInterface[]): PopupMenuItemInterface[] =>
  items.slice().sort((a, b) => {
    const indexA = MENU_SEQUENCE_ID.indexOf(a.id);
    const indexB = MENU_SEQUENCE_ID.indexOf(b.id);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

/**
 * Function to handle the menu options
 * @param {boolean} currentValue current value
 * @param {PopupMenuItemInterface[]} gridMenuItems grid menu items
 * @param {(PopupMenuItemInterface | PopupMenuItemInterface[])} options options
 * @return {PopupMenuItemInterface[]} the items ordered
 */
export const handleMenuOptions = (
  currentValue: boolean,
  gridMenuItems: PopupMenuItemInterface[],
  options: PopupMenuItemInterface | PopupMenuItemInterface[]
): PopupMenuItemInterface[] => {
  if (currentValue) {
    return Array.isArray(options) ? [...options, ...gridMenuItems] : [options, ...gridMenuItems];
  }

  return gridMenuItems.filter((item) => item !== options);
};

/**
 * Get the column type from the type string.
 * @param {string} type column type as string
 * @return {ValueOf<typeof ColumnTypesUIEnum>} parsed column type
 */
export const getColumnType = (type: string): ValueOf<typeof ColumnTypesUIEnum> => {
  switch (type) {
    case 'number':
      return ColumnTypesUIEnum['Number'];
    case 'date':
      return ColumnTypesUIEnum['Date'];
    case 'boolean':
      return ColumnTypesUIEnum['Boolean'];
    case 'string':
    default:
      return ColumnTypesUIEnum['Text'];
  }
};

/**
 * Get the column sort order from the order string.
 * @param {string} order column order as string
 * @return {ValueOf<typeof ColumnSortTypeUIEnum>} parsed column order
 */
export const getOrderType = (order: string | null | undefined): ValueOf<typeof ColumnSortTypeUIEnum> => {
  switch (order) {
    case 'desc':
      return ColumnSortTypeUIEnum['Desc'];
    case 'asc':
    default:
      return ColumnSortTypeUIEnum['Asc'];
  }
};

/**
 * Get column filter type by corresponding api type.
 * @param {string} type filter type as string
 * @return {ValueOf<typeof ColumnFilterTypeUIEnum>} filter type
 */
export const getColumnFilterType = (type: string): ValueOf<typeof ColumnFilterTypeUIEnum> => {
  switch (type) {
    case 'blank':
      return ColumnFilterTypeUIEnum['Blank'];
    case 'greaterThan':
      return ColumnFilterTypeUIEnum['GreaterThan'];
    case 'greaterThanOrEqual':
      return ColumnFilterTypeUIEnum['GreaterThanOrEqual'];
    case 'lessThan':
      return ColumnFilterTypeUIEnum['LessThan'];
    case 'lessThanOrEqual':
      return ColumnFilterTypeUIEnum['LessThanOrEqual'];
    case 'notBlank':
      return ColumnFilterTypeUIEnum['NotBlank'];
    case 'notEqual':
      return ColumnFilterTypeUIEnum['NotEqual'];
    case 'inRange':
      return ColumnFilterTypeUIEnum['InRange'];
    case 'equals':
    default:
      return ColumnFilterTypeUIEnum['Equals'];
  }
};

/**
 * Get the text column filter type from the type string.
 * @param {string} type column type as string
 * @return {ValueOf<typeof TextColumnFilterTypeUIEnum>} parsed column type
 */
export const getTextColumnFilter = (type: string): ValueOf<typeof TextColumnFilterTypeUIEnum> => {
  switch (type) {
    case 'blank':
      return TextColumnFilterTypeUIEnum['Blank'];
    case 'contains':
      return TextColumnFilterTypeUIEnum['Contains'];
    case 'endsWith':
      return TextColumnFilterTypeUIEnum['EndsWith'];
    case 'notBlank':
      return TextColumnFilterTypeUIEnum['NotBlank'];
    case 'notContains':
      return TextColumnFilterTypeUIEnum['NotContains'];
    case 'notEqual':
      return TextColumnFilterTypeUIEnum['NotEqual'];
    case 'startsWith':
      return TextColumnFilterTypeUIEnum['StartsWith'];
    case 'equals':
    default:
      return TextColumnFilterTypeUIEnum['Equals'];
  }
};

/**
 * Get the boolean column filter type from the type string.
 * @param {string} type column type as string
 * @return {ValueOf<typeof BooleanColumnFilterTypeUIEnum>} parsed column type
 */
export const getBooleanColumnFilterType = (type: string): ValueOf<typeof BooleanColumnFilterTypeUIEnum> => {
  switch (type) {
    case 'true':
      return BooleanColumnFilterTypeUIEnum['True'];
    case 'false':
    default:
      return BooleanColumnFilterTypeUIEnum['False'];
  }
};

const createDateFilter = (
  dateFrom: string | undefined,
  dateTo: string | undefined,
  columnId: string,
  type: string
): ColumnFilterInterface => {
  const dateColumnFilter: DateColumnFilterInterface = {
    columnId,
    type: getColumnFilterType(type),
  };

  if (
    dateColumnFilter.type !== ColumnFilterTypeUIEnum['Blank'] &&
    dateColumnFilter.type !== ColumnFilterTypeUIEnum['NotBlank']
  ) {
    dateColumnFilter.filter = getDateString(new Date(dateFrom || ''));
  }

  if (dateTo) {
    dateColumnFilter.filterTo = getDateString(new Date(dateTo));
  }
  return { dateColumnFilter };
};

/**
 * Create a number filter object.
 * @param {string} filter filter value
 * @param {string} filterTo filter value to
 * @param {string} columnId column id
 * @param {string} type filter type
 * @return {ColumnFilterInterface} filter model
 */
const createNumberFilter = (
  filter: string,
  filterTo: string | undefined,
  columnId: string,
  type: string
): ColumnFilterInterface => {
  const numberColumnFilter: NumberColumnFilterInterface = {
    columnId,
    type: getColumnFilterType(type),
    filter: Number(filter),
  };

  if (filterTo) {
    numberColumnFilter.filterTo = Number(filterTo);
  }

  return {
    numberColumnFilter,
  };
};

/**
 * Get the filter model from the filter object.
 * @param {string} columnId Column id
 * @param {FilterData | FilterModel} filterData filter data
 * @return {ColumnFilterInterface} filter model
 */
export const getFilterModel = (columnId: string, filterData: FilterData | FilterModel): ColumnFilterInterface => {
  const { filterType, type, filter: filterValue, dateFrom, dateTo, filterTo } = filterData;

  switch (filterType) {
    case 'number':
      return createNumberFilter(filterValue, filterTo, columnId, type);
    case 'date': {
      return createDateFilter(dateFrom, dateTo, columnId, type);
    }

    case 'boolean':
      return {
        booleanColumnFilter: {
          columnId,
          type: getBooleanColumnFilterType(type),
        },
      };

    case 'text':
    default:
      return {
        textColumnFilter: {
          columnId,
          filter: filterValue,
          type: getTextColumnFilter(type),
        },
      };
  }
};

/**
 * Get the filter model from the filter object.
 * @param {{ [key: string]: unknown }} filter key is columnId of the filtered column
 * value of the filter object is the filter data
 * @return {ColumnFilterInterface[]} filter model
 */
export const getFilterData = (filter: { [key: string]: unknown }): ColumnFilterInterface[] => {
  const filters: ColumnFilterInterface[] = [];

  Object.keys(filter || {}).forEach((key) => {
    const filterData = filter[key] as FilterModel;
    const conditions: ColumnFilterInterface[] = [];
    let columnFilter: ColumnFilterInterface = {};

    if (filterData?.conditions?.length) {
      filterData.conditions.forEach((condition) => {
        conditions.push(getFilterModel(key, condition));
      });
    } else {
      columnFilter = getFilterModel(key, filterData);
    }

    if (conditions.length) {
      columnFilter = {
        joinColumnFilter: {
          conditions,
          type: filterData.operator as ValueOf<typeof JoinColumnFilterTypeUIEnum>,
        },
      };
    }

    filters.push(columnFilter);
  });

  return filters;
};

/**
 * Filter function to replace the AG Grid client side filter.
 * Used for server side pagination to prevent double filtering - client side and server side.
 * @param {ChangedPath} changedPath - changed path object
 */
export const doFilter = (changedPath: ChangedPath): void => {
  changedPath.forEachChangedNodeDepthFirst((rowNode: RowNode) => {
    // eslint-disable-next-line no-param-reassign
    rowNode.childrenAfterFilter = rowNode.childrenAfterGroup;
    rowNode.setAllChildrenCount(null);
  }, true);
};
