  <kendo-label [text]="label" class="sc-label sc-mb-0" *ngIf="label"></kendo-label>
  <div class="sc-editor">
    <div [id]="controlName + '-toolbar'">
      <span class="ql-formats">
        <select class="ql-sc-formats">
          <option
            [attr.selected]="format.id === CONSTANTS.EDITOR_DEFAULT_FORMAT ? true : null"
            [value]="format.id"
            *ngFor="let format of CONSTANTS.EDITOR_FORMATS; trackBy: trackByFunction"></option>
        </select>
      </span>
      <span class="ql-formats">
        <sc-icon-button
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center"
          size="medium"
          (click)="formatText('bold')"
          themeColour="white"
          icon="bold"
          shape="rounded"
          aria-label="Bold"
          i18n-aria-label>
        </sc-icon-button>
        <sc-icon-button
          size="medium"
          (click)="formatText('italic')"
          themeColour="white"
          icon="italic"
          shape="rounded"
          aria-label="Italic"
          i18n-aria-label
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center sc-ml-2">
        </sc-icon-button>
        <sc-icon-button
          size="medium"
          (click)="formatText('underline')"
          themeColour="white"
          icon="underline"
          shape="rounded"
          aria-label="Underline"
          i18n-aria-label
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center sc-mr-2 sc-ml-2">
        </sc-icon-button>
        <sc-icon-button
          size="medium"
          themeColour="white"
          icon="paintbrush"
          shape="rounded"
          aria-label="Colour"
          i18n-aria-label
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center editor__format--highlight">
        </sc-icon-button>
        <select class="ql-background" aria-label="Background Colour" i18n-aria-label>
          <option selected></option>
          <option
            class="ql-color"
            [value]="colour"
            *ngFor="let colour of CONSTANTS.EDITOR_BACKGROUND_COLOURS; trackBy: trackByFunction"></option>
        </select>
      </span>
      <span class="ql-formats">
        <sc-icon-button
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center"
          size="medium"
          (click)="formatList('bullet')"
          themeColour="white"
          icon="unordered-list"
          shape="rounded"
          aria-label="Unordered List"
          i18n-aria-label>
        </sc-icon-button>
        <sc-icon-button
          size="medium"
          (click)="formatList('ordered')"
          themeColour="white"
          icon="ordered-list"
          shape="rounded"
          aria-label="Ordered List"
          i18n-aria-label
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center sc-ml-10">
        </sc-icon-button>
      </span>
      <span class="ql-formats">
        <sc-icon-button
          class="sc-editor__toolbar-icon flex justify-content-center align-items-center"
          size="medium"
          (click)="formatLink()"
          themeColour="white"
          icon="link"
          shape="rounded"
          aria-label="Link"
          i18n-aria-label>
        </sc-icon-button>
      </span>
      <span class="ql-formats">
        <sc-delete-button size="medium" (deleteClicked)="formatClear()" aria-label="Clear Formatting" i18n-aria-label>
        </sc-delete-button>
      </span>
    </div>
    <div #editor [id]="controlName + '-editor'" [style]="{ height: height + 'px' }"></div>
  </div>
  <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
