import { ApolloLink } from '@apollo/client/core';
import { ApolloErrorService, Auth0AndGuestUserAuthService } from '@surecloud/common';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { SSELink } from '../classes/sse/sse-link.class';
import { graphQLErrorsLink, networkErrorsLink } from './apollo-error-link';

/**
 * Gets the Headers to add to the SSELink
 * @export
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The Open Auth service.
 * @return {Promise<Record<string, string>>} Promise with the headers.
 */
export function getSSELinkHeaders(
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService
): Promise<Record<string, string>> {
  return firstValueFrom(
    auth0AndGuestUserAuthService.isAuthenticated$.pipe(
      switchMap((isAuth) => {
        if (isAuth) {
          return auth0AndGuestUserAuthService.getAccessTokenSilently();
        }

        return of(null);
      })
    )
  ).then((token) => {
    if (token) {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${token}`,
      };

      return headers;
    }

    return {};
  });
}

/**
 * Creates a SSELink.
 * @export
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The Open Auth service.
 * @param {string} subscriptionsUri The subscriptions URL.
 * @return {SSELink} The created SSELink.
 */
export function createSSELink(
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  subscriptionsUri: string
): SSELink {
  return new SSELink({
    url: subscriptionsUri,
    headers: () => getSSELinkHeaders(auth0AndGuestUserAuthService),
  });
}

/**
 * Combines graphQLErrorsLink(), networkErrorsLink() and createSSELink() that always execute in serial order.
 * Afterware & Middleware docs: https://www.apollographql.com/docs/react/v2/networking/network-layer/#middleware.
 * @export
 * @param {ApolloErrorService} errorService The service for error handling
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The Open Auth service.
 * @param {string} subscriptionsUri The Subscriptions URL.
 * @return {ApolloLink} The Apollo link.
 */
export function createSSEChainLink(
  errorService: ApolloErrorService,
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  subscriptionsUri: string
): ApolloLink {
  return ApolloLink.from([
    graphQLErrorsLink(errorService),
    networkErrorsLink(errorService),
    createSSELink(auth0AndGuestUserAuthService, subscriptionsUri),
  ]);
}
