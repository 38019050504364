<form [formGroup]="formGroup">
  <div class="flex align-items-center sc-py-4" *ngFor="let option of options; trackBy: trackByFunction">
    <sc-radio-buttons
      [name]="controlName"
      [formControlName]="controlName"
      [options]="[{ value: option.value, label: option.text }]"></sc-radio-buttons>
    <sc-input-number
      class="sc-p-4"
      *ngIf="option.input"
      [ngStyle]="{ 'width.px': inputWidth }"
      [controlName]="option.input"
      [min]="inputMin"
      [autoCorrect]="inputAutoCorrect"></sc-input-number>
    <p *ngIf="option.textAfterInput" i18n>{{ option.textAfterInput }}</p>
  </div>
</form>
