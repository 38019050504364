import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectDropdownComponentOptionInterface } from '@surecloud/common';
import { MultiselectDropdownComponent } from '../select-dropdown/multiselect-dropdown.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';
import { OPTION_LIST_CONSTANTS } from './option-list.constants';

/**
 * SC option list component, wrapper around a multi or single drop down
 * @export
 * @class OptionListComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-option-list',
  standalone: true,
  imports: [CommonModule, MultiselectDropdownComponent, SelectDropdownComponent],
  templateUrl: './option-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OptionListComponent {
  /**
   * Constants we need to expose to the user edit view.
   * @memberof OptionListComponent
   */
  readonly CONSTANTS = OPTION_LIST_CONSTANTS;
  /**
   * string value to determine if its single select or multiple select drop down
   * @memberof OptionListComponent
   */
  @Input() selectType = OPTION_LIST_CONSTANTS.OPTION_LIST_SELECT_TYPE.SINGLE;

  /**
   * Text to display an additional description to the user.
   * @memberof OptionListComponent
   */
  @Input() description = '';

  /**
   * Set the dropdown to readonly mode.
   * @memberof OptionListComponent
   */
  @Input() readonly = false;

  /**
   *
   *Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof OptionListComponent
   */
  @Input() controlName = '';

  /**
   * The label text for the select dropdown.
   * @memberof OptionListComponent
   */
  @Input() label = '';

  /**
   * Sets the placeholder value for the input.
   * @memberof OptionListComponent
   */

  @Input() set placeholder(text: string) {
    if (text) this.defaultItem = { text, value: null };
    this.multiSelectPlaceholder = text;
  }

  /**
   * Sets the default value for the input to be used as placeholder for single select list.
   * This solution as per the suggestion on https://feedback.telerik.com/kendo-angular-ui/1407766-placeholder-for-dropdownlist
   * @memberof OptionListComponent
   */
  defaultItem: { text: string; value: string | null } | undefined;

  /**
   * Sets the default value for the input.
   * @memberof OptionListComponent
   */
  multiSelectPlaceholder = '';
  /**
   * The parent form group of the select dropdown.
   * @type {FormGroup}
   * @memberof SelectDropdownComponent
   */
  @Input()
  parentFormGroup?: FormGroup;

  /**
   * The list of [options to show](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/multiselect/data-binding/) in the select dropdown.
   * @type {(SelectDropdownComponentOptionInterface[])}
   * @memberof OptionListComponent
   */
  @Input() options: SelectDropdownComponentOptionInterface[] = [];
}
