import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import * as HomepageApplicationsSelectors from '../selectors/homepage-applications/homepage-applications.selectors';

/**
 * The facade for the Homepage Applications feature sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 * @export
 * @class HomepageApplicationsFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class HomepageApplicationsFeatureFacade extends BaseFacade {
  /**
   * Boolean to determine if the Homepage Applications have been loaded.
   * @memberof HomepageApplicationsFeatureFacade
   */
  loaded$ = this.store.pipe(select(HomepageApplicationsSelectors.getHomepageApplicationsLoaded));

  /**
   * Homepage Applications tabs mapped for advance ag-grid
   * @memberof HomepageApplicationsFeatureFacade
   */
  getTabs$ = this.store.pipe(select(HomepageApplicationsSelectors.getHomepageApplicationTabs));

  /**
   * Homepage Applications active tab mapped for advance ag-grid
   * @memberof HomepageApplicationsFeatureFacade
   */
  getActiveTab$ = this.store.pipe(select(HomepageApplicationsSelectors.getActiveTabIndex));

  /**
   * Get the selected Homepage Application
   * @memberof HomepageApplicationsFeatureFacade
   */
  homepageApplicationForGrid$ = this.store.pipe(
    select(HomepageApplicationsSelectors.getSelectedForGrid)
  );

  /**
   * Creates an instance of HomepageApplicationsFeatureFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof HomepageApplicationsFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
