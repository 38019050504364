import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { LinkedRecordsInputApiInterface, RecordLinkInputApiInterface } from '@surecloud/api-types';
import { RequestIdInterface } from '@surecloud/common';
import { TableRecordLinkInterface } from '../../services/normalise-get-link-records/get-link.records.interface';
import { RecordLinkInterface } from '../models/record-link/record-link.model';

/**
 * Record Linked API actions map.
 */
const RECORD_LINKED_API_EVENTS_MAP = {
  'Read Linked Record List': props<{ linkedRecordInputList: LinkedRecordsInputApiInterface[] }>(),
  'Read Linked Record': props<{ linkedRecordInput: LinkedRecordsInputApiInterface }>(),
  'Read Linked Records Data Success': props<{ normalisedRecordLink: TableRecordLinkInterface }>(),
  'Read Linked Records Data Failure': props<{ error: string }>(),
  'Read Linked Records Success': props<{ recordLink: RecordLinkInterface }>(),
  'Read Linked Records Failure': props<{ error: string }>(),
  'Unlink Records': props<{ unlinkRecords: RecordLinkInputApiInterface; tabId: string }>(),
  'Unlink Records Success': props<{ recordsIds: string[]; tabId: string }>(),
  'Unlink Records Failure': props<{ error: string }>(),
  'Cancel Unlink Records': emptyProps(),
  'Create Linked Record Success': props<{ recordId: string }>(),
  'Create Linked Record Failure': props<{ error: string }>(),
};

/**
 * Record Linked API actions.
 * @export
 */
export const RecordLinkedApiActions = createActionGroup({
  source: 'Record Linked/API',
  events: RECORD_LINKED_API_EVENTS_MAP,
});

/**
 * Record Linked actions
 */
const RecordLinkedPageActions = {
  selectLinkedRecordTab: createAction('[Record View Table Page] Select Linked Record Tab', props<{ tabId: string }>()),
  createNewLinkedRecord: createAction(
    '[Record View Table Page] Create New Linked Record',
    props<{ tabId: string; linkId: string; recordId: string } & RequestIdInterface>()
  ),
};

/**
 * Record Linked API and Page actions.
 */
export const RecordLinkedActions = {
  ...RecordLinkedApiActions,
  ...RecordLinkedPageActions,
};
