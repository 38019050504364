import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { E2eHookDirective } from '@surecloud/common';
import { ScButtonSize } from '../button/button.constants';
import { IconButtonComponent } from '../button/icon-button.component';

/**
 * Surecloud Delete Button Component.
 * @export
 * @class DeleteButtonComponent
 */
@Component({
  selector: 'sc-delete-button',
  standalone: true,
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconButtonComponent, E2eHookDirective],
})
export class DeleteButtonComponent {
  /**
   * Sets the button type.
   * @memberof DeleteButtonComponent
   */
  @Input() buttonType = 'button';
  /**
   * If we want to disable the button.
   * @memberof DeleteButtonComponent
   */
  @Input()
  disabled = false;

  /**
   * The size of the button.
   * @memberof DeleteButtonComponent
   */
  @Input()
  size: ScButtonSize = 'small';

  /**
   * The test hook to pass in.
   * @memberof DeleteButtonComponent
   */
  @Input()
  testhook!: string;

  /**
   * The click event for the delete button.
   * @memberof DeleteButtonComponent
   */
  @Output() deleteClicked = new EventEmitter();
}
