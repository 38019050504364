import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { E2eHookDirective, trackByIndex } from '@surecloud/common';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';
import { RadioOptionInterface } from './radio-buttons.interface';

/**
 * Surecloud Wrapper Radio Buttons Component
 *
 * @example
 * <sc-radio-buttons
 *  [formControl]="formControl"
 *  [options]="options"
 * </sc-radio-buttons>
 * @export
 * @class RadioButtonsComponent
 */
@Component({
  selector: 'sc-radio-buttons',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, E2eHookDirective],
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonsComponent),
      multi: true,
    },
  ],
})
export class RadioButtonsComponent<RadioOptionValue> extends ControlValueAccessorConnector<RadioOptionValue> {
  /**
   * The list of value options.
   *
   * @memberof RadioButtonsComponent
   */
  @Input()
  options!: RadioOptionInterface<RadioOptionValue>[];

  /**
   * Set each radio with a unique value that uniquely identifies that individual radio button within the group.
   * Following HTML stantard to radio buttons element: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#defining_a_radio_group
   *
   * @type {string}
   * @memberof RadioButtonsComponent
   */
  @Input() name!: string;

  /**
   * Expose the track by function to the view template.
   *
   * @memberof RadioButtonsComponent
   */
  trackByFunction = trackByIndex;
}
