import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trackByIndex } from '@surecloud/common';
import { GridDocumentInterface } from './grid-document.interface';

@Component({
  selector: 'sc-grid-document',
  standalone: true,
  templateUrl: './grid-document.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class GridDocumentRendererComponent implements ICellRendererAngularComp {
  documents!: GridDocumentInterface[];

  trackByIndex = trackByIndex;

  agInit(params: ICellRendererParams): void {
    this.documents = params.value;
  }

  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
