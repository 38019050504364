import { createAction, props } from '@ngrx/store';
import { RuleActionInterface } from '../../models/rule-action.models';

export const RuleActionActions = {
  /**
   * RuleAction Actions Related to the internals of RuleAction
   * [Rule Action]
   */
  selectRuleAction: createAction('[Rule Action] Select Rule Action', props<{ actionId: string | undefined }>()),

  /**
   *RuleAction Actions Related to the RuleAction API
   * [Rule Action/API]
   */
  readRuleActionList: createAction('[Rule Action/API] Read RuleAction List'),
  readRuleActionListSuccess: createAction(
    '[Rule Action/API] Read RuleAction List Success',
    props<{ ruleActionList: RuleActionInterface[] }>()
  ),
  readRuleActionListFailure: createAction('[Rule Action/API] Read RuleAction List Failure', props<{ error: string }>()),

  createNewRuleActionSuccess: createAction('[Rule Action/API] CreateRuleAction Success', props<{ actionId: string }>()),
  createNewRuleActionFailure: createAction('[Rule Action/API] Create Rule Action Failure', props<{ error: string }>()),

  readRuleAction: createAction('[Rule Action/API] ReadRuleAction', props<{ actionId: string }>()),
  readRuleActionSuccess: createAction(
    '[Rule Action/API] ReadRuleAction Success',
    props<{ ruleAction: RuleActionInterface }>()
  ),
  readRuleActionFailure: createAction('[Rule Action/API] Read Rule Action Failure', props<{ error: string }>()),

  updateRuleAction: createAction('[Rule Action/API] Update RuleAction', props<{ ruleAction: RuleActionInterface }>()),
  updateRuleActionSuccess: createAction(
    '[Rule Action/API] Update RuleAction Success',
    props<{ partialRuleAction: Partial<RuleActionInterface> }>()
  ),
  updateRuleActionFailure: createAction('[Rule Action/API] Update Rule Action Failure', props<{ error: string }>()),

  deleteRuleAction: createAction('[Rule Action/API] Delete Rule Action', props<{ actionId: string }>()),
  deleteRuleActionSuccess: createAction(
    '[Rule Action/API] Delete RuleAction Success',
    props<{ actionId: string; routeCommands: string[] | null }>()
  ),
  deleteRuleActionFailure: createAction('[Rule Action/API] Delete RuleAction Failure', props<{ error: string }>()),
};

