/**
 * Temporary feature flag enum
 * Add new feature flags here.
 * @export
 * @enum {string}
 */
export enum TemporaryFeatureFlagEnum {
  Ng4155 = 'Ng4155',
  Ng4435 = 'Ng4435',
}

/**
 * Temporary feature flag type
 * If a flag is set to true, the feature is enabled.
 * @export
 * @interface TemporaryFeatureFlagLocalStorageType
 */
export type TemporaryFeatureFlagLocalStorageType = {
  [key in TemporaryFeatureFlagEnum]: boolean;
};
