import { ApplicationRouteInterface, PageTitleType } from '@surecloud/common';
import { AuthRoutesEnum } from './auth-routes.enum';
import { EnterTenantComponent } from './components/enter-tenant/enter-tenant.component';

export const authRoutes: ApplicationRouteInterface[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `${AuthRoutesEnum.EnterTenant}`,
  },
  {
    component: EnterTenantComponent,
    path: `${AuthRoutesEnum.EnterTenant}`,
    pathMatch: 'full',
    title: $localize`Login - Company Domain Name` as PageTitleType,
  },
];
