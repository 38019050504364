import { createAction, props } from '@ngrx/store';
import { RuleTriggerInterface } from '../../models/rule-trigger.models';

export const RuleTriggerActions = {
  /**
   * RuleTrigger Actions Related to the internals of RuleTrigger
   * [RuleTrigger]
   */
  selectRuleTrigger: createAction('[Rule Trigger] Select Rule Trigger', props<{ triggerId: string }>()),

  /**
   *RuleTrigger Actions Related to the  RuleTrigger API
   * [RuleTrigger/API]
   */
  readRuleTriggerList: createAction('[Rule Trigger/API] Read RuleTrigger List'),
  readRuleTriggerListSuccess: createAction(
    '[Rule Trigger/API] Read RuleTrigger List Success',
    props<{ ruleTriggerList: RuleTriggerInterface[] }>()
  ),
  readRuleTriggerListFailure: createAction(
    '[Rule Trigger/API] Read RuleTrigger List Failure',
    props<{ error: string }>()
  ),

  createNewRuleTriggerSuccess: createAction(
    '[Rule Trigger/API] CreateRuleTrigger Success',
    props<{ triggerId: string }>()
  ),
  createNewRuleTriggerFailure: createAction(
    '[Rule Trigger/API] Create Rule Trigger Failure',
    props<{ error: string }>()
  ),

  readRuleTrigger: createAction('[Rule Trigger/API] ReadRuleTrigger', props<{ triggerId: string }>()),
  readRuleTriggerSuccess: createAction(
    '[Rule Trigger/API] ReadRuleTrigger Success',
    props<{ ruleTrigger: RuleTriggerInterface }>()
  ),
  readRuleTriggerFailure: createAction('[Rule Trigger/API] Read Rule Trigger Failure', props<{ error: string }>()),

  updateRuleTrigger: createAction(
    '[Rule Trigger/API] Update RuleTrigger',
    props<{ ruleTrigger: RuleTriggerInterface }>()
  ),
  updateRuleTriggerSuccess: createAction(
    '[Rule Trigger/API] Update RuleTrigger Success',
    props<{ partialRuleTrigger: Partial<RuleTriggerInterface> }>()
  ),
  updateRuleTriggerFailure: createAction('[RuleTrigger/API] Update Rule Trigger Failure', props<{ error: string }>()),

  deleteRuleTrigger: createAction('[Rule Trigger/API] Delete Rule Trigger', props<{ triggerId: string }>()),
  deleteRuleTriggerSuccess: createAction(
    '[Rule Trigger/API] Delete RuleTrigger Success',
    props<{ triggerId: string; routeCommands: string[] | null }>()
  ),
  deleteRuleTriggerFailure: createAction('[Rule Trigger/API] Delete RuleTrigger Failure', props<{ error: string }>()),
};
