import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TaskSummaryInterface, trackByIndex } from '@surecloud/common';
import { TagComponent } from '@surecloud/design';
/**
 * Surecloud Tasks Summary Component.
 * This is a clone of the Design v1 component with new design changes.
 * @export
 * @class TaskSummaryDesignV2Component
 */
@Component({
  selector: 'design-v2-task-summary',
  standalone: true,
  styleUrls: ['./task-summary.component.scss'],
  templateUrl: './task-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TagComponent],
  encapsulation: ViewEncapsulation.None,
})
export class TaskSummaryDesignV2Component {
  /**
   * Task Summary to display.
   * @type {(TaskSummaryInterface | undefined)}
   * @memberof TaskSummaryDesignV2Component
   */
  @Input()
  task: TaskSummaryInterface | undefined;

  /**
   * When a user clicks on a task.
   * Then emit that clicked task.
   * @type {EventEmitter<TaskSummaryInterface>}
   * @memberof TaskSummaryDesignV2Component
   */
  @Output()
  selected: EventEmitter<TaskSummaryInterface> = new EventEmitter();

  /**
   * The track by function for ngFor loops in the view.
   * @memberof TaskSummaryDesignV2Component
   */
  trackByFunction = trackByIndex;

  /**
   * When a user clicks on a task.
   * Then emit that task as selected.
   * @param {TaskSummaryInterface} task The Task a user clicked on.
   * @memberof TaskSummaryDesignV2Component
   */
  select(task: TaskSummaryInterface): void {
    this.selected.emit(task);
  }
}
