<div
  *ngIf="{
    showWarning: showWarning$ | async
  } as $"
  class="enter-tenant d-flex flex-center-xy vh-100">
  <section class="card">
    <div class="mb-24">
      <img class="card__img" i18n-alt alt="Surecloud Logo" height="38" width="228" src="./assets/logos/logo-full.svg" />
    </div>
    <label class="d-block mb-24" for="tenant" i18n>Enter your company domain name on SureCloud.</label>
    <form [formGroup]="enterTenantForm" (ngSubmit)="onSubmit()">
      <div class="d-flex flex-center-y">
        <input
          class="enter-tenant__input mr-12"
          type="text"
          id="tenant"
          formControlName="tenant"
          scCommonE2eHook="enter-tenant:form:tenant" />
        <span>{{ urlSuffix }}</span>
      </div>
      <div class="mt-24 mb-24">
        <p *ngIf="$.showWarning" class="enter-tenant__warning" i18n>This domain does not exist.</p>
      </div>
      <button
        class="enter-tenant__btn"
        type="submit"
        [disabled]="!enterTenantForm.valid || $.showWarning"
        scCommonE2eHook="enter-tenant:form:submit"
        i18n>
        Continue
      </button>
    </form>
  </section>
</div>
