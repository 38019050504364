import { Pipe, PipeTransform } from '@angular/core';

/**
 * Truncate a string to a a certain length and add ellipsis.
 * @export
 * @class TruncatePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  /**
   * The ellipsis character to use. Can be customised.
   * @private
   * @memberof TruncatePipe
   */
  private ellipsis = '...';

  /**
   * The maximum number of characters to display before truncating.
   * @private
   * @memberof TruncatePipe
   */
  private maximum = 25;

  /**
   * Transform the input value to a truncated string with ellipsis
   * @param {string} value The string to truncate.
   * @param {number} [maximum] The maximum number of characters to display before truncating.
   * @param {boolean} [completeWords] Should the truncation be done at the end of a word.
   * @param {string} [ellipsis] The ellipsis characters to use.
   * @return {string} The truncated string.
   * @memberof TruncatePipe
   */
  public transform(value: string, maximum = this.maximum, completeWords = false, ellipsis = this.ellipsis): string {
    let limit = maximum;

    if (completeWords && value.length > maximum) {
      const lastSpace = value.substring(0, maximum).lastIndexOf(' ');
      limit = lastSpace > 0 ? lastSpace : limit;
    }

    return value.length > limit ? value.substring(0, limit) + ellipsis : value;
  }
}
