import {
  EntityAttributeTypeEnum,
  getTranslatedAttributeText,
  NAVIGATE_ROW_COLUMN,
  SCGridSchema,
  SCGridSchemaTypeEnum,
} from '@surecloud/common';
import { ColDef } from 'ag-grid-community';
import { isArrayOfUserAvatarGuard } from '../user-selects/is-user-avatar.guard';
import { UserAvatarType } from '../user-selects/user-avatar.model';
import {
  dateComparator,
  numberWithPrefixComparator,
  numericComparator,
  userAvatarComparator,
  userAvatarTextMatcher,
} from './grid-column-helpers';
import { GridNavigationCellComponent } from './navigation-cell/grid-navigation-cell.component';
import { GridAttributeTypeRendererComponent } from './renderers/attribute-type/grid-attribute-type-renderer.component';
import { GridCheckboxRendererComponent } from './renderers/checkbox/grid-checkbox-renderer.component';
import { GridDocumentRendererComponent } from './renderers/document/grid-document.component';
import { isArrayOfGridDocumentsGuard } from './renderers/document/is-grid-document.guard';
import { GridDropdownRendererComponent } from './renderers/dropdown/grid-dropdown-renderer.component';
import { GridIconRendererComponent } from './renderers/icon/grid-icon-renderer.component';
import { GridRelativeDateRendererComponent } from './renderers/relative-date/relative-date-renderer.component';
import { GridRowDragRendererComponent } from './renderers/row-drag/grid-row-drag-renderer.component';
import { GridTagsEditableRendererComponent } from './renderers/tags-editable/grid-tags-editable-renderer.component';
import { GridTagsReadonlyRendererComponent } from './renderers/tags-readonly/grid-tags-readonly-renderer.component';
import { isArrayOfTagsGuard } from './renderers/tags-readonly/is-grid-tags-readonly.guard';
import { GridUserAvatarRendererComponent } from './renderers/user-avatar/grid-user-avatar.component';

/**
 * ACTION_COLUMN_WIDTH - constant for defualt actions column width.
 */
export const ACTION_COLUMN_WIDTH = 40;

/**
 * CHECKBOX_COLUMN_WIDTH - constant for boolean column width.
 */
export const CHECKBOX_COLUMN_WIDTH = 150;

/**
 * ICON_COLUMN_WIDTH - constant for icon column width.
 */
export const ICON_COLUMN_WIDTH = 160;

/**
 * SMALL_COLUMN_WIDTH - constant for small column width.
 */
export const SMALL_COLUMN_WIDTH = 120;

/**
 * MEDIUM_COLUMN_WIDTH - constant for medium column width.
 */
export const MEDIUM_COLUMN_WIDTH = 180;

/**
 * LARGE_COLUMN_WIDTH - constant for medium column width.
 */
export const LARGE_COLUMN_WIDTH = 250;
/**
 * Apply this class via the Ag Grid cellClass property on a ColDef to disabled a cell within the grid.
 * If this class changes ensure to update the CSS class in basic-grid.component.scss
 */
export const GRID_CELL_DISABLED = 'sc-grid-cell--disabled';

export const AG_SELECT_CLASS = 'ag-select-editable';

/**
 * An AgGrid Config for Globally controlling the default column types of all grids.
 */
export const globalColumnTypes: Record<SCGridSchemaTypeEnum, ColDef> = {
  [SCGridSchemaTypeEnum.SingleSelect]: {
    editable: true,
    cellRenderer: GridDropdownRendererComponent,
    cellClass: 'ag-grid-dropdown',
    resizable: false,
  },
  [SCGridSchemaTypeEnum.Text]: {
    filter: 'agTextColumnFilter',
    minWidth: MEDIUM_COLUMN_WIDTH,
    cellClass: 'stringType', // for excel export
  },
  [SCGridSchemaTypeEnum.Boolean]: {
    filter: 'agTextColumnFilter',
    cellClass: 'booleanType', // for excel export
  },
  [SCGridSchemaTypeEnum.Number]: {
    filter: 'agNumberColumnFilter',
    comparator: numericComparator,
    cellClass: 'numberType', // for excel export
  },
  [SCGridSchemaTypeEnum.Sequence]: {
    filter: 'agTextColumnFilter',
    minWidth: MEDIUM_COLUMN_WIDTH,
    comparator: numberWithPrefixComparator,
  },
  [SCGridSchemaTypeEnum.Date]: {
    filter: 'agDateColumnFilter',
    cellClass: 'dateType', // for excel export
    filterParams: {
      comparator: dateComparator,
    },
  },
  [SCGridSchemaTypeEnum.Navigate]: {
    maxWidth: ACTION_COLUMN_WIDTH,
    cellRenderer: GridNavigationCellComponent,
    cellClass: 'flex justify-content-center align-items-center flex-row',
    field: NAVIGATE_ROW_COLUMN,
  },
  [SCGridSchemaTypeEnum.HeaderSelect]: {
    maxWidth: ACTION_COLUMN_WIDTH,
    cellClass: AG_SELECT_CLASS,
    resizable: false,
    cellRenderer: GridCheckboxRendererComponent,
    type: SCGridSchemaTypeEnum.HeaderSelect,
    sortable: false,
    suppressMenu: true,
  },
  [SCGridSchemaTypeEnum.Select]: {
    maxWidth: ACTION_COLUMN_WIDTH,
    cellClass: AG_SELECT_CLASS,
    resizable: false,
    cellRenderer: GridCheckboxRendererComponent,
    type: SCGridSchemaTypeEnum.Select,
    sortable: false,
    suppressMenu: true,
  },
  [SCGridSchemaTypeEnum.Checkbox]: {
    maxWidth: CHECKBOX_COLUMN_WIDTH,
    cellClass: AG_SELECT_CLASS,
    resizable: false,
    cellRenderer: GridCheckboxRendererComponent,
    type: SCGridSchemaTypeEnum.Checkbox,
    sortable: false,
    suppressMenu: true,
  },
  [SCGridSchemaTypeEnum.CheckboxEditable]: {
    cellClass: AG_SELECT_CLASS,
    maxWidth: ACTION_COLUMN_WIDTH,
    cellRenderer: GridCheckboxRendererComponent,
    type: SCGridSchemaTypeEnum.CheckboxEditable,
    sortable: false,
    suppressMenu: true,
  },
  [SCGridSchemaTypeEnum.AttributeType]: {
    maxWidth: ICON_COLUMN_WIDTH,
    resizable: false,
    cellRenderer: GridAttributeTypeRendererComponent,
    getQuickFilterText: (params) => {
      const attType = params.data.type as EntityAttributeTypeEnum;
      return getTranslatedAttributeText(attType);
    },
  },
  [SCGridSchemaTypeEnum.TagsEditable]: {
    cellRenderer: GridTagsEditableRendererComponent,
    getQuickFilterText: (params) =>
      params.data.tags.map((tag: { label: string; tagId: string }) => tag.label).join(' '),
  },
  [SCGridSchemaTypeEnum.TagsReadonly]: {
    minWidth: MEDIUM_COLUMN_WIDTH,
    cellRenderer: GridTagsReadonlyRendererComponent,
    autoHeight: true,
    getQuickFilterText: (params) => {
      const { value } = params;
      if (!value) return '';
      return isArrayOfTagsGuard(value) ? value.map(({ display }) => display).join(' ') : '';
    },
  },
  [SCGridSchemaTypeEnum.Icon]: {
    maxWidth: ICON_COLUMN_WIDTH,
    resizable: false,
    cellRenderer: GridIconRendererComponent,
    getQuickFilterText: (params) => {
      const field = params.colDef?.field;
      const data = field && params.data[field] ? params.data[field] : {};
      const { label } = data;
      return label || '';
    },
  },
  [SCGridSchemaTypeEnum.MultipleUserAvatar]: {
    cellRenderer: GridUserAvatarRendererComponent,
    minWidth: MEDIUM_COLUMN_WIDTH,
    autoHeight: true,
    comparator: userAvatarComparator,
    filter: 'agTextColumnFilter',
    filterParams: {
      textFormatter: (value: UserAvatarType) => value,
      textMatcher: userAvatarTextMatcher,
    },
    getQuickFilterText: (params) => {
      const { value } = params;
      return isArrayOfUserAvatarGuard(value)
        ? value.map(({ display, initials }) => `${display} ${initials}`).join(' ')
        : '';
    },
  },
  [SCGridSchemaTypeEnum.SingleUserAvatar]: {
    cellRenderer: GridUserAvatarRendererComponent,
    minWidth: MEDIUM_COLUMN_WIDTH,
    autoHeight: true,
    comparator: userAvatarComparator,
    filter: 'agTextColumnFilter',
    filterParams: {
      textFormatter: (value: UserAvatarType) => value,
      textMatcher: userAvatarTextMatcher,
    },
    getQuickFilterText: (params) => {
      const { value } = params;
      return isArrayOfUserAvatarGuard(value)
        ? value.map(({ display, initials }) => `${display} ${initials}`).join(' ')
        : '';
    },
  },
  [SCGridSchemaTypeEnum.RelativeDate]: {
    cellRenderer: GridRelativeDateRendererComponent,
    getQuickFilterText: (params) => params.value.label,
  },
  [SCGridSchemaTypeEnum.RowDrag]: {
    type: SCGridSchemaTypeEnum.RowDrag,
    cellRenderer: GridRowDragRendererComponent,
    maxWidth: ACTION_COLUMN_WIDTH,
    resizable: false,
    sortable: false,
    suppressMovable: true,
    suppressMenu: true,
  },
  [SCGridSchemaTypeEnum.Document]: {
    cellRenderer: GridDocumentRendererComponent,
    minWidth: MEDIUM_COLUMN_WIDTH,
    autoHeight: true,
    getQuickFilterText: (params) => {
      const { value } = params;

      return isArrayOfGridDocumentsGuard(value) ? value.map(({ display }) => `${display}`).join(' ') : '';
    },
  },
};

/**
 * Create a column definition from a grid schema.
 * @export
 * @param {SCGridSchema} schema The column definition schema.
 * @return {ColDef[]} Array of column definitions.
 */
export const makeColumnDefinitionsFromGridSchema = (schema: SCGridSchema): ColDef[] =>
  schema.keys.map((key) => {
    const { type, label, cellClass, editable, valueGetter, valueSetter } = schema.schemaMap[key];
    return {
      field: key,
      type,
      hide: false,
      headerName: label,
      cellClass,
      editable,
      valueGetter,
      valueSetter,
    };
  });
