import { Injectable } from '@angular/core';
import { EntityAttributeRuleConditionTypeEnum, EntityAttributeTypeEnum, UNTITLED } from '@surecloud/common';
import { Observable, map, of } from 'rxjs';
import { EntityStateFacade } from '../../+state/facades/entity-state.facade';
import {
  EntityAttributeRuleOptionInterface,
  EntityAttributeRuleOptionsInterface,
} from './rule-options.service.interface';

export const constructRuleOptions = (): EntityAttributeRuleOptionsInterface =>
  Object.values(EntityAttributeTypeEnum).reduce((ruleOptionMap, type) => {
    const mapClone: EntityAttributeRuleOptionsInterface = { ...ruleOptionMap };
    mapClone[type] = [];
    return mapClone;
  }, {} as EntityAttributeRuleOptionsInterface);

/**
 * Entity attribute rule options service.
 *
 * @export
 * @class EntityAttributeRuleOptionsService
 */
@Injectable({
  providedIn: 'root',
})
export class EntityAttributeRuleOptionsService {
  /**
   * Rule options.
   *
   * @private
   * @type {EntityAttributeRuleOptionsInterface}
   * @memberof EntityAttributeRuleOptionsService
   */
  private readonly ruleOptions: EntityAttributeRuleOptionsInterface = {
    ...constructRuleOptions(),
    [EntityAttributeTypeEnum.Number]: [
      {
        controls: [
          {
            key: 'linkedAttributeId',
            label: $localize`Please set the greater than attribute value for this attribute`,
            required: true,
            type: 'select',
            options: this.entityFacade.selectedNumberAttributes$.pipe(
              map((attributes) =>
                attributes.map((attribute) => ({
                  text: attribute.name || UNTITLED,
                  value: attribute.attributeId,
                }))
              )
            ),
          },
        ],
        name: $localize`Greater than attribute`,
        type: EntityAttributeRuleConditionTypeEnum.GreaterThan,
      },
      {
        controls: [
          {
            key: 'linkedAttributeId',
            label: $localize`Please set the less than attribute value for this attribute`,
            required: true,
            type: 'select',
            options: this.entityFacade.selectedNumberAttributes$.pipe(
              map((attributes) =>
                attributes.map((attribute) => ({
                  text: attribute.name || UNTITLED,
                  value: attribute.attributeId,
                }))
              )
            ),
          },
        ],
        name: $localize`Less than attribute`,
        type: EntityAttributeRuleConditionTypeEnum.LessThan,
      },
      {
        controls: [
          {
            key: 'limit',
            label: $localize`Please set the greater than limit for this attribute`,
            min: 1,
            required: true,
            type: 'number',
          },
        ],
        name: $localize`Greater than limit`,
        type: EntityAttributeRuleConditionTypeEnum.GreaterThan,
      },
      {
        controls: [
          {
            key: 'limit',
            label: $localize`Please set the less than limit for this attribute`,
            min: 1,
            required: true,
            type: 'number',
          },
        ],
        name: $localize`Less than limit`,
        type: EntityAttributeRuleConditionTypeEnum.LessThan,
      },
    ],
    [EntityAttributeTypeEnum.Text]: [
      {
        controls: [
          {
            key: 'limit',
            label: $localize`Please set the maximum character limit for this attribute`,
            min: 1,
            required: true,
            type: 'number',
          },
        ],
        name: $localize`Maximum character limit`,
        type: EntityAttributeRuleConditionTypeEnum.MaximumCharacterLimit,
      },
      {
        controls: [
          {
            key: 'limit',
            label: $localize`Please set the minimum character limit for this attribute`,
            min: 1,
            required: true,
            type: 'number',
          },
        ],
        name: $localize`Minimum character limit`,
        type: EntityAttributeRuleConditionTypeEnum.MinimumCharacterLimit,
      },
      {
        name: $localize`Unique - no other record has this value`,
        type: EntityAttributeRuleConditionTypeEnum.Unique,
      },
    ],
    [EntityAttributeTypeEnum.Url]: [],
    [EntityAttributeTypeEnum.Email]: [],
    [EntityAttributeTypeEnum.Document]: [],
    [EntityAttributeTypeEnum.Date]: [],
    [EntityAttributeTypeEnum.Sequence]: [],
    [EntityAttributeTypeEnum.Calculation]: [],
    [EntityAttributeTypeEnum.OptionList]: [],
    [EntityAttributeTypeEnum.Hierarchy]: [],
    [EntityAttributeTypeEnum.User]: [],
    [EntityAttributeTypeEnum.Toggle]: [],
    [EntityAttributeTypeEnum.State]: [],
  };

  /**
   * Get theentity attribute rule options for a specific entity attribute type.
   *
   * @param {EntityAttributeTypeEnum} type The type of attribute.
   * @return {Observable<EntityAttributeRuleOptionInterface[]>} Array of entity attribute validation rules for the attribute type.
   * @memberof EntityAttributeRuleOptionsService
   */
  getOptions(type: EntityAttributeTypeEnum | null): Observable<EntityAttributeRuleOptionInterface[]> {
    return of(type ? this.ruleOptions[type] : []);
  }

  /**
   * Creates an instance of EntityAttributeRuleOptionsService.
   *
   * @param {EntityStateFacade} entityFacade The entity feature facade service.
   * @memberof EntityAttributeRuleOptionsService
   */
  constructor(private readonly entityFacade: EntityStateFacade) {}
}
