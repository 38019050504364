import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { NoTypeComponent } from '../../no-type/no-type.component';
import { RendererNoTypeControlConfigInterface } from '../component-renderer-config.interface';

/**
 * Dynamically creates a NO TYPE component
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererNoTypeControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<NoTypeComponent>} component - Type of component to be dynamically created
 * @return {ComponentRef<NoTypeComponent> | undefined} - Dynamic Component
 */
export const noTypeComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererNoTypeControlConfigInterface | undefined,
  component: Type<NoTypeComponent>
): ComponentRef<NoTypeComponent> | undefined => {
  if (!componentData) return undefined;
  const componentRef = viewContainerRef.createComponent(component);
  componentRef.instance.label = componentData.label;
  componentRef.instance.placeholder = componentData.placeholder;
  return componentRef;
};
