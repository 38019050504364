import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CommonRoutesEnum, GuestAuthService, GuestTokenService, RecordViewRoutesEnum } from '@surecloud/common';
import { Observable, first, of, switchMap } from 'rxjs';
import { AuthRoutesEnum } from '../../auth-routes.enum';
import { AuthService } from '../../services/auth.service';

/**
 * Implements a route guard for the guest authentication.
 * @export
 * @class CanActivateGuestRecordViewGuard
 */
@Injectable({
  providedIn: 'root',
})
export class CanActivateGuestRecordViewGuard {
  /**
   * The URL tree to navigate to when the guest token is invalid.
   * @private
   * @memberof CanActivateGuestRecordViewGuard
   */
  private GUEST_ERROR_INVALID_TOKEN_URL_TREE = this.router.createUrlTree(['/', CommonRoutesEnum.ErrorRoot], {
    queryParams: {
      message: $localize`It looks like the guest user token is invalid. Please contact support if the problem persists.`,
    },
  });

  /**
   * Creates an instance of CanActivateGuestRecordViewGuard.
   * @param {AuthService} authService - auth service
   * @param {Router} router - angular router
   * @param {GuestAuthService} guestAuthService - guest auth service
   * @param {GuestTokenService} guestTokenService - guest token service
   * @memberof CanActivateGuestRecordViewGuard
   */
  constructor(
    private authService: AuthService,
    private router: Router,
    private guestAuthService: GuestAuthService,
    private guestTokenService: GuestTokenService
  ) {}

  /**
   * Implements canActivate to check that guest user token passed in the URL is valid.
   * redirects to auth page
   * @return {(Observable<boolean | UrlTree>)} Can a user view this record or URL of the login page.
   * @memberof CanActivateGuestRecordViewGuard
   */
  canActivate(): Observable<boolean | UrlTree> {
    /**
     *  ----------------------
     *   VALIDATE GUEST TOKEN
     *  ----------------------
     *  Parse and validate the guest token in the URL.
     */
    return this.guestAuthService.validateAndInitialise().pipe(
      switchMap(() => this.authService.isAuthenticated().pipe(first())),
      switchMap((isAuthenticated) => {
        /**
         *  -----------------------------
         *   AUTHENTICATED AS GUEST USER
         *  -----------------------------
         *  If we are here, the guest token is valid.
         *  Navigate to the given record.
         *  If the recordId is somehow not present, navigate to the error page.
         */
        if (isAuthenticated) {
          const recordId = this.guestTokenService.getAuthorisedRecordId();
          return recordId
            ? of(this.router.parseUrl(`/${RecordViewRoutesEnum.Root}/${recordId}`))
            : of(this.GUEST_ERROR_INVALID_TOKEN_URL_TREE);
        }
        /**
         *  ------------------------------
         *   GUEST LINK NOT AUTHENTICATED
         *  ------------------------------
         *  Send to the auth page.
         */
        return of(this.router.parseUrl(`/${AuthRoutesEnum.Root}`));
      })
    );
  }
}
