import { AdvancedGridComponent } from '../../grid/advanced-grid/advanced-grid.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { tableComponentCreator } from '../creators/table-creator.config';

/**
 * Control configuration for TABLE renderer component
 *
 */
export const TABLE_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: AdvancedGridComponent,
  creatorFn: tableComponentCreator,
};
