import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  QUESTION_SET_FEATURE_KEY,
  StateInterface,
  questionSetAdapter,
} from '../../reducers/question-set/question-set.reducer';

// Lookup the Question Set feature state managed by NgRx
export const getQuestionSetState = createFeatureSelector<StateInterface>(QUESTION_SET_FEATURE_KEY);

const { selectAll, selectEntities } = questionSetAdapter.getSelectors();

/**
 * Standard state selectors
 */
export const getQuestionSets = createSelector(getQuestionSetState, (state: StateInterface) => selectAll(state));
export const getQuestionSetEntities = createSelector(getQuestionSetState, (state: StateInterface) =>
  selectEntities(state)
);
export const getQuestionSetLoaded = createSelector(getQuestionSetState, (state: StateInterface) => state.loaded);
export const getSelectedQuestionSetId = createSelector(
  getQuestionSetState,
  (state: StateInterface) => state.selectedId
);
export const getSelectedQuestionSet = createSelector(
  getQuestionSetEntities,
  getSelectedQuestionSetId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
