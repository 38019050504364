import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Container component
 * @export
 * @class ContainerComponent
 */
@Component({
  selector: 'sc-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class ContainerComponent {
  /**
   * Class name for the container
   * @memberof ContainerComponent
   */
  @Input() className = '';
}
