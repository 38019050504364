/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { ErrorDetails } from '../../models/error/error.models';

/**
 * Placeholder for Logging.
 * @export
 * @class LoggerService
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  logEvent<T>(moduleName: string, eventName: string, data?: T, message = ''): void {
    console.log(`[${moduleName}]\n> Event: ${eventName}\n> Data: ${JSON.stringify(data)}\n> Message: ${message}`);
  }

  logError(error?: ErrorDetails): void {
    const errorHeadline = `
    ------------------------
      GlobalErrorHandler -> ${error?.message}
      Please send this error ID to support to investigate: ${error?.guid}
    ------------------------
    `;
    console.log(errorHeadline);
    console.log(error);
  }

  logConsoleError(message: string): void {
    console.error(message);
  }
}
