import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG_TOKEN, EnvironmentConfigInterface, LoggerService } from '@surecloud/common';
import { FeatureFlagContextInterface, UserProfileInterface } from '@surecloud/user-profile';
import { LDClient, LDContext, LDOptions, initialize } from 'launchdarkly-js-client-sdk';
import { Observable, catchError, from, map } from 'rxjs';
import { FeatureFlags } from '../+state/models/feature-flag.models';

export const LAUNCH_DARKLY_NO_CONTEXT = 'no-context';

/**
 * Service to manage feature flags and abstract the implementation of
 * the LaunchDarkly client SDK. ref: https://docs.launchdarkly.com/sdk/client-side/javascript
 * @export
 * @class FeatureFlagService
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  /**
   * The LaunchDarkly client SDK instance.
   * @private
   * @type {LDClient}
   * @memberof FeatureFlagService
   */
  private launchDarklyClientSDK!: LDClient;

  /**
   * Creates an instance of FeatureFlagService.
   * @param {EnvironmentConfigInterface} envConfig - The runtime environment configuration.
   * @param {LoggerService} logger - The common logger service.
   * @memberof FeatureFlagService
   */
  constructor(
    @Inject(ENV_CONFIG_TOKEN) private readonly envConfig: EnvironmentConfigInterface,
    private logger: LoggerService
  ) {}

  /**
   * Maps the FeatureFlagContexts provided from our API into LDContext to pass to LaunchDarkly.
   * @param {FeatureFlagContextInterface[]} featureFlagContexts - feature flag contexts
   * @return {LDContext} context to provide to LaunchDarkly
   */
  private static mapFeatureFlagContextsToLDContext(featureFlagContexts: FeatureFlagContextInterface[] = []): LDContext {
    const multiContext: LDContext = {
      kind: 'multi',
    };

    if (featureFlagContexts) {
      featureFlagContexts.forEach((context) => {
        const contextObject: { [key: string]: string } = {
          key: context.key,
        };

        context.attributes.forEach((attribute) => {
          contextObject[attribute.name] = attribute.value;
        });

        multiContext[context.kind] = contextObject;
      });
    }

    return multiContext;
  }

  /**
   * Initialise the LaunchDarkly client.
   * Requires the featureFlagContexts and secureModeHash from UserProfile.
   * To avoid initialization delays it is possible to use bootstrapping. Ref: https://docs.launchdarkly.com/sdk/features/bootstrapping#javascript
   * We've decided to not use this as it creates syncing problems with the flag data or requires a lot of server side setup to achieve.
   * @param {UserProfileInterface} userProfile - user profile
   * @return {Observable<FeatureFlags>} - observable of feature flags
   */
  init(userProfile: UserProfileInterface): Observable<FeatureFlags> {
    const { featureFlagSecureModeHash, featureFlagContexts } = userProfile;
    const hash = featureFlagSecureModeHash || LAUNCH_DARKLY_NO_CONTEXT;
    const ldContext = FeatureFlagService.mapFeatureFlagContextsToLDContext(featureFlagContexts);

    // The options object is used to configure the client.
    const options: LDOptions = { allAttributesPrivate: true, hash };

    // Get reference to the LaunchDarkly client SDK
    this.launchDarklyClientSDK = initialize(this.envConfig.launchDarkly.clientId, ldContext, options);

    // Call the identify method to associate the user with the client
    // this.launchDarklyClientSDK.identify(userContext, hash);

    // Return the observable of feature flags
    return from(this.launchDarklyClientSDK.waitUntilReady()).pipe(
      map(() => this.launchDarklyClientSDK.allFlags()),
      /**
       * The client emits the ready event only once, when it finishes initializing.
       * If you receive an error message about the ldclient.on('ready') callback not firing,
       * this means that the SDK began listening for the ready event too late, after the
       * client already emitted it. To fix this, move the ready listener to immediately after
       * you call initialize, or use a promise instead. To learn more, read Error
       * "ldclient.on('ready')" not firing.
       */
      catchError(() => {
        this.logger.logConsoleError('Error initializing LaunchDarkly client SDK');
        return [];
      })
    );
  }
}
