import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ScIconInterface } from '@surecloud/common';
import { ScSvgIcon } from './icon.class';
import { ScSvgIconModuleConfigInterface } from './icon.interface';
import { SVG_ICONS_CONFIG } from './icon.tokens';

/**
 * Service that creates SVG components
 * @export
 * @class ScIconRegistry
 */
@Injectable({ providedIn: 'root' })
export class IconRegistry {
  private readonly svgMap = new Map<string, ScSvgIcon>();
  private renderer: Renderer2;

  constructor(@Inject(SVG_ICONS_CONFIG) config: ScSvgIconModuleConfigInterface, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);

    const { icons, missingIconFallback } = config;

    if (icons) {
      this.register(icons);
    }

    if (missingIconFallback) {
      this.register(missingIconFallback);
    }
  }

  /**
   * Get's the icon data
   * @param {(string | undefined)} key icon name
   * @return {*}  {(string | undefined)}
   * @memberof IconRegistry
   */
  get(key: string | undefined): string | undefined {
    const icon = key && this.svgMap.get(key);

    if (!icon) {
      return undefined;
    }

    if (!icon.init) {
      const svg: SVGElement = this.toElement(icon.content);

      this.renderer.setAttribute(svg, 'fit', '');
      this.renderer.setAttribute(svg, 'height', '100%');
      this.renderer.setAttribute(svg, 'width', '100%');
      this.renderer.setAttribute(svg, 'preserveAspectRatio', 'xMidYMid meet');
      this.renderer.setAttribute(svg, 'focusable', 'false');

      icon.content = svg.outerHTML;
      icon.init = true;
    }

    return icon.content;
  }

  /**
   * Registers one or more icons in the service
   * @param {(ScIconInterface | ScIconInterface[])} icons list of icons to be register
   * @memberof IconRegistry
   */
  register(icons: ScIconInterface | ScIconInterface[]): void {
    const iconsArray = Array.isArray(icons) ? icons : [icons];

    // eslint-disable-next-line no-restricted-syntax
    for (const { name, data } of iconsArray) {
      this.svgMap.set(name, new ScSvgIcon(data));
    }
  }

  /**
   * Return a SVGElement with content
   * @private
   * @param {string} content icon data
   * @return {*}  {SVGElement}
   * @memberof IconRegistry
   */
  private toElement(content: string): SVGElement {
    const div: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.setProperty(div, 'innerHTML', content);

    return div.querySelector('svg') as SVGElement;
  }
}
