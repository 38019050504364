<div class="sc-single-link">
  <kendo-label [text]="label" class="sc-label flex-column flex-grow-1">
    <kendo-dropdownlist
      class="sc-single-link__dropdown k-input"
      rounded="none"
      size="none"
      textField="name"
      valueField="value"
      [data]="options"
      [formControl]="control"
      [ngClass]="{ 'sc-input--readonly': readonly && !control.disabled }"
      [popupSettings]="{ popupClass: 'hidden' }"
      [readonly]="readonly"
      [scCommonE2eHook]="testhook"
      [style.width.px]="staticWidth"
      [valuePrimitive]="true"
      (keydown.enter)="!readonly && clicked.emit()"
      (click)="!readonly && clicked.emit()">
      <ng-template kendoDropDownListValueTemplate let-option>
        <span class="sc-single-link__prefix-icon sc-mr-8">
          <sc-icon key="link-hug" size="x-large" colour="grey-600"></sc-icon>
        </span>
        {{ option?.name }}
      </ng-template>
    </kendo-dropdownlist>
    <div class="flex">
      <kendo-formhint *ngIf="description" class="description">{{ description }}</kendo-formhint>
    </div>
  </kendo-label>
  <div
    class="sc-single-link__icons flex"
    [ngClass]="{ 'sc-single-link__icons--labelled': !!label }"
    *ngIf="control.value">
    <sc-icon-button
      class="sc-ml-8"
      icon="open-up"
      themeColour="outline"
      shape="rounded"
      size="large"
      (click)="navigate.emit(control.value)">
    </sc-icon-button>
    <sc-delete-button
      class="sc-ml-8"
      size="large"
      [disabled]="control.disabled || readonly"
      (deleteClicked)="deleted.emit()">
    </sc-delete-button>
  </div>
</div>
