import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  EntityAttributeInterface,
  EntityAttributeUpdatedInterface,
  isCalculationAttributeUpdate,
} from '@surecloud/common';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';

export const ATTRIBUTE_FEATURE_KEY = 'attribute';

export interface StateInterface extends EntityState<EntityAttributeInterface> {
  selectedId?: string; // which attribute record has been selected
  loaded: boolean; // has the Entity list been loaded
  error?: string | null; // last known error (if any)
}

export interface AttributePartialStateInterface {
  readonly [ATTRIBUTE_FEATURE_KEY]: StateInterface;
}

export const attributeAdapter: EntityAdapter<EntityAttributeInterface> = createEntityAdapter<EntityAttributeInterface>({
  selectId: (attribute: EntityAttributeInterface) => attribute.attributeId,
});

export const initialState: StateInterface = attributeAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const getAttributeIdsForEntity = (entityId: string, attributes: EntityAttributeInterface[]): string[] =>
  attributes.reduce<string[]>((attributeIds, attribute) => {
    if (attribute && attribute.entityId === entityId) {
      attributeIds.push(attribute.attributeId);
    }
    return attributeIds;
  }, []);

const { selectAll } = attributeAdapter.getSelectors();

const attributeReducer = createReducer(
  initialState,
  on(EntityAttributeActions.readAttributeList, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(EntityAttributeActions.readAttributeListSuccess, (state: StateInterface, { attributeList }) =>
    attributeAdapter.addMany(attributeList, { ...state, loaded: true })
  ),
  on(
    EntityAttributeActions.selectAttribute,
    (state: StateInterface, { attributeId }: EntityAttributeUpdatedInterface) => ({
      ...state,
      selectedId: attributeId,
    })
  ),
  on(EntityAttributeActions.readAttributeListFailure, (state: StateInterface, { error }) => ({
    ...state,
    error,
  })),

  on(EntityAttributeActions.createAttributeSuccess, (state: StateInterface, { attribute }) =>
    attributeAdapter.setOne(attribute, state)
  ),
  on(
    EntityAttributeActions.updateAttributeFromEffect,
    EntityAttributeActions.updateAttributeFromPage,
    (state: StateInterface, { attribute }) =>
      attributeAdapter.updateOne({ id: attribute.attributeId, changes: attribute }, state)
  ),
  on(EntityAttributeActions.updateAttributeSuccess, (state: StateInterface, attributeUpdate) => {
    if (isCalculationAttributeUpdate(attributeUpdate)) {
      const { type, attributeId, ...changes } = attributeUpdate;
      return attributeAdapter.updateOne({ id: attributeUpdate.attributeId, changes }, state);
    }
    return state;
  }),
  on(EntityAttributeActions.deleteAttributeSuccess, (state: StateInterface, { attributeId }) =>
    attributeAdapter.removeOne(attributeId, state)
  ),
  // Delete all attributes for an entity when the entity is deleted.
  on(EntityActions.deleteEntitySuccess, (state: StateInterface, { entityId }) => {
    const attributeIds = getAttributeIdsForEntity(entityId, selectAll(state));
    return attributeAdapter.removeMany(attributeIds, state);
  }),
  on(EntityAttributeActions.reset, () => initialState)
);

/**
 *
 *Perform reducer logic on the entity NGRX state store for a specific attribute action.
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return attributeReducer(state, action);
}
