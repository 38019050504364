/**
 * Represents a SVG Icon content
 *
 * @export
 * @class ScSvgIcon
 */
export class ScSvgIcon {
  init = false;

  constructor(public content: string) {}
}
