import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationInterface } from '@surecloud/common';
import { GridApi } from 'ag-grid-community';
import { LoadingButtonStateInterface } from '../../../button/loading-button-state.interface';
import { AdvancedGridPaginationComponent } from '../advanced-grid-pagination/advanced-grid-pagination.component';
import { AdvancedGridButtonComponent, AdvancedGridButtonType } from '../button/advanced-grid-button.component';

/**
 * Surecloud advanced grid wrapper around sureCloud basic grid.
 *
 * Avoid using get & set in this file. (Too many issues using these).
 */
@Component({
  selector: 'sc-advanced-grid-footer',
  templateUrl: './advanced-grid-footer.component.html',
  styleUrls: ['./advanced-grid-footer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AdvancedGridButtonComponent, AdvancedGridPaginationComponent],
})
export class AdvancedGridFooterComponent {
  /**
   * Set this if we want to display the grid but remove functionality so it can be previewed.
   * @memberof AdvancedGridFooterComponent
   */
  @Input() readonly = false;

  /**
   * The name of the Primary Button
   * @memberof AdvancedGridFooterComponent
   */
  @Input() primaryButtonName = '';

  /**
   * The name of the Secondary Button
   * @memberof AdvancedGridFooterComponent
   */
  @Input() secondaryButtonName = '';
  /**
   * Show the Primary Button.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridFooterComponent
   */
  @Input() showPrimaryButton: boolean | null = true;

  /**
   * Show the Secondary Button
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof AdvancedGridFooterComponent
   */
  @Input() showSecondaryButton: boolean | null = false;

  /**
   * The type of the primary button.
   * @type {AdvancedGridButtonType}
   * @memberof AdvancedGridFooterComponent
   */
  @Input() primaryButtonType: AdvancedGridButtonType = 'text';

  /**
   * The type of the secondary button.
   * @type {AdvancedGridButtonType}
   * @memberof AdvancedGridFooterComponent
   */
  @Input() secondaryButtonType: AdvancedGridButtonType = 'text';

  /**
   * The state of the primary button if it's loading type, should be undefined otherwise
   * @type {LoadingButtonStateInterface}
   * @memberof AdvancedGridFooterComponent
   */
  @Input() primaryLoadingButtonState?: LoadingButtonStateInterface;

  /**
   * The state of the secondary button if it's loading type, should be undefined otherwise
   * @type {LoadingButtonStateInterface}
   * @memberof AdvancedGridFooterComponent
   */
  @Input() secondaryLoadingButtonState?: LoadingButtonStateInterface;

  /**
   * The pagination object to be passed to the pagination component
   * @type {(PaginationInterface | null)}
   * @memberof AdvancedGridFooterComponent
   */
  @Input() pagination: PaginationInterface | null = null;

  /**
   * Grid API to be passed to the footer component
   * @type {(GridApi)}
   * @memberof AdvancedGridFooterComponent
   */
  @Input() gridApi!: GridApi;

  /**
   * Handles the clicked from the primary button
   * @memberof AdvancedGridFooterComponent
   */
  @Output() primaryButtonClicked = new EventEmitter();

  /**
   * Handles the clicked from the secondary button
   * @memberof AdvancedGridFooterComponent
   */
  @Output() secondaryButtonClicked = new EventEmitter();

  /**
   * Handles the page size change event
   * @memberof AdvancedGridFooterComponent
   */
  @Output() pageSizeChange = new EventEmitter<number>();

  /**
   * Handles the go to first page button clicked
   * @memberof AdvancedGridFooterComponent
   */
  @Output() goToFirstPage = new EventEmitter<void>();

  /**
   * Handles the go to last page button clicked
   * @memberof AdvancedGridFooterComponent
   */
  @Output() goToLastPage = new EventEmitter<void>();

  /**
   * Handles the go to next page button clicked
   * @memberof AdvancedGridFooterComponent
   */
  @Output() goToNextPage = new EventEmitter<void>();

  /**
   * Handles the go to previous page button clicked
   * @memberof AdvancedGridFooterComponent
   */
  @Output() goToPreviousPage = new EventEmitter<void>();

  /**
   * Handles the go to specific page button clicked
   * @memberof AdvancedGridFooterComponent
   */
  @Output() goToPageNumber = new EventEmitter<number>();
}
