<h2 class="sc-font-heading-xl sc-mb-4 sc-text-brand-500" *ngIf="heading">{{ heading }}</h2>
<ng-container *ngIf="navigationItems && navigationItems.length; else error">
  <ol>
    <li *ngFor="let navigationItem of navigationItems; trackBy: trackByNavigationItem">
      <button
        type="button"
        class="sc-font-table-entry sc-text block sc-pt-8 sc-pb-8"
        [ngClass]="{
          'sc-text-brand-500': activeNavigationItemId === navigationItem.id,
          'sc-bold': activeNavigationItemId === navigationItem.id,
          'cursor-auto': activeNavigationItemId === navigationItem.id,
          'cursor-pointer': activeNavigationItemId !== navigationItem.id
        }"
        (click)="setActiveNavigationItem(navigationItem)">
        {{ navigationItem.label }}
      </button>
    </li>
  </ol>
</ng-container>
<ng-template #error>
  <p *ngIf="emptyMessage">{{ emptyMessage }}</p>
</ng-template>
