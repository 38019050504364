// Define default options and styles for charts.

import { isChartPoint } from './chart.component.model';

// Constants for common styles we want to use.
const common = {
  textDarkPrimary: {
    color: 'var(--brand-500)',
  },
  heading2: {
    fontWeight: '700',
    fontSize: '18px',
  },
  heading4: {
    fontWeight: '700',
    fontSize: '13px',
  },
  width: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 100,
  },
};

export const defaultChartConfig: Highcharts.Options = {
  colors: ['var(--orange-600)', 'var(--red-300)', 'var(--green-300)', 'var(--blue-500)'],
  credits: {
    enabled: false,
  },
  chart: {
    style: {
      fontFamily: 'Nunito Sans',
    },
  },
  title: {
    margin: 55,
    style: {
      ...common.heading2,
      ...common.textDarkPrimary,
    },
  },
  subtitle: {
    style: {
      ...common.heading4,
      ...common.textDarkPrimary,
    },
  },
  yAxis: {
    title: {
      x: -12, // Moves text to the left.
      style: {
        color: 'var(--brand-300)',
        ...common.heading4,
      },
    },
    labels: {
      style: {
        ...common.textDarkPrimary,
        fontWeight: '700',
        fontSize: '15px',
        ...common.width,
      },
      useHTML: true,
    },
  },
  xAxis: {
    title: {
      style: {
        color: 'var(--brand-300)',
        ...common.heading4,
      },
    },
    labels: {
      style: {
        ...common.heading4,
        ...common.textDarkPrimary,
        ...common.width,
      },
    },
  },
  legend: {
    itemStyle: {
      ...common.heading4,
      ...common.textDarkPrimary,
    },
  },
  plotOptions: {
    heatmap: {
      // This configuration is used to display the value of each point in a heatmap chart.
      dataLabels: {
        className: 'sc-chart__point-wrapper--heatmap',
        formatter() {
          const { point } = this;

          return isChartPoint(point) ? `<span class="sc-chart__point-label--heatmap">${point.value}</span>` : null;
        },
        style: {
          fontSize: '13px',
          fontWeight: '600',
          textOutline: '0',
        },
        useHTML: true,
      },
    },

    // Pie chart options
    pie: {
      dataLabels: {
        format: '<b>{point.name}</b>: {point.percentage:.1f}%',
        style: {
          ...common.textDarkPrimary,
          fontSize: '13px',
          fontWeight: '600',
          textOutline: '0',
        },
      },
    },
  },
};
