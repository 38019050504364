import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import { makeAttributeConfigSectionId } from '../global-attribute-config.model';

/**
 * Makes the global Name attribute configuration
 */
export const GLOBAL_NAME_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Name,
  key: EntityAttributeTypeEnum.Name,
  type: EntityAttributeTypeEnum.Name,
  label: $localize`Name`,
  uniqueSections: [
    {
      sectionComponent: 'EntityAttributeNamePropertiesComponent',
      sectionId: makeAttributeConfigSectionId('name-props'),
      sectionName: $localize`Additional Properties`,
    },
  ],
});
