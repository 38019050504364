import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { SidebarLayoutOverlayComponentService } from '@surecloud/design';
import { NgScrollbarModule } from 'ngx-scrollbar';

/**
 * Surecloud Sidebar Overlay Component.
 * Right hand (for now) sidebar overlay.
 * This is a clone of the Design v1 component with new design changes.
 * @export
 * @class SidebarLayoutOverlayDesignV2Component
 */
@Component({
  selector: 'design-v2-sidebar-layout-overlay',
  standalone: true,
  templateUrl: './sidebar-layout-overlay.component.html',
  styleUrls: ['./sidebar-layout-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgScrollbarModule],
})
export class SidebarLayoutOverlayDesignV2Component {
  /**
   * The SideBar Overlay view child.
   * We need this to check if a user clicks outside of the Sidebar Overlay Component to close it.
   * @type {ElementRef | undefined}
   * @memberof SidebarLayoutOverlayDesignV2Component
   */
  @ViewChild('sidebar', { read: ElementRef })
  public sidebar: ElementRef | undefined;

  /**
   * When a user clicks outside of the Sidebar Overlay Component.
   * Then close the Sidebar Overlay Component.
   * @param {PointerEvent} event The mouse click event.
   * @memberof SidebarLayoutOverlayDesignV2Component
   */
  @HostListener('document:click', ['$event'])
  public click(event: PointerEvent): void {
    if (!this.sidebar?.nativeElement.contains(event.target)) {
      this.overlay.update(false);

      // Exhausted all efforts to try and not manually run change detection with no dice - you're welcome to roll
      this.changes.detectChanges();
    }
  }

  /**
   * When a user clicks the escape key while the Sidebar Overlay Component is open.
   * Then close the Sidebar Overlay Component.
   * @param {KeyboardEvent} event The keyboard event.
   * @memberof SidebarLayoutOverlayDesignV2Component
   */
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.overlay.update(false);

      // Exhausted all efforts to try and not manually run change detection with no dice - you're welcome to roll
      this.changes.detectChanges();
    }
  }

  /**
   * Creates an instance of SidebarLayoutOverlayDesignV2Component.
   * @param {ChangeDetectorRef} changes The Angular Change Detection Reference.
   * @param {SidebarLayoutOverlayComponentService} overlay The SideBar Overlay Component Service.
   * @memberof SidebarLayoutOverlayDesignV2Component
   */
  constructor(private changes: ChangeDetectorRef, public readonly overlay: SidebarLayoutOverlayComponentService) {}
}
