import { Injectable } from '@angular/core';
import { LoggerService, RecordInterface } from '@surecloud/common';
import { TableRecordLinkInterface } from './get-link.records.interface';
import { getNormalisedRecordLinksError, isRecordLinksGuard } from './normalise-get-link-records.validation';

/**
 * Service encapsulating the schema and configuration for flattening the
 * getLinkedRecords API response.
 *
 * @export
 * @class NormaliseGetLinkedRecordsService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetLinkedRecordsService {
  /**
   * Creates an instance of NormaliseGetLinkedRecordsService.
   *
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetLinkedRecordsService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Normalise the API record data.
   *
   * @param {string} tabId The tab ID.
   * @param {RecordInterface[]} linkRecordsList Array of linked Records.
   * @param {boolean} isLinked Is the record data linked.
   * @param {boolean} viewerCanCreate checks if the user can create the record.
   * @return {TableRecordLinkInterface} The Record Link.
   * @memberof NormaliseGetLinkedRecordsService
   */
  flatten(tabId: string, linkRecordsList: RecordInterface[], isLinked: boolean, viewerCanCreate: boolean): TableRecordLinkInterface {
    const linkType = isLinked ? 'Linked' : 'Linkable';

    const result: TableRecordLinkInterface = {
      link: {
        tabId,
        isLinked,
        recordIds: linkRecordsList.map((record) => record.recordId),
        viewerCanCreate,
      },
      tableRecords: linkRecordsList.map((record) => ({
        ...record,
        parentId: `${tabId}.${isLinked ? 'linked' : 'linkable'}.${record.recordId}`,
        attributes: record.attributes,
      })),
    };

    if (!isRecordLinksGuard(result)) {
      this.logger.logEvent(
        'Record Feature',
        `Normalise Record ${linkType} List Data`,
        getNormalisedRecordLinksError(result)
      );

      throw new Error(`The result of the normalised Record ${linkType} List does not match the expected format.`);
    }
    return result;
  }
}
