import { z } from 'zod';

/**
 * Defines the structure of the current environment config
 * as a zod schema to be used for runtime checking.
 * @export
 * @interface z.ZodObject
 */
export const environmentConfigZodSchema = z.object({
  auth0: z.object({
    clientId: z.string(),
    domain: z.string(),
    redirectUri: z.string().optional(),
    audience: z.string(),
  }),
  launchDarkly: z.object({
    clientId: z.string(),
  }),
});

/**
 * Defines the structure of the current environment config
 * this should be amended as things are added/removed from
 * the runtime config file.
 * @export
 * @interface EnvironmentConfigInterface
 */
export type EnvironmentConfigInterface = z.infer<typeof environmentConfigZodSchema>;
