/**
 * Entity attributes format types.
 *
 * @export
 * @enum {number}
 */
export enum EntityAttributeFormatTypesEnum {
  Currency = 'CURRENCY',
  DecimalNumber = 'DECIMAL_NUMBER',
  Percentage = 'PERCENTAGE',
  WholeNumber = 'WHOLE_NUMBER',
}
