import { Injectable } from '@angular/core';
import { HomepageRecentRecordApiInterface } from '@surecloud/api-types';
import { LoggerService } from '@surecloud/common';
import {
  NormalisedRecentRecords,
  getNormalisedRecentRecordsError,
  isNormalisedRecentRecordsGuard,
} from './normalise-get-recent-records.validation';

/**
 * Service encapsulating the schema and configuration for flattening the get Recent Records API response.
 *
 * @export
 * @class NormaliseGetRecentRecordsService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetRecentRecordsService {
  /**
   * Creates an instance of NormaliseGetRecentRecordsService
   *
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetRecentRecordsService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the recentRecords data from the Recent Records API response.
   *
   * @param {HomepageRecentRecordApiInterface[]} recentRecords The Recent Records data structure as it comes from the API.
   * @return {NormalisedRecentRecords} UI Recent Records data structure.
   * @memberof NormaliseGetRecentRecordsService
   */
  extract(recentRecords: HomepageRecentRecordApiInterface[]): NormalisedRecentRecords {
    const tasks = { recentRecords };
    if (!isNormalisedRecentRecordsGuard(tasks)) {
      this.logger.logEvent(
        'Recent Records Feature',
        'Normalise Recent Records Data',
        getNormalisedRecentRecordsError(recentRecords)
      );

      throw new Error('The result of the normalise Recent Records does not match the expected format.');
    }

    return tasks;
  }
}
