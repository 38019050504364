import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Dashed border container component
 * @export
 * @class DashedBorderContainerComponent
 */
@Component({
  selector: 'sc-dashed-border-container',
  templateUrl: './dashed-border-container.component.html',
  styleUrls: ['./dashed-border-container.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class DashedBorderContainerComponent {
  /**
   * Class name for the dashed border container
   * @memberof DashedBorderContainerComponent
   */
  @Input() className = '';
}
