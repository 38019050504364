import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LogicItemComponent } from './logic-item.component';
import { LogicItemInterface } from './logic-item.component.interface';

/**
 * Surecloud LogicBlockComponent Component.
 *
 * @export
 * @class LogicBlockComponent
 */
@Component({
  selector: 'sc-logic-block',
  standalone: true,
  templateUrl: './logic-block.component.html',
  styleUrls: ['./logic-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, LogicItemComponent],
})
export class LogicBlockComponent {
  /**
   * The route we want to go to when clicking on the whole logic block.
   *
   * @type {string[]}
   * @memberof LogicBlockComponent
   */
  @Input()
  blockRouteCommands?: string[];

  /**
   * The logic blocks that we want to display.
   *
   * @type {LogicItemInterface[]}
   * @memberof LogicBlockComponent
   */
  @Input()
  logicBlocks!: LogicItemInterface[];

  /**
   * Which order to display the logic block tags and text in.
   *
   * @type {('start' | 'end')}
   * @memberof LogicBlockComponent
   */
  @Input()
  direction: 'start' | 'end' = 'start';

  /**
   * The logic block we want to start editing.
   *
   * @memberof LogicBlockComponent
   */
  @Output() startEdit = new EventEmitter<string>();

  trackByIndex = LogicBlockComponent.trackByIndex;

  /**
   * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
   * The function takes the iteration index and in this case Angular will track changes with the index.
   *
   * @static
   * @param {number} index // position of the item.
   * @return {*}  {number}
   * @memberof LogicBlockComponent
   */
  static trackByIndex(index: number): number {
    return index;
  }
}
