<section
  *ngIf="overlay.stream$ | async"
  class="sidebar-overlay sc-bg-white sc-pt-16 sc-pr-2 sc-pl-24 flex flex-column"
  #sidebar>
  <div class="sidebar-overlay__title sc-pr-24">
    <ng-content select="[sidebar-overlay-title]"></ng-content>
  </div>
  <div class="sc-pb-16 flex-grow-1">
    <ng-scrollbar>
      <div class="sc-pr-24">
        <ng-content select="[sidebar-overlay-content]"></ng-content>
      </div>
    </ng-scrollbar>
  </div>
</section>
