<ul *ngIf="clonedItems.length" [dragula]="dragulaRef" [id]="id" [(dragulaModel)]="clonedItems">
  <li *ngFor="let item of clonedItems; trackBy: trackByFunction; let last = last">
    <a
      [routerLink]="item.routeCommands"
      sc-text-button
      size="large"
      themeColour="primary"
      fillMode="outline"
      i18n
      class="w-full"
      [ngClass]="last ? 'sc-mb-24' : 'sc-mb-12'">
      <sc-icon key="waffle" size="x-large" colour="grey-1000" class="absolute left-0 sc-ml-8"></sc-icon>
      <p>{{ item.title }}</p>
    </a>
  </li>
</ul>
