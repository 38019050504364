import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Abstract service for a simple Behaviour Subject stream.
 * Good to use for a simple service that Components can use to display.
 * @export
 * @abstract
 * @class BehaviorSubjectService
 * @template T The interface of data that will be passed to the Behavior Subject stream.
 */
export abstract class BehaviorSubjectService<T> {
  /**
   * Private subject for stream.
   * @private
   * @type {BehaviorSubject<T>}
   * @memberof BehaviorSubjectService
   */
  private streamSubject$: BehaviorSubject<T>;

  /**
   * Public stream that cannot be updated directly.
   * @memberof BehaviorSubjectService
   */
  stream$: Observable<T>;

  /**
   * Update the next value of the Behavior Subject stream.
   * @param {T} value The data that will be the next value of the Behavior Subject stream.
   * @memberof BehaviorSubjectService
   */
  update(value: T): void {
    this.streamSubject$.next(value);
  }

  /**
   * When the instance of BehaviorSubjectService is created.
   * Then setup the initial Behavior Subject stream with a value.
   * @param {T} value The initial value of the Behavior Subject stream.
   * @memberof BehaviorSubjectService
   */
  constructor(protected value: T) {
    this.streamSubject$ = new BehaviorSubject(value);
    this.stream$ = this.streamSubject$.asObservable();
  }
}
