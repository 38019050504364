import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropdownComponentOptionInterface, trackByIndex } from '@surecloud/common';
import { Subject, takeUntil } from 'rxjs';
import { IconButtonComponent } from '../../../button/icon-button.component';
import { SelectDropdownComponent } from '../../../select-dropdown/select-dropdown.component';

@Component({
  selector: 'sc-advanced-grid-pagination',
  templateUrl: './advanced-grid-pagination.component.html',
  styleUrls: ['./advanced-grid-pagination.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SelectDropdownComponent, IconButtonComponent],
  providers: [FormGroupDirective],
})
export class AdvancedGridPaginationComponent implements OnChanges, OnDestroy {
  /**
   * Making track by index available to the template.
   * @memberof AdvancedGridPaginationComponent
   */
  trackByIndex = trackByIndex;

  /**
   * The page sizes to be displayed in the dropdown.
   * @private
   * @memberof AdvancedGridPaginationComponent
   */
  private readonly pageSizes = [10, 20, 50, 100];

  /**
   * The current page number.
   * @memberof AdvancedGridPaginationComponent
   */
  @Input() currentPage = 0;

  /**
   * The page size.
   * @memberof AdvancedGridPaginationComponent
   */
  @Input() pageSize = 0;

  /**
   * The total number of items.
   * @memberof AdvancedGridPaginationComponent
   */
  @Input() totalItems = 0;

  /**
   * Emits when the page size changes.
   * @memberof AdvancedGridPaginationComponent
   */
  @Output() pageSizeChange = new EventEmitter<number>();

  /**
   * Emits when the user navigates to the first page.
   * @memberof AdvancedGridPaginationComponent
   */
  @Output() goToFirstPage = new EventEmitter<void>();

  /**
   * Emits when the user navigates to the last page.
   * @memberof AdvancedGridPaginationComponent
   */
  @Output() goToLastPage = new EventEmitter<void>();

  /**
   * Emits when the user navigates to the next page.
   * @memberof AdvancedGridPaginationComponent
   */
  @Output() goToNextPage = new EventEmitter<void>();

  /**
   * Emits when the user navigates to the previous page.
   * @memberof AdvancedGridPaginationComponent
   */
  @Output() goToPreviousPage = new EventEmitter<void>();

  /**
   * Emits when the user navigates to a specific page.
   * @memberof AdvancedGridPaginationComponent
   */
  @Output() goToPageNumber = new EventEmitter<number>();

  /**
   * The page size form group.
   * @memberof AdvancedGridPaginationComponent
   */
  pageSizeFormGroup = new FormGroup({
    pageSize: new FormControl<number | null>(null),
  });

  /**
   * The page size options to be displayed in the dropdown in text value format
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof AdvancedGridPaginationComponent
   */
  pageSizeOptions: SelectDropdownComponentOptionInterface[] = this.pageSizes.map((size) => ({
    text: `${size}`,
    value: size,
  }));

  /**
   * The end index of the current page.
   * @memberof AdvancedGridPaginationComponent
   */
  endIndex = 0;

  /**
   * The page numbers to be displayed.
   * @type {((number | string)[])}
   * @memberof AdvancedGridPaginationComponent
   */
  pageNumbers: (number | string)[] = [];

  /**
   * The destroy subject to unsubscribe from observables.
   * @private
   * @memberof AdvancedGridPaginationComponent
   */
  private destroy$ = new Subject<void>();

  /**
   * Creates an instance of AdvancedGridPaginationComponent.
   * and subscribes to the page size changes.
   * @memberof AdvancedGridPaginationComponent
   */
  constructor() {
    this.pageSizeFormGroup.controls.pageSize.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.pageSizeChange.emit(value);
      }
    });
  }

  /**
   * On changes lifecycle hook.
   * @param {SimpleChanges} changes The changes object.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentPage']) {
      // PaginationInputInterface pageNumber is 0 based, so we need to add 1 to currentPage
      // to make it 1 based and match the page numbers displayed to the user.
      this.currentPage = changes['currentPage'].currentValue + 1;
    }
    if (changes['currentPage'] || changes['pageSize'] || changes['totalItems']) {
      this.pageSizeFormGroup.controls.pageSize.setValue(this.pageSize, { emitEvent: false });
      this.endIndex = Math.min(this.currentPage * this.pageSize, this.totalItems);
      this.pageNumbers = this.getPageNumbers();
    }
  }

  /**
   * On destroy lifecycle hook.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Gets the pagination info
   * @readonly
   * @type {string}
   * @memberof AdvancedGridPaginationComponent
   */
  get paginationInfo(): string {
    const currentItems = this.totalItems > 0 ? this.currentPage * this.pageSize - this.pageSize + 1 : 0;
    return $localize`${currentItems} - ${this.endIndex} of ${this.totalItems} records`;
  }

  /**
   * Handles the go to page event
   * @param {number | string } page selected page number or '...' for the ellipsis clicked
   * @param {number} index used to determine if the first or last ellipsis are clicked
   */
  goToPage(page: number | string, index: number): void {
    if (typeof page === 'number' && page !== this.currentPage) {
      this.goToPageNumber.emit(page);
    } else if (index === 0) {
      // Check if the first ... are clicked
      this.goToFirstPage.emit();
    } else {
      // Or the last ... are clicked
      this.goToLastPage.emit();
    }
  }

  /**
   * Gets the page numbers to be displayed.
   * Empty strings are used to represent the space used by ellipsis.
   * @return {((number | string)[])} The page numbers to be displayed.
   */
  private getPageNumbers(): (number | string)[] {
    const totalPages = Math.ceil(this.totalItems / this.pageSize);

    if (totalPages <= 3) {
      const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      return ['', ...pages, ''];
    }

    if (this.currentPage === 1 || this.currentPage === 2) {
      return ['', 1, 2, 3, '...'];
    }
    if (this.currentPage === totalPages) {
      return ['...', totalPages - 2, totalPages - 1, totalPages, ''];
    }

    return ['...', this.currentPage - 1, this.currentPage, this.currentPage + 1, '...'];
  }
}
