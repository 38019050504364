import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * PageComponent - for pages without sidebars or additional menus, where content should
 * be centered.
 * @export
 * @class PageComponent
 */
@Component({
  selector: 'sc-page',
  templateUrl: './page.component.html',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {
  /**
   * The maximum width of the page.
   * @type {'medium' | 'large'}
   * @default 'medium'
   * @memberof PageComponent
   */
  @Input() maxWidth: 'medium' | 'large' = 'medium';

  /**
   * Attach classes to the host element.
   * @memberof PageComponent
   */
  @HostBinding('className') hostClasses = 'flex justify-content-center sc-p-40';
}
