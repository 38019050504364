/**
 * Entity attribute type enum definition
 *
 * @export
 * @enum {string}
 */
export enum EntityAttributeTypeEnum {
  Text = 'TEXT',
  Number = 'NUMBER',
  Url = 'URL',
  Sequence = 'SEQUENCE',
  Email = 'EMAIL',
  Toggle = 'TOGGLE',
  Date = 'DATE',
  Calculation = 'CALCULATION',
  User = 'USER',
  Document = 'DOCUMENT',
  OptionList = 'OPTION_LIST',
  Hierarchy = 'HIERARCHY',
  State = 'STATE',
  Owner = 'OWNER',
  Context = 'CONTEXT',
  Name = 'NAME',
}

/**
 *
 * Public method to check if item is an attribute type
 *
 * @export
 * @param {unknown} likeEntityAttributeType
 * @return {*}  {likeEntityAttributeType is EntityAttributeTypeEnum}
 */
export const isEntityAttributeType = (
  likeEntityAttributeType: unknown
): likeEntityAttributeType is EntityAttributeTypeEnum => {
  const values = Object.values(EntityAttributeTypeEnum);
  return values.indexOf(likeEntityAttributeType as EntityAttributeTypeEnum) > -1;
};
