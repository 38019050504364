import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EntityPermissionInterface } from '../models/permission/permission-entity/permission-entity.models';

/**
 * Entity Permission API actions map.
 */
const ENTITY_PERMISSION_API_EVENTS_MAP = {
  'Read Entity Permission List': emptyProps(),
  'Read Entity Permission List Success': props<{ permissions: EntityPermissionInterface[] }>(),
  'Read Entity Permission Failure': props<{ error: string }>(),
  'Create Entity Permission Success': props<{ entityPermission: EntityPermissionInterface }>(),
  'Create Entity Permission Failure': props<{ error: string }>(),
  'Update Entity Permission Success': props<{ entityPermission: EntityPermissionInterface }>(),
  'Update Entity Permission Failure': props<{ error: string }>(),
  'Delete Entity Permission': props<{ entityPermission: EntityPermissionInterface }>(),
  'Delete Entity Permission Success': props<{ entityPermissionId: string }>(),
  'Delete Entity Permission Failure': props<{ error: string }>(),
};

/**
 * Entity Permission API actions.
 */
export const EntityPermissionApiActions = createActionGroup({
  source: 'Entity Permission/API',
  events: ENTITY_PERMISSION_API_EVENTS_MAP,
});

/**
 * Entity Properties Page actions.
 */
export const EntityPropertiesPageActions = createActionGroup({
  source: 'Entity Properties Page',
  events: {
    'Create Entity Permission': emptyProps(),
    'Update Entity Permission': props<{ entityPermission: EntityPermissionInterface }>(),
    'Start Delete Entity Permission': props<{ entityPermission: EntityPermissionInterface }>(),
  },
});

/**
 * Entity Permission actions.
 */
export const EntityPermissionActions = {
  ...EntityPermissionApiActions,
  ...EntityPropertiesPageActions,
};
