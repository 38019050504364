import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import { filter, Observable } from 'rxjs';
import { TaskInterface } from '../models/task/task.models';
import * as TaskSelectors from '../selectors/task/task.selectors';

/**
 * The facade for the Task feature sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 * @export
 * @class TaskFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class TaskFeatureFacade extends BaseFacade {
  /**
   * The Tasks as an array of TaskSummaryInterface.
   * @memberof TaskFeatureFacade
   */
  taskSummaries$ = this.store.pipe(select(TaskSelectors.getTaskSummaries));

  /**
   * Task totals summary.
   * @memberof TaskFeatureFacade
   */
  taskTotals$ = this.store.pipe(select(TaskSelectors.getTaskTotals));

  /**
   * How many new tasks are there.
   * @memberof TaskFeatureFacade
   */
  totalNewTasks$ = this.store.pipe(select(TaskSelectors.getTotalNewTasks));

  /**
   * Creates an instance of TaskFeatureFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof TaskFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }

  /**
   * Get a Task by Task ID.
   * @param {string} taskId The Task ID to get the task by.
   * @return {Observable<TaskInterface>} Observable stream of the Task.
   * @memberof TaskFeatureFacade
   */
  getTaskById$ = (taskId: string): Observable<TaskInterface> =>
    this.store.pipe(
      select(TaskSelectors.getTaskById(taskId)),
      filter((task): task is TaskInterface => !!task)
    );
}
