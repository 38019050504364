import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import { makeAttributeConfigComponent, makeAttributeConfigSectionId } from '../global-attribute-config.model';

/**
 * Makes the global Sequence attribute configuration
 */
export const GLOBAL_SEQUENCE_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Sequence,
  key: EntityAttributeTypeEnum.Sequence,
  type: EntityAttributeTypeEnum.Sequence,
  label: $localize`Sequence`,
  uniqueSections: [
    {
      sectionComponent: makeAttributeConfigComponent('Sequence'),
      sectionId: makeAttributeConfigSectionId('sequence'),
      sectionName: $localize`Sequence Properties`,
    },
  ],
});
