import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRIORITY_TASKS_FEATURE_KEY, StateInterface } from '../../reducers/priority-tasks/priority-tasks.reducer';

// Lookup the PriorityTasks feature state managed by NgRx
export const getPriorityTasksState = createFeatureSelector<StateInterface>(PRIORITY_TASKS_FEATURE_KEY);


export const getPriorityTasksLoaded = createSelector(getPriorityTasksState, (state: StateInterface) => state.loaded);

/**
 * Get the loaded priority tasks.
 */
export const getPriorityTasks = createSelector(getPriorityTasksState, (state: StateInterface) => state.priorityTasks ? { ...state.priorityTasks } : undefined );

/**
 * Get all the tasks
 */
export const getTasks = createSelector(getPriorityTasks, (priorityTasks) => priorityTasks?.tasks);

/**
 * Get the number of incomplete tasks
 */
export const getTotalIncomplete = createSelector(getPriorityTasks, (priorityTasks) => priorityTasks?.totalIncomplete);

/**
 * Get the tasks in the grid relative date format
 */
export const getTasksForGrid = createSelector(getTasks, (tasks) => tasks?.map((task) => ({ summary: task.summary, taskId: task.taskId, recordId: task.recordId, due: { label: task.due, urgency: task.urgency, dueDate: task.dueDate } })));
