import { SelectDropdownComponentOptionInterface } from '../../../interfaces/select-dropdown.component.interface';

/**
 * Entity attribute user mode type enum definition
 * @export
 * @enum {string}
 */
export enum EntityAttributeUserModeTypeEnum {
  Groups = 'GROUPS',
  Users = 'USERS',
  UsersGroups = 'USERS_GROUPS',
}

/**
 *Entity attribute calculation trend direction type enum definition
 * @export
 * @enum {number}
 */
export enum EntityAttributeCalculationTrendDirectionEnum {
  Increasing = 'INCREASING',
  Decreasing = 'DECREASING',
  Stable = 'STABLE',
}

/**
 *Entity attribute calculation direction type enum definition
 * @export
 * @enum {number}
 */
export enum EntityAttributeCalculationDirectionEnum {
  Increased = 'INCREASED',
  Decreased = 'DECREASED',
  Stable = 'STABLE',
}

/**
 *Entity attribute text box size type enum definition
 * @export
 * @enum {number}
 */
export enum TextboxSizeEnum {
  Small = 'SMALL',
  Large = 'LARGE',
}
/**
 * Dropdown options for the textbox size form control.
 */
export const TEXTBOX_SIZE_OPTIONS: SelectDropdownComponentOptionInterface[] = (
  Object.keys(TextboxSizeEnum) as Array<keyof typeof TextboxSizeEnum>
).map((value) => ({
  text: $localize`${value}`,
  value: TextboxSizeEnum[value],
}));
