import { createAction, props } from '@ngrx/store';
import { TableRecordInterface } from '../models/table-record/table-record.model';

export const TableRecordActions = {
  // Update Record
  updateTableRecord: createAction(
    '[Record View Table Page] Update Table Record',
    props<{ record: TableRecordInterface; attributeId: string; value: string; columnIndex: number }>()
  ),
  updateTableRecordSuccess: createAction(
    '[Record/API] Update Table Record Success',
    props<{ record: TableRecordInterface }>()
  ),
  updateTableRecordsSuccess: createAction(
    '[Record/API] Update Table Records Success',
    props<{ records: TableRecordInterface[]; tabId: string }>()
  ),
  updateTableRecordFailure: createAction('[Record/API] Update Table Records Failed', props<{ error: string }>()),
  addLinkedTableRecord: createAction(
    '[Record View Table Page] Add Linked Table Record',
    props<{ tableRecord: TableRecordInterface }>()
  ),
  addLinkableTableRecord: createAction(
    '[Record View Table Page] Add Linkable Table Record',
    props<{ tableRecord: TableRecordInterface }>()
  ),
  removeLinkedTableRecord: createAction(
    '[Record View Table Page] Remove Linked Table Record',
    props<{ tableRecord: TableRecordInterface }>()
  ),
  removeLinkableTableRecord: createAction(
    '[Record View Table Page] Remove Linkable Table Record',
    props<{ tableRecord: TableRecordInterface }>()
  ),
};
