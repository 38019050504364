<ng-container [formGroup]="parentFormGroup">
  <sc-input-text
    [controlName]="controlName"
    [description]="description"
    [label]="label"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [enableSuccessValidationStyle]="enableSuccessValidationStyle"
    prefix="email"
    [clearButton]="true"
    [marginBottom]="marginBottom"
    [staticWidth]="staticWidth"
    (blurred)="blurred.emit()"></sc-input-text>
</ng-container>

