import { Injectable } from '@angular/core';
import {
  RoleApiInterface,
  RoleCreatePayloadApiInterface,
  RoleDeleteInputApiInterface,
  RoleDeletePayloadApiInterface,
  RoleUpdateInputApiInterface,
  RoleUpdatePayloadApiInterface,
} from '@surecloud/api-types';
import { RoleInterface, makeRole } from '@surecloud/common';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import {
  apolloRoleCreateMutation,
  apolloRoleDeleteMutation,
  apolloRoleUpdateMutation,
} from '../../mutations/role/role.mutations';
import { apolloRoleQuery, apolloRolesQuery } from '../../queries/role.queries';
import { NormaliseGetRolesService } from '../normalise-get-roles/normalise-get-roles.service';
import { NormalisedRoleList } from '../normalise-get-roles/normalise-get-roles.validations';

export const ROLE_READ_ALL_ERROR = 'No response data from get roles returned from API.';
export const ROLE_READ_ERROR = 'No response data from get role returned from API.';
export const ROLE_CREATE_ERROR = 'No response data from create role returned from API.';
export const ROLE_DELETE_ERROR = 'No response data from delete role returned from API.';
export const ROLE_UPDATE_ERROR = 'No response data from update role returned from API.';

/**
 * Role API service.
 * @export
 * @class RoleService
 */
@Injectable({
  providedIn: 'root',
})
export class RoleService {
  /**
   * Creates an instance of RoleService
   * @param {Apollo} apollo The Apollo service.
   * @param {NormaliseGetRolesService} normaliseGetRolesService The normalise roles data service.
   * @memberof RoleService
   */
  constructor(private readonly apollo: Apollo, private readonly normaliseGetRolesService: NormaliseGetRolesService) {}

  /**
   * Query to get all roles.
   * @return {Observable<NormalisedRoleList>} The roles normalised.
   * @memberof RoleService
   */
  readAll(): Observable<NormalisedRoleList> {
    return this.apollo
      .query<{ roles: RoleApiInterface[] }>(apolloRolesQuery())
      .pipe(
        switchMap(({ data }) =>
          data?.roles ? of(this.normaliseGetRolesService.flatten(data.roles)) : throwError(() => ROLE_READ_ALL_ERROR)
        )
      );
  }

  /**
   * Query to get a role.
   * @param {string} roleId The role ID.
   * @return {Observable<NormalisedRoleList>} The role normalised.
   * @memberof RoleService
   */
  read(roleId: string): Observable<NormalisedRoleList> {
    return this.apollo
      .query<{ role: RoleApiInterface }>(apolloRoleQuery(roleId))
      .pipe(
        switchMap(({ data }) =>
          data?.role ? of(this.normaliseGetRolesService.flatten([data.role])) : throwError(() => ROLE_READ_ERROR)
        )
      );
  }

  /**
   * Create a new role.
   * @param {string | null} requestId The requestId.
   * @return {Observable<{roleId: string}>} The create role ID.
   * @memberof RoleService
   */
  create(requestId: string | null = null): Observable<RoleInterface> {
    return this.apollo
      .mutate<{ roleCreate: RoleCreatePayloadApiInterface }>(apolloRoleCreateMutation(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.roleCreate?.createdId ? of(makeRole(data.roleCreate.createdId)) : throwError(() => ROLE_CREATE_ERROR)
        )
      );
  }

  /**
   * Delete a role.
   * @param {string} roleId The ID of the role to delete.
   * @return {Observable<{roleId: string}>} The create role ID.
   * @memberof RoleService
   */
  delete(roleId: string): Observable<{ roleId: string }> {
    const roleDeleteInput: RoleDeleteInputApiInterface = {
      roleId,
    };

    return this.apollo
      .mutate<{ roleDelete: RoleDeletePayloadApiInterface }>(apolloRoleDeleteMutation(roleDeleteInput))
      .pipe(
        switchMap(({ data }) =>
          data?.roleDelete?.deletedId ? of({ roleId: data.roleDelete.deletedId }) : throwError(() => ROLE_DELETE_ERROR)
        )
      );
  }

  /**
   * Update a role.
   * @param {RoleInterface} role The role details
   * @param {string | null} requestId The requestId.
   * @return {Observable<RoleInterface>} The updated role.
   * @memberof RoleService
   */
  update(role: RoleInterface, requestId: string | null = null): Observable<RoleInterface> {
    const roleUpdateInput: RoleUpdateInputApiInterface = {
      ...role,
    };

    return this.apollo
      .mutate<{ roleUpdate: RoleUpdatePayloadApiInterface }>(apolloRoleUpdateMutation(roleUpdateInput, requestId))
      .pipe(
        switchMap(({ data }) => (data?.roleUpdate.role?.roleId ? of({ ...role }) : throwError(() => ROLE_UPDATE_ERROR)))
      );
  }
}
