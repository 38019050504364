import { EntityAttributeRuleConditionTypeEnum, EntityAttributeTypeEnum } from '@surecloud/common';

/**
 *
 */
export interface GlobalConditionOptionInterface {
  label: string;
  conditionType: EntityAttributeRuleConditionTypeEnum;
  valueType: string;
}

/**
 * Temporal Union enum for Period
 *
 * @export
 * @enum {number}
 */
export enum TemporalUnitEnum {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS',
}

/**
 * Entity Attribute Type And exclude options interface
 *
 * @export
 * @interface EntityAttributeTypeAndOptionsInterface
 */
export interface EntityAttributeTypeAndOptionsInterface {
  type: EntityAttributeTypeEnum | null;
  excludeOptions: EntityAttributeRuleConditionTypeEnum[];
}

/**
 *  Entity Attribute type to Condition list length
 */
export const EntityAttributeTypeToConditionListLengthMap: Record<string, number> = {
  TEXT: 3,
  NUMBER: 2,
  DATE: 2,
};
