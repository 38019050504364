import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { E2eHookDirective, HierarchyNodeInterface } from '@surecloud/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HierarchyComponent } from '../hierarchy/hierarchy.component';
import { DropDownHierarchyInterface } from './dropdown-hierarchy.interface';

/**
 * Surecloud Dropdown Hierarchy Component.
 * @export
 * @implements {OnInit}
 * @class DropdownHierarchyComponent
 */
@Component({
  selector: 'sc-dropdown-hierarchy',
  standalone: true,
  styleUrls: ['./dropdown-hierarchy.component.scss'],
  templateUrl: './dropdown-hierarchy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    LabelModule,
    ReactiveFormsModule,
    HierarchyComponent,
    PopupModule,
    DropDownsModule,
    NgScrollbarModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownHierarchyComponent implements OnInit, OnChanges {
  /**
   * The native dropdown items for the kendo-dropdownlist.
   * We hide this list to display our own popup list that contains our hierarchy component.
   * But we do need this list to add items to it, as it relies on them to display the selected item when a form value is set.
   * @type {DropDownHierarchyInterface[]}
   * @memberof DropdownHierarchyComponent
   */
  public dropdownItems: DropDownHierarchyInterface[] = [];

  /**
   * Controls the visibility of the popup/dropdown menu.
   * @memberof DropdownHierarchyComponent
   */
  public dropdownVisibility = false;

  /**
   * Class to add to the hosting element.
   * @memberof DropdownHierarchyComponent
   */
  @HostBinding('class') componentClass = 'sc-w-inherit';

  /**
   * The reference to the anchor element that opens the popup/dropdown menu.
   * @type {ElementRef}
   * @memberof DropdownHierarchyComponent
   */
  @ViewChild('anchor', { read: ElementRef })
  public anchor!: ElementRef;

  /**
   * The reference to the popup element.
   * @type {ElementRef}
   * @memberof DropdownHierarchyComponent
   */
  @ViewChild('popup', { read: ElementRef })
  public popup!: ElementRef;

  /**
   * The parent form group of the select dropdown.
   * @type {FormGroup}
   * @memberof DropdownHierarchyComponent
   */
  @Input()
  parentFormGroup?: FormGroup;

  /**
   *
   *Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof DropdownHierarchyComponent
   */
  @Input() controlName = '';

  /**
   * The current top level node.
   * @type {HierarchyNodeInterface}
   * @memberof DropdownHierarchyComponent
   */
  @Input()
  topLevelNode!: HierarchyNodeInterface;

  /**
   * Optional hint description to display.
   * @type {(string | undefined)}
   * @memberof DropdownHierarchyComponent
   */
  @Input()
  description: string | undefined;

  /**
   * The current node.
   * When this changes, mark the selected node if default/starting value has been provided.
   * As a default value can be set even if the node is on any level within the hierarchy.
   * @type {HierarchyNodeInterface}
   * @memberof DropdownHierarchyComponent
   */
  node!: HierarchyNodeInterface;
  get currentNode(): HierarchyNodeInterface {
    return this.node;
  }
  @Input() set currentNode(newNode: HierarchyNodeInterface) {
    this.node = newNode;
    const matchedNode = newNode?.nodes?.find((node) => node.id === this.defaultValue?.id);
    if (matchedNode) {
      matchedNode.selected = true;
    }
  }

  /**
   * The label text for the select dropdown.
   * @memberof DropdownHierarchyComponent
   */
  @Input() label = '';

  /**
   * The starting default value if there is one.
   * @type {DropDownHierarchyInterface}
   * @memberof DropdownHierarchyComponent
   */
  @Input() defaultValue?: DropDownHierarchyInterface;

  /**
   * The event triggered when we want to get and set a new current node.
   * @memberof DropdownHierarchyComponent
   */
  @Output() requestNewCurrentNode = new EventEmitter<string>();

  /**
   * Emit an event when the popup dropdown menu is destroyed so the toplevel data can be reset.
   * @memberof DropdownHierarchyComponent
   */
  @Output() dropdownMenuDestroyed = new EventEmitter();

  /**
   * The path taken through the hierarchy nodes to get to the selected value.
   * The path is represented as an array of node IDs.
   * @type {EventEmitter<string[]>}
   * @memberof DropdownHierarchyComponent
   */
  @Output() hierarchyPath: EventEmitter<string[]> = new EventEmitter();

  /**
   * Show/Hide the dropdown.
   * @memberof DropdownHierarchyComponent
   */
  public toggleDropdown(): void {
    if (!this.parentFormGroup?.controls[this.controlName].disabled) {
      this.dropdownVisibility = !this.dropdownVisibility;
    }
  }

  /**
   * When we make a selection in the hierarchy component.
   * Add the selected node to the hidden dropdown items so it can be displayed when the form value is set.
   * Then set the form value.
   * @param {DropDownHierarchyInterface} selectedNode - The node that has been selected.
   * @param { string } selectedNode.id The ID of the selected node.
   * @param { string } selectedNode.name The name of the selected node.
   * @memberof DropdownHierarchyComponent
   */
  public selected({ id, name }: DropDownHierarchyInterface): void {
    this.dropdownItems = [{ id, name }];
    this.parentFormGroup?.controls[this.controlName]?.setValue(id);
    this.dropdownVisibility = false;
  }

  /**
   * If a click is detected outside of this component then close the popup/dropdown menu.
   * @param {PointerEvent} event The click event.
   * @memberof DropdownHierarchyComponent
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: PointerEvent): void {
    if (!this.anchor.nativeElement.contains(event.target) && !this.popup?.nativeElement?.contains(event.target)) {
      this.dropdownVisibility = false;
    }
  }

  /**
   * If the Escape key is pressed then close the popup/dropdown menu.
   * @param {KeyboardEvent} event The keyboard event.
   * @memberof DropdownHierarchyComponent
   */
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.dropdownVisibility = false;
    }
  }

  /**
   * If the parent has set a new value to the form control.
   * Then update the selected node within the nodes and at set it as the hidden dropdown items.
   * So that we can display the selected value.
   * @private
   * @memberof DropdownHierarchyComponent
   */

  private setDefaultValue(): void {
    const formValue = this.parentFormGroup?.controls[this.controlName]?.value;

    if (this.defaultValue && formValue === this.defaultValue.id) {
      this.dropdownItems = [this.defaultValue];
    }
  }

  /**
   * Creates an instance of DropdownHierarchyComponent.
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @memberof DropdownHierarchyComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Runs when the component is started.
   * Sets the parent formgroup on the component.
   * If a value has been set on the form control and the default value is provided then set it.
   * @memberof DropdownHierarchyComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }

  /**
   * When the component has input changes and those changes update the default value.
   * Then set the default value and ask for the options downdown to be reset.
   * @param {SimpleChanges} changes The compone input changes.
   * @memberof DropdownHierarchyComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultValue']) {
      this.setDefaultValue();
      this.dropdownMenuDestroyed.emit();
    }
  }
}
