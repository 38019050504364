import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from 'highcharts';
import { TableRecordInterface } from '../models/table-record/table-record.model';
import { StateInterface, TABLE_RECORD_FEATURE_KEY, tableRecordAdapter } from '../reducers/table-record.reducer';

// Lookup the 'Table Record' feature state managed by NgRx
export const getRecordState = createFeatureSelector<StateInterface>(TABLE_RECORD_FEATURE_KEY);

const { selectAll, selectEntities } = tableRecordAdapter.getSelectors();

/**
 * Get all records.
 */
export const getTableRecords = createSelector(getRecordState, (state: StateInterface) => selectAll(state));

/**
 * Get the Record entity map from component state.
 */
export const getTableRecordEntities = createSelector(getRecordState, (state: StateInterface) => selectEntities(state));

export const getTableRecord = (
  id: string
): MemoizedSelector<
  object,
  TableRecordInterface | undefined,
  (s1: Dictionary<TableRecordInterface>) => TableRecordInterface | undefined
> => createSelector(getTableRecordEntities, (entities) => entities[id]);
