import { ChangeDetectionStrategy, Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { InputTextComponent } from '../../input-text/input-text.component';
/**
 *Grid Text Cell Editor component used to handle edits in the ag-grid
 *
 * @export
 * @class GridTextCellEditorComponent
 * @implements {ICellEditorAngularComp}
 */
@Component({
  selector: 'sc-grid-text-cell-editor',
  template: `<form class="sc-line-height-0" [formGroup]="gridCellTextForm">
    <sc-input-text #inputTextComponentRef controlName="value"></sc-input-text>
  </form>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputTextComponent, ReactiveFormsModule],
  encapsulation: ViewEncapsulation.None,
})
export class GridTextCellEditorComponent implements ICellEditorAngularComp {
  /**
   *The parameters used for editing the cell
   * Have to define this as any here, ICellEditorParams<any,any>
   *
   * @private
   * @type {*}
   * @memberof GridTextCellEditorComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private params: any;

  /**
   *
   *
   * @memberof GridTextCellEditorComponent
   */
  gridCellTextForm = this.fb.group({
    value: this.fb.control(''),
  });

  /**
   *The Angular ViewContainerRef helper
   *
   * @type {ViewContainerRef} A view container instance
   * @memberof GridTextCellEditorComponent
   */
  @ViewChild('inputTextComponentRef', { read: ViewContainerRef })
  public inputTextComponentRef!: ViewContainerRef;

  /**
   * Creates an instance of GridTextCellEditorComponent.
   *
   * @param {FormBuilder} fb Instance of the form builder the component it placed in
   * @memberof GridTextCellEditorComponent
   */
  constructor(private fb: FormBuilder) {}

  /**
   *Params for rendering this component.
   *
   * @param {ICellEditorParams} params cell params passed in when in edit mode
   * @memberof GridTextCellEditorComponent
   */
  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.setInitialState(this.params);
  }

  afterGuiAttached(): void {
    this.inputTextComponentRef.element.nativeElement.focus();
    const htmlInputEl: HTMLInputElement = this.inputTextComponentRef.element.nativeElement.querySelector('input');
    if (!htmlInputEl) return;
    htmlInputEl.focus();
  }

  /**
   *Return the final value - called by the grid once after editing is complete
   *
   * @return {*}  {(string | null)}
   * @memberof GridTextCellEditorComponent
   */
  getValue(): string | null {
    return this.gridCellTextForm.controls.value.value;
  }

  /**
   *Gets called once after initialised. If you return true, the editor will appear in a popup,
   so is not constrained to the boundaries of the cell. This is great if you want to, for example,
   provide you own custom dropdown list for selection. Default is false (ie if you don't provide the method).
   *
   * @return {false}  {boolean} default to false
   * @memberof GridTextCellEditorComponent
   */
  // eslint-disable-next-line class-methods-use-this
  isPopup(): boolean {
    return false;
  }

  /**
   *Gets called once after initialised. If you return true, the editor will not be used and the grid will
   continue editing. Use this to make a decision on editing inside the init() function, eg maybe you want
   to only start editing if the user hits a numeric key, but not a letter, if the editor is for numbers.
   *
   * @return {false}  {boolean}
   * @memberof GridTextCellEditorComponent
   */
  // eslint-disable-next-line class-methods-use-this
  isCancelBeforeStart?(): boolean {
    return false;
  }

  /**
   * Gets called once after editing is complete. If your return true, then the new value will not be used.
   * The editing will have no impact on the record. Use this if you do not want a new value from your gui,
   * i.e. you want to cancel the editing.
   *
   * @return {false}  {boolean}
   * @memberof GridTextCellEditorComponent
   */
  // eslint-disable-next-line class-methods-use-this
  isCancelAfterEnd?(): boolean {
    return false;
  }

  /**
   *Sets the value of the cell in edit
   *
   * @private
   * @param {ICellEditorParams} params the new value of the control
   * @memberof GridTextCellEditorComponent
   */
  private setInitialState(params: ICellEditorParams): void {
    const { value } = params;
    this.gridCellTextForm.controls.value.setValue(value, { emitEvent: false });
  }
}
