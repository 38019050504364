import { ATTRIBUTE_FORM_FIELDS } from '@surecloud/common';

/**
 * makes a attribute config section
 * @param {string} name of section
 * @return {string} - section name
 */
export const makeAttributeConfigSectionId = (name: string): string => `attribute-${name}`;

/**
 * Makes a attribute component
 * @param {string} name of component
 * @return {string} - component name
 */
export const makeAttributeConfigComponent = (name: string): string => `EntityAttribute${name}PropertiesComponent`;

/**
 * Constant for the common validation section name.
 */
export const GLOBAL_ATTRIBUTE_CONFIG_VALIDATION_SECTION_COMPONENT_NAME = 'EntityAttributeDataValidationComponent';

/**
 * Constant for the common user-filter section name.
 */
export const GLOBAL_ATTRIBUTE_CONFIG_USER_FILTER_SECTION_COMPONENT_NAME = 'EntityAttributeUserFilterComponent';

/**
 * Common validation section for text, number & date attributes
 */
export const GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION = {
  sectionComponent: GLOBAL_ATTRIBUTE_CONFIG_VALIDATION_SECTION_COMPONENT_NAME,
  sectionId: ATTRIBUTE_FORM_FIELDS.SECTION_IDS.DATA_VALIDATION,
  sectionName: ATTRIBUTE_FORM_FIELDS.SECTIONS.DATA_VALIDATION_SECTION_NAME,
};

/**
 * Common validation section for text, number & date attributes
 */
export const GLOBAL_ATTRIBUTE_COMMON_USER_FILTER_SECTION = {
  sectionComponent: GLOBAL_ATTRIBUTE_CONFIG_USER_FILTER_SECTION_COMPONENT_NAME,
  sectionId: ATTRIBUTE_FORM_FIELDS.SECTION_IDS.USER_FILTER,
  sectionName: ATTRIBUTE_FORM_FIELDS.SECTIONS.USER_FILTER_SECTION_NAME,
};
