import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { E2eHookDirective, SelectDropdownComponentOptionInterface } from '@surecloud/common';
import { InputNumberComponent } from '../input-number/input-number.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

/**
 * Component with a Input number and a select dropdown component
 * @export
 * @class InputNumberAndDropdownsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-input-number-and-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './input-number-and-dropdowns.component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    E2eHookDirective,
    SelectDropdownComponent,
    InputNumberComponent,
  ],
})
export class InputNumberAndDropdownsComponent implements OnInit {
  /**
   * Sets the name of the input number control, this needs to match the form control name on the parent form group.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() inputControlName = '';

  /**
   * Sets the name of the select dropdown control, this needs to match the form control name on the parent form group.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() dropdownControlName = '';

  /**
   * Text to display an additional description to the user in the input number.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() inputDescription = '';

  /**
   * Text to display an additional description to the user in the dropdown.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() dropdownDescription = '';

  /**
   * Sets the text value for the input label.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() inputLabel = '';

  /**
   * Sets the text value for the dropdown.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() dropdownLabel = '';

  /**
   * Sets the placholder value for the input.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() placeholder = '';

  /**
   * Sets the readonly value for the input.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() readonly = false;

  /**
   * Sets the option list for the dropodown
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() options: SelectDropdownComponentOptionInterface[] = [];

  /**
   * Sets the name of the second select dropdown control,
   * this needs to match the form control name on the parent form group.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() secondDropdownControlName = '';

  /**
   * Sets the option list for the second dropodown
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() secondDropdownOptions: SelectDropdownComponentOptionInterface[] = [];

  /**
   * Sets the text value for the second dropdown.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() secondDropdownLabel = '';

  /**
   * Text to display an additional description to the user in the second dropdown.
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input() secondDropdownDescription = '';

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   * @type {FormGroup}
   * @memberof InputNumberAndDropdownsComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Creates an instance of InputTextComponent.
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof InputNumberAndDropdownsComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
