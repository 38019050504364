import { NamedToggleComponent } from '../../named-toggle/named-toggle.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for TOGGLE renderer component
 *
 */
export const TOGGLE_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: NamedToggleComponent,
  creatorFn: formControlComponentCreator,
};
