import { Injectable } from '@angular/core';
import { UserProfileApiInterface } from '@surecloud/api-types';
import { LoggerService } from '@surecloud/common';
import {
  NormalisedUserProfile,
  getNormalisedUserProfileError,
  isNormalisedUserProfileGuard,
} from './normalise-get-user-profile.validation';
/**
 * Service to normalise and validate the get User Profile API response.
 * @export
 * @class NormaliseGetUserProfileService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetUserProfileService {
  /**
   * Creates an instance of NormaliseGetUserProfileService
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetUserService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the User Profile data from the User Profile API response.
   * @param {UserProfileApiInterface} userProfile The User Profile data structure as it comes from the API.
   * @return {NormalisedUserProfile} normalised User Profile object
   * @memberof NormaliseGetUserProfileService
   */
  extract(userProfile: UserProfileApiInterface): NormalisedUserProfile {
    if (!isNormalisedUserProfileGuard(userProfile)) {
      this.logger.logEvent(
        'User ProfileFeature',
        'Normalise User Profile Data',
        getNormalisedUserProfileError(userProfile)
      );

      throw new Error('The result of the normalise User Profile does not match the expected format.');
    }

    return userProfile;
  }
}
