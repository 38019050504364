import { SelectDropdownComponent } from '../../../select-dropdown/select-dropdown.component';
import { validationDropdownControlComponentCreator } from '../creators/dropdown-control-creator.config';
import { ValidationRendererControlConfigInterface } from '../validation-component-renderer-control-config.interface';

/**
 * Control configuration for DROPDOWN renderer component
 *
 */
export const DROPDOWN_CONTROL_CONFIG: ValidationRendererControlConfigInterface = {
  component: SelectDropdownComponent,
  creatorFn: validationDropdownControlComponentCreator,
};

