/**
 * Interface for the 'Logic Condition' data.
 * @property {string} rootId The root ID that the logic condition is attached to.
 * @property {string} parentId The parent ID of the logic condition.
 * @property {string[] | null} attributes List of attribute ids.
 * @property {LogicConditionComparatorEnum | null} comparator logic condition comparator.
 * @property {string} conditionId The ID of the logic condition.
 * @property {ConditionTypeEnum | null} condition condition.
 * @export
 * @interface LogicConditionInterface
 */
export interface LogicConditionInterface {
  rootId: string;
  parentId: string;
  attributes: string[] | null;
  comparator: LogicConditionComparatorEnum | null;
  conditionId: string;
  condition: ConditionTypeEnum | null;
}

/**
 * @property {LogicConditionTypeEnum | null} type logic condition type.
 * @export
 * @interface BasicLogicConditionInterface
 * @extends {LogicConditionInterface}
 */
export interface BasicLogicConditionInterface extends LogicConditionInterface {
  type: LogicConditionTypeEnum.BASIC;
}

/**
 * @property {LogicConditionTypeEnum | null} type logic condition type.
 * @property {LogicValueInterface} value logic condition value.
 * @export
 * @interface ValueLogicConditionInterface
 * @extends {LogicConditionInterface}
 */
export interface ValueLogicConditionInterface extends LogicConditionInterface {
  type: LogicConditionTypeEnum.VALUE;
  value?: LogicValueInterface | null;
}

/**
 * @property {LogicConditionTypeEnum | null} type logic condition type.
 * @property {number} unit string number of days/weeks/months in DATE type
 * @property {DateLogicConditionDurationEnum} duration the duration comparator. It can be days/weeks/months in DATE type
 * @export
 * @interface DateLogicConditionInterface
 * @extends {LogicConditionInterface}
 */
export interface DateLogicConditionInterface extends LogicConditionInterface {
  type: LogicConditionTypeEnum.DATE;
  unit?: number | null;
  duration?: DateLogicConditionDurationEnum | null;
}

/**
 * @property {LogicConditionTypeEnum | null} type logic condition type.
 * @property {string[]} questionSetIds list of question set ids
 * @export
 * @interface QuestionSetLogicConditionInterface
 * @extends {LogicConditionInterface}
 */
export interface QuestionSetLogicConditionInterface extends LogicConditionInterface {
  type: LogicConditionTypeEnum.QUESTION_SET;
  questionSetIds?: string[] | null;
}

/**
 * A combining union for all logic condition types
 * @export
 * @type {LogicConditionType}
 */
export type LogicConditionType =
  | BasicLogicConditionInterface
  | ValueLogicConditionInterface
  | DateLogicConditionInterface
  | QuestionSetLogicConditionInterface;

/**
 * Logic Condition Comparator ENUM
 * @export
 * @enum {string}
 */
export enum LogicConditionComparatorEnum {
  CONTAINS = 'CONTAINS',
  EMPTY = 'EMPTY',
  EQUALS = 'EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_EMPTY = 'NOT_EMPTY',
  NOT_EQUALS = 'NOT_EQUALS',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  IS_COMPLETE = 'IS_COMPLETE',
  IS_NOT_COMPLETE = 'IS_NOT_COMPLETE',
}

/**
 * Logic Condition Comparator text ENUM
 * @export
 * @enum {string}
 */
export const MapLogicConditionComparatorEnumToText: Record<keyof typeof LogicConditionComparatorEnum, string> = {
  EQUALS: $localize`does equal`,
  NOT_EQUALS: $localize`does not equal`,
  EMPTY: $localize`is empty`,
  NOT_EMPTY: $localize`is not empty`,
  CONTAINS: $localize`does contain`,
  NOT_CONTAINS: $localize`does not contain`,
  LESS_THAN: $localize`is less than`,
  GREATER_THAN: $localize`is greater than`,
  BEFORE: $localize`in the past`,
  AFTER: $localize`in the future`,
  IS_COMPLETE: $localize`is complete`,
  IS_NOT_COMPLETE: $localize`is not complete`,
};

/**
 * Logic Condition Type ENUM
 * @export
 * @enum {string}
 */
export enum ConditionTypeEnum {
  IF = 'IF',
  IF_SOME = 'IF_SOME',
  IF_NONE = 'IF_NONE',
}
/**
 * Logic Condition Type text ENUM
 * @export
 * @enum {string}
 */
export enum ConditionTypeTextEnum {
  IF = 'if',
  IF_SOME = 'if some',
  IF_NONE = 'if none',
}

/**
 * Type for the 'Logic Condition' Value.
 * @export
 * @type {LogicValueInterface}
 */
export type LogicValueInterface =
  | {
      type: LogicValueTypeEnum.ATTRIBUTE;
      attributeId?: string;
    }
  | {
      type: LogicValueTypeEnum.TEXT;
      value?: string;
    }
  | {
      type: LogicValueTypeEnum.DATE;
      value?: string;
    };

/**
 * Type for the 'Logic Value' Option
 * @export
 * @type {LogicValueOptionInterface}
 */
export type LogicValueOptionInterface =
  | {
      type: LogicValueTypeEnum.ATTRIBUTE;
      attributeId?: string;
      text: string;
      value: string;
    }
  | {
      type: LogicValueTypeEnum.TEXT;
      text: string;
      value?: string;
    }
  | {
      type: LogicValueTypeEnum.DATE;
      value?: string;
      text: string;
    }
  | {
      type: LogicValueTypeEnum.QUESTION_SET;
      value?: string;
      text: string;
    };

/**
 * Logic Condition Value Type ENUM
 * @export
 * @enum {string}
 */
export enum LogicValueTypeEnum {
  TEXT = 'TEXT',
  ATTRIBUTE = 'ATTRIBUTE',
  DATE = 'DATE',
  QUESTION_SET = 'QUESTION_SET',
}

/**
 * Logic Condition Type ENUM
 * @export
 * @enum {string}
 */
export enum LogicConditionTypeEnum {
  BASIC = 'BASIC',
  VALUE = 'VALUE',
  DATE = 'DATE',
  QUESTION_SET = 'QUESTION_SET',
}

/**
 * Date Logic ConditionDuration ENUM
 * @export
 * @enum {string}
 */
export enum DateLogicConditionDurationEnum {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export type keyOfLogicConditionComparatorEnum = keyof typeof LogicConditionComparatorEnum;
export type keyOfLogicConditionTypeEnum = keyof typeof LogicConditionTypeEnum;
export type keyOfConditionTypeEnum = keyof typeof ConditionTypeEnum;

/**
 * Logic condition creation type
 * @export
 * @type {logicConditionCreationType}
 */
export type logicConditionCreationType = {
  rootId: string;
  parentId: string;
  attributes: string[] | null;
  conditionId: string;
  condition: ConditionTypeEnum | null;
  comparator: LogicConditionComparatorEnum | null;
  type: LogicConditionTypeEnum;
  value?: LogicValueInterface | null;
  unit?: number | null;
  duration?: DateLogicConditionDurationEnum | null;
  questionSetIds?: string[] | null;
};

/**
 * Helper function for making base logic condition
 * @param {logicConditionCreationType} logicCondition Interface containing values for logic creation
 * @return {Omit<LogicConditionType, 'type'>} Return LogicConditionType without type property
 */
const makeBaseLogicCondition = (logicCondition: logicConditionCreationType): Omit<LogicConditionType, 'type'> => ({
  rootId: logicCondition.rootId,
  parentId: logicCondition.parentId,
  attributes: logicCondition.attributes,
  conditionId: logicCondition.conditionId,
  condition: logicCondition.condition,
  comparator: logicCondition.comparator,
});

/**
 * Make basic logic condition
 * @param {logicConditionCreationType} logicCondition Interface containing values for logic creation
 * @return {BasicLogicConditionInterface} Created logic condition interface
 */
export const makeBasicLogicCondition = (logicCondition: logicConditionCreationType): BasicLogicConditionInterface => ({
  ...makeBaseLogicCondition(logicCondition),
  type: LogicConditionTypeEnum.BASIC,
});

/**
 * Make value logic condition
 * @param {logicConditionCreationType} logicCondition Interface containing values for logic creation
 * @return {ValueLogicConditionInterface} Created logic condition interface
 */
export const makeValueLogicCondition = (logicCondition: logicConditionCreationType): ValueLogicConditionInterface => ({
  ...makeBaseLogicCondition(logicCondition),
  type: LogicConditionTypeEnum.VALUE,
  value: logicCondition.value,
});

/**
 * Make date logic condition
 * @param {logicConditionCreationType} logicCondition Interface containing values for logic creation
 * @return {DateLogicConditionInterface} Created logic condition interface
 */
export const makeDateLogicCondition = (logicCondition: logicConditionCreationType): DateLogicConditionInterface => ({
  ...makeBaseLogicCondition(logicCondition),
  type: LogicConditionTypeEnum.DATE,
  unit: logicCondition.unit,
  duration: logicCondition.duration,
});

/**
 * Make question set logic condition
 * @param {logicConditionCreationType} logicCondition Interface containing values for logic creation
 * @return {QuestionSetLogicConditionInterface} Created logic condition interface
 */
export const makeQuestionSetLogicCondition = (
  logicCondition: logicConditionCreationType
): QuestionSetLogicConditionInterface => ({
  ...makeBaseLogicCondition(logicCondition),
  type: LogicConditionTypeEnum.QUESTION_SET,
  questionSetIds: logicCondition.questionSetIds,
});
