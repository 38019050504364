import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CommonRoutesEnum,
  EntityInterface,
  GridStateInterface,
  NAVIGATE_ROW_COLUMN,
  PaginationTypeEnum,
  RecordGridInputInterface,
  UNTITLED,
  haveRecordIds,
  makeUUID,
} from '@surecloud/common';
import { LoadingButtonStateInterface } from '@surecloud/design';
import { EntityStateFacade } from '@surecloud/entity-state';
import { RequestErrorFacade } from '@surecloud/error';

import { NormalisedRecord, RecordActions, RecordComponentValueInterface, RecordFeatureFacade } from '@surecloud/record';
import { RecordImportActions } from '@surecloud/record-import';

import { CellClickedEvent, CellValueChangedEvent } from 'ag-grid-community';
import { BehaviorSubject, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { FeatureFlagFacade, NG_4037_BULK_IMPORT_RECORDS } from '@surecloud/feature-flag';
import { StandaloneRecordListActions } from './+state/actions/standalone-record-list.actions';

@Component({
  selector: 'sc-standalone-record-list',
  templateUrl: './standalone-record-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandaloneRecordListComponent implements OnInit, OnDestroy {
  /**
   * The PaginationTypeEnum enum visible to the template.
   * @memberof StandaloneRecordListComponent
   */
  PaginationTypeEnum = PaginationTypeEnum;

  /**
   * The state of the add record loading button.
   * Used to control the button state, since it is triggered by an observable.
   * @memberof StandaloneRecordListComponent
   */
  addRecordButtonLoadingState$ = new BehaviorSubject<LoadingButtonStateInterface>({});

  /**
   * When the component is destroyed.
   * Then emit so that other observables may tear down.
   * @type {Subject<boolean>}
   * @private
   * @memberof StandaloneRecordListComponent
   */
  private destroyed$: Subject<void> = new Subject();

  /**
   * Feature flag to enable the Bulk Import of Records (NG-4037) feature.
   * @memberof StandaloneRecordListComponent
   */
  enabledForNg4037$: Observable<boolean> = of(false);

  /**
   * Creates an instance of EntityPreviewDataComponent.
   * @param {RecordFeatureFacade} recordFacade The Record facade service
   * @param {EntityStateFacade} entityFacade The Entity facade service
   * @param {Router} router The router service
   * @param {RequestErrorFacade} errorFacade The Error state facade. Used to get errors by their request id.
   * @param {FeatureFlagFacade} featureFlagFacade The feature flag facade service.
   * @memberof StandaloneRecordListComponent
   */
  constructor(
    public readonly recordFacade: RecordFeatureFacade,
    public readonly entityFacade: EntityStateFacade,
    private readonly router: Router,
    private readonly errorFacade: RequestErrorFacade,
    private readonly featureFlagFacade: FeatureFlagFacade
  ) {}

  /**
   * Runs when component initialized.
   * Dispatch an enter action.
   * @memberof StandaloneRecordListComponent
   */
  ngOnInit(): void {
    this.recordFacade.dispatch(StandaloneRecordListActions.standaloneRecordListEnter());
    this.enabledForNg4037$ = this.featureFlagFacade.featureIsEnabled$(NG_4037_BULK_IMPORT_RECORDS);
  }

  /**
   * Runs when component is disposed of.
   * Dispatch leave action and emit destroyed.
   * @memberof StandaloneRecordListComponent
   */
  ngOnDestroy(): void {
    this.recordFacade.dispatch(StandaloneRecordListActions.standaloneRecordListLeave());

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Dispatches and action when a used edits a Record
   * @param {CellValueChangedEvent} cellChangedRequest The event of the cell edit
   * @memberof StandaloneRecordListComponent
   */
  recordUpdated(cellChangedRequest: CellValueChangedEvent): void {
    const { colId } = cellChangedRequest.colDef;
    const { recordId, attributes, viewerCanDelete } = cellChangedRequest.data;
    const { value } = cellChangedRequest;

    if (colId) {
      attributes[colId].value = { value, display: value };

      const updatedRecord: RecordComponentValueInterface = {
        recordId,
        colId,
        attributes,
        viewerCanDelete,
      };

      this.recordFacade.dispatch(
        StandaloneRecordListActions.recordListUpdateRecord({ recordComponentValue: updatedRecord })
      );
    }
  }

  /**
   * Dispatches an action when a user adds a Record
   * @memberof StandaloneRecordListComponent
   */
  addRecord(): void {
    const requestId = makeUUID();

    this.recordFacade.dispatch(StandaloneRecordListActions.recordListCreateRecord({ requestId }));

    this.addRecordButtonLoadingState$.next({ loading: true });

    this.errorFacade
      .getErrorById$(requestId)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(() => this.addRecordButtonLoadingState$.next({ loading: false, error: 'Error' }));
  }

  /**
   * Dispatches an action when a user deletes a Record(s)
   * @param {(NormalisedRecord[] | unknown)} recordsToDelete An array of Records to be deleted
   * @memberof StandaloneRecordListComponent
   */
  // prettier-ignore
  deleteRecord(recordsToDelete: NormalisedRecord[] | unknown): void { // NOSONAR
    const records = recordsToDelete as NormalisedRecord[];
    const recordIds: string[] = records.map((r) => r.recordId);
    this.recordFacade.dispatch(StandaloneRecordListActions.recordListStartDeleteRecords({ recordIds }));
  }

  /**
   * Handles the cellClicked event from the grid component.
   * @param {CellClickedEvent} event - the cell click event data
   * @memberof StandaloneRecordListComponent
   */
  cellClicked({ colDef, data }: CellClickedEvent): void {
    if (colDef.field === NAVIGATE_ROW_COLUMN) {
      const { recordId } = data;
      this.router.navigate(['/', CommonRoutesEnum.RecordViewRoot, recordId]);
    }
  }

  /**
   * Exports records in the grid.
   * Provide attributeIds to determine the columns to export.
   * Provide recordIds to determine the specific records to export, leave empty to export all records.
   * Provide entityId to determine the entity to export records from.
   * @param {RecordGridInputInterface} recordGridData - the record grid data
   * @param {EntityInterface} entity - the entity
   * @param {string[]} recordIds - the record ids to export
   */
  exportRecords(recordGridData: RecordGridInputInterface, entity: EntityInterface, recordIds: string[] = []): void {
    const attributeIds = recordGridData.columnAttributes.map((attr) => attr.attributeId);
    const { entityId, name } = entity;
    const exportName = name || UNTITLED;
    this.recordFacade.dispatch(
      StandaloneRecordListActions.recordListExportRecords({
        exportName,
        entityId,
        attributeIds,
        recordIds,
      })
    );
  }

  /**
   * Export the selected rows
   * @param {unknown[]} selectedRows - the selected rows
   * @param {RecordGridInputInterface} recordGridData - the record grid data
   * @param {EntityInterface} entity - the entity
   * @memberof StandaloneRecordListComponent
   */
  exportSelectedRecords(
    selectedRows: unknown[],
    recordGridData: RecordGridInputInterface,
    entity: EntityInterface
  ): void {
    if (haveRecordIds(selectedRows)) {
      const recordIds = selectedRows.map((r) => r.recordId);
      this.exportRecords(recordGridData, entity, recordIds);
    }
  }

  /**
   * Import records into file.
   * @param {string} entityId - the entity id
   * @memberof StandaloneRecordListComponent
   */
  importRecords(entityId: string): void {
    this.recordFacade.dispatch(RecordImportActions.openRecordImportModal({ modalDetails: { entityId } }));
  }

  /**
   * Handle the grid state changes.
   * When pagination or sorting or filtering changes, request the new data from the server.
   * @param {GridStateInterface} gridState - the grid state
   * @memberof StandaloneRecordListComponent
   */
  updateGridState(gridState: GridStateInterface): void {
    this.recordFacade.dispatch(RecordActions.updateGridState({ gridState }));
  }
}
