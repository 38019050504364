import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { LinkableRecordsEffects } from './+state/effects/linkable-records/linkable-records.effects';
import { LinkedRecordsEffects } from './+state/effects/linked-records/linked-records.effects';
import { RecordEffects } from './+state/effects/record.effects';
import { TableRecordEffects } from './+state/effects/table-record/table-record.effects';
import * as fromRecordLinks from './+state/reducers/link-records.reducer';
import * as fromRecord from './+state/reducers/record.reducer';
import * as fromTableRecord from './+state/reducers/table-record.reducer';

/**
 * Record View Module.
 *
 * @export
 * @class RecordModule
 */
@NgModule({
  imports: [
    StoreModule.forFeature(fromRecordLinks.RECORD_LINKS_FEATURE_KEY, fromRecordLinks.reducer),
    StoreModule.forFeature(fromRecord.RECORDS_FEATURE_KEY, fromRecord.reducer),
    StoreModule.forFeature(fromTableRecord.TABLE_RECORD_FEATURE_KEY, fromTableRecord.reducer),
    CommonModule,
  ],
  providers: [
    EffectsBootstrapProvider.provide([LinkableRecordsEffects, LinkedRecordsEffects, RecordEffects, TableRecordEffects]),
  ],
})
export class RecordModule {}
