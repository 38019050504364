import * as fromControls from './controls';
import { ValidationRendererTypeEnum } from './validation-component-renderer-config.interface';
import { ValidationRendererControlConfigInterface } from './validation-component-renderer-control-config.interface';
/**
 * Renderer control configuration
 */
export const VALIDATION_RENDERER_ALL_CONTROLS_CONFIG: Record<
  ValidationRendererTypeEnum,
  ValidationRendererControlConfigInterface
> = {
  NUMBER: fromControls.NUMBER_CONTROL_CONFIG,
  NUMBER_DROPDOWN: fromControls.NUMBER_DROPDOWN_CONTROL_CONFIG,
  DROPDOWN: fromControls.DROPDOWN_CONTROL_CONFIG,
  DATE: fromControls.DATE_CONTROL_CONFIG,
};
