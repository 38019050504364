import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonIconModule } from '../../icon/icons/common-icon.module';
/**
 * Empty page for integrations
 * @export
 * @class IntegrationEmptyPageComponent
 */
@Component({
  selector: 'sc-integration-empty-page',
  templateUrl: './integration-empty-page.component.html',
  styleUrls: ['./integration-empty-page.component.scss'],
  standalone: true,
  imports: [CommonModule, CommonIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationEmptyPageComponent {}
