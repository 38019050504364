<div
  [ngSwitch]="status"
  class="sc-matrix-cell-container"
  [ngClass]="{ 'sc-matrix-cell-container--editable': isEditable }">
  <button *ngSwitchCase="cellStatusEnum.EMPTY" class="sc-matrix-cell sc-matrix-cell__empty" (click)="toggleStatus()">
    <span class="status-hidden">{{ status }}</span>
  </button>
  <button
    *ngSwitchCase="cellStatusEnum.FILLED"
    class="sc-matrix-cell"
    [ngClass]="{ 'sc-matrix-cell__filled--selected': isSelected }"
    [ngStyle]="{ 'background-color': colour }"
    (click)="toggleCellSelection()">
    <span class="status-hidden">{{ status }}</span>
  </button>
  <button
    *ngSwitchCase="cellStatusEnum.SELECTED"
    class="sc-matrix-cell sc-matrix-cell__selected"
    (click)="toggleStatus()">
    <span class="status-hidden">{{ status }}</span>
  </button>
  <button
    *ngSwitchCase="cellStatusEnum.LABELLED"
    class="sc-matrix-cell"
    [ngStyle]="{ 'background-color': colour, cursor: 'pointer' }"
    (click)="labelledClicked.emit()">
    <span class="sc-matrix-cell__labelled flex align-items-center justify-content-center">
      {{ label }}
    </span>
  </button>
  <button
    *ngSwitchCase="cellStatusEnum.NEW"
    class="sc-matrix-cell sc-matrix-cell__new"
    (click)="isEditable ? newCellClick.emit() : null">
    <sc-icon key="green-plus" width="24px" height="24px"></sc-icon>
  </button>
</div>
