import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CommonRoutesEnum, fromPageState, makeUUID } from '@surecloud/common';
import * as HomepageApplicationsSelectors from '@surecloud/homepage-applications-state';
import { HomepageApplicationsActions } from '@surecloud/homepage-applications-state';
import { map, switchMap, takeUntil } from 'rxjs';
import { ApplicationListActions } from '../actions/application-list.actions';
/**
 * The Effects/side effects for the Application List Feature.
 * @export
 * @class EntityStateEffects
 */
@Injectable({ providedIn: 'root' })
export class StandaloneApplicationListEffects {
  /**
   * When a user enter the application list.
   * Then dispatch a breadcrumb action.
   * @memberof StandaloneApplicationListEffects
   */
  setApplicationListBreadcrumb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationListActions.applicationListEnter),
      switchMap(() =>
        this.store.pipe(
          select(HomepageApplicationsSelectors.getSelectedId),
          takeUntil(
            this.actions$.pipe(
              ofType(ApplicationListActions.applicationListEnter),
              ofType(ApplicationListActions.applicationListLeave)
            )
          )
        )
      ),
      map((tabId) =>
        fromPageState.BreadcrumbActions.setBreadcrumbs({
          breadcrumbs: [
            {
              label: $localize`My Applications`,
              route: ['/', CommonRoutesEnum.ApplicationList],
              id: makeUUID(),
              tabId,
            },
          ],
        })
      )
    )
  );

  /**
   * When a user select a homepage application.
   * Then set that Application Name as a Tab Id in the breadcrumb state.
   * @memberof StandaloneApplicationListEffects
   */
  setApplicationListBreadcrumbTabId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageApplicationsActions.selectHomepageApplication),
      map(({ applicationName }) =>
        fromPageState.BreadcrumbActions.setBreadcrumbs({
          breadcrumbs: [
            {
              label: $localize`My Applications`,
              route: ['/', CommonRoutesEnum.ApplicationList],
              id: makeUUID(),
              tabId: applicationName,
            },
          ],
        })
      )
    )
  );

  /**
   * Creates an instance of StandaloneApplicationListEffects.
   * @param {Actions} actions$ The entity actions.
   * @param {Store} store The ngrx store.
   * @memberof StandaloneApplicationListEffects
   */
  constructor(private readonly actions$: Actions, private readonly store: Store) {}
}
