import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuestionSetEffects } from './+state/effects/question-set/question-set.effects';
import { QUESTION_SET_FEATURE_KEY, reducer } from './+state/reducers/question-set/question-set.reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([QuestionSetEffects]),
    StoreModule.forFeature(QUESTION_SET_FEATURE_KEY, reducer),
  ],
})
export class QuestionSetStateModule {}
