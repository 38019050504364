import { ConnectorCategoryApiInterface } from '@surecloud/api-types';
import { ValueOf } from '../utility-types';

/**
 * Integration Connection Status Enum
 * @export
 * @enum {number}
 */
export enum IntegrationConnectionStatusEnum {
  AUTHENTICATION_FAILED = 'Authentication Failed',
  CONNECTED = 'Connected',
  NOT_CONNECTED = 'Not connected',
}

/**
 * Integration data
 * @export
 * @interface Integration
 */
export interface IntegrationInterface {
  connectorId: string;
  icon: string;
  title: string;
  description: string;
  categories: ConnectorCategoryApiInterface[];
  status: ValueOf<typeof IntegrationConnectionStatusEnumToText>;
  quantityConnection: number;
}

/**
 * Integration Connection Status Enum to Text
 * @export
 * @enum {string}
 */
export const IntegrationConnectionStatusEnumToText: Record<keyof typeof IntegrationConnectionStatusEnum, string> = {
  AUTHENTICATION_FAILED: $localize`Fail`,
  CONNECTED: $localize`Connected`,
  NOT_CONNECTED: $localize`Not connected`,
};
