import { EntityDeleteInputApiInterface, EntityUpdateInputApiInterface } from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';
import { MutationOptions } from 'apollo-angular/types';

/**
 * Create entity GraphQL mutation.
 */
const ENTITY_CREATE_MUTATION = gql`
  mutation entityCreateMutation {
    entityCreate {
      createdId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for creating a entity.
 * Entities are create with no properties.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityCreateMutation = (requestId: string | null): MutationOptions => ({
  mutation: ENTITY_CREATE_MUTATION,
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Update entity GraphQL mutation.
 */
const ENTITY_UPDATE_MUTATION = gql`
  mutation entityUpdateMutation($input: EntityUpdateInput!) {
    entityUpdate(input: $input) {
      entity {
        entityId
      }
    }
  }
`;

/**
 * Function to update the GraphQL mutation options for updating an entity.
 * @param {EntityUpdateInputApiInterface} input The entity properties to update.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityUpdateMutation = (
  input: EntityUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_UPDATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Delete entity GraphQL mutation.
 */
const ENTITY_DELETE_MUTATION = gql`
  mutation entityDeleteMutation($input: EntityDeleteInput!) {
    entityDelete(input: $input) {
      deletedId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for deleting an entity.
 * @param {EntityDeleteInputApiInterface} input The entity to delete.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityDeleteMutation = (input: EntityDeleteInputApiInterface): MutationOptions => ({
  mutation: ENTITY_DELETE_MUTATION,
  variables: { input },
});
