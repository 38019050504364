import {
  EntityAttributeRuleConditionTypeEnum,
  EntityAttributeRuleTemporalOperatorTypeEnum,
  EntityAttributeRuleValueTypeEnum,
  EntityAttributeTypeEnum,
  SelectDropdownComponentOptionInterface,
  UNTITLED,
} from '@surecloud/common';
import {
  ValidationRendererControlConfigUnion,
  ValidationRendererDateControlConfigInterface,
  ValidationRendererDropdownControlConfigInterface,
  ValidationRendererFormControlConfigInterface,
  ValidationRendererNumberAndDropdownControlConfigInterface,
  ValidationRendererTypeEnum,
} from './validation-component-renderer/validation-component-renderer-config.interface';
import {
  EntityAttributeTypeAndOptionsInterface,
  GlobalConditionOptionInterface,
  TemporalUnitEnum,
} from './validation-conditions.interface';

/**
 * Global condition config
 */
export const globalConditionOptions: GlobalConditionOptionInterface[] = [
  {
    label: $localize`Maximum Character Limit`,
    conditionType: EntityAttributeRuleConditionTypeEnum.MaximumCharacterLimit,
    valueType: EntityAttributeTypeEnum.Text,
  },
  {
    label: $localize`Min Character Limit`,
    conditionType: EntityAttributeRuleConditionTypeEnum.MinimumCharacterLimit,
    valueType: EntityAttributeTypeEnum.Text,
  },
  {
    label: $localize`Is Unique`,
    conditionType: EntityAttributeRuleConditionTypeEnum.Unique,
    valueType: EntityAttributeTypeEnum.Text,
  },
  {
    label: $localize`Greater Than`,
    conditionType: EntityAttributeRuleConditionTypeEnum.GreaterThan,
    valueType: EntityAttributeTypeEnum.Number,
  },
  {
    label: $localize`Less Than`,
    conditionType: EntityAttributeRuleConditionTypeEnum.LessThan,
    valueType: EntityAttributeTypeEnum.Number,
  },
  {
    label: $localize`Before`,
    conditionType: EntityAttributeRuleConditionTypeEnum.Before,
    valueType: EntityAttributeTypeEnum.Date,
  },
  {
    label: $localize`After`,
    conditionType: EntityAttributeRuleConditionTypeEnum.After,
    valueType: EntityAttributeTypeEnum.Date,
  },
];

/**
 *  Public method to get condition options for an attribute by type
 * @param {EntityAttributeTypeAndOptionsInterface} typeAndExcludeOptions the type of the Attribute Value and the list of options to exclude
 * @return {GlobalConditionOptionInterface[]} a list of configs
 */
export const getConditionOptionsForType = (
  typeAndExcludeOptions: EntityAttributeTypeAndOptionsInterface
): GlobalConditionOptionInterface[] =>
  globalConditionOptions.filter(
    (option) =>
      option.valueType === typeAndExcludeOptions.type &&
      !typeAndExcludeOptions.excludeOptions.includes(option.conditionType)
  );

/**
 *  Public method to make the dropdown options for an attribute by type
 * @param {EntityAttributeTypeAndOptionsInterface} typeAndExcludeOptions the type of the Attribute Value and the list of options to exclude
 * @return {SelectDropdownComponentOptionInterface[]} a list of options
 */
export const makeDropdownConditionOptions = (
  typeAndExcludeOptions: EntityAttributeTypeAndOptionsInterface
): SelectDropdownComponentOptionInterface[] =>
  getConditionOptionsForType(typeAndExcludeOptions).map((options) => ({
    text: options.label,
    value: options.conditionType,
  }));

/**
 * Convert a condition type to label
 * @param { EntityAttributeRuleConditionTypeEnum | string | null} conditionType the condition type to convert
 * @return
 */
export const convertConditionToLabel = (
  conditionType: EntityAttributeRuleConditionTypeEnum | string | null
): string => {
  const label = globalConditionOptions.find((option) => option.conditionType === conditionType)?.label;

  if (label) {
    return label;
  }

  if (conditionType) {
    return conditionType;
  }

  return $localize`${UNTITLED}`;
};

/**
 * Attribute option for value type dropdown
 */
export const VALUE_TYPE_ATTRIBUTE_OPTION: SelectDropdownComponentOptionInterface = {
  text: $localize`Attribute`,
  value: EntityAttributeRuleValueTypeEnum.Attribute,
};

/**
 * Number option for value type dropdown
 */
export const VALUE_TYPE_NUMBER_OPTION: SelectDropdownComponentOptionInterface = {
  text: $localize`Number`,
  value: EntityAttributeRuleValueTypeEnum.Number,
};

/**
 * Period option for value type dropdown
 */
export const VALUE_TYPE_PERIOD_OPTION: SelectDropdownComponentOptionInterface = {
  text: $localize`Period`,
  value: EntityAttributeRuleValueTypeEnum.Period,
};

/**
 * Date option for value type dropdown
 */
export const VALUE_TYPE_DATE_OPTION: SelectDropdownComponentOptionInterface = {
  text: $localize`Date`,
  value: EntityAttributeRuleValueTypeEnum.Date,
};

/**
 * Period options for period dropdown
 */
export const PERIOD_OPTIONS: SelectDropdownComponentOptionInterface[] = [
  {
    text: $localize`Days`,
    value: TemporalUnitEnum.Days,
  },
  {
    text: $localize`Weeks`,
    value: TemporalUnitEnum.Weeks,
  },
  {
    text: $localize`Months`,
    value: TemporalUnitEnum.Months,
  },
];

/**
 * Period options for period dropdown
 */
export const PERIOD_OPERATOR_OPTIONS: SelectDropdownComponentOptionInterface[] = [
  {
    text: $localize`Minus`,
    value: EntityAttributeRuleTemporalOperatorTypeEnum.Minus,
  },
  {
    text: $localize`Plus`,
    value: EntityAttributeRuleTemporalOperatorTypeEnum.Plus,
  },
];

/**
 * Condition to value type options map
 */
export const conditionToValueTypeOptionsMap: Record<string, SelectDropdownComponentOptionInterface[]> = {
  MAXIMUM_CHARACTER_LIMIT: [],
  MINIMUM_CHARACTER_LIMIT: [],
  UNIQUE: [],
  BEFORE: [VALUE_TYPE_ATTRIBUTE_OPTION, VALUE_TYPE_PERIOD_OPTION, VALUE_TYPE_DATE_OPTION],
  AFTER: [VALUE_TYPE_ATTRIBUTE_OPTION, VALUE_TYPE_PERIOD_OPTION, VALUE_TYPE_DATE_OPTION],
  GREATER_THAN: [VALUE_TYPE_ATTRIBUTE_OPTION, VALUE_TYPE_NUMBER_OPTION],
  LESS_THAN: [VALUE_TYPE_ATTRIBUTE_OPTION, VALUE_TYPE_NUMBER_OPTION],
};

/**
 * Config for number renderer validation
 */
const numberRendererConfig: ValidationRendererFormControlConfigInterface = {
  type: ValidationRendererTypeEnum.Number,
  label: $localize`Value`,
  guidanceText: $localize`Enter the value for the condition`,
  placeholder: $localize`Value`,
};

/**
 * Config for attribute renderer validation
 */
const attributeRendererConfig: ValidationRendererDropdownControlConfigInterface = {
  type: ValidationRendererTypeEnum.Dropdown,
  label: $localize`Attribute`,
  controlName: 'dropdown',
  description: 'Select a number attribute',
  options: [],
};

/**
 * Config for attribute date renderer validation
 */
const attributeDateRendererConfig: ValidationRendererDropdownControlConfigInterface = {
  type: ValidationRendererTypeEnum.Dropdown,
  label: $localize`Attribute`,
  controlName: 'dropdown',
  description: 'Select a date attribute',
  options: [],
};

/**
 * Config for number and dropodown renderer validation
 */
const numberAndDropdownAttributeRendererConfig: ValidationRendererNumberAndDropdownControlConfigInterface = {
  type: ValidationRendererTypeEnum.NumberAndDropdown,
  inputLabel: $localize`Value`,
  inputDescription: $localize`Enter the value for the condition`,
  dropdownLabel: $localize`Period`,
  dropdownDescription: $localize`Select a date period`,
  secondDropdownLabel: $localize`Period unit`,
  secondDropdownDescription: $localize`Select a date period unit`,
  inputControlName: 'number',
  dropdownControlName: 'dropdown',
  options: PERIOD_OPTIONS,
  secondDropdownOptions: PERIOD_OPERATOR_OPTIONS,
  secondDropdownControlName: 'dropdownPeriodOperator',
};

const dateAttributeRendererConfig: ValidationRendererDateControlConfigInterface = {
  type: ValidationRendererTypeEnum.Date,
  label: $localize`Date`,
  controlName: 'date',
  guidanceText: 'Select a date',
};

/**
 * Condition to form name mapn
 */
export const conditionToFormNameMap: Record<string, ValidationRendererControlConfigUnion | string | null> = {
  'MAXIMUM_CHARACTER_LIMIT.': numberRendererConfig,
  'MINIMUM_CHARACTER_LIMIT.': numberRendererConfig,
  'MAXIMUM_CHARACTER_LIMIT.NUMBER': numberRendererConfig,
  'MINIMUM_CHARACTER_LIMIT.NUMBER': numberRendererConfig,
  'UNIQUE.': null,
  'UNIQUE.NUMBER': null,
  'BEFORE.ATTRIBUTE': attributeDateRendererConfig,
  'BEFORE.PERIOD': numberAndDropdownAttributeRendererConfig,
  'BEFORE.DATE': dateAttributeRendererConfig,
  'AFTER.ATTRIBUTE': attributeDateRendererConfig,
  'AFTER.PERIOD': numberAndDropdownAttributeRendererConfig,
  'AFTER.DATE': dateAttributeRendererConfig,
  'GREATER_THAN.NUMBER': numberRendererConfig,
  'LESS_THAN.NUMBER': numberRendererConfig,
  'GREATER_THAN.ATTRIBUTE': attributeRendererConfig,
  'LESS_THAN.ATTRIBUTE': attributeRendererConfig,
};
