<div i18n class="sc-matrix-side-panel-block flex" *ngIf="label">
  <span
    class="sc-matrix-side-panel-block__item align-items-center justify-content-center sc-font-subtitle sc-text sc-border-radius-8 flex"
    [ngStyle]="{
      color: colour ? colour : COLOURS.black,
      'background-color': background ? background : COLOURS.brand25,
      'border-colour': background ? background : COLOURS.neutral40
    }">
    {{ label }}
  </span>
</div>
