import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ComboBoxModule, DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  DateLogicConditionDurationEnum,
  E2eHookDirective,
  LogicValueOptionInterface,
  SelectDropdownComponentOptionInterface,
} from '@surecloud/common';
import { Observable } from 'rxjs';
import { DeleteButtonComponent } from '../delete-button/delete-button.component';
import { InputNumberComponent } from '../input-number/input-number.component';
import { MultiselectDropdownComponent } from '../select-dropdown/multiselect-dropdown.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

/**
 * Logic Builder Component.
 * @example
 *  <sc-logic-builder
 *     [conditionOptions]="conditionOptions"
 *     [conditionValueOptions]="conditionValueOptions"
 *     [comparatorOptions]="comparatorOptions"
 *     [comparatorValueOptions]="comparatorValueOptions"
 *     (deleteLogicBuilder)="deleteLogicBuilder()"
 *     [parentFormGroup]="form.controls['logic']">
 *  </sc-logic-builder>
 * @export
 * @class LogicBuilderComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-logic-builder',
  standalone: true,
  templateUrl: './logic-builder.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    DropDownListModule,
    ReactiveFormsModule,
    SelectDropdownComponent,
    ComboBoxModule,
    MultiselectDropdownComponent,
    DeleteButtonComponent,
    LabelModule,
    ButtonsModule,
    DropDownsModule,
    InputNumberComponent,
  ],
})
export class LogicBuilderComponent implements OnInit {
  /**
   * Placeholder/default text to display in inputs.
   * @memberof LogicBuilderComponent
   */
  defaultPlaceholder = {
    condition: {
      text: $localize`Select condition`,
      value: null,
    },
    comparator: {
      text: $localize`Select comparator`,
      value: null,
    },
    conditionValues: $localize`Select condition values`,
    comparatorValue: $localize`Select comparator value`,
  };

  /**
   * Date unit options for select dropdowns
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof LogicBuilderComponent
   */
  DATE_UNIT_OPTIONS: SelectDropdownComponentOptionInterface[] = [
    {
      text: $localize`Duration`,
      value: null,
    },
    {
      text: $localize`Days`,
      value: DateLogicConditionDurationEnum.DAYS,
    },
    {
      text: $localize`Weeks`,
      value: DateLogicConditionDurationEnum.WEEKS,
    },
    {
      text: $localize`Months`,
      value: DateLogicConditionDurationEnum.MONTHS,
    },
    {
      text: $localize`Years`,
      value: DateLogicConditionDurationEnum.YEARS,
    },
  ];

  /**
   * Value Normalizer
   * @memberof LogicBuilderComponent
   */
  @Input()
  valueNormalizer!: (text: Observable<string>) => Observable<unknown>;

  /**
   * The parent form group to use for the logic builder inputs.
   * @type {FormGroup}
   * @memberof LogicBuilderComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * The list of options to  display in the condition dropdown.
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof LogicBuilderComponent
   */
  @Input()
  conditionOptions!: SelectDropdownComponentOptionInterface[];

  /**
   * The list of options to display in the condition value multiselect.
   * @type {LogicValueOptionInterface[]}
   * @memberof LogicBuilderComponent
   */
  @Input()
  conditionValueOptions!: LogicValueOptionInterface[];

  /**
   * The list of options to display in the comparator dropdown.
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof LogicBuilderComponent
   */
  @Input()
  comparatorOptions!: SelectDropdownComponentOptionInterface[];

  /**
   * The list of options to display in the comparator value multiselect.
   * @type {LogicValueOptionInterface[]}
   * @memberof LogicBuilderComponent
   */
  @Input()
  comparatorValueOptions!: LogicValueOptionInterface[];

  /**
   * Output event for when the delete button has been clicked.
   * @memberof LogicBuilderComponent
   */
  @Output()
  deleteLogicBuilder = new EventEmitter();

  /**
   * Keep track of the popup state so we can apply styles when it's open.
   * @type {(boolean | undefined)}
   * @memberof LogicBuilderComponent
   */
  public comboboxPopupOpen: boolean | undefined;

  /**
   * Creates an instance of LogicBuilderComponent.
   * @param {FormGroupDirective} formGroupDirective The Angular form group directive service.
   * @memberof LogicBuilderComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Runs when the component is started.
   * Sets the parent formGroup form the component.
   * @memberof LogicBuilderComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
