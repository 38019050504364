import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { COMMON_MAX_CHAR_25, UPDATE_DELAY, trackByIndex } from '@surecloud/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { LoadingButtonComponent } from '../button/loading-button.component';
import { DeleteButtonComponent } from '../delete-button/delete-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { InputTextComponent } from '../input-text/input-text.component';
import { MatrixSidePanelBlockComponent } from '../matrix-side-panel-block/matrix-side-panel-block.component';
import { InputTextListItemInterface, InputTextListItemMetaDataInterface } from './input-text-list.component.interface';
import { InputTextListItemDeletablePipe } from './pipes/input-text-list-item-deletable.pipe';
/**
 * Surecloud Input Text List Component.
 * @implements { OnChanges}
 * @implements { OnDestroy }
 * @export
 * @class InputTextListComponent
 */
@Component({
  selector: 'sc-input-text-list',
  standalone: true,
  styleUrls: ['./input-text-list.component.scss'],
  templateUrl: './input-text-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CommonIconModule,
    DeleteButtonComponent,
    InputTextComponent,
    InputTextListItemDeletablePipe,
    LoadingButtonComponent,
    NgScrollbarModule,
    ReactiveFormsModule,
    MatrixSidePanelBlockComponent,
    LoadingButtonComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputTextListComponent implements OnChanges, OnDestroy {
  /**
   * When the component is destroyed.
   * Then emit a value.
   * And other observables can tear down.
   * @private
   * @type {Subject<void>}
   * @memberof InputTextListComponent
   */
  private destroyed$: Subject<void> = new Subject();

  /**
   * The ID to item metadata for the input text list items.
   * Can we guarantee that adding controls to the form will in the same order as the items array?
   * This metadata map is used to set the background, colour and label of the input text list item by item id.
   * @type {Record<string, InputTextListItemMetaDataInterface>}
   * @memberof InputTextListComponent
   */
  public metadata: Record<string, InputTextListItemMetaDataInterface> = {};

  /**
   * Expose the track by function to the view template.
   * @memberof InputTextListComponent
   */
  public trackByFunction = trackByIndex;

  /**
   * Does the component currently have an error.
   * This is used to pass onto the loading button if needed.
   * @memberof InputTextListComponent
   */
  @Input() public error = '';

  /**
   * The items that are currently in the input text list.
   * @type {InputTextListItemInterface[]}
   * @memberof InputTextListComponent
   */
  @Input() public items: InputTextListItemInterface[] = [];

  /**
   * The maximum height of the scrollable area.
   * @memberof InputTextListComponent
   */
  @Input() public height = 250;

  /**
   * The type of item that is being added by the Input Text List.
   * Used for the add button.
   * @memberof InputTextListComponent
   */
  @Input() public label = 'Item';

  /**
   * Is the component currently loading in new items.
   * Will show the loading state of the button if true.
   * @memberof InputTextListComponent
   */
  @Input() public loading = false;

  /**
   * The maximum number of items that can be added to the list.
   * @memberof InputTextListComponent
   */
  @Input() public maximum: number | undefined;

  /**
   * The maximum length of the input text
   * @type {number}
   * @memberof InputTextListComponent
   */
  @Input() public maxLength: number = COMMON_MAX_CHAR_25;

  /**
   * The minimum number of items that can be in the list.
   * When this number is reached - a user will no longer be able to remove an item.
   * @memberof InputTextListComponent
   */
  @Input() public minimum = 0;

  /**
   * When the add button is clicked.
   * The emit an event to add an item.
   * @type {EventEmitter<void>}
   * @memberof InputTextListComponent
   */
  @Output() public addItem: EventEmitter<void> = new EventEmitter();

  /**
   * When an item is removed.
   * Then emit an event with the removed item.
   * @type {EventEmitter<InputTextListItemInterface>}
   * @memberof InputTextListComponent
   */
  @Output() public removeItem: EventEmitter<InputTextListItemInterface> = new EventEmitter();

  /**
   * When an item is updated.
   * Then emit an event with the updated item.
   * @type {EventEmitter<InputTextListItemInterface>}
   * @memberof InputTextListComponent
   */
  @Output() public updateItem: EventEmitter<InputTextListItemInterface> = new EventEmitter();

  /**
   * The form group for the input text list.
   * @type {FormGroup}
   * @memberof InputTextListComponent
   */
  public form: FormGroup = new FormGroup({});

  /**
   * Runs when the component has `@input` changes.
   * Updates the dynamic form to reflect any updates.
   * @param {SimpleChanges} changes Any changes to the component.
   * @memberof InputTextListComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.clean();
      this.setMetadata();
      this.setFormControls();
    }
  }

  /**
   * When the component is destroyed.
   * Then emit destroyed$ value.
   * @memberof InputTextListComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * When new items are input into the component.
   * Then remove any old items in metadata and the form controls that are no longer in the new input items.
   * @private
   * @memberof InputTextListComponent
   */
  private clean(): void {
    Object.keys(this.form.controls).forEach((control) => {
      if (!this.items.find((item) => `${item.position}` === control)) {
        this.form.removeControl(control);
        delete this.metadata[control];
      }
    });
  }

  /**
   * Set a map of Metadata for each item in the input text list.
   * @private
   * @memberof InputTextListComponent
   */
  private setMetadata(): void {
    this.items.forEach(({ background, colour, label, position }) => {
      this.metadata[position] = {
        background,
        colour,
        label,
      };
    });
  }

  /**
   * Creates the form controls for each item in the input text list.
   * @private
   * @memberof InputTextListComponent
   */
  private setFormControls(): void {
    this.items.forEach((item: InputTextListItemInterface) => {
      if (!this.form.controls[item.position]) {
        this.form.addControl(`${item.position}`, new FormControl());
        this.form.controls[item.position].valueChanges
          .pipe(debounceTime(UPDATE_DELAY), takeUntil(this.destroyed$))
          .subscribe((value) => this.updateItemValue(item.position, value));
      }

      this.form.controls[item.position].setValue(item.value, { emitEvent: false });
    });
  }

  /**
   * When an Input List Item has been updated.
   * Then emit an event the updated Input List Item.
   * @private
   * @param {number} position The position of the item to update.
   * @param {string} value The update value of the item.
   * @memberof InputTextListComponent
   */
  private updateItemValue(position: number, value: string): void {
    const item = this.items.find((i) => i.position === position);

    if (item) {
      this.updateItem.emit({ ...item, value });
    }
  }

  /**
   * Removes the items form control and metadata when an item is removed.
   * @param {string} position The position of the item to remove.
   * @memberof InputTextListComponent
   */
  public remove(position: string): void {
    const item = this.items.find((i) => `${i.position}` === position);

    this.form.removeControl(position, { emitEvent: false });
    delete this.metadata[position];
    this.removeItem.emit(item);
  }
}
