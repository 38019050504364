import { createActionGroup, props } from '@ngrx/store';
import { UserProfileInterface } from '@surecloud/user-profile';
import { FeatureFlags } from '../models/feature-flag.models';

/**
 * Feature Flag API actions map.
 */
const FEATURE_FLAG_ACTIONS_MAP = {
  'Read Feature Flags': props<{ userProfile: UserProfileInterface }>(),
  'Read Feature Flags Success': props<{ featureFlags: FeatureFlags }>(),
  'Read Feature Flags Failure': props<{ error: string }>(),
};

/**
 * Feature Flag API actions.
 *
 * @export
 */
export const FeatureFlagActions = createActionGroup({
  source: 'Feature Flag',
  events: FEATURE_FLAG_ACTIONS_MAP,
});
