import { InputTextareaComponent } from '../../input-textarea/input-textarea.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for Multi TEXT renderer component
 *
 */
export const TEXT_AREA_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: InputTextareaComponent,
  creatorFn: formControlComponentCreator,
};
