import { Buffer } from 'buffer';
import { BASE64_REGEX } from '../constants/regex.constants';

/**
 * A type guard to check if a string is base64 encoded.
 * @export
 * @param {string} value The string to check.
 * @return {boolean} Whether the string is base64 encoded.
 */
export const isStringBase64Guard = (value: string): boolean => {

  if (!BASE64_REGEX.test(value)) {
    return false;
  }

  // Try to decode the string using Buffer
  const decoded = Buffer.from(value, 'base64');

  // Check if re-encoding the decoded buffer gives the original string
  return decoded.toString('base64') === value;
};
