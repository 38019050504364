import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';

import { Auth0AndGuestUserAuthServiceInterface } from './auth0-and-guest-user-auth.service.interface';

/**
 * Stub to avoid calling Auth0 methods in tests.
 * @export
 * @class Auth0AndGuestUserAuthServiceStub
 */
@Injectable({
  providedIn: 'root',
})
export class Auth0AndGuestUserAuthServiceStub implements Auth0AndGuestUserAuthServiceInterface {
  /**
   * User is always authenticated in tests.
   * @return {Observable<boolean>} An observable that emits a boolean.
   * @memberof Auth0AndGuestUserAuthServiceStub
   */
  isAuthenticated$ = of(true);

  /**
   * Returns a stubbed access token.
   * @return {Observable<string>} An observable that emits the stubbed token as a string.
   * @memberof Auth0AndGuestUserAuthServiceStub
   */
  // eslint-disable-next-line class-methods-use-this
  getAccessTokenSilently(): Observable<string> {
    return of('token');
  }

  /**
   * Stub for auth0Service's `logout` method.
   * @return {Observable<string>} An empty observable
   * @memberof Auth0AndGuestUserAuthServiceStub
   */
  // eslint-disable-next-line class-methods-use-this
  logout = (): Observable<void> => EMPTY;

  /**
   * Stub for `loginWithRedirect` method.
   * @return {Observable<string>} An empty observable
   * @memberof Auth0AndGuestUserAuthServiceStub
   */
  // eslint-disable-next-line class-methods-use-this
  loginWithRedirect = (): Observable<void> => EMPTY;

  /**
   * Errors never happen in tests. :)
   * @memberof Auth0AndGuestUserAuthServiceStub
   */
  error$ = EMPTY;
}
