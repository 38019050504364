import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';

/**
 * Makes the global state attribute configuration
 */
export const GLOBAL_STATE_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.State,
  key: EntityAttributeTypeEnum.State,
  type: EntityAttributeTypeEnum.State,
  label: $localize`State`,
  uniqueSections: [],
});
