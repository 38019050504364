import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';

/**
 * Makes the global Context attribute configuration
 */
export const GLOBAL_CONTEXT_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Context,
  key: EntityAttributeTypeEnum.Context,
  type: EntityAttributeTypeEnum.Context,
  label: $localize`Context`,
  uniqueSections: [],
});
