import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAttributeRuleOptionInterface } from '../../../services/rule-options/rule-options.service.interface';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';
import { EntityAttributeRuleActions } from '../../actions/rule.actions';
import { EntityAttributeRuleInterface } from '../../models/rule/rule.models';

export const RULE_FEATURE_KEY = 'rule';

export interface StateInterface extends EntityState<EntityAttributeRuleInterface> {
  selectedId?: string; // which attribute rule record has been selected
  loaded: boolean; // has the attribute rule list been loaded
  error?: string | null; // last known error (if any)
  options: EntityAttributeRuleOptionInterface[]; // Array of rule options
}

export interface RulePartialStateInterface {
  readonly [RULE_FEATURE_KEY]: StateInterface;
}

export const ruleAdapter: EntityAdapter<EntityAttributeRuleInterface> =
  createEntityAdapter<EntityAttributeRuleInterface>({
    selectId: (rule: EntityAttributeRuleInterface) => rule.ruleId,
  });

export const initialState: StateInterface = ruleAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  options: [],
});

const { selectAll } = ruleAdapter.getSelectors();

/**
 * Get an array of rule IDs that belong to an attribute.
 *
 * @param {string} attributeId The attribute ID to look for rules on.
 * @param {EntityAttributeRuleInterface[]} rules List of rules.
 * @return {string[]} Array of rule IDs that belong to the attribute.
 */
const getRuleIdsForAttribute = (attributeId: string, rules: EntityAttributeRuleInterface[]): string[] =>
  rules.reduce<string[]>((ruleIds, rule) => {
    if (rule && rule.attributeId === attributeId) {
      ruleIds.push(rule.ruleId);
    }
    return ruleIds;
  }, []);

/**
 * Get an array of rule IDs that belong to an entity.
 *
 * @param {string} entityId The entity to look for rules on.
 * @param {EntityAttributeRuleInterface[]} rules List of rules.
 * @return {string[]} Array of rule IDs that belong to the entity.
 */
const getRuleIdsForEntity = (entityId: string, rules: EntityAttributeRuleInterface[]): string[] =>
  rules.reduce<string[]>((ruleIds, rule) => {
    if (rule && rule.entityId === entityId) {
      ruleIds.push(rule.ruleId);
    }
    return ruleIds;
  }, []);

const ruleReducer = createReducer(
  initialState,
  on(EntityAttributeRuleActions.readRuleListSuccess, (state: StateInterface, { ruleList }) =>
    ruleAdapter.setAll(ruleList, { ...state, loaded: true })
  ),
  on(EntityAttributeRuleActions.readRuleOptionsSuccess, (state: StateInterface, { ruleOptions }) => ({
    ...state,
    options: ruleOptions,
  })),

  on(EntityAttributeRuleActions.createRuleSuccess, (state: StateInterface, { rule }) =>
    ({ ...ruleAdapter.setOne(rule, state), selectedId: rule.ruleId })
  ),
  on(EntityAttributeRuleActions.updateRuleSuccess, (state: StateInterface, { rule }) =>
    ruleAdapter.setOne(rule, state)
  ),
  on(EntityAttributeRuleActions.deleteRuleSuccess, (state: StateInterface, { ruleId }) =>
    ruleAdapter.removeOne(ruleId, state)
  ),
  on(EntityAttributeRuleActions.selectRule, (state: StateInterface, { ruleId }: { ruleId: string }) => ({
    ...state,
    selectedId: ruleId,
  })),

  // Delete all rules for an attribute when the attributeType is changed, since the rules no longer apply to the new type.
  // And delete all rules for an attribute when the attribute is delete.
  on(
    EntityAttributeActions.completeSelectAttributeType,
    EntityAttributeActions.deleteAttributeSuccess,
    (state: StateInterface, action) => {
      const id =
        action.type === EntityAttributeActions.completeSelectAttributeType.type
          ? action.attribute.attributeId
          : action.attributeId;

      const ruleIds = getRuleIdsForAttribute(id, selectAll(state));
      return ruleAdapter.removeMany(ruleIds, state);
    }
  ),

  // Delete all rules for an entity when the entity is deleted.
  on(EntityActions.deleteEntitySuccess, (state: StateInterface, { entityId }) => {
    const ruleIds = getRuleIdsForEntity(entityId, selectAll(state));
    return ruleAdapter.removeMany(ruleIds, state);
  })
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific attribute action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return ruleReducer(state, action);
}
