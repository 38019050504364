import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonActions, LoggerService, UNTITLED } from '@surecloud/common';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { EntityAttributeFormatOptionsService } from '../../../services/format-options/format-options.service';
import { EntityAttributeFormatService } from '../../../services/format/format.service';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';
import { EntityAttributeFormatActions } from '../../actions/format.actions';

/**
 * Entity attribute format effect and side effects.
 * @export
 * @class EntityStateAttributeFormatEffects
 */
@Injectable({ providedIn: 'root' })
export class EntityStateAttributeFormatEffects {
  /**
   * When all entity data is loaded successfully,
   * Then populate the formats state.
   * @memberof EntityStateAttributeFormatEffects
   */
  loadEntityAttributeFormatList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readAllEntityDataSuccess, EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        EntityAttributeFormatActions.readFormatListSuccess({
          formatList: normalisedEntityList.formats,
        })
      )
    )
  );

  /**
   * When a user has changed the entity attribute type.
   * Then get a list of format options for that attribute type.
   * @memberof EntityStateAttributeFormatEffects
   */
  loadEntityAttributeFormatOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.selectAttributeType),
      switchMap(({ attributeType }) => this.formatOptionsService.getOptions(attributeType)),
      map((formatOptions) => EntityAttributeFormatActions.readFormatOptionsSuccess({ formatOptions }))
    )
  );

  /**
   * When a user clicks add format button.
   * Then create the entity attribute format on the API.
   * @memberof EntityStateAttributeFormatEffects
   */
  createEntityAttributeFormat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeFormatActions.createFormat),
      switchMap(({ format }) =>
        this.formatService.create(format).pipe(
          map((createdFormat) => EntityAttributeFormatActions.createFormatSuccess({ format: createdFormat })),
          catchError((error: unknown) => of(EntityAttributeFormatActions.createFormatFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user has confirmed the update on a format.
   * Then update the entity attribute format on the API.
   * @memberof EntityStateAttributeFormatEffects
   */
  updateEntityAttributeFormat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeFormatActions.completeUpdateFormat),
      switchMap(({ format }) =>
        this.formatService.update(format).pipe(
          map((updatedFormat) => EntityAttributeFormatActions.updateFormatSuccess({ format: updatedFormat })),
          catchError((error: unknown) => of(EntityAttributeFormatActions.updateFormatFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user has confirmed delete of a format.
   * Then delete the format on the API.
   * @memberof EntityStateAttributeFormatEffects
   */
  deleteEntityAttributeFormat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeFormatActions.completeDeleteFormat),
      switchMap(({ format }) =>
        this.formatService.delete(format).pipe(
          map(({ formatId }) => EntityAttributeFormatActions.deleteFormatSuccess({ formatId })),
          catchError((error: unknown) => of(EntityAttributeFormatActions.deleteFormatFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user clicks the delete format button.
   * Then open a delete entity attribute format confirmation modal.
   * @memberof EntityStateAttributeFormatEffects
   */
  enterDeleteEntityAttributeFormat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeFormatActions.startDeleteFormat),
      map(({ format }) => {
        const title = $localize`Confirm Delete Attribute Format`;
        const content = $localize`Are you sure you want to delete the ${format.type || UNTITLED} attribute format?`;

        const confirmAction = EntityAttributeFormatActions.completeDeleteFormat({ format });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels or confirms the deletion of a format.
   * Then close the confirm delete entity attribute format modal.
   * @memberof EntityStateAttributeFormatEffects
   */
  leaveDeleteEntityAttributeFormat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeFormatActions.completeDeleteFormat),
      map(CommonActions.closeConfirmationModal)
    )
  );

  /**
   * Logs any errors.
   * @memberof EntityAttributeRuleEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EntityAttributeFormatActions.deleteFormatFailure,
          EntityAttributeFormatActions.createFormatFailure,
          EntityAttributeFormatActions.updateFormatFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Entity Attribute Format', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of EntityStateAttributeFormatEffects.
   * @param {Actions} actions$ The store actions.
   * @param {EntityAttributeFormatOptionsService} formatOptionsService The entity attribute format options service.
   * @param {EntityAttributeFormatService} formatService The entity attribute format service.
   * @param {LoggerService} logger The application logging service.
   * @memberof EntityAttributeRuleEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly formatOptionsService: EntityAttributeFormatOptionsService,
    private readonly formatService: EntityAttributeFormatService,
    private readonly logger: LoggerService
  ) {}
}
