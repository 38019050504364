import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 *Base  Section Component
 *
 * @export
 * @abstract
 * @class BaseSectionComponent
 */
@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export abstract class BaseSectionComponent {
  @Input() sectionName = '';
  @Input() sectionId = '';
}
