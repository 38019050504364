<toolbar-feature [isSecondaryNavEnabled]="(isSecondaryNavEnabled$ | async) === true">
  <div class="sc-bg-main-bg homepage flex flex-column justify-content-center align-items-center">
    <div class="homepage__container">
      <div class="homepage__container-header">
        <h2 i18n class="sc-text-brand-500 sc-mb-4 sc-font-heading-xl 1">
          {{ greeting$ | async }}
        </h2>
        <p i18n class="sc-text sc-mb-24">
          {{ formattedDate }}
        </p>
      </div>
      <div class="flex flex-row justify-content-start flex-wrap homepage__sections-container">
        <ng-container *ngIf="priorityTasksFeatureFacade.loaded$ | async">
          <sc-section
            class="homepage__section"
            classname="sc-mb-0"
            key="priority-task-list"
            heading="Priorities"
            i18n-heading>
            <div header-content class="flex justify-content-center align-items-center">
              <button
                sc-text-button
                fillMode="text"
                themeColour="primary"
                i18n
                *ngIf="priorityTasksFeatureFacade.totalTasks$ | async as totalTasks"
                (click)="openToolbarTasks($event)">
                View all tasks ({{ totalTasks }})
              </button>
            </div>
            <sc-homepage-grid
              key="no-tasks"
              [rowData]="(priorityTasksFeatureFacade.tasksForGrid$ | async) || []"
              [columnDefs]="priorityTasksColDefs"
              heading="You’re all up to date"
              i18n-heading
              body="You don’t have any outstanding tasks"
              i18n-body
              (clicked)="recordCellClicked($event)">
            </sc-homepage-grid>
          </sc-section>
        </ng-container>

        <ng-container *ngIf="recentRecordsFeatureFacade.loaded$ | async">
          <sc-section
            key="recently-viewed"
            heading="Recently viewed"
            i18n-heading
            class="homepage__section"
            classname="sc-mb-0">
            <sc-homepage-grid
              key="empty-state-graphics"
              [rowData]="(recentRecordsFeatureFacade.recentRecordsForGrid$ | async) || []"
              [columnDefs]="recentRecordsColDefs"
              heading="No recently viewed records"
              i18n-heading
              body="Your recently viewed items will appear here to allow you to get to where you need quickly!"
              i18n-body
              (clicked)="recordCellClicked($event)">
            </sc-homepage-grid>
          </sc-section>
        </ng-container>

        <ng-container *ngIf="homepageApplicationsFeatureFacade.loaded$ | async">
          <sc-section
            key="my-applications"
            heading="My applications"
            i18n-heading
            class="homepage__section w-full"
            classname="sc-mb-0">
            <sc-homepage-grid
              key="no-applications"
              [activeTab]="(homepageApplicationsFeatureFacade.getActiveTab$ | async) || 0"
              [tabs]="(homepageApplicationsFeatureFacade.getTabs$ | async) || []"
              [rowData]="(homepageApplicationsFeatureFacade.homepageApplicationForGrid$ | async) || []"
              [columnDefs]="homepageApplicationsColDefs"
              (activeTabChange)="changeTab($event)"
              (clicked)="entityCellClicked($event)"
              heading="You don't have any apps installed"
              i18n-heading
              i18n-body>
            </sc-homepage-grid>
          </sc-section>
        </ng-container>
      </div>
    </div>
  </div>
</toolbar-feature>
