import { Injectable } from '@angular/core';
import { HomepagePriorityTaskApiInterface } from '@surecloud/api-types';
import { LoggerService } from '@surecloud/common';
import {
  NormalisedPriorityTasks,
  getNormalisedPriorityTasksError,
  isNormalisedPriorityTasksGuard,
} from './normalise-get-priority-tasks.validation';

/**
 * Service encapsulating the schema and configuration for flattening the get PriorityTasks API response.
 *
 * @export
 * @class NormaliseGetPriorityTasksService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetPriorityTasksService {
  /**
   * Creates an instance of NormaliseGetPriorityTasksService
   *
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetPriorityTasksService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the PriorityTasks data from the PriorityTasks API response.
   *
   * @param {HomepagePriorityTaskApiInterface} priorityTasks The PriorityTasks data structure as it comes from the API.
   * @return {NormalisedPriorityTasks} UI PriorityTasks data structure.
   * @memberof NormaliseGetPriorityTasksService
   */
  extract(priorityTasks: HomepagePriorityTaskApiInterface): NormalisedPriorityTasks {
    const tasks = { priorityTasks };
    if (!isNormalisedPriorityTasksGuard(tasks)) {
      this.logger.logEvent(
        'PriorityTasks Feature',
        'Normalise PriorityTasks Data',
        getNormalisedPriorityTasksError(priorityTasks)
      );

      throw new Error('The result of the normalise PriorityTasks does not match the expected format.');
    }

    return tasks;
  }
}
