<label i18n class="sc-form-label" for="filePicker">{{ label }}</label>
<div
  [ngClass]="{
    disabled: control.disabled,
    'sc-file-picker': !readonly
  }">
  <div class="sc-mr-4">
    <kendo-fileselect
      #filePicker
      *ngIf="!readonly"
      [multiple]="multiple"
      [showFileList]="false"
      [restrictions]="fileRestrictions"
      [formControl]="control"
      [disabled]="control.disabled"
      (select)="onSelectEvent($event)">
      <kendo-upload-messages (select)="(selectFileText)" i18n-dropFilesHere></kendo-upload-messages>
    </kendo-fileselect>
    <div *ngIf="control.value?.length && !invalidFile" class="sc-file-picker__file-list flex flex-column">
      <ng-container *ngFor="let file of control.value; trackBy: trackByFunction">
        <sc-file-item
          [name]="file.name || file.fileName"
          [status]="file.uploadStatus || file.state"
          [virusScanStatus]="file.virusScanStatus"
          [readonly]="readonly"
          [size]="file.size"
          [hasDocumentId]="!!file.documentId"
          (download)="fileDownload.emit(file)"
          (remove)="onRemoveFile(file)"></sc-file-item>
      </ng-container>
    </div>
  </div>
</div>
<kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
