<ng-container>
  <design-v2-global-nav
    [isStandalone]="(toolbarFacade.globalNavIsStandalone$ | async) || false"
    [canViewGlobalMenu]="(toolbarFacade.canViewGlobalMenu$ | async) || false"
    [canViewUserMenu]="(toolbarFacade.canViewUserMenu$ | async) || false"
    [canViewTasks]="(toolbarFacade.canViewTasks$ | async) || false"
    [activeRouteLabel]="(activeRouteLabel$ | async) || ''"
    [numberOfNewTasks]="(taskFacade.totalNewTasks$ | async) || 0"
    [globalNavLinks]="toolbarFacade.navItems$ | async"
    (helpClicked)="openHelpCentre()"
    (taskClicked)="taskListToggle($event)"
    (userClicked)="userMenu.toggle($event)"
    *ngIf="isDesignV2Enabled; else globalNavDesignV1">
  </design-v2-global-nav>
  <ng-template #globalNavDesignV1>
    <sc-global-nav
      [isStandalone]="(toolbarFacade.globalNavIsStandalone$ | async) || false"
      [canViewGlobalMenu]="(toolbarFacade.canViewGlobalMenu$ | async) || false"
      [canViewUserMenu]="(toolbarFacade.canViewUserMenu$ | async) || false"
      [canViewTasks]="(toolbarFacade.canViewTasks$ | async) || false"
      [activeRouteLabel]="(activeRouteLabel$ | async) || ''"
      [numberOfNewTasks]="(taskFacade.totalNewTasks$ | async) || 0"
      [globalNavLinks]="toolbarFacade.navItems$ | async"
      (helpClicked)="openHelpCentre()"
      (taskClicked)="taskListToggle($event)"
      (userClicked)="userMenu.toggle($event)">
    </sc-global-nav>
  </ng-template>
  <toolbar-feature-tasks [isDesignV2Enabled]="isDesignV2Enabled" *ngIf="toolbarFacade.canViewTasks$ | async" #taskList>
  </toolbar-feature-tasks>
  <toolbar-feature-user-menu [isDesignV2Enabled]="isDesignV2Enabled" #userMenu></toolbar-feature-user-menu>
</ng-container>
