import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';

/**
 * Makes the global toggle attribute configuration
 */
export const GLOBAL_TOGGLE_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Toggle,
  key: EntityAttributeTypeEnum.Toggle,
  type: EntityAttributeTypeEnum.Toggle,
  label: $localize`Toggle`,
  uniqueSections: [],
});
