import {
  DRAG_ROW_COLUMN,
  NAVIGATE_ROW_COLUMN,
  SCGridSchemaTypeEnum,
  SELECT_ROW_COLUMN,
  isArray,
} from '@surecloud/common';
import { Column, ExcelExportParams, ProcessCellForExportParams } from 'ag-grid-community';
import { format } from 'date-fns';

/**
 * AdvancedGridCellWithDisplayProperty
 */
type AdvancedGridCellWithDisplayProperty = { display: string };

/**
 * Handle lists with a consistent display property
 * @param {({value: AdvancedGridCellWithDisplayProperty[] | ''})} value - the value to handle
 * @return {string} - the string value
 */
const handleListsWithDisplayProperty = (value: AdvancedGridCellWithDisplayProperty[] | ''): string => {
  if (isArray(value)) {
    return value.map((item: AdvancedGridCellWithDisplayProperty) => item.display).join(', ');
  }
  return '';
};

/**
 * Process Cell Callback
 * @param {ProcessCellForExportParams} params - the cell export parameters
 * @return {string} - the processed cell value
 */
export const processCellCallback = (params: ProcessCellForExportParams): string => {
  const colDef = params.column.getColDef();
  const columnType = colDef.type;
  switch (columnType) {
    case SCGridSchemaTypeEnum.MultipleUserAvatar:
    case SCGridSchemaTypeEnum.SingleUserAvatar:
    case SCGridSchemaTypeEnum.TagsReadonly:
    case SCGridSchemaTypeEnum.Document: {
      return handleListsWithDisplayProperty(params.value);
    }
    default: {
      return params.value;
    }
  }
};

/**
 * Advanced Grid Excel Export Params
 * Configuration for exporting data to Excel via AgGrid.
 */
export const ADVANCED_GRID_EXCEL_EXPORT_PARAMS: ExcelExportParams = {
  processCellCallback,
};

/**
 * The default sheet name.
 */
export const ADVANCED_GRID_EXCEL_EXPORT_SHEET_NAME = $localize`Sheet 1`;

/**
 * Excluded column types for Excel export
 */
export const ADVANCED_GRID_EXCEL_EXPORT_EXCLUDED_COLUMN_TYPES = [
  SELECT_ROW_COLUMN,
  NAVIGATE_ROW_COLUMN,
  DRAG_ROW_COLUMN,
];

/**
 * Make the export file name for the Advanced Grid
 * @param {string} exportName - the name of the export
 * @return {string} - the export file name
 */
export const makeAdvancedGridExcelExportFileName = (exportName: string): string => {
  const displayDate = format(new Date(), 'dd-MM-yyyy_HH-mm-ss');
  const name = `${exportName.toLowerCase().split(' ').join('_')}_`;
  return $localize`aurora_${name}export_${displayDate}`;
};

/**
 * Function to create Excel export options for the Advanced Grid
 * @param {Column[]} columns - the columns to export
 * @param {string} exportName - the name of the export
 * @param {ExcelExportParams} defaultExcelExportParams - the default export options
 * @param {string[]} excludedColumnTypes - the column types to exclude from the export
 * @return {ExcelExportParams} - the export options
 */
export const createAdvancedGridExcelExportOptions = (
  columns: Column[],
  exportName: string,
  defaultExcelExportParams: ExcelExportParams,
  excludedColumnTypes: string[]
): ExcelExportParams => {
  const includedColumns = columns.reduce<Column[]>((columnsForExport: Column[], column: Column) => {
    const excluded = excludedColumnTypes.indexOf(column.getColId()) > -1;
    if (!excluded) {
      columnsForExport.push(column);
    }
    return columnsForExport;
  }, []);
  const fileName = makeAdvancedGridExcelExportFileName(exportName);
  return {
    ...defaultExcelExportParams,
    fileName,
    columnKeys: includedColumns,
    onlySelected: true,
    sheetName: ADVANCED_GRID_EXCEL_EXPORT_SHEET_NAME,
  };
};
