import { ColDef } from 'ag-grid-community';
import { GridTextCellEditorComponent } from './cell-editors/grid-text-cell-editor.component';
import { SMALL_COLUMN_WIDTH } from './global-column-types';

export const DEFAULT_COLUMN_DEF: ColDef = {
  editable: false,
  minWidth: SMALL_COLUMN_WIDTH,
  resizable: true, // dragdable column width
  wrapHeaderText: true,
  autoHeaderHeight: true,
  flex: 1,
  cellStyle: {
    display: 'block', // enables ellipses + top-left alignment
  },
  cellEditor: GridTextCellEditorComponent,
};
