<ng-container [formGroup]="parentFormGroup">
  <kendo-formfield class="block" showErrors="initial" [ngClass]="{ 'sc-mb-20': marginBottom }">
    <kendo-label
      [text]="label"
      class="sc-label flex-column"
      [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
      <kendo-textbox
        scCommonE2eHook="{{ testhook }}:form:textbox"
        [formControlName]="controlName"
        [ngClass]="{
          'sc-input--readonly': readonly && !parentFormGroup.controls[controlName].disabled
        }"
        [style.width.px]="staticWidth"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [clearButton]="clearButton"
        [maxlength]="maxLength"
        size="none"
        (blur)="blurred.emit()">
        <ng-template [ngSwitch]="prefix" kendoTextBoxPrefixTemplate>
          <span *ngSwitchCase="'search'" class="sc-input__prefix-icon sc-input__prefix-icon--search">
            <sc-icon key="search" size="x-large" colour="grey-600"></sc-icon>
          </span>
          <span i18n *ngSwitchCase="'email'" class="sc-input__prefix-icon">
            <sc-icon key="email" size="x-large" colour="grey-600"></sc-icon>
          </span>
          <span i18n *ngSwitchCase="'url'" class="sc-input__prefix-url sc-py-8 sc-px-12 h-full">URL</span>
        </ng-template>
      </kendo-textbox>
      <div class="flex">
        <div
          i18n
          *ngIf="enableSuccessValidationStyle && parentFormGroup.controls[controlName].valid"
          class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2 flex">
            <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
          </div>
          Success
        </div>
        <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-4">
          <div class="sc-pt-2 sc-pl-2 flex">
            <sc-icon key="alert-circle-outline" size="medium" colour="red-500" class=""></sc-icon>
          </div>
        </div>
        <!-- If form error for this control, show the error -->
        <kendo-formhint
          *ngIf="parentFormGroup.controls[controlName]?.errors; else showDescription"
          scCommonE2eHook="{{ testhook }}:form:error-hint">
          <span class="sc-text-error">
            <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
            {{ parentFormGroup.controls[controlName].getError('message') }}</span
          ></kendo-formhint
        >
        <!-- Else show the description -->
        <ng-template #showDescription>
          <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
        </ng-template>
      </div>
    </kendo-label>
  </kendo-formfield>
</ng-container>
