/* eslint-disable max-classes-per-file */
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { SectionRendererConfigInterface } from '@surecloud/common';
import { GlobalAttributeUtils } from './global-attribute-utils.class';

export const ATTRIBUTE_UTILITY = new InjectionToken('Token for the Attribute Utility class');

/**
 * The root module for the Entity feature
 * @export
 * @class EntityModule
 */
@NgModule({
  imports: [],
  declarations: [],
  providers: [],
})
export class EntityAttributeModule {
  static forRoot(attributeConfigs: SectionRendererConfigInterface[]): ModuleWithProviders<EntityAttributeModule> {
    const attributeUtility = new GlobalAttributeUtils(attributeConfigs);
    return {
      ngModule: EntityAttributeModule,
      providers: [
        {
          provide: ATTRIBUTE_UTILITY,
          useValue: attributeUtility,
        },
      ],
    };
  }
}
