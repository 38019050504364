import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RequestIdInterface } from '@surecloud/common';
import { NormalisedQuestionSetList } from '../../services/normalise-get-question-sets/normalise-get-question-sets.validation';
import { QuestionSetInterface } from '../models/question-set/question-set.models';

/**
 * Question Set API actions map.
 */
const QUESTION_SET_API_ACTIONS_MAP = {
  'Create Question Set': props<{ entityId: string } & RequestIdInterface>(),
  'Create Question Set Success': props<{ questionSet: QuestionSetInterface }>(),
  'Create Question Set Failure': props<{ error: string }>(),

  'Read Question Set': props<{ questionSetId: string }>(),
  'Read Question Set Data Success': props<{ normalisedQuestionSetList: NormalisedQuestionSetList }>(),
  'Read Question Set Success': props<{ questionSet: QuestionSetInterface }>(),
  'Read Question Set Failure': props<{ error: string }>(),

  'Read Question Sets': props<{ entityId: string }>(),
  'Read Question Sets Data Success': props<{ normalisedQuestionSetList: NormalisedQuestionSetList }>(),
  'Read Question Sets Success': props<{ questionSets: QuestionSetInterface[] }>(),
  'Read Question Sets Failure': props<{ error: string }>(),

  'Delete Question Set': props<{ questionSet: QuestionSetInterface }>(),
  'Delete Question Set Success': props<{ questionSetId: string }>(),
  'Delete Question Set Failure': props<{ error: string }>(),

  'Update Question Set': props<{ questionSet: QuestionSetInterface }>(),
  'Update Question Set Success': props<{ questionSet: QuestionSetInterface }>(),
  'Update Question Set Failure': props<{ error: string }>(),

};

/**
 * Question Set API actions.
 *
 * @export
 */
export const QuestionSetApiActions = createActionGroup({
  source: 'Question Set/API',
  events: QUESTION_SET_API_ACTIONS_MAP,
});

/**
 * Question Set Effects actions map.
 */
const QUESTION_SET_EFFECTS_ACTIONS_MAP = {
  Reset: emptyProps(),
  'Select Question Set': props<{ questionSetId: string | undefined }>(),
  'Navigate to Question Set': props<{ questionSetId: string }>(),
  'Read Entity': props<{ entityId: string }>(),
};

/**
 * Question Set Effects actions.
 *
 * @export
 */
export const QuestionSetEffectsActions = createActionGroup({
  source: 'Question Set/Effects',
  events: QUESTION_SET_EFFECTS_ACTIONS_MAP,
});
