<div class="flex flex-column homepage-grid w-full">
  <ng-container *ngIf="rowData.length > 0; else noRowData">
    <div class="sc-pt-4 flex-grow-1">
      <sc-advanced-grid
        [height]="height"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [tabs]="tabs"
        [footer]="false"
        [canFilter]="false"
        [canDelete]="false"
        [activeTab]="activeTab"
        (cellClicked)="clicked.emit($event)"
        (activeTabChange)="activeTabChange.emit($event)">
      </sc-advanced-grid>
    </div>
  </ng-container>
  <ng-template #noRowData>
    <div class="flex flex-column justify-content-center align-items-center homepage-grid__no-row-data flex-grow-1">
      <sc-icon *ngIf="key" class="sc-mb-16" [key]="key" [height]="111" [width]="140"></sc-icon>
      <p i18n class="sc-font-heading-md-semi-bold sc-text-brand-500 sc-mb-8">{{ heading }}</p>
      <p i18n class="sc-font-information-body sc-text-lighter">{{ body }}</p>
    </div>
  </ng-template>
</div>
