import { createAction, props } from '@ngrx/store';
import { LogicConditionType } from '../../models/logic-condition.models';

export const LogicConditionActions = {
  /**
   * LogicCondition Actions Related to the internals of LogicCondition
   * [LogicCondition]
   */
  selectLogicCondition: createAction(
    '[Logic Condition] Select Logic Condition',
    props<{ conditionId: string | undefined }>()
  ),

  /**
   * LogicCondition Actions Related to the LogicCondition API
   * [LogicCondition/API]
   */
  readLogicConditionList: createAction('[Logic Condition/API] Read Logic Condition List'),
  readLogicConditionListSuccess: createAction(
    '[Logic Condition/API] Read Logic Condition List Success',
    props<{ logicConditionList: LogicConditionType[] }>()
  ),
  readLogicConditionListFailure: createAction(
    '[Logic Condition/API] Read Logic Condition List Failure',
    props<{ error: string }>()
  ),

  createNewLogicConditionSuccess: createAction(
    '[Logic Condition/API] Create Logic Condition Success',
    props<{ conditionId: string; workflowId: string; triggerId: string }>()
  ),
  createNewLogicConditionFailure: createAction(
    '[Logic Condition/API] Create Logic Condition Failure',
    props<{ error: string }>()
  ),

  updateLogicCondition: createAction(
    '[Logic Condition/API] Update Logic Condition',
    props<{ logicCondition: LogicConditionType }>()
  ),
  updateLogicConditionSuccess: createAction(
    '[Logic Condition/API] Update Logic Condition Success',
    props<{ partialLogicCondition: Partial<LogicConditionType> }>()
  ),
  updateLogicConditionFailure: createAction(
    '[Logic Condition/API] Update Logic Condition Failure',
    props<{ error: string }>()
  ),

  deleteLogicCondition: createAction('[LogicCondition/API] Delete Logic Condition', props<{ conditionId: string }>()),
  deleteLogicConditionSuccess: createAction(
    '[Logic Condition/API] Delete Logic Condition Success',
    props<{ conditionId: string }>()
  ),
  deleteLogicConditionFailure: createAction(
    '[Logic Condition/API] Delete Logic Condition Failure',
    props<{ error: string }>()
  ),
};
