import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';
import { TagComponent } from '../tag/tag.component';

/**
 * Surecloud No Type Component.
 *
 * @export
 * @class QuestionComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-question',
  standalone: true,
  imports: [LabelModule, TextBoxModule, InputsModule, SvgIconsModule, TagComponent, CommonModule],
  styleUrls: ['./question.component.scss'],
  templateUrl: './question.component.html',
})
export class QuestionComponent {
  /**
   * Sets the text value for the label.
   *
   * @memberof QuestionComponent
   */
  @Input() label = '';

  /**
   * Description when there isn't a selected QuestionSet.
   *
   * @memberof QuestionComponent
   */
  unselectedQuestionSetDescription = $localize`Select a Question Set that will define the Assessment Type`;

  /**
   * Description when there is a selected QuestionSet.
   *
   * @memberof QuestionComponent
   */
  selectedQuestionSetDescription = $localize`Questions will be displayed from the following Question Set`;

  /**
   * Sets the text value for the tag.
   *
   * @memberof QuestionComponent
   */
  @Input() tag = '';
}
