import { createAction, props } from '@ngrx/store';
import { EntityAttributeRuleOptionInterface } from '../../services/rule-options/rule-options.service.interface';
import {
  EntityAttributeCreateRuleInterface,
  EntityAttributeRuleInterface,
  EntityAttributeRuleUpdatedInterface,
} from '../models/rule/rule.models';

export const EntityAttributeRuleActions = {
  /**
   * User actions.
   */
  cancelUpdateRule: createAction('[Entity Attribute Page] Cancel Update Entity Attribute Rule'),
  completeDeleteRule: createAction(
    '[Entity Attribute Page] Complete Delete Entity Attribute Rule',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),
  completeUpdateRule: createAction(
    '[Entity Attribute Page] Complete Update Entity Attribute Rule',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),
  createRule: createAction(
    '[Entity Attribute Page] Create Entity Attribute Rule',
    props<{ rule: EntityAttributeCreateRuleInterface }>()
  ),
  readRuleOptionsSuccess: createAction(
    '[Entity Attribute Page] Update Entity Attribute Rule Options',
    props<{
      ruleOptions: EntityAttributeRuleOptionInterface[];
    }>()
  ),
  startDeleteRule: createAction(
    '[Entity Attribute Page] Start Delete Entity Attribute Rule',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),
  startUpdateRule: createAction(
    '[Entity Attribute Page] Start Update Validation Rule',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),
  selectRule: createAction('[Entity Attribute Page] Select Entity Attribute Rule', props<{ ruleId: string }>()),
  updateRule: createAction(
    '[Entity Attribute Rule Modal] Update Validation Rule',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),

  /**
   * API actions.
   */
  createRuleFailure: createAction('[Entity/API] Create Entity Attribute Rule Failure', props<{ error: string }>()),
  createRuleSuccess: createAction(
    '[Entity/API] Create Entity Attribute Rule Success',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),
  deleteRuleFailure: createAction('[Entity/API] Delete Entity Attribute Rule Failure', props<{ error: string }>()),
  deleteRuleSuccess: createAction(
    '[Entity/API] Delete Entity Attribute Rule Success',
    props<EntityAttributeRuleUpdatedInterface>()
  ),
  readRuleListSuccess: createAction(
    '[Entity/API] Read Entity Attribute Rule List Success',
    props<{ ruleList: EntityAttributeRuleInterface[] }>()
  ),
  updateRuleFailure: createAction('[Entity/API] Update Entity Attribute Rule Failure', props<{ error: string }>()),
  updateRuleSuccess: createAction(
    '[Entity/API] Update Entity Attribute Rule Success',
    props<{ rule: EntityAttributeRuleInterface }>()
  ),
};
