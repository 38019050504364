import { IntegrationInterface, MORE_LABEL } from '@surecloud/common';
import { ScTagTheme } from '../tag/tag.constants';

// 36 is the limit of characters
// that can be displayed in a single row
// including spaces and characters
const CATEGORY_ROW_SYMBOLS_LIMIT = 36;

// 2 is the length of the space symbol
const CATEGORY_SPACE_SYMBOL_LENGTH = 2;

/**
 * Integration Tag
 */
export type IntegrationTag = {
  theme: ScTagTheme;
  name: string;
};

/**
 * Integration Categories
 * @type {IntegrationInterface['categories'] | IntegrationTag[]}
 */
export type IntegrationCategories = IntegrationInterface['categories'] | IntegrationTag[];

/**
 * Get visible category tags
 * @param {IntegrationCategories} categories - categories
 * @param {boolean} multiRow - whether the categories should be displayed in multi row
 * @return {number} - number of visible category tags
 */
export const getVisibleCategoryTags = (categories: IntegrationCategories, multiRow = false): number => {
  const symbolLimit = multiRow ? CATEGORY_ROW_SYMBOLS_LIMIT * 2 : CATEGORY_ROW_SYMBOLS_LIMIT;
  let categoriesCount = 0;
  let currentChars = 0;

  categories.forEach((category) => {
    const categoryLabelLength = category.name.length + CATEGORY_SPACE_SYMBOL_LENGTH;

    if (currentChars + categoryLabelLength >= symbolLimit) {
      return;
    }

    currentChars += categoryLabelLength;
    categoriesCount += 1;
  });

  // if extra categories label is displayed
  // and the categories characters exceed the limit
  // then we need to reduce the number of categories
  if (
    !multiRow &&
    categoriesCount > 1 &&
    categoriesCount < categories.length &&
    currentChars + MORE_LABEL.length >= symbolLimit
  ) {
    categoriesCount -= 1;
  }

  return categoriesCount;
};

/**
 * Check if categories should be displayed in multi row
 * @param {IntegrationCategories} categories - categories to check
 * @return {boolean} - true if categories should be displayed in multi row
 */
export const isCategoriesMultiRow = (categories: IntegrationCategories): boolean => {
  const labelsLength = categories.reduce((acc, category) => acc + category.name.length, 0);
  const visibleCategoriesCount = getVisibleCategoryTags(categories);
  const extraLabelLength =
    visibleCategoriesCount === categories.length ? 0 : categories.length - visibleCategoriesCount + MORE_LABEL.length;

  return (
    labelsLength + categories.length * CATEGORY_SPACE_SYMBOL_LENGTH + extraLabelLength > CATEGORY_ROW_SYMBOLS_LIMIT
  );
};
