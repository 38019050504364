import { InputTextComponent } from '../../input-text/input-text.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { readOnlyFormControlComponentCreator } from '../creators/readonly-form-control-creator.config';

/**
 * Control configuration for SEQUENCE renderer component
 *
 */
export const SEQUENCE_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: InputTextComponent,
  creatorFn: readOnlyFormControlComponentCreator,
};
