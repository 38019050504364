import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import ChartModuleMore from 'highcharts/highcharts-more';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import HCWordCould from 'highcharts/modules/wordcloud';
import { defaultChartConfig } from './chart.constants';

// Set up regressions
declare function require(name: string): (highcharts: typeof Highcharts) => void;

// We have to import it using `require` since creating our own type definitions didn't work
// eslint-disable-next-line sonarjs/no-use-of-empty-return-value
const HighchartsRegression = require('highcharts-regression'); // NOSONAR
// eslint-disable-next-line sonarjs/no-use-of-empty-return-value
const HighChartsHeatmap = require('highcharts/modules/heatmap.js'); // NOSONAR

HighchartsRegression(Highcharts);
HighChartsHeatmap(Highcharts);
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
HCWordCould(Highcharts);

// Set our default options/config/styles in highcharts before it's assigned.
// NB: This function needs to be called AFTER the the above plugins otherwise the default configuration is overwritten by the above.
Highcharts.setOptions(defaultChartConfig);

/**
 * Wrapper component around [High Charts](https://www.highcharts.com/demo/column-basic  https://github.com/highcharts/highcharts-angular).
 * @example
 * @export
 * @class ChartColumnComponent
 */
@Component({
  selector: 'sc-chart',
  standalone: true,
  styleUrls: ['./chart.component.scss'],
  templateUrl: './chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HighchartsChartModule],
  encapsulation: ViewEncapsulation.None,
})
export class ChartComponent {
  /**
   * The reference to the highchart chart.
   * @type {Highcharts.Chart}
   * @memberof ChartComponent
   */
  public chartRef!: Highcharts.Chart;

  /**
   * The Highcharts Library.
   * @type {typeof Highcharts}
   * @memberof ChartComponent
   */
  public Highcharts: typeof Highcharts = Highcharts;

  /**
   * Whenever we update the chart be sure to change this update flag to true.
   * High Charts will then [run change detection for the chart](https://www.npmjs.com/package/highcharts-angular#options-details).
   * @memberof ChartComponent
   */
  public update = false;

  /**
   * All the chart options and config.
   * @type {Highcharts.Options}
   * @memberof ChartComponent
   */
  private options!: Highcharts.Options;

  /**
   * The chart options
   * @type {Highcharts.Options}
   * @memberof ChartComponent
   */
  get chartOptions(): Highcharts.Options {
    return this.options;
  }

  /**
   * Setting the chart options, we need to add the color to the series.
   * Since when adding series to an already created chart the colors are sometimes duplicated.
   * @type {Highcharts.Options}
   * @memberof ChartComponent
   */
  @Input() set chartOptions(value: Highcharts.Options) {
    const seriesWithColour = value.series?.map((series, index) => ({
      ...series,
      color: ('color' in series && series.color) || value.colors?.[index],
    }));

    this.options = {
      ...value,
      series: seriesWithColour,
    };
    this.update = true;
  }

  /**
   *  The height of the chart.
   *  When the parent height changes reflow/resize the chart.
   * @memberof ChartComponent
   */
  chartHeight = 400;

  get height(): number {
    return this.chartHeight;
  }

  @Input() set height(value: number) {
    this.chartHeight = value;
    this.update = true;
  }

  /**
   *  The width of the chart.
   *  When the parent width changes reflow/resize the chart.
   * @memberof ChartComponent
   */
  chartWidth = 400;

  get width(): number {
    return this.chartWidth;
  }

  @Input() set width(value: number) {
    this.chartWidth = value;
    this.update = true;
  }

  /**
   * When a user clicks a point on the chart.
   * Then emit the clicked point.
   * @type {EventEmitter<Highcharts.Point>}
   * @memberof ChartComponent
   */
  @Output()
  pointSelected: EventEmitter<Highcharts.Point> = new EventEmitter();

  /**
   * When a series point has been selected.
   * Then emit an event with the point that was selected.
   * @private
   * @param {Highcharts.SeriesClickEventObject} event The HighChart click point event with additional point data.
   * @memberof ChartComponent
   */
  private selectPoint(event: Highcharts.SeriesClickEventObject): void {
    this.pointSelected.emit(event.point);
  }

  /**
   * Call back method on the highchart so we can store the chart reference.
   * We add a click event to any point on the series so that we can emit an event when a chart point is clicked.
   * @param {Highcharts.Chart} chart The instance of the chart.
   * @type {Highcharts.ChartCallbackFunction}
   * @memberof ChartComponent
   */
  public chartCallback: Highcharts.ChartCallbackFunction = (chart: Highcharts.Chart) => {
    this.chartRef = chart;
    this.chartRef.update({
      plotOptions: {
        series: {
          events: {
            click: (event) => {
              this.selectPoint(event);
            },
          },
        },
      },
    });

    // We disable the tooltips for a Heatmap chart type.
    if (this.chartRef.options.chart?.type === 'heatmap' && this.chartRef.options.tooltip) {
      this.chartRef.options.tooltip.enabled = false;
    }
  };
}
