import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropdownComponentOptionInterface, selectOptionsType } from '@surecloud/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { SelectDropdownComponent } from '../../../select-dropdown/select-dropdown.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SelectDropdownComponent],
  providers: [FormGroupDirective],
  selector: 'sc-grid-dropdown-renderer',
  templateUrl: './grid-dropdown-renderer.component.html',
  styleUrls: ['./grid-dropdown-renderer.component.scss'],
})
export class GridDropdownRendererComponent implements OnInit, OnDestroy, ICellRendererAngularComp {
  /**
   * Ag Grids API
   * @type {(GridApi | undefined)} the AG Grid api
   * @memberof GridDropdownRendererComponent
   */
  agGridApi: GridApi | undefined;

  /**
   * Reference to the column id of the input data
   * @type {(string| undefined)}
   * @memberof GridDropdownRendererComponent
   */
  colId: string | undefined;

  /**
   * Cell renderer params
   * @type {*}
   * @memberof GridDropdownRendererComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;

  /**
   * The list of options to show in the select dropdown.
   * @memberof GridDropdownRendererComponent
   */
  selectOptions: SelectDropdownComponentOptionInterface[] = [];

  /**
   * Checkbox control
   * @type {FormControl<boolean>}
   * @memberof GridDropdownRendererComponent
   */
  control: FormControl = new FormControl('');

  /**
   * Form group with select control used for SelectDropdown component
   * @memberof GridDropdownRendererComponent
   */
  dropdownFormGroup = new FormGroup({ control: this.control });

  /**
   * Property to complete and unsubscribe control changes when component be destroyed
   * @private
   * @memberof GridDropdownRendererComponent
   */
  private destroy$ = new Subject<boolean>();

  /**
   * Handle with control changes
   * @memberof GridDropdownRendererComponent
   */
  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((newValue) => this.setParams(newValue));
  }

  /**
   * Handle to complete subject destroy$
   * @memberof GridDropdownRendererComponent
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Handles the ag init and sets the value of the checkbox initial value
   * @param {ICellRendererParams} params cell renderer params
   * @memberof GridDropdownRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    this.agGridApi = params.api;
    this.params = params;
    this.params.colDef.editable = false;
    this.colId = params.column?.getColId();

    this.setOptions();
    const currentValue = this.selectOptions.find((option) => {
      const value = option.text;
      return value === this.params.value;
    });
    this.control.setValue(currentValue?.value || '');
  }

  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   * @memberof GridDropdownRendererComponent
   * @return {boolean} isRefresh
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }

  /**
   * Set params based on control changes
   * @param {boolean} newValue new selected value
   * @memberof GridDropdownRendererComponent
   */
  setParams(newValue: string): void {
    this.params.newValue = newValue;
    const isChangeRequired = this.params.colDef?.valueSetter ? this.params.colDef.valueSetter(this.params) : false;
    if (!isChangeRequired) {
      return;
    }

    this.params.value = newValue;
    this.setOptions();
    const { colId } = this.params.column;
    this.params.node.setDataValue(colId, newValue);
    this.params.node.setSelected(newValue);
  }

  /**
   * Sets dropdown options
   * @private
   * @memberof GridDropdownRendererComponent
   */
  private setOptions(): void {
    const values = this.params.colDef.cellEditorParams?.values || [];
    this.selectOptions = values.map((value: selectOptionsType) => {
      const formatter = this.params.colDef?.cellEditorParams?.formatValue;
      const text = formatter ? formatter(value) : value;

      return {
        value,
        text,
      };
    });
  }
}
