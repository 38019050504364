import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { FeatureFlagEffects } from './+state/effects/feature-flag.effects';
import { FEATURE_FLAG_FEATURE_KEY, reducer } from './+state/reducers/feature-flag.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_FLAG_FEATURE_KEY, reducer)],
  providers: [EffectsBootstrapProvider.provide([FeatureFlagEffects])],
})
export class FeatureFlagModule {}
