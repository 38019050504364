import { Dictionary } from '@ngrx/entity';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

import { RequestErrorInterface } from '@surecloud/common';

import { REQUEST_ERROR_FEATURE_KEY, StateInterface, requestErrorAdapter } from '../reducers/request-error.reducer';

/**
 * Lookup the 'RequestError' feature state managed by NgRx
 */
export const getRequestErrorState = createFeatureSelector<StateInterface>(REQUEST_ERROR_FEATURE_KEY);

const { selectEntities } = requestErrorAdapter.getSelectors();

/**
 * Standard state selectors
 */
export const getErrorEntities = createSelector(getRequestErrorState, (state: StateInterface) => selectEntities(state));

/**
 * Get the request error by request id
 * @param {string} requestId The unique id of a request
 * @return {RequestErrorInterface | undefined} The error entity if it exists.
 */
export const getRequestErrorById = (
  requestId: string
): MemoizedSelector<
  object,
  RequestErrorInterface | undefined,
  (s1: Dictionary<RequestErrorInterface>) => RequestErrorInterface | undefined
> => createSelector(getErrorEntities, (error) => error[requestId]);
