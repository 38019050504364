import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskSummaryInterface } from '@surecloud/common';
import { TaskFeatureFacade } from '@surecloud/task-state';
import { first } from 'rxjs';
import { ToolbarTasksPageActions } from '../../+state/actions/toolbar-task.actions';

/**
 * Toolbar Tasks Component.
 * Parent to all dumb Task Components.
 * @export
 * @class ToolbarTasksComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'toolbar-feature-tasks',
  templateUrl: './toolbar-tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarTasksComponent implements AfterViewInit {
  /**
   * Is Design Version 2 Feature enabled.
   * @memberof ToolbarTasksComponent
   */
  @Input()
  public isDesignV2Enabled = false;

  /**
   * Creates an instance of ToolbarTasksComponent.
   * @param {TaskFeatureFacade} facade The Task State feature facade.
   * @memberof ToolbarTasksComponent
   */
  constructor(public facade: TaskFeatureFacade) {}

  /**
   * When the component is started.
   * Then dispatch an action to get the Tasks.
   * @memberof ToolbarTasksComponent
   */
  ngAfterViewInit(): void {
    this.facade.dispatch(ToolbarTasksPageActions.enter());
  }

  /**
   * When a user has selected on a Task.
   * Then mark that task as read.
   * And dispatch ToolbarTasksPageActions.select.
   * @param {TaskSummaryInterface} taskSummary The Task that was selected.
   * @memberof ToolbarTasksComponent
   */
  selected(taskSummary: TaskSummaryInterface): void {
    const { id } = taskSummary;
    this.facade.dispatch(ToolbarTasksPageActions.toggle({ open: false }));
    this.facade
      .getTaskById$(id)
      .pipe(first())
      .subscribe((task) => this.facade.dispatch(ToolbarTasksPageActions.select({ task })));
  }

  /**
   * When the Task List has been toggled to show or hide.
   * Then update the service that controls the visibility of the Task List component.
   * And dispatch an action to get the latest tasks if it is about to be shown.
   * @param {Event} event The click event on the Task List Icon.
   * @memberof ToolbarTasksComponent
   */
  toggle(event: Event): void {
    event.stopPropagation();
    this.facade.dispatch(ToolbarTasksPageActions.toggle({}));
  }
}
