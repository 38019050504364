<div class="grid grid-nogutter justify-content-evenly sc-py-50 sc-font-heading-lg">
  <div class="sc-error-details__tech-details">
    <h2 class="sc-font-heading-lg sc-mb-20 uppercase" i18n>Technical Details</h2>
    <p
      tabindex="0"
      class="flex align-items-center sc-cursor-pointer"
      (click)="toggleError()"
      (keydown.enter)="toggleError()"
      i18n>
      {{ showError ? 'Hide' : 'Show ' }} details for this error
      <sc-icon
        [key]="showError ? 'chevron-up' : 'chevron-down'"
        [width]="14"
        size="x-large"
        colour="brand-500"
        class="sc-ml-12"></sc-icon>
    </p>
  </div>
  <div>
    <h2 class="sc-font-heading-lg sc-mb-20 uppercase" i18n>Support</h2>
    <ul>
      <li class="sc-mb-10" i18n>
        <a [href]="href.help" class="flex align-items-center">
          <sc-icon key="user-group" [width]="18" size="x-large" colour="brand-500" class="sc-mr-12"></sc-icon>
          Get Help
        </a>
      </li>
      <li i18n>
        <a [href]="href.email" class="flex align-items-center">
          <sc-icon key="email" [width]="20" size="x-large" colour="brand-500" class="sc-mr-12"></sc-icon>
          help&#64;surecloud.io
        </a>
      </li>
    </ul>
  </div>
  <div class="col-12 flex justify-content-center">
    <div
      *ngIf="showError"
      class="sc-error-details__error-message sc-font-heading-md-semi-bold sc-bg-yellow-50 sc-mt-50 sc-p-32">
      <div *ngIf="errorMessage" class="flex justify-content-between">
        <p>
          {{ errorMessage }}
        </p>
        <sc-icon
          [cdkCopyToClipboard]="copyToClipboard"
          key="drop"
          [width]="18"
          size="x-large"
          colour="brand-500"
          class="flex-shrink-0 sc-ml-16 sc-cursor-pointer"></sc-icon>
      </div>

      <strong class="block text-right sc-mt-16" *ngIf="trackingId" i18n> TRACKING ID: {{ trackingId }} </strong>
    </div>
  </div>
</div>
