import { NoTypeComponent } from '../../no-type/no-type.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { noTypeComponentCreator } from '../creators/no-type.creator';

/**
 * Control configuration for NO TYPE renderer component
 *
 */
export const NO_TYPE_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: NoTypeComponent,
  creatorFn: noTypeComponentCreator,
};
