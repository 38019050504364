import { z } from 'zod';

/**
 * Normalise Get Tasks Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetTaskService.
 */

/**
 * Task Zod schema.
 */
export const taskZodSchema = z.object({
  assigneeId: z.string().nullable(),
  completed: z.string().nullable(),
  description: z.string().nullable(),
  dueDate: z.string().nullable(),
  newTask: z.boolean().nullable(),
  recordId: z.string(),
  recordName: z.string().nullable(),
  summary: z.string().nullable(),
  taskId: z.string(),
});

/**
 * Normalised Task list Zod schema.
 */
export const normalisedTaskListZodSchema = z.object({
  tasks: z.array(taskZodSchema),
});

/**
 * Normalised Task list data interface.
 */
export type NormalisedTaskList = z.infer<typeof normalisedTaskListZodSchema>;

/**
 * Guard to check the normalised Task list data matches the schema.
 *
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedTaskList.
 */
export const isNormalisedTaskListGuard = (map: unknown): map is NormalisedTaskList =>
  normalisedTaskListZodSchema.safeParse(map).success;

/**
 * When the normalised Task list data does not match the schema
 * then get the error as to show where the problem is.
 *
 * @param {unknown} map Map of the data to check.
 * @return {z.SafeParseReturnType<unknown, NormalisedTaskList>} The error on where the map of the data does not match a NormalisedTaskList.
 */
export const getNormalisedTaskListError = (map: unknown): z.SafeParseReturnType<unknown, NormalisedTaskList> =>
  normalisedTaskListZodSchema.safeParse(map);
