import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SCGridSchema, TabInterface } from '@surecloud/common';
import { CellClickedEvent, CellEditRequestEvent, ColDef } from 'ag-grid-community';
import { makeColumnDefinitionsFromGridSchema } from '../../grid';
import { AdvancedGridComponent } from '../../grid/advanced-grid/advanced-grid.component';
import { TitleDescriptionComponent } from '../../title-description/title-description.component';

/**
 * Surecloud grid view template component
 * @export
 * @class GridViewTemplateComponent
 */
@Component({
  selector: 'sc-grid-view-template',
  templateUrl: './grid-view-template.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AdvancedGridComponent, TitleDescriptionComponent, CommonModule],
})
export class GridViewTemplateComponent implements OnInit {
  /**
   * heading Text
   * @memberof GridViewTemplateComponent
   */
  @Input() heading: string | undefined;
  /**
   *Description Text
   * @memberof GridViewTemplateComponent
   */
  @Input() description: string | undefined;

  /**
   *Grid schema
   * @type {SCGridSchema}
   * @memberof GridViewTemplateComponent
   */
  @Input() gridSchema!: SCGridSchema;

  /**
   *The data source
   * @type {unknown[]} the data set
   * @memberof BasicGridComponent
   */
  @Input() rowData: unknown[] = [];

  /**
   *Array of tabs
   * @type {TabInterface[]}
   * @memberof GridViewTemplateComponent
   */
  @Input() tabs: TabInterface[] = [];

  /**
   * The active tab we want to select first.
   * @type {number}
   * @memberof GridViewTemplateComponent
   */
  @Input() activeTab: number | undefined;

  /**
   * The Event to emit when the active tab has been changed.
   * @memberof GridViewTemplateComponent
   */
  @Output() gridTabChanged = new EventEmitter<number>();

  /**
   *Theme to be applied
   * @memberof GridViewTemplateComponent
   */
  @Input() theme = 'ag-theme-alpine';

  /**
   * The name of the Primary Button
   *
   * @memberof GridFooterComponent
   */
  @Input() primaryButtonName = '';

  /**
   *The type of row selection, singular or multiple
   * @type {('single' | 'multiple' | undefined)}
   * @memberof GridViewTemplateComponent
   */
  @Input() rowSelection: 'single' | 'multiple' | undefined = 'single';

  /**
   * filter string to use to restrict data
   * @type {string|null}
   * @memberof GridViewTemplateComponent
   */
  @Input() quickFilterText = '';

  /**
   * enable add row button
   * @type {boolean}
   * @memberof GridViewTemplateComponent
   */
  @Input() canAddRow: boolean | null = true;

  /**
   * enable delete row button
   * @type {boolean}
   * @memberof GridViewTemplateComponent
   */
  @Input() canDeleteRow: boolean | null = true;

  /**
   * enable select row checkbox
   * @type {boolean}
   * @memberof GridViewTemplateComponent
   */
  @Input() canSelectRow: boolean | null = true;

  /**
   * The height of the table.
   * @memberof AdvancedGridComponent
   */
  @Input() height = 400;

  /**
   *Handles the new row added event
   * @memberof GridViewTemplateComponent
   */
  @Output() gridAddRowClicked = new EventEmitter<unknown>();

  /**
   *Handles the cell edited event
   * @memberof GridViewTemplateComponent
   */
  @Output() gridCellEdited = new EventEmitter<CellEditRequestEvent>();

  /**
   *Handles the cell clicked event
   * @memberof GridViewTemplateComponent
   */
  @Output() gridCellClicked = new EventEmitter<CellClickedEvent>();
  /**
   *Handles the rows deleted event
   * @memberof GridViewTemplateComponent
   */
  @Output() gridRowsDeleted = new EventEmitter<unknown[]>();

  /**
   *Array of columns to display and their definitions
   * @type {ColDef[]}
   * @memberof GridViewTemplateComponent
   */
  columnDefs: ColDef[] = [];

  /**
   *Handles the onInit() event and Makes the column defs from the schema
   * @memberof GridViewTemplateComponent
   */
  ngOnInit(): void {
    this.columnDefs = this.makeColumnDefs();
  }

  /**
   *Makes the column defs from the schema passed in
   * @return {*}  {ColDef[]}
   * @memberof GridViewTemplateComponent
   */
  makeColumnDefs(): ColDef[] {
    return makeColumnDefinitionsFromGridSchema(this.gridSchema);
  }
}
