<sc-tabs
  *ngIf="tabs && tabs.length > 0"
  class="block sc-mb-24"
  [activeTab]="activeTab"
  (activeTabChange)="activeTab = $event; activeTabChange.emit($event)"
  [tabs]="tabs"></sc-tabs>
<div *ngIf="canFilter || canDelete" class="flex justify-content-between sc-mb-24">
  <sc-grid-quick-filter
    *ngIf="canFilter"
    [filterChangeDebounceDelay]="filterChangeDebounceDelay"
    [readonly]="readonly"
    (filterChanged)="setQuickFilter($event)"></sc-grid-quick-filter>
  <sc-popup-menu
    *ngIf="gridMenuItems && gridMenuItems.length > 0"
    test-hook="advanced-grid"
    i18n-test-hook
    class="align-self-end align-self-center sc-ml-10"
    [menuItems]="gridMenuItems"
    (itemClicked)="gridMenuClicked($event)"></sc-popup-menu>
</div>
<sc-basic-grid
  [footer]="footer"
  [height]="height"
  [rowData]="[]"
  [rowDataLength]="rowData.length"
  [columnDefs]="columnDefs"
  [rowSelection]="rowSelection"
  [readonly]="readonly"
  [rowDrag]="rowDrag"
  [context]="context"
  [theme]="theme"
  [isPaginationEnabled]="paginationType === PaginationTypeEnum.ClientSide"
  gridName="View"
  i18n-gridName
  [quickFilterText]="quickFilterText"
  [showLoadingState]="showLoadingState"
  [isLoaded]="isLoaded"
  [noResultsMessage]="noResultsMessage"
  (filterChanged)="gridStateChanged()"
  (sortChanged)="gridStateChanged()"
  (paginationChanged)="updatePagination()"
  (selectionChanged)="rowSelectionChange($event)"
  [suppressScrollOnNewData]="suppressScrollOnNewData"
  [defaultExcelExportParams]="defaultExcelExportParams"
  [excelStyles]="excelStyles"
  (gridReady)="onGridReady($event)"
  (cellValueChanged)="onCellValueChanged($event)"
  (cellClicked)="cellClicked.emit($event)"
  (rowDragMove)="onRowDragMove($event)"
  (rowDragEnd)="onRowDragEnd($event)">
</sc-basic-grid>
<sc-advanced-grid-footer
  i18n
  *ngIf="footer"
  [primaryButtonName]="primaryButtonName"
  [secondaryButtonName]="secondaryButtonName"
  [showPrimaryButton]="showPrimaryButton"
  [showSecondaryButton]="showSecondaryButton"
  [primaryButtonType]="primaryButtonType"
  [secondaryButtonType]="secondaryButtonType"
  [primaryLoadingButtonState]="primaryLoadingButtonState"
  [secondaryLoadingButtonState]="secondaryLoadingButtonState"
  [readonly]="readonly"
  [pagination]="pagination"
  (primaryButtonClicked)="primaryButtonClicked.emit($event)"
  (secondaryButtonClicked)="secondaryButtonClicked.emit($event)"
  (goToFirstPage)="onGoToFirstPage()"
  (goToLastPage)="onGoToLastPage()"
  (goToNextPage)="onGoToNextPage()"
  (goToPreviousPage)="onGoToPreviousPage()"
  (pageSizeChange)="onPageSizeChange($event)"
  (goToPageNumber)="onGoToPage($event)">
</sc-advanced-grid-footer>
