import { Injectable } from '@angular/core';
import { QuestionSetApiInterface } from '@surecloud/api-types';
import { LoggerService, NormalisationOutputStrategyInterface, Normalise } from '@surecloud/common';
import { schema } from 'normalizr';
import {
  NormalisedQuestionSetList,
  getNormalisedQuestionSetListError,
  isNormalisedQuestionSetListGuard,
} from './normalise-get-question-sets.validation';

export const QUESTION_SETS_KEY = 'questionSets';

/**
 * Service encapsulating the schema and configuration for flattening the get Question Set API response.
 * @export
 * @class NormaliseGetQuestionSetService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetQuestionSetService {
  /**
   * Question Set rule schema.
   * @memberof NormaliseGetQuestionSetService
   */
  static readonly questionSetSchema = new schema.Entity(QUESTION_SETS_KEY, undefined, {
    idAttribute: (value): string => value.questionSetId,
  });

  /**
   * Question Set list schema.
   * @memberof NormaliseGetQuestionSetService
   */
  questionSetListSchema = [NormaliseGetQuestionSetService.questionSetSchema];

  /**
   * An array of output strategies to process the flattened data.
   * @type {NormalisationOutputStrategyInterface[]}
   * @memberof NormaliseGetQuestionSetService
   */
  outputStrategies: NormalisationOutputStrategyInterface[] = [
    {
      key: QUESTION_SETS_KEY,
    },
  ];

  /**
   * Creates an instance of NormaliseGetQuestionSetService
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetQuestionSetService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the Question Set data from the Question Set API response.
   * @param {QuestionSetApiInterface[]} questionSetList The Question Set data structure as it comes from the API.
   * @return {NormalisedQuestionSetList} UI Question Set List data structure.
   * @memberof NormaliseGetQuestionSetService
   */
  extract(questionSetList: QuestionSetApiInterface[]): NormalisedQuestionSetList {
    const { questionSets } = Normalise.flatten(questionSetList, this.questionSetListSchema, this.outputStrategies);

    const normaliseResult = { questionSets };

    if (!isNormalisedQuestionSetListGuard(normaliseResult)) {
      this.logger.logEvent(
        'Question Set Feature',
        'Normalise Question Set List Data',
        getNormalisedQuestionSetListError(normaliseResult)
      );

      throw new Error('The result of the normalise Question Set List does not match the expected format.');
    }

    return normaliseResult;
  }
}
