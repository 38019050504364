import { Injectable } from '@angular/core';
import { DialogRef, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ModalInterface, ModalOptionsInterface } from './modal.service.interface';

/**
 * Modal service to open a [Kendo dialog](https://www.telerik.com/kendo-angular-ui/components/dialogs/get-started/) on the page.
 * Able to set text or a component as the main content region within the modal.
 *
 * Any application using this service must `import { DialogModule } from '@progress/kendo-angular-dialog';`
 * @example @NgModule({
 *  ...
 *  imports: [
 *    ...
 *    DialogModule,
 *    ...
 *  ],
 * })
 * export class YourModule {}
 * @example
 * this.modal.open<ComponentName>({content: ComponentName });
 * this.modal.open({content: 'Modal content' });
 * @export
 * @class ModalService
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  /**
   * Default options for the modal if none are set.
   * @private
   * @memberof ModalService
   */
  private readonly defaults = {
    height: 238,
    width: 426,
  };

  /**
   * Reference to the current opened generic confirmation modal.
   * @type {(ModalInterface<ConfirmationModalComponent> | null)}
   * @memberof ModalService
   */
  public confirmationReference: ModalInterface<ConfirmationModalComponent> | null = null;

  /**
   * Map our ModalOptionsInterface to the
   * [Kendo DialogSettings interface](https://www.telerik.com/kendo-angular-ui/components/dialogs/api/DialogSettings/).
   * @private
   * @static
   * @param {ModalOptionsInterface} options The modal options passed to open method to map to the Kendo UI dialog settings..
   * @return {DialogSettings} The Kendo UI dialog settings.
   * @memberof ModalService
   */
  private mapOptions(options: ModalOptionsInterface): DialogSettings {
    const { content, height, title, width } = options;
    return {
      content,
      height: height || this.defaults.height,
      title,
      width: width || this.defaults.width,
    };
  }

  /**
   * Open a modal using the [Kendo Dialog Service](https://www.telerik.com/kendo-angular-ui/components/dialogs/dialog/service/).
   * Able to set text or a component as the main content region within the modal.
   * @example const modal = this.modal.open<ComponentName>({ content: ComponentName });
   * // Access the component like:
   * modal.component ...
   * this.modal.open({ content: 'Modal content' });
   * @template T The component class if a component is being set as the main content region.
   * @param {ModalOptionsInterface} options Modal options.
   * @return {ModalInterface<T>} Interface return from the modal open method.
   * @memberof ModalService
   */
  open<T>(options: ModalOptionsInterface): ModalInterface<T> {
    const dialog: DialogRef = this.modal.open(this.mapOptions(options));

    return {
      // Method to close the dialog
      close: dialog.close as () => void,

      // Stream that updates when the dialog is closed.
      onClose: dialog.result,

      // Send back the component instance if the main content region is a component
      component: dialog.content && (dialog.content.instance as T),
    };
  }

  /**
   * Open a generic confirmation modal.
   * @param {string} [title] The title of the modal.
   * @param {string} [content] The content of the modal.
   * @param {string} [confirmButtonLabel] The label of the confirm button.
   * @memberof ModalService
   */
  public openConfirmation(title = $localize`Confirm`, content = $localize`Please confirm your action.`, confirmButtonLabel = $localize`Confirm`): void {
    this.confirmationReference = this.open<ConfirmationModalComponent>({
      content: ConfirmationModalComponent,
      title,
    });

    if (this.confirmationReference.component) {
      this.confirmationReference.component.content = content;
      this.confirmationReference.component.confirmButtonLabel = confirmButtonLabel;
    }
  }

  /**
   * Creates an instance of ModalService.
   * @param {DialogService} modal The [Kendo Dialog Service](https://www.telerik.com/kendo-angular-ui/components/dialogs/dialog/service/)
   * @memberof ModalService
   */
  constructor(private readonly modal: DialogService) {}
}
