import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  BreadcrumbComponent,
  CommonIconModule,
  GlobalNavComponent,
  TaskListComponent,
  ToolbarsComponent,
  UserMenuComponent,
} from '@surecloud/design';
import {
  BreadcrumbDesignV2Component,
  GlobalNavDesignV2Component,
  TaskListDesignV2Component,
  UserMenuDesignV2Component,
} from '@surecloud/design-v2';
import { TaskStateModule } from '@surecloud/task-state';
import { ToolbarTaskEffects } from './+state/effects/toolbar-task/toolbar-task.effects';
import { ToolbarUserMenuEffects } from './+state/effects/toolbar-user-menu/toolbar-user-menu.effects';
import { ToolbarFeatureComponent } from './components/toolbar-feature/toolbar-feature.component';
import { ToolbarNavigationComponent } from './components/toolbar-navigation/toolbar-navigation.component';
import { ToolbarTasksComponent } from './components/toolbar-tasks/toolbar-tasks.component';
import { ToolbarUserMenuComponent } from './components/toolbar-user-menu/toolbar-user-menu.component';

@NgModule({
  declarations: [ToolbarFeatureComponent, ToolbarNavigationComponent, ToolbarTasksComponent, ToolbarUserMenuComponent],
  exports: [ToolbarFeatureComponent],
  imports: [
    BreadcrumbComponent,
    BreadcrumbDesignV2Component,
    CommonModule,
    CommonIconModule,
    GlobalNavComponent,
    GlobalNavDesignV2Component,
    ToolbarsComponent,
    TaskListComponent,
    TaskListDesignV2Component,
    TaskStateModule,
    UserMenuComponent,
    UserMenuDesignV2Component,
    EffectsModule.forFeature([ToolbarTaskEffects]),
    EffectsModule.forFeature([ToolbarUserMenuEffects]),
  ],
})
export class ToolbarFeatureModule {}
