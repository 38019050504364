import { Injectable } from '@angular/core';
import {
  EntityAttributePermissionCreateInputApiInterface,
  EntityAttributePermissionCreatePayloadApiInterface,
  EntityAttributePermissionDeleteInputApiInterface,
  EntityAttributePermissionDeletePayloadApiInterface,
  EntityAttributePermissionUpdateInputApiInterface,
  EntityAttributePermissionUpdatePayloadApiInterface,
} from '@surecloud/api-types';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import {
  AttributePermissionCreateInputInterface,
  AttributePermissionDeleteInputInterface,
  AttributePermissionInterface,
  makeAttributePermission,
} from '../../../+state/models/permission/attribute-permission/attribute-permission.models';
import {
  apolloEntityAttributePermissionCreateMutation,
  apolloEntityAttributePermissionDeleteMutation,
  apolloEntityAttributePermissionUpdateMutation,
} from '../../../mutations/permission/attribute-permission/attribute-permission.mutations';

export const ATTRIBUTE_PERMISSION_CREATE_ERROR = 'No response data from create attribute permission returned from API.';
export const ATTRIBUTE_PERMISSION_DELETE_ERROR = 'No response data from delete attribute permission returned from API.';
export const ATTRIBUTE_PERMISSION_UPDATE_ERROR = 'No response data from update attribute permission returned from API.';

/**
 * Entity Attribute Permission API service.
 */
@Injectable({
  providedIn: 'root',
})
export class AttributePermissionService {
  /**
   * Creates an instance of AttributePermissionService.
   * @param {Apollo} apollo The Apollo service.
   * @memberof AttributePermissionService
   */
  constructor(private readonly apollo: Apollo) {}

  /**
   * Create a new entity attribute permission.
   * @param {AttributePermissionCreateInputInterface} entityAttributePermissionCreate The input to create a new entity permission.
   * @param {string | null} requestId The requestId.
   * @return {Observable<AttributePermissionInterface>} The created entity attribute permission
   * @memberof AttributePermissionService
   */
  create(
    entityAttributePermissionCreate: AttributePermissionCreateInputInterface,
    requestId: string | null = null
  ): Observable<AttributePermissionInterface> {
    const entityAttributePermissionCreateInput: EntityAttributePermissionCreateInputApiInterface = {
      ...entityAttributePermissionCreate,
    };

    return this.apollo
      .mutate<{ entityAttributePermissionCreate: EntityAttributePermissionCreatePayloadApiInterface }>(
        apolloEntityAttributePermissionCreateMutation(entityAttributePermissionCreateInput, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributePermissionCreate?.createdId
            ? of(
                makeAttributePermission(
                  data.entityAttributePermissionCreate.createdId,
                  entityAttributePermissionCreate.entityId,
                  entityAttributePermissionCreate.attributeId
                )
              )
            : throwError(() => ATTRIBUTE_PERMISSION_CREATE_ERROR)
        )
      );
  }

  /**
   * Delete an entity attribute permission.
   * @param {AttributePermissionDeleteInputInterface} entityAttributePermissionDelete The input to delete an entity Attribute permission.
   * @return {Observable<{deletedIds: string[]}>} The deleted entity Attribute permission IDs.
   * @memberof AttributePermissionService
   */
  delete(
    entityAttributePermissionDelete: AttributePermissionDeleteInputInterface,
  ): Observable<{ deletedIds: string[] }> {
    const attributePermissionDeleteInput: EntityAttributePermissionDeleteInputApiInterface = {
      ...entityAttributePermissionDelete,
    };
    return this.apollo
      .mutate<{ entityAttributePermissionDelete: EntityAttributePermissionDeletePayloadApiInterface }>(
        apolloEntityAttributePermissionDeleteMutation(attributePermissionDeleteInput)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributePermissionDelete?.deletedIds
            ? of({ deletedIds: data?.entityAttributePermissionDelete.deletedIds })
            : throwError(() => ATTRIBUTE_PERMISSION_DELETE_ERROR)
        )
      );
  }

  /**
   * Update an entity attribute permission.
   * @param {AttributePermissionInterface} entityAttributePermission The input to update an entity attribute permission.
   * @param {string | null} requestId The requestId.
   * @return {Observable<AttributePermissionInterface>} The updated entity attribute permission.
   * @memberof AttributePermissionService
   */
  update(entityAttributePermission: AttributePermissionInterface, requestId: string | null = null): Observable<AttributePermissionInterface> {
    const { role, attributeId, ...apiEntityRolePermission } = entityAttributePermission;
    const entityAttributePermissionUpdateInput: EntityAttributePermissionUpdateInputApiInterface = {
      ...apiEntityRolePermission,
      roleId: role?.roleId,
    };
    return this.apollo
      .mutate<{ entityAttributePermissionUpdate: EntityAttributePermissionUpdatePayloadApiInterface }>(
        apolloEntityAttributePermissionUpdateMutation(entityAttributePermissionUpdateInput, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributePermissionUpdate
            ? of({ ...entityAttributePermission })
            : throwError(() => ATTRIBUTE_PERMISSION_UPDATE_ERROR)
        )
      );
  }
}
