<div class="flex flex-column flex-wrap toolbars">
  <ng-content select="[toolbars-navigation]"></ng-content>
  <div *ngIf="canViewSecondaryNav" class="sc-hide--mobile">
    <div
      i18n
      class="toolbars__secondary-nav sc-px-24 flex align-items-center"
      [ngClass]="{
        'toolbars__secondary-nav--design-v2': isDesignV2Enabled,
        'toolbars__secondary-nav--enabled': canViewSecondaryNav
      }">
      <div class="flex-grow-1">
        <ng-content select="[toolbars-primary]"></ng-content>
      </div>
      <ng-content select="[toolbars-secondary]"></ng-content>
    </div>
  </div>
  <div class="flex flex-grow-1 overflow-hidden">
    <div
      class="toolbars__main-container flex flex-grow-1"
      [ngClass]="{ 'toolbars__main-container--design-v2': isDesignV2Enabled }">
      <main class="toolbars__content overflow-scroll">
        <ng-content select="[toolbars-content]"></ng-content>
      </main>
    </div>
  </div>
</div>
