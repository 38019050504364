<div class="sc-advanced-grid-footer flex flex-row align-items-center sc-p-12 sc-bg-grey-100">
  <sc-advanced-grid-button
    *ngIf="showPrimaryButton"
    [buttonType]="primaryButtonType"
    [loadingButtonState]="primaryLoadingButtonState"
    [readonly]="readonly"
    [buttonName]="primaryButtonName"
    (buttonClicked)="primaryButtonClicked.emit($event)"></sc-advanced-grid-button>

  <sc-advanced-grid-button
    class="sc-ml-10"
    *ngIf="showSecondaryButton"
    [buttonType]="secondaryButtonType"
    [loadingButtonState]="secondaryLoadingButtonState"
    [readonly]="readonly"
    [buttonName]="secondaryButtonName"
    (buttonClicked)="secondaryButtonClicked.emit($event)"></sc-advanced-grid-button>

  <div *ngIf="pagination" class="sc-pagination flex flex-end">
    <sc-advanced-grid-pagination
      [currentPage]="pagination.currentPage"
      [pageSize]="pagination.pageSize"
      [totalItems]="pagination.totalItems"
      (goToFirstPage)="goToFirstPage.emit()"
      (goToLastPage)="goToLastPage.emit()"
      (goToNextPage)="goToNextPage.emit()"
      (goToPreviousPage)="goToPreviousPage.emit()"
      (pageSizeChange)="pageSizeChange.emit($event)"
      (goToPageNumber)="goToPageNumber.emit($event)"></sc-advanced-grid-pagination>
  </div>
</div>
