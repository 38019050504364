import { Directive, ViewContainerRef } from '@angular/core';

/**
 * View Container Directive.
 * Provides the ViewContainerRef of the element the directive is attached to.
 *
 * @export
 * @class ViewContainerDirective
 */
@Directive({
  selector: '[scCommonViewContainer]',
  standalone: true,
})
export class ViewContainerDirective {
  /**
   * Creates an instance of ViewContainerDirective.
   *
   * @param {ViewContainerRef} viewContainerRef The Angular View Container Ref.
   * @memberof ViewContainerDirective
   */
  constructor(public viewContainerRef: ViewContainerRef) {}
}
