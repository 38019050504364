import { InputEmailComponent } from '../../input-email/input-email.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for Email renderer component
 *
 */
export const EMAIL_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: InputEmailComponent,
  creatorFn: formControlComponentCreator,
};
