import { ExcelStyle } from 'ag-grid-community';

export const ADVANCED_GRID_EXCEL_STYLES: ExcelStyle[] = [
  {
    id: 'numberType',
    numberFormat: {
      format: '0',
    },
  },
  {
    id: 'currencyFormat',
    numberFormat: {
      format: '#,##0.00 €',
    },
  },
  {
    id: 'negativeInBrackets',
    numberFormat: {
      format: '$[blue] #,##0;$ [red](#,##0)',
    },
  },
  {
    id: 'booleanType',
    dataType: 'Boolean',
  },
  {
    id: 'stringType',
    dataType: 'String',
  },
  {
    id: 'dateType',
    dataType: 'String',
  },
];
