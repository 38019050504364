import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AttributePermissionActions } from '../../../actions/attribute-permission.actions';
import { AttributePermissionInterface } from '../../../models/permission/attribute-permission/attribute-permission.models';

export const ATTRIBUTE_PERMISSION_FEATURE_KEY = 'entityAttributePermission';

export interface StateInterface extends EntityState<AttributePermissionInterface> {
  loaded: boolean; // has the Entity Attribute Permission List been loaded
  error?: string | null; // last known error (if any)
}

export interface EntityAttributePermissionPartialStateInterface {
  readonly [ATTRIBUTE_PERMISSION_FEATURE_KEY]: StateInterface;
}

export const entityAttributePermissionAdapter: EntityAdapter<AttributePermissionInterface> =
  createEntityAdapter<AttributePermissionInterface>({
    selectId: (entityAttributePermission: AttributePermissionInterface) =>
      entityAttributePermission.entityAttributePermissionId,
  });

export const initialState: StateInterface = entityAttributePermissionAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Entity Attribute Permission reducer.
 */
const entityAttributePermissionReducer = createReducer(
  initialState,
  on(AttributePermissionActions.readAttributePermissionList, (state: StateInterface) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(AttributePermissionActions.readAttributePermissionListSuccess, (state: StateInterface, { permissions }) =>
    entityAttributePermissionAdapter.setAll(permissions, { ...state, loaded: true })
  ),
  on(
    AttributePermissionActions.readAttributePermissionFailure,
    (state: StateInterface, { error }: { error: string }) => ({
      ...state,
      error,
    })
  ),
  on(
    AttributePermissionActions.updateAttributePermissionSuccess,
    (state: StateInterface, { entityAttributePermission }) =>
      entityAttributePermissionAdapter.updateOne(
        { id: entityAttributePermission.entityAttributePermissionId, changes: entityAttributePermission },
        state
      )
  ),
  on(AttributePermissionActions.deleteAttributePermissionSuccess, (state: StateInterface, { deletedIds }) =>
    entityAttributePermissionAdapter.removeMany(deletedIds, state)
  ),
  on(
    AttributePermissionActions.createAttributePermissionSuccess,
    (state: StateInterface, { entityAttributePermission }) =>
      entityAttributePermissionAdapter.addOne(entityAttributePermission, state)
  )
);

/**
 * Perform reducer logic on the Entity Attribute Permission NGRX state store for a specific Entity Attribute Permission action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return entityAttributePermissionReducer(state, action);
}
