import { LinkedEntityTypeApiInterface } from '@surecloud/api-types';
import { LinkedEntityTagInterface } from '../linked-entity-tag/linked-entity-tag.model';

/**
 * Linked Entity Interface.
 * @property {string} linkId The ID of the Linked Entity.
 * @property {string} entityId The entity ID the Linked Entity is connected to.
 * @property {LinkedEntityTypeApiInterface} type The linked entity type.
 * @export
 * @interface LinkedEntityInterface
 */
export interface LinkedEntityInterface {
  linkedEntityId: string;
  entityId: string;
  type: LinkedEntityTypeApiInterface;
}

/**
 * Linked Entity With Tags Interface.
 * @property {string} entityId The linked entity ID.
 * @property {string} name The linked entity name.
 * @property {LinkedEntityTagInterface[]} tags The linked entity tags.
 * @property {LinkedEntityTypeApiInterface} type The linked entity type.
 * @export
 * @interface LinkedEntityInterface
 */
export interface LinkedEntityWithTagsInterface {
  entityId: string;
  name: string | null;
  description: string | null;
  tags: LinkedEntityTagInterface[];
  type: LinkedEntityTypeEnum;
}

/**
 * Linked Entity type enum
 * @export
 * @enum {number}
 */
export enum LinkedEntityTypeEnum {
  MULTIPLE = 'Multiple',
  SINGLE = 'Single',
}
