import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { HomepageApplicationsActions } from '../../actions/homepage-applications.actions';
import { HomepageApplicationInterface } from '../../models/homepage-applications/homepage-applications.models';

export const HOMEPAGE_APPLICATIONS_FEATURE_KEY = 'homepageApplications';

export interface StateInterface extends EntityState<HomepageApplicationInterface> {
  selectedId?: string; // which homepage application has been selected
  loaded: boolean;
  error?: string | null;
}

export interface HomepageApplicationsPartialStateInterface {
  readonly [HOMEPAGE_APPLICATIONS_FEATURE_KEY]: StateInterface;
}

export const homepageApplicationsAdapter: EntityAdapter<HomepageApplicationInterface> =
  createEntityAdapter<HomepageApplicationInterface>({
    // applicationName is unique
    selectId: (homepageApplicationInterface: HomepageApplicationInterface) =>
      homepageApplicationInterface.applicationName,
  });

export const initialState: StateInterface = homepageApplicationsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Homepage Applications reducer.
 */
const homepageApplicationsReducer = createReducer(
  initialState,

  on(HomepageApplicationsActions.readHomepageApplications, (state: StateInterface) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    HomepageApplicationsActions.readHomepageApplicationsSuccess,
    (state: StateInterface, { homepageApplications, tabId }) =>
      homepageApplicationsAdapter.setAll(homepageApplications, {
        ...state,
        selectedId: !tabId ? homepageApplications[0]?.applicationName : tabId,
        loaded: true,
      })
  ),
  on(
    HomepageApplicationsActions.readHomepageApplicationsFailure,
    (state: StateInterface, { error }: { error: string }) => ({
      ...state,
      error,
    })
  ),
  on(
    HomepageApplicationsActions.selectHomepageApplicationSuccess,
    (state: StateInterface, { applicationName }: { applicationName: string }) => ({
      ...state,
      selectedId: applicationName,
    })
  ),
  on(HomepageApplicationsActions.unSelectHomepageApplicationTab, (state: StateInterface) => ({
    ...state,
    selectedId: undefined,
  }))
);

/**
 * Perform reducer logic on the Homepage Applications NGRX state store for a specific HomepageApplications actions.
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX Homepage Applications action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return homepageApplicationsReducer(state, action);
}
