import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { ScInputPrefix } from './input-text.interface';

/**
 * Surecloud input text Component that wraps the TextBoxComponent with a optional label.
 * @export
 * @class InputTextComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-input-text',
  standalone: true,
  imports: [
    LabelModule,
    TextBoxModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    E2eHookDirective,
    CommonIconModule,
  ],
  templateUrl: './input-text.component.html',
})
export class InputTextComponent implements OnInit {
  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof InputTextComponent
   */
  @Input() controlName = '';

  /**
   * Text to display an additional description to the user.
   * @memberof InputTextComponent
   */
  @Input() description = '';

  /**
   * Sets the text value for the label.
   * @memberof InputTextComponent
   */
  @Input() label = '';

  /**
   * Sets the placholder value for the input.
   * @memberof InputTextComponent
   */
  @Input() placeholder = '';

  /**
   * Sets the readonly value for the input.
   * @memberof InputTextComponent
   */
  @Input() readonly = false;

  /**
   * Sets if we want a clear button to display.
   * @memberof InputTextComponent
   */
  @Input() clearButton = false;

  /**
   * Sets if we want a success validation style to display.
   * @memberof InputTextComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Sets which prefix to display at the start of the input.
   * @memberof InputTextComponent
   */
  @Input() prefix: ScInputPrefix = 'none';

  /**
   * Sets if we want the usual form margin bottom on the form field.
   * @memberof InputTextComponent
   */
  @Input() marginBottom = false;

  /**
   * Sets a px width for the input. If this is not set the width will be set to 100% by default from styles.
   * @memberof InputTextComponent
   */
  @Input()
  staticWidth!: string;

  /**
   * The test hook to pass in.
   * @memberof InputTextComponent
   */
  @Input()
  testhook!: string;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   * @type {FormGroup}
   * @memberof InputTextComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Provide a value to restrict the upper length of the text
   * @type {number}
   * @memberof InputTextComponent
   */
  @Input()
  maxLength!: number;

  /**
   * Emits when the input text component has been blurred.
   * @type {EventEmitter<void>}
   * @memberof InputTextComponent
   */
  @Output()
  blurred: EventEmitter<void> = new EventEmitter();

  /**
   * Creates an instance of InputTextComponent.
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof InputTextComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
