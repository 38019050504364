import { Directive, Input, Optional, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';

/**
 * @export
 * @abstract
 * @class ControlValueAccessorConnector
 * @implements {ControlValueAccessor}
 * @template T
 */
@Directive()
export abstract class ControlValueAccessorConnector<T> implements ControlValueAccessor {
  /**
   * @type {FormControlDirective}
   * @memberof ControlValueAccessorConnector
   */
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective;

  /**
   * @type {string}
   * @memberof ControlValueAccessorConnector
   */
  @Input() formControlName!: string;

  /**
   * @type {FormControl<T>}
   * @memberof ControlValueAccessorConnector
   */
  @Input() formControl!: FormControl<T>;

  /**
   * Creates an instance of ControlValueAccessorConnector.
   * @param {ControlContainer} controlContainer
   * @memberof ControlValueAccessorConnector
   */
  constructor(@Optional() private controlContainer: ControlContainer) {}

  /**
   * @readonly
   * @type {FormControl}
   * @memberof ControlValueAccessorConnector
   */
  get control(): FormControl {
    return this.formControl || (this.controlContainer.control?.get(this.formControlName) as FormControl<T>);
  }

  /**
   * @readonly
   * @type {boolean}
   * @memberof ControlValueAccessorConnector
   */
  get hasErrors(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  /**
   * @param {T} fn
   * @memberof ControlValueAccessorConnector
   */
  registerOnTouched(fn: T): void {
    if (this.formControlDirective?.valueAccessor) this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  /**
   * @param {T} fn
   * @memberof ControlValueAccessorConnector
   */
  registerOnChange(fn: T): void {
    if (this.formControlDirective?.valueAccessor) this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  /**
   * @param {T} value
   * @memberof ControlValueAccessorConnector
   */
  writeValue(value: T): void {
    if (this.formControlDirective?.valueAccessor) this.formControlDirective.valueAccessor.writeValue(value);
  }

  /**
   * @param {boolean} isDisabled
   * @memberof ControlValueAccessorConnector
   */
  setDisabledState(isDisabled: boolean): void {
    if (this.formControlDirective?.valueAccessor)
      this.formControlDirective.valueAccessor.setDisabledState?.(isDisabled);
  }
}
