// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AuthRoutesEnum,
  CanActivateAuthGuard,
  CanActivateCoreGuard,
  CanActivateGuestRecordViewGuard,
  IDPLoginGuard,
} from '@surecloud/auth';
import {
  ApplicationRouteInterface,
  CommonRoutesEnum,
  NavigationLinkLabels,
  RecordViewRoutesEnum,
  StandaloneRouteNamesEnum,
} from '@surecloud/common';
import {
  STANDALONE_APPLICATION_LIST_ROUTE,
  STANDALONE_ERROR_ROUTE,
  STANDALONE_HOME_ROUTE,
  STANDALONE_RECORD_LIST_ROUTE,
} from '@surecloud/standalone';
import { EmptyComponent } from './empty.component';

export const coreAppRoutes: ApplicationRouteInterface[] = [
  {
    path: AuthRoutesEnum.Root,
    canActivate: [CanActivateAuthGuard],
    loadChildren: () => import('@surecloud/auth').then((m) => m.AuthModule),
  },
  STANDALONE_HOME_ROUTE,
  STANDALONE_ERROR_ROUTE,
  STANDALONE_RECORD_LIST_ROUTE,
  STANDALONE_APPLICATION_LIST_ROUTE,
  {
    path: CommonRoutesEnum.EntityRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/entity').then((m) => m.EntityModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.WorkflowRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/workflow').then((m) => m.WorkflowModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.ViewBuilderRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/view-builder').then((m) => m.ViewBuilderModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: RecordViewRoutesEnum.Root,
    canActivate: [CanActivateCoreGuard],
    data: {
      activeRouteLabel: NavigationLinkLabels.MyApplications,
      canViewAsGuest: true,
    },
    loadChildren: () => import('@surecloud/record-view').then((m) => m.RecordViewModule),
  },
  {
    path: CommonRoutesEnum.DashboardRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/dashboard').then((m) => m.DashboardModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.Reporting,
    },
  },
  {
    path: CommonRoutesEnum.UserManagementRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/user-management').then((m) => m.UserManagementModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.ManageUsers,
    },
  },
  {
    path: CommonRoutesEnum.OptionListsRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/option-list').then((m) => m.OptionListFeatureModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.GroupRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/group-state').then((m) => m.GroupStateModule),
  },
  {
    path: CommonRoutesEnum.QuestionLibraryRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/question-library-feature').then((m) => m.QuestionLibraryModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.NotificationRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/notification-feature').then((m) => m.NotificationFeatureModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.RiskMatrixRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/risk-matrix-feature').then((m) => m.RiskMatrixFeatureModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.CustomHierarchyRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/custom-hierarchy').then((m) => m.CustomHierarchyFeatureModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.IntegrationListRoot,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/integration').then((m) => m.IntegrationModule),
    data: {
      activeRouteLabel: NavigationLinkLabels.SystemSetUp,
    },
  },
  {
    path: CommonRoutesEnum.TempApplicationList,
    canActivate: [CanActivateCoreGuard],
    loadChildren: () => import('@surecloud/application').then((m) => m.ApplicationFeatureModule),
  },
  /**
   * -----------------
   * Guest Link Route
   * -----------------
   * For example => '/guestLink/ey33Dfg111'
   *
   * We don't specify the token here as a param (as with a normal route).
   * Instead it is handled with a custom URL parser (on app load)
   * and not by built-in Angular routing.
   *
   * We pre-load the Record View Module.
   */
  {
    path: CommonRoutesEnum.GuestLink,
    canActivate: [CanActivateGuestRecordViewGuard],
    data: { canViewAsGuest: true },
    loadChildren: () => import('@surecloud/record-view').then((m) => m.RecordViewModule),
  },
  /**
   * ----------------
   * IDP Login Route
   * ----------------
   * Once the user has authenticated with their chosen IDP, they will be redirected to this route.
   * This route will then login the user to the application with the provide orgId.
   * The empty component is to enable the path match. It never get's there.
   */
  {
    path: `${CommonRoutesEnum.IDPLogin}`,
    canActivate: [IDPLoginGuard],
    component: EmptyComponent,
  },
  {
    path: '',
    redirectTo: StandaloneRouteNamesEnum.Home,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: $localize`/${CommonRoutesEnum.ErrorRoot}?message=We can't find what you are looking for`,
    pathMatch: 'full',
  },
];
