import { createSelector } from '@ngrx/store';
import { ATTRIBUTE_ICON_MAP, SelectDropdownComponentOptionInterface, UNTITLED } from '@surecloud/common';
import { QuestionSetSelectors } from '@surecloud/question-set-state';
import { RoleSelectors } from '@surecloud/role-state';
import * as AttributeSelectors from '../attribute/attribute.selectors';
import { getSelectedId } from '../entity/entity.selectors';

/**
 * Get the selected Entities Question Sets in an interface we can give to the Advanced Grid component.
 * Eg. It needs to display as tags and icons.
 */
export const getSelectedEntityQuestionSetsForGrid = createSelector(
  QuestionSetSelectors.getQuestionSets,
  getSelectedId,
  AttributeSelectors.getAttributeEntities,
  RoleSelectors.getRoleEntities,
  (questionSets, entityId, attributes, roles) =>
    questionSets
      .filter((questionSet) => questionSet.entityId === entityId)
      .map((questionSet) => {
        const attribute = questionSet.attributeId ? attributes[questionSet.attributeId] : undefined;
        const label = attribute?.name || null;
        const type = attribute?.type ? ATTRIBUTE_ICON_MAP[attribute.type] : null;

        const reviewers = questionSet.reviewerRoleIds
          .map((roleId) => ({
            value: roles[roleId]?.name,
            display: roles[roleId]?.name,
          }))
          .filter(({ value }) => value);

        return {
          attribute: { label, type },
          entityId: questionSet.entityId,
          name: questionSet.name,
          questionSetId: questionSet.questionSetId,
          reviewers,
        };
      })
);

/**
 * Get the selected Entities Question Sets in an SelectDropdownComponentOptionInterface interface.
 */
export const getSelectedEntityQuestionSetsForDropdown = createSelector(
  QuestionSetSelectors.getQuestionSets,
  getSelectedId,
  (questionSets, entityId) =>
    questionSets
      .filter((questionSet) => questionSet.entityId === entityId)
      .map(
        (questionSet): SelectDropdownComponentOptionInterface => ({
          text: questionSet.name || UNTITLED,
          value: questionSet.questionSetId,
        })
      )
);
