import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import * as RecentRecordsSelectors from '../selectors/recent-records/recent-records.selectors';

/**
 * The facade for the RecentRecords feature sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 *
 * @export
 * @class RecentRecordsFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class RecentRecordsFeatureFacade extends BaseFacade {
  /**
   * Recent records.
   *
   * @memberof RecentRecordsFeatureFacade
   */
  recentRecordsForGrid$ = this.store.pipe(select(RecentRecordsSelectors.getRecentRecordsforGrid));

  /**
   * Boolean to determine if the Recent records have been loaded.
   *
   * @memberof RecentRecordsFeatureFacade
   */
  loaded$ = this.store.pipe(select(RecentRecordsSelectors.getRecentRecordsLoaded));

  /**
   * Creates an instance of RecentRecordsFeatureFacade.
   *
   * @param {Store} store The NGRX application state store.
   * @memberof RecentRecordsFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
