import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Margin, PopupModule } from '@progress/kendo-angular-popup';
import {
  TextButtonComponent,
  UserAvatarComponent,
  UserMenuComponentService,
  UserMenuConfigurationInterface,
} from '@surecloud/design';

/**
 * Surecloud User Menu Component.
 * Uses the [Kendo Popup Component](https://www.telerik.com/kendo-angular-ui/components/popup/).
 * This is a clone of the Design v1 component with new design changes.
 * @export
 * @class UserMenuComponent
 */
@Component({
  selector: 'design-v2-user-menu',
  standalone: true,
  styleUrls: ['./user-menu.component.scss'],
  templateUrl: './user-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PopupModule, UserAvatarComponent, TextButtonComponent],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuDesignV2Component {
  /**
   * The user configuration to show within the User Menu Component.
   * @type {(UserMenuConfigurationInterface | null)}
   * @memberof UserMenuComponent
   */
  @Input()
  public config: UserMenuConfigurationInterface | null = null;

  /**
   * Any horizontal or vertical spacing that should be between the offset position and the User Menu Component.
   * @type {Margin}
   * @memberof UserMenuComponent
   */
  @Input()
  public margin: Margin = {
    horizontal: 0,
    vertical: 8,
  };

  /**
   * When a user clicks the Log out button.
   * Then emit this event.
   * @type {EventEmitter<void>}
   * @memberof UserMenuComponent
   */
  @Output()
  public logout: EventEmitter<void> = new EventEmitter();

  /**
   * The reference to the popup element.
   * @type {ElementRef | undefined}
   * @memberof UserMenuComponent
   */
  @ViewChild('popup', { read: ElementRef })
  public popup: ElementRef | undefined;

  /**
   * Creates an instance of UserMenuComponent.
   * @param {ChangeDetectorRef} changes The Angular Change Detection Reference.
   * @param {UserMenuComponentService} overlay The SideBar Overlay Component Service.
   * @memberof UserMenuComponent
   */
  constructor(private changes: ChangeDetectorRef, public readonly overlay: UserMenuComponentService) {}

  /**
   * If a click is detected outside of this component then close the popup/dropdown menu.
   * @param {PointerEvent} event The click event.
   * @memberof UserMenuComponent
   */
  @HostListener('document:click', ['$event'])
  public click(event: PointerEvent): void {
    if (!this.popup?.nativeElement.contains(event.target)) {
      this.overlay.update(false);

      // Exhausted all efforts to try and not manually run change detection with no dice - you're welcome to roll
      this.changes.detectChanges();
    }
  }

  /**
   * If the Escape key is pressed then close the popup.
   * @param {KeyboardEvent} event The keyboard event.
   * @memberof UserMenuComponent
   */
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.overlay.update(false);

      // Exhausted all efforts to try and not manually run change detection with no dice - you're welcome to roll
      this.changes.detectChanges();
    }
  }
}
