import { makeUUID } from '../../classes';
import { UNTITLED } from '../../constants/name-conventions.constants';
import { CommonRoutesEnum } from '../../routes/common-routes.enum';
import { EntityInterface } from '../entity/entity.models';

/**
 * The base breadcrumb item interface.
 * Common properties across all items in a breadcrumb array.
 * @property {string} id The unique identifier for the breadcrumb item. Cannot be the route as some routes are circular.
 * @property {string} label The label to display in the breadcrumb. If a label is not available the Breadcrumb template will render our UNTITLED constant.
 * @property {string[]} route The route to the page.
 * @interface BreadcrumbBaseInterface
 */
export interface BreadcrumbItemInterface {
  id: string;
  label?: string | null;
  route?: string[];
  tabId?: string;
}

/**
 * The breadcrumbs interface - an interface of many breadcrumb items representing a user's page navigation history.
 * @interface BreadcrumbInterface
 */
export type BreadcrumbInterface = BreadcrumbItemInterface[];

/**
 * Entity Breadcrumb Route
 */
export const ENTITY_BREADCRUMB_ROUTE = {
  id: makeUUID(),
  label: $localize`Workflows`,
  route: ['/', CommonRoutesEnum.EntityRoot],
};

/**
 * The Entity Breadcrumb route is common across alot of  the application.
 * This present a utility function to easily get the entity breadcrumb.
 * @param {EntityInterface} entity The Entity to build the bread rumb for.
 * @return {BreadcrumbItemInterface} The breadcrumb item for the entity.
 */
export const getEntityBreadcrumb = (entity: EntityInterface): BreadcrumbItemInterface => ({
  id: entity.entityId,
  label: entity.name || UNTITLED,
  route: ['/', CommonRoutesEnum.EntityRoot, entity.entityId],
});
