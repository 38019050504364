import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownFilterSettings, DropDownsModule, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective, SelectDropdownComponentOptionInterface, appendToType } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Wrapper component around the [Kendo UI MultiMultiselect DropDownList](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/multiselect/).
 * @example
 * <sc-multiselect-dropdown
 *  [controlName]="formControlName"
 *  [description]="Description for select dropdown"
 *  [label]="Label for select dropdown"
 *  [staticWidth]="200"
 *  [options]="['Option 1', 'Option 2', 'Option 3']"
 *  [readonly]="true">
 * </sc-multiselect-dropdown>
 * @export
 * @class MultiselectDropdownComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-multiselect-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    DropDownsModule,
    LabelModule,
    ReactiveFormsModule,
    CommonIconModule,
  ],
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectDropdownComponent implements OnInit {
  /**
   * Text to display an additional description to the user.
   * @memberof MultiselectDropdownComponent
   */
  @Input() description = '';

  /**
   *
   *Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof MultiselectDropdownComponent
   */
  @Input() controlName = '';

  /**
   * Automation testhook to add to the select dropdown.
   * @memberof MultiselectDropdownComponent
   */
  @Input() testhook = 'select-dropdown';

  /**
   *
   *If we want to display a clear button on the multiselect.
   * @memberof MultiselectDropdownComponent
   */
  @Input() clearButton = false;

  /**
   * The label text for the select dropdown.
   * @memberof MultiselectDropdownComponent
   */
  @Input() label = '';

  /**
   *  Sets if we want a success validation style to display.
   * @memberof InputMultiselectDropdownComponentTextComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Sets the value type to primitive by default.
   * If we want save the whole option object as the value then set it to false.
   * @memberof MultiselectDropdownComponent
   */
  @Input()
  valuePrimitive = true;

  /**
   * The parent form group of the select dropdown.
   * @type {FormGroup}
   * @memberof MultiselectDropdownComponent
   */
  parentFormGroup!: FormGroup;

  /**
   * The list of [options to show](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/multiselect/data-binding/) in the select dropdown.
   * @type {(SelectDropdownComponentOptionInterface[] | unknown[])}
   * @memberof MultiselectDropdownComponent
   */
  @Input() options!: SelectDropdownComponentOptionInterface[] | unknown[];

  /**
   * The text property to assign for the option if a MultiselectDropdownComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/multiselect/data-binding/#toc-arrays-of-complex-data)
   * @memberof MultiselectDropdownComponent
   */
  @Input() optionTextProperty = 'text';

  /**
   * The value property to assign for the option if a MultiselectDropdownComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/multiselect/data-binding/#toc-arrays-of-complex-data)
   * @memberof MultiselectDropdownComponent
   */
  @Input() optionValueProperty = 'value';

  /**
   * Sets the select dropdown readonly mode.
   * @memberof MultiselectDropdownComponent
   */
  @Input() readonly = false;

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof MultiselectDropdownComponent
   */
  @Input() staticWidth!: string;

  /**
   * Sets the placholder value for the input.
   * @memberof MultiselectDropdownComponent
   */
  @Input() placeholder = '';

  /**
   * Sets if we want the usual form margin bottom on the form field.
   * @memberof MultiselectDropdownComponent
   */
  @Input() marginBottom = false;

  /**
   * Sets the filter settings
   * @type {DropDownFilterSettings}
   * @memberof MultiselectDropdownComponent
   */
  @Input() filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  /**
   * Sets how the dropdown popup is append to the component,
   * By default we are appended to the component ViewContainerRef
   * @type {appendToType}
   * @memberof MultiselectDropdownComponent
   */
  @Input() appendTo: appendToType = null;

  /**
   *
   *
   * @type {MultiSelectComponent}
   * @memberof MultiselectDropdownComponent
   */
  @ViewChild(MultiSelectComponent, { static: true }) dropdown!: MultiSelectComponent;

  /**
   * Check's if the dropdown is expanded
   * @memberof MultiselectDropdownComponent
   */
  expanded = false;

  /**
   * Creates an instance of MultiselectDropdownComponent.
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @memberof MultiselectDropdownComponent
   */
  constructor(private formGroupDirective: FormGroupDirective, public viewContainerRef: ViewContainerRef) {}

  /**
   * Runs when the component is started.
   * Sets the parent formgroup form the component.
   * @memberof MultiselectDropdownComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.formGroupDirective.control;
  }

  /**
   * Runs when the dropdown opens
   * @memberof MultiselectDropdownComponent
   */
  opened(): void {
    this.expanded = true;
  }

  /**
   * Runs when the dropdown closes
   * @memberof MultiselectDropdownComponent
   */
  closed(): void {
    this.expanded = false;
  }

  /**
   * Toggle the dropdown as the status parameter
   * @param {boolean} status
   * @memberof MultiselectDropdownComponent
   */
  toggle(status: boolean): void {
    this.dropdown.toggle(status);
    this.expanded = status;
  }
}
