import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ENV_URL_TOKEN } from '@surecloud/common';
import { BehaviorSubject, EMPTY, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';

/**
 * Routed component to display the enter tenant login form
 * @export
 * @class EnterTenantComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-auth-enter-tenant',
  templateUrl: './enter-tenant.component.html',
  styleUrls: ['./enter-tenant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterTenantComponent implements OnInit, OnDestroy {
  enterTenantForm = this.fb.group({
    tenant: ['', [Validators.pattern('[a-zA-Z0-9-]*'), Validators.required]],
  });
  urlSuffix = '';
  showWarning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    @Inject(ENV_URL_TOKEN) private envUrl: string,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.urlSuffix = `.${this.envUrl}`;

    this.enterTenantForm.controls.tenant.valueChanges
      .pipe(
        tap(() => this.showWarning$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  /**
   * Grab tenant value from form & hand over to login function
   * @memberof EnterTenantComponent
   */
  onSubmit(): void {
    const { tenant } = this.enterTenantForm.value;
    if (tenant) {
      this.authService
        .tenantLookup(tenant)
        .pipe(
          switchMap((orgId) => {
            if (orgId) {
              return this.authService.tenantLogin(orgId);
            }
            this.showWarning$.next(true);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
