/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CommonRoutesEnum, ReturnURIService } from '@surecloud/common';
import { FeatureFlagActions, FeatureFlagFacade } from '@surecloud/feature-flag';
import { UserProfileFeatureFacade, UserProfileTypeEnum } from '@surecloud/user-profile';
import { Observable, first, map, of, switchMap, tap } from 'rxjs';
import { AuthRoutesEnum } from '../../auth-routes.enum';
import { AuthService } from '../../services/auth.service';
/**
 * Implements a route guard for the authenticated areas of the core application
 * @export
 * @class CanActivateCoreGuard
 */
@Injectable({
  providedIn: 'root',
})
export class CanActivateCoreGuard {
  /**
   * The URL tree to navigate to when the user is a guest and tries to access the core application.
   * @private
   * @memberof CanActivateCoreGuard
   */
  private GUEST_ERROR_CANNOT_ACCESS_CORE_URL_TREE = this.router.createUrlTree(['/', CommonRoutesEnum.ErrorRoot], {
    queryParams: {
      message: $localize`You cannot access the core application as a guest user. Please open a new tab to log in.`,
    },
  });

  /**
   * Creates an instance of CanActivateCoreGuard.
   * @param {AuthService} authService - auth service.
   * @param {Router} router - angular router.
   * @param {UserProfileFeatureFacade} userProfileFacade - user profile facade.
   * @param {FeatureFlagFacade} featureFlagFacade - feature flag facade.
   * @param {ReturnURIService} returnURI - return URI service.
   * @memberof CanActivateCoreGuard
   */
  constructor(
    private authService: AuthService,
    private router: Router,
    private userProfileFacade: UserProfileFeatureFacade,
    private featureFlagFacade: FeatureFlagFacade,
    private returnURI: ReturnURIService
  ) {}

  /**
   * Retrieves whether the route is a Record View route
   * @private
   * @static
   * @param {ActivatedRouteSnapshot} route - route snapshot.
   * @return {boolean} Can a user view this route as a guest.
   * @memberof CanActivateCoreGuard
   */
  private static canViewAsGuest(route: ActivatedRouteSnapshot): boolean {
    return route.data?.['canViewAsGuest'] === true;
  }

  /**
   * Implements canActivate to check that user is authenticated, otherwise
   * redirects to auth page, and checks if user is a guest and tries to access the core application.
   * @param {ActivatedRouteSnapshot} route - the current route snapshot
   * @return {*}  {(Observable<boolean | UrlTree>)}
   * @memberof CanActivateCoreGuard
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated().pipe(
      first(),
      switchMap((isAuthenticated) => {
        /**
         *  --------------------
         *   AUTHENTICATED USER
         *  --------------------
         *  Handle the authenticated user activation path.
         */
        if (isAuthenticated) {
          /**
           * ------------
           *  RETURN URL
           * ------------
           * If there is a return URL stashed, build it as a URL tree
           * & navigate to it. Then remove it.
           */
          const returnUrlTree = this.returnURI.asUrlTree();
          // eslint-disable-next-line max-depth
          if (returnUrlTree) {
            return of(returnUrlTree);
          }
          /**
           *  -------------------
           *   USER PROFILE LOAD
           *  -------------------
           *  Ensure the userprofile is loaded on every core route. In the first instance,
           *  this will block loading of the page until the user profile returns. Following
           *  initial load, the profile will load from state.
           */
          return this.userProfileFacade.getUserProfile().pipe(
            first(),
            /**
             *  -------------------
             *   FEATURE FLAG INIT
             *  -------------------
             *  Using the user profile, initialise the feature flags.
             */
            tap((userProfile) => this.featureFlagFacade.dispatch(FeatureFlagActions.readFeatureFlags({ userProfile }))),
            map((userProfile) => {
              /**
               *  ------------
               *   GUEST USER
               *  ------------
               *  An authenticated guest user can only access the Record View page.
               *  They should not be able to access the rest of the core application.
               *  So unless it's a Record View route, send them to the auth page with a message.
               *  (Future: could add list of valid user types to config of each route)
               */
              if (userProfile?.type === UserProfileTypeEnum.GUEST) {
                const canViewAsGuest = CanActivateCoreGuard.canViewAsGuest(route);
                return canViewAsGuest ? true : this.GUEST_ERROR_CANNOT_ACCESS_CORE_URL_TREE;
              }
              /**
               *  ---------------
               *   STANDARD USER
               *  ---------------
               *  An authenticated standard user can access the core application.
               *  Given the userProfile is loaded correctly.
               */
              return !!userProfile;
            })
          );
        }
        /**
         *  -------------------
         *   NOT AUTHENTICATED
         *  -------------------
         *  Send to the auth page & stash intended route.
         */
        this.returnURI.stash();
        return of(this.router.parseUrl(`/${AuthRoutesEnum.Root}`));
      })
    );
  }
}
