import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IconButtonComponent } from '../../button/icon-button.component';
import { CommonIconModule } from '../../icon/icons/common-icon.module';

/**
 * GridNavigationCellComponent - custom AgGrid component for displaying a navigation icon.
 *
 * @export
 * @class GridNavigationCellComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  standalone: true,
  imports: [RouterModule, CommonIconModule, CommonModule, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sc-grid-navigation-cell',
  template: ` <span class="flex w-full justify-content-center align-items-center" aria-label="open-up" i18n-aria-label>
    <sc-icon size="medium" [width]="16" [height]="16" colour="brand-500" key="open-up"></sc-icon>
  </span>`,
})
export class GridNavigationCellComponent implements ICellRendererAngularComp {
  /**
   * Class to add to the hosting element.
   *
   * @memberof GridNavigationCellComponent
   */
  @HostBinding('className') hostClass = 'sc-line-height-0';

  /**
   * agInit implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridNavigationCellComponent
   */
  // eslint-disable-next-line class-methods-use-this
  agInit(): void {} // NOSONAR

  /**
   * refresh implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridNavigationCellComponent
   * @return {boolean} isRefresh
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
