import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import { makeAttributeConfigComponent, makeAttributeConfigSectionId } from '../global-attribute-config.model';

/**
 *
 * Makes the global option list attribute configuration
 */
export const GLOBAL_OPTION_LIST_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.OptionList,
  key: EntityAttributeTypeEnum.OptionList,
  type: EntityAttributeTypeEnum.OptionList,
  label: $localize`Option List`,
  uniqueSections: [
    {
      sectionComponent: makeAttributeConfigComponent('OptionList'),
      sectionId: makeAttributeConfigSectionId('option-list'),
      sectionName: $localize`Option List Properties`,
    },
  ],
});
