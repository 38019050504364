import { ApolloLink, fromPromise, Operation, split } from '@apollo/client/core';
import {
  ApolloErrorService,
  AURORA_REQUEST_ID,
  Auth0AndGuestUserAuthService,
  isArrayOfFilesOrEmptyGuard,
  makeUUID,
} from '@surecloud/common';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { createUploadLink } from 'apollo-upload-client';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { graphQLErrorsLink, networkErrorsLink } from './apollo-error-link';

/**
 * Creates a Middleware to add an auth0 token if the user is authenticated.
 * @export
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The open auth service.
 * @param {string} apiUri The API URL.
 * @return {ApolloLink} The created Apollo link.
 */
export function addTokenMiddleware(
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  apiUri: string
): ApolloLink {
  return new ApolloLink((operation, forward) =>
    fromPromise(
      firstValueFrom(
        auth0AndGuestUserAuthService.isAuthenticated$.pipe(
          switchMap((isAuth) => {
            if (isAuth) {
              return auth0AndGuestUserAuthService.getAccessTokenSilently();
            }
            return of(null);
          })
        )
      )
    ).flatMap((token) => {
      if (token) {
        operation.setContext({
          uri: apiUri,
          headers: {
            [AURORA_REQUEST_ID]: operation.getContext()['headers']?.[AURORA_REQUEST_ID] || makeUUID(),
            Authorization: `Bearer ${token}`,
          },
        });
      }
      return forward(operation);
    })
  );
}

/**
 * Creates an Apollo HttpLink.
 * @export
 * @param {HttpLink} httpLink The HTTP link.
 * @param {string} apiUri The API URL.
 * @return {HttpLinkHandler} The HTTP link handler.
 */
export function createHttpLink(httpLink: HttpLink, apiUri: string): HttpLinkHandler {
  return httpLink.create({ uri: apiUri });
}

/**
 * Checks if the operation is an upload document function.
 * @param {Operation} op The operation.
 * @return {boolean} True if the operation is an upload document function.
 */
export const isDocumentFunction = (op: Operation): boolean => {
  const operationInput = op.variables?.['input'];
  return (
    operationInput?.document !== undefined ||
    operationInput?.value instanceof File ||
    isArrayOfFilesOrEmptyGuard(operationInput?.value) ||
    operationInput?.file instanceof File
  );
};

/**
 * Combines GraphQLErrorsLink, networkErrorsLink() and createHttpLink() that always execute in serial order.
 * Afterware & Middleware docs: https://www.apollographql.com/docs/react/v2/networking/network-layer/#middleware.
 * @export
 * @param {ApolloErrorService} errorService The service for error handling
 * @param {HttpLink} httpLink The HTTP lnk.
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The Open Auth service.
 * @param {string} apiUri The app API uri.
 * @return {ApolloLink} The Apollo link.
 */
export function createHttpChainLink(
  errorService: ApolloErrorService,
  httpLink: HttpLink,
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  apiUri: string
): ApolloLink {
  // We pass in the afterwares & middlewares in the order we want them to trigger in the link chain.
  return ApolloLink.from([
    addTokenMiddleware(auth0AndGuestUserAuthService, apiUri),
    graphQLErrorsLink(errorService),
    networkErrorsLink(errorService),
    createHttpLink(httpLink, apiUri),
  ]);
}

/**
 * Link for File Uploads
 * @param {ApolloErrorService} errorService The service for error handling
 * @param {string} apiUri url for the api.
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService auth0AndGuestUserAuthService
 * @return {ApolloLink} apollo link
 */
export function createUploadChainLink(
  errorService: ApolloErrorService,
  apiUri: string,
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService
): ApolloLink {
  return ApolloLink.from([
    addTokenMiddleware(auth0AndGuestUserAuthService, apiUri),
    graphQLErrorsLink(errorService),
    networkErrorsLink(errorService),
    createUploadLink(),
  ]);
}

/**
 * Decide whether to use httpLink or uploadLink
 * @param {ApolloErrorService} errorService The service for error handling
 * @param {HttpLink} httpLink http link class
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService auth0AndGuestUserAuthService
 * @param {string} apiUri url for the api.
 * @return {ApolloLink} apollo link
 */
export function createHttpOrUploadLink(
  errorService: ApolloErrorService,
  httpLink: HttpLink,
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  apiUri: string
): ApolloLink {
  return split(
    isDocumentFunction,
    createUploadChainLink(errorService, apiUri, auth0AndGuestUserAuthService),
    createHttpChainLink(errorService, httpLink, auth0AndGuestUserAuthService, apiUri)
  );
}
