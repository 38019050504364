import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import { GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION } from '../global-attribute-config.model';

/**
 * Makes the global date attribute configuration
 */
export const GLOBAL_DATE_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Date,
  key: EntityAttributeTypeEnum.Date,
  type: EntityAttributeTypeEnum.Date,
  label: $localize`Date`,
  uniqueSections: [GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION],
});
