import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { LoggerService } from '@surecloud/common';
import { RecentRecordsService } from '../../../services/recent-records/recent-records.service';
import { RecentRecordsActions } from '../../actions/recent-records.actions';

/**
 * The Effects/side effects for RecentRecords.
 *
 * @export
 * @class RecentRecordsEffects
 */
@Injectable({ providedIn: 'root' })
export class RecentRecordsEffects {
  /**
   * When reading a Recent Records.
   * Then load all Recent Records data from the API.
   *
   * @memberof RecentRecordsEffects
   */
  loadRecentRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecentRecordsActions.readRecentRecords),
      mergeMap(() =>
        this.recentRecordsService.readAll().pipe(
          map((normalisedRecentRecords) =>
            RecentRecordsActions.readRecentRecordsDataSuccess({
              normalisedRecentRecords,
            })
          ),
          catchError((error: unknown) => of(RecentRecordsActions.readRecentRecordsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the Recent Records has received a response.
   * Then load the result into state.
   *
   * @memberof RecentRecordsEffects
   */
  setRecentRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecentRecordsActions.readRecentRecordsDataSuccess),
      map(({ normalisedRecentRecords }) =>
        RecentRecordsActions.readRecentRecordsSuccess({
          recentRecords: normalisedRecentRecords.recentRecords,
        })
      )
    )
  );

  /**
   * Logs any errors.
   *
   * @memberof RecentRecordsEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RecentRecordsActions.readRecentRecordsFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Recent Records', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of RecentRecordsEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {RecentRecordsService} recentRecordsService The Recent Records API service.
   * @param {LoggerService} logger The application logging service.
   * @memberof RecentRecordsEffects
   */
  constructor(private readonly actions$: Actions, private readonly recentRecordsService: RecentRecordsService, private readonly logger: LoggerService,
    ) {}
}
