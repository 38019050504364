import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { E2eHookDirective, ToastSeverityEnum } from '@surecloud/common';
import { Message } from 'primeng/api';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';
/**
 * Surecloud Toast Component that shows in PrimeNG toast.
 * @export
 * @class ToastComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-toast',
  standalone: true,
  imports: [CommonModule, SvgIconsModule, E2eHookDirective],
  styleUrls: ['./toast.component.scss'],
  templateUrl: './toast.component.html',
})
export class ToastComponent {
  /**
   * The message object containing data for the toast.
   * @memberof ToastComponent
   */
  @Input() message!: Message;

  /**
   * The test hook to pass in.
   * @memberof ToastComponent
   */
  @Input()
  testhook!: string;

  /**
   * Toast severity enum available for use in the template.
   * @memberof ToastComponent
   */
  ToastSeverityEnum = ToastSeverityEnum;
}
