import { SelectHierarchyNodeInterface } from '@surecloud/common';

/**
 * Utility function to flatten a deeply nested array of hierarchy nodes.
 * @param {SelectHierarchyNodeInterface} node The hierarchy node to flatten.
 * @return {SelectHierarchyNodeInterface[]} Flatten array of hierarchy nodes.
 */
export const getSelectHierarchyNodesAsFlatArray = (
  node: SelectHierarchyNodeInterface
): SelectHierarchyNodeInterface[] => [
  { ...node },
  ...(node.nodes ? node.nodes.flatMap((nodes) => getSelectHierarchyNodesAsFlatArray(nodes)) : []),
];
