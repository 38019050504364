import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingButtonStateInterface } from '../../../button/loading-button-state.interface';
import { LoadingButtonComponent } from '../../../button/loading-button.component';
import { TextButtonComponent } from '../../../button/text-button.component';

export type AdvancedGridButtonType = 'text' | 'loading';

/**
 * Surecloud advanced grid wrapper around sureCloud basic grid.
 *
 * Avoid using get & set in this file. (Too many issues using these).
 */
@Component({
  selector: 'sc-advanced-grid-button',
  templateUrl: './advanced-grid-button.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TextButtonComponent, LoadingButtonComponent],
})
export class AdvancedGridButtonComponent {
  /**
   * Set this if we want to display the grid but remove functionality so it can be previewed.
   * @memberof AdvancedGridButtonComponent
   */
  @Input() readonly = false;

  /**
   * The type of the button.
   * Currently supports 'text' and 'loading'.
   * Default value is 'text'.
   * @type {AdvancedGridButtonType}
   */
  @Input() buttonType: AdvancedGridButtonType = 'text';

  /**
   * The name of the button
   * @memberof AdvancedGridButtonComponent
   */
  @Input() buttonName = '';

  /**
   * The state of the loading button
   * @type {LoadingButtonStateInterface}
   * @memberof AdvancedGridButtonComponent
   */
  @Input() loadingButtonState?: LoadingButtonStateInterface;

  /**
   * Handles the clicked event from the button
   * @memberof AdvancedGridButtonComponent
   */
  @Output() buttonClicked = new EventEmitter();
}
