import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { RecentRecordsEffects } from './+state/effects/recent-records/recent-records.effects';
import { RECENT_RECORDS_FEATURE_KEY, reducer } from './+state/reducers/recent-records/recent-records.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(RECENT_RECORDS_FEATURE_KEY, reducer)],
  providers: [EffectsBootstrapProvider.provide([RecentRecordsEffects])],
})
export class RecentRecordsStateModule {}
