import { InputTextComponent } from '../../input-text/input-text.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for TEXT renderer component
 *
 */
export const TEXT_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: InputTextComponent,
  creatorFn: formControlComponentCreator,
};
