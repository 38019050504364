import { Injectable } from '@angular/core';
import {
  GetTaskApiInterfaceQueryService,
  GetTasksApiInterfaceQueryService,
  GetTasksCountApiInterfaceQueryService,
  RecordTaskApiInterface,
  RecordTaskUpdateApiInterfaceMutationService,
  RecordTaskUpdateInputApiInterface,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { TaskInterface } from '../../+state/models/task/task.models';
import { NormaliseGetTaskService } from '../normalise-get-tasks/normalise-get-tasks.service';
import { NormalisedTaskList } from '../normalise-get-tasks/normalise-get-tasks.validation';

export const TASK_READ_ERROR = 'No response data from read Task returned from API.';
export const TASK_READ_ALL_ERROR = 'No response data from read all Task returned from API.';
export const TASK_READ_TOTALS_ERROR = 'No response data from read Task Totals returned from API.';
export const TASK_UPDATE_ERROR = 'No response data from update task returned from API.';

/**
 * Task API service.
 * @export
 * @class TaskService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskService {
  /**
   * Creates an instance of TaskService
   * @param {GetTaskApiInterfaceQueryService} taskQueryService The service to fetch a Task.
   * @param {GetTasksApiInterfaceQueryService} tasksQueryService The service to fetch all Tasks.
   * @param {GetTasksCountApiInterfaceQueryService} tasksCountQueryService The Task Count Query service.
   * @param {RecordTaskUpdateApiInterfaceMutationService} taskUpdateMutationService The Task update service.
   * @param {NormaliseGetTaskService} normaliseGetTaskService The Task data normalising service.
   * @memberof TaskService
   */
  constructor(
    private readonly taskQueryService: GetTaskApiInterfaceQueryService,
    private readonly tasksQueryService: GetTasksApiInterfaceQueryService,
    private readonly tasksCountQueryService: GetTasksCountApiInterfaceQueryService,
    private readonly taskUpdateMutationService: RecordTaskUpdateApiInterfaceMutationService,
    private readonly normaliseGetTaskService: NormaliseGetTaskService
  ) {}

  /**
   * Query to get a Task by id.
   * @param {string} recordId The record ID the task belongs to.
   * @param {string} taskId The Task ID.
   * @return {Observable<NormalisedTaskList>} The Task normalised data.
   * @memberof TaskService
   */
  read(recordId: string, taskId: string): Observable<NormalisedTaskList> {
    return this.taskQueryService
      .fetch({ input: { recordId, taskId } })
      .pipe(
        switchMap(({ data }) =>
          data?.task ? of(this.normaliseGetTaskService.extract([data.task])) : throwError(() => TASK_READ_ERROR)
        )
      );
  }

  /**
   * Query to get all Tasks.
   * @return {Observable<NormalisedTaskList>} The Task normalised data.
   * @memberof TaskService
   */
  readAll(): Observable<NormalisedTaskList> {
    return this.tasksQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.tasks
            ? of(
                this.normaliseGetTaskService.extract(
                  data.tasks.filter((task): task is RecordTaskApiInterface => !!task)
                )
              )
            : throwError(() => TASK_READ_ALL_ERROR)
        )
      );
  }

  /**
   * Read the total amount of tasks and new tasks.
   * @return {Observable<RecordTaskApiInterface>} Interface containing the total tasks and new tasks.
   * @memberof TaskService
   */
  readTotals(): Observable<RecordTaskApiInterface[]> {
    return this.tasksCountQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.tasks
            ? of(data.tasks.filter((task): task is RecordTaskApiInterface => !!task))
            : throwError(() => TASK_READ_TOTALS_ERROR)
        )
      );
  }

  /**
   * Update a task on the API.
   * @param {TaskInterface} task The task to update.
   * @param {string | null} requestId The requestId.
   * @return {Observable<TaskInterface>} The updated task persisted to the API.
   * @memberof TaskService
   */
  update(task: TaskInterface, requestId: string | null = null): Observable<TaskInterface> {
    const { recordId, taskId } = task;
    const input: RecordTaskUpdateInputApiInterface = {
      newTask: task.newTask,
      recordId,
      taskId,
    };

    return this.taskUpdateMutationService
      .mutate({ input }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.recordTaskUpdate.task?.taskId ? of({ ...task }) : throwError(() => TASK_UPDATE_ERROR)
        )
      );
  }
}
