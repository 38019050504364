import {
  CommonRoutesEnum,
  GlobalNavLinkInterface,
  NavigationLinkLabels,
  StandaloneRouteNamesEnum,
  UserProfilePermissionsEnum,
} from '@surecloud/common';

/**
 * List of global designer links
 */
const GLOBAL_DESIGNER_NAV_LINKS_LIST: GlobalNavLinkInterface[] = [
  {
    route: `/${CommonRoutesEnum.OptionListsRoot}`,
    label: NavigationLinkLabels.OptionLists,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.optionlist,
  },
  {
    route: `/${CommonRoutesEnum.QuestionLibraryRoot}`,
    label: NavigationLinkLabels.QuestionLibrary,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.question,
  },
  {
    route: `/${CommonRoutesEnum.RiskMatrixRoot}`,
    label: NavigationLinkLabels.RiskMatrix,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.riskmatrix,
  },
  {
    route: `/${CommonRoutesEnum.EntityRoot}`,
    label: NavigationLinkLabels.Workflows,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.workflow,
  },
  {
    route: `/${CommonRoutesEnum.CustomHierarchyRoot}`,
    label: NavigationLinkLabels.CustomHierarchy,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.customHierarchy,
  },
  {
    route: `/${CommonRoutesEnum.IntegrationListRoot}`,
    label: NavigationLinkLabels.Integration,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.integration,
  },
];

/**
 * List of global nav links
 */
export const GLOBAL_NAV_LINKS_LIST: GlobalNavLinkInterface[] = [
  {
    route: `/${CommonRoutesEnum.ApplicationList}`,
    label: NavigationLinkLabels.MyApplications,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.application,
  },
  {
    route: `/${CommonRoutesEnum.DashboardRoot}`,
    label: NavigationLinkLabels.Reporting,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.dashboard,
  },
  {
    route: `/${CommonRoutesEnum.UserManagementRoot}`,
    label: NavigationLinkLabels.ManageUsers,
    isEndOfSection: false,
    permission: UserProfilePermissionsEnum.user,
  },
  {
    label: NavigationLinkLabels.SystemSetUp,
    isEndOfSection: false,
    links: GLOBAL_DESIGNER_NAV_LINKS_LIST,
  },
];
/**
 * List of global nav links
 */
export const GLOBAL_NAV_LINKS_LIST_DESIGN_V2: GlobalNavLinkInterface[] = [
  {
    route: `/${StandaloneRouteNamesEnum.Home}`,
    label: NavigationLinkLabels.Home,
    isEndOfSection: false,
  },
  ...GLOBAL_NAV_LINKS_LIST,
];
