import Block from 'quill/blots/block';
import { EDITOR_DEFAULT_FORMAT, EDITOR_FORMATS, EDITOR_FORMAT_ID_ATTRIBUTE } from '../../editor.component.constants';
import { EditorComponentFormatIdTypes } from '../../editor.component.interface';

/**
 * Custom block format types blot.
 * This blot drives the primary dropdown in the EditorComponent.
 * @export
 * @class FormatTypesBlot
 * @extends {Block}
 */
export class FormatTypesBlot extends Block {
  /**
   * Create a specific format for a given type from the primary EditorComponent dropdown menu.
   * @static
   * @param {EditorComponentFormatIdTypes} value The format id from the value attribute on the primary EditorComponent dropdown menu or gleaned from the format method.
   * @return {HTMLElement} The create element that should be rendered into the editor.
   * @memberof FormatTypesBlot
   */
  static override create(value: EditorComponentFormatIdTypes): HTMLElement {
    const format = EDITOR_FORMATS.find((item) => item.id === value);
    const id = format?.id || EDITOR_DEFAULT_FORMAT;
    const tagName = format?.tagName || 'p';
    const type = format?.type || EDITOR_DEFAULT_FORMAT;

    // Create the correct HTML node.
    const node = super.create(tagName);

    // Add our class so we can CSS it
    node.classList.add(`${FormatTypesBlot.className}__${type}`);

    // Add a custom ID so we can reformat it when it comes back from the API.
    node.setAttribute(EDITOR_FORMAT_ID_ATTRIBUTE, id);

    // Add an additional modifier class if needed
    if (type !== id) {
      node.classList.add(`${FormatTypesBlot.className}__${type}--${id}`);
    }

    return node;
  }

  /**
   * Checks the current Block HTML element for a EDITOR_FORMAT_ID_ATTRIBUTE.
   * If it exists we return the value of that attribute so we can correctly render the element in the create function.
   * Otherwise it will return the default format.
   * @static
   * @param {HTMLElement} element The element to check for a format ID.
   * @return {EditorComponentFormatIdTypes} The format ID the element should render as.
   * @memberof FormatTypesBlot
   */
  static override formats(element: HTMLElement): EditorComponentFormatIdTypes {
    return (element.getAttribute(EDITOR_FORMAT_ID_ATTRIBUTE) as EditorComponentFormatIdTypes) || EDITOR_DEFAULT_FORMAT;
  }
}

FormatTypesBlot.blotName = 'sc-formats';
FormatTypesBlot.tagName = ['h1', 'h2', 'p', 'div'];
FormatTypesBlot.className = 'sc-formats';

