import { createAction, props } from '@ngrx/store';
import { RequestIdInterface } from '@surecloud/common';
import { RecordComponentValueInterface } from '@surecloud/record';

/**
 * An object grouping the Record List page actions
 * @export
 */
export const StandaloneRecordListActions = {
  standaloneRecordListEnter: createAction('[Standalone Record List Page] Enter'),
  standaloneRecordListLeave: createAction('[Standalone Record List Page] Leave'),
  recordListUpdateRecord: createAction(
    '[Standalone Record List Page] Update Record',
    props<{ recordComponentValue: RecordComponentValueInterface }>()
  ),
  recordListCreateRecord: createAction('[Standalone Record List Page] Create New Record', props<RequestIdInterface>()),
  recordListStartDeleteRecords: createAction(
    '[Standalone Record List Page] Start Delete Records',
    props<{ recordIds: string[] }>()
  ),
  recordListExportRecords: createAction(
    '[Standalone Record List Page] Export Records',
    props<{ exportName: string; entityId: string; attributeIds: string[]; recordIds: string[] }>()
  ),
};
