import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '@surecloud/auth';
import { ToolbarUserMenuPageActions } from '../../+state/actions/toolbar-user-menu.actions';
import { ToolbarFeatureFacade } from '../../+state/facades/toolbar.facade';

/**
 * Toolbar User Menu Component.
 * Parent to all dumb User Menu Components.
 * @export
 * @class ToolbarUserMenuComponent
 */
@Component({
  selector: 'toolbar-feature-user-menu',
  templateUrl: './toolbar-user-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserMenuComponent {
  /**
   * Is the Design Version 2 feature enabled.
   * @memberof ToolbarUserMenuComponent
   */
  @Input() isDesignV2Enabled = false;

  /**
   * Creates an instance of ToolbarUserMenuComponent.
   * @param {AuthService} authorisation The Application Authorisation service.
   * @param {ToolbarFeatureFacade} facade The Toolbar Feature Facade service.
   * @memberof ToolbarUserMenuComponent
   */
  constructor(private readonly authorisation: AuthService, public readonly facade: ToolbarFeatureFacade) {}

  /**
   * When a user clicks the logout icon.
   * Then log that user out from the application.
   * @memberof ToolbarUserMenuComponent
   */
  logout(): void {
    this.authorisation.logout();
  }

  /**
   * When the User Menu has been toggled to show or hide.
   * Then dispatch ToolbarUserMenuPageActions.toggle.
   * @param {Event} event The click event on the User Icon.
   * @memberof ToolbarUserMenuComponent
   */
  toggle(event: Event): void {
    event.stopPropagation();
    this.facade.dispatch(ToolbarUserMenuPageActions.toggle());
  }
}
