/**
 * Fixed size stack data structure implementation
 * Not all stack methods are implemented as they are not needed for now
 *
 * @export
 * @class FixedSizeStack
 */
export class FixedSizeStack<T> {
  /**
   * The elements of the stack
   *
   * @type {T[]}
   */
  elements: T[] = [];

  /**
   * The maximum size of the stack
   *
   * @type {number}
   */
  size: number;

  /**
   * Creates an instance of FixedSizeStack.
   *
   * @param {number} size The fixed size of the stack.
   * @memberof BaseFacade
   */
  constructor(size: number) {
    this.size = size;
  }

  /**
   * Add an element to the stack.
   *
   * @param {T} element The element to add at the top of the stack.
   * @memberof FixedSizeStack
   */
  push(element: T): void {
    if (this.elements.length === this.size) this.elements.shift();
    this.elements.push(element);
  }

  /**
   * Get the elements of the stacl
   *
   * @return {T[]} A mutated array of the stack elements in LIFO order
   * @memberof BaseFacade
   */
  get(): T[] {
    return this.elements.reverse();
  }
}
