import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RuleTriggerInterface } from '../../models/rule-trigger.models';
import { RuleTriggerActions } from './rule-trigger.actions';

export const RULE_TRIGGER_FEATURE_KEY = 'ruleTrigger';

export interface StateInterface extends EntityState<RuleTriggerInterface> {
  selectedId?: string; // which Workflow RuleTrigger has been selected
  loaded: boolean; // has the Workflow RuleTrigger list been loaded
  error?: string | null; // last known error (if any)
}

export interface RuleTriggerPartialStateInterface {
  readonly [RULE_TRIGGER_FEATURE_KEY]: StateInterface;
}

export const ruleTriggerAdapter: EntityAdapter<RuleTriggerInterface> = createEntityAdapter<RuleTriggerInterface>({
  selectId: (value) => value.triggerId,
});

export const initialState: StateInterface = ruleTriggerAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const ruleTriggerReducer = createReducer(
  initialState,
  on(RuleTriggerActions.readRuleTriggerList, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(RuleTriggerActions.readRuleTriggerListSuccess, (state: StateInterface, { ruleTriggerList }) =>
    ruleTriggerAdapter.setAll(ruleTriggerList, { ...state, loaded: true })
  ),
  on(RuleTriggerActions.readRuleTriggerListFailure, (state: StateInterface, { error }) => ({
    ...state,
    error,
  })),
  on(RuleTriggerActions.selectRuleTrigger, (state: StateInterface, { triggerId }) => ({
    ...state,
    selectedId: triggerId,
  })),
  on(RuleTriggerActions.deleteRuleTriggerSuccess, (state: StateInterface, { triggerId }) =>
    ruleTriggerAdapter.removeOne(triggerId, state)
  ),
  on(RuleTriggerActions.updateRuleTriggerSuccess, (state: StateInterface, { partialRuleTrigger }) =>
    partialRuleTrigger.triggerId
      ? ruleTriggerAdapter.updateOne({ id: partialRuleTrigger.triggerId, changes: partialRuleTrigger }, state)
      : state
  )
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific state action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX state action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return ruleTriggerReducer(state, action);
}
