import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { fromPageState, LoggerService } from '@surecloud/common';
import { Store } from '@ngrx/store';
import { HomepageApplicationsService } from '../../../services/homepage-applications/homepage-applications.service';
import { HomepageApplicationsActions } from '../../actions/homepage-applications.actions';

/**
 * The Effects/side effects for HomepageApplications.
 * @export
 * @class HomepageApplicationsEffects
 */
@Injectable({ providedIn: 'root' })
export class HomepageApplicationsEffects {
  /**
   * When reading a HomepageApplications.
   * Then load all HomepageApplications data from the API.
   * @memberof HomepageApplicationsEffects
   */
  loadHomepageApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageApplicationsActions.readHomepageApplications),
      mergeMap(() =>
        this.homepageApplicationsService.readAll().pipe(
          map((normalisedHomepageApplications) =>
            HomepageApplicationsActions.readHomepageApplicationsDataSuccess({
              normalisedHomepageApplications,
            })
          ),
          catchError((error: unknown) =>
            of(HomepageApplicationsActions.readHomepageApplicationsFailure({ error: `${error}` }))
          )
        )
      )
    )
  );

  /**
   * When the HomepageApplications has received a response.
   * Then load the result into state.
   * @memberof HomepageApplicationsEffects
   */
  setHomepageApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageApplicationsActions.readHomepageApplicationsDataSuccess),
      withLatestFrom(this.store.select(fromPageState.getLatestBreadcrumbTabId)),
      map(([{ normalisedHomepageApplications }, tabId]) =>
        HomepageApplicationsActions.readHomepageApplicationsSuccess({
          homepageApplications: normalisedHomepageApplications.homepageApplications,
          tabId,
        })
      )
    )
  );

  /**
   * When the user select a tab in the Homepage.
   * Then load the select the Homepage Application.
   * @memberof HomepageApplicationsEffects
   */
  selectHomepageApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageApplicationsActions.selectHomepageApplication),
      map(({ applicationName }) =>
        HomepageApplicationsActions.selectHomepageApplicationSuccess({
          applicationName,
        })
      )
    )
  );

  /**
   * Logs any errors.
   * @memberof HomepageApplicationsEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          HomepageApplicationsActions.readHomepageApplicationsFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Homepage Applications', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of HomepageApplicationsEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {HomepageApplicationsService} homepageApplicationsService The HomepageApplications API service.
   * @param {LoggerService} logger The application logging service.
   * @param {Store} store The NGRX Store.
   * @memberof HomepageApplicationsEffects
   */
  constructor(private readonly actions$: Actions, private readonly homepageApplicationsService: HomepageApplicationsService, private readonly logger: LoggerService, private store: Store) {}
}
