import {
  BooleanColumnFilterTypeApiInterface,
  ColumnSortOrderApiInterface,
  ColumnTypeApiInterface,
  JoinColumnFilterTypeApiInterface,
  ScalarColumnFilterTypeApiInterface,
  TextColumnFilterTypeApiInterface,
} from '@surecloud/api-types';
import { ValueOf } from '../utility-types';
import { PaginationInterface } from './grid-pagination.interface';

/**
 * Interface for the grid state.
 */
export interface GridStateInterface {
  pagination: PaginationInterface | null;
  sorting: ColumnSortInterface[];
  filters?: ColumnFilterInterface[];
  quickFilter?: string;
}

/**
 * Column sort interface.
 */
export interface ColumnSortInterface {
  columnId: string;
  columnType: ValueOf<typeof ColumnTypesUIEnum>;
  order: ValueOf<typeof ColumnSortTypeUIEnum>;
}

/**
 * Column type enum using API values
 */
export const ColumnTypesUIEnum: { [p: string]: ColumnTypeApiInterface } = {
  Text: ColumnTypeApiInterface.TextApiInterface,
  Number: ColumnTypeApiInterface.NumberApiInterface,
  Boolean: ColumnTypeApiInterface.BooleanApiInterface,
  Date: ColumnTypeApiInterface.DateApiInterface,
};

/**
 * Column sort order enum using API values
 */
export const ColumnSortTypeUIEnum: { [p: string]: ColumnSortOrderApiInterface } = {
  Asc: ColumnSortOrderApiInterface.AscApiInterface,
  Desc: ColumnSortOrderApiInterface.DescApiInterface,
};

/**
 * Column filter interface.
 */
export type ColumnFilterInterface = {
  booleanColumnFilter?: BooleanColumnFilter;
  dateColumnFilter?: DateColumnFilterInterface;
  joinColumnFilter?: JoinColumnFilterInterface;
  numberColumnFilter?: NumberColumnFilterInterface;
  textColumnFilter?: TextColumnFilterInterface;
};

/**
 * Boolean column filter.
 */
export type BooleanColumnFilter = {
  columnId: string;
  type: ValueOf<typeof BooleanColumnFilterTypeUIEnum>;
};

/**
 * Boolean column filter type enum using BooleanColumnFilterTypeApiInterface enum values.
 */
export const BooleanColumnFilterTypeUIEnum: { [p: string]: BooleanColumnFilterTypeApiInterface } = {
  False: BooleanColumnFilterTypeApiInterface.FalseApiInterface,
  True: BooleanColumnFilterTypeApiInterface.TrueApiInterface,
};

/**
 * Date column filter interface.
 */
export type DateColumnFilterInterface = {
  columnId: string;
  filter?: string;
  filterTo?: string;
  type: ValueOf<typeof ColumnFilterTypeUIEnum>;
};

/**
 * Column filter type enum using ScalarColumnFilterTypeApiInterface enum values.
 */
export const ColumnFilterTypeUIEnum: { [p: string]: ScalarColumnFilterTypeApiInterface } = {
  Blank: ScalarColumnFilterTypeApiInterface.BlankApiInterface,
  Equals: ScalarColumnFilterTypeApiInterface.EqualsApiInterface,
  GreaterThan: ScalarColumnFilterTypeApiInterface.GreaterThanApiInterface,
  GreaterThanOrEqual: ScalarColumnFilterTypeApiInterface.GreaterThanOrEqualApiInterface,
  LessThan: ScalarColumnFilterTypeApiInterface.LessThanApiInterface,
  LessThanOrEqual: ScalarColumnFilterTypeApiInterface.LessThanOrEqualApiInterface,
  NotBlank: ScalarColumnFilterTypeApiInterface.NotBlankApiInterface,
  NotEqual: ScalarColumnFilterTypeApiInterface.NotEqualApiInterface,
  InRange: ScalarColumnFilterTypeApiInterface.InRangeApiInterface,
};

/**
 * Join column filter interface.
 */
export interface JoinColumnFilterInterface {
  conditions: ColumnFilterInterface[];
  type: ValueOf<typeof JoinColumnFilterTypeUIEnum>;
}

/**
 * Join column filter type enum using JoinColumnFilterTypeApiInterface enum values.
 */
export const JoinColumnFilterTypeUIEnum: { [p: string]: JoinColumnFilterTypeApiInterface } = {
  And: JoinColumnFilterTypeApiInterface.AndApiInterface,
  Or: JoinColumnFilterTypeApiInterface.OrApiInterface,
};

/**
 * Number column filter interface.
 */
export interface NumberColumnFilterInterface {
  columnId: string;
  filter?: number;
  filterTo?: number;
  type: ValueOf<typeof ColumnFilterTypeUIEnum>;
}

/**
 * Text column filter interface.
 */
export interface TextColumnFilterInterface {
  columnId: string;
  filter?: string;
  type: ValueOf<typeof TextColumnFilterTypeUIEnum>;
}

/**
 * Text column filter type enum using TextColumnFilterTypeApiInterface enum values.
 */
export const TextColumnFilterTypeUIEnum: { [p: string]: TextColumnFilterTypeApiInterface } = {
  Blank: TextColumnFilterTypeApiInterface.BlankApiInterface,
  Contains: TextColumnFilterTypeApiInterface.ContainsApiInterface,
  EndsWith: TextColumnFilterTypeApiInterface.EndsWithApiInterface,
  Equals: TextColumnFilterTypeApiInterface.EqualsApiInterface,
  NotBlank: TextColumnFilterTypeApiInterface.NotBlankApiInterface,
  NotContains: TextColumnFilterTypeApiInterface.NotContainsApiInterface,
  NotEqual: TextColumnFilterTypeApiInterface.NotEqualApiInterface,
  StartsWith: TextColumnFilterTypeApiInterface.StartsWithApiInterface,
};
