import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SvgIconsModule } from '../../../icon/icons/svg-icons.module';

@Component({
  standalone: true,
  imports: [CommonModule, SvgIconsModule],
  selector: 'sc-grid-row-drag-renderer',
  templateUrl: './grid-row-drag-renderer.component.html',
  styleUrls: ['./grid-row-drag-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridRowDragRendererComponent implements AfterViewInit, ICellRendererAngularComp {
  @ViewChild('dragContainer', { read: ElementRef, static: true }) dragContainer: ElementRef | undefined;

  private cellRendererParams!: ICellRendererParams;

  ngAfterViewInit(): void {
    this.cellRendererParams.registerRowDragger(this.dragContainer?.nativeElement);
  }

  agInit(params: ICellRendererParams): void {
    this.cellRendererParams = params;
    params.api.updateGridOptions({
      rowDragManaged: true,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
