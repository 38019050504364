import { createAction, props } from '@ngrx/store';
import { RecordLinkedImportModalInterface } from '../../models/record-import';

export const RecordImportActions = {
  openRecordImportModal: createAction(
    '[Record Import] Open Record Import Modal',
    props<{ modalDetails: RecordLinkedImportModalInterface }>()
  ),
  cancelRecordImportModal: createAction('[Record Import] Cancel Record Import Modal'),

  startImportRecords: createAction('[Record Import]Start Import Records', props<{ file: File }>()),
  importRecords: createAction('[Record Import] Import Records', props<{ file: File }>()),
  importLinkedRecords: createAction('[Record Import] Import Linked Records', props<{ file: File }>()),
  importRecordsSuccess: createAction('[Record Import] Import Records Success'),
  importRecordsFailure: createAction('[Record Import] Import Records Failure', props<{ error: string }>()),
};
