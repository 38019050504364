import { UrgencyTypeEnum, makeHumanReadableZodErrors } from '@surecloud/common';
import { z } from 'zod';

/**
 * Normalise Get PriorityTasks Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetPriorityTasksService.
 */
export const urgencyTypeZodEnum = z.nativeEnum(UrgencyTypeEnum);

/**
 * Tasks Zod schema.
 */
export const tasksZodSchema = z.object({
  due: z.string(),
  summary: z.string().nullable(),
  taskId: z.string(),
  urgency: urgencyTypeZodEnum,
  dueDate: z.string(),
  recordId: z.string(),
});

/**
 * PriorityTasks Zod schema.
 */
export const priorityTasksZodSchema = z.object({
  tasks: z.array(tasksZodSchema),
  totalIncomplete: z.number(),
});

/**
 * Normalised PriorityTasks Zod schema.
 */
export const normalisedPriorityTasksZodSchema = z.object({
  priorityTasks: priorityTasksZodSchema,
});

/**
 * Normalised PriorityTasks data interface.
 */
export type NormalisedPriorityTasks = z.infer<typeof normalisedPriorityTasksZodSchema>;

/**
 * Guard to check the normalised PriorityTasks data matches the schema.
 *
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedPriorityTasks.
 */
export const isNormalisedPriorityTasksGuard = (map: unknown): map is NormalisedPriorityTasks =>
  normalisedPriorityTasksZodSchema.safeParse(map).success;

/**
 * When the normalised PriorityTasks data does not match the schema
 * then get the error as to show where the problem is.
 *
 * @param {unknown} map Map of the data to check.
 * @return {string} The error on where the map of the data does not match a NormalisedPriorityTasks.
 */
export const getNormalisedPriorityTasksError = (
  map: unknown
): string => {
  const zodResult = normalisedPriorityTasksZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};
