import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { fromQuestionSet, QuestionSetEffects } from '@surecloud/question-set-state';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { EntityStateAttributeEffects } from './+state/effects/attribute/attribute.effects';
import { EntityStateEffects } from './+state/effects/entity/entity.effects';
import { EntityStateAttributeFormatEffects } from './+state/effects/format/format.effects';
import { LinkedEntityTagEffects } from './+state/effects/linked-entity-tag/linked-entity-tag.effects';
import { LinkedEntityEffects } from './+state/effects/linked-entity/linked-entity.effects';
import { EntityStateAttributeRuleEffects } from './+state/effects/rule/rule.effects';
import * as fromAttribute from './+state/reducers/attribute/attribute.reducer';
import * as fromEntity from './+state/reducers/entity/entity.reducer';
import * as fromFormat from './+state/reducers/format/format.reducer';
import * as fromLinkedEntityTag from './+state/reducers/linked-entity-tag/linked-entity-tag.reducer';
import * as fromLinkedEntity from './+state/reducers/linked-entity/linked-entity.reducer';
import * as fromRule from './+state/reducers/rule/rule.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromEntity.ENTITY_FEATURE_KEY, fromEntity.reducer),
    StoreModule.forFeature(fromAttribute.ATTRIBUTE_FEATURE_KEY, fromAttribute.reducer),
    StoreModule.forFeature(fromFormat.FORMAT_FEATURE_KEY, fromFormat.reducer),
    StoreModule.forFeature(fromRule.RULE_FEATURE_KEY, fromRule.reducer),
    StoreModule.forFeature(fromLinkedEntity.LINKED_ENTITY_FEATURE_KEY, fromLinkedEntity.reducer),
    StoreModule.forFeature(fromLinkedEntityTag.LINKED_ENTITY_TAG_FEATURE_KEY, fromLinkedEntityTag.reducer),
    StoreModule.forFeature(fromQuestionSet.QUESTION_SET_FEATURE_KEY, fromQuestionSet.reducer),
  ],
  providers: [
    EffectsBootstrapProvider.provide([
      EntityStateEffects,
      EntityStateAttributeEffects,
      EntityStateAttributeFormatEffects,
      EntityStateAttributeRuleEffects,
      LinkedEntityEffects,
      LinkedEntityTagEffects,
      QuestionSetEffects,
    ]),
  ],
})
export class EntityStateModule {}
