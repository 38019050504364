<label class="sc-checkbox flex align-items-center">
  <input
    #checkbox
    scCommonE2eHook="{{ testhook }}:checkbox"
    kendoCheckBox
    type="checkbox"
    [formControl]="control"
    [attr.disabled]="control.disabled"
    [ngClass]="{ checked: control.value }" />

  <span *ngIf="label" class="sc-font-table-entry sc-text sc-ml-8">{{ label }}</span>
</label>
