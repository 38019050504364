import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ATTRIBUTE_PERMISSION_FEATURE_KEY,
  StateInterface,
  entityAttributePermissionAdapter,
} from '../../../reducers/permission/attribute-permission/attribute-permission.reducer';
import * as AttributeSelectors from '../../attribute/attribute.selectors';

// Lookup the 'Entity Attribute Permission' feature state managed by NgRx
export const getEntityAttributePermissionState = createFeatureSelector<StateInterface>(
  ATTRIBUTE_PERMISSION_FEATURE_KEY
);

const { selectAll } = entityAttributePermissionAdapter.getSelectors();

/**
 * Get all Entity Attribute Permissions.
 */
export const getEntityAttributePermissionList = createSelector(
  getEntityAttributePermissionState,
  (state: StateInterface) => selectAll(state)
);

/**
 * Get the Entity attribute Roles to be selected in the edit attribute page
 * In an interface that can be given to the dropdown.
 */
export const getSelectedAttributeRolePermissions = createSelector(
  AttributeSelectors.getSelectedId,
  getEntityAttributePermissionList,
  (selectedAttributeId, entityAttributePermissionList) =>
    entityAttributePermissionList.filter((permission) => permission.attributeId === selectedAttributeId)
);
