/**
 * Slider class type enum
 *
 * @export
 * @enum {number}
 */
export enum SliderClassTypeEnum {
  Risk = 'sc-slider-risk',
  Primary = 'sc-slider-primary',
  Secondary = 'sc-slider-secondary',
}
