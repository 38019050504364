import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthClientConfig as Auth0ClientConfig, AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { ENV_CONFIG_TOKEN } from '@surecloud/common';
import { initialiseAuth0Factory } from './services/factory/initialise-auth0.factory';

/**
 * Allows functionality that depends on AuthService to be imported and used by other modules.
 * Functionality includes: RouteGuards & Services.
 *
 * @export
 * @class AuthSharedModule
 */
@NgModule({
  imports: [HttpClientModule, Auth0Module.forRoot()],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialiseAuth0Factory,
      deps: [ENV_CONFIG_TOKEN, Auth0ClientConfig],
      multi: true,
    },
  ],
})
export class AuthSharedModule {}
