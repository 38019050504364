/**
 * User Group Type Enum
 *
 * @export
 * @enum {number}
 */
export enum UserGroupTypeEnum {
  Group = 'GROUP',
  User = 'USER',
}
