import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CommonActions,
  CommonRoutesEnum,
  ENTITY_BREADCRUMB_ROUTE,
  EntityInterface,
  LoggerService,
  UNTITLED,
  fromPageState,
  selectNestedRouteParam,
} from '@surecloud/common';
import { QuestionSetApiActions } from '@surecloud/question-set-state';
import { EMPTY, catchError, filter, map, mergeMap, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { EntityService } from '../../../services/entity/entity.service';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';
import { LinkedEntityActions } from '../../actions/linked-entity.actions';
import * as EntitySelectors from '../../selectors/entity/entity.selectors';

/**
 * The Effects/side effects for the Entity Feature.
 * @export
 * @class EntityStateEffects
 */
@Injectable({ providedIn: 'root' })
export class EntityStateEffects {
  /**
   * When a user enters the entity list page.
   * Then load of the entity list.
   * @memberof EntityStateEffects
   */
  initEntityList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommonActions.loadEntityList,
        LinkedEntityActions.linkedEntityEnter,
        LinkedEntityActions.selectLinkedEntityEnter
      ),
      map(() => EntityActions.readEntityList())
    )
  );

  /**
   * When reading an entity list, selecting an entity.
   * Then load all entity data from the API.
   * @memberof EntityStateEffects
   */
  loadAllEntityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readEntityList),
      switchMap(() => this.entityService.readAll()),
      map((normalisedEntityList) =>
        EntityActions.readAllEntityDataSuccess({
          normalisedEntityList,
        })
      )
    )
  );

  /**
   * When reading an entity summary list.
   * Then load all entity summary data from the API.
   * @memberof EntityStateEffects
   */
  loadAllEntitySummaryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entityListEnter, EntityActions.readEntitySummaryList),
      switchMap(() =>
        this.entityService.readEntitySummaryList().pipe(
          map((entityList) => EntityActions.readAllEntitySummaryDataSuccess({ entityList })),
          catchError((error: unknown) => of(EntityActions.readAllEntitySummaryDataFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the entity list has received a response.
   * Then load the result into state.
   * @memberof EntityStateEffects
   */
  loadEntityList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readAllEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        EntityActions.readEntityListSuccess({
          entityList: normalisedEntityList.entities,
        })
      )
    )
  );

  /**
   * When loading a single entity.
   * Then handle the success and failure use cases.
   * @memberof EntityStateEffects
   */
  loadEntityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readEntity, EntityActions.selectEntity),
      switchMap(({ entityId }) =>
        entityId
          ? this.entityService.read(entityId).pipe(
              map((normalisedEntityList) => EntityActions.readOneEntityDataSuccess({ normalisedEntityList })),
              catchError((error: unknown) => of(EntityActions.readEntityFailure({ error: `${error}` })))
            )
          : EMPTY
      )
    )
  );

  /**
   * When we have received a normalised entity.
   * Then load it into state.
   * @memberof EntityStateEffects
   */
  loadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        EntityActions.readEntitySuccess({
          entities: normalisedEntityList.entities,
        })
      )
    )
  );

  /**
   * When we have received a normalised entity summary list.
   * Then load it into state.
   * @memberof EntityStateEffects
   */
  loadEntitySummaryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readAllEntitySummaryDataSuccess),
      map(({ entityList }) =>
        EntityActions.readEntityListSuccess({
          entityList,
        })
      )
    )
  );

  /**
   * When a user has submitted the create entity form.
   * Then make a call to the API to create the entity.
   * @memberof EntityStateEffects
   */
  createEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.completeCreateEntity),
      mergeMap(({ requestId }) =>
        this.entityService.create(requestId).pipe(
          map((entity) => EntityActions.createEntitySuccess({ entity })),
          catchError((error: unknown) => of(EntityActions.createEntityFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When an entity is created.
   * Navigate to the entity view.
   * @memberof EntityStateEffects
   */
  entityCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EntityActions.createEntitySuccess),
        tap(({ entity }) => this.router.navigate(['/', CommonRoutesEnum.EntityRoot, entity.entityId]))
      ),
    { dispatch: false }
  );

  /**
   * When entity API called failed.
   * Then log the error.
   * @memberof EntityStateEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EntityActions.createEntityFailure,
          EntityActions.updateEntityFailure,
          EntityActions.deleteEntityFailure,
          EntityActions.readEntityFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Entity', type, error))
      ),
    { dispatch: false }
  );

  /**
   * When a user enters the entity page.
   * Then update the selected entityId.
   * @memberof EntityStateEffects
   */
  selectEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EntityActions.entityEnter,
        EntityActions.entityReEnter,
        EntityAttributeActions.attributeEnter,
        CommonActions.loadEntity
      ),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      map(([, entityId]) => EntityActions.selectEntity({ entityId }))
    )
  );

  /**
   * Set base route for the Entity Breadcrumb State.
   * @memberof EntityStateEffects
   */
  setBaseRouteEntityBreadcrumbState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entityEnterLib),
      map(() =>
        fromPageState.BreadcrumbActions.setBreadcrumbs({
          breadcrumbs: [ENTITY_BREADCRUMB_ROUTE],
        })
      )
    )
  );

  /**
   * Set the Entity Breadcrumb State.
   * @memberof EntityStateEffects
   */
  addEntityBreadcrumbState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entityEnter),
      switchMap(() =>
        this.store.select(EntitySelectors.getSelected).pipe(
          filter((entity): entity is EntityInterface => !!entity),
          withLatestFrom(this.store.select(fromPageState.getBreadcrumbs)),
          takeUntil(this.actions$.pipe(ofType(EntityActions.entityLeave, EntityActions.entityEnter)))
        )
      ),
      map(([{ entityId, name }, breadcrumbs]) => {
        const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
        return fromPageState.BreadcrumbActions.setBreadcrumbs({
          breadcrumbs: [
            // If the last breadcrumb is the same as the current entity, remove the last breadcrumb
            ...(lastBreadcrumb.id === entityId ? breadcrumbs.slice(0, -1) : breadcrumbs),
            {
              id: entityId,
              label: name,
              route: ['/', CommonRoutesEnum.EntityRoot, entityId],
            },
          ],
        });
      })
    )
  );

  /**
   * When a user updates the entity.
   * Make a call to update the entity on the API.
   * @memberof EntityStateEffects
   */
  updateEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.completeUpdateEntity),
      switchMap(({ entity }) =>
        this.entityService.update(entity).pipe(
          map((updatedEntity) => EntityActions.updateEntitySuccess({ entity: updatedEntity })),
          catchError((error: unknown) => of(EntityActions.updateEntityFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user starts the delete entity journey by clicking delete entity.
   * Then open the confirm delete entity modal.
   * @memberof EntityStateEffects
   */
  enterDeleteEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.startDeleteEntity),
      map(({ entity }) => {
        const title = $localize`Confirm Delete Entity`;
        const content = $localize`Are you sure you want to delete the ${entity.name || UNTITLED} entity?`;

        const confirmAction = EntityActions.completeDeleteEntity({ entity });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels or confirms the deletion of an entity.
   * Then close the confirm delete entity modal.
   * @memberof EntityStateEffects
   */
  leaveDeleteEntity$ = createEffect(() =>
    this.actions$.pipe(ofType(EntityActions.completeDeleteEntity), map(CommonActions.closeConfirmationModal))
  );

  /**
   * When a user has confirmed to delete an entity.
   * Then delete that entity through the API.
   * @memberof EntityStateEffects
   */
  deleteEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.completeDeleteEntity),
      switchMap(({ entity }) =>
        this.entityService.delete(entity).pipe(
          map((entityId) => EntityActions.deleteEntitySuccess(entityId)),
          catchError((error: unknown) => of(EntityActions.deleteEntityFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a entity tab has been selected by a user.
   * Then navigate a user to that route.
   * @memberof EntityStateEffects
   */
  navigateToTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EntityActions.selectEntityTab),
        tap(({ route }) => {
          if (route) {
            this.router.navigateByUrl(route);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * When we have received a normalised entity.
   * Then load all Question Set data from the API.
   * @memberof EntityStateEffects
   */
  loadQuestionSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        QuestionSetApiActions.readQuestionSetsDataSuccess({
          normalisedQuestionSetList: { questionSets: normalisedEntityList.questionSets || [] },
        })
      )
    )
  );

  /**
   * Creates an instance of EntityStateEffects.
   * @param {Actions} actions$ The entity actions.
   * @param {EntityService} entityService The entity API service.
   * @param {LoggerService} logger The application logging service.
   * @param {Store} store The application ,
   * @param {Router} router The Angular Router service.
   * @memberof EntityStateEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly entityService: EntityService,
    private readonly logger: LoggerService,
    private readonly store: Store,
    private readonly router: Router
  ) {}
}
