import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RecentRecordsActions } from '../../actions/recent-records.actions';
import { RecentRecordInterface } from '../../models/recent-records/recent-records.models';

export const RECENT_RECORDS_FEATURE_KEY = 'recentRecords';

export interface StateInterface extends EntityState<RecentRecordInterface> {
  selectedId?: string; // which record has been selected
  loaded: boolean;
  error?: string | null;
}

export interface RecentRecordsPartialStateInterface {
  readonly [RECENT_RECORDS_FEATURE_KEY]: StateInterface;
}

export const recentRecordsAdapter: EntityAdapter<RecentRecordInterface> =
  createEntityAdapter<RecentRecordInterface>({
    selectId: (record: RecentRecordInterface) => record.recordId,
  });

export const initialState: StateInterface = recentRecordsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * recentRecords reducer.
 */
const recentRecordsReducer = createReducer(
  initialState,

  on(RecentRecordsActions.readRecentRecords, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(RecentRecordsActions.readRecentRecordsSuccess, (state: StateInterface, { recentRecords }) => recentRecordsAdapter.setAll(recentRecords, { ...state, loaded: true })),
  on(RecentRecordsActions.readRecentRecordsFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  }))
);

/**
 * Perform reducer logic on the Recent Records NGRX state store for a specific Recent Records actions.
 *
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX Recent Records action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return recentRecordsReducer(state, action);
}
