<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ol class="design-v2-breadcrumb list-none flex align-items-center">
  <li class="flex align-items-center">
    <a
      class="design-v2-breadcrumb__home flex align-items-center justify-content-center"
      routerLinkActive="design-v2-breadcrumb__home--active"
      aria-label="Navigate to home"
      i18n-aria-label
      [routerLink]="homepage">
      <sc-icon colour="grey-800" size="x-large" key="home"></sc-icon>
      <span i18n class="sc-visually-hidden">Home</span>
    </a>
  </li>
  <ng-container *ngIf="breadcrumbItems">
    <ng-container *ngFor="let crumb of breadcrumbItems; let index = index; let last = last; trackBy: trackByFunction">
      <ng-container *ngIf="index === 1 && dropdownItems.length">
        <li class="flex align-items-center">
          <sc-icon colour="grey-800" class="sc-mx-8" size="medium" key="chevron-right"></sc-icon>
          <sc-popup
            icon="more-horizontal"
            themeColour="grey-800"
            [anchorAlign]="CONSTANTS.ALIGN_ANCHOR"
            [closeOn]="CONSTANTS.POPUP_CLOSE_ON_ANY_CLICK"
            [popupAlign]="CONSTANTS.ALIGN_POPUP">
            <div class="design-v2-breadcrumb__dropdown">
              <ng-scrollbar>
                <ol class="design-v2-breadcrumb__dropdown-list list-none">
                  <li
                    class="design-v2-breadcrumb__dropdown-item"
                    *ngFor="let item of dropdownItems; let itemIndex = index; trackBy: trackByFunction">
                    <button
                      attr.aria-label="Navigate to {{ item.label || CONSTANTS.UNTITLED }}"
                      class="design-v2-breadcrumb__dropdown-crumb sc-font-button-regular sc-text-lighter flex align-items-center w-full"
                      type="button"
                      (click)="navigateToRoute(item.route || [], itemIndex + 1)">
                      <sc-icon colour="grey-800" class="sc-mr-12" size="medium" key="chevron-right"></sc-icon>
                      <span class="design-v2-breadcrumb__dropdown-label">{{
                        item.label || CONSTANTS.UNTITLED | truncate : CONSTANTS.MAX_DROPDOWN_LENGTH
                      }}</span>
                    </button>
                  </li>
                </ol>
              </ng-scrollbar>
            </div>
          </sc-popup>
        </li>
      </ng-container>
      <li class="flex align-items-center">
        <sc-icon colour="grey-800" class="sc-mx-8" size="medium" key="chevron-right"></sc-icon>
        <button
          attr.aria-label="Navigate to {{ crumb.label || CONSTANTS.UNTITLED }}"
          class="design-v2-breadcrumb__crumb sc-font-button-regular sc-text-lighter sc-p-8"
          type="button"
          (click)="navigateToRoute(crumb.route, index + dropdownItems.length)"
          *ngIf="crumb.route && !last; else current">
          {{ crumb.label || CONSTANTS.UNTITLED | truncate : CONSTANTS.MAX_LENGTH }}
        </button>
        <ng-template #current>
          <span class="design-v2-font-button-regular design-v2-text-surecloud-blue sc-p-8">
            {{ crumb.label || CONSTANTS.UNTITLED | truncate : CONSTANTS.MAX_LENGTH }}
          </span>
        </ng-template>
      </li>
    </ng-container>
  </ng-container>
</ol>
