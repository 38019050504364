import { EntityAttributeTypeEnum } from '../../interfaces/entity-attribute-type/entity-attribute-type.enum';
import { SectionRendererConfigInterface } from '../../interfaces/section-renderer-config.interface';
import { Optional } from '../../utility-types/utility-types';

/**
 * default Global section renderer Config
 */
const defaultGlobalSectionRendererConfig: SectionRendererConfigInterface = {
  name: 'Enter Attribute Name',
  key: 'Enter Attribute Key',
  label: 'Enter Attribute Label',
  type: EntityAttributeTypeEnum.Text,
  uniqueSections: [],
};

/**
 * Easy create a global setion renderer config
 * @param {Optional<SectionRendererConfigInterface, 'key' | 'name' | 'uniqueSections'>} config - partial config
 * @return {SectionRendererConfigInterface} - Full config
 */
export const makeGlobalSectionRendererConfig = (
  config: Optional<SectionRendererConfigInterface, 'key' | 'name' | 'uniqueSections'>
): SectionRendererConfigInterface => {
  const { type } = config;
  return {
    ...defaultGlobalSectionRendererConfig,
    ...config,
    key: type,
    name: type,
  };
};
