import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { HREF } from '@surecloud/common';
import { TextButtonComponent } from '../button/text-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Surecloud Error Details Component that displays error info on the /error page.
 *
 * @export
 * @class ErrorDetailsComponent
 */
@Component({
  selector: 'sc-error-details',
  standalone: true,
  templateUrl: './error-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./error-details.component.scss'],
  imports: [CommonModule, TextButtonComponent, CommonIconModule, ClipboardModule],
})
export class ErrorDetailsComponent {
  /**
   * Controls the display on the error message details.
   *
   * @memberof ErrorDetailsComponent
   */
  public showError = false;

  /**
   * Constants to use for hrefs
   *
   * @memberof ErrorDetailsComponent
   */
  public href = HREF;

  /**
   * The string to copy to the clipboard.
   *
   * @memberof ErrorDetailsComponent
   */
  @Input() copyToClipboard = '';

  /**
   * The error message to display
   *
   * @type {(string | null | undefined)}
   * @memberof ErrorDetailsComponent
   */
  @Input() errorMessage: string | null | undefined;

  /**
   * The tracking id to display
   *
   * @type {(string | null | undefined)}
   * @memberof ErrorDetailsComponent
   */
  @Input() trackingId: string | null | undefined;

  /**
   * Controls toggling the visiblity of the error message details.
   *
   * @memberof ErrorDetailsComponent
   */
  public toggleError(): void {
    this.showError = !this.showError;
  }
}
