/**
 * Document virus scan status enum definition
 * @export
 * @enum {string}
 */
export enum DocumentVirusScanStatusEnum {
  Clean = 'CLEAN',
  Error = 'ERROR',
  Infected = 'INFECTED',
  Pending = 'PENDING',
}

/**
 * Document upload status enum definition
 * @export
 * @enum {string}
 */
export enum DocumentUploadStatusEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  SuccessfullyUploaded = 'SUCCESSFULLY_UPLOADED',
}
