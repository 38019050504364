import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  linkedEntityTagAdapter,
  LINKED_ENTITY_TAG_FEATURE_KEY,
  StateInterface,
} from '../../reducers/linked-entity-tag/linked-entity-tag.reducer';
import * as EntitySelectors from '../entity/entity.selectors';

// Lookup the 'Entity Linked Entity Tag' feature state managed by NgRx
export const getLinkedEntityTagState = createFeatureSelector<StateInterface>(LINKED_ENTITY_TAG_FEATURE_KEY);

const { selectAll, selectEntities } = linkedEntityTagAdapter.getSelectors();

/**
 * Get all Linked Entity Tags.
 */
export const getLinkedEntityTagList = createSelector(getLinkedEntityTagState, (state: StateInterface) =>
  selectAll(state)
);

/**
 * Get the Linked Entity NGRX entity map from Linked Entity Tag state.
 */
export const getLinkedEntityTagEntities = createSelector(getLinkedEntityTagState, (state: StateInterface) =>
  selectEntities(state)
);

export const getSelectedLinkedEntityTagList = createSelector(
  EntitySelectors.getSelectedId,
  getLinkedEntityTagList,
  (selectedId, linkedEntityTagList) =>
    linkedEntityTagList.filter((linkedEntity) => linkedEntity.entityId === selectedId)
);
