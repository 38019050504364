import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntegrationConnectionStatusEnumToText, ValueOf } from '@surecloud/common';
import { CommonIconModule } from '../../../icon/icons/common-icon.module';
import { ScIconColour } from '../../../icon/icons/icon.interface';
/**
 * Integration chip status component
 * @export
 * @class IntegrationChipStatusComponent
 */
@Component({
  selector: 'sc-integration-chip-status',
  templateUrl: './integration-chip-status.component.html',
  styleUrls: ['./integration-chip-status.component.scss'],
  standalone: true,
  imports: [CommonModule, CommonIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationChipStatusComponent {
  /**
   * Status of the integration
   * @type {IntegrationConnectionStatusEnumToText.NOT_CONNECTED}
   * @memberof IntegrationChipStatusComponent
   */
  @Input() status: string = IntegrationConnectionStatusEnumToText.NOT_CONNECTED;

  /**
   * Quantity of connections
   * @type {number}
   * @memberof IntegrationChipStatusComponent
   */
  @Input() quantityConnection = 0;

  /**
   * Integration Connection Status Enum
   * @memberof IntegrationChipStatusComponent
   */
  integrationConnectionStatusEnum = IntegrationConnectionStatusEnumToText;

  /**
   * Status icon data corresponds
   * to the status of the integration
   * @type {{ [key: ValueOf<typeof IntegrationConnectionStatusEnumToText>]: { icon: string; colour: ScIconColour } }}
   * @memberof IntegrationChipStatusComponent
   */
  statusIcon: { [key: ValueOf<typeof IntegrationConnectionStatusEnumToText>]: { icon: string; colour: ScIconColour } } =
    {
      [IntegrationConnectionStatusEnumToText.CONNECTED]: {
        icon: 'link-hug',
        colour: 'green-300',
      },
      [IntegrationConnectionStatusEnumToText.NOT_CONNECTED]: {
        icon: 'not-connected',
        colour: 'grey-500',
      },
      [IntegrationConnectionStatusEnumToText.AUTHENTICATION_FAILED]: {
        icon: 'alert',
        colour: 'red-400',
      },
    };
}
