<ng-container [formGroup]="parentFormGroup">
  <kendo-formfield class="block" showErrors="initial" [ngClass]="{ 'sc-mb-20': marginBottom }">
    <kendo-label
      [text]="label"
      class="sc-label flex-column"
      [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
      <kendo-numerictextbox
        scCommonE2eHook="{{ testhook }}:form:numericbox"
        [formControlName]="controlName"
        [ngClass]="{
          'sc-input--readonly': readonly && !parentFormGroup.controls[controlName].disabled
        }"
        [style.width.px]="staticWidth"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [decimals]="decimals"
        [format]="format"
        [min]="min"
        [autoCorrect]="autoCorrect"
        [step]="increment"
        size="none">
      </kendo-numerictextbox>
      <div class="flex">
        <div
          i18n
          *ngIf="enableSuccessValidationStyle && parentFormGroup.controls[controlName].valid"
          class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
          </div>
          Success
        </div>
        <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="red-300" class="sc-mr-4"></sc-icon>
          </div>
          Incomplete
        </div>
        <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
      </div>
    </kendo-label>
  </kendo-formfield>
</ng-container>

