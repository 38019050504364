import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UNTITLED } from '@surecloud/common';
import {
  RendererAllComponentUnion,
  RendererFormControlComponentUnion,
} from '../component-renderer-component.interface';
import { RendererCalculationConfigInterface } from '../component-renderer-config.interface';
import { isRendererCaclulationControlComponentRef } from '../component-renderer.guards';
import { getDirection } from './calculation-creator-helper';

/**
 * Dynamically creates a calculation component
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererCalculationConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<RendererAllComponentUnion>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<RendererFormControlComponentUnion> | undefined} - Dynamic Component
 */
export const calculationComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererCalculationConfigInterface | undefined,
  component: Type<RendererAllComponentUnion>,
  control: { controlId: string; group: FormGroup } | undefined
): ComponentRef<RendererFormControlComponentUnion> | undefined => {
  if (!componentData) return undefined;

  const { label, guidanceText, attribute } = componentData;
  const componentRef = viewContainerRef.createComponent(component);

  if (!isRendererCaclulationControlComponentRef(componentRef)) return undefined;

  componentRef.instance.description = guidanceText || UNTITLED;
  componentRef.instance.label = label || UNTITLED;

  if (!control) return componentRef;

  const formGroup = control.group.controls[control.controlId];
  componentRef.instance.value = formGroup?.value;
  componentRef.instance.controlName = control.controlId;
  componentRef.instance.parentFormGroup = control.group;
  componentRef.instance.direction = getDirection(componentData.direction);
  componentRef.instance.trendDirection = attribute?.trendDirection || null;
  componentRef.instance.trendAnalysis = attribute?.trendAnalysis || false;
  return componentRef;
};
