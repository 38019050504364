import { RoleInterface } from '@surecloud/common';

/**
 * Entity Attribute Permission interface.
 *
 * @export
 * @property {string} entityId The entity ID.
 * @property {string} attributeId The entity attributeId ID.
 * @property {string} entityAttributePermissionId The entity Attribute permission ID.
 * @property {boolean} readPermission Whether the role can view the entity Attribute.
 * @property {boolean} updatePermission Whether the role can update the entity Attribute.
 * @property {RoleInterface} role The role associated with the entity Attribute permission.
 * @interface AttributePermissionInterface
 */
export interface AttributePermissionInterface {
  entityId: string;
  attributeId: string;
  entityAttributePermissionId: string;
  readPermission: boolean;
  updatePermission: boolean;
  role?: RoleInterface | null;
}

/**
 * Entity Attribute Permission Create Input Interface.
 *
 * @export
 * @property {string} entityId The entity ID.
 * @property {string} attributeId The attribute ID.
 * @interface AttributePermissionCreateInputInterface
 */
export interface AttributePermissionCreateInputInterface {
  entityId: string;
  attributeId: string;
}

/**
 * Entity Attribute Permission Delete Input Interface.
 *
 * @export
 * @property {string} entityId The entity ID.
 * @property {string[]} attributePermissionIds The entity Attribute permission IDs.
 * @interface AttributePermissionDeleteInputInterface
 */
export interface AttributePermissionDeleteInputInterface {
  entityId: string;
  entityAttributePermissionIds: string[];
}

/**
 * Utility function to make a Entity Attribute Permission
 *
 * @param {string} entityAttributePermissionId The ID of the Entity Attribute Permission.
 * @param {string} entityId The entityId of the Entity Permission.
 * @param {string} attributeId The attributeId of the Entity Permission.
 * @return {AttributePermissionInterface} A Entity Attribute Permission interface.
 */
export const makeAttributePermission = (
  entityAttributePermissionId: string,
  entityId: string,
  attributeId: string
): AttributePermissionInterface => ({
  entityAttributePermissionId,
  entityId,
  attributeId,
  readPermission: false,
  updatePermission: false,
  role: null,
});

/**
 * isAttributePermission - checks if object is an  Entity Attribute Permission.
 *
 * @param {unknown} value The object.
 * @return {boolean} Whether it is a  Entity Permission or not.
 */
export const isAttributePermission = (value: unknown): value is AttributePermissionInterface => {
  const entityPermission = value as AttributePermissionInterface;
  return entityPermission && !!entityPermission.entityAttributePermissionId && !!entityPermission.entityId;
};

