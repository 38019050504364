import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconButtonComponent } from '../button/icon-button.component';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';
import { TagComponent } from '../tag/tag.component';
import { LogicItemInterface } from './logic-item.component.interface';

/**
 * Surecloud LogicItemComponent Component.
 *
 * @export
 * @class LogicItemComponent
 */
@Component({
  selector: 'sc-logic-item',
  standalone: true,
  templateUrl: './logic-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [IconButtonComponent, SvgIconsModule, CommonModule, TagComponent, RouterModule],
})
export class LogicItemComponent {
  @Input()
  routeCommands?: string[];

  /**
   * The logic item to display.
   *
   * @type {LogicItemInterface}
   * @memberof LogicItemComponent
   */
  @Input()
  logicItem!: LogicItemInterface;

  /**
   * Which order to display the logic block tags and text in.
   *
   * @type {('start' | 'end')}
   * @memberof LogicItemComponent
   */
  @Input()
  direction: 'start' | 'end' = 'start';

  /**
   * If the logic item is editable and emits and event if clicked.
   *
   * @memberof LogicItemComponent
   */
  @Input()
  editable = false;

  /**
   * The logic block we want to start editing.
   *
   * @memberof LogicItemComponent
   */
  @Output() startEdit = new EventEmitter();

  trackByIndex = LogicItemComponent.trackByIndex;

  /**
   * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
   * The function takes the iteration index and in this case Angular will track changes with the index.
   *
   * @static
   * @param {number} index // position of the item.
   * @return {*}  {number}
   * @memberof LogicItemComponent
   */
  static trackByIndex(index: number): number {
    return index;
  }
}
