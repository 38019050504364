<div class="sc-infobox flex align-items-center">
  <sc-icon
    class="sc-infobox__icon flex-shrink-0"
    key="info-circle"
    size="small"
    colour="brand-500"
    [height]="20"
    [width]="20"></sc-icon>
  <p class="align-self-center sc-text">
    <ng-content></ng-content>
  </p>
</div>
