<form [formGroup]="form" class="flex flex-column" scCommonE2eHook="{{ testhook }}:edit-score">
  <h4 i18n class="sc-text-brand-500 sc-mb-16">Edit Score</h4>
  <sc-slider [formControl]="form.controls.slider" class="sc-mb-16"></sc-slider>
  <sc-checkbox [formControl]="form.controls.checkboxChecked" label="Don't score this question" i18n-label class="sc-mb-16">
  </sc-checkbox>
  <sc-input-text
    label="Justification"
    i18n-label
    description="Reason for the given score"
    i18n-description
    placeholder="Due to..."
    i18n-placeholder
    controlName="justification"
    class="sc-mb-16"></sc-input-text>
</form>
