/**
 * Group interface.
 * @export
 * @interface GroupInterface
 */
export interface GroupInterface {
  groupId: string;
  name: string | null;
  description?: string | null;
  roles?: string[] | null;
  users?: string[];
  viewerCanDelete?: boolean;
  viewerCanUpdate?: boolean;
}

/**
 * Group Delete Input interface.
 * @export
 * @interface GroupDeleteInputInterface
 */
export interface GroupDeleteInputInterface {
  groupId: string;
  name: string | null;
}

/**
 * Helper function to quickly create a Group
 * @param {string} groupId Id of the Group.
 * @param {string | null} name name of the Group.
 * @param options
 * @export
 * @return {GroupInterface} A newly created Group model.
 */
export const makeGroup = (
  groupId: string,
  name: string | null = null,
  options: Partial<Omit<GroupInterface, 'groupId' | 'name'>> = {}
): GroupInterface => ({
  groupId,
  name,
  description: options.description || null,
});

/**
 * isGroup - checks if object is a group.
 * @param {unknown} value The object.
 * @return {boolean} Whether it is a group or not.
 */
export const isGroup = (value: unknown): value is GroupInterface => {
  const group = value as GroupInterface;

  return group?.groupId !== undefined;
};

/**
 * Checks if an array of values is an array of Groups.
 * @param {unknown[]} groups The values to check if Group Interfaces.
 * @return {boolean} Whether the values are Group Interfaces or not.
 */
export const isGroups = (groups: unknown[]): groups is GroupInterface[] => groups.every(isGroup);
