import { createAction } from '@ngrx/store';

/**
 * An object grouping the home page entity actions
 *
 * @export
 */
export const HomepageActions = {
  homepageEnter: createAction('[Home Page] Enter'),
};
