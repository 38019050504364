<div class="sc-toast sc-toast--{{ message.severity }}" scCommonE2eHook="{{ testhook }}:toast">
  <div class="sc-toast__icon">
    <ng-container *ngIf="message.severity === ToastSeverityEnum.SUCCESS; else errorIcon">
      <sc-icon class="icon" color="text" key="checkmark-outline" colour="green-500"></sc-icon>
    </ng-container>

    <ng-template #errorIcon>
      <span class="error-icon pi pi-times-circle"></span>
    </ng-template>
  </div>

  <div class="sc-toast__message">
    <div *ngIf="message.severity === ToastSeverityEnum.ERROR" class="sc-toast__message--heading">
      <p i18n>Error</p>
    </div>
    <div class="sc-toast__message--detail">{{ message.detail }}</div>
  </div>
</div>
