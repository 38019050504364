<ng-container *ngIf="heading">
  <div class="sc-mb-4 flex justify-content-between align-items-center">
    <div>
      <h1 i18n class="inline-block sc-text-brand-500 sc-font-heading-2xl sc-mr-12">{{ heading }}</h1>
      <span *ngIf="metaData" class="sc-text-grey-600" i18n>{{ metaData }}</span>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <p class="sc-font-table-entry sc-text sc-mb-24" i18n>{{ description }}</p>
</ng-container>
