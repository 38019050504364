import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { InputTextComponent } from '../../input-text/input-text.component';

/**
 * Surecloud quick filter component
 * @export
 * @class GridQuickFilterComponent
 * @implements {OnInit}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sc-grid-quick-filter',
  templateUrl: './grid-quick-filter.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, InputTextComponent],
})
export class GridQuickFilterComponent implements OnInit, OnDestroy {
  /**
   * This subject that emits when the component is destroyed.
   * @private
   * @memberof GridQuickFilterComponent
   */
  private destroyed$ = new Subject<void>();

  /**
   * The delay in milliseconds before the filter change is emitted.
   * Good to use when you want to prevent multiple calls to the server.
   * @memberof GridQuickFilterComponent
   */
  @Input() filterChangeDebounceDelay = 0;

  /**
   * Sets the readonly value for the input.
   * @memberof GridQuickFilterComponent
   */
  @Input() readonly = false;

  /**
   * The width of the Search Text Input.
   * @memberof GridQuickFilterComponent
   */
  @Input() width = '200';

  /**
   * Emits when the text filter criteria is changed
   * @memberof GridQuickFilterComponent
   */
  @Output() filterChanged = new EventEmitter<string>();

  /**
   * The form control around the filter
   * @memberof GridQuickFilterComponent
   */
  quickFilterForm = this.fb.group<{ filterText: FormControl<string | null> }>({
    filterText: this.fb.control(''),
  });

  /**
   * Creates an instance of GridQuickFilterComponent.
   * @param {FormBuilder} fb form builder instance
   * @memberof GridQuickFilterComponent
   */
  constructor(protected readonly fb: FormBuilder) {}

  /**
   * Wires up the on change event of the filter text
   * @memberof GridQuickFilterComponent
   */
  ngOnInit(): void {
    this.quickFilterForm.controls.filterText.valueChanges
      .pipe(debounceTime(this.filterChangeDebounceDelay), takeUntil(this.destroyed$))
      .subscribe((text) => {
        this.filterChanged.emit(text || '');
      });
  }

  /**
   * When the component is destroyed.
   * Then emit destroyed$ so that other observables can be unsubscribed.
   * @memberof GridQuickFilterComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
