import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LinkedEntityTypeApiInterface } from '@surecloud/api-types';
import { LinkedEntityInterface } from '../models/linked-entity/linked-entity.model';

/**
 * Linked Entity API actions map.
 */
const LINKED_ENTITY_API_EVENTS_MAP = {
  'Reset Linked Entity': emptyProps(),
  'Read Linked Entity List': emptyProps(),
  'Read Linked Entity Success': props<{ links: LinkedEntityInterface[] }>(),
  'Read Linked Entity Failure': props<{ error: string }>(),

  'Create Linked Entities': props<{ linkedEntityIds: string[] }>(),
  'Create Linked Entities Success': props<{ createdIds: string[]; linkedEntities: LinkedEntityInterface[] }>(),
  'Create Linked Entities Failure': props<{ error: string }>(),

  'Delete Linked Entities': props<{ linkedEntityIds: string[] }>(),
  'Delete Linked Entities Success': props<{ deletedIds: string[] }>(),
  'Delete Linked Entities Failure': props<{ error: string }>(),

  'Update Linked Entities': props<{ linkedEntityId: string; linkedEntityType: LinkedEntityTypeApiInterface }>(),
  'Update Linked Entities Success': props<{ linkedEntity: LinkedEntityInterface }>(),
  'Update Linked Entities Failure': props<{ error: string }>(),
};

const LINKED_ENTITY_PAGE_EVENTS_MAP = {
  'Linked Entity Enter': emptyProps(),
  'Select Linked Entity Enter': emptyProps(),
  'Start Add Linked Entities': emptyProps(),
  'Confirm Add Linked Entities': props<{ linkedEntityIds: string[] }>(),
  'Cancel Add Linked Entities': emptyProps(),
  'Start Delete Linked Entities': props<{ linkedEntityIds: string[] }>(),
};

/**
 * Linked Entity API actions.
 */
export const LinkedEntityApiActions = createActionGroup({
  source: 'Entity/API',
  events: LINKED_ENTITY_API_EVENTS_MAP,
});

/**
 * Linked Entity Page actions.
 */
export const LinkedEntityPageActions = createActionGroup({
  source: 'Linked Entity Page',
  events: LINKED_ENTITY_PAGE_EVENTS_MAP,
});

/**
 * Linked Entity API actions.
 */
export const LinkedEntityActions = {
  ...LinkedEntityApiActions,
  ...LinkedEntityPageActions,
};
