import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ScIconType } from '@surecloud/common';
import { IconRegistry } from './icon-registry.service';
import { ScIconSizeLgMeasure, ScIconSizeMdMeasure, ScIconSizeSmMeasure, ScIconSizeXLgMeasure } from './icon.constant';
import { ScIconColour, ScIconSize, ScSvgIconModuleConfigInterface } from './icon.interface';
import { SVG_ICONS_CONFIG } from './icon.tokens';

/**
 * Icon Component that set up an Icon
 * @export
 * @class IconComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'sc-icon',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  /**
   * Icon key
   * @type {ScIconType}
   * @memberof IconComponent
   */
  @Input() key!: ScIconType | string; // NOSONAR

  /**
   * Icon size
   * @type {ScIconSize}
   * @memberof IconComponent
   */
  @Input() size: ScIconSize = 'medium';

  /**
   * Icon colour
   * @type {ScIconColour}
   * @memberof IconComponent
   */
  @Input() colour: ScIconColour = 'primary';

  /**
   * icon width
   * @type {(number | string)}
   * @memberof IconComponent
   */
  @Input() width!: number | string;

  /**
   * Icon height
   * @type {(number | string)}
   * @memberof IconComponent
   */
  @Input() height!: number | string;

  private mergedConfig: ScSvgIconModuleConfigInterface;

  constructor(
    private elementRef: ElementRef,
    private registry: IconRegistry,
    private renderer: Renderer2,
    @Inject(SVG_ICONS_CONFIG) private config: ScSvgIconModuleConfigInterface
  ) {
    this.mergedConfig = this.createConfig();
    this.renderer.setAttribute(this.element, 'role', 'true');
    this.renderer.setAttribute(this.element, 'aria-hidden', 'true');
    this.renderer.setStyle(this.element, 'display', 'inline-block');
  }

  /**
   * Getter for elementRef.nativeElement
   * @readonly
   * @type {HTMLElement}
   * @memberof IconComponent
   */
  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  /**
   * Update host element on changes.
   * @param {SimpleChanges} changes A hash table of changes represented by SimpleChange objects
   * @memberof IconComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['key']) {
      this.setIcon(changes['key'].previousValue, changes['key'].currentValue);
    }

    if (changes['size']) {
      this.setIconPropertySize(this.mergedConfig.sizes[this.size], 'height');
      this.setIconPropertySize(this.mergedConfig.sizes[this.size], 'width');
    }

    if (changes['height']) {
      this.setIconPropertySize(this.height, 'height');
      this.setIconPropertySize(this.height, 'line-height');
    }

    if (changes['width']) {
      this.setIconPropertySize(this.width, 'width');
    }

    if (changes['colour']) {
      this.renderer.setStyle(this.element, 'color', `var(--${this.colour})`);
    }
  }

  /**
   * Creates a default config for the component
   * @private
   * @return {*}  {ScSvgIconModuleConfigInterface}
   * @memberof IconComponent
   */
  private createConfig(): ScSvgIconModuleConfigInterface {
    const defaults: ScSvgIconModuleConfigInterface = {
      sizes: {
        small: ScIconSizeSmMeasure,
        medium: ScIconSizeMdMeasure,
        large: ScIconSizeLgMeasure,
        'x-large': ScIconSizeXLgMeasure,
      },
    };

    return {
      ...defaults,
      ...this.config,
    };
  }

  /**
   * Set's the icon type
   * @private
   * @param {(string | undefined)} oldKey old icon key
   * @param {(string | undefined)} newKey new icon key
   * @memberof IconComponent
   */
  private setIcon(oldKey: string | undefined, newKey: string | undefined): void {
    const icon = this.registry.get(newKey) ?? this.registry.get(this.config.missingIconFallback?.name);

    if (oldKey) {
      this.renderer.removeAttribute(this.element, 'aria-label', `${oldKey}-icon`);
    }
    if (icon && newKey) {
      this.renderer.setAttribute(this.element, 'aria-label', `${newKey}-icon`);
      this.renderer.setProperty(this.element, 'innerHTML', icon);
    }
  }

  /**
   * Set's the size of a css property
   * @private
   * @param {(number | string | undefined)} value value of the size
   * @param {string} styleProperty css property to change
   * @memberof IconComponent
   */
  private setIconPropertySize(value: number | string | undefined, styleProperty: string): void {
    if (value) {
      const newValue = typeof value === 'string' ? value : `${value}px`;
      this.renderer.setStyle(this.element, styleProperty, newValue);
    }
  }
}
