import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client/core';
import { Client, ClientOptions, createClient } from 'graphql-sse';
import { print } from 'graphql/index';

export class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();

    this.client = createClient(options);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public override request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
    return new Observable((sink) =>
      this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: (executionResult) => {
            if (this.isFetchResult(executionResult) && executionResult?.data !== 'ping') {
              sink.next(executionResult);
            }
          },
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      ),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  private isFetchResult(executionResult: unknown): executionResult is FetchResult<Record<string, unknown>, Record<string, unknown>, Record<string, unknown>> {
    return (executionResult as FetchResult<Record<string, unknown>, Record<string, unknown>, Record<string, unknown>>) !== undefined;
  }
}
