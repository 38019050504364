import { OptionListComponent } from '../../option-list/option-list.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { optionListComponentCreator } from '../creators/option-list-form-control-creator.config';

/**
 * Control configuration for OPTION LIST renderer component
 *
 */
export const OPTIONLIST_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: OptionListComponent,
  creatorFn: optionListComponentCreator,
};

