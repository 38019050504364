import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UNTITLED } from '@surecloud/common';
import { ValidationFormControlComponentInterface } from '../validation-component-renderer-component.interface';
import { ValidationRendererFormControlConfigInterface } from '../validation-component-renderer-config.interface';
import { isValidationRendererFormControlComponentRef } from '../validation-component-renderer.guards';

/**
 * Dynamically creates a validation component with a form control
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {ValidationRendererFormControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<ValidationFormControlComponentInterfacen>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<ValidationFormControlComponentInterface> | undefined} - Dynamic Component
 */
export const validationFormControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: ValidationRendererFormControlConfigInterface | undefined,
  component: Type<ValidationFormControlComponentInterface>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = false
): ComponentRef<ValidationFormControlComponentInterface> | undefined => {
  if (!componentData) return undefined;

  const { label, placeholder, guidanceText } = componentData;
  const componentRef = viewContainerRef.createComponent(component);

  if (!isValidationRendererFormControlComponentRef(componentRef)) return undefined;

  componentRef.instance.description = guidanceText ?? UNTITLED;
  componentRef.instance.label = label ?? UNTITLED;
  componentRef.instance.placeholder = placeholder ?? UNTITLED;
  componentRef.instance.readonly = readonly;

  if (!control) return componentRef;

  componentRef.instance.controlName = control.controlId;
  componentRef.instance.parentFormGroup = control.group;

  return componentRef;
};
