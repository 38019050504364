/**
 * Create Entity interface.
 *
 * @property {string} name The name of entity to create.
 * @export
 * @interface EntityCreateInputInterface
 */
export interface EntityCreateInputInterface {
  name: string | null;
}

/**
 * Interface for the 'Entity' data.
 *
 * @property {string} entityId The ID of the entity.
 * @property {string} name The name of the entity.
 * @property {string} created The date the entity was created.
 * @export
 * @interface EntityInterface
 */
export interface EntityInterface {
  entityId: string;
  name: string | null;
  created?: string | null;
  description?: string | null;
}

/**
 * Interface returned whenever an entity is updated.
 *
 * @property {string} entityId The ID of the entity.
 * @export
 * @interface EntityUpdatedInterface
 */
export interface EntityUpdatedInterface {
  entityId: string;
}

/**
 * isEntity - checks if object is an entity.
 *
 * @param {unknown} value - the object.
 * @return {boolean} - whether it is an entity or not.
 */
export const isEntity = (value: unknown): value is EntityInterface => {
  const entity = value as EntityInterface;
  return entity.entityId !== undefined && entity.name !== undefined;
};

/**
 * Helper function to quickly create an Entity
 *
 * @param {string} entityId Id of the entity.
 * @param {string} name The name of the entity.
 * @param {string} description The entity description.
 * @export
 * @return {EntityInterface} A newly created Entity model.
 */
export const makeEntity = (
  entityId: string,
  name: string | null = null,
  description: string | null = null
): EntityInterface => ({
  entityId,
  name,
  description,
});
