<design-v2-sidebar-layout-overlay>
  <div sidebar-overlay-title class="flex align-items-center sc-pb-16">
    <h2 i18n class="sc-font-heading-xl design-v2-text-surecloud-blue sc-mr-12" *ngIf="heading">{{ heading }}</h2>
    <sc-tag size="lg" theme="blue">{{ total }}</sc-tag>
    <sc-tag i18n size="lg" theme="green" *ngIf="new">{{ new }} New</sc-tag>
  </div>
  <ng-container sidebar-overlay-content>
    <ng-container *ngIf="tasks.length; else empty">
      <design-v2-task-summary
        *ngFor="let task of tasks; trackBy: trackByFunction"
        [task]="task"
        (selected)="selected.next($event)">
      </design-v2-task-summary>
    </ng-container>
    <ng-template #empty>
      <p i18n class="sc-font-body-text sc-text sc-px-16 sc-pt-20">We didn't find anything to show here.</p>
    </ng-template>
  </ng-container>
</design-v2-sidebar-layout-overlay>
