import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';

/**
 * Makes the global Owner attribute configuration
 */
export const GLOBAL_OWNER_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Owner,
  key: EntityAttributeTypeEnum.Owner,
  type: EntityAttributeTypeEnum.Owner,
  label: $localize`Owner`,
  uniqueSections: [],
});
