import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UNTITLED } from '@surecloud/common';
import { RECENT_RECORDS_FEATURE_KEY, StateInterface, recentRecordsAdapter } from '../../reducers/recent-records/recent-records.reducer';

// Lookup the Recent Records feature state managed by NgRx
export const getRecentRecordsState = createFeatureSelector<StateInterface>(RECENT_RECORDS_FEATURE_KEY);

const { selectAll } = recentRecordsAdapter.getSelectors();

export const getRecentRecordsLoaded = createSelector(getRecentRecordsState, (state: StateInterface) => state.loaded);

/**
 * Get the loaded Recent Records.
 */
export const getRecentRecordList = createSelector(getRecentRecordsState, (state: StateInterface) =>
  selectAll(state)
);

/**
 * Get the loaded Recent Records without empty title.
 */
export const getRecentRecordsforGrid = createSelector(getRecentRecordList, (recentRecordList) =>
  recentRecordList.map((record) => ({ recordId: record.recordId, recordName: record.recordName || UNTITLED, due: { label: record.lastViewed, urgency: '', dueDate: record.lastViewedDate } }))
);
