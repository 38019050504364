import { Params } from '@angular/router';
import { RouterReducerState, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Get the router state.
 */
export const selectRouter = createFeatureSelector<RouterReducerState>('router');

/**
 * Get the nested `:parameters` on a route.
 * @param {RouterReducerState<SerializedRouterStateSnapshot>} router The activated route.
 * @return {Params} An object literal of the nested parameters on a route.
 */
export const getNestedRouteParams = (router?: RouterReducerState<SerializedRouterStateSnapshot>): Params => {
  let currentRoute = router?.state.root;
  let params: Params = {};

  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
};

/**
 * Get the nested params from the router state.
 */
export const selectNestedRouteParams = createSelector(selectRouter, getNestedRouteParams);

/**
 * Get nested URL parameters from the router.
 * @param {string} param The parameter to get from the router state.
 * @return {MemoizedSelector<object, any, DefaultProjectorFn<any>>} Nested URL parameters.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectNestedRouteParam = (param: string): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
  createSelector(selectNestedRouteParams, (params) => params?.[param]);
