import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NormalisedUserProfile } from '../../services/normalise-get-user-profile/normalise-get-user-profile.validation';
import { UserProfileInterface } from '../models/user-profile.models';

/**
 * User Profile API actions map.
 */
const USER_PROFILE_API_ACTIONS_MAP = {
  'Read User Profile': emptyProps(),
  'Read User Profile Data Success': props<{ normalisedUserProfile: NormalisedUserProfile }>(),
  'Read User Profile Success': props<{ userProfile: UserProfileInterface }>(),
  'Read User Profile Failure': props<{ error: string }>(),
};

/**
 * User Profile API actions.
 *
 * @export
 */
const UserProfileApiActions = createActionGroup({
  source: 'User/API',
  events: USER_PROFILE_API_ACTIONS_MAP,
});

/**
 * Combined User Profile API actions.
 */
export const UserProfileActions = {
  ...UserProfileApiActions,
};
