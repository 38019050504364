import { Injectable } from '@angular/core';
import { UserProfileApiInterfaceQueryService } from '@surecloud/api-types';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { NormaliseGetUserProfileService } from '../normalise-get-user-profile/normalise-get-user-profile.service';
import { NormalisedUserProfile } from '../normalise-get-user-profile/normalise-get-user-profile.validation';

export const USER_PROFILE_READ_ERROR = 'No response data from read User profile returned from API.';

/**
 * User Profile API service.
 * @export
 * @class UserProfileService
 */
@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  /**
   * Creates an instance of UserService
   * @param {UserProfileApiInterfaceQueryService} userProfileApiInterfaceQueryService The Apollo service to query the api.
   * @param {NormaliseGetUserProfileService} normaliseGetUserProfileService The service that normalises and validates the User Profile data.
   * @memberof UserProfileService
   */
  constructor(
    private userProfileApiInterfaceQueryService: UserProfileApiInterfaceQueryService,
    private normaliseGetUserProfileService: NormaliseGetUserProfileService
  ) {}

  /**
   * Query to get a User by id.
   * @return {Observable<NormalisedUserProfile>} The User normalised data.
   * @memberof UserProfileService
   */
  read(): Observable<NormalisedUserProfile> {
    return this.userProfileApiInterfaceQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.userprofile
            ? of(this.normaliseGetUserProfileService.extract(data.userprofile))
            : throwError(() => USER_PROFILE_READ_ERROR)
        )
      );
  }
}
