import { InputNumberAndDropdownsComponent } from '../../../input-number-and-dropdown/input-number-and-dropdowns.component';
import { validationNumberAndDropdownControlComponentCreator } from '../creators/input-number-and-dropdown-control-creator.config';
import { ValidationRendererControlConfigInterface } from '../validation-component-renderer-control-config.interface';

/**
 * Control configuration for NUMBER and DROPDOWN renderer component
 *
 */
export const NUMBER_DROPDOWN_CONTROL_CONFIG: ValidationRendererControlConfigInterface = {
  component: InputNumberAndDropdownsComponent,
  creatorFn: validationNumberAndDropdownControlComponentCreator,
};
