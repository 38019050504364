import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AdvancedGridComponent,
  ErrorDetailsComponent,
  HomepageGridComponent,
  PageComponent,
  RecordGridComponent,
  SectionComponent,
  TextButtonComponent,
  TitleDescriptionComponent,
  ToolbarsComponent,
} from '@surecloud/design';
import { EntityAttributeModule, EntityStateModule } from '@surecloud/entity-state';
import { HomepageApplicationsStateModule } from '@surecloud/homepage-applications-state';
import { PriorityTasksStateModule } from '@surecloud/priority-tasks-state';
import { RecentRecordsStateModule } from '@surecloud/recent-records-state';
import { RecordModule } from '@surecloud/record';
import { RecordImportModule } from '@surecloud/record-import';
import { ToolbarFeatureModule } from '@surecloud/toolbar-feature';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { StandaloneApplicationListComponent } from './application-list/application-list.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomepageEffects } from './homepage/+state/effects/homepage.effects';
import { HomepageComponent } from './homepage/homepage.component';
import { StandaloneRecordListEffects } from './record-list/+state/effects/standalone-record-list.effects';
import { StandaloneRecordListComponent } from './record-list/standalone-record-list.component';
import { StandaloneApplicationListEffects } from './application-list/+state/effects/application-list.effects';

@NgModule({
  declarations: [
    HomepageComponent,
    StandaloneRecordListComponent,
    ErrorPageComponent,
    StandaloneApplicationListComponent,
  ],
  imports: [
    CommonModule,
    EntityAttributeModule,
    ToolbarsComponent,
    RecordModule,
    EntityStateModule,
    AdvancedGridComponent,
    TitleDescriptionComponent,
    PageComponent,
    SectionComponent,
    RouterModule,
    TextButtonComponent,
    ErrorDetailsComponent,
    ToolbarFeatureModule,
    PriorityTasksStateModule,
    RecordGridComponent,
    RecentRecordsStateModule,
    HomepageGridComponent,
    HomepageApplicationsStateModule,
    RecordImportModule,
  ],
  providers: [EffectsBootstrapProvider.provide([HomepageEffects, StandaloneRecordListEffects, StandaloneApplicationListEffects])],
})
export class StandaloneModule {}
