/**
 * Enum values for the application route names.
 * @export
 * @enum {number}
 */
export enum StandaloneRouteNamesEnum {
  Home = 'home',
  RecordList = 'recordList',
  Error = 'error',
}
