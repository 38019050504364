import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommonActions, LoggerService, UNTITLED, selectNestedRouteParams } from '@surecloud/common';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AttributePermissionService } from '../../../../services/permission/attribute-permission/attribute-permission.service';
import { AttributePermissionActions } from '../../../actions/attribute-permission.actions';
import { EntityActions } from '../../../actions/entity.actions';
import { AttributePermissionDeleteInputInterface } from '../../../models/permission/attribute-permission/attribute-permission.models';

/**
 * Entity attribute permissions effect and side effects.
 * @export
 * @class AttributePermissionEffects
 */
@Injectable({ providedIn: 'root' })
export class AttributePermissionEffects {
  /**
   * When all entity data is loaded successfully,
   * Then populate the attributes permissions state.
   * @memberof AttributePermissionEffects
   */
  loadEntityAttributePermissionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readAllEntityDataSuccess, EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        AttributePermissionActions.readAttributePermissionListSuccess({
          permissions: normalisedEntityList.attributePermissions,
        })
      )
    )
  );

  /**
   * When a user clicks add Entity attribute permission button.
   * Then create the Entity attribute permission on the API.
   * @memberof  AttributePermissionEffects
   */
  createEntityAttributePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributePermissionActions.createAttributePermission),
      withLatestFrom(this.store.select(selectNestedRouteParams)),
      switchMap(([, { entityId, attributeId }]) =>
        this.attributePermissionService.create({ entityId, attributeId }).pipe(
          map((entityAttributePermission) =>
            AttributePermissionActions.createAttributePermissionSuccess({ entityAttributePermission })
          ),
          catchError((error: unknown) =>
            of(AttributePermissionActions.createAttributePermissionFailure({ error: `${error}` }))
          )
        )
      )
    )
  );

  /**
   * When a user has confirmed the update on a Entity attribute permission.
   * Then update the Entity attribute permission on the API.
   * @memberof AttributePermissionEffects
   */
  updateEntityAttributePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributePermissionActions.updateAttributePermission),
      switchMap(({ entityAttributePermission }) =>
        this.attributePermissionService.update(entityAttributePermission).pipe(
          map((updatedEntityAttributePermission) =>
            AttributePermissionActions.updateAttributePermissionSuccess({
              entityAttributePermission: updatedEntityAttributePermission,
            })
          ),
          catchError((error: unknown) =>
            of(AttributePermissionActions.updateAttributePermissionFailure({ error: `${error}` }))
          )
        )
      )
    )
  );

  /**
   * When a user has confirmed delete of a Entity attribute permission.
   * Then delete the Entity attribute permission on the API.
   * @memberof AttributePermissionEffects
   */
  deleteEntityAttributePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributePermissionActions.deleteAttributePermission),
      switchMap(({ entityAttributeDeletePermission }) =>
        this.attributePermissionService.delete(entityAttributeDeletePermission).pipe(
          map(({ deletedIds }) => AttributePermissionActions.deleteAttributePermissionSuccess({ deletedIds })),
          catchError((error: unknown) =>
            of(AttributePermissionActions.deleteAttributePermissionFailure({ error: `${error}` }))
          )
        )
      )
    )
  );

  /**
   * When a user clicks the delete Entity attribute permission button.
   * Then open a delete Entity attribute permission confirmation modal.
   * @memberof AttributePermissionEffects
   */
  enterDeleteEntityAttributePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributePermissionActions.startDeleteAttributePermission),
      map(({ entityAttributePermission }) => {
        const title = $localize`Confirm Delete Attribute Permission`;
        const content = $localize`Are you sure you want to delete the ${
          entityAttributePermission.role?.name || UNTITLED
        } attribute permission?`;

        const { entityAttributePermissionId, entityId } = entityAttributePermission;
        const entityPermissionDeleteInput: AttributePermissionDeleteInputInterface = {
          entityAttributePermissionIds: [entityAttributePermissionId],
          entityId,
        };
        const confirmAction = AttributePermissionActions.deleteAttributePermission({
          entityAttributeDeletePermission: entityPermissionDeleteInput,
        });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels or confirms the deletion of a Entity attribute permission.
   * Then close the confirm delete Entity attribute permission modal.
   * @memberof AttributePermissionEffects
   */
  leaveDeleteEntityAttributePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributePermissionActions.deleteAttributePermission),
      map(CommonActions.closeConfirmationModal)
    )
  );

  /**
   * Logs any errors.
   * @memberof AttributePermissionEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AttributePermissionActions.deleteAttributePermissionFailure,
          AttributePermissionActions.createAttributePermissionFailure,
          AttributePermissionActions.updateAttributePermissionFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Entity Attribute Permission', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of export class AttributePermissionEffects
   * .
   * @param {Actions} actions$ The store actions.
   * @param {AttributePermissionService} attributePermissionService The entity attribute Permission service.
   * @param {Store} store - Global ngrx store
   * @param {LoggerService} logger The application logging service.
   * @memberof AttributePermissionEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly attributePermissionService: AttributePermissionService,
    private readonly store: Store,
    private readonly logger: LoggerService
  ) {}
}
