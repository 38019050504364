import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import {
  GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION,
  makeAttributeConfigSectionId,
} from '../global-attribute-config.model';

/**
 * Makes the global Number attribute configuration
 */
export const GLOBAL_NUMBER_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Number,
  key: EntityAttributeTypeEnum.Number,
  type: EntityAttributeTypeEnum.Number,
  label: $localize`Number`,
  uniqueSections: [
    {
      sectionId: makeAttributeConfigSectionId('number'),
      sectionName: $localize`Additional Properties`,
      sectionComponent: 'EntityAttributeNumberPropertiesComponent',
    },
    GLOBAL_ATTRIBUTE_COMMON_VALIDATION_SECTION,
  ],
});
