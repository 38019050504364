<ul class="sc-tabs flex" *ngIf="tabs && tabs.length">
  <li
    *ngFor="let tab of tabs; trackBy: trackByFunction"
    class="sc-tabs__tab flex-1 text-center"
    [ngClass]="{
      'sc-tabs__tab--active': activeTab === tab.id,
      'sc-tabs__tab--disabled': tab.disabled
    }">
    <a tabindex="0" class="sc-tabs__tab-content block" (click)="setActiveTab(tab.id)" (keydown.enter)="setActiveTab(tab.id)">
      {{ tab.name }}
    </a>
  </li>
</ul>
