import { Injectable } from '@angular/core';
import {
  TemporaryFeatureFlagEnum,
  TemporaryFeatureFlagLocalStorageType,
} from '../../interfaces/temporary-feature-flag.interface';

export const FEATURE_FLAG_LOCAL_STORAGE_KEY = 'featureFlags';

/**
 * Temporary feature flag service.
 */
@Injectable({
  providedIn: 'root',
})
export class TemporaryFeatureFlagService {
  /**
   * Is the feature flag enabled?
   * @param {TemporaryFeatureFlagEnum} flag The feature flag to check.
   * @return {boolean} Is the feature flag enabled?
   */
  // eslint-disable-next-line class-methods-use-this
  isFlagEnabled(flag: TemporaryFeatureFlagEnum): boolean {
    const featureFlagsJson = localStorage.getItem(FEATURE_FLAG_LOCAL_STORAGE_KEY);

    if (!featureFlagsJson) {
      return false;
    }

    const featureFlags = JSON.parse(featureFlagsJson) as TemporaryFeatureFlagLocalStorageType;

    return featureFlags[flag] || false;
  }
}
