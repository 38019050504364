<ng-container [formGroup]="parentFormGroup">
  <kendo-formfield
    class="block sc-select-dropdown sc-select-dropdown--multiselect"
    showErrors="initial"
    [ngClass]="{ 'sc-mb-20': marginBottom }">
    <kendo-label
      [text]="label"
      class="sc-label flex-column"
      [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
      <kendo-multiselect
        scCommonE2eHook="{{ testhook }}:form:multiselect"
        [formControlName]="controlName"
        [data]="options"
        [checkboxes]="{ checkOnClick: false }"
        [autoClose]="false"
        [style.width.px]="staticWidth"
        [ngClass]="{
          'sc-input--readonly': readonly && !parentFormGroup.controls[controlName].disabled
        }"
        [textField]="optionTextProperty"
        [valueField]="optionValueProperty"
        [valuePrimitive]="valuePrimitive"
        (opened)="opened()"
        (closed)="closed()"
        [clearButton]="clearButton"
        [kendoDropDownFilter]="filterSettings"
        [attr.aria-expanded]="expanded"
        [placeholder]="placeholder"
        [popupSettings]="{
          appendTo: appendTo ? appendTo : viewContainerRef,
          popupClass: 'sc-scrollbar sc-select-dropdown__popup sc-select-dropdown__popup--multiselect'
        }"
        [readonly]="readonly">
        <ng-template></ng-template>
      </kendo-multiselect>
      <div class="flex">
        <div
          i18n
          *ngIf="enableSuccessValidationStyle && parentFormGroup.controls[controlName].valid"
          class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
          </div>
          Success
        </div>
        <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="red-300" class="sc-mr-4"></sc-icon>
          </div>
          Incomplete
        </div>
        <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
      </div>
    </kendo-label>
  </kendo-formfield>
</ng-container>
