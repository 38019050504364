<ng-container>
  <kendo-label [text]="label" class="sc-label flex-column">
    <div class="table flex flex-column sc-mb-8 justify-content-center align-items-center w-full">
      <sc-icon key="question-circle" colour="brand-500" [height]="24" [width]="25" class="sc-mt-24 sc-mb-16"></sc-icon>
      <p i18n class="h-full flex align-items-center sc-mb-16">
        {{ tag ? selectedQuestionSetDescription : unselectedQuestionSetDescription }}
      </p>
      <sc-tag *ngIf="tag" class="sc-mb-24" size="lg">{{ tag }}</sc-tag>
    </div>
  </kendo-label>
</ng-container>
