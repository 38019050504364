export const scssVariables = {
  'anti-flash-white': '#f2f2f2',
  'brand-700': '#0d455e',
  'brand-600': '#105879',
  'brand-500': '#146c94',
  'brand-400': '#1880af',
  'brand-300': '#1b93ca',
  'brand-100': '#78c8ed',
  'brand-50': '#bbe4f6',
  'brand-25': '#ecf9ff',
  'green-600': '#0d4d2b',
  'green-500': '#11673a',
  'green-400': '#158149',
  'green-300': '#1a9b58',
  'green-100': '#52e297',
  'green-50': '#beedd4',
  'yellow-600': '#9b7c14',
  'yellow-500': '#a98a22',
  'yellow-400': '#c29f27',
  'yellow-300': '#d6b032',
  'yellow-100': '#ebd99c',
  'yellow-50': '#f8f2dc',
  'orange-600': '#a7461b',
  'orange-500': '#b44e20',
  'orange-400': '#ce5925',
  'orange-300': '#db6936',
  'orange-100': '#f2c1ab',
  'orange-50': '#fcebe3',
  'blue-600': '#29649d',
  'blue-500': '#2f73b5',
  'blue-400': '#3782cb',
  'blue-300': '#5092d1',
  'blue-100': '#b5d1ec',
  'blue-50': '#d3e6f9',
  'red-600': '#852432',
  'red-500': '#9d2a3b',
  'red-400': '#b53044',
  'red-300': '#ca3a4f',
  'red-100': '#d97281',
  'red-50': '#f5dbdf',
  'purple-600': '#824970',
  'purple-500': '#965481',
  'purple-400': '#a76291',
  'purple-300': '#b2759f',
  'purple-100': '#e0c7d8',
  'purple-50': '#fbe7f4',
  mandy: '#e26577',
  terracotta: '#ce7746',
  apple: '#4caf50',
  'forest-green': '#2e7d32',
  'true-blue': '#5793c1',
  'cornflower-blue': '#129bb0',
  'ship-cove': '#8989bb',
  'lilac-bush': '#b07ea7',
  'neutral-40': '#c4dae3',
  'neutral-20': '#ccdae1',
  'neutral-03': '#f7f7f7',
  'grey-1000': '#515151',
  'grey-900': '#666',
  'grey-800': '#757575',
  'grey-700': '#858585',
  'grey-600': '#959595',
  'grey-500': '#a5a5a5',
  'grey-400': '#b6b6b6',
  'grey-300': '#c7c7c7',
  'grey-200': '#d8d8d8',
  'grey-100': '#e9e9e9',
  'grey-50': '#fbfbfb',
  text: '#181818',
  'text-lighter': '$grey-800',
  'text-lightest': '#f7f7f7',
  'text-disabled': '#5e6974',
  'disabled-background': '#e8e6e4',
  'main-background': '#f6f4f4',
  black: '#000',
  white: '#fff',
  clemantine: '#ffc107',
  terracota: '#ff6f00',
  crimson: '#f44336',
  marron: '#b71c1c',
  'rosie-pink': '#f06292',
  'rich-lilac': '#ba68c8',
  'lilac-blush': '#b07ea7',
  salmon: '#f9e0e2',
  aqua: '#b6edea',
  meadow: '#accc9b',
  sunset: '#e8d3ab',
  sky: '#cadade',
  violet: '#e0e3f9',
  bubblegum: '#f5e0f9',
  'tea-green': '#d4f4d3',
  polar: '#e0f5f9',
  tasman: '#ced9cf',
  tangerine: '#ffd0a9',
  'aero-blue': '#bffed1',
  'alice-blue': '#eef2f6',
  'jagged-ice': '#cfecee',
  'lavender-blush': '#ffe8ee',
  'barley-white': '#fff0c9',
  ghost: '#ced3d9',
  snuff: '#d9d5ec',
  parchment: '#f1e8d7',
  charlotte: '#aae8f8',
  risk: '#ced3d9',
  compliance: '#e2dcff',
  'third-party-risk-management': '#ffd4e0',
  'incident-management': '#fff0c9',
  'data-privacy': '#e6dcdc',
  'policy-management': '#c7dfe5',
  'business-continuity': '#ffc9e5',
  'internal-audit': '#ffddc1',
  'vulnerability-management': '#d6edc3',
  ccm: '#e4e0fa',
};
