import { FileInfo } from '@progress/kendo-angular-upload';
import { FileInterface } from '../interfaces/file.interface';

/**
 * Type Guard for File
 * Uses a check on the .rawFile prop to verify its a File object.
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isFileGuard = (value: unknown): value is File => !!(value as File).size;


/**
 * Type Guard for FileInfo
 * Uses a check on the .name prop to verify its a File object.
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isFileInfoGuard = (value: unknown): value is FileInfo => !!(value as FileInfo).name;

/**
 * Type Guard for FileInterface
 * Uses a check on the .fileName prop to verify its a File object.
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isFileInterfaceGuard = (value: unknown): value is FileInterface => !!(value as FileInterface).fileName;
