import { FilePickerComponent } from '../../file-picker/file-picker.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { documentFormControlComponentCreator } from '../creators/document-form-control-creator.config';

/**
 * Control configuration for DOCUMENT renderer component
 *
 */
export const DOCUMENT_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: FilePickerComponent,
  creatorFn: documentFormControlComponentCreator,
};
