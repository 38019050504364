import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  RendererAllComponentUnion,
  RendererFormControlComponentUnion,
} from '../component-renderer-component.interface';
import { RendererFormControlConfigInterface } from '../component-renderer-config.interface';
import { formControlComponentCreator } from './form-control-creator.config';

/**
 * Extends the formControlComponentCreator function to set readonly to true.
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererFormControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<RendererAllComponentUnion>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<RendererFormControlComponentUnion> | undefined} - Dynamic Component
 */
export const readOnlyFormControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererFormControlConfigInterface | undefined,
  component: Type<RendererAllComponentUnion>,
  control: { controlId: string; group: FormGroup } | undefined
): ComponentRef<RendererFormControlComponentUnion> | undefined =>
  formControlComponentCreator(viewContainerRef, componentData, component, control, true);
