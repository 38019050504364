<div
  class="sc-integration-chip-status flex align-items-center justify-content-between w-full sc-border-radius-16 sc-font-button-small"
  [ngClass]="{
    'sc-integration-chip-status--not-connected': status === integrationConnectionStatusEnum.NOT_CONNECTED,
    'sc-integration-chip-status--auth-failed': status === integrationConnectionStatusEnum.AUTHENTICATION_FAILED,
    'sc-integration-chip-status--connected': status === integrationConnectionStatusEnum.CONNECTED
  }">
  <sc-icon
    *ngIf="statusIcon[status] as statusIconData"
    [key]="statusIconData.icon"
    [colour]="statusIconData.colour"
    [width]="14"
    [height]="14"></sc-icon>
  <span>{{ status }}</span>
  <strong *ngIf="quantityConnection">{{ quantityConnection }}</strong>
</div>
