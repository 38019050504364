import { createSelector } from '@ngrx/store';

import { GlobalNavLinkInterface } from '@surecloud/common';
import { featureFlagSelectors, NG_4784_UPDATING_BRANDING_FOR_GARTNER } from '@surecloud/feature-flag';
import { UserProfileInterface, UserProfileSelectors } from '@surecloud/user-profile';
import {
  GLOBAL_NAV_LINKS_LIST,
  GLOBAL_NAV_LINKS_LIST_DESIGN_V2,
} from '../../../global-nav/global-nav-component.constants';

export const canViewNavItem = (userProfile: UserProfileInterface, navItem: GlobalNavLinkInterface): boolean => {
  // If no permission and no links, always show.
  if (!navItem.permission && !navItem.links) return true;
  // If no permission but links, only show if there are links.
  if (!navItem.permission && navItem.links && navItem.links.length === 0) return false;
  // if permission and user has permission, show.
  return !navItem.permission || userProfile.readPermissions.includes(navItem.permission);
};

/**
 * Get the Record View Workflow details in an interface we can pass to the Step Banner Component.
 */
export const getNavItemsNested = createSelector(
  UserProfileSelectors.getUserProfile,
  featureFlagSelectors.getFeatureFlagEntities,
  (userProfile, featureFlags) => {
    if (!userProfile) return [];
    const navLinks = featureFlags[NG_4784_UPDATING_BRANDING_FOR_GARTNER]
      ? GLOBAL_NAV_LINKS_LIST_DESIGN_V2
      : GLOBAL_NAV_LINKS_LIST;
    return navLinks.reduce<GlobalNavLinkInterface[]>((accumulatedValues: GlobalNavLinkInterface[], navItem) => {
      const link = { ...navItem };
      if (link.links) {
        link.links = link.links.filter(
          (subNavItem) => !subNavItem.permission || userProfile.readPermissions.includes(subNavItem.permission)
        );
      }

      const canView = canViewNavItem(userProfile, link);
      if (canView) {
        accumulatedValues.push(link);
      }
      return accumulatedValues;
    }, []);
  }
);
