import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
/**
 * Surecloud toolbar component.
 * @export
 * @class ToolbarsComponent
 */
@Component({
  standalone: true,
  selector: 'sc-toolbars',
  templateUrl: './toolbars.component.html',
  styleUrls: ['./toolbars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class ToolbarsComponent {
  /**
   * Determines whether to show or hide the secondary nav.
   * @type {boolean}
   * @memberof ToolbarsComponent
   */
  @Input() canViewSecondaryNav = false;

  /**
   * I wasn't able to clone this component for Design Version 2 as the content projection template directives no longer worked.
   * Instead a flag has been injected to control the CSS.
   * @type {(null | boolean)}
   * @memberof ToolbarsComponent
   */
  @Input() isDesignV2Enabled: null | boolean = false;
}
