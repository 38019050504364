import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { ChipsModule } from 'primeng/chips';
import { BehaviorSubject, debounceTime, filter, first, Subject, takeUntil } from 'rxjs';
import { GridTagsEditableParentComponentInterface } from './grid-tags-editable-parent.component.interface';

/**
 * GridTagRendererComponent - custom AgGrid component for a tags field.
 *
 * @export
 * @class GridTagRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ChipsModule],
  styleUrls: ['./grid-tags-editable-renderer.component.scss'],
  selector: 'sc-tags-editable-renderer',
  templateUrl: './grid-tags-editable-renderer.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class GridTagsEditableRendererComponent implements ICellRendererAngularComp, OnDestroy {
  values: { label: string; tagId?: string }[] = [];

  /**
   * Ag Grids API
   *
   * @type {(GridApi | undefined)} the AG Grid api
   * @memberof GridTagsEditableRendererComponent
   */
  public agGridApi: GridApi | undefined;

  /**
   * Row Index
   *
   * @memberof GridTagsEditableRendererComponent
   */
  rowIndex = 0;

  /**
   * Cell renderer params
   *
   * @type {*}
   * @memberof GridTagsEditableRendererComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;

  /**
   * Reference to the column id of the input data
   *
   * @type {(string| undefined)}
   * @memberof GridTagsEditableRendererComponent
   */
  colId: string | undefined;

  /**
   *
   * @type {GridTagsEditableRendererComponent}
   * @memberof GridTagsEditableRendererComponent
   */
  parentComponentRef!: GridTagsEditableParentComponentInterface;

  /**
   * Controls deletion to avoid a graphql rate limitation errors
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof GridTagsEditableRendererComponent
   */
  private canDelete$ = new BehaviorSubject(true);

  /**
   * When the component is destroyed.
   * Then this observable will emit.
   * And other observables can tear down.
   *
   * @private
   * @type {Subject<boolean>}
   * @memberof GridTagsEditableRendererComponent
   */
  private destroyed$: Subject<boolean> = new Subject();

  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridTagsEditableRendererComponent
   * @return {boolean} isRefresh
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    this.values = this.getValues();
    return true;
  }

  /**
   * Handles the ag init and sets the value of the checkbox initial value
   *
   * @param {ICellRendererParams} params cell renderer params
   * @memberof GridCheckboxRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    this.gqlRateLimitControl();

    this.params = params;
    this.colId = params.column?.getColId();
    this.values = this.getValues();
    this.parentComponentRef = this.params.context.componentParent;
  }

  /**
   * Handles the logic for preventing deletion to avoid gql rate limit errors
   *
   * @memberof GridCheckboxRendererComponent
   */
  private gqlRateLimitControl(): void {
    this.canDelete$
      .pipe(
        filter((canDeleteState) => !canDeleteState),
        debounceTime(500),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.canDelete$.next(true);
      });
  }

  /**
   *
   * @memberof GridCheckboxRendererComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * Handles the valueAdded event which is passed to the parent component
   * This is a workaround to pass an event up in ag-grid to be handled by a parent component
   *
   * @param {{value}} value
   * @memberof GridCheckboxRendererComponent
   */
  valueAdded({ value }: { value: string }): void {
    const isDuplicate = this.values.find((val) => val.label === value);
    if (this.colId && !isDuplicate) {
      const { column, node } = this.params;
      this.parentComponentRef.addTag(this.params.data, { label: value }, column, node);
      this.values = this.values.concat({ label: value });
    } else {
      // Force a refresh to avoid having an empty label in the UI
      this.values = [...this.values];
    }
  }

  /**
   * Handles the valueRemoved event which is passed to the parent component
   * This is a workaround to pass an event up in ag-grid to be handled by a parent component
   *
   * @param {{value: partialTag}} tag to remove and can sometimes have no tag id
   * @memberof GridCheckboxRendererComponent
   */
  valueRemoved({ value: partialTag }: { value: { label: string; tagId?: string } }): void {
    if (!this.colId) return;
    const tagToRemove = this.values.find((tag) => tag.label === partialTag.label);
    this.canDelete$.pipe(first()).subscribe((canDelete) => {
      if (!canDelete || !tagToRemove?.tagId) {
        // Force a refresh to keep the "removed" tag in the UI
        this.values = [...this.values];
        return;
      }
      this.canDelete$.next(false);

      this.parentComponentRef.removeTag(this.params.data, tagToRemove.tagId);
      this.values = this.values.filter((val) => val.tagId !== tagToRemove.tagId);
    });
  }

  /**
   *Get tags from params.data which should have the tagId once it's returned from the backend
   *
   * @return {{ label: string; tagId?: string }}
   * @memberof GridCheckboxRendererComponent
   */
  private getValues(): { label: string; tagId?: string }[] {
    return this.params.data.tags.map((tag: { label: string; tagId: string }) => ({ ...tag }));
  }

  /**
   * Prevent typing while waiting for a tag id to be assigned to the previously added tag
   *
   * @param {KeyboardEvent} $event
   * @memberof GridCheckboxRendererComponent
   */
  keyPress($event: KeyboardEvent): void {
    // Prevent adding tags before all tags are saved
    if (this.values.find((val) => !val.tagId)) {
      $event.preventDefault();
    }
  }
}
