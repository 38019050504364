import { DirectionDataInterface } from './calculation.type';

export const CALC_DIRECTION_RIGHT = 'right';
export const CALC_DIRECTION_UP = 'up';
export const CALC_DIRECTION_DOWN = 'down';

export const CALC_CORRELATION_POSITIVE = 'positive';
export const CALC_CORRELATION_NEGATIVE = 'negative';
export const CALC_CORRELATION_NONE = 'none';

export const CALC_DIRECTION_DATA: { [key: string]: DirectionDataInterface } = {
  positive: {
    direction: CALC_DIRECTION_UP,
    text: $localize`Increased`,
  },
  negative: {
    direction: CALC_DIRECTION_DOWN,
    text: $localize`Decreased`,
  },
  none: {
    direction: CALC_DIRECTION_RIGHT,
    text: $localize`Stable`,
  },
};
