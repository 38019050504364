import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

/**
 * Generic Row component.
 * Good to use with the generic Section component & Column component.
 *
 * @export
 * @class RowComponent
 */
@Component({
  selector: 'sc-row',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RowComponent {
  /**
   * Add space below row (usually you don't want it on the last row in a set)
   *
   * @memberof RowComponent
   */
  @Input() addMarginBottom = true;

  /**
   * Class to add to the hosting element.
   *
   * @memberof RowComponent
   * @return {string} the set of classes as a string.
   */
  @HostBinding('className') get rowClass(): string {
    return `flex sc-row${this.addMarginBottom ? ' sc-mb-24' : ''}`;
  }
}
