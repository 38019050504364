import { ApolloLink } from '@apollo/client/core';
import { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { ApolloErrorService } from '@surecloud/common';
import { GraphQLError } from 'graphql';
import { ApiGraphQLErrorTypesEnum } from '../constants/api-error-types.enum';
import { isHttpErrorResponse } from '../type-guards/http-type-guards';

/**
 *
 * @param {GraphQLError} error GraphQLError object
 * @param {ApolloErrorService} errorService the service to handle errors
 */
function handleError(error: GraphQLError, errorService: ApolloErrorService): void {
  const errorType = error?.extensions?.['errorType'];

  switch (errorType) {
    case ApiGraphQLErrorTypesEnum.PERMISSION_DENIED:
      errorService.handlePermissionDeniedError();
      break;
    case ApiGraphQLErrorTypesEnum.INTERNAL:
      errorService.handleInternalError();
      break;
    case ApiGraphQLErrorTypesEnum.NOT_FOUND:
      errorService.handleNotFoundError(error);
      break;
    case ApiGraphQLErrorTypesEnum.BAD_REQUEST:
      errorService.handleBadRequest(error);
      break;
    default:
  }
}

/**
 *
 * @param {NetworkError} error Network Error object
 * @param {ApolloErrorService} errorService the service to handle errors
 */
function handleNetworkError(error: NetworkError, errorService: ApolloErrorService): void {
  errorService.handleNetworkError(error);
}

/**
 * Creates an Afterware (error) for graphQLErrors.
 * @export
 * @param {ErrorService} errorService The service for error handling
 * @return {ApolloLink} The Apollo link.
 */
export function graphQLErrorsLink(errorService: ApolloErrorService): ApolloLink {
  return onError(({ graphQLErrors }) => {
    if (!Array.isArray(graphQLErrors) || !graphQLErrors.length) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const error of graphQLErrors) {
      handleError(error, errorService);
    }
  });
}

/**
 * Creates an Afterware (error) for networkErrors.
 * @export
 * @param {ApolloErrorService} errorService The service for error handling
 * @return {ApolloLink} The Apollo link.
 */
export function networkErrorsLink(errorService: ApolloErrorService): ApolloLink {
  return onError(({ networkError }) => {
    if (isHttpErrorResponse(networkError)) {
      handleNetworkError(networkError, errorService);
    }
  });
}
