import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PopupModule } from '@progress/kendo-angular-popup';
import {
  IntegrationConnectionStatusEnumToText,
  IntegrationInterface,
  MORE_LABEL,
  trackByIndex,
} from '@surecloud/common';
import { findKeyByColor } from '../../colour-schema/colours.constants';
import { SvgIconsModule } from '../../icon/icons/svg-icons.module';
import { TagComponent } from '../../tag/tag.component';
import { ScTagTheme, ScTagThemes } from '../../tag/tag.constants';
import { getVisibleCategoryTags, IntegrationTag } from '../integration.constants';
import { IntegrationChipStatusComponent } from './integration-chip-status/integration-chip-status.component';

/**
 * Integration card component
 * @export
 * @class IntegrationCardComponent
 */
@Component({
  selector: 'sc-integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, IntegrationChipStatusComponent, TagComponent, SvgIconsModule, PopupModule],
})
export class IntegrationCardComponent implements OnInit {
  /**
   * The integration object to display
   * @type {IntegrationInterface}
   * @memberof IntegrationCardComponent
   */
  @Input() integration!: IntegrationInterface;

  /**
   * Whether the integration card is disabled
   * @type {boolean}
   * @memberof IntegrationCardComponent
   */
  @Input() disabled = true;

  /**
   * Whether the integration card categories is multi row
   * @type {boolean}
   * @memberof IntegrationCardComponent
   */
  @Input() multiRow = false;

  /**
   * Whether to show extra categories
   * @type {boolean}
   * @memberof IntegrationCardComponent
   */
  @Input() showExtraCategories = false;

  /*
   * Track by index function exposed to the template
   * @memberof IntegrationCardComponent
   */
  trackByIndex = trackByIndex;

  /**
   * Integration connection status enum text
   * visible to the template
   * @memberof IntegrationCardComponent
   */
  IntegrationConnectionStatusEnumToText = IntegrationConnectionStatusEnumToText;

  /**
   * Extra categories label
   * @memberof IntegrationCardComponent
   */
  MORE_LABEL = MORE_LABEL;

  /**
   * Category themes for the integration card
   * key is the category colour
   * value is the tag theme
   * @type {{ [key: string]: IntegrationTag }}
   * @memberof IntegrationCardComponent
   */
  categoryThemes: { [key: string]: ScTagTheme } = {};

  /**
   * Tags for the integration card
   * @type {IntegrationTag[]}
   * @memberof IntegrationCardComponent
   */
  tags: IntegrationTag[] = [];

  /**
   * Extra tags for the integration card
   * Shown when showExtraCategories button is clicked
   * @type {IntegrationTag[]}
   * @memberof IntegrationCardComponent
   */
  extraTags: IntegrationTag[] = [];

  /**
   * The number of category tags to be shown
   * Depends on the isCategoryMultiRow input
   * If isCategoryMultiRow is true, show 4 categories
   * @type {number}
   * @memberof IntegrationCardComponent
   */
  visibleCategoriesCount = 2;

  /**
   * Runs after Angular initiates all data-bound properties of the component.
   * @memberof IntegrationCardComponent
   */
  ngOnInit(): void {
    this.integration.categories.forEach((category) => {
      const key = findKeyByColor(category.colour);
      const tagTheme = ScTagThemes.find((theme) => theme === key);
      if (tagTheme) {
        this.categoryThemes[category.colour] = tagTheme;
        this.tags.push({
          theme: tagTheme,
          name: category.name,
        });
      }
    });
    this.visibleCategoriesCount = getVisibleCategoryTags(this.tags, this.multiRow);
    this.extraTags = this.tags.slice(this.visibleCategoriesCount);
  }
}
