import { UserProfilePermissionsEnum, makeHumanReadableZodErrors } from '@surecloud/common';
import { z } from 'zod';
import { UserProfileTypeEnum } from '../../+state/models/user-profile.models';

/**
 * User Profile Permission Zod schema.
 */
export const userProfilePermissionEnum = z.nativeEnum(UserProfilePermissionsEnum);

/**
 * User Profile Type Zod schema.
 */
export const userProfileType = z.nativeEnum(UserProfileTypeEnum);

export const featureFlagContextAttributeZodSchema = z.object({
  name: z.string(),
  value: z.string(),
});

export const featureFlagContextZodSchema = z.object({
  kind: z.string(),
  key: z.string(),
  attributes: z.array(featureFlagContextAttributeZodSchema),
});

/**
 * Normalised User Profile Zod schema.
 */
export const normalisedUserProfileZodSchema = z.object({
  firstName: z.string().nullable().optional(),
  type: userProfileType,
  colour: z.string(),
  lastName: z.string().nullable().optional(),
  emailAddress: z.string(),
  initials: z.string(),
  createPermissions: z.array(userProfilePermissionEnum),
  deletePermissions: z.array(userProfilePermissionEnum),
  readPermissions: z.array(userProfilePermissionEnum),
  updatePermissions: z.array(userProfilePermissionEnum),
  userId: z.string().nullable().optional(),
  featureFlagSecureModeHash: z.string().nullable().optional(),
  featureFlagContexts: z.array(featureFlagContextZodSchema),
});

/**
 * Normalised User Profile data interface.
 */
export type NormalisedUserProfile = z.infer<typeof normalisedUserProfileZodSchema>;

/**
 * Guard to check the normalised User Profile matches the schema.
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedUserProfile.
 */
export const isNormalisedUserProfileGuard = (map: unknown): map is NormalisedUserProfile =>
  normalisedUserProfileZodSchema.safeParse(map).success;

/**
 * When the normalised User Profile data does not match the schema
 * then get the error as to show where the problem is.
 * @param {unknown} map Map of the data to check.
 * @return {z.SafeParseReturnType<unknown, NormalisedUserList>} The error on where the map of the data does not match a NormalisedUserProfile.
 */
export const getNormalisedUserProfileError = (map: unknown): string => {
  const zodResult = normalisedUserProfileZodSchema.safeParse(map);
  return zodResult.success ? 'No Errors' : makeHumanReadableZodErrors(zodResult.error.issues);
};
