<div class="sc-select-hierarchy" scCommonE2eHook="{{ testhook }}">
  <kendo-label [text]="label" class="sc-label">
    <div #anchor>
      <kendo-multiselect
        class="sc-select-hierarchy__dropdown"
        rounded="none"
        scCommonE2eHook="{{ testhook }}:multiselect"
        size="none"
        textField="text"
        valueField="value"
        [autoClose]="false"
        [checkboxes]="false"
        [clearButton]="false"
        [data]="options"
        [disabled]="disabled"
        [filterable]="false"
        [ngClass]="{
          'sc-select-hierarchy__dropdown--open': open$ | async,
          'sc-select-hierarchy__dropdown--not-removable': !isRemovable,
          'sc-select-hierarchy__dropdown--multiple': !singleSelect,
          'sc-select-hierarchy__dropdown--single': singleSelect,
          'sc-input--readonly': readonly && !disabled
        }"
        [(ngModel)]="values"
        [placeholder]="placeholder"
        [popupSettings]="{ popupClass: 'hidden' }"
        [readonly]="readonly"
        [valuePrimitive]="true"
        (click)="toggle()"
        (keydown.enter)="toggle()"
        (removeTag)="removeValue($event)">
        <ng-template></ng-template>
      </kendo-multiselect>
    </div>
    <div class="flex">
      <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
    </div>
  </kendo-label>
  <kendo-popup
    class="sc-select-hierarchy__popup sc-w-inherit"
    positionMode="absolute"
    #popup
    [anchor]="anchor"
    [animate]="false"
    *ngIf="open$ | async">
    <ng-scrollbar>
      <sc-select-hierarchy-nodes
        [name]="name"
        [nodes]="nodes"
        [singleSelect]="singleSelect"
        [values]="values"
        (selected)="selectValues($event)">
      </sc-select-hierarchy-nodes>
    </ng-scrollbar>
  </kendo-popup>
</div>
