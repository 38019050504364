import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAVIGATE_ROW_COLUMN, SCGridSchemaTypeEnum, StandaloneRouteNamesEnum } from '@surecloud/common';
import { HomepageApplicationsActions, HomepageApplicationsFeatureFacade } from '@surecloud/homepage-applications-state';
import { PriorityTasksFeatureFacade } from '@surecloud/priority-tasks-state';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { first } from 'rxjs';
import { ApplicationListActions } from './+state/actions/application-list.actions';

@Component({
  selector: 'sc-standalone-application-list',
  templateUrl: './application-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandaloneApplicationListComponent implements OnInit, OnDestroy {
  /**
   * Column Definitions for the Homepage Applications grid
   * @type {ColDef[]}
   * @memberof StandaloneApplicationListComponent
   */
  homepageApplicationsColDefs: ColDef[] = [
    {
      field: NAVIGATE_ROW_COLUMN,
      type: SCGridSchemaTypeEnum.Navigate,
      headerName: '',
    },
    {
      field: 'entityName',
      type: SCGridSchemaTypeEnum.Text,
      headerName: $localize`Name`,
    },
    {
      field: 'entityDescription',
      type: SCGridSchemaTypeEnum.Text,
      headerName: $localize`Description`,
    },
  ];

  /**
   * Creates an instance of StandaloneApplicationListComponent.
   * @param {HomepageApplicationsFeatureFacade} homepageApplicationsFeatureFacade The Homepage Applications Feature Facade.
   * @param {Router} router The Angular Router.
   * @param {PriorityTasksFeatureFacade} priorityTasksFeatureFacade The Priority Tasks Feature Facade.
   * @memberof StandaloneApplicationListComponent
   */
  constructor(
    public homepageApplicationsFeatureFacade: HomepageApplicationsFeatureFacade,
    private router: Router,
    public priorityTasksFeatureFacade: PriorityTasksFeatureFacade
  ) {}

  /**
   * On init dispatch an event to load entities.
   * @memberof StandaloneApplicationListComponent
   */
  ngOnInit(): void {
    this.priorityTasksFeatureFacade.dispatch(ApplicationListActions.applicationListEnter());
  }

  /**
   * On destroy do nothing.
   * @memberof StandaloneApplicationListComponent
   */
  ngOnDestroy(): void {
    this.priorityTasksFeatureFacade.dispatch(ApplicationListActions.applicationListLeave());
  }

  /**
   * Navigates to the Entity.
   * @param {CellClickedEvent} event - the cell click event
   * @param {ColDef<unknown>} event.colDef - the cell column definition
   * @param {unknown} event.data - the cell data
   * @memberof StandaloneApplicationListComponent
   */
  entityCellClicked({ colDef, data }: CellClickedEvent): void {
    if (colDef.field === NAVIGATE_ROW_COLUMN && data.entityId) {
      const { entityId } = data;
      this.router.navigate([StandaloneRouteNamesEnum.RecordList, entityId]);
    }
  }

  /**
   * When a Homepage Application Tab is clicked then select that tab
   * @param {number} tabIndex the tab index
   * @memberof StandaloneApplicationListComponent
   */
  changeTab(tabIndex: number): void {
    this.homepageApplicationsFeatureFacade.getTabs$.pipe(first()).subscribe((tabs) => {
      if (tabIndex > -1 && tabs.length > tabIndex) {
        this.homepageApplicationsFeatureFacade.dispatch(
          HomepageApplicationsActions.selectHomepageApplication({ applicationName: tabs[tabIndex].name })
        );
      }
    });
  }
}
