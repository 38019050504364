import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkError } from '@apollo/client/errors';
import { Dictionary } from '@ngrx/entity';
import { GraphQLError } from 'graphql';
import { RequestErrorActions } from '../../+state/actions/request-error.actions';
import { CommonActionDispatcher } from '../../+state/facades/common-action-dispatcher';
import { API_ERROR_MESSAGES } from '../../constants/error-messages.constants';
import { CommonRoutesEnum } from '../../routes/common-routes.enum';
import { ToastService } from '../toast/toast.service';

/**
 * The key of the requestId in the debugInfo object from API
 * @export
 */
export const REQUEST_ID_KEY = 'requestId';

/**
 * Error service.
 * @export
 * @class ApolloErrorService
 */
@Injectable({
  providedIn: 'root',
})
export class ApolloErrorService {
  /**
   * Creates an instance of ApolloErrorService.
   * @param {Router} router The Angular Router service.
   * @param {ToastService} toastService The PrimeNG Message wrapper message service.
   * @param {CommonActionDispatcher} commonDispatcher The facade used to send errors.
   * @memberof ApolloErrorService
   */
  constructor(
    private router: Router,
    private toastService: ToastService,
    private readonly commonDispatcher: CommonActionDispatcher
  ) {}

  /**
   * Handle permission denied error
   * @memberof ApolloErrorService
   */
  public handlePermissionDeniedError(): void {
    this.router.navigate([CommonRoutesEnum.ErrorRoot], {
      queryParams: {
        message: $localize`Sorry, it looks like you are not authorized to view that page. Check with the system administrator, that your permissions have been configured correctly`,
      },
    });
  }

  /**
   * Handle internal error
   * @memberof ApolloErrorService
   */
  public handleInternalError(): void {
    this.toastService.handleMessage($localize`Internal Error. An unspecified error occurred with this operation.`);
  }

  /**
   * Handle not found
   * @param {GraphQLError} graphQlError the error details
   * @memberof ApolloErrorService
   */
  public handleNotFoundError(graphQlError: GraphQLError): void {
    if (graphQlError.message === API_ERROR_MESSAGES.subDomainNotFound) {
      return; // Do nothing, and let the component handle the error
    }
    this.router.navigate([CommonRoutesEnum.ErrorRoot], {
      queryParams: {
        message: $localize`It looks like the resource you've requested is not available.`,
      },
    });
  }

  /**
   * Handle bad request
   * @param {GraphQLError} graphQlError the error details
   * @memberof ApolloErrorService
   */
  public handleBadRequest(graphQlError: GraphQLError): void {
    if (graphQlError.message === API_ERROR_MESSAGES.validationFailed) {
      this.handleValidationFailed(graphQlError.extensions['debugInfo'] as Dictionary<string>);
    }
  }

  /**
   * Handle Network Error
   * @param {NetworkError} error the network error object
   * @memberof ApolloErrorService
   */
  public handleNetworkError(error: NetworkError): void {
    this.router.navigate([CommonRoutesEnum.ErrorRoot], {
      queryParams: {
        message: $localize`Network Error: ${error?.message}`,
      },
    });
  }

  /**
   * Handle validation failure and show an error toast for each error
   * @param { Dictionary<string> } debugInfo the error details
   * @memberof ApolloErrorService
   */
  private handleValidationFailed(debugInfo: Dictionary<string>): void {
    const errors = Object.keys(debugInfo)
      .filter((key) => key !== REQUEST_ID_KEY)
      .map((key) => `${debugInfo[key]}`);

    const message = errors.join('\n');
    const key = Object.keys(debugInfo).find((debugKey) => debugInfo[debugKey] === message);

    this.toastService.handleMessage(message);

    this.commonDispatcher.dispatch(
      RequestErrorActions.errorOccurred({ message, requestId: debugInfo[REQUEST_ID_KEY] || 'ID_UNKNOWN', key })
    );
  }
}
