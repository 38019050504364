import { Injectable } from '@angular/core';
import { RecordTaskApiInterface } from '@surecloud/api-types';
import { LoggerService, NormalisationOutputStrategyInterface, Normalise } from '@surecloud/common';
import { schema } from 'normalizr';
import {
  NormalisedTaskList,
  getNormalisedTaskListError,
  isNormalisedTaskListGuard,
} from './normalise-get-tasks.validation';

const TASKS_KEY = 'tasks';

/**
 * Service encapsulating the schema and configuration for flattening the get Task API response.
 * @export
 * @class NormaliseGetTaskService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetTaskService {
  /**
   * Task rule schema.
   * @memberof NormaliseGetTaskService
   */
  taskSchema = new schema.Entity(TASKS_KEY, undefined, {
    idAttribute: (value): string => value.taskId,
  });

  /**
   * Task list schema.
   * @memberof NormaliseGetTaskService
   */
  taskListSchema = [this.taskSchema];

  /**
   * An array of output strategies to process the flattened data.
   * @type {NormalisationOutputStrategyInterface[]}
   * @memberof NormaliseGetTaskService
   */
  outputStrategies: NormalisationOutputStrategyInterface[] = [{ key: TASKS_KEY }];

  /**
   * Creates an instance of NormaliseGetTaskService
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetTaskService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the Task data from the Task API response.
   * @param {RecordTaskApiInterface[]} taskList The Task data structure as it comes from the API.
   * @return {NormalisedTaskList} UI Task List data structure.
   * @memberof NormaliseGetTaskService
   */
  extract(taskList: RecordTaskApiInterface[]): NormalisedTaskList {
    const { tasks } = Normalise.flatten(taskList, this.taskListSchema, this.outputStrategies);

    const normaliseResult = { tasks };

    if (!isNormalisedTaskListGuard(normaliseResult)) {
      this.logger.logEvent('Task Feature', 'Normalise Task List Data', getNormalisedTaskListError(normaliseResult));

      throw new Error('The result of the normalise Task List does not match the expected format.');
    }

    return normaliseResult;
  }
}
