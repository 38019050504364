/**
 * Cleans a string of any non ISO-88591 characters.
 * @param {string} value The string to clean.
 * @return {string} The cleaned string.
 */
export const stringToISO88591 = (value: string): string =>
  value
    .split('')
    .map((character) => (character.charCodeAt(0) < 256 ? character : ''))
    .join('');
