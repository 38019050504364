<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<div class="flex sc-matrix-builder" *ngIf="matrix">
  <div class="flex align-items-center sc-mr-12 sc-guidance-text sc-text sc-matrix-builder__title">
    <span>
      {{ matrix.labelY | uppercase }}
    </span>
  </div>
  <div class="matrix-container flex flex-column-reverse">
    <div class="flex justify-content-center sc-mt-16 sc-guidance-text sc-text">
      {{ matrix.labelX | uppercase }}
    </div>
    <div
      class="flex matrix-container__rows"
      *ngFor="
        let row of matrix.rows;
        trackBy: trackByFunction;
        let rowIndex = index;
        let firstRow = first;
        let lastRow = last
      ">
      <div
        class="flex align-items-center sc-mr-16 sc-font-guidance-text matrix-container__rows--cell"
        [ngClass]="{ 'matrix-container__rows--label': firstRow }"
        [ngStyle]="{ 'min-width': minRowLabelWidth + 'px' }">
        <span
          #rowLabel
          kendoTooltip
          [showOn]="'none'"
          [position]="'top'"
          [title]="row.label"
          (mouseenter)="showTooltipIfEllipsis(rowLabel, 'top', rowMaxHeight)"
          (mouseleave)="hideTooltip(rowLabel)">
          {{ row.label || emptyLabel }}
        </span>
      </div>
      <div
        class="flex matrix-container__columns"
        *ngFor="
          let column of row.columns;
          trackBy: trackByCellIdFunction;
          let colIndex = index;
          let lastCol = last;
          let firstCol = first
        ">
        <div class="flex flex-column">
          <sc-matrix-cell
            [id]="column.cellId"
            [status]="column.status"
            [isEditable]="isEditable"
            [label]="column.quantity"
            [colour]="column.colour"
            (selectedClicked)="selectCell($event)">
          </sc-matrix-cell>
          <div
            #columnLabel
            kendoTooltip
            [showOn]="'none'"
            [title]="column.label"
            (mouseenter)="showTooltipIfEllipsis(columnLabel, 'bottom', colMaxHeight)"
            (mouseleave)="hideTooltip(columnLabel)"
            class="sc-mt-16 sc-font-guidance-text matrix-container__columns--label"
            *ngIf="firstRow">
            {{ column.label || emptyLabel }}
          </div>
        </div>
        <div
          class="flex align-items-center sc-pl-8"
          *ngIf="isEditable && lastCol && lastRow && matrix.rows.length > matrixMinSize">
          <sc-icon-button
            size="small"
            themeColour="red"
            icon="trash"
            shape="circular"
            (keydown.enter)="deleteRow.emit(row.rowId)"
            (click)="deleteRow.emit(row.rowId)"></sc-icon-button>
        </div>
        <ng-container *ngIf="firstRow && lastCol && matrix.rows[0].columns.length < matrixMaxSize && isEditable">
          <sc-matrix-cell
            [status]="statusEnum.NEW"
            [isEditable]="true"
            (newCellClick)="addColumn.emit()"></sc-matrix-cell>
        </ng-container>
      </div>
    </div>
    <div
      class="flex matrix-container__delete-icons justify-content-between"
      [ngClass]="{
        'matrix-container__full-column':
          matrix.rows.length === matrixMaxSize && matrix.rows[0].columns.length < matrixMaxSize
      }"
      [ngClass]="{ 'matrix-container__full-row': matrix.rows[0].columns.length === matrixMaxSize }">
      <div class="flex">
        <div class="matrix-container__empty" [ngStyle]="{ 'min-width': minRowLabelWidth + 'px' }"></div>
        <sc-matrix-cell
          *ngIf="matrix.rows.length < matrixMaxSize && isEditable"
          [status]="statusEnum.NEW"
          [isEditable]="true"
          (newCellClick)="addRow.emit()"></sc-matrix-cell>
      </div>
      <sc-icon-button
        *ngIf="isEditable && matrix.rows.length >= matrixMinSize && matrix.rows[0].columns.length > matrixMinSize"
        class="sc-pb-8 sc-pr-24"
        size="small"
        themeColour="red"
        icon="trash"
        shape="circular"
        (keydown.enter)="deleteLastColumn()"
        (click)="deleteLastColumn()">
      </sc-icon-button>
    </div>
  </div>
  <div class="sc-ml-16">
    <sc-rating-scaling-colour (colourSelected)="assignToCells($event)" [ratingList]="ratingList">
    </sc-rating-scaling-colour>
  </div>
</div>
