<div class="flex align-items-center">
  <sc-icon class="sc-mr-8" key="checkmark-outline" [width]="60" [height]="60" colour="green-300"></sc-icon>
  <div class="sc-text sc-font-body-text sc-ml-8">
    <p i18n class="sc-mb-4">Your files have been uploaded successfully!</p>
    <p i18n>
      Please note that it may take some time for the imported records to appear on the platform.<br />
      You can continue browsing the platform in the meantime.
    </p>
  </div>
</div>
<sc-modal-buttons
  i18n-primaryButtonTitle
  primaryButtonTitle="OK"
  (primaryClicked)="close()"
  [secondaryButtonShow]="false"
  [primaryButtonSize]="'medium'">
</sc-modal-buttons>
