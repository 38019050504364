/**
 * Pagination Interface containing the current page, page size and total items
 * @export
 * @interface PaginationInterface
 */
export interface PaginationInterface {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

/**
 * Pagination Input Interface
 * @export
 * @enum {number}
 */
export enum PaginationTypeEnum {
  ClientSide = 'ClientSide',
  ServerSide = 'ServerSide',
}
