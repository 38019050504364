import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { RequestIdInterface, RoleInterface } from '@surecloud/common';
import { NormalisedRoleList } from '../../services/normalise-get-roles/normalise-get-roles.validations';

/**
 * Role API actions map.
 *
 */
const ROLE_API_ACTIONS_MAP = {
  'Create Role': props<RequestIdInterface>(),
  'Create Role Success': props<{ role: RoleInterface }>(),
  'Create Role Failure': props<{ error: string }>(),

  'Read Role List': emptyProps(),
  'Read Role List Data Success': props<{ normalisedRoleList: NormalisedRoleList }>(),
  'Read Role List Success': props<{ roles: RoleInterface[] }>(),
  'Read Role List Failure': props<{ error: string }>(),

  'Read Role': props<{ roleId: string }>(),
  'Read Role Data Success': props<{ normalisedRoleList: NormalisedRoleList }>(),
  'Read Role Success': props<{ role: RoleInterface }>(),
  'Read Role Failure': props<{ error: string }>(),

  'Read Roles': props<{ roleIds: string[] }>(),
  'Read Roles Data Success': props<{ normalisedRoleList: NormalisedRoleList }>(),
  'Read Roles Success': props<{ roles: RoleInterface[] }>(),
  'Read Roles Failure': props<{ error: string }>(),

  'Delete Role': props<{ role: RoleInterface }>(),
  'Delete Role Success': props<{ roleId: string }>(),
  'Delete Role Failure': props<{ error: string }>(),

  'Update Role': props<{ role: RoleInterface }>(),
  'Update Role Success': props<{ role: RoleInterface }>(),
  'Update Role Failure': props<{ error: string }>(),
};

/**
 * Role API actions.
 * @export
 */
const RoleApiActions = createActionGroup({
  source: 'Role/API',
  events: ROLE_API_ACTIONS_MAP,
});

/**
 * Role actions related to state.
 */
const RoleActions = {
  selectRole: createAction('[Role] Select Role', props<{ roleId: string | undefined }>()),
};

/**
 * Role effect actions map.
 */
const ROLE_EFFECT_ACTIONS_MAP = {
  'Navigate To Role': props<{ roleId: string }>(),
};

/**
 * Role Effect actions.
 */
const RoleEffectActions = createActionGroup({
  source: 'Role/Effects',
  events: ROLE_EFFECT_ACTIONS_MAP,
});

/**
 * Role State actions.
 * @export
 */
export const RoleStateActions = {
  ...RoleActions,
  ...RoleApiActions,
  ...RoleEffectActions,
};
