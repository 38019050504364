import { NgModule } from '@angular/core';
import { completeIconSet } from '@surecloud/common';
import { ScIconSizeLgMeasure, ScIconSizeMdMeasure, ScIconSizeSmMeasure, ScIconSizeXLgMeasure } from './icon.constant';
import { SvgIconsModule } from './svg-icons.module';

/**
 * Icon Module to be called by external modules
 * @export
 * @class CommonIconModule
 */
@NgModule({
  imports: [
    SvgIconsModule.forRoot({
      sizes: {
        small: ScIconSizeSmMeasure,
        medium: ScIconSizeMdMeasure,
        large: ScIconSizeLgMeasure,
        'x-large': ScIconSizeXLgMeasure,
      },
      defaultSize: 'large',
      icons: completeIconSet,
    }),
  ],
  exports: [SvgIconsModule],
  declarations: [],
})
export class CommonIconModule {}
