import { EntityAttributeTypeApiInterface } from '@surecloud/api-types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { EntityAttributeTypeEnum } from '../../../interfaces/entity-attribute-type/entity-attribute-type.enum';
import { SelectDropdownComponentOptionInterface } from '../../../interfaces/select-dropdown.component.interface';
import { NotRequiredBoolean, NotRequiredString } from '../../../utility-types/not-required.type';
import { SCGridSchemaTypeEnum, SCGridSchemaTypeUnion } from '../../grid-schema.class';
import { EntityAttributeCalculationTrendDirectionEnum, EntityAttributeUserModeTypeEnum } from './attribute.model.enum';

/**
 * Entity attribute create interface.
 * @property {string} entityId The ID of the entity to create the attribute on.
 * @export
 * @interface EntityAttributeCreateInterface
 */
export interface EntityAttributeCreateInterface {
  entityId: string;
}

/**
 * Interface returned whenever an attribute has successfully been updated.
 * @export
 * @interface EntityAttributeUpdatedInterface
 */
export interface EntityAttributeUpdatedInterface {
  attributeId: string;
}

/**
 * Interface returned whenever a calculation attribute has successfully been updated.
 * @export
 * @interface EntityCalculationAttributeUpdatedInterface
 */
export interface EntityCalculationAttributeUpdatedInterface extends EntityAttributeUpdatedInterface {
  trendAnalysis?: boolean | null;
  trendDirection?: EntityAttributeCalculationTrendDirectionEnum | null;
  formula?: string | null;
}

/**
 * EntityAttribute type definition
 * @property {string} attributeId The ID of the attribute.
 * @property {string | null} guidanceText The guidance text of the attribute.
 * @property {string | null} name The name of the attribute.
 * @property {EntityAttributeTypeEnum | null} type The type of attribute.
 * @export
 * @interface EntityAttributeInterface
 */
export interface BaseEntityAttributeInterface {
  entityId: string;
  attributeId: string;
  guidanceText: string | null;
  name: string | null;
  type: EntityAttributeTypeEnum | null;
}

/**
 * EntityAttributePlaceholderInterface type definition
 * @property {string | null} placeholder The placeholder of the attribute.
 * @export
 * @interface EntityAttributePlaceholderInterface
 */
export interface EntityAttributePlaceholderInterface extends BaseEntityAttributeInterface {
  placeholder: string | null;
  type:
    | EntityAttributeTypeEnum.Email
    | EntityAttributeTypeEnum.Name
    | EntityAttributeTypeEnum.Number
    | EntityAttributeTypeEnum.OptionList
    | EntityAttributeTypeEnum.Text
    | EntityAttributeTypeEnum.Url
    | EntityAttributeTypeEnum.User;
}

/**
 *
 *EntityAttributeOptionListInterface type definition
 * @property {string | null} optionListId The Id of the OptionList attribute.
 * @property {boolean} multiple If the list allows multiple selections
 * @property {boolean} extendable The attribute can be extended
 * @export
 * @interface EntityAttributeOptionListInterface Entity Attribute option list
 * @extends {EntityAttributePlaceholderInterface}
 */
export interface EntityAttributeOptionListInterface extends EntityAttributePlaceholderInterface {
  optionListId: NotRequiredString;
  multiple: NotRequiredBoolean;
  extendable: NotRequiredBoolean;
}

/**
 *EntityAttributeCalculationInterface type definition
 * @property {string | null} formula The formula of the attribute.
 * @property {EntityAttributeCalculationTrendDirectionEnum} trendDirection trend direction of the attribute.
 * @property {boolean} trendAnalysis The attribute can show trend analysis
 * @export
 * @interface EntityAttributeCalculationInterface
 * @extends {BaseEntityAttributeInterface}
 */
export interface EntityAttributeCalculationInterface extends BaseEntityAttributeInterface {
  formula: NotRequiredString;
  trendDirection: EntityAttributeCalculationTrendDirectionEnum | null;
  trendAnalysis: NotRequiredBoolean;
}

/**
 *  EntityAttributeSequenceInterface type definition
 * @property {string | null} prefix The prefix of the attribute.
 * @property {EntityAttributeTypeEnum.Sequence} type The type of attribute.
 * @export
 * @interface EntityAttributeSequenceInterface Entity Attribute Sequence
 * @extends {BaseEntityAttributeInterface}
 */
export interface EntityAttributeSequenceInterface extends BaseEntityAttributeInterface {
  prefix: string | null;
}

/**
 * EntityAttribute User type definition
 * @property {boolean} multiple The multiple selection option of the attribute.
 * @property {EntityAttributeUserModeTypeEnum} userMode The user mode type of attribute.
 * @property {EntityAttributeTypeEnum.User} type The type of attribute.
 * @export
 * @interface EntityAttributeUserInterface
 */
export interface EntityAttributeUserInterface
  extends BaseEntityAttributeInterface,
    EntityAttributePlaceholderInterface {
  userMode: EntityAttributeUserModeTypeEnum | null;
  multiple: boolean;
  type: EntityAttributeTypeEnum.User;
  hierarchy: boolean | null;
  roleIds: string[] | null;
}

/**
 * EntityAttribute Name type definition
 * @property {boolean} calculated The toggle to use a calculated name instead of a input string of the attribute name.
 * @property {string | null} calculation The calculation forumla of attribute.
 * @property {EntityAttributeTypeEnum.Name} type The type of attribute.
 * @export
 * @interface EntityAttributeUserInterface
 * @extends {EntityAttributePlaceholderInterface}
 */
export interface EntityAttributeNameInterface extends EntityAttributePlaceholderInterface {
  calculated: boolean;
  calculation: string | null;
  type: EntityAttributeTypeEnum.Name;
}

/**
 * EntityAttribute Text type definition#
 * @property {boolean} large The toggle to use a large text size.
 * @export
 * @interface EntityAttributeTextInterface
 * @extends {BaseEntityAttributeInterface}
 */
export interface EntityAttributeTextInterface extends BaseEntityAttributeInterface {
  large: boolean;
  type: EntityAttributeTypeEnum.Text;
}

/**
 * EntityAttributeCustomHierarchyInterface type definition
 * @property {NotRequiredString} customHierarchyId The Custom Hierarchy Id.
 * @property {NotRequiredString[]} levels The selected levels of the hierarchy.
 * @export
 * @interface EntityAttributeCustomHierarchyInterface Entity Attribute Custom Hierarchy
 * @extends {EntityAttributePlaceholderInterface}
 */
export interface EntityAttributeCustomHierarchyInterface extends BaseEntityAttributeInterface {
  customHierarchyId: NotRequiredString;
  levels: string[] | null;
}

/**
 * Union type of Entity Attribute Components.
 * @export
 */
export type EntityAttributeInterface =
  | BaseEntityAttributeInterface
  | EntityAttributePlaceholderInterface
  | EntityAttributeSequenceInterface
  | EntityAttributeUserInterface
  | EntityAttributeOptionListInterface
  | EntityAttributeCustomHierarchyInterface
  | EntityAttributeCalculationInterface
  | EntityAttributeNameInterface
  | EntityAttributeTextInterface;

export type EntityAttributeIntersectionInterface = BaseEntityAttributeInterface &
  EntityAttributeSequenceInterface &
  EntityAttributeUserInterface &
  EntityAttributeOptionListInterface &
  EntityAttributeCustomHierarchyInterface &
  EntityAttributeCalculationInterface;

/**
 * Type guard to check if it is a attribute
 * @param {unknown} value - the object.
 * @return {boolean} Whether the the data is of type BaseEntityAttributeInterface.
 */
export const isAttribute = (value: unknown): value is EntityAttributeInterface => {
  const entity = value as EntityAttributeInterface;
  return entity.attributeId !== undefined && entity.name !== undefined;
};

/**
 * Type guard to check if it is a placeholder entity attribute.
 * @param {EntityAttributeInterface} attribute The Entity Attribute to check.
 * @return {boolean} Whether Entity Attribute is of type EntityAttributePlaceholderInterface.
 */
export const isPlaceholderEntityAttribute = (
  attribute: EntityAttributeInterface
): attribute is EntityAttributePlaceholderInterface => {
  const types = [
    EntityAttributeTypeEnum.Email,
    EntityAttributeTypeEnum.Name,
    EntityAttributeTypeEnum.Number,
    EntityAttributeTypeEnum.OptionList,
    EntityAttributeTypeEnum.Text,
    EntityAttributeTypeEnum.Url,
    EntityAttributeTypeEnum.User,
  ];

  return !!attribute.type && types.includes(attribute.type);
};

/**
 * Type guard to check if a attribute is of type option list.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is OptionList.
 * @return {boolean} Whether the view component is of type EntityAttributeOptionListInterface.
 */
export const isAttributeOptionList = (
  attribute: EntityAttributeInterface | undefined
): attribute is EntityAttributeOptionListInterface =>
  !!attribute && attribute.type === EntityAttributeTypeEnum.OptionList;

/**
 * Type guard to check if a attribute is of type Custom Hierarchy.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is CustomHierarchy.
 * @return {boolean} Whether the view component is of type EntityAttributeCustomHierarchyInterface.
 */
export const isAttributeCustomHierarchy = (
  attribute: EntityAttributeInterface | undefined
): attribute is EntityAttributeCustomHierarchyInterface =>
  !!attribute && attribute.type === EntityAttributeTypeEnum.Hierarchy;

/**
 * Type guard to check if a attribute is of type Sequence.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is ATTRIBUTE.
 * @return {boolean} Whether the view component is of type EntityAttributeSequenceInterface.
 */
export const isAttributeSequence = (
  attribute: EntityAttributeInterface
): attribute is EntityAttributeSequenceInterface => attribute.type === EntityAttributeTypeEnum.Sequence;

/**
 * Type guard to check if a attribute is of type User.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is User.
 * @return {boolean} Whether the view component is of type EntityAttributeUserInterface.
 */
export const isAttributeUser = (attribute: EntityAttributeInterface): attribute is EntityAttributeUserInterface =>
  attribute.type === EntityAttributeTypeEnum.User;

/**
 * Type guard to check if a attribute is of type Name.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is Name.
 * @return {boolean} Whether the view component is of type EntityAttributeNameInterface.
 */
export const isAttributeName = (attribute: EntityAttributeInterface): attribute is EntityAttributeNameInterface =>
  attribute.type === EntityAttributeTypeEnum.Name;

/**
 * Type guard to check if a attribute is of type TEXT.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is Name.
 * @return {boolean} Whether the view component is of type EntityAttributeNameInterface.
 */
export const isAttributeText = (attribute: EntityAttributeInterface): attribute is EntityAttributeTextInterface =>
  attribute.type === EntityAttributeTypeEnum.Text;

/**
 * Type guard to check if a attribute is of type Calculation.
 * @param {EntityAttributeInterface} attribute The view component to check if the type is Calculation.
 * @return {boolean} Whether the view component is of type EntityAttributeCalculationInterface.
 */
export const isAttributeCalculation = (
  attribute: EntityAttributeInterface
): attribute is EntityAttributeCalculationInterface => attribute.type === EntityAttributeTypeEnum.Calculation;

/**
 * Helper function to quickly create an EntityAttribute.
 * @export
 * @param {EntityAttributeTypeEnum} type The attribute type to create
 * @param {string} name The name of the attribute
 * @param {string} attributeId The ID of the attribute
 * @param {string} entityId The entity ID the attribute belongs to
 * @param {string} guidanceText The guidance text of the attribute
 * @return {EntityAttributeInterface} Entity attribute
 */
export const makeEntityAttribute = (
  type: EntityAttributeTypeEnum | null,
  name: string | null,
  attributeId: string,
  entityId: string,
  guidanceText: string | null = null
): EntityAttributeInterface => ({
  entityId,
  attributeId,
  guidanceText,
  name,
  type,
});

/**
 * Public method to get the user mode options from te EntityAttributeUserModeTypeEnum.
 * @param modes
 * @return { SelectDropdownComponentOptionInterface[]}  {SelectDropdownComponentOptionInterface[]} Dropdown options for user modes
 */
export const userModeOptions: SelectDropdownComponentOptionInterface[] = (
  Object.keys(EntityAttributeUserModeTypeEnum) as Array<keyof typeof EntityAttributeUserModeTypeEnum>
).map((key) => ({
  text: key === 'UsersGroups' ? $localize`Users and Groups` : key,
  value: EntityAttributeUserModeTypeEnum[`${key}`],
}));

/**
 * Public method to get the calculation tren direction options from te EntityAttributeCalculationTrendDirectionEnum.
 * @param modes
 * @return { SelectDropdownComponentOptionInterface[]}  {SelectDropdownComponentOptionInterface[]} Dropdown options for calculation trends
 */
export const calculationTrendDirectionOptions = (
  Object.keys(
    EntityAttributeCalculationTrendDirectionEnum
  ) as (keyof typeof EntityAttributeCalculationTrendDirectionEnum)[]
).map((key) => ({
  text: key,
  value: EntityAttributeCalculationTrendDirectionEnum[`${key}`] as string,
}));

// Deprecated
const entityAttributeTypeToGridColumnTypeMap: Record<EntityAttributeTypeEnum, SCGridSchemaTypeEnum> = {
  [EntityAttributeTypeEnum.User]: SCGridSchemaTypeEnum.MultipleUserAvatar,
  [EntityAttributeTypeEnum.OptionList]: SCGridSchemaTypeEnum.TagsReadonly,
  [EntityAttributeTypeEnum.Date]: SCGridSchemaTypeEnum.Date,
  [EntityAttributeTypeEnum.Number]: SCGridSchemaTypeEnum.Number,
  [EntityAttributeTypeEnum.Toggle]: SCGridSchemaTypeEnum.Boolean,
  [EntityAttributeTypeEnum.Text]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Sequence]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Calculation]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Owner]: SCGridSchemaTypeEnum.SingleUserAvatar,
  [EntityAttributeTypeEnum.State]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Email]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Url]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Context]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Document]: SCGridSchemaTypeEnum.Document,
  [EntityAttributeTypeEnum.Hierarchy]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Name]: SCGridSchemaTypeEnum.Text,
};

/**
 * Deprecated
 * Public method to parse type {EntityAttributeTypeEnum} to {SCGridSchemaTypeEnum}
 * @param {(EntityAttributeTypeEnum | Maybe<EntityAttributeTypeApiInterface> | null)} type - the attribute type
 * @return {*}  {SCGridSchemaTypeUnion}
 */
export const parseTypeCol = (
  type?: EntityAttributeTypeEnum | Maybe<EntityAttributeTypeApiInterface> | null
): SCGridSchemaTypeUnion => {
  if (!type || !entityAttributeTypeToGridColumnTypeMap[type]) return SCGridSchemaTypeEnum.Text;
  return entityAttributeTypeToGridColumnTypeMap[type];
};

/**
 * Type guard to check if it is a calculation attribute update interface.
 * @param {unknown} value - the object.
 * @return {boolean} Whether the the data is of type EntityCalculationAttributeUpdatedInterface.
 */
export const isCalculationAttributeUpdate = (value: unknown): value is EntityCalculationAttributeUpdatedInterface => {
  const entity = value as EntityCalculationAttributeUpdatedInterface;
  return entity.attributeId !== undefined && 'trendDirection' in entity;
};
