import { createAction, props } from '@ngrx/store';
import { BreadcrumbInterface, BreadcrumbItemInterface } from '../../models/breadcrumb/breadcrumb.model';

export const BreadcrumbActions = {
  // Add a new breadcrumb
  addBreadcrumb: createAction(
    '[Page State Breadcrumb] Add Breadcrumb',
    props<{ breadcrumb: BreadcrumbItemInterface }>()
  ),

  // Remove all breadcrumbs from starting index
  removeBreadcrumbs: createAction('[Page State Breadcrumb] Remove Breadcrumbs',
    props<{ index: number }>()),

  // Remove the last breadcrumb in the list
  removeBreadcrumb: createAction('[Page State Breadcrumb] Remove Breadcrumb'),

  // Reset all breadcrumbs
  resetBreadcrumbs: createAction('[Page State Breadcrumb] Reset Breadcrumbs'),

  // Set the entire breadcrumb list (useful for initialization or complete replacement)
  setBreadcrumbs: createAction(
    '[Page State Breadcrumb] Set Breadcrumbs',
    props<{ breadcrumbs: BreadcrumbInterface }>()
  ),
};

/**
 * An object grouping page state actions
 * @export
 */
export const PageStateActions = {
  setDashboardEditMode: createAction('[Page State] Set Dashboard Edit Mode', props<{ dashboardEditMode: boolean }>()),
};
