import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { QuestionComponent } from '../../question/question.component';
import { RendererQuestionControlConfigInterface } from '../component-renderer-config.interface';

/**
 * Dynamically creates a NO TYPE component
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererQuestionControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<QuestionComponent>} component - Type of component to be dynamically created
 * @return {ComponentRef<QuestionComponent> | undefined} - Dynamic Component
 */
export const questionComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererQuestionControlConfigInterface | undefined,
  component: Type<QuestionComponent>
): ComponentRef<QuestionComponent> | undefined => {
  if (!componentData) return undefined;
  const componentRef = viewContainerRef.createComponent(component);
  componentRef.instance.label = componentData.label;
  componentRef.instance.tag = componentData.tag;
  return componentRef;
};

