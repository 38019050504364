import { DatePickerComponent } from '../../date-picker/date-picker.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for DATE renderer component
 *
 */
export const DATE_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: DatePickerComponent,
  creatorFn: formControlComponentCreator,
};
