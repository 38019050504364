import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityPermissionActions } from '../../../actions/permission-entity.actions';
import { EntityPermissionInterface } from '../../../models/permission/permission-entity/permission-entity.models';

export const ENTITY_PERMISSION_FEATURE_KEY = 'entityPermission';

export interface StateInterface extends EntityState<EntityPermissionInterface> {
  loaded: boolean; // has the Entity Permission List been loaded
  error?: string | null; // last known error (if any)
}

export interface EntityPermissionPartialStateInterface {
  readonly [ENTITY_PERMISSION_FEATURE_KEY]: StateInterface;
}

export const entityPermissionAdapter: EntityAdapter<EntityPermissionInterface> =
  createEntityAdapter<EntityPermissionInterface>({
    selectId: (entityPermission: EntityPermissionInterface) => entityPermission.entityPermissionId,
  });

export const initialState: StateInterface = entityPermissionAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Entity Permission reducer.
 */
const entityPermissionReducer = createReducer(
  initialState,
  on(EntityPermissionActions.readEntityPermissionList, (state: StateInterface) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(EntityPermissionActions.readEntityPermissionListSuccess, (state: StateInterface, { permissions }) =>
    entityPermissionAdapter.setAll(permissions, { ...state, loaded: true })
  ),
  on(EntityPermissionActions.readEntityPermissionFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
  on(EntityPermissionActions.updateEntityPermissionSuccess, (state: StateInterface, { entityPermission }) =>
    entityPermissionAdapter.updateOne({ id: entityPermission.entityPermissionId, changes: entityPermission }, state)
  ),
  on(EntityPermissionActions.deleteEntityPermissionSuccess, (state: StateInterface, { entityPermissionId }) =>
    entityPermissionAdapter.removeOne(entityPermissionId, state)
  ),
  on(EntityPermissionActions.createEntityPermissionSuccess, (state: StateInterface, { entityPermission }) =>
    entityPermissionAdapter.addOne(entityPermission, state)
  )
);

/**
 * Perform reducer logic on the Entity Permission NGRX state store for a specific Entity Permission action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return entityPermissionReducer(state, action);
}
