import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonSize, ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  K_BUTTON_PREFIX,
  ScButtonBorder,
  ScButtonFillMode,
  ScButtonThemeColour,
  buttonFillModeToClassName,
  buttonSizeToClassName,
} from './button.constants';

/**
 * Button Component that attaches kendoButton styles.
 *
 * @example
 * <a [sc-text-button] [themeColour]="primary" [size]="medium" [fillMode]="fill" [routerLink]="['/']">Link Button Example</a>
 * @example
 * <button [sc-text-button] [themeColour]="danger" [size]="large" [fillMode]="outline" (click)="onClickMe()">Button example</button>
 * @export
 * @class TextButtonComponent
 */
@Component({
  selector: '[sc-text-button]',
  standalone: true,
  imports: [ButtonsModule],
  template: `<ng-content></ng-content>`,
  styleUrls: ['./text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TextButtonComponent implements OnInit, OnChanges {
  /**
   * Set the theme colour for the text button.
   *
   * @type {ScButtonThemeColour}
   * @memberof TextButtonComponent
   */
  @Input() themeColour: ScButtonThemeColour = 'brand';

  /**
   * Sets the preset size of the text button.
   *
   * @type {ButtonSize}
   * @memberof TextButtonComponent
   */
  @Input() size: ButtonSize = 'medium';

  /**
   * Sets the preset size of the text button.
   *
   * @type {ButtonSize}
   * @memberof TextButtonComponent
   */
  @Input() fillMode: ScButtonFillMode = 'fill';

  /**
   * Sets the preset border of the text button.
   *
   * @type {ScButtonBorder}
   * @memberof TextButtonComponent
   */
  @Input() border: ScButtonBorder = 'default';

  /**
   * Creates an instance of TextButtonComponent.
   *
   * @param {Renderer2} renderer An abstraction provided by Angular in the form of a service that allows to manipulate elements of your app without having to touch the DOM directly.
   * @param {ElementRef} elementRef A wrapper around a native element inside of the View
   * @memberof TextButtonComponent
   */
  constructor(private renderer: Renderer2, public elementRef: ElementRef) {}

  /**
   * Concat a prefix with a css class
   *
   * @private
   * @static
   * @param {string} prefix prefix of the class
   * @param {string} value value of the class
   * @return {*}  {string}
   * @memberof TextButtonComponent
   */
  private static addClassPrefix(prefix: string, value: string): string {
    return `${prefix}-${value}`;
  }

  /**
   * Angular lifecycle hook that initiates the component
   *
   * @memberof TextButtonComponent
   */
  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, K_BUTTON_PREFIX);
    this.updateClass(undefined, K_BUTTON_PREFIX, undefined, buttonSizeToClassName.get(this.size));
    this.updateClass(undefined, buttonFillModeToClassName.get(this.fillMode), undefined, this.themeColour);
    this.addButtonType();
  }

  /**
   * Update host element on changes.
   *
   * @param {SimpleChanges} changes A hashtable of changes represented by SimpleChange objects
   * @memberof TextButtonComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['themeColour'] && this.themeColour) {
      this.updateClass(
        undefined,
        buttonFillModeToClassName.get(this.fillMode),
        changes['themeColour'].previousValue,
        changes['themeColour'].currentValue
      );
    }

    if (changes['size'] && this.size) {
      this.updateClass(
        undefined,
        K_BUTTON_PREFIX,
        buttonSizeToClassName.get(changes['size'].previousValue),
        buttonSizeToClassName.get(changes['size'].currentValue)
      );
    }

    if (changes['fillMode'] && this.fillMode) {
      this.updateClass(
        buttonFillModeToClassName.get(changes['fillMode'].previousValue),
        buttonFillModeToClassName.get(changes['fillMode'].currentValue),
        this.themeColour,
        this.themeColour
      );
    }
    if (changes['border'] && this.border) {
      this.updateClass(undefined, K_BUTTON_PREFIX, changes['border'].previousValue, changes['border'].currentValue);
    }
  }

  /**
   * Updates a link component class
   *
   * @private
   * @param {string} oldPrefix prefix to be removed
   * @param {string} newPrefix prefix to be added
   * @param {(string | undefined)} oldValue class old value
   * @param {(string | undefined)} newValue class new value
   * @memberof TextButtonComponent
   */
  private updateClass(
    oldPrefix: string | undefined,
    newPrefix: string | undefined,
    oldValue: string | undefined,
    newValue: string | undefined
  ): void {
    if (oldPrefix && newPrefix && oldValue && newValue) {
      this.renderer.addClass(this.elementRef.nativeElement, TextButtonComponent.addClassPrefix(newPrefix, newValue));
      this.renderer.removeClass(this.elementRef.nativeElement, TextButtonComponent.addClassPrefix(oldPrefix, oldValue));
    } else if (newPrefix && oldValue && newValue) {
      this.renderer.addClass(this.elementRef.nativeElement, TextButtonComponent.addClassPrefix(newPrefix, newValue));
      this.renderer.removeClass(this.elementRef.nativeElement, TextButtonComponent.addClassPrefix(newPrefix, oldValue));
    } else if (newValue && newPrefix) {
      this.renderer.addClass(this.elementRef.nativeElement, TextButtonComponent.addClassPrefix(newPrefix, newValue));
    }
  }

  /**
   * Add a button type if none is present
   * @private
   * @memberof TextButtonComponent
   */
  private addButtonType(): void {
    const buttonType = this.elementRef.nativeElement.getAttribute('type');
    if (!buttonType) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'type', 'button');
    }
  }
}
