import {
  MultipleRecordAttributeCellInterface,
  RecordAttributeUnion,
  SingleRecordAttributeCellInterface,
} from '@surecloud/common';

export const isRecordDataSingleCellInterface = (map: RecordAttributeUnion): map is SingleRecordAttributeCellInterface =>
  'singleAttribute' in map;

export const isRecordDataMultipleCellInterface = (
  map: RecordAttributeUnion
): map is MultipleRecordAttributeCellInterface => 'multiAttributes' in map;
