import { FormGroup } from '@angular/forms';
import { SelectDropdownComponentOptionInterface } from '@surecloud/common';

export enum ValidationRendererTypeEnum {
  Number = 'NUMBER',
  NumberAndDropdown = 'NUMBER_DROPDOWN',
  Dropdown = 'DROPDOWN',
  Date = 'DATE',
}

/**
 * Interface for the Validation Renderer view Form
 * @property {string} controlId - The component control ID.
 * @property {FormGroup} group - The component control group.
 * @export
 * @interface RendererViewFormInterface
 */
export interface ValidationRendererViewFormInterface {
  controlId: string;
  group: FormGroup;
}

/**
 * Base Interface for the validation Renderer config
 * @property {ValidationRendererTypeEnum} type - The component type.
 * @export
 * @interface ValidationRendererConfigBaseInterface
 */
export interface ValidationRendererConfigBaseInterface {
  type: ValidationRendererTypeEnum;
}

/**
 * Validation Renderer Form Control Config Interface
 * @property {string|null} label - The component label
 * @property {string|null} guidanceText - The component guidanceText
 * @property {string|null} placeholder - The component placeholder
 * @export
 * @interface ValidationRendererFormControlConfigInterface
 * @extends {ValidationRendererConfigBaseInterface}
 */
export interface ValidationRendererFormControlConfigInterface extends ValidationRendererConfigBaseInterface {
  label: string | null;
  guidanceText: string | null;
  placeholder: string | null;
}

/**
 * Renderer Number and Dropdown Control Config Interface
 * @property {string|null} inputLabel - The component input label
 * @property {string|null} dropdownLabel - The component dropdown label
 * @property {string|null} inputDescription - The component input description
 * @property {string|null} dropdownDescription - The component dropdown description
 * @property {string|null} placeholder - The component placeholder
 * @property {string} inputControlName - The component input control name
 * @property {string} dropdownControlName - The component dropdown control name
 * @property {SelectDropdownComponentOptionInterface[]} options - The component dropdown options
 * @export
 * @interface RendererNumberAndDropdownControlConfigInterface
 * @extends {ValidationRendererConfigBaseInterfacee}
 */
export interface ValidationRendererNumberAndDropdownControlConfigInterface
  extends ValidationRendererConfigBaseInterface {
  inputLabel: string | null;
  dropdownLabel: string | null;
  inputDescription: string | null;
  dropdownDescription: string | null;
  inputControlName: string;
  dropdownControlName: string;
  options: SelectDropdownComponentOptionInterface[];
  secondDropdownLabel: string | null;
  secondDropdownDescription: string | null;
  secondDropdownOptions: SelectDropdownComponentOptionInterface[];
  secondDropdownControlName: string;
}

/**
 * Dropdown Validation Renderer Form Control Config Interface
 * @property {string|null} label - The component label
 * @property {string|null} description - The component description
 * @property {string} controlName - The component controlName
 * @property {SelectDropdownComponentOptionInterface[]} options - The component dropdown options
 * @export
 * @interface ValidationRendererDropdownControlConfigInterface
 * @extends {ValidationRendererConfigBaseInterface}
 */
export interface ValidationRendererDropdownControlConfigInterface extends ValidationRendererConfigBaseInterface {
  label: string | null;
  description: string | null;
  controlName: string;
  options: SelectDropdownComponentOptionInterface[];
}

/**
 * Date Validation Renderer Form Control Config Interface
 * @property {string|null} label - The component label
 * @property {string|null} description - The component description
 * @property {string} controlName - The component controlName
 * @export
 * @interface ValidationRendererDateControlConfigInterface
 * @extends {ValidationRendererConfigBaseInterface}
 */
export interface ValidationRendererDateControlConfigInterface extends ValidationRendererConfigBaseInterface {
  label: string | null;
  controlName: string;
  guidanceText: string | null;
}

export type ValidationRendererControlConfigUnion =
  | ValidationRendererFormControlConfigInterface
  | ValidationRendererNumberAndDropdownControlConfigInterface
  | ValidationRendererDropdownControlConfigInterface
  | ValidationRendererDateControlConfigInterface;
