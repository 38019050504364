import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService } from '@surecloud/common';
import { catchError, from, map, mergeMap, of, switchMap, tap, toArray } from 'rxjs';
import { NormalisedRoleList } from '../../services/normalise-get-roles/normalise-get-roles.validations';
import { RoleService } from '../../services/role/role.service';
import { RoleStateActions } from '../actions/role.actions';

/**
 * The Effects/side effects for Role.
 * @export
 * @class RoleEffects
 */
@Injectable({ providedIn: 'root' })
export class RoleEffects {
  /**
   * When reading a role list.
   * Then load all role data from the API.
   * @memberof RoleEffects
   */
  loadRoleListData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.readRoleList),
      switchMap(() =>
        this.roleService.readAll().pipe(
          map((normalisedRoleList) =>
            RoleStateActions.readRoleListDataSuccess({
              normalisedRoleList,
            })
          ),
          catchError((error: unknown) => of(RoleStateActions.readRoleListFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the role list has received a response.
   * Then load the result into state.
   * @memberof RoleEffects
   */
  loadRoleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.readRoleListDataSuccess),
      map(({ normalisedRoleList }) =>
        RoleStateActions.readRoleListSuccess({
          roles: normalisedRoleList.roles,
        })
      )
    )
  );

  /**
   * When reading an role.
   * Then load the role data from the API.
   * @memberof RoleEffects
   */
  loadRoleData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.readRole),
      switchMap(({ roleId }) =>
        this.roleService.read(roleId).pipe(
          map((normalisedRoleList) =>
            RoleStateActions.readRoleDataSuccess({
              normalisedRoleList,
            })
          ),
          catchError((error: unknown) => of(RoleStateActions.readRoleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the role has received a response.
   * Then load the result into state.
   * @memberof RoleEffects
   */
  loadRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.readRoleDataSuccess),
      map(({ normalisedRoleList }) =>
        RoleStateActions.readRoleSuccess({
          role: normalisedRoleList.roles[0],
        })
      )
    )
  );

  /**
   * When a role needs to be created on the API.
   * Then call the create method on the role service.
   * @memberof RoleEffects
   */
  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.createRole),
      switchMap(({ requestId }) =>
        this.roleService.create(requestId).pipe(
          map((role) => RoleStateActions.createRoleSuccess({ role })),
          catchError((error: unknown) => of(RoleStateActions.createRoleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a Role is successfully created on the API.
   * Then navigate to the Role page.
   * @memberof RoleEffects
   */
  navigateToRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.createRoleSuccess),
      map(({ role }) => RoleStateActions.navigateToRole({ roleId: role.roleId }))
    )
  );

  /**
   * When a role needs to be updated on the API.
   * Then call the update method on the role service.
   * @memberof RoleEffects
   */
  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.updateRole),
      switchMap(({ role }) =>
        this.roleService.update(role).pipe(
          map((updatedRole) => RoleStateActions.updateRoleSuccess({ role: updatedRole })),
          catchError((error: unknown) => of(RoleStateActions.updateRoleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a role needs to be deleted on the API.
   * Then call the delete method on the role service.
   * @memberof RoleEffects
   */
  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.deleteRole),
      switchMap(({ role }) =>
        this.roleService.delete(role.roleId).pipe(
          map(({ roleId }) => RoleStateActions.deleteRoleSuccess({ roleId })),
          catchError((error: unknown) => of(RoleStateActions.deleteRoleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When an array of role IDs needs to be loaded.
   * Then load those roles from the API.
   * @memberof RoleEffects
   */
  loadBatchRolesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.readRoles),
      switchMap(({ roleIds }) =>
        from(roleIds).pipe(
          mergeMap((roleId) => this.roleService.read(roleId)),
          toArray(),

          map((normalisedRoleLists: NormalisedRoleList[]): NormalisedRoleList => {
            const initialNormalisedRoleList: NormalisedRoleList = { roles: [] };

            return normalisedRoleLists.reduce(
              (normalisedRoleList, currentNormalisedRoleList) => ({
                roles: [...normalisedRoleList.roles, ...currentNormalisedRoleList.roles],
              }),
              initialNormalisedRoleList
            );
          }),
          map((normalisedRoleList: NormalisedRoleList) =>
            RoleStateActions.readRolesDataSuccess({ normalisedRoleList })
          ),
          catchError((error: unknown) => of(RoleStateActions.readRolesFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When roles batch data has loaded successfully.
   * Then populate the role state.
   * @memberof RoleEffects
   */
  loadBatchRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleStateActions.readRolesDataSuccess),
      map(({ normalisedRoleList }) => RoleStateActions.readRolesSuccess({ roles: normalisedRoleList.roles }))
    )
  );

  /**
   * When a Create Report API call failed.
   * Then log the error.
   * @memberof RoleEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RoleStateActions.readRoleListFailure,
          RoleStateActions.readRoleFailure,
          RoleStateActions.updateRoleFailure,
          RoleStateActions.deleteRoleFailure,
          RoleStateActions.createRoleFailure,
          RoleStateActions.readRolesFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Role', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of RoleEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {RoleService} roleService The Role API service.
   * @param {LoggerService} logger The common logger service.
   * @memberof RoleEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly roleService: RoleService,
    private readonly logger: LoggerService
  ) {}
}
