import { ValueGetterFunc, ValueSetterFunc } from 'ag-grid-community';

/**
 * SCGridSchemaTypeEnum
 */
export enum SCGridSchemaTypeEnum {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  SingleSelect = 'singleSelect',
  Select = 'rowSelect',
  HeaderSelect = 'headerRowSelect',
  Navigate = 'navigate',
  Checkbox = 'checkbox',
  AttributeType = 'attributeType',
  TagsEditable = 'tagsEditable',
  TagsReadonly = 'tagsReadonly',
  Icon = 'icon',
  MultipleUserAvatar = 'userAvatar',
  RelativeDate = 'relativeDate',
  RowDrag = 'rowDrag',
  CheckboxEditable = 'checkboxEditable',
  SingleUserAvatar = 'singleUserAvatar',
  Document = 'document',
  Sequence = 'sequence',
}

/**
 * SCGridSchemaTypeUnion
 */
export type SCGridSchemaTypeUnion = `${SCGridSchemaTypeEnum}`;

/**
 * SCGridSchemaInterface
 * @property {SCGridSchemaTypeUnion} type - the grid column type
 * @property {string | undefined} label - the grid column label
 */
export interface SCGridSchemaInterface {
  [x: string]: SCGridSchemaInterface[keyof SCGridSchemaInterface];
  type: SCGridSchemaTypeUnion;
  label?: string;
  editable?: boolean;
  cellClass?: string;
  valueGetter?: ValueGetterFunc;
  valueSetter?: ValueSetterFunc;
}

/**
 * SCGridSchemaMapInterface
 */
export type SCGridSchemaMapInterface = Record<string, SCGridSchemaInterface>;
export type SCGridSchemaValuesUnion = SCGridSchemaInterface[keyof SCGridSchemaInterface];

export const SELECT_ROW_COLUMN = 'selectRow';
export const NAVIGATE_ROW_COLUMN = 'navigateRow';
export const DRAG_ROW_COLUMN = 'dragRow';
export const KEYDOWN_SPACE = 'Space';
export const TAGS_COLUMN = 'Tags';

/**
 * SCGridCheckboxEditableType
 * @property {boolean} boolean - The value of the checkbox
 * @property {boolean} disabled - Disabled state of the checkbox
 */
export type SCGridCheckboxEditableType = {
  value: boolean;
  disabled: boolean;
};

/**
 * SCGridSchema Class
 * @export
 * @class SCGridSchema
 */
export class SCGridSchema {
  keys: string[] = [];
  schemaMap: SCGridSchemaMapInterface;

  /**
   * Creates an instance of SCGridSchema.
   * @param {SCGridSchemaMapInterface} entityStructure - provided structure.
   * @memberof SCGridSchema
   */
  constructor(entityStructure: SCGridSchemaMapInterface) {
    this.keys = Object.keys(entityStructure);
    this.schemaMap = entityStructure;
  }
}
