import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { StandaloneRouteNamesEnum } from '@surecloud/common';
import { Observable, map } from 'rxjs';
import { AuthService } from '../../services/auth.service';

/**
 * Implements a route guard to prevent authenticated users entering the auth area
 * of the application
 * @export
 * @class CanActivateAuthGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class CanActivateAuthGuard {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Implements canActivate to allow route activation if user is unauthenticated,
   * otherwise will send user to main area of the application.
   * @return {*}  {(Observable<boolean | UrlTree>)}
   * @memberof CanActivateAuthGuard
   */
  canActivate(): Observable<boolean | UrlTree> {
    return this.authService
      .isAuthenticated()
      .pipe(
        map((isAuthenticated) => (isAuthenticated ? this.router.parseUrl(`/${StandaloneRouteNamesEnum.Home}`) : true))
      );
  }
}
