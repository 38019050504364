<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<nav
  class="design-v2-global-nav w-full sc-bg-white flex align-items-center justify-content-between sc-py-8 sc-pr-24 sc-pl-24"
  [ngClass]="{ 'design-v2-global-nav--standalone': isStandalone }">
  <a
    i18n
    [routerLink]="homeLink.route"
    [attr.aria-label]="homeLink.label"
    class="design-v2-global-nav__home-link flex align-items-center justify-content-center">
    <img class="design-v2-global-nav__logo" src="/assets/logos/surecloud-logo.svg" [attr.alt]="homeLink.label" />
  </a>
  <div class="flex align-items-center flex-grow-1">
    <ul class="flex align-items-center justify-content-between">
      <li i18n *ngFor="let link of globalNavLinks; trackBy: trackByFunction">
        <a
          *ngIf="link.route"
          [routerLink]="link.route"
          routerLinkActive="toolbars__global-nav-main-link--active"
          class="toolbars__global-nav-main-link design-v2-font-heading-md sc-text-lighter sc-px-12 design-v2-transition-colours"
          [attr.data-menu]="link.label">
          {{ link.label }}
        </a>
        <!--
          NOTE - Currently only "Designer" has a submenu. Change needed to accommodate further submenus.
         -->
        <ng-container *ngIf="!link.route">
          <button
            #menuAnchor
            class="toolbars__global-nav-main-link design-v2-font-heading-md sc-text-lighter sc-px-12"
            [ngClass]="{
              'toolbars__global-nav-main-link--active': activeRouteLabel === link.label
            }"
            (keydown.enter)="onToggle()"
            (click)="onToggle()"
            [attr.data-menu]="link.label">
            {{ link.label }}
          </button>
          <!--
            Designer Submenu
           -->
          <kendo-popup
            #menu
            *ngIf="show"
            popupClass="toolbars__global-nav sc-border-radius-4"
            [anchor]="menuAnchor"
            [animate]="false"
            [margin]="{ horizontal: 0, vertical: 8 }"
            (anchorViewportLeave)="show = false">
            <ul class="list-none">
              <li *ngFor="let subLink of link.links; trackBy: trackByFunction">
                <a
                  [routerLink]="subLink.route"
                  routerLinkActive="toolbars__global-nav-link--active"
                  class="toolbars__global-nav-link sc-text block sc-p-12 sc-text-lighter design-v2-transition-colours">
                  {{ subLink.label }}
                </a>
              </li>
            </ul>
          </kendo-popup>
        </ng-container>
      </li>
    </ul>
  </div>
  <!--
    RIGHT-HAND-SIDE MENU
  -->
  <div class="flex align-items-center">
    <sc-icon-button
      class="sc-px-4"
      size="x-large"
      themeColour="global-nav--design-v2"
      icon="question-circle"
      (click)="helpClicked.emit($event)">
    </sc-icon-button>
    <sc-icon-button
      *ngIf="canViewTasks"
      [ngClass]="{ 'design-v2-global-nav__icon-notification': numberOfNewTasks && numberOfNewTasks > 0 }"
      [attr.data-value]="numberOfNewTasks && numberOfNewTasks > 9 ? '9+' : numberOfNewTasks"
      class="sc-px-4"
      size="x-large"
      themeColour="global-nav--design-v2"
      icon="inbox"
      (click)="taskClicked.emit($event)">
    </sc-icon-button>
    <sc-icon-button
      *ngIf="canViewUserMenu"
      class="sc-pl-4"
      size="x-large"
      themeColour="global-nav--design-v2"
      icon="user-circle"
      (click)="userClicked.emit($event)">
    </sc-icon-button>
  </div>
</nav>
