import { MutationOptions } from '@apollo/client';
import {
  EntityAttributeFormatCreateInputApiInterface,
  EntityAttributeFormatDeleteInputApiInterface,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';
import { EntityAttributeFormatUpdateInputApiInterface } from '../../services/format/format.service.input.interface';

/**
 * Create entity attribute format GraphQL mutation
 */
const ENTITY_ATTRIBUTE_FORMAT_CREATE_MUTATION = gql`
  mutation entityAttributeFormatCreateMutation($input: EntityAttributeFormatCreateInput!) {
    entityAttributeFormatCreate(input: $input) {
      createdId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for an creating an entity's attribute format.
 * @param {EntityAttributeFormatCreateInputApiInterface} input Details about the format to create.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityAttributeFormatCreateMutation = (
  input: EntityAttributeFormatCreateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_FORMAT_CREATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Update entity attribute format GraphQL mutation
 */
const ENTITY_ATTRIBUTE_FORMAT_UPDATE_MUTATION = gql`
  mutation entityAttributeFormatUpdateMutation($input: EntityAttributeFormatUpdateInput!) {
    entityAttributeFormatUpdate(input: $input) {
      format {
        formatId
      }
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for an updating an entity's attribute format.
 * @param {EntityAttributeFormatUpdateInputApiInterface} input Details about the format to update.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityAttributeFormatUpdateMutation = (
  input: EntityAttributeFormatUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_FORMAT_UPDATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Delete entity attribute format GraphQL mutation
 */
const ENTITY_ATTRIBUTE_FORMAT_DELETE_MUTATION = gql`
  mutation entityAttributeFormatDeleteMutation($input: EntityAttributeFormatDeleteInput!) {
    entityAttributeFormatDelete(input: $input) {
      deletedId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for deleting and entity's attribute format.
 * @param {EntityAttributeFormatDeleteInputApiInterface} input Details about the format to delete.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityAttributeFormatDeleteMutation = (
  input: EntityAttributeFormatDeleteInputApiInterface
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_FORMAT_DELETE_MUTATION,
  variables: { input },
});
