import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { UserProfileActions } from '../actions/user-profile.actions';
import { UserProfileInterface } from '../models/user-profile.models';

export const USER_PROFILE_FEATURE_KEY = 'userProfile';

export interface StateInterface extends EntityState<UserProfileInterface> {
  selectedId?: string;
  loaded: boolean;
  error?: string | null;
  userProfile: UserProfileInterface | undefined;
}

export interface UserProfilePartialStateInterface {
  readonly [USER_PROFILE_FEATURE_KEY]: StateInterface;
}

export const userProfileAdapter: EntityAdapter<UserProfileInterface> = createEntityAdapter<UserProfileInterface>();

export const initialState: StateInterface = userProfileAdapter.getInitialState({
  loaded: false,
  userProfile: undefined,
});

/**
 * User reducer.
 */
const userProfileReducer = createReducer(
  initialState,

  on(UserProfileActions.readUserProfile, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(UserProfileActions.readUserProfileSuccess, (state: StateInterface, { userProfile }) => ({
    ...state,
    loaded: true,
    userProfile,
  })),
  on(UserProfileActions.readUserProfileFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  }))
);

/**
 * Perform reducer logic on the User NGRX state store for a specific User Profile action.
 *
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX User action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return userProfileReducer(state, action);
}
