import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ALLOWED_EXTENSIONS } from '../../file-picker/file-picker-extensions.constants';
import {
  RendererAllComponentUnion,
  RendererFormControlComponentUnion,
} from '../component-renderer-component.interface';
import { RendererFormControlConfigInterface } from '../component-renderer-config.interface';
import { isRendererDocumentFormControlComponentRef } from '../component-renderer.guards';
import { formControlComponentCreator } from './form-control-creator.config';

/**
 * Extends the formControlComponentCreator to add additional properties related to the
 * file-picker component.
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererFormControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<RendererAllComponentUnion>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<RendererFormControlComponentUnion> | undefined} - Dynamic Component
 */
export const documentFormControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererFormControlConfigInterface | undefined,
  component: Type<RendererAllComponentUnion>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = true
): ComponentRef<RendererFormControlComponentUnion> | undefined => {
  const formComponentRef = formControlComponentCreator(viewContainerRef, componentData, component, control, readonly);
  if (formComponentRef && isRendererDocumentFormControlComponentRef(formComponentRef)) {
    formComponentRef.instance.allowedExtensions = ALLOWED_EXTENSIONS;
  }

  if (!control) return formComponentRef;

  if (readonly && control.group.controls[control.controlId]) {
    control.group.controls[control.controlId].disable({ emitEvent: false });
  }

  return formComponentRef;
};
