import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

/**
 * Injection token so that we can optionally provide the title root of our applications if it changes from the `SureCloud` default.
 *
 * @example <title>[PAGE_TITLE_PREFIX] - Rest of the page title</title>
 * @example
 * providers: [{
 *    provide: PAGE_TITLE_PREFIX,
 *    userValue: 'A Different Application Name`,
 * }]
 */
export const PAGE_TITLE_PREFIX = new InjectionToken<string>('Title Root', {
  providedIn: 'root',
  factory: (): string => 'SureCloud',
});

/**
 * Page Title service to update the title tag on an application with a workspace wide stratergy.
 *
 * @export
 * @class PageTitleStrategy
 * @extends {TitleStrategy}
 */
@Injectable({
  providedIn: 'root',
})
export class PageTitleStrategy extends TitleStrategy {
  /**
   * Creates an instance of PageTitleStrategy.
   *
   * @param {Title} title The Angular Title class.
   * @param {string} prefix The page title prefix injection token for the first part of the page title.
   * @memberof PageTitleStrategy
   */
  constructor(private readonly title: Title, @Inject(PAGE_TITLE_PREFIX) private readonly prefix: string) {
    super();
  }

  /**
   * Override the Angular page title setter with our own implementation.
   * This is so we can ensure a common pattern is used for all application page titles.
   *
   * @memberof PageTitleStrategy
   * @param {RouterStateSnapshot} routerState Snapshot of the current router state.
   */

  override updateTitle(routerState: RouterStateSnapshot): void {
    const suffix = this.buildTitle(routerState);
    const title = suffix ? `${this.prefix} - ${suffix}` : this.prefix;

    this.title.setTitle(title);
  }
}
