import { Injectable } from '@angular/core';
import {
  EntityAttributeInterface,
  EntityAttributeNameInterface,
  EntityAttributeUserInterface,
  EntityAttributeUserModeTypeEnum,
  isAttributeName,
  isAttributeUser,
  TemporaryFeatureFlagEnum,
  TemporaryFeatureFlagService,
  UNTITLED,
} from '@surecloud/common';
import { filter, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityStateFacade } from '../../facades/entity-state.facade';

/**
 * Utils Class for The Attribute Properties.
 * @export
 * @class AttributePropertiesUtils
 */
@Injectable({
  providedIn: 'root',
})
export class AttributePropertiesUtils {
  /**
   * Get a the name of an attribute.
   * @param {EntityAttributeInterface} attribute The attribute to get the name for.
   * @return {string} The name of the attribute. Untitled if undefined.
   * @memberof AttributePropertiesUtils
   */
  static getAttributeName(attribute: EntityAttributeInterface): string {
    return attribute.name ? attribute.name : UNTITLED;
  }

  /**
   * Creates an instance of AttributePropertiesUtils.
   * @param {EntityStateFacade} entityFacade The entity state facade.
   * @param {TemporaryFeatureFlagService} tempFeatureFlagService The temporary feature flag service.
   * @memberof AttributePropertiesUtils
   */
  constructor(
    private readonly entityFacade: EntityStateFacade,
    private readonly tempFeatureFlagService: TemporaryFeatureFlagService
  ) {}

  /**
   * Get latest selected attribute
   * @return {Observable<EntityAttributeInterface>} Stream of the Entity Attribute.
   * @memberof AttributePropertiesUtils
   */
  getSelectedAttribute(): Observable<EntityAttributeInterface> {
    return this.entityFacade.selectedAttribute$.pipe(
      filter((attribute): attribute is EntityAttributeInterface => !!attribute)
    );
  }

  /**
   * Listens to a form field data change, gets the selected attribute and dispatches an update event
   * @param {(Observable<string | boolean | null | string[]>)} valueChanges - the from field data changes listener
   * @param {string} property - the name of the property to update
   * @param {Subject<void>} notifier$ - the notifier observable
   * @memberof AttributePropertiesUtils
   */
  getSelectedAttributeAndValueChanges(
    valueChanges: Observable<string | boolean | null | string[]>,
    property: string,
    notifier$: Subject<void>
  ): void {
    valueChanges
      .pipe(withLatestFrom(this.getSelectedAttribute()), takeUntil(notifier$))
      .subscribe(([propertyValue, selectedAttribute]) => {
        let updatedAttribute = { ...selectedAttribute, [property]: propertyValue };
        if (isAttributeName(updatedAttribute) && property === 'calculated' && !propertyValue) {
          (updatedAttribute as EntityAttributeNameInterface).calculation = null;
        }
        if (
          isAttributeUser(updatedAttribute) &&
          property === 'userMode' &&
          this.tempFeatureFlagService.isFlagEnabled(TemporaryFeatureFlagEnum.Ng4155)
        ) {
          updatedAttribute = this.updateAttributeOnUserModeChange(propertyValue, updatedAttribute);
        }
        this.entityFacade.dispatch(EntityAttributeActions.updateAttributeFromPage({ attribute: updatedAttribute }));
      });
  }

  /**
   * Updates the Attribute based on the propertyValue
   * @param {string | boolean | null | string[]} propertyValue - the value of the property
   * @param {EntityAttributeUserInterface} updatedAttribute - the updated Attribute
   * @memberof AttributePropertiesUtils
   * @return {EntityAttributeUserInterface} - updated attribute
   */
  // eslint-disable-next-line class-methods-use-this
  private updateAttributeOnUserModeChange(
    propertyValue: string | boolean | null | string[],
    updatedAttribute: EntityAttributeUserInterface
  ): EntityAttributeUserInterface {
    if (
      propertyValue !== EntityAttributeUserModeTypeEnum.Groups &&
      (updatedAttribute.hierarchy === undefined || updatedAttribute.hierarchy === null)
    ) {
      return { ...updatedAttribute, hierarchy: true };
    }
    if (propertyValue === EntityAttributeUserModeTypeEnum.Groups) {
      return { ...updatedAttribute, hierarchy: null, roleIds: null };
    }
    return updatedAttribute;
  }
}
