import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAttributeFormatOptionInterface } from '../../../services/format-options/format-options.service.interface';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';
import { EntityAttributeFormatActions } from '../../actions/format.actions';
import { EntityAttributeFormatInterface } from '../../models/format/format.models';

export const FORMAT_FEATURE_KEY = 'format';

export interface StateInterface extends EntityState<EntityAttributeFormatInterface> {
  selectedId?: string; // which attribute rule record has been selected
  loaded: boolean; // has the attribute rule list been loaded
  error?: string | null; // last known error (if any)
  options: EntityAttributeFormatOptionInterface[]; // Array of format options
}

export interface FormatPartialStateInterface {
  readonly [FORMAT_FEATURE_KEY]: StateInterface;
}

export const formatAdapter: EntityAdapter<EntityAttributeFormatInterface> =
  createEntityAdapter<EntityAttributeFormatInterface>({
    selectId: (format: EntityAttributeFormatInterface) => format.formatId,
  });

export const initialState: StateInterface = formatAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  options: [],
});

const { selectAll } = formatAdapter.getSelectors();

/**
 * Get an array of format IDs that belong to attribute ID.
 *
 * @param {string} attributeId The attribute ID to look for formats on.
 * @param {EntityAttributeFormatInterface[]} formats List of formats
 * @return {string[]} Array of format IDs that belong to the attribute.
 */
const getFormatIdsForAttribute = (attributeId: string, formats: EntityAttributeFormatInterface[]): string[] =>
  formats.reduce<string[]>((formatIds, format) => {
    if (format && format.attributeId === attributeId) {
      formatIds.push(format.formatId);
    }
    return formatIds;
  }, []);

/**
 * Get an array of format IDs that belong to entity ID.
 *
 * @param {string} entityId The entity ID to look for formats on.
 * @param {EntityAttributeFormatInterface[]} formats List of formats
 * @return {string[]} Array of format IDs that belong to the entity.
 */
const getFormatIdsForEntity = (entityId: string, formats: EntityAttributeFormatInterface[]): string[] =>
  formats.reduce<string[]>((formatIds, format) => {
    if (format && format.entityId === entityId) {
      formatIds.push(format.formatId);
    }
    return formatIds;
  }, []);

const formatReducer = createReducer(
  initialState,
  on(EntityAttributeFormatActions.readFormatListSuccess, (state: StateInterface, { formatList }) =>
    formatAdapter.setAll(formatList, { ...state, loaded: true })
  ),
  on(EntityAttributeFormatActions.readFormatOptionsSuccess, (state: StateInterface, { formatOptions }) => ({
    ...state,
    options: formatOptions,
  })),
  on(EntityAttributeFormatActions.createFormatSuccess, (state: StateInterface, { format }) =>
    formatAdapter.setOne(format, state)
  ),
  on(EntityAttributeFormatActions.updateFormatSuccess, (state: StateInterface, { format }) =>
    formatAdapter.setOne(format, state)
  ),
  on(EntityAttributeFormatActions.deleteFormatSuccess, (state: StateInterface, { formatId }) =>
    formatAdapter.removeOne(formatId, state)
  ),

  // Delete all formats for an attribute when the attributeType is changed, since the formats no longer apply to the new type.
  // And delete all formats for an attribute when the attribute is delete.
  on(
    EntityAttributeActions.completeSelectAttributeType,
    EntityAttributeActions.deleteAttributeSuccess,
    (state: StateInterface, action) => {
      const id =
        action.type === EntityAttributeActions.completeSelectAttributeType.type
          ? action.attribute.attributeId
          : action.attributeId;

      const formatIds = getFormatIdsForAttribute(id, selectAll(state));
      return formatAdapter.removeMany(formatIds, state);
    }
  ),

  // Delete all formats for an entity when the entity is deleted.
  on(EntityActions.deleteEntitySuccess, (state: StateInterface, { entityId }) => {
    const formatIds = getFormatIdsForEntity(entityId, selectAll(state));
    return formatAdapter.removeMany(formatIds, state);
  })
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific attribute action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return formatReducer(state, action);
}
