<sc-icon-button
  [buttonType]="buttonType"
  [disabled]="disabled"
  [size]="size"
  (click)="deleteClicked.emit()"
  scCommonE2eHook="{{ testhook }}:delete"
  themeColour="red"
  icon="trash"
  shape="rounded">
</sc-icon-button>
