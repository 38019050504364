import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonActions, LoggerService } from '@surecloud/common';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { EntityAttributeRuleOptionsService } from '../../../services/rule-options/rule-options.service';
import { EntityAttributeRuleService } from '../../../services/rule/rule.service';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';
import { EntityAttributeRuleActions } from '../../actions/rule.actions';

/**
 * Entity attribute rule effects and side effects.
 * @export
 * @class EntityStateAttributeRuleEffects
 */
@Injectable({ providedIn: 'root' })
export class EntityStateAttributeRuleEffects {
  /**
   * When all entity data is loaded successfully,
   * Then populate the rules state.
   * @memberof EntityStateAttributeRuleEffects
   */
  loadEntityAttributeRuleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readAllEntityDataSuccess, EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        EntityAttributeRuleActions.readRuleListSuccess({
          ruleList: normalisedEntityList.rules,
        })
      )
    )
  );

  /**
   * When a user selects an attribute type.
   * Then load the rule options for that attribute type.
   * @memberof EntityStateAttributeRuleEffects
   */
  loadEntityAttributeRuleOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.selectAttributeType),
      switchMap(({ attributeType }) => this.ruleOptionsService.getOptions(attributeType)),
      map((ruleOptions) => EntityAttributeRuleActions.readRuleOptionsSuccess({ ruleOptions }))
    )
  );

  selectEntityAttributeRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeRuleActions.startUpdateRule),
      map(({ rule }) => EntityAttributeRuleActions.selectRule({ ruleId: rule.ruleId }))
    )
  );

  /**
   * When a user clicks add rule.
   * Then create the entity attribute rule on the API.
   * @memberof EntityStateAttributeRuleEffects
   */
  createEntityAttributeRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeRuleActions.createRule),
      switchMap(({ rule }) =>
        this.ruleService.create(rule).pipe(
          map((createdRule) => EntityAttributeRuleActions.createRuleSuccess({ rule: createdRule })),
          catchError((error: unknown) => of(EntityAttributeRuleActions.createRuleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user confirms the update on a rule.
   * Then update the entity attribute rule on the API.
   * @memberof EntityStateAttributeRuleEffects
   */
  updateEntityAttributeRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeRuleActions.completeUpdateRule),
      switchMap(({ rule }) =>
        this.ruleService.update(rule).pipe(
          map((updatedRule) => EntityAttributeRuleActions.updateRuleSuccess({ rule: updatedRule })),
          catchError((error: unknown) => of(EntityAttributeRuleActions.updateRuleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user confirms the deletion of a rule.
   * Then delete the entity attribute rule on the API.
   * @memberof EntityStateAttributeRuleEffects
   */
  deleteEntityAttributeRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeRuleActions.completeDeleteRule),
      switchMap(({ rule }) =>
        this.ruleService.delete(rule).pipe(
          map(({ ruleId }) => EntityAttributeRuleActions.deleteRuleSuccess({ ruleId })),
          catchError((error: unknown) => of(EntityAttributeRuleActions.deleteRuleFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user has confirmed or cancels the deletion of a rule.
   * Then close the confirm delete entity attribute rule modal.
   * @memberof EntityStateAttributeRuleEffects
   */
  leaveDeleteEntityAttributeRule$ = createEffect(() =>
    this.actions$.pipe(ofType(EntityAttributeRuleActions.completeDeleteRule), map(CommonActions.closeConfirmationModal))
  );

  /**
   * Logs any errors.
   * @memberof EntityStateAttributeRuleEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EntityAttributeRuleActions.deleteRuleFailure,
          EntityAttributeRuleActions.createRuleFailure,
          EntityAttributeRuleActions.updateRuleFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Entity Attribute Rule', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of EntityStateAttributeRuleEffects.
   * @param {Actions} actions$ The store actions.
   * @param {LoggerService} logger The application logging service.
   * @param {EntityAttributeRuleService} ruleService The entity attribute rule service.
   * @param {EntityAttributeRuleOptionsService} ruleOptionsService The entity attribute rule options service.
   * @memberof EntityStateAttributeRuleEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly logger: LoggerService,
    private readonly ruleService: EntityAttributeRuleService,
    private readonly ruleOptionsService: EntityAttributeRuleOptionsService
  ) {}
}
