<div class="sc-slider flex flex-row flex-wrap w-full">
  <kendo-slider
    [ngClass]="classname"
    scCommonE2eHook="{{ testhook }}:form:slider"
    [min]="min"
    [max]="max"
    [smallStep]="smallStep"
    [showButtons]="false"
    [tickPlacement]="'after'"
    [largeStep]="largeStep"
    [formControl]="control"
    [disabled]="control.disabled"
    aria-label="Select score"
    i18n-aria-label
    [attr.aria-valuenow]="control.disabled ? 'Disabled' : formControl.value"
    [attr.aria-valuemin]="min"
    [attr.aria-valuemax]="max">
  </kendo-slider>
</div>
