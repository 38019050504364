import { Validators } from '@angular/forms';
import { COMMON_MAX_CHAR_10000, COMMON_MAX_CHAR_25, COMMON_MAX_CHAR_50 } from './validation.constants';

/**
 * Angular form validator preset for Max Character Length of 25 chars.
 * Usage: short text input
 */
export const COMMON_VALIDATOR_MAX_CHAR_25 = Validators.maxLength(COMMON_MAX_CHAR_25);

/**
 * Angular form validator preset for Max Character Length of 50 chars.
 * Usage: longer text inputs / descriptions.
 */
export const COMMON_VALIDATOR_MAX_CHAR_50 = Validators.maxLength(COMMON_MAX_CHAR_50);

/**
 * Angular form validator preset for Max Character Length of 10,000 chars.
 * Usage: Text Editors.
 */
export const COMMON_VALIDATOR_MAX_CHAR_10000 = Validators.maxLength(COMMON_MAX_CHAR_10000);

