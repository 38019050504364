<ng-container [formGroup]="parentFormGroup">
  <kendo-switch
    [formControlName]="controlName"
    [onLabel]="onLabel"
    [offLabel]="offLabel"
    [size]="size"
    scCommonE2eHook="{{ testhook }}:form:toggle"
    [readonly]="readonly">
  </kendo-switch>
</ng-container>
