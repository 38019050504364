import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { DrawerModule } from '@progress/kendo-angular-layout';
import { E2eHookDirective, ScIconType } from '@surecloud/common';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { IconButtonComponent } from '../button/icon-button.component';
import { TextButtonComponent } from '../button/text-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { BackButtonConfigInterface, CLOSE_SIDEBAR_ICON, OPEN_SIDEBAR_ICON } from './sidebar-layout.interface';

/**
 * Wrapper component around the [Kendo UI Drawer](https://www.telerik.com/kendo-angular-ui/components/layout/drawer/).
 * @export
 * @class SidebarLayoutComponent
 */
@Component({
  selector: 'sc-sidebar-layout',
  standalone: true,
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    E2eHookDirective,
    DrawerModule,
    IconButtonComponent,
    TextButtonComponent,
    CommonIconModule,
    RouterModule,
    NgScrollbarModule,
  ],
})
export class SidebarLayoutComponent implements OnChanges {
  /**
   * Set's if the sidebar is expanded
   * @memberof SidebarLayoutComponent
   */
  @Input() expanded = false;

  /**
   * Set's if the sidebar button properties
   * @memberof SidebarLayoutComponent
   */
  @Input() backButtonConfig!: BackButtonConfigInterface | null;

  /**
   * Is the sidebar expandable?
   * There are use cases where a user is prohibited from opening the side panel.
   * @memberof SidebarLayoutComponent
   */
  @Input() hasSidebarPermission = true;

  /**
   * Emits the expanded state of the sidebar
   * @memberof SidebarLayoutComponent
   */
  @Output() isExpanded = new EventEmitter<boolean>();

  /**
   * NgScrollbar main content region.
   * @type {(NgScrollbar | undefined)}
   * @memberof SidebarLayoutComponent
   */
  @ViewChild('mainScrollRegion') mainScrollRegion: NgScrollbar | undefined;

  /**
   * The value of the current Icon of the sidebar button
   * @type {ScIconType}
   * @memberof SidebarLayoutComponent
   */
  icon: ScIconType = OPEN_SIDEBAR_ICON;

  /**
   * Update icon on changes.
   * @memberof SidebarLayoutComponent
   */
  ngOnChanges(): void {
    this.icon = this.expanded ? CLOSE_SIDEBAR_ICON : OPEN_SIDEBAR_ICON;
  }

  /**
   * Changes the sidebar properties if expanded state changes
   * @memberof SidebarLayoutComponent
   */
  onExpandChange(): void {
    if (this.hasSidebarPermission) {
      this.expanded = !this.expanded;
      this.icon = this.icon === CLOSE_SIDEBAR_ICON ? OPEN_SIDEBAR_ICON : CLOSE_SIDEBAR_ICON;
      this.isExpanded.emit(this.expanded);
    }
  }

  /**
   * Scroll to the top of the main scroll region.
   * @memberof SidebarLayoutComponent
   */
  scrollToMainScrollRegionTop(): void {
    if (this.mainScrollRegion) {
      this.mainScrollRegion.scrollTo({ top: 0 });
    }
  }
}
