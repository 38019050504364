import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from '@surecloud/common';
import { map, switchMap, tap } from 'rxjs';
import { RecordImportModalService } from '../../services/record-import-modal/record-import-modal.service';
import { RecordImportService } from '../../services/record-import/record-import.service';
import { RecordImportActions } from '../actions/record-import.actions';

/**
 * The record import effects.
 * @export
 * @class RecordImportEffects
 */
@Injectable({ providedIn: 'root' })
export class RecordImportEffects {
  /**
   * When a user opens the record import modal.
   * @memberof RecordImportEffects
   */
  openRecordImportModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordImportActions.openRecordImportModal),
        tap(({ modalDetails }) => this.recordImportModalService.openImportModal(modalDetails))
      ),
    { dispatch: false }
  );

  /**
   * When a user cancels the record import modal.
   * @memberof RecordImportEffects
   */
  cancelRecordImportModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordImportActions.cancelRecordImportModal),
        tap(() => {
          if (this.recordImportModalService.recordImportModalViewReference)
            this.recordImportModalService.recordImportModalViewReference.close();
        })
      ),
    { dispatch: false }
  );

  /**
   * When a user starts to import records.
   * @memberof RecordImportEffects
   */
  startImportRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordImportActions.startImportRecords),
      map(({ file }) => {
        if (this.recordImportModalService.modalDetails?.entityId) {
          return RecordImportActions.importRecords({ file });
        }
        return RecordImportActions.importLinkedRecords({ file });
      })
    )
  );

  /**
   * When a user imports records.
   * @memberof RecordImportEffects
   */
  importRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordImportActions.importRecords),
      switchMap(({ file }) =>
        this.recordImportService
          .importRecords({
            ...this.recordImportModalService.modalDetails,
            entityId: this.recordImportModalService.modalDetails?.entityId || '',
            file,
          })
          .pipe(
            map((success) =>
              success
                ? RecordImportActions.importRecordsSuccess()
                : RecordImportActions.importRecordsFailure({ error: 'Failed to import records' })
            )
          )
      )
    )
  );

  /**
   * When a user imports linked records.
   * @memberof RecordImportEffects
   */
  importLinkedRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordImportActions.importLinkedRecords),
      switchMap(({ file }) =>
        this.recordImportService
          .importLinkedRecords({
            linkedEntityId: this.recordImportModalService.modalDetails?.linkedEntityId || '',
            linkedRecordId: this.recordImportModalService.modalDetails?.linkedRecordId || '',
            file,
          })
          .pipe(
            map((success) =>
              success
                ? RecordImportActions.importRecordsSuccess()
                : RecordImportActions.importRecordsFailure({ error: 'Failed to import records' })
            )
          )
      )
    )
  );

  /**
   * When records fail to import.
   * @memberof RecordImportEffects
   */
  importRecordsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordImportActions.importRecordsSuccess),
        tap(() => {
          if (this.recordImportModalService.recordImportModalViewReference) {
            this.recordImportModalService.recordImportModalViewReference.close();
            this.recordImportModalService.openSuccessModal();
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * When records fail to import.
   * @memberof RecordImportEffects
   */
  importRecordsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordImportActions.importRecordsFailure),
        tap(({ error }) => {
          if (this.recordImportModalService.recordImportModalViewReference) {
            this.recordImportModalService.recordImportModalViewReference.close();
            this.toast.handleMessage(error);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of RecordImportEffects.
   * @param {Actions} actions$ The ngrx actions
   * @param {RecordImportModalService} recordImportModalService The record import modal service
   * @param {RecordImportService} recordImportService The record import service
   * @param {ToastService} toast The toast service
   * @memberof RecordImportEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly recordImportModalService: RecordImportModalService,
    private readonly recordImportService: RecordImportService,
    private readonly toast: ToastService
  ) {}
}
