import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { UserProfileEffects } from './+state/effects/user-profile.effects';
import { USER_PROFILE_FEATURE_KEY, reducer } from './+state/reducers/user-profile.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(USER_PROFILE_FEATURE_KEY, reducer)],
  providers: [EffectsBootstrapProvider.provide([UserProfileEffects])],
})
export class UserProfileModule {}
