<div class="flex flex-column h-full w-full p-0 overflow-hidden bg-white">
  <kendo-drawer-container class="flex flex-auto h-full">
    <kendo-drawer
      #drawer
      (expandedChange)="onExpandChange()"
      mode="push"
      class="sc-hide--mobile"
      [mini]="true"
      [width]="350"
      [expanded]="expanded"
      [ngClass]="{
        'sidebar--closed': !expanded,
        'sidebar--restricted': !hasSidebarPermission
      }">
      <ng-template kendoDrawerTemplate>
        <div class="flex flex-column overflow-hidden h-full">
          <div
            class="sidebar__header flex justify-content-between"
            [ngClass]="{ 'sidebar__header--spacer': backButtonConfig }">
            <div>
              <a
                *ngIf="backButtonConfig"
                [ngClass]="{ 'sidebar--hidden': !expanded }"
                sc-text-button
                themeColour="brand"
                size="small"
                fillMode="outline"
                [routerLink]="backButtonConfig.routeCommands"
                class="sidebar-header__back-button">
                <sc-icon key="back" colour="brand-500" [height]="12" [width]="12"></sc-icon>
                {{ backButtonConfig.label }}
              </a>
            </div>
            <div
              class="flex justify-content-en sidebar-header-container__notch-wrapper"
              [ngClass]="{
                'sidebar-header__container-expanded': expanded,
                'sidebar-header__container-closed': !expanded
              }">
              <sc-icon-button
                (click)="drawer.toggle()"
                themeColour="outline"
                [icon]="icon"
                class="sidebar-header-container__notch"
                size="small"
                shape="rounded"
                *ngIf="hasSidebarPermission">
              </sc-icon-button>
            </div>
          </div>
          <ng-scrollbar class="flex-grow-1" [ngClass]="{ 'sidebar--hidden': !expanded }" *ngIf="hasSidebarPermission">
            <div class="sc-pl-24 sc-pr-24" [ngClass]="backButtonConfig ? 'sc-pt-10' : 'sc-pt-16'">
              <ng-content select="[sidebar-content]"></ng-content>
            </div>
          </ng-scrollbar>
          <div [ngClass]="{ 'sidebar--hidden': !expanded }">
            <ng-content select="[sidebar-footer]"></ng-content>
          </div>
        </div>
      </ng-template>
    </kendo-drawer>

    <kendo-drawer-content class="h-full w-full sidebar__main-content sc-pt-32 sc-pb-32 sc-bg-main-bg sc-p-20--mobile">
      <ng-scrollbar class="flex w-full" [track]="'all'" #mainScrollRegion>
        <div class="sc-pl-32 sc-pr-32">
          <ng-content select="[main-content]"></ng-content>
        </div>
      </ng-scrollbar>
    </kendo-drawer-content>
  </kendo-drawer-container>
</div>
