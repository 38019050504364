<form [formGroup]="quickFilterForm">
  <sc-input-text
    [readonly]="readonly"
    [staticWidth]="width"
    prefix="search"
    controlName="filterText"
    placeholder="Search table"
    i18n-placeholder>
  </sc-input-text>
</form>
