import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { LinkedEntityTagActions } from '../../actions/linked-entity-tag.actions';
import { LinkedEntityActions } from '../../actions/linked-entity.actions';
import { LinkedEntityTagInterface } from '../../models/linked-entity-tag/linked-entity-tag.model';

export const LINKED_ENTITY_TAG_FEATURE_KEY = 'linkedEntityTag';

export interface StateInterface extends EntityState<LinkedEntityTagInterface> {
  loaded: boolean; // has the Linked Entity list been loaded
  error?: string | null; // last known error (if any)
}

export interface LinkedEntityTagPartialStateInterface {
  readonly [LINKED_ENTITY_TAG_FEATURE_KEY]: StateInterface;
}

export const linkedEntityTagAdapter: EntityAdapter<LinkedEntityTagInterface> =
  createEntityAdapter<LinkedEntityTagInterface>({
    selectId: (linkedEntityTag: LinkedEntityTagInterface) => linkedEntityTag.tagId,
  });

export const initialState: StateInterface = linkedEntityTagAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Linked Entity Tag reducer.
 */
const linkedEntityTagReducer = createReducer(
  initialState,
  on(LinkedEntityTagActions.readLinkedEntityTagList, (state: StateInterface) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(LinkedEntityTagActions.readLinkedEntityTagSuccess, (state: StateInterface, { tags }) =>
    linkedEntityTagAdapter.setAll(tags, { ...state, loaded: true })
  ),
  on(LinkedEntityTagActions.readLinkedEntityTagFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
  on(LinkedEntityTagActions.addLinkedEntityTagSuccess, (state: StateInterface, tag) =>
    linkedEntityTagAdapter.addOne(tag, state)
  ),
  on(LinkedEntityTagActions.deleteLinkedEntityTagSuccess, (state: StateInterface, { deletedId }) =>
    linkedEntityTagAdapter.removeOne(deletedId, state)
  ),
  on(LinkedEntityActions.deleteLinkedEntitiesSuccess, (state: StateInterface, { deletedIds }) => {
    const idsToDelete = Object.keys(state.entities).filter((tagId) => {
      const id = state.entities[tagId]?.linkedEntityId;
      return id ? deletedIds.includes(id) : false;
    });
    return linkedEntityTagAdapter.removeMany(idsToDelete, state);
  })
);

/**
 * Perform reducer logic on the Linked Entity Tag NGRX state store for a specific Linked Entity Tag action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return linkedEntityTagReducer(state, action);
}
