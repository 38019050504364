import { FormGroup } from '@angular/forms';

import {
  EntityAttributeCalculationDirectionEnum,
  EntityAttributeCalculationTrendDirectionEnum,
  EntityAttributeTypeEnum,
} from '@surecloud/common';
import { RendererOptionListInterface } from './models/option-list.model';

export const RENDERER_CONFIG_ATTRIBUTE_TYPE = 'ATTRIBUTE';

export enum RendererTypeEnum {
  Table = 'TABLE',
  Information = 'INFORMATION',
  Question = 'QUESTION',
  Calculation = 'CALCULATION',
  NoType = 'NO_TYPE',
  Document = 'DOCUMENT',
  Dropdown = 'DROPDOWN',
  OptionList = 'OPTION_LIST',
  Url = 'URL',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
}
export type RendererTypeUnion = `${EntityAttributeTypeEnum}` | `${RendererTypeEnum}`;

/**
 * Interface for the Renderer view Form
 * @property {string} controlId - The component control ID.
 * @property {FormGroup} group - The component control group.
 * @export
 * @interface RendererViewFormInterface
 */
export interface RendererViewFormInterface {
  controlId: string;
  group: FormGroup;
}

/**
 * Base Interface for the Renderer config
 * @property {RendererTypeUnion} type - The component type.
 * @export
 * @interface RendererConfigBaseInterface
 */
export interface RendererConfigBaseInterface {
  type: RendererTypeUnion;
}

/**
 * Renderer Table Control Config Interface
 * @property {RendererTableTabControlConfigInterface[]} tabs - Table Tabs
 * @export
 * @interface RendererTableControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererTableControlConfigInterface extends RendererConfigBaseInterface {
  tabs: RendererTableTabControlConfigInterface[];
}

/**
 * Renderer Option List Control Config Interface
 * @property { RendererOptionListInterface | null} optionList - Option List
 * @property { boolean | null} multiple - Option List is a multiple select
 * @property {string | null} label - The label
 * @property {string | null} guidanceText - The guidance Text
 * @property {string | null} placeholder - The place holder
 * @export
 * @interface RendererTableControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererOptionListControlConfigInterface extends RendererConfigBaseInterface {
  optionList: RendererOptionListInterface | null;
  multiple: boolean | null;
  label: string | null;
  guidanceText: string | null;
  placeholder: string | null;
  attribute: RendererAttributeInterface | null;
}

/**
 * Renderer Table Tab Control Config Interface
 * @property {string} tabId - The tab ID
 * @property {string} label - The tab label
 * @property {RendererTableColumnControlConfigInterface[]} columns - The tab columns
 * @interface RendererTableTabControlConfigInterface
 */
interface RendererTableTabControlConfigInterface {
  tabId: string;
  name: string;
  columns: RendererTableColumnControlConfigInterface[];
}

/**
 * Renderer Table Column Control Config Interface
 * @property {string} columnId - The column ID
 * @property {string} attributeId - The attribute ID
 * @property {string} label - The column label
 * @interface RendererTableColumnControlConfigInterface
 */
interface RendererTableColumnControlConfigInterface {
  columnId: string;
  attributeId: string;
  label: string;
}

export interface RendererAttributeInterface {
  type: EntityAttributeTypeEnum | null;
  name: string | null;
  guidanceText: string | null;
  optionList: RendererOptionListInterface | null;
  large: boolean | null;
  trendDirection: EntityAttributeCalculationTrendDirectionEnum | null;
  trendAnalysis: boolean | null;
}

/**
 * Renderer Attribute Control Config Interface
 * @export
 * @interface RendererAttributeConfigInterface
 */
export interface RendererAttributeConfigInterface {
  type: typeof RENDERER_CONFIG_ATTRIBUTE_TYPE;
  attribute: RendererAttributeInterface;
}

/**
 * Renderer Form Control Config Interface
 * @property {string|null} label - The component label
 * @property {string|null} guidanceText - The component guidanceText
 * @property {string|null} placeholder - The component placeholder
 * @export
 * @interface RendererFormControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererFormControlConfigInterface extends RendererConfigBaseInterface {
  label: string | null;
  guidanceText: string | null;
  placeholder: string | null;
  attribute: RendererAttributeInterface | null;
}

/**
 * Renderer Attribute Calculation Control Config Interface
 * @export
 * @interface RendererCalculationConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererCalculationConfigInterface extends RendererConfigBaseInterface {
  label: string | null;
  guidanceText: string | null;
  direction: EntityAttributeCalculationDirectionEnum | null;
  attribute: RendererAttributeInterface | null;
}

/**
 * Renderer Attribute Text Control Config Interface
 * @export
 * @interface RendererTextControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererTextControlConfigInterface extends RendererConfigBaseInterface {
  label: string | null;
  guidanceText: string | null;
  placeholder: string | null;
  attribute: RendererAttributeInterface | null;
}

/**
 * Renderer No Type Control config
 * @export
 * @interface RendererNoTypeControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererNoTypeControlConfigInterface extends RendererConfigBaseInterface {
  label: string;
  placeholder: string;
}

/**
 * Renderer Question Control config
 * @export
 * @interface RendererQuestionControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererQuestionControlConfigInterface extends RendererConfigBaseInterface {
  label: string;
  tag: string;
}

/**
 * Renderer Information Control config
 * @export
 * @interface RendererInformationControlConfigInterface
 * @extends {RendererConfigBaseInterface}
 */
export interface RendererInformationControlConfigInterface extends RendererConfigBaseInterface {
  label: string;
}

export type RendererControlConfigUnion =
  | RendererFormControlConfigInterface
  | RendererOptionListControlConfigInterface
  | RendererTableControlConfigInterface
  | RendererInformationControlConfigInterface
  | RendererCalculationConfigInterface
  | RendererTextControlConfigInterface;

export const makeNoTypeComponentConfig = (): RendererNoTypeControlConfigInterface => ({
  type: RendererTypeEnum.NoType,
  label: 'No Type found.',
  placeholder: 'No Type component',
});
