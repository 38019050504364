import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UNTITLED } from '@surecloud/common';
import { SelectDropdownComponent } from '../../../select-dropdown/select-dropdown.component';
import { ValidationRendererDropdownControlConfigInterface } from '../validation-component-renderer-config.interface';
import { isValidationRendererDropdownControlComponentRef } from '../validation-component-renderer.guards';

/**
 * Dynamically creates a validation DropdownComponent with a form control
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {ValidationRendererDropdownControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<SelectDropdownComponent>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<SelectDropdownComponent> | undefined} - Dynamic Component
 */
export const validationDropdownControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: ValidationRendererDropdownControlConfigInterface | undefined,
  component: Type<SelectDropdownComponent>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = false
): ComponentRef<SelectDropdownComponent> | undefined => {
  if (!componentData) return undefined;

  const { controlName, description, label, options } = componentData;
  const componentRef = viewContainerRef.createComponent(component);

  if (!isValidationRendererDropdownControlComponentRef(componentRef)) return undefined;

  componentRef.instance.description = description ?? UNTITLED;
  componentRef.instance.label = label ?? UNTITLED;
  componentRef.instance.readonly = readonly;
  componentRef.instance.options = options;

  if (!control) return componentRef;

  componentRef.instance.controlName = controlName;

  componentRef.instance.parentFormGroup = control.group;

  return componentRef;
};
