import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskSummaryInterface, trackByIndex } from '@surecloud/common';
import { SidebarLayoutOverlayComponent } from '../sidebar-layout-overlay/sidebar-layout-overlay.component';
import { TagComponent } from '../tag/tag.component';
import { TaskSummaryComponent } from '../task-summary/task-summary.component';
/**
 * Surecloud Tasks List Component.
 * @export
 * @class TaskListComponent
 */
@Component({
  selector: 'sc-task-list',
  standalone: true,
  templateUrl: './task-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SidebarLayoutOverlayComponent, TagComponent, TaskSummaryComponent],
})
export class TaskListComponent {
  /**
   * Pointer to the @Input tasks.
   * @private
   * @type {TaskSummaryInterface[]}
   * @memberof TaskListComponent
   */
  private localTasks: TaskSummaryInterface[] = [];

  /**
   * The total amout of new tasks.
   * @memberof TaskListComponent
   */
  new = 0;

  /**
   * The total amout of tasks.
   * @memberof TaskListComponent
   */
  total = 0;

  /**
   * The track by function for ngFor loops in the view.
   * @memberof TaskListComponent
   */
  trackByFunction = trackByIndex;

  /**
   * The Tasks to show.
   * @type {TaskSummaryInterface[]}
   * @memberof TaskListComponent
   */
  @Input()
  get tasks(): TaskSummaryInterface[] {
    return this.localTasks;
  }
  set tasks(value: TaskSummaryInterface[] | undefined) {
    const tasks = value || [];

    this.total = tasks.length;
    this.new = tasks.filter((task) => task.new).length;
    this.localTasks = tasks;
  }

  /**
   * The heading of the Task List Component.
   * @memberof TaskListComponent
   */
  @Input()
  heading = $localize`My Tasks`;

  /**
   * When a user clicks on a task.
   * Then emit that task as selected.
   * @type {EventEmitter<TaskSummaryInterface>}
   * @memberof TaskListComponent
   */
  @Output()
  selected: EventEmitter<TaskSummaryInterface> = new EventEmitter();
}
