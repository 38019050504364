import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Store } from '@ngrx/store';
import { selectRouteData } from '@surecloud/common';
import { of, switchMap } from 'rxjs';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';
import { NavLinkInterface } from './side-nav-component.interface';

/**
 * Surecloud Side Nav Component.
 * @export
 * @class SideNavComponent
 */
@Component({
  selector: 'sc-side-nav',
  standalone: true,
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgIconsModule, RouterModule, CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavComponent {
  /**
   * The different navigation items.
   * @type {NavLinkInterface[]}
   * @memberof SideNavComponent
   */
  @Input()
  navLinks!: NavLinkInterface[];

  /**
   * Does a user have permission to view the Side Nav.
   * @memberof SideNavComponent
   */
  @Input()
  hasSideNavPermission = true;

  /**
   * Get the active side nav link data from the route.
   * @memberof SideNavComponent
   */
  public activeSideNavLink$ = this.store.select(selectRouteData).pipe(
    switchMap((route) => {
      if (route['activeSideNavLink']) {
        return of(route['activeSideNavLink']);
      }
      return of(null);
    })
  );

  trackByFunction = SideNavComponent.trackByFn;

  /**
   * Track by function for Angular template loops.
   * @static
   * @param {number} index The index value of the nav link in the array.
   * @return {string} String to track the nav link by.
   * @memberof SideNavComponent
   */
  static trackByFn(index: number): string {
    return `${index}`;
  }

  /**
   * Creates an instance of ToolbarsComponent.
   * @param {Store} store The NGRX store.
   * @memberof SideNavComponent
   */
  constructor(private readonly store: Store) {}
}
