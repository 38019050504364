import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { LinkedEntityActions } from '../../actions/linked-entity.actions';
import { LinkedEntityInterface } from '../../models/linked-entity/linked-entity.model';

export const LINKED_ENTITY_FEATURE_KEY = 'linkedEntity';

export interface StateInterface extends EntityState<LinkedEntityInterface> {
  loaded: boolean; // has the Linked Entity list been loaded
  error?: string | null; // last known error (if any)
}

export interface LinkedEntityPartialStateInterface {
  readonly [LINKED_ENTITY_FEATURE_KEY]: StateInterface;
}

export const linkedEntityAdapter: EntityAdapter<LinkedEntityInterface> = createEntityAdapter<LinkedEntityInterface>({
  selectId: (linkedEntity: LinkedEntityInterface) => linkedEntity.linkedEntityId,
});

export const initialState: StateInterface = linkedEntityAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

/**
 * Linked Entity reducer.
 */
const linkedEntityReducer = createReducer(
  initialState,
  on(LinkedEntityActions.resetLinkedEntity, () => ({ ...initialState })),
  on(LinkedEntityActions.readLinkedEntityList, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(LinkedEntityActions.readLinkedEntitySuccess, (state: StateInterface, { links }) =>
    linkedEntityAdapter.upsertMany(links, { ...state, loaded: true })
  ),
  on(LinkedEntityActions.createLinkedEntitiesSuccess, (state: StateInterface, linkedEntities) =>
    linkedEntityAdapter.addMany(linkedEntities.linkedEntities, state)
  ),
  on(LinkedEntityActions.deleteLinkedEntitiesSuccess, (state: StateInterface, linkedEntities) =>
    linkedEntityAdapter.removeMany(linkedEntities.deletedIds, state)
  ),
  on(LinkedEntityActions.readLinkedEntityFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
  on(LinkedEntityActions.updateLinkedEntities, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(LinkedEntityActions.updateLinkedEntitiesSuccess, (state: StateInterface, { linkedEntity }) =>
    linkedEntityAdapter.upsertOne(linkedEntity, state)
  )
);

/**
 * Perform reducer logic on the Linked Entity NGRX state store for a specific Linked Entity action.
 *
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return linkedEntityReducer(state, action);
}
