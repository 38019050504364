<div class="sc-side-nav h-full bg-white relative" *ngIf="hasSideNavPermission">
  <ul>
    <li *ngFor="let link of navLinks; trackBy: trackByFunction">
      <a
        class="sc-side-nav__link flex flex-grow-1 flex-column align-items-center sc-pt-8 sc-pb-8"
        [ngClass]="{ 'sc-side-nav__link--current': link.label === (activeSideNavLink$ | async) }"
        [routerLink]="link.routeCommands">
        <div class="sc-side-nav__icon-wrapper flex align-items-center justify-content-center sc-mb-8">
          <sc-icon
            class="sc-side-nav__icon"
            [key]="link.icon"
            size="large"
            colour="text"
            [height]="24"
            [width]="24"></sc-icon>
        </div>
        <span class="sc-side-nav__label sc-pl-4 sc-pr-4 text-center">{{ link.label }}</span>
      </a>
    </li>
  </ul>
</div>
