import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RequestErrorActions, RequestErrorInterface } from '@surecloud/common';

export const REQUEST_ERROR_FEATURE_KEY = 'requestError';

export type StateInterface = EntityState<RequestErrorInterface>;

export const requestErrorAdapter: EntityAdapter<RequestErrorInterface> = createEntityAdapter<RequestErrorInterface>({
  selectId: (error: RequestErrorInterface) => error.requestId,
});

export const initialState: StateInterface = requestErrorAdapter.getInitialState();
/**
 * Request error reducer
 */
const requestErrorReducer = createReducer(
  initialState,
  on(RequestErrorActions.errorOccurred, (state: StateInterface, { message, requestId, key }: RequestErrorInterface) =>
    requestErrorAdapter.addOne({ requestId, message, key }, state)
  )
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific attribute action.
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX attribute action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return requestErrorReducer(state, action);
}
