import { Injectable } from '@angular/core';
import {
  QuestionSetApiInterfaceQueryService,
  QuestionSetCreateInputApiInterface,
  QuestionSetCreateMutationApiInterfaceMutationService,
  QuestionSetDeleteInputApiInterface,
  QuestionSetDeleteMutationApiInterfaceMutationService,
  QuestionSetsApiInterfaceQueryService,
  QuestionSetUpdateMutationApiInterfaceMutationService,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { makeQuestionSet, QuestionSetInterface } from '../../+state/models/question-set/question-set.models';
import { NormaliseGetQuestionSetService } from '../normalise-get-question-sets/normalise-get-question-sets.service';
import { NormalisedQuestionSetList } from '../normalise-get-question-sets/normalise-get-question-sets.validation';

export const QUESTION_SET_CREATE_ERROR = 'No response data from create Question Set returned from API.';
export const QUESTION_SET_DELETE_ERROR = 'No response data from delete Question Set returned from API.';
export const QUESTION_SET_UPDATE_ERROR = 'No response data from update Question Set returned from API.';
export const QUESTION_SET_READ_ERROR = 'No response data from read Question Set returned from API.';
export const QUESTION_SET_READ_ALL_ERROR = 'No response data from read all Question Set returned from API.';

/**
 * Question Set API service.
 * @export
 * @class QuestionSetService
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionSetService {
  /**
   * Creates an instance of QuestionSetService
   * @param {NormaliseGetQuestionSetService} normaliseGetQuestionSetService The Question Set data normalising service.
   * @param {QuestionSetApiInterfaceQueryService } questionSetApiInterfaceQueryService The Question Set query service.
   * @param {QuestionSetsApiInterfaceQueryService} questionSetsApiInterfaceQueryService The Question Sets query service.
   * @param {QuestionSetCreateMutationApiInterfaceMutationService} questionSetCreateMutationApiInterfaceMutationService The Question Set create mutation service.
   * @param {QuestionSetDeleteMutationApiInterfaceMutationService} questionSetDeleteMutationApiInterfaceMutationService The Question Set delete mutation service.
   * @param {QuestionSetUpdateMutationApiInterfaceMutationService} questionSetUpdateMutationApiInterfaceMutationService The Question Set update mutation service.
   * @memberof QuestionSetService
   */
  constructor(
    private readonly normaliseGetQuestionSetService: NormaliseGetQuestionSetService,
    private readonly questionSetApiInterfaceQueryService: QuestionSetApiInterfaceQueryService,
    private readonly questionSetsApiInterfaceQueryService: QuestionSetsApiInterfaceQueryService,
    private readonly questionSetCreateMutationApiInterfaceMutationService: QuestionSetCreateMutationApiInterfaceMutationService,
    private readonly questionSetDeleteMutationApiInterfaceMutationService: QuestionSetDeleteMutationApiInterfaceMutationService,
    private readonly questionSetUpdateMutationApiInterfaceMutationService: QuestionSetUpdateMutationApiInterfaceMutationService
  ) {}

  /**
   * Create a new Question Set.
   * @param {string} entityId The Entity ID to create a Question Set for.
   * @param {string | null} requestId The requestId.
   * @return {Observable<QuestionSetInterface>} The created Question Set.
   * @memberof QuestionSetService
   */
  create(entityId: string, requestId: string | null = null): Observable<QuestionSetInterface> {
    const questionSetCreateInput: QuestionSetCreateInputApiInterface = { entityId };

    return this.questionSetCreateMutationApiInterfaceMutationService
      .mutate({ questionSetCreateInput }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.questionSetCreate?.createdId
            ? of(makeQuestionSet(data.questionSetCreate.createdId, entityId))
            : throwError(() => QUESTION_SET_CREATE_ERROR)
        )
      );
  }

  /**
   * Delete a Question Set.
   * @param {QuestionSetInterface} questionSet The Question Set to delete.
   * @return {Observable<{questionSetId: string}>} The deleted Question Set ID.
   * @memberof QuestionSetService
   */
  delete(questionSet: QuestionSetInterface): Observable<{ questionSetId: string }> {
    const { questionSetId } = questionSet;
    const questionSetDeleteInput: QuestionSetDeleteInputApiInterface = { questionSetId };

    return this.questionSetDeleteMutationApiInterfaceMutationService
      .mutate({ questionSetDeleteInput })
      .pipe(
        switchMap(({ data }) =>
          data?.questionSetDelete?.deletedId
            ? of({ questionSetId: data.questionSetDelete.deletedId })
            : throwError(() => QUESTION_SET_DELETE_ERROR)
        )
      );
  }

  /**
   * Query to get a Question Set by id.
   * @param {string} questionSetId The Question Set id.
   * @return {Observable<NormalisedQuestionSetList>} The Question Set normalised data.
   * @memberof QuestionSetService
   */
  read(questionSetId: string): Observable<NormalisedQuestionSetList> {
    return this.questionSetApiInterfaceQueryService
      .fetch({ questionSetId })
      .pipe(
        switchMap(({ data }) =>
          data?.questionSet
            ? of(this.normaliseGetQuestionSetService.extract([data.questionSet]))
            : throwError(() => QUESTION_SET_READ_ERROR)
        )
      );
  }

  /**
   * Query to get all Question Sets.
   * @param {string} entityId The Entity ID to read all the Question Sets for.
   * @return {Observable<NormalisedQuestionSetList>} The Question Set normalised data.
   * @memberof QuestionSetService
   */
  readAll(entityId: string): Observable<NormalisedQuestionSetList> {
    return this.questionSetsApiInterfaceQueryService
      .fetch({ entityId })
      .pipe(
        switchMap(({ data }) =>
          data?.questionSets
            ? of(this.normaliseGetQuestionSetService.extract(data.questionSets))
            : throwError(() => QUESTION_SET_READ_ALL_ERROR)
        )
      );
  }

  /**
   * Update a Question Set.
   * @param {QuestionSetInterface} questionSet The Question Set details
   * @param {string | null} requestId The requestId.
   * @return {Observable<QuestionSetInterface>} The updated Question Set.
   * @memberof QuestionSetService
   */
  update(questionSet: QuestionSetInterface, requestId: string | null = null): Observable<QuestionSetInterface> {
    const { entityId, ...questionSetUpdateInput } = questionSet;

    return this.questionSetUpdateMutationApiInterfaceMutationService
      .mutate({ questionSetUpdateInput }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.questionSetUpdate?.questionSet?.questionSetId
            ? of({ ...questionSet })
            : throwError(() => QUESTION_SET_UPDATE_ERROR)
        )
      );
  }
}
