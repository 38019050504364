<button
  [type]="buttonType"
  sc-text-button
  (click)="handleClick($event)"
  class="sc-loading-button"
  [class.w-full]="fullWidth"
  [disabled]="disabled"
  [size]="size"
  [fillMode]="fillMode"
  [themeColour]="themeColour"
  [ngClass]="['sc-loading-button--' + this.state]">
  <span class="sc-loading-button__text">
    <p class="sc-loading-button__content"><ng-content></ng-content></p>
    <p i18n *ngIf="state === 'loading'">Loading...</p>
    <p *ngIf="state === 'error'">{{ error }}</p>
  </span>
  <span class="sc-loading-button__background">
    <div class="sc-loading-button__spinner">
      <div class="sc-loading-button__color"></div>
      <div class="sc-loading-button__mask"></div>
    </div>
  </span>
</button>
