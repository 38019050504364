import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { UNSUPPORTED } from '@surecloud/common';
import { ColDef } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { AdvancedGridComponent } from '../../grid/advanced-grid/advanced-grid.component';
import { RendererTableControlConfigInterface } from '../component-renderer-config.interface';

/**
 * Set's a tab column
 *
 * @param {number} tabIndex - Tab index
 * @param {RendererTableControlConfigInterface} componentData - Data to setup
 * @return {ColDef[]} - AgGrid ColdDefs columns
 */
export const setTableColumns = (tabIndex: number, componentData: RendererTableControlConfigInterface): ColDef[] =>
  componentData.tabs[tabIndex]?.columns?.map((column) => ({ headerName: column.label })) || [];

/**
 * Dynamically creates a table component
 *
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {RendererTableControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<AdvancedGridComponent>} component - Type of component to be dynamically created
 * @return {ComponentRef<AdvancedGridComponent> | undefined} - Dynamic Component
 */
export const tableComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: RendererTableControlConfigInterface | undefined,
  component: Type<AdvancedGridComponent>
): ComponentRef<AdvancedGridComponent> | undefined => {
  if (!componentData) return undefined;
  const componentRef = viewContainerRef.createComponent(component);

  const destroyed$: Subject<void> = new Subject();

  componentRef.instance.tabs = componentData.tabs.map((tab, index) => ({
    id: index,
    name: tab.name || UNSUPPORTED,
  }));
  componentRef.instance.columnDefs = setTableColumns(0, componentData);
  componentRef.instance.readonly = true;

  componentRef.instance.activeTabChange
    .asObservable()
    .pipe(takeUntil(destroyed$))
    .subscribe((tabIndex) => {
      componentRef.instance.columnDefs = setTableColumns(tabIndex, componentData);
    });

  componentRef.onDestroy(() => {
    destroyed$.next();
    destroyed$.complete();
  });
  return componentRef;
};
