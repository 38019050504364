import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  LinkedEntityTagCreateInterface,
  LinkedEntityTagInterface,
  LinkedEntityTagRemoveInterface,
} from '../models/linked-entity-tag/linked-entity-tag.model';

/**
 * Linked Entity Tag API actions map.
 */
const LINKED_ENTITY_TAG_API_EVENTS_MAP = {
  'Read Linked Entity Tag List': emptyProps(),
  'Read Linked Entity Tag Success': props<{ tags: LinkedEntityTagInterface[] }>(),
  'Read Linked Entity Tag Failure': props<{ error: string }>(),
  'Add Linked Entity Tag': props<{ tag: LinkedEntityTagCreateInterface }>(),
  'Add Linked Entity Tag Success': props<LinkedEntityTagInterface>(),
  'Add Linked Entity Tag Failure': props<{ error: string }>(),
  'Delete Linked Entity Tag': props<{ tag: LinkedEntityTagRemoveInterface }>(),
  'Delete Linked Entity Tag Success': props<{ deletedId: string }>(),
  'Delete Linked Entity Tag Failure': props<{ error: string }>(),
};

/**
 * Linked Entity Tag UI actions map.
 */
const LINKED_ENTITY_TAG_UI_EVENTS_MAP = {
  'Complete Add Linked Entity Tag': props<{ label: string; linkedEntityId: string }>(),
  'Complete Delete Linked Entity Tag': props<{ tagId: string; linkedEntityId: string }>(),
};

/**
 * Linked Entity Tag API actions.
 */
export const LinkedEntityTagApiActions = createActionGroup({
  source: 'Entity/API',
  events: LINKED_ENTITY_TAG_API_EVENTS_MAP,
});

/**
 * Linked Entity Tag API actions.
 */
export const LinkedEntityTagUIActions = createActionGroup({
  source: 'Linked Entity Page',
  events: LINKED_ENTITY_TAG_UI_EVENTS_MAP,
});

/**
 * Linked Entity Tag API actions.
 */
export const LinkedEntityTagActions = {
  ...LinkedEntityTagApiActions,
  ...LinkedEntityTagUIActions,
};
