import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScIconType, TabInterface } from '@surecloud/common';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { CommonIconModule } from '../../../icon/icons/common-icon.module';
import { AdvancedGridComponent } from '../../advanced-grid/advanced-grid.component';

@Component({
  selector: 'sc-homepage-grid',
  templateUrl: './homepage-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./homepage-grid.component.scss'],
  standalone: true,
  imports: [CommonModule, CommonIconModule, AdvancedGridComponent],
})
export class HomepageGridComponent {
  /**
   * Icon key
   * @type {ScIconType | undefined}
   * @memberof HomepageGridComponent
   */
  @Input() key: ScIconType | undefined;

  /**
   * The grid data source
   * @type {unknown[]} the data set
   * @memberof HomepageGridComponent
   */
  @Input() rowData: unknown[] = [];

  /**
   * The grid column definitions
   * @type {ColDef[]} the column definitions
   * @memberof HomepageGridComponent
   */
  @Input() columnDefs: ColDef[] = [];

  /**
   * The grid tabs
   * @type {(TabInterface[])}
   * @memberof HomepageGridComponent
   */
  @Input() tabs: TabInterface[] = [];

  /**
   * The grid active tab.
   * @type {number}
   * @memberof HomepageGridComponent
   */
  @Input() activeTab: number | undefined;

  /**
   * The grid active tab.
   * @type {number}
   * @memberof HomepageGridComponent
   */
  @Input() height = 243;

  /**
   * Emits when a tab is clicked
   * @memberof HomepageGridComponent
   */
  @Output() activeTabChange = new EventEmitter<number>();

  /**
   * The empty list title of the section.
   * @type {(string | undefined)}
   * @memberof HomepageGridComponent
   */
  @Input()
  heading: string | undefined;

  /**
   * The empty list body of the section.
   * @type {(string | undefined)}
   * @memberof HomepageGridComponent
   */
  @Input()
  body: string | undefined;

  /**
   * Emits when the cell is clicked
   * @memberof HomepageGridComponent
   */
  @Output() clicked = new EventEmitter<CellClickedEvent>();
}
