import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { DragDropReorderInterface, DragDropReorderItemInterface } from '@surecloud/common';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { Subject, takeUntil } from 'rxjs';
import { TextButtonComponent } from '../button/text-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Surecloud List View Component that wraps the kendo List View
 *
 * @export
 * @class DragDropListComponent
 */
@Component({
  selector: 'sc-drag-drop-list',
  standalone: true,
  templateUrl: './drag-drop-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [RouterModule, CommonModule, TextButtonComponent, CommonIconModule, DragulaModule],
})
export class DragDropListComponent implements OnInit, OnDestroy {
  /**
   * The items we want to drag and drop.
   *
   * @type {DragDropReorderItemInterface[]}
   * @memberof DragDropListComponent
   */
  clonedItems: DragDropReorderItemInterface[] = [];

  /**
   * When the items input changes, clone them so them to protect against mutative changes.
   *
   * @memberof DragDropListComponent
   */
  @Input() set items(items: DragDropReorderItemInterface[]) {
    this.clonedItems = [...items];
  }

  /**
   * The ID attached to the dragula component.
   *
   * @memberof DragDropListComponent
   */
  @Input() id = 'drag-drop-list';

  /**
   * The dragula reference, used for sharing data between different drag and drops sections if the same ref is used.
   *
   * @memberof DragDropListComponent
   */
  @Input() dragulaRef = 'DRAG_DROP_LIST';

  /**
   * The data we want to emit when the user drops an item causing a reorder.
   *
   * @memberof DragDropListComponent
   */
  @Output()
  reordered = new EventEmitter<DragDropReorderInterface>();

  /**
   * When the component is destroyed.
   * Then emit a true value.
   * Then other observables can tear down.
   *
   * @private
   * @memberof DragDropListComponent
   */
  private readonly destroyed$ = new Subject<void>();

  /**
   * Creates an instance of DragDropListComponent.
   *
   * @param {DragulaService} dragula The Dragula drag and drop library service.
   * @memberof DragDropListComponent
   */
  constructor(private readonly dragula: DragulaService) {}

  /**
   * Expose the track by function to the view template.
   *
   * @memberof DragDropListComponent
   */
  trackByFunction = DragDropListComponent.trackByFn;

  /**
   * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
   * The function takes the iteration index and item ID, and in this case Angular will track changes by the item.id
   *
   * @param {number} _ Index number
   * @param {DragDropReorderItemInterface} item An item in an Iterable
   * @return {*}  {string}
   * @memberof DragDropListComponent
   */
  static trackByFn(_: number, item: DragDropReorderItemInterface): string {
    return item.id;
  }

  /**
   * When the dragula detects an item has been dropped causing a new order.
   * Trigger the output to emit the data about the new order.
   *
   * @private
   * @param {DragDropReorderInterface} dragDropReorder The data for the reorder.
   * @memberof DragDropListComponent
   */
  private reorder(dragDropReorder: DragDropReorderInterface): void {
    this.reordered.emit(dragDropReorder);
  }

  /**
   * When the component is initialised.
   * Dispatch the ViewSectionActions.enterViewBuilderSections
   *
   *
   * @memberof ViewBuilderSectionListComponent
   */
  ngOnInit(): void {
    this.dragula
      .dropModel<DragDropReorderItemInterface>(this.dragulaRef)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ item, targetIndex, targetModel }) => {
        this.dragula.find(this.dragulaRef).drake.cancel(true);
        this.reorder({ reorderedItem: item, newPosition: targetIndex, reorderedArray: targetModel });
      });
  }

  /**
   * When the component is destroyed.
   * Then emit this.destroyed$.
   * Then other observables can tear down.
   * And destroy the Dragula group.
   *
   * @memberof DragDropListComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.dragula.destroy(this.dragulaRef);
  }
}
