import { Injectable } from '@angular/core';
import { EntitySummaryApiInterface } from '@surecloud/api-types';
import {
  EntityAttributeInterface,
  LoggerService,
  NormalisationOutputStrategyInterface,
  Normalise,
} from '@surecloud/common';
import { NormaliseGetQuestionSetService, QUESTION_SETS_KEY } from '@surecloud/question-set-state';
import { schema } from 'normalizr';
import { EntityAttributeFormatInterface } from '../../+state/models/format/format.models';
import { LinkedEntityTagInterface } from '../../+state/models/linked-entity-tag/linked-entity-tag.model';
import { LinkedEntityInterface } from '../../+state/models/linked-entity/linked-entity.model';
import { AttributePermissionInterface } from '../../+state/models/permission/attribute-permission/attribute-permission.models';
import { EntityPermissionInterface } from '../../+state/models/permission/permission-entity/permission-entity.models';
import { EntityAttributeRuleInterface } from '../../+state/models/rule/rule.models';
import { EntityApiInterface } from '../entity/entity.service.interface';
import {
  NormalisedEntityList,
  NormalisedEntitySummaryList,
  getNormalisedEntityListError,
  getNormalisedEntitySummaryListError,
  isNormalisedEntityListGuard,
  isNormalisedEntitySummaryListGuard,
} from './normalise-get-entities.validation';

const ENTITIES_KEY = 'entities';
const ATTRIBUTES_KEY = 'attributes';
const ATTRIBUTE_RULE_KEY = 'rules';
const ATTRIBUTE_FORMAT_KEY = 'formats';
const LINKED_ENTITIES_KEY = 'links';
const LINKED_ENTITY_TAGS_KEY = 'tags';
const PERMISSIONS_KEY = 'permissions';
const PERMISSIONS_ATTRIBUTE_KEY = 'attributePermissions';

/**
 * Service encapsulating the schema and configuration for flattening the
 * getEntities API response.
 * @export
 * @class NormaliseGetEntitiesService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetEntitiesService {
  /**
   * Entity attribute validation rule schema.
   * @memberof NormaliseGetEntitiesService
   */
  attributeRuleSchema = new schema.Entity(ATTRIBUTE_RULE_KEY, undefined, {
    idAttribute: (value): string => value.ruleId,
    processStrategy: (value, parent): EntityAttributeRuleInterface => ({
      ...value,
      entityId: parent.entityId,
      attributeId: parent.attributeId,
    }),
  });

  /**
   * Entity attribute permissios validation rule schema.
   * @memberof NormaliseGetEntitiesService
   */
  attributePermissionSchema = new schema.Entity(PERMISSIONS_ATTRIBUTE_KEY, undefined, {
    idAttribute: (value): string => value.entityAttributePermissionId,
    processStrategy: (value, parent): AttributePermissionInterface => ({
      ...value,
      entityId: parent.entityId,
      attributeId: parent.attributeId,
    }),
  });

  /**
   * Entity attribute format schema.
   * @memberof NormaliseGetEntitiesService
   */
  attributeFormatSchema = new schema.Entity(ATTRIBUTE_FORMAT_KEY, undefined, {
    idAttribute: (value): string => value.formatId,
    processStrategy: (value, parent): EntityAttributeFormatInterface => ({
      ...value,
      entityId: parent.entityId,
      attributeId: parent.attributeId,
    }),
  });

  /**
   * Entity attribute schema.
   * @memberof NormaliseGetEntitiesService
   */
  attributeSchema = new schema.Entity(
    ATTRIBUTES_KEY,
    {
      [ATTRIBUTE_RULE_KEY]: [this.attributeRuleSchema],
      [ATTRIBUTE_FORMAT_KEY]: [this.attributeFormatSchema],
      [PERMISSIONS_KEY]: [this.attributePermissionSchema],
    },
    {
      idAttribute: (value): string => value.attributeId,
      processStrategy: (value, parent): EntityAttributeInterface => ({
        ...value,
        entityId: parent.entityId,
      }),
    }
  );

  /**
   * Linked Entity tag schema.
   * @memberof NormaliseGetEntitiesService
   */
  linkedEntityTagSchema = new schema.Entity(LINKED_ENTITY_TAGS_KEY, undefined, {
    idAttribute: (value): string => value.tagId,
    processStrategy: (value, parent): LinkedEntityTagInterface => ({
      ...value,
      linkedEntityId: parent.linkedEntityId,
      entityId: parent.entityId,
      type: parent.type,
    }),
  });

  /**
   * Linked Entity schema.
   * @memberof NormaliseGetEntitiesService
   */
  linkedEntitySchema = new schema.Entity(
    ENTITIES_KEY,
    {
      [ATTRIBUTES_KEY]: [this.attributeSchema],
    },
    {
      idAttribute: (value): string => value.entityId,
    }
  );

  /**
   * Entity Link schema
   * @memberof NormaliseGetEntitiesService
   */
  linkSchema = new schema.Entity(
    LINKED_ENTITIES_KEY,
    {
      [ENTITIES_KEY]: this.linkedEntitySchema,
      [LINKED_ENTITY_TAGS_KEY]: [this.linkedEntityTagSchema],
    },
    {
      idAttribute: (value): string => value.linkedEntityId,
      processStrategy: (value, parent): LinkedEntityInterface => ({
        ...value,
        entityId: parent.entityId,
      }),
    }
  );

  /**
   * Entity Permission schema.
   * @memberof NormaliseGetEntitiesService
   */
  permissionSchema = new schema.Entity(PERMISSIONS_KEY, undefined, {
    idAttribute: (value): string => value.entityPermissionId,
    processStrategy: (value, parent): EntityPermissionInterface => ({
      ...value,
      entityId: parent.entityId,
    }),
  });

  /**
   * Entity schema.
   * @memberof NormaliseGetEntitiesService
   */
  entitySchema = new schema.Entity(
    ENTITIES_KEY,
    {
      [ATTRIBUTES_KEY]: [this.attributeSchema],
      [LINKED_ENTITIES_KEY]: [this.linkSchema],
      [PERMISSIONS_KEY]: [this.permissionSchema],
      [QUESTION_SETS_KEY]: [NormaliseGetQuestionSetService.questionSetSchema],
    },
    {
      idAttribute: (value): string => value.entityId,
    }
  );

  /**
   * Entity list schema.
   * @memberof NormaliseGetEntitiesService
   */
  entityListSchema = [this.entitySchema];

  /**
   * An array of output strategies to process the flattened data.
   * @type {NormalisationOutputStrategyInterface[]}
   * @memberof NormaliseGetEntitiesService
   */
  outputStrategies: NormalisationOutputStrategyInterface[] = [
    {
      key: ENTITIES_KEY,
      process: Normalise.createRemovePropertiesFn([
        ATTRIBUTES_KEY,
        LINKED_ENTITIES_KEY,
        PERMISSIONS_KEY,
        QUESTION_SETS_KEY,
      ]),
    },
    {
      key: ATTRIBUTES_KEY,
      process: Normalise.createRemovePropertiesFn([ATTRIBUTE_RULE_KEY, ATTRIBUTE_FORMAT_KEY, PERMISSIONS_KEY]),
    },
    { key: ATTRIBUTE_RULE_KEY },
    { key: ATTRIBUTE_FORMAT_KEY },
    { key: PERMISSIONS_ATTRIBUTE_KEY },
    {
      key: LINKED_ENTITIES_KEY,
      process: Normalise.createRemovePropertiesFn([ENTITIES_KEY, LINKED_ENTITY_TAGS_KEY]),
    },
    {
      key: LINKED_ENTITY_TAGS_KEY,
    },
    {
      key: PERMISSIONS_KEY,
    },
    {
      key: QUESTION_SETS_KEY,
    },
  ];

  /**
   * Creates an instance of NormaliseGetEntitiesService.
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetEntitiesService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Flatten the getEntities response and validate the new schema return.
   * @param {EntityApiInterface[]} entityList - the response from API getEntities
   * @return {NormalisedEntityList} - the flattened getEntities response.
   */
  flatten(entityList: EntityApiInterface[]): NormalisedEntityList {
    const normaliseResult = Normalise.flatten(entityList, this.entityListSchema, this.outputStrategies);
    if (!isNormalisedEntityListGuard(normaliseResult)) {
      this.logger.logEvent(
        'Entity Feature',
        'Normalise Entity List Data',
        undefined,
        getNormalisedEntityListError(normaliseResult)
      );

      throw new Error('The result of the normalised Entity List does not match the expected format.');
    }
    return normaliseResult;
  }

  /**
   * Extract the getEntitiesSummary response and validate the new schema return.
   * @param {EntitySummaryApiInterface[]} entitySummaryList - the response from API getEntitiesSummary
   * @return {NormalisedEntitySummaryList} - the flattened getEntities response.
   */
  extractEntitiesSummary(entitySummaryList: EntitySummaryApiInterface[]): NormalisedEntitySummaryList {
    const entityList = entitySummaryList.map((entity) => {
      const { updated, ...rest } = entity;
      return { ...rest, created: updated };
    });

    if (!isNormalisedEntitySummaryListGuard(entityList)) {
      this.logger.logEvent(
        'Entity Feature',
        'Normalise Entity Summary List Data',
        undefined,
        getNormalisedEntitySummaryListError(entityList)
      );

      throw new Error('The result of the normalised Entity Summary List does not match the expected format.');
    }
    return entityList;
  }
}
