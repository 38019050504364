import { scssVariables } from '../colour-schema/colours';

/**
 * Manually control the zoom level.
 */
export const DIAGRAM_ZOOM = 0.85;

/**
 *  Styling Constants
 */

// Font
export const DIAGRAM_FONT_SIZE = 14 * DIAGRAM_ZOOM;
export const DIAGRAM_FONT_WEIGHT = 'bold';

// Step
export const DIAGRAM_STEP_FILL_COLOUR = scssVariables.white;
export const DIAGRAM_STEP_TEXT_COLOUR = scssVariables.text;
export const DIAGRAM_STEP_ELEMENT_HEIGHT = 64 * DIAGRAM_ZOOM;
export const DIAGRAM_STEP_WIDTH = 125 * DIAGRAM_ZOOM; // Set the static width for child elements
export const DIAGRAM_STEP_ELEMENT_RADIUS = 16 * DIAGRAM_ZOOM;

// Group
export const DIAGRAM_GROUP_FILL_COLOUR = scssVariables['blue-300'];
export const DIAGRAM_GROUP_ELEMENT_HEIGHT = 36 * DIAGRAM_ZOOM;
export const DIAGRAM_GROUP_TEXT_COLOUR = scssVariables.text;
export const DIAGRAM_GROUP_ELEMENT_RADIUS = 8 * DIAGRAM_ZOOM;

// General Element
export const DIAGRAM_ELEMENT_BORDER_WIDTH = 2;
export const DIAGRAM_ELEMENT_BORDER_COLOUR = scssVariables['true-blue'];

// Lines/Links
export const DIAGRAM_LINE_CURVE = 20;
export const DIAGRAM_LINE_WIDTH = 2;
export const DIAGRAM_LINE_COLOUR = scssVariables['brand-500'];
export const DIAGRAM_LINE_LABEL_FILL_COLOUR = scssVariables['brand-500'];
export const DIAGRAM_LINE_LABEL_BORDER_COLOUR = scssVariables['true-blue'];
export const DIAGRAM_LINE_LABEL_TEXT_COLOUR = scssVariables.white;

/**
 *  Structural Constants
 */
export const DIAGRAM_STEP_Y_OFFSET = 138 * DIAGRAM_ZOOM; // Offset from group header element.
export const DIAGRAM_STEP_SPACE_X = 158 * DIAGRAM_ZOOM;
export const DIAGRAM_GROUP_SPACE_X = 150 * DIAGRAM_ZOOM;
export const DIAGRAM_ROW_HEIGHT = 30 * DIAGRAM_ZOOM;
export const DIAGRAM_START_X = 50;
export const DIAGRAM_START_Y = 100;
export const DIAGRAM_PORT_RADIUS = 5;
export const DIAGRAM_HEIGHT = 1000;
