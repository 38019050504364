<section class="sc-integration-empty-page flex flex-column align-items-center sc-pt-10">
  <sc-icon key="empty-integration" [width]="344" [height]="344"></sc-icon>
  <div class="sc-integration-empty-page__body">
    <p i18n class="title sc-font-information-subtitle sc-text">Improving Your Experience.</p>
    <div class="subtitle sc-font-information-body sc-text-lighter sc-ml-18 sc-pt-10">
      <p i18n class="sc-mb-20">We're working on new integrations to speed up your workflow.</p>
      <p i18n>Thanks for your patience!</p>
    </div>
  </div>
</section>
