import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

/**
 * Generic Column component.
 * Good to use with the generic Section component.
 *
 * @export
 * @class ColumnComponent
 */
@Component({
  selector: 'sc-column',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ColumnComponent {
  /**
   * Class to add to the hosting element.
   *
   * @memberof ColumnComponent
   */
  @HostBinding('className') columnClass = 'col sc-column';
}
