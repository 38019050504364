import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAttributeTypeEnum, RecordColumnAttributeInterface } from '@surecloud/common';
import { NormalisedRecord } from '../../services/normalise-get-record/normalise-get-record.validations';
import { RecordActions } from '../actions/record.actions';

export const RECORDS_FEATURE_KEY = 'record';

export interface StateInterface extends EntityState<NormalisedRecord> {
  columnAttributes: RecordColumnAttributeInterface[];
  viewerCanCreate: boolean;
  totalItems: number;
  loaded: boolean;
  error?: string | null;
}

export const recordAdapter: EntityAdapter<NormalisedRecord> = createEntityAdapter<NormalisedRecord>({
  selectId: (record: NormalisedRecord) => record.recordId,
});

export const initialState: StateInterface = recordAdapter.getInitialState({
  // set initial required properties
  columnAttributes: [],
  viewerCanCreate: false,
  loaded: false,
  totalItems: 0,
});

/**
 * Record reducer.
 */
const recordReducer = createReducer(
  initialState,

  on(RecordActions.readRecordGridInputSuccess, (state: StateInterface, { recordGridInput }) => {
    const columnAttributes = recordGridInput.columnAttributes.map((attribute) => ({
      ...attribute,
      type: attribute.type === EntityAttributeTypeEnum.Owner ? EntityAttributeTypeEnum.User : attribute.type,
    }));

    const { records, viewerCanCreate, totalItems } = recordGridInput;
    const newState = { ...state, columnAttributes, viewerCanCreate, totalItems };
    return recordAdapter.setAll(records, { ...newState, loaded: true });
  }),

  on(RecordActions.readRecordResponseFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RecordActions.updateRecordSuccess, (state: StateInterface, { recordComponentValue }) =>
    recordAdapter.updateOne(
      {
        id: recordComponentValue.recordId,
        changes: { attributes: recordComponentValue.attributes },
      },
      state
    )
  ),

  on(RecordActions.createRecordSuccess, (state: StateInterface, { record }) =>
    recordAdapter.addOne(
      {
        ...record,
        attributes: state.columnAttributes.map(() => ({ singleAttribute: { value: '', display: '' } })),
      },
      state
    )
  ),

  on(RecordActions.completeDeleteRecords, (state: StateInterface, { recordIds }) =>
    recordAdapter.removeMany(recordIds, {
      ...state,
      totalItems: state.totalItems - recordIds.length,
    })
  ),

  on(RecordActions.reset, () => initialState)
);

/**
 * Perform reducer logic on the record NGRX state store for a specific record action.
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX record view action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return recordReducer(state, action);
}
