import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScIconType } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';

@Component({
  selector: 'sc-section',
  standalone: true,
  imports: [CommonModule, CommonIconModule],
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  /**
   * Apply an additional HTML classname to the section with this input.
   * @type {(string )}
   * @memberof SectionComponent
   */
  @Input()
  classname = 'sc-mb-20';

  /**
   * Apply an additional HTML classname for heading to the section with this input.
   * @type {(string )}
   * @memberof SectionComponent
   */
  @Input()
  classnameHeading = '';

  /**
   * The heading of the section.
   * @type {(string | undefined)}
   * @memberof SectionComponent
   */
  @Input()
  heading: string | undefined;

  /**
   * Icon key
   * @type {ScIconType | undefined}
   * @memberof SectionComponent
   */
  @Input() key: ScIconType | undefined;
}
