import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Align, PopupModule } from '@progress/kendo-angular-popup';
import { ScIconType } from '@surecloud/common';
import { ScButtonSize, ScButtonThemeColour, ScIconButtonShape } from '../button/button.constants';
import { IconButtonComponent } from '../button/icon-button.component';

export const POPUP_CLOSE_ON_TRIGGER = 'close-on-trigger-click';
export const POPUP_CLOSE_ON_ANY_CLICK = 'close-on-any-click';
export const POPUP_CLOSE_ON_OUTSIDE_CLICK = 'close-on-outside-click';
export type PopupCloseOnType =
  | typeof POPUP_CLOSE_ON_ANY_CLICK
  | typeof POPUP_CLOSE_ON_OUTSIDE_CLICK
  | typeof POPUP_CLOSE_ON_TRIGGER;

/**
 * Surecloud Popup Component that wraps the [Kendo PopupComponent](https://www.telerik.com/kendo-angular-ui/components/popup/).
 * @export
 * @class PopupComponent
 */
@Component({
  selector: 'sc-popup',
  standalone: true,
  templateUrl: './popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PopupModule, IconButtonComponent],
})
export class PopupComponent {
  /**
   * The reference to the anchor element that opens the popup/dropdown menu.
   * @type {ElementRef}
   * @memberof PopupComponent
   */
  @ViewChild('anchor', { read: ElementRef })
  public anchor!: ElementRef;

  /**
   * The reference to the popup element.
   * @type {ElementRef}
   * @memberof PopupComponent
   */
  @ViewChild('popup', { read: ElementRef })
  public popup!: ElementRef;

  /**
   * The position to align the popup.
   * @memberof PopupComponent
   */
  @Input() popupAlign: Align = { horizontal: 'right', vertical: 'top' };

  /**
   * The position to align the anchor.
   * @memberof PopupComponent
   */
  @Input() anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };

  /**
   * A class to add to the popup element.
   * @memberof PopupComponent
   */
  @Input() popupClass = '';

  /**
   * The icon button size.
   * @memberof PopupComponent
   */
  @Input() size: ScButtonSize = 'large';

  /**
   * Sets the shape of the icon button.
   * @type {ScIconButtonShape}
   * @memberof PopupComponent
   */
  @Input() shape: ScIconButtonShape = 'rounded';

  /**
   * Sets the icon
   * @type {ScIconType}
   * @memberof PopupComponent
   */
  @Input() icon: ScIconType | null = 'more-vertical';

  /**
   * Sets the theme colour for the icon button.
   * @type {ScButtonThemeColour}
   * @memberof PopupComponent
   */
  @Input() themeColour: ScButtonThemeColour = 'white';

  /**
   * Sets the horizontal margin of the popup in relationship with the icon button.
   * @type {ScButtonThemeColour}
   * @memberof PopupComponent
   */
  @Input() horizontal = 0;

  /**
   * Sets the vertical margin of the popup in relationship with the icon button.
   * @type {ScButtonThemeColour}
   * @memberof PopupComponent
   */
  @Input() vertical = 4;

  /**
   * Sets the label for the text button
   * @type {string}
   * @memberof PopupComponent
   */
  @Input() label = '';

  /**
   * Sets the close strategy
   * @type {string}
   * @memberof PopupComponent
   */
  @Input() closeOn: PopupCloseOnType = POPUP_CLOSE_ON_TRIGGER;

  /**
   * The visibility state of the popup.
   * @memberof PopupComponent
   */
  public show = false;

  /**
   * Creates an instance of PopupComponent.
   * @param {ChangeDetectorRef} cdr  The change detector reference.
   */
  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * Toggle showing/hiding the popup.
   * @memberof PopupComponent
   */
  public onToggle(): void {
    this.setShow(!this.show);
  }

  /**
   * If a click is detected outside of this component then close the popup/dropdown menu
   * according to the closeOn setting.
   * @param {PointerEvent} event The click event.
   * @memberof PopupComponent
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: PointerEvent): void {
    event.stopPropagation();
    const triggerContainsClickedElement = this.anchor.nativeElement.contains(event.target);
    const triggerIsClickedElement = event.target === this.anchor.nativeElement;
    // We want to ignore all trigger clicks as they are handled by the underlying component.
    const triggerClicked = triggerContainsClickedElement || triggerIsClickedElement;
    const contentClicked = this.popup?.nativeElement.contains(event.target);
    const closeOnAnyClick = this.closeOn === POPUP_CLOSE_ON_ANY_CLICK && !triggerClicked;
    const closeOnOutsideClick = this.closeOn === POPUP_CLOSE_ON_OUTSIDE_CLICK && !contentClicked && !triggerClicked;

    if (closeOnAnyClick || closeOnOutsideClick) {
      this.setShow(false);
    }
  }

  /**
   * If the Escape key is pressed then close the popup.
   * @param {KeyboardEvent} event The keyboard event.
   * @memberof PopupComponent
   */
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.setShow(false);
    }
  }

  /**
   * Set's the show value
   * @private
   * @param {boolean} show show the popup if true
   * @memberof PopupComponent
   */
  private setShow(show: boolean): void {
    this.show = show;
    this.cdr.detectChanges();
  }
}
