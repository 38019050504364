import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonIconModule } from '../../../icon/icons/common-icon.module';
import { MatrixCellStatusEnum } from '../../matrix-cell.enum';
import { MatrixCellInterface } from '../../matrix.interface';

/**
 * Component for a matrix cell
 * @export
 * @class MatrixCellComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-matrix-cell',
  templateUrl: './matrix-cell.component.html',
  styleUrls: ['./matrix-cell.component.scss'],
  standalone: true,
  imports: [CommonModule, CommonIconModule],
})
export class MatrixCellComponent {
  /**
   * The ID of the cell
   * @memberof MatrixCellComponent
   */
  @Input() id = '';

  /**
   * The column ID of the cell
   * @memberof MatrixCellComponent
   */
  @Input() colId = '';

  /**
   * The status of the cell
   * @type {MatrixCellStatusEnum}
   * @memberof MatrixCellComponent
   */
  @Input() status: MatrixCellStatusEnum = MatrixCellStatusEnum.EMPTY;

  /**
   * The label of the cell
   * @type {string}
   * @memberof MatrixCellComponent
   */
  @Input() label?: string;

  /**
   * The colour of the cell
   * @type {string}
   * @memberof MatrixCellComponent
   */
  @Input() colour?: string;

  /**
   * Can the cell be edited
   * @memberof MatrixCellComponent
   */
  @Input() isEditable = false;

  /**
   * Event emitter for when a cell with NEW status is clicked
   * @memberof MatrixCellComponent
   */
  @Output() newCellClick = new EventEmitter<void>();

  /**
   * Event emitter for when a cell is clicked
   * @memberof MatrixCellComponent
   */
  @Output() selectedClicked = new EventEmitter<MatrixCellInterface>();

  /**
   * Event emitter for when a cell with LABELLED status is clicked
   * @memberof MatrixCellComponent
   */
  @Output() labelledClicked = new EventEmitter<void>();

  /**
   * Enum for the status of a matrix cell
   * @memberof MatrixCellComponent
   */
  cellStatusEnum = MatrixCellStatusEnum;

  /**
   * Whether the cell is selected
   * @type {boolean}
   * @memberof MatrixCellComponent
   */
  isSelected = false;

  /**
   * Handles the click event outside the cell
   * @param {MouseEvent} event - click event
   * @memberof MatrixCellComponent
   */
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent): void {
    if (!(event.target as HTMLElement).closest('.sc-matrix-cell') && this.isSelected) {
      if (this.status === MatrixCellStatusEnum.EMPTY || this.status === MatrixCellStatusEnum.SELECTED) {
        this.toggleStatus();
      } else {
        this.toggleCellSelection();
      }
    }
  }

  /**
   * Toggles the status of the cell
   * @memberof MatrixCellComponent
   */
  toggleStatus(): void {
    if (this.isEditable) {
      this.status =
        this.status === MatrixCellStatusEnum.EMPTY ? MatrixCellStatusEnum.SELECTED : MatrixCellStatusEnum.EMPTY;
      this.toggleCellSelection();
    }
  }

  /**
   * Emits an event when a cell with FILLED status is clicked
   * @memberof MatrixCellComponent
   */
  toggleCellSelection(): void {
    if (this.isEditable) {
      this.isSelected = !this.isSelected;
      this.selectedClicked.emit({
        columnId: this.colId,
        cellId: this.id,
        status: this.status,
        colour: this.colour,
        label: this.label,
        isSelected: this.isSelected,
      });
    }
  }
}
