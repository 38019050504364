import { Action, ActionReducer } from '@ngrx/store';
import { FixedSizeStack } from '@surecloud/common';

export const actionsLog: FixedSizeStack<Action> = new FixedSizeStack<Action>(50);

/**
 *
 * @param {ActionReducer} reducer the reducer to pre process by the metaReducer
 * @return function with state and action
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    actionsLog.push(action);
    return reducer(state, action);
  };
}
