import { NAVIGATE_ROW_COLUMN, SELECT_ROW_COLUMN } from '@surecloud/common';
import { ColDef } from 'ag-grid-community';

/**
 * A reusable filter for removing additional columns from definitions.
 * @param {ColDef} column - the column to filter on
 * @return {boolean} whether to filter or not
 */
export const filterOutSelectAndNavigateColumnTypes = (column: ColDef): boolean =>
  column.field !== SELECT_ROW_COLUMN && column.field !== NAVIGATE_ROW_COLUMN;
