import { createAction, props } from '@ngrx/store';
import {
  CustomRecordResultInterface,
  GridStateInterface,
  RecordGridInputInterface,
  RecordInputInterface,
  RecordInterface,
  RequestIdInterface,
} from '@surecloud/common';
import { RecordComponentValueInterface } from '../models/record.input.interface';

export const RecordActions = {
  previewDataEnter: createAction('[Preview Data Page] Enter'),
  previewDataLeave: createAction('[Preview Data Page] Leave'),
  recordListEnter: createAction('[Record List Page] Enter'),
  recordListLeave: createAction('[Record List Page] Leave'),

  // Read Record
  readRecordResponse: createAction('[Record/API] Read Records Response', props<{ input: RecordInputInterface }>()),
  readRecordResponseSuccess: createAction(
    '[Record/API] Read Records success',
    props<{ recordResult: CustomRecordResultInterface }>()
  ),
  readRecordResponseFailure: createAction('[Record/API] Read Records Failed', props<{ error: string }>()),
  readRecordGridInputSuccess: createAction(
    '[Record/API] Read Record Grid Input success',
    props<{ recordGridInput: RecordGridInputInterface }>()
  ),
  // Update Record
  recordUpdate: createAction(
    '[Preview Data Page] Update Record',
    props<{ recordComponentValue: RecordComponentValueInterface }>()
  ),
  updateRecordSuccess: createAction(
    '[Record/API] Update Record Success',
    props<{ recordComponentValue: RecordInterface }>()
  ),
  updateRecordFailure: createAction('[Record/API] Update Records Failed', props<{ error: string }>()),

  createNewRecord: createAction('[Preview Data Page] Create New Record', props<RequestIdInterface>()),
  createRecord: createAction('[Record/API] Create Record', props<{ entityId: string } & RequestIdInterface>()),
  createRecordSuccess: createAction('[Record/API] Create Record success', props<{ record: RecordInterface }>()),
  createRecordFailure: createAction('[Record/API] Create Record Failed', props<{ error: string }>()),

  startDeleteRecords: createAction('[Preview Data Page] Start Delete Records', props<{ recordIds: string[] }>()),
  completeDeleteRecords: createAction('[Preview Data Page] Complete Delete Records', props<{ recordIds: string[] }>()),
  cancelDeleteRecords: createAction('[Preview Data Page] Cancel Delete Records'),
  deleteRecords: createAction('[Record/API] Delete Record', props<{ recordIds: string[] }>()),
  deleteRecordsFailure: createAction('[Record/API] Delete Record Failed', props<{ error: string }>()),

  exportRecordsSuccess: createAction('[Record/API] Export Records Success', props<{ url: string }>()),
  exportRecordsFailure: createAction('[Record/API] Export Records Failed', props<{ error: string }>()),

  reset: createAction('[Record] Reset'),

  updateGridState: createAction('[Record/API] Update Grid State', props<{ gridState: GridStateInterface }>()),
};
