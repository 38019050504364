import { EntityAttributeTypeEnum, SCGridSchemaTypeEnum, SCGridSchemaTypeUnion } from '@surecloud/common';

const entityAttributeTypeToGridColumnTypeMap: Record<EntityAttributeTypeEnum, SCGridSchemaTypeEnum> = {
  [EntityAttributeTypeEnum.User]: SCGridSchemaTypeEnum.MultipleUserAvatar,
  [EntityAttributeTypeEnum.OptionList]: SCGridSchemaTypeEnum.TagsReadonly,
  [EntityAttributeTypeEnum.Date]: SCGridSchemaTypeEnum.Date,
  [EntityAttributeTypeEnum.Number]: SCGridSchemaTypeEnum.Number,
  [EntityAttributeTypeEnum.Toggle]: SCGridSchemaTypeEnum.Boolean,
  [EntityAttributeTypeEnum.Text]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Sequence]: SCGridSchemaTypeEnum.Sequence,
  [EntityAttributeTypeEnum.Calculation]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Owner]: SCGridSchemaTypeEnum.MultipleUserAvatar,
  [EntityAttributeTypeEnum.State]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Email]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Url]: SCGridSchemaTypeEnum.Text,
  [EntityAttributeTypeEnum.Context]: SCGridSchemaTypeEnum.TagsReadonly,
  [EntityAttributeTypeEnum.Document]: SCGridSchemaTypeEnum.Document,
  [EntityAttributeTypeEnum.Hierarchy]: SCGridSchemaTypeEnum.TagsReadonly,
  [EntityAttributeTypeEnum.Name]: SCGridSchemaTypeEnum.Text,
};

/**
 * Public method to parse type {EntityAttributeTypeEnum} to {SCGridSchemaTypeEnum}
 * @export
 * @param {(EntityAttributeTypeEnum | null)} type - the attribute type
 * @return {*}  {SCGridSchemaTypeUnion}
 */
export const parseRecordColumnType = (type?: EntityAttributeTypeEnum | null): SCGridSchemaTypeUnion => {
  if (!type || !entityAttributeTypeToGridColumnTypeMap[type]) return SCGridSchemaTypeEnum.Text;
  return entityAttributeTypeToGridColumnTypeMap[type];
};
