<a
  *ngIf="blockRouteCommands; else noBlockRouteCommands"
  class="sc-logic-block sc-logic-block--route block"
  [routerLink]="blockRouteCommands">
  <ul>
    <li *ngFor="let block of logicBlocks; trackBy: trackByIndex" class="sc-logic-block__item block">
      <sc-logic-item [logicItem]="block" [direction]="direction"></sc-logic-item>
    </li>
  </ul>
</a>

<ng-template #noBlockRouteCommands>
  <div class="sc-logic-block block" *ngIf="logicBlocks && logicBlocks.length">
    <ul>
      <li *ngFor="let block of logicBlocks; trackBy: trackByIndex" class="sc-logic-block__item block">
        <ng-container *ngIf="block.itemRouteCommands; else noItemRouteCommand">
          <a [routerLink]="block.itemRouteCommands">
            <sc-logic-item [logicItem]="block" [direction]="direction"> </sc-logic-item>
          </a>
        </ng-container>
        <ng-template #noItemRouteCommand>
          <sc-logic-item
            (startEdit)="startEdit.emit(block.id)"
            [logicItem]="block"
            [direction]="direction"
            [editable]="true">
          </sc-logic-item>
        </ng-template>
      </li>
    </ul>
  </div>
</ng-template>
