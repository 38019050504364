import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ProgressBarModule, ProgressColor } from '@progress/kendo-angular-progressbar';
import { CarouselModule } from 'primeng/carousel';
import { CategoryInterface } from './categories.component.model';

/**
 * Component to display a list of categories progress
 * @export
 * @class CategoriesComponent
 */
@Component({
  selector: 'sc-categories',
  standalone: true,
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ProgressBarModule, CarouselModule],
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesComponent {
  /**
   * The active category ID.
   * @type {string}
   * @memberof CategoriesComponent
   */
  @Input()
  activeCategoryId: string | undefined | null;

  /**
   * Categories items
   * @type {CategoryInterface[]}
   * @memberof CategoriesComponent
   */
  @Input()
  items: CategoryInterface[] = [];

  /**
   * Categories title
   * @memberof CategoriesComponent
   */
  @Input()
  heading = $localize`Categories`;

  /**
   * Number of completed Categories
   * @type {number}
   * @memberof CategoriesComponent
   */
  @Input()
  completed = 0;

  /**
   * Number of total Categories
   * @type {number}
   * @memberof CategoriesComponent
   */
  @Input()
  total = 0;

  /**
   * Categories Label
   * @type {number}
   * @memberof CategoriesComponent
   */
  @Input()
  label = $localize`questions answered`;

  /**
   * When a category is clicked.
   * Then emit that category.
   * @type {EventEmitter<CategoryInterface>}
   * @memberof CategoriesComponent
   */
  @Output()
  activeCategory: EventEmitter<CategoryInterface> = new EventEmitter();

  private RED_300 = '#ca3a4f';
  private YELLOW_300 = '#d6b032';
  private GREEN_300 = '#1a9b58';
  /**
   * Categories progress bar colours
   * @type {ProgressColor[]}
   * @memberof CategoriesComponent
   */
  public colors: ProgressColor[] = [
    {
      from: 0,
      to: 49,
      color: this.RED_300,
    },
    {
      from: 50,
      to: 99,
      color: this.YELLOW_300,
    },
    {
      from: 99,
      to: 100,
      color: this.GREEN_300,
    },
  ];

  /**
   * When a user clicks on a category.
   * And that category is not currently active.
   * Then emit the active category event.
   * @param {CategoryInterface} category The category a user clicked on.
   * @memberof CategoriesComponent
   */
  setActiveCategory(category: CategoryInterface): void {
    if (category.categoryId !== this.activeCategoryId) {
      this.activeCategory.emit(category);
    }
  }
}
