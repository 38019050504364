import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonIconModule } from '../../../icon/icons/common-icon.module';
import { GridIconInterface } from './grid-icon-renderer.interface';

/**
 * AgGrid rendering component for rendering a icon with an optional label column cell.
 * The data structure of your column definition field must be of type GridIconInterface.
 *
 * @export
 * @class GridIconRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CommonIconModule],
  selector: 'sc-icon-renderer',
  templateUrl: './grid-icon-renderer.component.html',
})
export class GridIconRendererComponent implements ICellRendererAngularComp {
  /**
   * Icon and optional label to render.
   * Icon defaultts to alert with no label.
   *
   * @type {GridIconInterface}
   * @memberof GridIconRendererComponent
   */
  icon: GridIconInterface | undefined = undefined;

  /**
   * Handles the ag init and sets the values for the icon.
   * The data structure of your column definition field must be of type GridIconInterface.
   *
   * @param {ICellRendererParams} params Cell renderer params.
   * @memberof GridIconRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    const field = params.colDef?.field;
    const data = field && params.data[field] ? params.data[field] : {};
    const { label, type } = data;

    this.icon = { label, type };
  }

  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   *
   * @memberof GridIconRendererComponent
   * @return {boolean} Should the cell refresh.
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
