import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getLogicConditionList } from '../logic-condition/logic-condition.selectors';
import { ruleTriggerAdapter, RULE_TRIGGER_FEATURE_KEY, StateInterface } from './rule-trigger.reducer';

// Lookup the 'Rule Trigger' feature state managed by NgRx
export const getRuleTriggerState = createFeatureSelector<StateInterface>(RULE_TRIGGER_FEATURE_KEY);

const { selectAll, selectEntities } = ruleTriggerAdapter.getSelectors();

/**
 * Get all rule triggers.
 */
export const getRuleTriggerList = createSelector(getRuleTriggerState, (state: StateInterface) => selectAll(state));
export const getRuleTriggerLoaded = createSelector(getRuleTriggerState, (state: StateInterface) => state.loaded);
export const getRuleTriggerError = createSelector(getRuleTriggerState, (state: StateInterface) => state.error);

export const getRuleTriggerEntities = createSelector(getRuleTriggerState, (state: StateInterface) =>
  selectEntities(state)
);

export const getRuleTriggerSelectedId = createSelector(
  getRuleTriggerState,
  (state: StateInterface) => state.selectedId
);

export const getSelectedRuleTrigger = createSelector(
  getRuleTriggerEntities,
  getRuleTriggerSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSelectedRuleTriggerLogicConditions = createSelector(
  getRuleTriggerSelectedId,
  getLogicConditionList,
  (selectedId, logicConditionList) =>
    logicConditionList.filter((logicCondition) => logicCondition.parentId === selectedId)
);
