import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, from, map, mergeMap, of, switchMap, take } from 'rxjs';
import { RecordService } from '../../../services/record/record.service';
import { RecordLinkableActions } from '../../actions/linkable-records.actions';
import { RecordLinkedActions } from '../../actions/linked-records.actions';
import { TableRecordActions } from '../../actions/table-records.actions';
import { LINKABLE, LINKED } from '../../constants/record.constants';
import { isRecordDataSingleCellInterface } from '../../models/record-input-type-guards';
import { TableRecordInterface } from '../../models/table-record/table-record.model';
import * as TableRecordSelectors from '../../selectors/table-record.selectors';
/**
 * The Effects/side effects for Table Record.
 *
 * @export
 * @class RecordEffects
 */
@Injectable({ providedIn: 'root' })
export class TableRecordEffects {
  /**
   * When a user update a value in a record view table.
   * Make a call to update the record on the API.
   *
   * @memberof TableRecordEffects
   */
  updateTableRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableRecordActions.updateTableRecord),
      switchMap(({ record, attributeId, value, columnIndex }) =>
        this.recordService.update(record.recordId, attributeId, value).pipe(
          map(() => {
            const { attributes } = record;
            const cell = attributes[columnIndex];
            if (isRecordDataSingleCellInterface(cell)) {
              cell.singleAttribute.value = value;
            }

            attributes[columnIndex] = cell;
            return { ...record, attributes };
          }),
          map(() => TableRecordActions.updateTableRecordSuccess({ record })),
          catchError((error: unknown) => of(TableRecordActions.updateTableRecordFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the linked records data has loaded.
   * Then dispatch a successful table records success action
   *
   * @memberof TableRecordEffects
   */
  loadLinkedRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.readLinkedRecordsDataSuccess, RecordLinkableActions.readLinkableRecordsDataSuccess),
      map(({ normalisedRecordLink }) =>
        TableRecordActions.updateTableRecordsSuccess({
          records: normalisedRecordLink.tableRecords,
          tabId: normalisedRecordLink.link.tabId,
        })
      )
    )
  );

  /**
   * When the linked records are are swap locally
   * Then add linked records data to the table store
   *
   * @memberof TableRecordEffects
   */
  swapLinkableToLinkRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkableActions.linkRecordsSuccess),
      switchMap(({ tabId, recordsIds }) =>
        from(recordsIds)
          .pipe(
            mergeMap((id) => this.store.select(TableRecordSelectors.getTableRecord(`${tabId}.${LINKABLE}.${id}`))),
            filter((tableRecord): tableRecord is TableRecordInterface => !!tableRecord)
          )
          .pipe(take(recordsIds.length))
      ),
      map((tableRecord) =>
        TableRecordActions.addLinkedTableRecord({
          tableRecord: { ...tableRecord, parentId: tableRecord.parentId.replace(`.${LINKABLE}.`, `.${LINKED}.`) },
        })
      )
    )
  );

  /**
   * When the linked records are are swap locally
   * Then add linkable records data to the table store
   *
   * @memberof TableRecordEffects
   */
  swapLinkedToLinkableRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.unlinkRecordsSuccess),
      switchMap(({ tabId, recordsIds }) =>
        from(recordsIds)
          .pipe(
            mergeMap((id) => this.store.select(TableRecordSelectors.getTableRecord(`${tabId}.${LINKED}.${id}`))),
            filter((tableRecord): tableRecord is TableRecordInterface => !!tableRecord)
          )
          .pipe(take(recordsIds.length))
      ),
      map((tableRecord) =>
        TableRecordActions.addLinkableTableRecord({
          tableRecord: { ...tableRecord, parentId: tableRecord.parentId.replace(`.${LINKED}.`, `.${LINKABLE}.`) },
        })
      )
    )
  );

  /**
   * When the linked records data is added then
   * Remove the corresponding linkable record data
   *
   * @memberof TableRecordEffects
   */
  removeLinkableTableRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableRecordActions.addLinkedTableRecord),
      map(({ tableRecord }) =>
        TableRecordActions.removeLinkableTableRecord({
          tableRecord: { ...tableRecord, parentId: tableRecord.parentId.replace(`.${LINKED}.`, `.${LINKABLE}.`) },
        })
      )
    )
  );

  /**
   * When the linkable records data is added then
   * Remove the corresponding linked record data
   *
   * @memberof TableRecordEffects
   */
  removeLinkedTableRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableRecordActions.addLinkableTableRecord),
      map(({ tableRecord }) =>
        TableRecordActions.removeLinkedTableRecord({
          tableRecord: { ...tableRecord, parentId: tableRecord.parentId.replace(`.${LINKABLE}.`, `.${LINKED}.`) },
        })
      )
    )
  );

  /**
   * Creates an instance of RecordViewEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {RecordService} recordService The record API service.
   * @param store
   * @memberof TableRecordEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly recordService: RecordService,
    private store: Store
  ) {}
}
