import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectQueryParam } from '@surecloud/common';
import { first, withLatestFrom } from 'rxjs';

@Component({
  selector: 'sc-standalone-error-page',
  templateUrl: './error-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent implements OnInit {
  /**
   * The string to copy to the clipboard.
   * @memberof ErrorPageComponent
   */
  public copyToClipboard = '';

  /**
   * The error message from the url query params.
   * @memberof ErrorPageComponent
   */
  public errorMessage$ = this.store.select(selectQueryParam('message'));

  /**
   * A tracking ID from the url query params.
   * @memberof ErrorPageComponent
   */
  trackingId$ = this.store.select(selectQueryParam('trackingId'));

  /**
   * Combines the error message and tracking id if one is present so it's ready to be copied to the clipboard.
   * @memberof ErrorPageComponent
   */
  public copyDetails(): void {
    this.errorMessage$.pipe(first(), withLatestFrom(this.trackingId$)).subscribe(([message, trackingId]) => {
      this.copyToClipboard = trackingId ? `${message} \nTracking ID: ${trackingId}` : `${message}`;
    });
  }

  /**
   * Creates an instance of ErrorPageComponent.
   * @param {Store} store The NGRX store.
   * @memberof ErrorPageComponent
   */
  constructor(private readonly store: Store) {}

  /**
   * When the component is initialised.
   * Then setup the details for copying to the clipboard.
   * @memberof ErrorPageComponent
   */
  ngOnInit(): void {
    this.copyDetails();
  }
}
