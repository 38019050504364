import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  LogicConditionType,
  LogicConditionTypeEnum,
  makeBasicLogicCondition,
} from '../../models/logic-condition.models';
import { LogicConditionActions } from './logic-condition.actions';

export const LOGIC_CONDITION_FEATURE_KEY = 'logicCondition';

export interface StateInterface extends EntityState<LogicConditionType> {
  selectedId?: string; // which Workflow LogicCondition has been selected
  loaded: boolean; // has the Workflow LogicCondition list been loaded
  error?: string | null; // last known error (if any)
}

export interface LogicConditionPartialStateInterface {
  readonly [LOGIC_CONDITION_FEATURE_KEY]: StateInterface;
}

export const logicConditionAdapter: EntityAdapter<LogicConditionType> = createEntityAdapter<LogicConditionType>({
  selectId: (value) => value.conditionId,
});

export const initialState: StateInterface = logicConditionAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const logicConditionReducer = createReducer(
  initialState,
  on(LogicConditionActions.readLogicConditionList, (state: StateInterface) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(LogicConditionActions.readLogicConditionListSuccess, (state: StateInterface, { logicConditionList }) =>
    logicConditionAdapter.setAll(logicConditionList, { ...state, loaded: true })
  ),
  on(LogicConditionActions.readLogicConditionListFailure, (state: StateInterface, { error }) => ({
    ...state,
    error,
  })),
  on(LogicConditionActions.selectLogicCondition, (state: StateInterface, { conditionId }) => ({
    ...state,
    selectedId: conditionId,
  })),
  on(
    LogicConditionActions.createNewLogicConditionSuccess,
    (state: StateInterface, { conditionId, workflowId, triggerId }) => {
      const logicCreation = {
        rootId: workflowId,
        parentId: triggerId,
        attributes: [],
        conditionId,
        condition: null,
        comparator: null,
        type: LogicConditionTypeEnum.BASIC,
        value: null,
      };

      const logicCondition = makeBasicLogicCondition(logicCreation);
      return logicConditionAdapter.addOne(logicCondition, { ...state, selectedId: conditionId });
    }
  ),
  on(LogicConditionActions.deleteLogicConditionSuccess, (state: StateInterface, { conditionId }) =>
    logicConditionAdapter.removeOne(conditionId, { ...state, selectedId: undefined })
  ),
  on(LogicConditionActions.updateLogicConditionSuccess, (state: StateInterface, { partialLogicCondition }) =>
    partialLogicCondition.conditionId
      ? logicConditionAdapter.updateOne(
          { id: partialLogicCondition.conditionId, changes: partialLogicCondition },
          state
        )
      : state
  )
);

/**
 * Perform reducer logic on the entity NGRX state store for a specific state action.
 * @export
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX state action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return logicConditionReducer(state, action);
}
