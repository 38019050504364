import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService } from '@surecloud/common';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { TaskService } from '../../../services/task/task.service';
import { TaskApiActions, TaskEffectsActions } from '../../actions/task.actions';

/**
 * The Effects/side effects for Task.
 *
 * @export
 * @class TaskEffects
 */
@Injectable({ providedIn: 'root' })
export class TaskEffects {
  /**
   * When reading a Task list.
   * Then load all Task data from the API.
   *
   * @memberof TaskEffects
   */
  loadTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.readTasks),
      switchMap(() =>
        this.taskService.readAll().pipe(
          map((normalisedTaskList) =>
            TaskApiActions.readTasksDataSuccess({
              normalisedTaskList,
            })
          ),
          catchError((error: unknown) => of(TaskApiActions.readTasksFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the Task list has received a response.
   * Then load the result into state.
   *
   * @memberof TaskEffects
   */
  setTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.readTasksDataSuccess),
      map(({ normalisedTaskList }) =>
        TaskApiActions.readTasksSuccess({
          tasks: normalisedTaskList.tasks,
        })
      )
    )
  );

  /**
   * When reading an Task.
   * Then load the Task data from the API.
   *
   * @memberof TaskEffects
   */
  loadTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.readTask),
      switchMap(({ recordId, taskId }) =>
        this.taskService.read(recordId, taskId).pipe(
          map((normalisedTaskList) =>
            TaskApiActions.readTaskDataSuccess({
              normalisedTaskList,
            })
          ),
          catchError((error: unknown) => of(TaskApiActions.readTaskFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the Task has received a response.
   * Then load the result into state.
   *
   * @memberof TaskEffects
   */
  setTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.readTaskDataSuccess),
      map(({ normalisedTaskList }) =>
        TaskApiActions.readTaskSuccess({
          task: normalisedTaskList.tasks[0],
        })
      )
    )
  );

  /**
   * When a Task has been updated.
   * Then persist that Task data to the API.
   *
   * @memberof TaskEffects
   */
  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.updateTask),
      switchMap(({ task }) =>
        this.taskService.update(task).pipe(
          map((data) => TaskApiActions.updateTaskSuccess({ task: data })),
          catchError((error: unknown) => of(TaskApiActions.updateTaskFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When reading new Task and Task totals.
   * Then load all slimline Task data from the API.
   *
   * @memberof TaskEffects
   */
  loadTaskTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.readTaskTotals),
      switchMap(() =>
        this.taskService.readTotals().pipe(
          map((tasks) => TaskApiActions.readTaskTotalsSuccess({ tasks })),
          catchError((error: unknown) => of(TaskApiActions.readTaskTotalsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the Task list Task Totals API calls have received a response.
   * Then set the task and new task totals into state.
   *
   * @memberof TaskEffects
   */
  setTaskTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskApiActions.readTasksSuccess, TaskApiActions.readTaskTotalsSuccess),
      map(({ tasks }) =>
        TaskEffectsActions.setTaskTotals({
          totalNewTasks: tasks.filter((task) => task.newTask).length,
          totalTasks: tasks.length,
        })
      )
    )
  );

  /**
   * When a Task API call failed.
   * Then log the error.
   *
   * @memberof TaskEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskApiActions.readTasksFailure, TaskApiActions.readTaskFailure, TaskApiActions.updateTaskFailure),
        tap(({ error, type }) => this.logger.logEvent('Task', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of TaskEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {TaskService} taskService The Task API service.
   * @param {LoggerService} logger The common logger service.
   * @memberof TaskEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly taskService: TaskService,
    private readonly logger: LoggerService
  ) {}
}
