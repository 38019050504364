import { Injectable } from '@angular/core';
import { RecentRecordsApiInterfaceQueryService } from '@surecloud/api-types';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { NormaliseGetRecentRecordsService } from '../normalise-get-recent-records/normalise-get-recent-records.service';
import { NormalisedRecentRecords } from '../normalise-get-recent-records/normalise-get-recent-records.validation';

export const RECENT_RECORDS_READ_ALL_ERROR = 'No response data from read all Priority Tasks returned from API.';

/**
 * Recent Records API service.
 * @export
 * @class RecentRecordsService
 */
@Injectable({
  providedIn: 'root',
})
export class RecentRecordsService {
  /**
   * Creates an instance of Recent Records Service
   * @param {RecentRecordsApiInterfaceQueryService} recentRecordsApiInterfaceQueryService The service to fetch all RecentRecordss.
   * @param {NormaliseGetRecentRecordsService} normaliseGetRecentRecordsService The recentRecords data normalising service.
   * @memberof RecentRecordsService
   */
  constructor(
    private readonly recentRecordsApiInterfaceQueryService: RecentRecordsApiInterfaceQueryService,
    private readonly normaliseGetRecentRecordsService: NormaliseGetRecentRecordsService
  ) {}

  /**
   * Query to get all Recent Recordss.
   * @return {Observable<NormalisedRecentRecords>} The Recent Records normalised data.
   * @memberof RecentRecordsService
   */
  readAll(): Observable<NormalisedRecentRecords> {
    return this.recentRecordsApiInterfaceQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.recentRecords?.homepageRecentRecords
            ? of(this.normaliseGetRecentRecordsService.extract(data.recentRecords.homepageRecentRecords))
            : throwError(() => RECENT_RECORDS_READ_ALL_ERROR)
        )
      );
  }
}
