import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Surecloud Title Description Component.
 * @export
 * @class TitleDescriptionComponent
 */
@Component({
  selector: 'sc-title-description',
  standalone: true,
  templateUrl: './title-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class TitleDescriptionComponent {
  /**
   * Sets the heading
   * @memberof TitleDescriptionComponent
   */
  @Input() heading: string | undefined | null;

  /**
   * Sets the description
   * @memberof TitleDescriptionComponent
   */
  @Input() description: string | undefined;

  /**
   * Sets any additional metadata.
   * @memberof TitleDescriptionComponent
   */
  @Input() metaData?: string;
}
