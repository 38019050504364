import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DEFAULT_COLOUR_PICKER_COLOURS } from './colour-picker.constants';

/**
 * Surecloud Colour Picker Component
 *
 *@example
 *<colour-picker-story [label]='label' [description]='description'></colour-picker-story>
 * @export
 * @class ColourPickerComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-colour-picker',
  standalone: true,
  templateUrl: './colour-picker.component.html',
  styleUrls: ['./colour-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, InputsModule, FormsModule, ReactiveFormsModule, LabelModule],
})
export class ColourPickerComponent implements OnInit {
  /**
   * The form control name if using a reactive form.
   *
   * @memberof ColourPickerComponent
   */
  @Input() controlName = '';

  /**
   * Sets the current selected Colour if there is one.
   * Needs to be an exact string match of one of the colours array items passed in.
   *
   * @type {string}
   * @memberof ColourPickerComponent
   */
  @Input() selectedColour?: string;

  /**
   * Sets the colours to pick from.
   *
   * @type {Array<string>}
   * @memberof ColourPickerComponent
   */
  @Input() colourOptions: Array<string> = DEFAULT_COLOUR_PICKER_COLOURS;

  /**
   * Sets the text value for the label.
   *
   * @memberof ColourPickerComponent
   */
  @Input() label = $localize`Colour`;

  /**
   * Text to display an additional description to the user.
   *
   * @memberof ColourPickerComponent
   */
  @Input() description = '';

  /**
   * Expose the track by function to the view template.
   *
   * @memberof ColourPickerComponent
   */
  trackByFunction = ColourPickerComponent.trackByFn;

  /**
   * The test hook to pass in.
   *
   * @memberof ColourPickerComponent
   */
  @Input()
  testhook!: string;

  /**
   * Used to store the instance of the parent form group so we can attach this component to a form control on it.
   *
   * @type {FormGroup}
   * @memberof ColourPickerComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Creates an instance of InputTextComponent.
   *
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof ColourPickerComponent
   */

  /**
   * Track by function for Angular template loops.
   *
   * @static
   * @param {number} index The index value of the item in the array.
   * @return {string} String to track the loop by.
   * @memberof ColourPickerComponent
   */
  static trackByFn(index: number): string {
    return `${index}`;
  }

  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   *
   * @memberof ColourPickerComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
