import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * Service to synchronise a form control value with the value of an object model if the two have changed.
 *
 * @export
 * @class FormSynchroniserService
 */
@Injectable({
  providedIn: 'root',
})
export class FormSynchroniserService {
  /**
   * Check if the new value is different from the old current value.
   *
   * @param {string} current The current value of the form control.
   * @param {string} update The proposed update of the form control.
   * @static
   * @template T
   * @return {boolean} Whether the new value is different from the current value.
   * @memberof FormSynchroniserService
   */
  static hasValueChanged<T>(current: T[keyof T], update: T[keyof T]): boolean {
    return current !== update;
  }

  /**
   * Set the new form control value if the new value is different from it's current value.
   *
   * @static
   * @template T
   * @param {FormGroup} form The form the control belongs to.
   * @param {string} field The field name of the control to check.
   * @param {string} value The new proposed value of the form control.
   * @return {void}
   * @memberof FormSynchroniserService
   */
  static setIfValueChanged<T>(form: FormGroup, field: string, value: T[keyof T]): void {
    const current = form.controls[field].value;
    if (FormSynchroniserService.hasValueChanged<T>(current, value)) {
      form.controls[field].setValue(value, { emitEvent: false });
    }
  }

  /**
   * Synchronise the form controls with the current data model.
   *
   * @static
   * @template FormModel
   * @template DataModel
   * @param {FormGroup} form The form to synchronise the values on.
   * @param {DataModel} dataModel The data model to synchronise the form with.
   * @memberof FormSynchroniserService
   */
  static synchronise<FormModel extends object, DataModel extends object>(form: FormGroup, dataModel: DataModel): void {
    const formModel = form.value as FormModel;
    const formModelKeys = Object.keys(formModel);
    const stateModelKeys = Object.keys(dataModel);
    const commonKeys = stateModelKeys.filter((value) => formModelKeys.includes(value));
    commonKeys.forEach((key) => {
      const value = dataModel[key as keyof DataModel];
      FormSynchroniserService.setIfValueChanged<DataModel>(form, key, value);
    });
  }
}
