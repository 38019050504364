import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaskInterface } from '@surecloud/task-state';

/**
 * Toolbar Task Page actions map.
 */
const TOOLBAR_TASKS_PAGE_ACTIONS_MAP = {
  enter: emptyProps(),
  toggle: props<{ open?: boolean }>(),
  open: emptyProps(),
  close: emptyProps(),
  select: props<{ task: TaskInterface }>(),
  'Read Tasks': emptyProps(),
  'Read Task Totals': emptyProps(),
};

/**
 * Toolbar Task page actions.
 * @export
 */
export const ToolbarTasksPageActions = createActionGroup({
  source: 'Toolbar Tasks/Page',
  events: TOOLBAR_TASKS_PAGE_ACTIONS_MAP,
});
