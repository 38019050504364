<form class="k-form" [formGroup]="form">
  <sc-select-dropdown
    class="block sc-mb-24"
    label="Condition"
    i18n-label
    controlName="condition"
    description="Select the validation condition"
    i18n-description
    [options]="conditionOptions">
  </sc-select-dropdown>
  <ng-container *ngIf="form.controls.condition.value && valueTypeOptions[form.controls.condition.value].length">
    <sc-select-dropdown
      class="block sc-mb-24"
      label="Type"
      i18n-label
      controlName="valueType"
      description="Select the type of value to measure against"
      i18n-description
      [options]="valueTypeOptions[form.controls.condition.value]">
    </sc-select-dropdown>
  </ng-container>
  <ng-container>
    <!-- Render dynamic component -->
    <div class="sc-mb-24"><ng-template scCommonViewContainer></ng-template></div>
    <!-- Render dropdown hierarchy component (This is a more complex component that requires Outputs) -->
    <sc-dropdown-hierarchy
      *ngIf="conditionToFormNameMap[configName] === 'dropdownHierarchyNumber'"
      class="sc-mb-24"
      controlName="dropdownHierarchy"
      (requestNewCurrentNode)="requestNewCurrentNode.emit($event)"
      (dropdownMenuDestroyed)="dropdownMenuDestroyed.emit()"
      label="Attribute"
      i18n-label
      [description]="
        conditionToFormNameMap[configName] === 'dropdownHierarchyNumber' ? numberDescription : dateDescription
      "
      [defaultValue]="defaultValue"
      [currentNode]="currentNode"
      [topLevelNode]="topLevelNode">
    </sc-dropdown-hierarchy>
  </ng-container>
</form>
