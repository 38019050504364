import { createSelector } from '@ngrx/store';
import { UserMenuConfigurationInterface } from '@surecloud/design';
import { UserProfileSelectors } from '@surecloud/user-profile';

/**
 * Get the User Profile details in an interface we can pass to the User Menu.
 */
export const getUserMenuConfig = createSelector(
  UserProfileSelectors.getUserProfile,
  UserProfileSelectors.getUserProfileFullName,
  (profile, name): UserMenuConfigurationInterface => ({
    email: profile?.emailAddress ?? null,
    colour: profile?.colour ?? '',
    name,
    initials: profile?.initials ?? '',
  })
);

/**
 * Get boolean determining whether can view global menu on the top toolbar.
 */
export const getCanViewGlobalMenu = createSelector(
  UserProfileSelectors.getIsStandardUserType,
  (isStandardUser): boolean => isStandardUser
);

/**
 * Get boolean determining whether can view user menu on the top toolbar.
 */
export const getCanViewUserMenu = createSelector(
  UserProfileSelectors.getIsStandardUserType,
  (isStandardUser): boolean => isStandardUser
);

/**
 * Get boolean determining whether can view tasks on the top toolbar.
 */
export const getCanViewTasks = createSelector(
  UserProfileSelectors.getIsStandardUserType,
  (isStandardUser): boolean => isStandardUser
);

/**
 * Get boolean determining whether can view secondary nav.
 */
export const getCanViewSecondaryNav = createSelector(
  UserProfileSelectors.getIsStandardUserType,
  (isStandardUser): boolean => isStandardUser
);
