import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommonActions, UNTITLED, selectNestedRouteParams } from '@surecloud/common';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { EntityPermissionService } from '../../../../services/permission/permission-entity/permission-entity.service';
import { EntityActions } from '../../../actions/entity.actions';
import { EntityPermissionActions } from '../../../actions/permission-entity.actions';

/**
 * The Effects/side effects for a Entity Permission.
 * @export
 * @class EntityStatePermissionEffects
 */
@Injectable({ providedIn: 'root' })
export class EntityStatePermissionEffects {
  /**
   * When all entity view data has loaded successfully,
   * Then populate the Entity permission state.
   * @memberof EntityStatePermissionEffects
   */
  loadEntityPermissionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        EntityPermissionActions.readEntityPermissionListSuccess({ permissions: normalisedEntityList.permissions })
      )
    )
  );

  /**
   * When an entity permission create is triggered,
   * Then create the entity permission via the API.
   * @memberof EntityStatePermissionEffects
   */
  createEntityPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityPermissionActions.createEntityPermission),
      withLatestFrom(this.store.select(selectNestedRouteParams)),
      switchMap(([, { entityId }]) =>
        this.entityPermissionService.create({ entityId }).pipe(
          map((entityPermission) => EntityPermissionActions.createEntityPermissionSuccess({ entityPermission })),
          catchError((error: unknown) =>
            of(EntityPermissionActions.createEntityPermissionFailure({ error: `${error}` }))
          )
        )
      )
    )
  );
  /**
   * When an entity permission update is triggered,
   * Then update the entity permission via the API.
   * @memberof EntityStatePermissionEffects
   */
  updateEntityPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityPermissionActions.updateEntityPermission),
      switchMap(({ entityPermission }) =>
        this.entityPermissionService.update(entityPermission).pipe(
          map((updatedEntityPermission) =>
            EntityPermissionActions.updateEntityPermissionSuccess({ entityPermission: updatedEntityPermission })
          ),
          catchError((error: unknown) =>
            of(EntityPermissionActions.updateEntityPermissionFailure({ error: `${error}` }))
          )
        )
      )
    )
  );

  /**
   * When a user starts the delete Entity Permission journey by clicking delete Entity Permission.
   * Then open the confirm delete Entity Permission modal.
   * @memberof EntityAttributeEffects
   */
  enterDeleteEntityPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityPermissionActions.startDeleteEntityPermission),
      map(({ entityPermission }) => {
        const title = $localize`Confirm Delete Entity Permission`;
        const content = $localize`Are you sure you want to delete the ${
          entityPermission?.role?.name || UNTITLED
        } entity permission?`;

        const confirmAction = EntityPermissionActions.deleteEntityPermission({ entityPermission });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels or confirms the deletion of an Entity Permission.
   * Then close the confirm delete Entity Permission modal.
   * @memberof EntityAttributeEffects
   */
  leaveDeleteEntityPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityPermissionActions.deleteEntityPermission),
      map(CommonActions.closeConfirmationModal)
    )
  );

  /**
   * When a user wants to delete an entity permission.
   * Then delete that entity through the API.
   * @memberof EntityAttributeEffects
   */
  deleteEntityPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityPermissionActions.deleteEntityPermission),
      switchMap(({ entityPermission }) =>
        this.entityPermissionService
          .delete({
            entityId: entityPermission.entityId,
            entityPermissionId: entityPermission.entityPermissionId,
          })
          .pipe(
            map((entityPermissionId) => EntityPermissionActions.deleteEntityPermissionSuccess(entityPermissionId)),
            catchError((error: unknown) =>
              of(EntityPermissionActions.deleteEntityPermissionFailure({ error: `${error}` }))
            )
          )
      )
    )
  );

  /**
   * Creates an instance of EntityStatePermissionEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {EntityPermissionService} entityPermissionService - Permission service
   * @param {Store} store - Global ngrx store
   * @memberof EntityStatePermissionEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly entityPermissionService: EntityPermissionService,
    private readonly store: Store
  ) {}
}
