/**
 * Question Set interface.
 *
 * @export
 * @interface QuestionSetInterface
 */
export interface QuestionSetInterface {
  attributeId: string | null;
  entityId: string;
  name: string | null;
  questionSetId: string;
  reviewerRoleIds: string[];
}

/**
 * Utility function to create a Question Set.
 *
 * @param {string} questionSetId The ID of the Question Set.
 * @param {string} entityId The entity ID the Question Set belongs to.
 * @param {Partial<Omit<QuestionSetInterface, 'questionSetId' | 'entityId'>>} options Additional properties to apply to the Question Set.
 * @return {QuestionSetInterface} A Question Set model.
 */
export const makeQuestionSet = (
  questionSetId: string,
  entityId: string,
  options: Partial<Omit<QuestionSetInterface, 'questionSetId' | 'entityId'>> = {}
): QuestionSetInterface => ({
  attributeId: options.attributeId || null,
  entityId,
  name: options.name || null,
  questionSetId,
  reviewerRoleIds: options.reviewerRoleIds || [],
});

/**
 * Checks if object is an Question Set.
 *
 * @param {unknown} value The object to check.
 * @return {boolean} Whether the unknown object is a Question Set.
 */
export const isQuestionSet = (value: unknown): value is QuestionSetInterface => {
  const questionSet = value as QuestionSetInterface;

  return !!questionSet?.questionSetId && !!questionSet.entityId;
};
