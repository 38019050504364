<section class="sc-feedback-section flex flex-column align-items-center sc-pt-6">
  <sc-icon [width]="163" [height]="112" class="sc-mb-16 sc-mt-40" key="feedback-success"></sc-icon>
  <div class="sc-font-heading-2xl sc-mb-20 sc-text">{{ heading }}</div>
  <p class="sc-feedback-section__message sc-font-table-entry sc-text sc-mb-24 flex justify-items-center">
    {{ message }}
  </p>
  <button
    *ngIf="buttonText"
    sc-text-button
    themeColour="brand"
    size="medium"
    fillMode="fill"
    class="sc-mt-24"
    (click)="buttonClick.emit()">
    {{ buttonText }}
  </button>
</section>
