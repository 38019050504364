import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxComponent, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CommonIconModule } from '../../icon/icons/common-icon.module';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { ControlValueAccessorConnector } from '../../utils/classes/control-value-accessor';
import { UserAvatarType } from '../user-avatar.model';

/**
 * Wrapper component around the [Kendo UI ComboBox](https://www.telerik.com/kendo-angular-ui/components/dropdowns/combobox)
 * @example
 * <sc-user-avatar-select
 *  [label]="label"
 *  [formControl]="formControl"
 *  [placeholder]="placeholder"
 *  [description]="description"
 *  [options]="options"></sc-user-avatar-select>
 * @export
 * @class UserAvatarSelectComponent
 * @extends {ControlValueAccessorConnector<string>}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-user-avatar-select',
  templateUrl: './user-avatar-select.component.html',
  styleUrls: ['./user-avatar-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DropDownsModule,
    LabelModule,
    InputsModule,
    ReactiveFormsModule,
    CommonIconModule,
    UserAvatarComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserAvatarSelectComponent),
      multi: true,
    },
  ],
})
export class UserAvatarSelectComponent extends ControlValueAccessorConnector<string> implements OnChanges {
  /**
   * The label text for the select dropdown.
   * @type {string}
   * @memberof UserAvatarSelectComponent
   */
  @Input() label!: string;

  /**
   * Sets the select dropdown readonly mode.
   * @memberof UserAvatarSelectComponent
   */
  @Input() readonly = false;

  /**
   * Text to display an additional description to the user.
   * @type {string}
   * @memberof UserAvatarSelectComponent
   */
  @Input() description!: string;

  /**
   * Sets if we want a success validation style to display.
   * @memberof UserAvatarSelectComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Text to display in dropdown input.
   * @type {string}
   * @memberof UserAvatarSelectComponent
   */
  @Input() placeholder!: string;

  /**
   * The full list of items [UserAvatarType] in the select dropdown.
   * @type {UserAvatarType[]}
   * @memberof UserAvatarSelectComponent
   */
  @Input() options: UserAvatarType[] = [];

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof UserAvatarSelectComponent
   */
  @Input() staticWidth!: string;

  /**
   * Emmits string typed on user select
   * @memberof UserAvatarSelectComponent
   */
  @Output() filterChange = new EventEmitter<string>();

  @ViewChild(ComboBoxComponent, { static: true }) dropdown!: ComboBoxComponent;

  /**
   * Keep track of when the popup is open or closed so we can apply styling.
   * @memberof UserAvatarSelectComponent
   */
  public popupOpen: boolean | undefined;

  /**
   * The selected user.
   * @type {UserAvatarType | undefined}
   * @memberof UserAvatarSelectComponent
   */
  public selectedUser!: UserAvatarType | undefined;

  /**
   * Set user if control has value
   * @memberof UserAvatarSelectComponent
   */
  ngOnChanges(): void {
    UserAvatarSelectComponent.setCleanButtonTooltip();

    if (this.options.length && this.control.value) {
      this.selectedUser = this.options.find(({ value }) => value === this.control.value);
    }
  }

  /**
   * Set selected user when changes
   * @param {UserAvatarType} user
   * @memberof UserAvatarSelectComponent
   */
  public selectionChange(user: UserAvatarType): void {
    this.selectedUser = user;
    UserAvatarSelectComponent.setCleanButtonTooltip();
  }

  /**
   * Check if type value is valid if not clean control value
   * @memberof UserAvatarSelectComponent
   */
  public valueValidation(): void {
    const isValid = this.options.some(({ value }) => value === this.control.value);
    if (!isValid) {
      this.control.setValue(null);
    }
  }

  /**
   * Toggle the combobox as the status parameter
   * @param {boolean} status
   * @memberof UserAvatarSelectComponent
   */
  public toggle(status: boolean): void {
    this.popupOpen = status;
    this.dropdown.toggle(status);
  }

  /**
   * Set the tooltip text generate by kendo UI
   * @static
   * @memberof UserAvatarSelectComponent
   */
  static setCleanButtonTooltip(): void {
    const element = document.getElementsByClassName('k-clear-value')[0] as HTMLElement;
    if (element) {
      element.setAttribute('title', 'Clean');
    }
  }
}
