import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { SectionRendererInterface, ViewContainerDirective } from '@surecloud/common';
import { Subject } from 'rxjs';
import { BaseSectionComponent } from './section-renderer-base.component';

/**
 * Render a Attribute Section Component dynamically.
 * @export
 * @class SectionRendererComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'sc-section-renderer',
  templateUrl: './section-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ViewContainerDirective],
})
export class SectionRendererComponent implements OnChanges, OnDestroy {
  /**
   * When the component is destroyed.
   * Then emit on this stream so that other observables can tear down.
   * @private
   * @type {Subject<boolean>}
   * @memberof SectionRendererComponent
   */
  private destroyed$: Subject<boolean> = new Subject();

  /**
   * The Section Config to dynamically render a section.
   * @type {(SectionRendererInterface | undefined)}
   * @memberof SectionRendererComponent
   */
  @Input() configSection: SectionRendererInterface | undefined;

  /**
   * sectionComponentMap
   *
   * a Map of section components,
   * to enable it to render dynamically inside here.
   */
  @Input() sectionComponentMap = new Map<string, Type<BaseSectionComponent>>();

  /**
   * View Container Directive that will provide the host element to insert the dynamically created component into.
   * @type {ViewContainerDirective}
   * @memberof SectionRendererComponent
   */
  @ViewChild(ViewContainerDirective, { static: true }) view!: ViewContainerDirective;

  /**
   * Reference to the component that is created so that we can destroy it at will.
   * @type {(ComponentRef<BaseSectionComponent> | undefined)}
   * @memberof SectionRendererComponent
   */
  componentRef: ComponentRef<BaseSectionComponent> | undefined;

  /**
   * When any changes are made to the component inputs.
   * Then destroy any reference to a previsouly created dynamic component.
   * And create a new dynamic component from scratch.
   * @param {SimpleChanges} changes The Angular simple changes interface.
   * @memberof SectionRendererComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef && changes['configSection']) {
      this.componentRef.destroy();
    }
    this.createComponent();
  }

  /**
   * When the component is destroyed.
   * Then emit on this stream so that other observables can tear down.
   * @memberof SectionRendererComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * Create a component and set input values.
   * @private
   * @memberof SectionRendererComponent
   */
  private createComponent(): void {
    if (!this.configSection) return;
    const { sectionComponent, sectionName, sectionId } = this.configSection;
    const componentType = this.sectionComponentMap.get(sectionComponent);
    if (componentType) {
      this.componentRef = this.view.viewContainerRef.createComponent(componentType);
      this.componentRef.instance.sectionName = sectionName;
      this.componentRef.instance.sectionId = sectionId;
    }
  }
}
