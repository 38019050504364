import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import { filter } from 'rxjs';
import * as QuestionSetSelectors from '../selectors/question-set/question-set.selectors';
import { isQuestionSet, QuestionSetInterface } from '../models/question-set/question-set.models';

/**
 * The facade for the Question Set State sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 * @export
 * @class QuestionSetStateFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionSetStateFacade extends BaseFacade {
  /**
   * The current selected Question Set.
   * @memberof QuestionSetStateFacade
   */
  selectedQuestionSet$ = this.store.pipe(
    select(QuestionSetSelectors.getSelectedQuestionSet),
    filter((questionSet): questionSet is QuestionSetInterface => isQuestionSet(questionSet))
  );

  /**
   * Array of Question Sets.
   * @memberof QuestionSetStateFacade
   */
  questionSets$ = this.store.pipe(select(QuestionSetSelectors.getQuestionSets));

  /**
   * Boolean to determine if the Question Set(s) have been loaded.
   * @memberof QuestionSetStateFacade
   */
  loaded$ = this.store.pipe(select(QuestionSetSelectors.getQuestionSetLoaded));

  /**
   * Creates an instance of QuestionSetStateFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof QuestionSetStateFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
