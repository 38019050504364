<toolbar-feature>
  <sc-page maxWidth="medium">
    <sc-title-description
      heading="My Applications"
      i18n-heading
      description="The table contains all applications you have access to."
      i18n-description></sc-title-description>
    <ng-container *ngIf="homepageApplicationsFeatureFacade.loaded$ | async">
      <sc-section classname="sc-mb-0">
        <!-- The component is named this because its reused from the homepage applications -->
        <sc-homepage-grid
          key="no-applications"
          [height]="450"
          [activeTab]="(homepageApplicationsFeatureFacade.getActiveTab$ | async) || 0"
          [tabs]="(homepageApplicationsFeatureFacade.getTabs$ | async) || []"
          [rowData]="(homepageApplicationsFeatureFacade.homepageApplicationForGrid$ | async) || []"
          [columnDefs]="homepageApplicationsColDefs"
          (activeTabChange)="changeTab($event)"
          (clicked)="entityCellClicked($event)"
          heading="You don't have any apps installed"
          i18n-heading
          i18n-body>
        </sc-homepage-grid>
      </sc-section>
    </ng-container>
  </sc-page>
</toolbar-feature>
