import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FEATURE_FLAG_FEATURE_KEY, featureFlagAdapter, StateInterface } from '../reducers/feature-flag.reducer';

// Lookup the Feature Flag feature state managed by NgRx
export const getFeatureFlagState = createFeatureSelector<StateInterface>(FEATURE_FLAG_FEATURE_KEY);

const { selectEntities } = featureFlagAdapter.getSelectors();

/**
 * Standard state selectors
 */
export const getFeatureFlagEntities = createSelector(getFeatureFlagState, (state: StateInterface) =>
  selectEntities(state)
);
export const getFeatureFlagLoaded = createSelector(getFeatureFlagState, (state: StateInterface) => state.loaded);

export const isFeatureFlagEnabled = (
  key: string
): MemoizedSelector<object, boolean, (s1: Dictionary<any>) => boolean> =>
  createSelector(getFeatureFlagEntities, (entities) => entities[key] || false);
