import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RoleInterface } from '@surecloud/common';
import { RoleStateActions } from '../actions/role.actions';

export const ROLE_FEATURE_KEY = 'role';

export interface StateInterface extends EntityState<RoleInterface> {
  selectedId?: string;
  loaded: boolean;
  error?: string | null;
}

export interface RolePartialStateInterface {
  readonly [ROLE_FEATURE_KEY]: StateInterface;
}

export const roleAdapter: EntityAdapter<RoleInterface> = createEntityAdapter<RoleInterface>({
  selectId: (role: RoleInterface) => role.roleId,
});

export const initialState: StateInterface = roleAdapter.getInitialState({
  loaded: false,
});

/**
 * Role reducer.
 */
const roleReducer = createReducer(
  initialState,

  on(RoleStateActions.createRoleSuccess, (state: StateInterface, { role }) => roleAdapter.addOne(role, state)),
  on(RoleStateActions.createRoleFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RoleStateActions.readRoleList, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(RoleStateActions.readRoleListSuccess, (state: StateInterface, { roles }) =>
    roleAdapter.setAll(roles, { ...state, loaded: true })
  ),
  on(RoleStateActions.readRoleListFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RoleStateActions.readRoles, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(RoleStateActions.readRolesSuccess, (state: StateInterface, { roles }) =>
    roleAdapter.upsertMany(roles, { ...state, loaded: true })
  ),
  on(RoleStateActions.readRolesFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RoleStateActions.readRole, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(RoleStateActions.readRoleSuccess, (state: StateInterface, { role }) =>
    roleAdapter.upsertOne(role, { ...state, loaded: true })
  ),
  on(RoleStateActions.readRoleFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RoleStateActions.deleteRole, (state: StateInterface, { role }) => roleAdapter.removeOne(role.roleId, state)),
  on(RoleStateActions.deleteRoleFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RoleStateActions.updateRole, (state: StateInterface, { role }) =>
    roleAdapter.updateOne({ id: role.roleId, changes: role }, state)
  ),
  on(RoleStateActions.updateRoleFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),

  on(RoleStateActions.selectRole, (state: StateInterface, { roleId }: { roleId: string | undefined }) => ({
    ...state,
    selectedId: roleId,
  }))
);

/**
 * Perform reducer logic on the role NGRX state store for a specific role action.
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX role action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return roleReducer(state, action);
}
