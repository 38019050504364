<kendo-popup
  #popup
  positionMode="absolute"
  popupClass="sc-user-menu"
  [offset]="position"
  [animate]="false"
  [margin]="margin"
  *ngIf="overlay.stream$ | async as position">
  <div class="sc-px-16 sc-pt-24">
    <ng-container *ngIf="config; else error">
      <div class="flex align-items-center design-v2-border-bottom sc-pb-24 sc-mb-16">
        <sc-user-avatar
          [hexColour]="config.colour"
          [initials]="config.initials"
          *ngIf="config.initials"></sc-user-avatar>
        <div class="sc-ml-8 flex-grow-1 min-w-0">
          <h3
            class="sc-font-heading-md sc-text white-space-nowrap overflow-hidden text-overflow-ellipsis"
            *ngIf="config.name">
            {{ config.name }}
          </h3>
          <p
            class="sc-font-body-text sc-text white-space-nowrap overflow-hidden text-overflow-ellipsis"
            *ngIf="config.email">
            {{ config.email }}
          </p>
        </div>
      </div>
      <div class="flex sc-pb-16">
        <button i18n sc-text-button fillMode="text" class="w-full" (click)="logout.emit()">Log out</button>
      </div>
    </ng-container>
    <ng-template #error>
      <p i18n>No user menu configuration to display</p>
    </ng-template>
  </div>
</kendo-popup>
