import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CommonActions,
  CommonRoutesEnum,
  ENTITY_BREADCRUMB_ROUTE,
  EntityAttributeInterface,
  EntityAttributeIntersectionInterface,
  EntityInterface,
  LoggerService,
  OptionalExcluding,
  UNTITLED,
  fromPageState,
  getEntityBreadcrumb,
} from '@surecloud/common';
import { catchError, filter, first, map, mergeMap, of, switchMap, takeUntil, tap } from 'rxjs';
import { EntityAttributeService } from '../../../services/attribute/attribute.service';
import { EntityAttributeActions } from '../../actions/attribute.actions';
import { EntityActions } from '../../actions/entity.actions';
import * as EntityAttributeSelectors from '../../selectors/attribute/attribute.selectors';
import * as EntitySelectors from '../../selectors/entity/entity.selectors';

/**
 * The Effects/side effects for an Entity's Attributes.
 * @export
 * @class EntityStateAttributeEffects
 */
@Injectable({ providedIn: 'root' })
export class EntityStateAttributeEffects {
  /**
   * When all entity data is loaded successfully,
   * Then populate the attributes state.
   * @memberof EntityStateAttributeEffects
   */
  loadEntityAttributeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readAllEntityDataSuccess, EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        EntityAttributeActions.readAttributeListSuccess({ attributeList: normalisedEntityList.attributes })
      )
    )
  );

  /**
   * When a user clicks delete attribute button.
   * Then show Delete Attribute view.
   * @memberof EntityStateAttributeEffects
   */
  enterDeleteEntityAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.startDeleteAttribute),
      map(({ attribute }) => {
        const title = $localize`Confirm Delete Attribute`;
        const content = $localize`Are you sure you want to delete the ${attribute.name || UNTITLED} attribute?`;

        const confirmAction = EntityAttributeActions.completeDeleteAttribute({ attribute });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels or comples the delete attribute user journey.
   * Then close the confirm delete attribute modal.
   * @memberof EntityStateAttributeEffects
   */
  leaveDeleteEntityAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.completeDeleteAttribute),
      map(CommonActions.closeConfirmationModal)
    )
  );

  /**
   * When a user has confirmed to delete an entity attribute.
   * Then delete that attribute through the API.
   * @memberof EntityStateAttributeEffects
   */
  deleteEntityAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.completeDeleteAttribute),
      switchMap(({ attribute }) =>
        this.attributeService.delete(attribute).pipe(
          map((attributeId) => EntityAttributeActions.deleteAttributeSuccess(attributeId)),
          catchError((error: unknown) => of(EntityAttributeActions.deleteAttributeFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the user saves the create attribute view,
   * Then create a attribute on an entity.
   * @memberof EntityStateAttributeEffects
   */
  createEntityAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.createAttribute),
      switchMap(({ attribute, requestId }) =>
        this.attributeService.create(attribute, requestId).pipe(
          map((updatedAttribute) => EntityAttributeActions.createAttributeSuccess({ attribute: updatedAttribute })),
          catchError((error: unknown) => of(EntityAttributeActions.createAttributeFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When an attriubte is created,
   * Then navigate to the attribute view.
   * @memberof EntityStateAttributeEffects
   */
  entityAttributeCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.createAttributeSuccess),
      map(({ attribute }) => EntityAttributeActions.navigateToAttribute({ attributeId: attribute.attributeId }))
    )
  );

  /**
   * When the user saves the update attribute view,
   * Then update an attribute on an entity.
   * @memberof EntityStateAttributeEffects
   */
  updateEntityAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.updateAttributeFromPage, EntityAttributeActions.completeSelectAttributeType),
      mergeMap(({ attribute }) => {
        const params: OptionalExcluding<EntityAttributeIntersectionInterface, 'attributeId' | 'entityId'> = attribute;
        return this.attributeService.update(params).pipe(
          map((attributeUpdate) => EntityAttributeActions.updateAttributeSuccess(attributeUpdate)),
          catchError((error: unknown) => of(EntityAttributeActions.updateAttributeFailure({ error: `${error}` })))
        );
      })
    )
  );

  /**
   * When a user changes the attribute type that has validation rules.
   * Then open a modal to confirm a user wishes to delete those rules by changing attribute type.
   * @memberof EntityStateAttributeEffects
   */
  enterConfirmAttributeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.startSelectAttributeType),
      map(({ attribute, attributeType }) => {
        const title = $localize`Confirm Attribute Type Change`;
        const content = $localize`Are you sure you want to change the ${
          attribute.name || UNTITLED
        } attribute type? Changing the attribute type will remove all validation rules and formats on this attribute.`;

        const confirmAction = EntityAttributeActions.completeSelectAttributeType({
          attribute: {
            ...attribute,
            type: attributeType,
          },
        });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels changing the attribute type.
   * Then revert the attribute type.
   * @memberof EntityStateAttributeEffects
   */
  updateAttributeFromEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.completeSelectAttributeType),
      map(({ attribute }) => EntityAttributeActions.updateAttributeFromEffect({ attribute }))
    )
  );

  /**
   * When a user confirms the change to attribute type.
   * Then close the change attribute type confirmation modal.
   * @memberof EntityStateAttributeEffects
   */
  leaveConfirmAttributeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.completeSelectAttributeType),
      map(CommonActions.closeConfirmationModal)
    )
  );

  /**
   * Logs any errors.
   * @memberof EntityStateAttributeEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EntityAttributeActions.deleteAttributeFailure,
          EntityAttributeActions.createAttributeFailure,
          EntityAttributeActions.updateAttributeFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Entity Attribute', type, error))
      ),
    { dispatch: false }
  );

  /**
   * When a user confirms changing then entity attribute type.
   * Then update the attribute type.
   * @memberof EntityStateAttributeEffects
   */
  selectEntityAttributeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.completeSelectAttributeType),
      map(({ attribute }) => EntityAttributeActions.selectAttributeType({ attributeType: attribute.type }))
    )
  );

  resetRecordsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.attributeLeave),
      map(() => EntityAttributeActions.reset())
    )
  );

  /**
   * Set the Entity Attribute Breadcrumb State.
   * @memberof EntityStateAttributeEffects
   */
  setEntityAttributeBreadcrumbState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.attributeEnter),
      switchMap(() =>
        this.store.select(EntitySelectors.getSelected).pipe(
          filter((entity): entity is EntityInterface => !!entity),
          first()
        )
      ),
      switchMap((entity) =>
        this.store.select(EntityAttributeSelectors.getSelectedAttribute).pipe(
          filter((attribute): attribute is EntityAttributeInterface => !!attribute),
          map((attribute) => ({ attribute, entity })),
          takeUntil(this.actions$.pipe(ofType(EntityAttributeActions.attributeLeave)))
        )
      ),
      map(({ attribute, entity }) => {
        const entityBreadcrumb = getEntityBreadcrumb(entity);

        return fromPageState.BreadcrumbActions.setBreadcrumbs({
          breadcrumbs: [
            ENTITY_BREADCRUMB_ROUTE,
            {
              ...entityBreadcrumb,
              label: $localize`${entityBreadcrumb.label} Attributes`,
              route: [...ENTITY_BREADCRUMB_ROUTE.route, entity.entityId, CommonRoutesEnum.AttributeList],
            },
            {
              id: attribute.attributeId,
              label: attribute.name,
            },
          ],
        });
      })
    )
  );

  /**
   * When a user has left the Entity Attribute.
   * Then dispatch an action to reset the breadcrumb page state
   * @memberof EntityStateAttributeEffects
   */
  resetEntityAttributeBreadcrumbState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityAttributeActions.attributeLeave),
      map(() => fromPageState.BreadcrumbActions.resetBreadcrumbs())
    )
  );

  /**
   * Creates an instance of EntityStateAttributeEffects.
   * @param {Actions} actions$ The NGRX effect actions.
   * @param {EntityAttributeService} attributeService The entity attribute service.
   * @param {LoggerService} logger The application logging service.
   * @param {Store} store The NGRX Store.
   * @memberof EntityStateAttributeEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly attributeService: EntityAttributeService,
    private readonly logger: LoggerService,
    private readonly store: Store
  ) {}
}
