import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoleInterface, UNTITLED } from '@surecloud/common';
import { EntityPermissionInterface } from '../../../models/permission/permission-entity/permission-entity.models';
import {
  ENTITY_PERMISSION_FEATURE_KEY,
  StateInterface,
  entityPermissionAdapter,
} from '../../../reducers/permission/permission-entity/permission-entity.reducer';
import * as EntitySelectors from '../../entity/entity.selectors';

// Lookup the 'Entity Permission' feature state managed by NgRx
export const getEntityPermissionState = createFeatureSelector<StateInterface>(ENTITY_PERMISSION_FEATURE_KEY);

const { selectAll, selectEntities } = entityPermissionAdapter.getSelectors();

/**
 * Get all Entity Permissions.
 */
export const getEntityPermissionList = createSelector(getEntityPermissionState, (state: StateInterface) =>
  selectAll(state)
);

/**
 * Get the Entity Permission NGRX entity map from Entity Permission state.
 */
export const getEntityPermissionEntities = createSelector(getEntityPermissionState, (state: StateInterface) =>
  selectEntities(state)
);

/**
 * Get the Entity Roles to be selected in the Workflow Trigger page
 * In an interface that can be given to the multiselect dropdown.
 */
export const getSelectedEntityRoleOptions = createSelector(
  EntitySelectors.getSelectedId,
  getEntityPermissionList,
  (selectedId, entityPermissionList) =>
    entityPermissionList
      .filter((permission) => permission.entityId === selectedId)
      .map(({ role }) => ({
        text: role?.name || UNTITLED,
        value: role?.roleId,
      }))
);

/**
 * Get the Entity Roles to be selected in the Workflow Trigger page
 * In an interface that can be given to the multiselect dropdown.
 */
export const getSelectedEntityRolePermissions = createSelector(
  EntitySelectors.getSelectedId,
  getEntityPermissionList,
  (selectedId, entityPermissionList) => entityPermissionList.filter((permission) => permission.entityId === selectedId)
);

/**
 * Helper function to ensure the role is not undefined or null
 * @param {EntityPermissionInterface} value the Entity Permission data
 * @return {*} {value is SafePermissionInterface}
 */
const isRoleValid = (value: EntityPermissionInterface): value is SafePermissionInterface => value.role !== null;

/**
 *  Safe interface with a role defined
 */
type SafePermissionInterface = EntityPermissionInterface & {
  role: RoleInterface;
};

/**
 * Gets a list of roles which the selected entity has been assigned
 */
export const getAvailableSelectedEntityRoles = createSelector(
  EntitySelectors.getSelectedId,
  getEntityPermissionList,
  (selectedId, entityPermissionList) =>
    entityPermissionList
      .filter((permission) => permission.entityId === selectedId)
      .flatMap((permission) => {
        if (!isRoleValid(permission)) {
          return [];
        }
        return permission.role;
      })
);
