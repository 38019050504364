import { Injectable } from '@angular/core';
import {
  LinkedRecordCreateInputApiInterface,
  LinkedRecordCreateMutationApiInterfaceMutationService,
  LinkedRecordsApiInterfaceQueryService,
  LinkedRecordsInputApiInterface,
  RecordUnlinkInputApiInterface,
  RecordUnlinkMutationApiInterfaceMutationService,
} from '@surecloud/api-types';
import { RecordInterface, makeContextWithRequestIdHeader } from '@surecloud/common';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { TableRecordLinkInterface } from '../normalise-get-link-records/get-link.records.interface';
import { NormaliseGetLinkedRecordsService } from '../normalise-get-link-records/normalise-get-link-records.service';

/**
 * Linked Records API service.
 * @export
 * @class LinkedRecordService
 */
@Injectable({
  providedIn: 'root',
})
export class LinkedRecordService {
  /**
   * Creates an instance of LinkedRecordService.
   * @param {RecordUnlinkMutationApiInterfaceMutationService} recordUnlinkMutationApiInterfaceMutationService The service for unlinking records.
   * @param {LinkedRecordsApiInterfaceQueryService} linkedRecordsApiInterfaceQueryService The service for fetching linked records
   * @param {LinkedRecordCreateMutationApiInterfaceMutationService} linkedRecordCreateMutationApiInterfaceMutationService The service for linked record creation
   * @param {NormaliseGetLinkedRecordsService} normaliseGetLinkedRecordsService The linked record normalise service.
   * @memberof LinkedRecordService
   */
  constructor(
    private readonly recordUnlinkMutationApiInterfaceMutationService: RecordUnlinkMutationApiInterfaceMutationService,
    private readonly linkedRecordsApiInterfaceQueryService: LinkedRecordsApiInterfaceQueryService,
    private readonly linkedRecordCreateMutationApiInterfaceMutationService: LinkedRecordCreateMutationApiInterfaceMutationService,
    private readonly normaliseGetLinkedRecordsService: NormaliseGetLinkedRecordsService
  ) {}

  /**
   * Query for all linked records by a record
   * @param {LinkedRecordsInputApiInterface} linkedRecordsInput The linked record read API input data.
   * @return {Observable<TableRecordLinkInterface>} The linked record data.
   * @memberof LinkedRecordService
   */
  read(linkedRecordsInput: LinkedRecordsInputApiInterface): Observable<TableRecordLinkInterface> {
    return this.linkedRecordsApiInterfaceQueryService
      .fetch({ input: linkedRecordsInput })
      .pipe(
        switchMap(({ data }) =>
          data?.linkedRecords
            ? of(
                this.normaliseGetLinkedRecordsService.flatten(
                  linkedRecordsInput.tabId as string,
                  data.linkedRecords.records as RecordInterface[],
                  true,
                  data.linkedRecords.viewerCanCreate
                )
              )
            : throwError(
                () =>
                  `No response data from get Linked Records from Record ${linkedRecordsInput.recordId} returned from API`
              )
        )
      );
  }

  /**
   * Unlink's records to a record
   * @param {RecordUnlinkInputApiInterface} unlinkRecords The unlink records API input data.
   * @param {string | null} requestId The requestId.
   * @return {Observable<{ recordId: string }>} The record ID.
   * @memberof LinkedRecordService
   */
  unlink(
    unlinkRecords: RecordUnlinkInputApiInterface,
    requestId: string | null = null
  ): Observable<{ recordId: string }> {
    return this.recordUnlinkMutationApiInterfaceMutationService
      .mutate({ input: unlinkRecords }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.recordUnlink?.record
            ? of({ recordId: data.recordUnlink.record.recordId })
            : throwError(() => 'No unlink record response data from unlink record returned from API')
        )
      );
  }

  /**
   * Creates a new Linked Record
   * @param {LinkedRecordCreateInputApiInterface} linkedRecordCreateInput The link record Input.
   * @param {string | null} requestId The requestId.
   * @return {*}  {Observable<RecordInterface>}
   * @memberof LinkedRecordService
   */
  create(
    linkedRecordCreateInput: LinkedRecordCreateInputApiInterface,
    requestId: string | null = null
  ): Observable<RecordInterface> {
    return this.linkedRecordCreateMutationApiInterfaceMutationService
      .mutate({ input: linkedRecordCreateInput }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.linkedRecordCreate?.record
            ? of({
                recordId: data.linkedRecordCreate.record.recordId,
                attributes: [],
                viewerCanDelete: data.linkedRecordCreate.record.viewerCanDelete,
              })
            : throwError(
                () =>
                  `No response data from Create Linked Records from Record ${linkedRecordCreateInput.recordId} and Link ${linkedRecordCreateInput.linkId} returned from API`
              )
        )
      );
  }
}
