import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SESSION_STORAGE, WINDOW } from '@ng-web-apis/common';

/**
 * A service to store and retrieve the return URI.
 */
@Injectable({
  providedIn: 'root',
})
export class ReturnURIService {
  /**
   * The key used to store the return URI in session storage.
   */
  private readonly STORAGE_KEY = 'aurora_return_uri';

  /**
   * Constructor
   * @param {Window} windowRef The window object.
   * @param {Storage} sessionStorageRef The session storage object.
   * @param {Router} router the Angular router for building the URL tree.
   */
  constructor(
    @Inject(WINDOW) private windowRef: Window,
    @Inject(SESSION_STORAGE) private sessionStorageRef: Storage,
    private router: Router
  ) {}

  /**
   * Return the stored URL as a URL Tree if found.
   * @return {UrlTree | boolean} The URL tree or false if no return URI is found.
   */
  asUrlTree(): UrlTree | boolean {
    const returnUri = this.sessionStorageRef.getItem(this.STORAGE_KEY);
    if (returnUri) {
      const urlTree = this.router.createUrlTree([returnUri]);
      this.sessionStorageRef.removeItem(this.STORAGE_KEY);
      return urlTree;
    }
    return false;
  }

  /**
   * Stash the current URL in session storage.
   * @return {void}
   */
  stash(): void {
    const uri = this.windowRef.location.pathname;
    this.sessionStorageRef.setItem(this.STORAGE_KEY, uri);
  }
}
