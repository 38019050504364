import { CalculationComponent } from '../../calculation/calculation.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { calculationComponentCreator } from '../creators/calculation-creator.config';

/**
 * Control configuration for CALCULATION renderer component
 *
 */
export const CALCULATION_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: CalculationComponent,
  creatorFn: calculationComponentCreator,
};
