import { createAction, createActionGroup, props } from '@ngrx/store';
import { LinkedRecordsInputApiInterface, RecordLinkInputApiInterface } from '@surecloud/api-types';
import { TableRecordLinkInterface } from '../../services/normalise-get-link-records/get-link.records.interface';
import { RecordLinkInterface } from '../models/record-link/record-link.model';

/**
 * Record Linkable API actions map.
 */
const RECORD_LINKABLE_API_EVENTS_MAP = {
  'Read Linkable Record List': props<{ linkableRecordInputList: LinkedRecordsInputApiInterface[] }>(),
  'Read Linkable Record': props<{ linkableRecordInput: LinkedRecordsInputApiInterface }>(),
  'Read Linkable Records Data Success': props<{ normalisedRecordLink: TableRecordLinkInterface }>(),
  'Read Linkable Records Data Failure': props<{ error: string }>(),
  'Read Linkable Records Success': props<{ recordLink: RecordLinkInterface }>(),
  'Read Linkable Records Failure': props<{ error: string }>(),
  'Link Records': props<{ linkRecords: RecordLinkInputApiInterface; tabId: string }>(),
  'Link Records Success': props<{ recordsIds: string[]; tabId: string }>(),
  'Link Failure': props<{ error: string }>(),
};

/**
 * Record Linkable API actions.
 * @export
 */
export const RecordLinkableApiActions = createActionGroup({
  source: 'Record Linkable/API',
  events: RECORD_LINKABLE_API_EVENTS_MAP,
});

/**
 * Record Linkable actions
 */
const RecordLinkablePageActions = {
  selectLinkableRecordTab: createAction(
    '[Record View Table Page] Select Linkable Record Tab',
    props<{ tabId: string }>()
  ),
};

/**
 * Record Linkable API and Page actions.
 */
export const RecordLinkableActions = {
  ...RecordLinkableApiActions,
  ...RecordLinkablePageActions,
};
