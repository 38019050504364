import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade, UserProfilePermissionsEnum } from '@surecloud/common';
import { filter, Observable, tap } from 'rxjs';
import { UserProfileActions } from '../actions/user-profile.actions';
import { UserProfileInterface } from '../models/user-profile.models';
import * as UserProfileSelectors from '../selectors/user-profile.selectors';
import { UserProfilePermissionTypesEnum } from '../selectors/user-profile.selectors.enum';

/**
 * The facade for the User feature sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 * @export
 * @class UserProfileFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class UserProfileFeatureFacade extends BaseFacade {
  /**
   * The User Profile.
   * @memberof UserProfileFeatureFacade
   */
  userProfile$ = this.store.pipe(select(UserProfileSelectors.getUserProfile));

  /**
   * Boolean to determine if the User Profile have been loaded.
   * @memberof UserProfileFeatureFacade
   */
  loaded$ = this.store.pipe(select(UserProfileSelectors.getUserProfileLoaded));

  /**
   * The User Profile.
   * @memberof UserProfileFeatureFacade
   */
  firstName$ = this.store.pipe(select(UserProfileSelectors.getFirstName));

  /**
   * Boolean to answer if
   * @memberof UserProfileFeatureFacade
   */
  isStandardUser$ = this.store.pipe(select(UserProfileSelectors.getIsStandardUserType));

  /**
   * Creates an instance of UserFeatureFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof UserProfileFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }

  /**
   * Retrieves and waits for the user profile to load if necessary.
   * @memberof UserProfileFeatureFacade
   * @return {Observable<UserProfileInterface>} An Observable of the UserProfileInterface object.
   */
  getUserProfile(): Observable<UserProfileInterface> {
    return this.userProfile$.pipe(
      tap((userProfile) => {
        if (!userProfile) {
          this.dispatch(UserProfileActions.readUserProfile());
        }
      }),
      filter((userProfile): userProfile is UserProfileInterface => !!userProfile)
    );
  }

  /**
   * Observable stream to see if a User Profile has a specific permission.
   * @param {UserProfilePermissionTypesEnum} type The type of permission to check for - Create, Delete, Read.
   * @param {UserProfilePermissionsEnum} permission The permission to check for.
   * @return {boolean} If the User Profile has the permission.
   * @memberof UserProfileFeatureFacade
   */
  hasUserProfilePermission$ = (
    type: UserProfilePermissionTypesEnum,
    permission: UserProfilePermissionsEnum
  ): Observable<boolean> => this.store.pipe(select(UserProfileSelectors.hasUserProfilePermission(type, permission)));
}
