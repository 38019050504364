/**
 * JavaScript equivalent of the SCSS colour variables.
 */
export const COLOURS = {
  black: '#000',
  brand25: '#ecf9ff',
  neutral40: '#c4dae3',
  text: '#181818',
};

export const TEXT_BRAND_500 = 'sc-text-brand-500';
export const TEXT_RED_300 = 'sc-text-red-300';
