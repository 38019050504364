import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '../../facades/base.facade';
import * as PageStateSelectors from './page-state.selectors';

/**
 * The facade for the Page state is responsible for providing store observables to,
 * and dispatching actions from the view layer
 * @export
 * @class PageStateFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class PageStateFacade extends BaseFacade {

  /**
   * Observable to the breadcrumbs state.
   * @memberof PageStateFacade
   */
  breadcrumbs$ = this.store.pipe(select(PageStateSelectors.getBreadcrumbs));

  /**
   * Creates an instance of PageStateFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof PageStateFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
