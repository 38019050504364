import { scssVariables } from './colours';

/**
 * Find the key of a color in the scssVariables object
 * @param {string} color Color to find the key for
 * @return {string | null} The key of the color or null if not found
 */
export const findKeyByColor = (color: string): keyof typeof scssVariables | null => {
  const key = Object.keys(scssVariables).find(
    (colourKey: string) => scssVariables[colourKey as keyof typeof scssVariables].toLowerCase() === color.toLowerCase()
  ) as keyof typeof scssVariables;
  return key || null;
};
