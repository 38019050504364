<ng-container *ngIf="nodes.length; else empty">
  <ng-container *ngIf="previousNodes.length && currentNode">
    <div
      class="sc-select-hierarchy-nodes__current-node--clickable sc-font-body-text sc-text sc-p-10"
      tabindex="0"
      (click)="goToPreviousNode($event)"
      (keydown.enter)="goToPreviousNode()">
      <sc-icon class="sc-mr-12" key="chevron-left" colour="brand-500" [height]="10" [width]="5"></sc-icon>
      {{ currentNode.text }}
    </div>
    <div class="sc-select-hierarchy-nodes__divider"></div>
  </ng-container>
  <ul class="flex flex-column">
    <li
      class="sc-select-hierarchy-nodes__node flex align-items-center sc-p-10 sc-font-body-text sc-text"
      tabindex="0"
      [ngClass]="{ 'sc-select-hierarchy-nodes__node--selectable': node.nodes?.length }"
      (click)="selectNode(node, $event)"
      (keydown.enter)="selectNode(node)"
      *ngFor="let node of nodes; trackBy: trackByFunction">
      <ng-container *ngIf="node.isSelectable">
        <sc-radio-buttons
          [formControl]="form.controls[CONSTANTS.FORM_CONTROLS.SINGLE_SELECT]"
          [name]="name"
          [options]="[{ value: node.value }]"
          *ngIf="singleSelect; else multiSelect">
        </sc-radio-buttons>
        <ng-template #multiSelect>
          <sc-checkbox [formControl]="form.controls[node.id]" class="sc-py-4 sc-mr-8"></sc-checkbox>
        </ng-template>
      </ng-container>
      {{ node.text }}
      <sc-icon
        class="sc-ml-auto sc-mr-4"
        key="chevron-right"
        colour="grey-600"
        [height]="10"
        [width]="5"
        *ngIf="node.nodes?.length">
      </sc-icon>
    </li>
  </ul>
</ng-container>
<ng-template #empty>
  <p i18n class="sc-font-body-text sc-text sc-p-16">No data to show.</p>
</ng-template>
