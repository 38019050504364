import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UNTITLED } from '@surecloud/common';
import { DatePickerComponent } from '../../../date-picker/date-picker.component';
import { ValidationRendererDateControlConfigInterface } from '../validation-component-renderer-config.interface';
import { isValidationRendererDateControlComponentRef } from '../validation-component-renderer.guards';

/**
 * Dynamically creates a validation DropdownComponent with a form control
 * @param {ViewContainerRef} viewContainerRef - Represents a angular container where one or more views can be attached to a component
 * @param {ValidationRendererDateControlConfigInterface | undefined} componentData - Dynamic component data
 * @param {Type<DatePickerComponent>} component - Type of component to be dynamically created
 * @param {{ controlId: string; group: FormGroup } | undefined} control Form control to add to the dynamic component
 * @param {boolean} readonly - Render the control as readonly
 * @return {ComponentRef<DatePickerComponent> | undefined} - Dynamic Component
 */
export const validationDateControlComponentCreator = (
  viewContainerRef: ViewContainerRef,
  componentData: ValidationRendererDateControlConfigInterface | undefined,
  component: Type<DatePickerComponent>,
  control: { controlId: string; group: FormGroup } | undefined,
  readonly = false
): ComponentRef<DatePickerComponent> | undefined => {
  if (!componentData) return undefined;

  const { controlName, guidanceText, label } = componentData;
  const componentRef = viewContainerRef.createComponent(component);

  if (!isValidationRendererDateControlComponentRef(componentRef)) return undefined;

  componentRef.instance.description = guidanceText ?? UNTITLED;
  componentRef.instance.label = label ?? UNTITLED;
  componentRef.instance.readonly = readonly;

  if (!control) return componentRef;

  componentRef.instance.controlName = controlName;

  componentRef.instance.parentFormGroup = control.group;

  return componentRef;
};
