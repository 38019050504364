<div class="design-v2-sidebar-overlay" *ngIf="overlay.stream$ | async">
  <div class="design-v2-sidebar-overlay__background"></div>
  <section class="design-v2-sidebar-overlay__content sc-bg-white sc-pt-16 sc-pr-2 flex flex-column" #sidebar>
    <div class="design-v2-sidebar-overlay__title design-v2-border-bottom sc-px-16">
      <ng-content select="[sidebar-overlay-title]"></ng-content>
    </div>
    <div class="sc-pb-16 flex-grow-1">
      <ng-scrollbar>
        <div class="sc-pr-24">
          <ng-content select="[sidebar-overlay-content]"></ng-content>
        </div>
      </ng-scrollbar>
    </div>
  </section>
</div>
