import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import { Observable } from 'rxjs';
import * as FeatureFlagSelectors from '../selectors/feature-flag.selectors';

/**
 * The facade for the Feature Flag feature sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 * @export
 * @class FeatureFlagFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagFacade extends BaseFacade {
  /**
   * Observable stream to see if a feature is enabled.
   * @param {string} key The feature flag key. E.g. "NG-1111-[feature-name]"
   * @return {boolean} If the User Profile has the feature enabled.
   * @memberof FeatureFlagFacade
   */
  featureIsEnabled$ = (key: string): Observable<boolean> =>
    this.store.pipe(select(FeatureFlagSelectors.isFeatureFlagEnabled(key)));

  /**
   * Creates an instance of FeatureFlagFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof FeatureFlagFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
