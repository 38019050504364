import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropDownsModule, RemoveTagEvent } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { E2eHookDirective, SelectHierarchyNodeInterface } from '@surecloud/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject } from 'rxjs';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { SelectHierarchyNodesComponent } from './select-hierarchy-nodes.component';
import { getSelectHierarchyNodesAsFlatArray } from './select-hierarchy.shared';

/**
 * Surecloud Select Hierarchy Component.
 * @export
 * @class SelectHierarchyComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'sc-select-hierarchy',
  standalone: true,
  styleUrls: ['./select-hierarchy.component.scss'],
  templateUrl: './select-hierarchy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonIconModule,
    CommonModule,
    DropDownsModule,
    E2eHookDirective,
    FormsModule,
    InputsModule,
    LabelModule,
    NgScrollbarModule,
    PopupModule,
    SelectHierarchyNodesComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectHierarchyComponent implements OnChanges, OnDestroy {
  /**
   * Open state of the hierarchy dropdown menu.
   * @memberof SelectHierarchyComponent
   */
  private isOpen = false;

  /**
   * Observable stream to open and close the Select Hierachy Dropdown.
   * @type {BehaviorSubject<boolean>}
   * @memberof SelectHierarchyComponent
   */
  public open$: BehaviorSubject<boolean> = new BehaviorSubject(this.isOpen);

  /**
   * The native dropdown items for the kendo-dropdownlist.
   * We hide this list to display our own popup list that contains our hierarchy data.
   * But we do need this list as it relies on them to display the selected item(s) they have been set in this.values.
   * @type {SelectHierarchyNodeInterface[]}
   * @memberof SelectHierarchyComponent
   */
  public options: SelectHierarchyNodeInterface[] = [];

  /**
   * Optional hint description to display.
   * @type {(string | undefined)}
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public description: string | undefined;

  /**
   * Sets the select hierarchy disabled mode.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public disabled = false;

  /**
   * Are the Hierachy Node tags in the Multi Select Dropdown component removable?
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public isRemovable = true;

  /**
   * The label text for the select dropdown.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public label = '';

  /**
   * In order to support multiple single select hierarchy components on a page with radio buttons.
   * You must input a unique name for each component so that the radio buttons are grouped.
   * @type {string}
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public name = 'select-hierarchy';

  /**
   * The hierarchy node data to display.
   * @type {SelectHierarchyNodeInterface[]}
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public nodes: SelectHierarchyNodeInterface[] = [];

  /**
   * Placeholder text to display when there is no value for the select hierarchy.
   * Currently only works as an accessibility improvement as you cannot disable the Kendo Multi Select Search Bar wtih configuration.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public placeholder = '';

  /**
   * Sets the select hierarchy readonly mode.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public readonly = false;

  /**
   * The select hierarchy component can select single or multiple nodes.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public singleSelect = true;

  /**
   * Automation testhook to add to the select hierarchy component.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public testhook = 'select-hierarchy';

  /**
   * The ID value(s) within the hierarchy component data that are currently selected.
   * @type {string[]}
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public values: string[] = [];

  /**
   * Output event of the current selected node IDs.
   * @memberof SelectHierarchyComponent
   */
  @Output()
  public selected = new EventEmitter<string[]>();

  /**
   * The reference to the anchor element that opens the popup/dropdown menu.
   * @type {ElementRef | undefined}
   * @memberof SelectHierarchyComponent
   */
  @ViewChild('anchor', { read: ElementRef })
  public anchor: ElementRef | undefined;

  /**
   * The reference to the popup element.
   * @type {ElementRef | undefined}
   * @memberof SelectHierarchyComponent
   */
  @ViewChild('popup', { read: ElementRef })
  public popup: ElementRef | undefined;

  /**
   * Creates an instance of SelectHierarchyComponent.
   * @param {ChangeDetectorRef} changes The Angular Change Detection reference service.
   * @memberof SelectHierarchyComponent
   */
  constructor(private readonly changes: ChangeDetectorRef) {}

  /**
   * When new hierarchy node data is input.
   * Create a flat array of all the selectedable options so we can sync the Kendo Multi Select component with
   * the available options.
   * @param {SimpleChanges} changes The compone input changes.
   * @memberof SelectHierarchyComponent
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['nodes']) {
      this.options = this.nodes
        .flatMap((nodes) => getSelectHierarchyNodesAsFlatArray(nodes))
        .filter((node) => node.isSelectable);
    }
  }

  /**
   * When the component is destroyed.
   * Then close any observables.
   * @memberof SelectHierarchyComponent
   */
  public ngOnDestroy(): void {
    this.open$.next(false);
    this.open$.complete();
  }

  /**
   * If a click is detected outside of this component then close the popup/dropdown menu.
   * @param {PointerEvent} event The click event.
   * @memberof SelectHierarchyComponent
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: PointerEvent): void {
    if (!this.anchor?.nativeElement.contains(event.target) && !this.popup?.nativeElement?.contains(event.target)) {
      this.setOpen(false);
    }
  }

  /**
   * If the Escape key is pressed then close the popup/dropdown menu.
   * @param {KeyboardEvent} event The keyboard event.
   * @memberof SelectHierarchyComponent
   */
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.setOpen(false);
    }
  }

  /**
   * Emit the current selected hierarchy node IDs.
   * @param {string[]} values Selected hierarchy node IDs.
   * @memberof SelectHierarchyComponent
   */
  public selectValues(values: string[]): void {
    this.values = values;
    this.selected.emit(values);

    // If the component is single select we will close the dropdown.
    if (this.singleSelect) {
      this.setOpen(false);
    }
  }

  /**
   * Set the open state on the observable stream and component state.
   * @param {boolean} open Should the hierarchy dropdown menu open or close.
   * @memberof SelectHierarchyComponent
   */
  public setOpen(open: boolean): void {
    this.isOpen = open;
    this.open$.next(this.isOpen);
    this.changes.detectChanges();
  }

  /**
   * Show/Hide the dropdown if the hierarchy component is not disabled.
   * @memberof SelectHierarchyComponent
   */
  public toggle(): void {
    if (!this.disabled && !this.readonly) {
      this.setOpen(!this.isOpen);
    }
  }

  /**
   * When a user has clicked the remove tag icon in the Kendo Multi Select component.
   * Then remove this node ID from our values array.
   * And emit the updated hierarchy node ID values.
   * @param {RemoveTagEvent} event The Kendo Multi Select component remove tag event.
   * @memberof SelectHierarchyComponent
   */
  public removeValue(event: RemoveTagEvent): void {
    this.selected.emit(this.values.filter((id) => id !== event.dataItem.id));
  }
}
