import { Observable, debounceTime } from 'rxjs';
import { UPDATE_DELAY } from '../constants/magic-numbers';

/**
 * Custom RxJS Operator to unify text control debouncing.
 *
 * @return {Observable<T>} observable stream - the amended stream.
 */
export function debounceTextControl(): <T>(source: Observable<T>) => Observable<T> {
  return function filterByFormControlValidationProperty<T>(source: Observable<T>): Observable<T> {
    return source.pipe(debounceTime(UPDATE_DELAY));
  };
}
