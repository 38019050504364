import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { InputTextComponent } from '../input-text/input-text.component';
import { URL_PATTERN } from './input-url.constants';

export const DEFAULT_URL_PLACEHOLDER = $localize`https://example.com`;
export const URL_PATTERN_ERROR_MESSAGE = $localize`Must be a valid URL and start with HTTPS`;

/**
 * Surecloud input url Component that wraps the TextBoxComponent with a optional label.
 * @export
 * @class InputUrlComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-input-url',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, InputTextComponent],
  templateUrl: './input-url.component.html',
})
export class InputUrlComponent implements OnInit, OnDestroy {
  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof InputUrlComponent
   */
  @Input() controlName = '';

  /**
   * Text to display an additional description to the user.
   * @memberof InputUrlComponent
   */
  @Input() description = '';

  /**
   * Sets the text value for the label.
   * @memberof InputUrlComponent
   */
  @Input() label = '';

  /**
   * Sets the placeholder value for the input to a local variable
   * ensures there is always a placeholder to explain the desired format.
   * @memberof InputUrlComponent
   */
  @Input() set placeholder(value: string) {
    this.localPlaceholder = value === '' ? DEFAULT_URL_PLACEHOLDER : value;
  }

  /**
   * Sets the readonly value for the input.
   * @memberof InputUrlComponent
   */
  @Input() readonly = false;

  /**
   *  Sets if we want a success validation style to display.
   * @memberof InputUrlComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   * @memberof InputUrlComponent
   */
  @Input() marginBottom = false;

  /**
   * Sets a px width for the input. If this is not set the width will be set to 100% by default from styles.
   * @memberof InputUrlComponent
   */
  @Input()
  staticWidth!: string;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   * @type {FormGroup}
   * @memberof InputUrlComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Emits when the input url component has been blurred.
   * @type {EventEmitter<void>}
   * @memberof InputUrlComponent
   */
  @Output()
  blurred: EventEmitter<void> = new EventEmitter();

  /**
   * Local placeholder value needed to ensure there is always a placeholder to explain the desired format.
   */
  localPlaceholder = '';

  /**
   * Destroyed subject event.
   */
  destroyed$ = new Subject<void>();

  /**
   * Creates an instance of InputUrlComponent.
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof InputUrlComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   * and apply the url reg ex to validate against
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
    const control = this.parentFormGroup.controls[this.controlName];
    control.addValidators(Validators.pattern(URL_PATTERN));
    this.parentFormGroup.controls[this.controlName].valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (!control.valid) {
        control.setErrors({
          message: URL_PATTERN_ERROR_MESSAGE,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
