import { createAction } from '@ngrx/store';

/**
 * An object grouping the Application List entity actions
 * @export
 */
export const ApplicationListActions = {
  applicationListEnter: createAction('[Application List Page] Enter'),
  applicationListLeave: createAction('[Application List Page] Leave'),
};
