import type Toolbar from 'quill/modules/toolbar.js';
import { BaseTooltip } from 'quill/themes/base';
import SnowTheme from 'quill/themes/snow';

/**
 * Represents an extended snow theme for the editor.
 * This class extends the base `SnowTheme` class and adds additional functionality.
 */
export class ExtendedSnowTheme extends SnowTheme {
  extendedTooltip: BaseTooltip | null = null;

  /**
   * Extends the toolbar of the editor with a new link record element.
   * This element is a clickable link that will link to a record.
   * @param {Toolbar} toolbar The toolbar to extend.
   */
  override extendToolbar(toolbar: Toolbar): void {
    super.extendToolbar(toolbar);

    const linkRecord = this.createLinkRecordElement();

    if (this.tooltip?.root.lastElementChild) {
      this.tooltip.root.appendChild(linkRecord);
      this.addLinkRecordClickListener(linkRecord);
    }
  }

  /**
   * Creates a new anchor element with specific styles and properties.
   * The created anchor element has a text content of 'Link to Record'.
   * It also has a class of 'ql-linkRecord'.
   * @return {HTMLAnchorElement} The created anchor element.
   */
  // eslint-disable-next-line class-methods-use-this
  private createLinkRecordElement(): HTMLAnchorElement {
    const linkRecord = document.createElement('a');
    linkRecord.textContent = $localize`Link to Record`;
    linkRecord.classList.add('ql-linkRecord');
    return linkRecord;
  }

  /**
   * Adds a click event listener to a given link record. When the link is clicked, it prevents the default
   * action, gets the current selection in the Quill editor, and formats the selected text as a link.
   * After the formatting is done, it hides the tooltip.
   * @param {HTMLAnchorElement} linkRecord - The link record to which the click event listener is added.
   */
  private addLinkRecordClickListener(linkRecord: HTMLAnchorElement): void {
    linkRecord.addEventListener('click', (event) => {
      event.preventDefault();
      this.extendedTooltip = this.tooltip as BaseTooltip;

      const range = this.extendedTooltip.linkRange ?? this.quill.getSelection(true); // Get the current selection
      const preview = this.extendedTooltip.root.querySelector('a.ql-preview');

      if (this.tooltip && preview && range) {
        const url = new URL(window.location.href);
        const href = `${url.origin}/record/#[record].[id]`;
        (preview as HTMLAnchorElement).href = href;
        this.quill.formatText(range.index, range.length, 'link', href, 'user');
      }
      this.extendedTooltip.hide();
    });
  }
}
