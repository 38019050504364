import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { E2eHookDirective } from '@surecloud/common';
import { authRoutes } from './auth.routes';
import { EnterTenantComponent } from './components/enter-tenant/enter-tenant.component';

/**
 * Lazy-loaded, so don't import directly into another module
 *
 * @export
 * @class AuthModule
 */
@NgModule({
  imports: [HttpClientModule, CommonModule, RouterModule.forChild(authRoutes), ReactiveFormsModule, E2eHookDirective],
  declarations: [EnterTenantComponent],
})
export class AuthModule {}
