import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  EntityAttributeInterface,
  EntityAttributeTypeEnum,
  EntityInterface,
  HierarchyNodeInterface,
  isAttributeCalculation,
  isAttributeOptionList,
  UNTITLED,
} from '@surecloud/common';
import { GLOBAL_ATTRIBUTE_CONFIG } from '../../models/attribute/attribute-configuration';
import { ATTRIBUTE_FEATURE_KEY, attributeAdapter, StateInterface } from '../../reducers/attribute/attribute.reducer';
import * as EntitySelectors from '../entity/entity.selectors';

// Lookup the 'Entity Attribute' feature state managed by NgRx
export const getAttributeState = createFeatureSelector<StateInterface>(ATTRIBUTE_FEATURE_KEY);

const { selectAll, selectEntities } = attributeAdapter.getSelectors();

/**
 * Get all attributes.
 */
export const getAttributeList = createSelector(getAttributeState, (state: StateInterface) => selectAll(state));

/**
 * Get the selected attribute ID.
 */
export const getSelectedId = createSelector(getAttributeState, (state: StateInterface) => state.selectedId);

/**
 * Get attributes for the selected entity.
 */
export const getSelectedAttributes = createSelector(
  getAttributeList,
  EntitySelectors.getSelectedId,
  (attributes, selectedEntityId) => attributes.filter((attribute) => attribute.entityId === selectedEntityId)
);

/**
 * Get the attribute entity map from attribute state.
 */
export const getAttributeEntities = createSelector(getAttributeState, (state: StateInterface) => selectEntities(state));

/**
 * Get the selected attribute data.
 */
export const getSelectedAttribute = createSelector(getAttributeEntities, getSelectedId, (attributes, selectedId) =>
  selectedId ? attributes[selectedId] : undefined
);

/**
 * Get all attributes that are of type NUMBER for selected entity.
 * Does not return the current selected attribute.
 */
export const getSelectedNumberAttributes = createSelector(
  getSelectedAttributes,
  getSelectedId,
  (attributes, selectedAttributeId) =>
    attributes.filter(
      (attribute) => attribute.attributeId !== selectedAttributeId && attribute.type === EntityAttributeTypeEnum.Number
    )
);

/**
 * Get a list of attributes for an Entity by ID.
 * @param {string} entityId The entity ID to get the attributes for.
 * @return {EntityAttributeInterface[]} The list of attributes belonging to the entity ID.
 */
export const getAttributesByEntityId = (
  entityId: string
): MemoizedSelector<
  object,
  EntityAttributeInterface[],
  (s1: EntityAttributeInterface[]) => EntityAttributeInterface[]
> =>
  createSelector(getAttributeList, (attributes) => attributes.filter((attribute) => attribute.entityId === entityId));

/**
 * Get a list of attributes for an Entity by ID as a HierarchyNodeInterface.
 * @param {string} entityId The entity ID to get the attributes for.
 * @param {EntityAttributeTypeEnum | EntityAttributeTypeEnum[]} type Optional atribute type(s) to only get attributes of a specific type.
 * @return {HierarchyNodeInterface} The list of attributes belonging to the entity ID in a HierarchyNodeInterface.
 */
export const getAttributesHierarchyByEntityId = (
  entityId: string,
  type?: EntityAttributeTypeEnum | EntityAttributeTypeEnum[]
): MemoizedSelector<
  object,
  HierarchyNodeInterface | undefined,
  (s1: EntityInterface | undefined, s2: EntityAttributeInterface[]) => HierarchyNodeInterface | undefined
> =>
  createSelector(EntitySelectors.getEntityById(entityId), getAttributesByEntityId(entityId), (entity, attributes) => {
    let hierarchy: HierarchyNodeInterface | undefined;

    if (entity) {
      hierarchy = {
        name: $localize`${entity.name || UNTITLED} Attributes`,
        id: entityId,
        icon: 'back',
        nodes: attributes
          // If the optional attribute type(s) parameter has been passed. Then filter to get only those types of attributes.
          .filter((attribute) => {
            if (type && Array.isArray(type) && attribute.type) {
              return type.includes(attribute.type);
            }
            if (type) {
              return attribute.type === type;
            }

            return true;
          })
          // Map to the HierarchyNodeInterface
          .map((attribute) => {
            const attributeConfig = GLOBAL_ATTRIBUTE_CONFIG.find((config) => config.type === attribute.type);

            return {
              name: attribute.name || UNTITLED,
              id: attribute.attributeId,
              icon: attributeConfig?.icon,
              isSelectable: true,
            };
          }),
      };
    }

    return hierarchy;
  });

/**
 * Get the selected entity list of attributes for an Question Component.
 */
export const getSelectedEntityAttributesForQuestionComponent = createSelector(getSelectedAttributes, (attributes) =>
  attributes.filter((attribute) => isAttributeOptionList(attribute) || isAttributeCalculation(attribute))
);

/**
 * Get an array of selected entity attributes by attribute type.
 * @param {EntityAttributeTypeEnum[]} types The attribute types you want to select.
 * @return {EntityAttributeInterface[]} A list of selected entity attributes by types.
 */
export const getSelectedEntityAttributesByTypes = (
  types: EntityAttributeTypeEnum[]
): MemoizedSelector<
  object,
  EntityAttributeInterface[],
  (s1: EntityAttributeInterface[]) => EntityAttributeInterface[]
> =>
  createSelector(getSelectedAttributes, (attributes) =>
    attributes.filter((attribute) => attribute.type && types.includes(attribute.type))
  );
