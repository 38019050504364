import { PermissionTypeApiInterface, PermissionValueApiInterface } from '@surecloud/api-types';
import { z } from 'zod';

/**
 * Role Permission Type Enum.
 */
export const permissionTypeEnum = z.nativeEnum(PermissionTypeApiInterface);

/**
 * Role Permission Value Enum.
 */
export const permissionValueEnum = z.nativeEnum(PermissionValueApiInterface);

/**
 * Role Permission Zod schema.
 */
export const rolePermissionZodSchema = z.object({
  create: permissionValueEnum.optional().nullable(),
  delete: permissionValueEnum.optional().nullable(),
  grant: permissionValueEnum.optional().nullable(),
  install: permissionValueEnum.optional().nullable(),
  read: permissionValueEnum.optional().nullable(),
  type: permissionTypeEnum.optional().nullable(),
  update: permissionValueEnum.optional().nullable(),
});

/**
 * Role Zod schema.
 * Description and permissions are optional as they not in the Entity Query (entity.queries.ts).
 */
export const roleZodSchema = z.object({
  availableToGuestUsers: z.boolean().nullable().optional(),
  roleId: z.string(),
  description: z.string().nullable().optional(),
  name: z.string().nullable(),
  permissions: z.array(rolePermissionZodSchema).optional(),
});
