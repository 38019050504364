<toolbar-feature>
  <sc-page maxWidth="large" *ngIf="entityFacade.selectedEntity$ | async as entity">
    <ng-container>
      <sc-title-description
        [heading]="entity.name + ' Records'"
        [description]="'The table contains all ' + entity.name + ' records you have access to.'"
        i18n-description
        i18n-title></sc-title-description>
    </ng-container>
    <ng-container>
      <ng-template #error>
        <p i18n>There was an error getting the entity's records.</p>
      </ng-template>
      <ng-container *ngIf="recordFacade.recordResult$ | async as recordGridData">
        <sc-section>
          <div class="sc-pt-4">
            <sc-record-grid
              [height]="450"
              [gridRecordData]="recordGridData"
              primaryButtonName="Add Record"
              i18n-primaryButtonName
              primaryButtonType="loading"
              [primaryLoadingButtonState]="(addRecordButtonLoadingState$ | async) || {}"
              [rowSelection]="'multiple'"
              [selectableRows]="true"
              [navigableRows]="true"
              [showPrimaryButton]="recordGridData.viewerCanCreate"
              [exportName]="entity.name || ''"
              [canExport]="true"
              [canImport]="((enabledForNg4037$ | async) === true) && recordGridData.viewerCanCreate"
              [canSelectAll]="false"
              [paginationType]="PaginationTypeEnum.ServerSide"
              (cellClicked)="cellClicked($event)"
              (cellValueChanged)="recordUpdated($event)"
              (primaryButtonClicked)="addRecord()"
              (rowsDeleted)="deleteRecord($event)"
              (importClicked)="importRecords(entity.entityId)"
              (exportAll)="exportRecords(recordGridData, entity)"
              (exportSelection)="exportSelectedRecords($event, recordGridData, entity)"
              (gridStateChanged)="updateGridState($event)"></sc-record-grid>
          </div>
        </sc-section>
      </ng-container>
    </ng-container>
  </sc-page>
</toolbar-feature>
