import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownListComponent, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective, trackByIndex } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { ColourPaletteColourOptionInterface } from './colour-palette.component.interface';

/**
 * Wrapper component around the [Kendo UI DropDownList](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/).
 * @example
 * <sc-colour-palette
 *  [controlName]="formControlName"
 *  [description]="Description for colour palette"
 *  [label]="Label for colour palette"
 *  [staticWidth]="200"
 *  [options]="['Option 1', 'Option 2', 'Option 3']"
 *  [readonly]="true">
 * </sc-colour-palette>
 * @export
 * @class SelectDropdownComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-colour-palette',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    DropDownListModule,
    LabelModule,
    ReactiveFormsModule,
    CommonIconModule,
  ],
  templateUrl: './colour-palette.component.html',
  styleUrls: ['./colour-palette.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColourPaletteComponent implements OnInit {
  /**
   * Text to display an additional description to the user.
   * @memberof ColourPaletteComponent
   */
  @Input() description = '';

  /**
   *
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof ColourPaletteComponent
   */
  @Input() controlName = '';

  /**
   *  Sets if we want a success validation style to display.
   * @memberof ColourPaletteComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * The label text for the colour palette dropdown.
   * @memberof ColourPaletteComponent
   */
  @Input() label = '';

  /**
   * The parent form group of the colour palette dropdown.
   * @type {FormGroup}
   * @memberof ColourPaletteComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * The list of colour groups that can be selected.
   * @type {ColourPaletteColourOptionInterface}
   * @memberof ColourPaletteComponent
   */
  @Input() colours!: ColourPaletteColourOptionInterface[] | null;

  /**
   * The text property to assign for the option if a SelectDropdownComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/#toc-arrays-of-complex-data)
   * @memberof ColourPaletteComponent
   */
  @Input() optionTextProperty = 'colours';

  /**
   * The value property to assign for the option if a SelectDropdownComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/#toc-arrays-of-complex-data)
   * @memberof ColourPaletteComponent
   */
  @Input() optionValueProperty = 'colourId';

  /**
   * Sets the select dropdown readonly mode.
   * @memberof ColourPaletteComponent
   */
  @Input() readonly = false;

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof ColourPaletteComponent
   */
  @Input() staticWidth!: string;

  /**
   * Sets the placholder value for the input.
   * @memberof ColourPaletteComponent
   */
  @Input() placeholder = '';

  /**
   * Sets the value type to primitive by default.
   * If we want save the whole option object as the value then set it to false.
   * @memberof ColourPaletteComponent
   */
  @Input()
  valuePrimitive = true;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   * @memberof ColourPaletteComponent
   */
  @Input()
  marginBottom = false;

  /**
   * Boolean if the control should use the ViewContainerRef
   * @memberof ColourPaletteComponent
   */
  @Input()
  useViewContainerRef = true;

  @ViewChild('dropdownlist', { static: true }) dropdownlist!: DropDownListComponent;

  /**
   * The Angular ViewContainerRef helper
   * @type {(ViewContainerRef | undefined)} A view container instance
   * @memberof SelectDropdownComponent
   */
  viewContainerRef: ViewContainerRef | undefined;

  /**
   * Track by function for Angular loops.
   * @memberof ColourPaletteComponent
   */
  trackByFunction = trackByIndex;

  /**
   * Creates an instance of ColourPaletteComponent.
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @memberof ColourPaletteComponent
   */
  constructor(private formGroupDirective: FormGroupDirective, public viewContainerRefDefault: ViewContainerRef) {}

  /**
   * Runs when the component is started.
   * Sets the parent formgroup form the component.
   * @memberof SelectDropdownComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
    if (this.useViewContainerRef) {
      this.viewContainerRef = this.viewContainerRefDefault;
    }
  }
}
