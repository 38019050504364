import { Injectable } from '@angular/core';
import { GroupApiInterface, GroupSummaryApiInterface } from '@surecloud/api-types';
import { schema } from 'normalizr';
import { NormalisationOutputStrategyInterface } from '../../classes/normalise/normalise-class.interface';
import { Normalise } from '../../classes/normalise/normalise.class';
import { GroupInterface } from '../../models/group/group.models';
import { LoggerService } from '../logger/logger.service';
import {
  NormalisedGroupList,
  getNormalisedGroupListError,
  isNormalisedGroupListGuard,
} from './normalise-get-groups.validation';

const GROUPS_KEY = 'groups';

/**
 * Service encapsulating the schema and configuration for flattening the get Group API response.
 * @export
 * @class NormaliseGetGroupService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetGroupService {
  /**
   * Group rule schema.
   * @memberof NormaliseGetGroupService
   */
  groupSchema = new schema.Entity(
    GROUPS_KEY,
    {},
    {
      idAttribute: (value): string => value.groupId,
      processStrategy: (value: GroupApiInterface): GroupInterface => {
        const { users, ...rest } = value;
        return {
          ...rest,
          name: value.name || null,
          description: value.description || null,
          roles: value.roles?.map((role: { roleId: string }) => role.roleId),
          users: value.users?.map((user: { userId: string }) => user.userId),
        };
      },
    }
  );

  /**
   * Group list schema.
   * @memberof NormaliseGetGroupService
   */
  groupListSchema = [this.groupSchema];

  /**
   * An array of output strategies to process the flattened data.
   * @type {NormalisationOutputStrategyInterface[]}
   * @memberof NormaliseGetGroupService
   */
  outputStrategies: NormalisationOutputStrategyInterface[] = [
    {
      key: GROUPS_KEY,
    },
  ];

  /**
   * Creates an instance of NormaliseGetGroupService
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetGroupService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Extract the Group data from the Group API response.
   * @param {GroupApiInterface[] | GroupSummaryApiInterface[]} groupList The Group data structure as it comes from the API.
   * @return {NormalisedGroupList} UI Group List data structure.
   * @memberof NormaliseGetGroupService
   */
  extract(groupList: GroupApiInterface[] | GroupSummaryApiInterface[]): NormalisedGroupList {
    const { groups } = Normalise.flatten(groupList, this.groupListSchema, this.outputStrategies);

    const normaliseResult = {
      groups,
    };

    if (!isNormalisedGroupListGuard(normaliseResult)) {
      this.logger.logEvent('Group Feature', 'Normalise Group List Data', getNormalisedGroupListError(normaliseResult));

      throw new Error('The result of the normalise Group List does not match the expected format.');
    }

    return normaliseResult;
  }
}
