/**
 * The amount of time to wait while scrolling
 * before checking what the active section is.
 */
export const SCROLL_DEBOUNCE_TIME = 100;

/**
 * When a section is within the the pixel number range below
 * from the scroll top of a scrollable container.
 * It can be considered the active section in the view.
 */
export const SCROLL_OFFSET_AMOUNT = 100;

/**
 * When a user has scrolled above the pixel number below
 * to show a "Back to top" button
 */
export const SCROLL_MINIMUM_POSITION = 200;
