export type ScTagSize = 'sm' | 'md' | 'lg';

export const ScTagThemes = [
  'blue',
  'green',
  'red',
  'orange',
  'purple',
  'light-grey',
  'grey',
  'dark-grey',
  'salmon',
  'aqua',
  'meadow',
  'sunset',
  'sky',
  'violet',
  'bubblegum',
  'tea-green',
  'polar',
  'tasman',
  'tangerine',
  'aero-blue',
  'jagged-ice',
  'lavender-blush',
  'barley-white',
  'ghost',
  'snuff',
  'parchment',
  'charlotte',
  'alice-blue',
] as const;

export type ScTagTheme = typeof ScTagThemes[number];
