import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isFileGuard } from '@surecloud/common';
import { FilePickerComponent, XLS_EXTENSION, XLSX_EXTENSION } from '@surecloud/design';
import { RecordImportActions } from '../../+state/actions/record-import.actions';
import { RecordImportFacade } from '../../+state/facades/record-import.facade';
/**
 * The record import component.
 * @export
 * @class RecordImportComponent
 */
@Component({
  selector: 'record-import',
  templateUrl: './record-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordImportComponent {
  /**
   * The file picker component.
   * @type {FilePickerComponent}
   * @memberof RecordImportComponent
   */
  @ViewChild('filePicker') filePicker!: FilePickerComponent;

  /**
   * The input file control.
   * @memberof RecordImportComponent
   */
  inputFileControl: FormControl<File[]> = new FormControl();

  /**
   * The allowed extensions.
   * @memberof RecordImportComponent
   */
  ALLOWED_EXTENSIONS = [XLS_EXTENSION, XLSX_EXTENSION];

  /**
   * The loading state.
   * @memberof RecordImportComponent
   */
  loading = false;

  /**
   * Creates an instance of RecordImportComponent.
   * @param {RecordImportFacade} recordImportFacade The record import facade.
   * @memberof RecordImportComponent
   */
  constructor(private recordImportFacade: RecordImportFacade) {}

  /**
   * When click on cancel button.
   * @memberof RecordImportComponent
   */
  cancel(): void {
    this.recordImportFacade.dispatch(RecordImportActions.cancelRecordImportModal());
  }

  /**
   * When click on import button.
   * @memberof RecordImportComponent
   */
  import(): void {
    const file = this.inputFileControl.value[0];

    if (isFileGuard(file)) {
      this.loading = true;
      this.recordImportFacade.dispatch(RecordImportActions.startImportRecords({ file }));
    }
  }
}
