import { EntityInterface } from '../models/entity/entity.models';
import { RecordAttributeUnion } from '../models/record-data.model';
import { EntityAttributeTypeEnum } from './entity-attribute-type/entity-attribute-type.enum';

/**
 * @property {RecordColumnAttributeInterface[]} columnAttributes the attribute ids for the columns
 * @property {RecordInterface[]} records the list of records
 * @property {boolean} viewerCanCreate checks if the user can create the record
 * @property {number} totalItems the total number of items
 * @export
 * @interface RecordGridInputInterface
 */
export interface RecordGridInputInterface {
  columnAttributes: RecordColumnAttributeInterface[];
  records: RecordInterface[];
  viewerCanCreate: boolean;
  totalItems: number;
}

/**
 * @property {EntityInterface} entitySummary The entity name
 * @property {RecordGridInputInterface} recordGridInput The entity description
 * @export
 * @interface CustomRecordResultInterface
 */
export interface CustomRecordResultInterface {
  entitySummary: EntityInterface;
  recordGridInput: RecordGridInputInterface;
}

/**
 * @property {string} recordId the id for the record
 * @property {RecordAttributeUnion} attributes array of attributes
 * @property {boolean} viewerCanDelete checks if the user can delete the record
 * @export
 * @interface RecordInterface
 */
export interface RecordInterface {
  recordId: string;
  attributes: RecordAttributeUnion[];
  viewerCanDelete: boolean;
}

/**
 *
 * @property {string} name record column name
 * @property {string} attributeId record attribute id
 * @property {EntityAttributeTypeEnum} type attribute type
 * @export
 * @interface RecordColumnAttributeInterface
 */
export interface RecordColumnAttributeInterface {
  name: string;
  attributeId: string;
  type: EntityAttributeTypeEnum;
  canBeMultiple: boolean;
}

/**
 * Public method to check if first item is a record
 * @export
 * @param {unknown[]} maybeRecords the possible records list
 * @return {*}  {maybeRecords is RecordInterface[]}
 */
export const shallowCheckFirstIsRecord = (maybeRecords: unknown[]): maybeRecords is RecordInterface[] => {
  const [firstRecord] = maybeRecords;
  if (!firstRecord) return false;
  return (
    (firstRecord as RecordInterface).recordId !== undefined &&
    (firstRecord as RecordInterface).attributes !== undefined &&
    (firstRecord as RecordInterface).viewerCanDelete !== undefined
  );
};

/**
 * Public method to check if first item has a recordId
 * @export
 * @param {unknown[]} maybeHasRecordId the possible records list
 * @return {*} {maybeHasRecordId is Required<{ recordId: string }[]}
 */
export const haveRecordIds = (maybeHasRecordId: unknown[]): maybeHasRecordId is Required<{ recordId: string }>[] => {
  if (maybeHasRecordId.length === 0) return false;
  return maybeHasRecordId.every((record) => (record as Required<{ recordId: string }>).recordId !== undefined);
};
