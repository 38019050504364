<div #anchor>
  <sc-icon-button
    *ngIf="icon"
    (click)="onToggle()"
    [themeColour]="themeColour"
    [icon]="icon"
    class="d-inline-block"
    [size]="size"
    [shape]="shape">
  </sc-icon-button>
  <button
    *ngIf="!icon && label"
    type="button"
    class="sc-font-body-link sc-text-brand-500 sc-cursor-pointer"
    (click)="onToggle()">
    {{ label }}
  </button>
</div>

<kendo-popup
  *ngIf="show"
  #popup
  positionMode="absolute"
  [anchorAlign]="anchorAlign"
  [popupAlign]="popupAlign"
  [anchor]="anchor"
  [animate]="false"
  (anchorViewportLeave)="show = false"
  [margin]="{ horizontal, vertical }"
  [popupClass]="popupClass">
  <div i18n class="content"><ng-content></ng-content></div>
</kendo-popup>
