import {
  EntityAttributeTypeEnum,
  EntityAttributeUserModeTypeEnum,
  isAttributeUser,
  makeGlobalSectionRendererConfig,
} from '@surecloud/common';
import {
  GLOBAL_ATTRIBUTE_COMMON_USER_FILTER_SECTION,
  makeAttributeConfigComponent,
  makeAttributeConfigSectionId,
} from '../global-attribute-config.model';

/**
 * Makes the global user attribute configuration
 */
export const GLOBAL_USER_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.User,
  key: EntityAttributeTypeEnum.User,
  type: EntityAttributeTypeEnum.User,
  label: $localize`User`,
  uniqueSections: [
    {
      sectionComponent: makeAttributeConfigComponent('User'),
      sectionId: makeAttributeConfigSectionId('user'),
      sectionName: $localize`User Properties`,
    },
    {
      sectionComponent: GLOBAL_ATTRIBUTE_COMMON_USER_FILTER_SECTION.sectionComponent,
      sectionId: GLOBAL_ATTRIBUTE_COMMON_USER_FILTER_SECTION.sectionId,
      sectionName: GLOBAL_ATTRIBUTE_COMMON_USER_FILTER_SECTION.sectionName,
      defaultVisibility: false,
      showCondition: (attributeValues): boolean => {
        if (isAttributeUser(attributeValues)) {
          return attributeValues?.userMode
            ? attributeValues?.userMode !== EntityAttributeUserModeTypeEnum.Groups
            : false;
        }
        return false;
      },
    },
  ],
});
