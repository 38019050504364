<ng-container *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
  <kendo-formfield class="block sc-select-dropdown" showErrors="initial" [ngClass]="{ 'sc-mb-20': marginBottom }">
    <kendo-label
      [text]="label"
      class="sc-label flex-column"
      [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
      <!--
        The (close) binding is a workaround for a bug in the Kendo Dropdownlist component,
        where if the is no close binding, the component does not always update its classes on close
      -->
      <div class="sc-select-dropdown__dropdown-container">
        <kendo-dropdownlist
          #dropdownlist
          scCommonE2eHook="{{ testhook }}:form:select"
          [formControlName]="controlName"
          [defaultItem]="defaultItem"
          [itemDisabled]="itemDisabled"
          [data]="options"
          size="none"
          [style.width.px]="staticWidth"
          [style.height.px]="staticHeight"
          class="sc-select-dropdown__dropdown"
          [ngClass]="{
            'sc-input--readonly': readonly && !parentFormGroup.controls[controlName].disabled,
          }"
          (opened)="opened()"
          (closed)="closed()"
          [textField]="optionTextProperty"
          [valueField]="optionValueProperty"
          [valuePrimitive]="valuePrimitive"
          [readonly]="readonly"
          [attr.aria-expanded]="open$ | async"
          [popupSettings]="{
            appendTo: viewContainerRef,
            popupClass: popupClass + ' sc-select-dropdown__popup sc-scrollbar'
          }">
        </kendo-dropdownlist>
        <button
          *ngIf="
            canClear &&
            !readonly &&
            parentFormGroup.controls[controlName]?.value &&
            !parentFormGroup.controls[controlName].disabled
          "
          class="clear-button"
          i18n
          (click)="clear()">
          <sc-icon key="close" size="small" colour="grey-600"></sc-icon>
        </button>
      </div>
      <div class="flex">
        <div
          i18n
          *ngIf="enableSuccessValidationStyle && parentFormGroup.controls[controlName].valid"
          class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
          </div>
          Success
        </div>
        <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
          <div class="sc-pt-2 sc-pl-2">
            <sc-icon key="checkmark-outline" size="small" colour="red-300" class="sc-mr-4"></sc-icon>
          </div>
          Incomplete
        </div>
        <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
      </div>
    </kendo-label>
  </kendo-formfield>
</ng-container>
