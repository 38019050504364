<div [ngClass]="{ 'sc-mb-20': marginBottom }">
  <p class="sc-form-label">{{ label }}</p>
  <div
    class="sc-named-toggle {{ additionalClasses }}"
    [ngClass]="{
      'sc-named-toggle--sm': size === 'small',
      'sc-named-toggle--disabled': parentFormGroup.controls[controlName].disabled,
      'sc-named-toggle--readonly': readonly
    }"
    [formGroup]="parentFormGroup"
    scCommonE2eHook="{{ testhook }}:form:named-toggle">
    <kendo-label
      class="sc-named-toggle__label flex flex-row justify-content-between sc-m-0"
      [for]="controlName"
      [text]="label">
      <sc-toggle [controlName]="controlName" [readonly]="readonly"></sc-toggle>
    </kendo-label>
  </div>
  <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
</div>
