import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { ApplicationRouteDataInterface, HREF } from '@surecloud/common';
import { TaskFeatureFacade } from '@surecloud/task-state';
import { Observable, map } from 'rxjs';
import { ToolbarTasksPageActions } from '../../+state/actions/toolbar-task.actions';
import { ToolbarFeatureFacade } from '../../+state/facades/toolbar.facade';

/**
 * Toolbar Navigation Component.
 * Parent to all dumb Global Nav Component.
 * @export
 * @class ToolbarNavigationComponent
 */
@Component({
  selector: 'toolbar-feature-navigation',
  templateUrl: './toolbar-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarNavigationComponent {
  /**
   * The current route observable.
   * @type {Observable<string>}
   * @memberof ToolbarNavigationComponent
   */
  activeRouteLabel$: Observable<string> = this.toolbarFacade.routeData$.pipe(
    map((data: ApplicationRouteDataInterface) => data?.activeRouteLabel || '')
  );

  /**
   * Is Design Version 2 Feature enabled.
   * @memberof ToolbarNavigationComponent
   */
  @Input()
  public isDesignV2Enabled = false;

  /**
   * Creates an instance of ToolbarNavigationComponent.
   * @param {Window} window The Window service.
   * @param {ToolbarFeatureFacade} toolbarFacade The Toolbar Feature Facade service.
   * @param {TaskFeatureFacade} taskFacade The Task Feature Facade service.
   * @memberof ToolbarNavigationComponent
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    public readonly toolbarFacade: ToolbarFeatureFacade,
    public readonly taskFacade: TaskFeatureFacade
  ) {}

  /**
   * When a user clicks on the help icon in the global navigation.
   * Then open a new tab in the browser with the Help Centre.
   * @memberof ToolbarNavigationComponent
   */
  openHelpCentre(): void {
    this.window.open(HREF.help, '_blank');
  }

  /**
   * When a user clicks on the task list icon in the global navigation.
   * @param {Event} event The click event on the Task List Icon.
   * @memberof ToolbarNavigationComponent
   */
  taskListToggle(event: Event): void {
    event.stopPropagation();
    this.taskFacade.dispatch(ToolbarTasksPageActions.toggle({}));
  }
}
