import { Injectable } from '@angular/core';
import {
  EntitiesSummaryApiInterfaceQueryService,
  EntityCreatePayloadApiInterface,
  EntityDeleteInputApiInterface,
  EntityDeletePayloadApiInterface,
  EntityUpdateInputApiInterface,
  EntityUpdatePayloadApiInterface,
} from '@surecloud/api-types';
import { EntityInterface, EntityUpdatedInterface, makeEntity, removeNullOrUndefinedArrayItems } from '@surecloud/common';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { EntityApiInterface } from './entity.service.interface';

import {
  apolloEntityCreateMutation,
  apolloEntityDeleteMutation,
  apolloEntityUpdateMutation,
} from '../../mutations/entity/entity.mutations';
import { apolloEntitiesQuery, apolloEntityQuery } from '../../queries/entity.queries';
import { NormaliseGetEntitiesService } from '../normalise-get-entities/normalise-get-entities.service';
import { NormalisedEntityList, NormalisedEntitySummaryList } from '../normalise-get-entities/normalise-get-entities.validation';
/**
 * Entity API service.
 * @export
 * @class EntityService
 */
@Injectable({
  providedIn: 'root',
})
export class EntityService {

  /**
   * Creates an instance of EntityService.
   * @param {Apollo} apollo The Apollo service.
   * @param {EntitiesSummaryApiInterfaceQueryService} entitySummaryQueryService The service to fetch all Entities Summary.
   * @param {NormaliseGetEntitiesService} normaliseGetEntitiesService The data flattening service.
   * @memberof EntityService
   */
  constructor(
    private readonly apollo: Apollo,
    private readonly entitySummaryQueryService: EntitiesSummaryApiInterfaceQueryService,
    private readonly normaliseGetEntitiesService: NormaliseGetEntitiesService,
  ) {}

  /**
   * Create a new entity.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityInterface>} The created entity returned from the API.
   * @memberof EntityService
   */
  create(requestId: string | null = null): Observable<EntityInterface> {
    return this.apollo
      .mutate<{ entityCreate: EntityCreatePayloadApiInterface }>(apolloEntityCreateMutation(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.entityCreate
            ? of(makeEntity(data.entityCreate.createdId, null))
            : throwError(() => 'No response data from create entity returned from API')
        )
      );
  }

  /**
   * Update an existing entity.
   * @param {EntityInterface} entity The entity to update.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityInterface>} The updated entity.
   * @memberof EntityService
   */
  update(entity: EntityInterface, requestId: string | null = null): Observable<EntityInterface> {
    const entityUpdateMutationInput: EntityUpdateInputApiInterface = {
      entityId: entity.entityId,
      name: entity.name,
      description: entity.description,
    };

    return this.apollo
      .mutate<{ entityUpdate: EntityUpdatePayloadApiInterface }>(apolloEntityUpdateMutation(entityUpdateMutationInput,requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.entityUpdate
            ? of({ ...entity, ...data.entityUpdate.entity })
            : throwError(() => 'No response data from update entity returned from API')
        )
      );
  }

  /**
   * Query all Entities
   * @return {Observable<NormalisedEntityList>} Array of normalised entities
   * @memberof EntityService
   */
  readAll(): Observable<NormalisedEntityList> {
    return this.apollo
      .query<{ entities: EntityApiInterface[] }>(apolloEntitiesQuery())
      .pipe(
        switchMap(({ data }) =>
          data?.entities
            ? of(this.normaliseGetEntitiesService.flatten(data.entities))
            : throwError(() => 'No response data from get entities returned from API')
        )
      );
  }

  /**
   * Query all Entities Summary
   * @return {Observable<NormalisedEntitySummaryList>} Array of normalised entities
   * @memberof EntityService
   */
  readEntitySummaryList(): Observable<NormalisedEntitySummaryList> {
    return this.entitySummaryQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.entitiesSummary
            ? of(this.normaliseGetEntitiesService.extractEntitiesSummary(removeNullOrUndefinedArrayItems(data.entitiesSummary)))
            : throwError(() => 'No response data from get entities summary returned from API')
        )
      );
  }

  /**
   * Query for an Entity by id
   * @param {string} id id of the entity
   * @return {Observable<NormalisedEntityList>} The entity.
   * @memberof EntityService
   */
  read(id: string): Observable<NormalisedEntityList> {
    return this.apollo
      .query<{ entity: EntityApiInterface }>(apolloEntityQuery(id))
      .pipe(
        switchMap(({ data }) =>
          data?.entity
            ? of(this.normaliseGetEntitiesService.flatten([data.entity]))
            : throwError(() => 'No response data from get entity returned from API')
        )
      );
  }

  /**
   * Delete an entity.
   * @param {EntityInterface} entity The entity to delete.
   * @return {Observable<EntityUpdatedInterface>} The deleted entity ID.
   * @memberof EntityService
   */
  delete(entity: EntityInterface): Observable<EntityUpdatedInterface> {
    const payload: EntityDeleteInputApiInterface = {
      entityId: entity.entityId,
    };

    return this.apollo
      .mutate<{ entityDelete: EntityDeletePayloadApiInterface }>(apolloEntityDeleteMutation(payload))
      .pipe(
        switchMap(({ data }) =>
          data?.entityDelete
            ? of({ entityId: data.entityDelete.deletedId })
            : throwError(() => 'No response data from delete entity returned from API')
        )
      );
  }
}
