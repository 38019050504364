import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScIconType } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Surecloud Icon List Item Component.
 * Used for a square icon and label look with a radius border.
 * @export
 * @class IconListItemComponent
 */
@Component({
  selector: 'sc-icon-list-item',
  standalone: true,
  styleUrls: ['./icon-list-item.component.scss'],
  templateUrl: './icon-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CommonIconModule],
})
export class IconListItemComponent {
  /**
   * The icon we want to display
   * @type {ScIconType | undefined }
   * @memberof IconListItemComponent
   */
  @Input()
  icon: ScIconType | undefined = undefined;

  /**
   * The label we want to display
   * @type {string | undefined }
   * @memberof IconListItemComponent
   */
  @Input()
  label: string | undefined = undefined;
}
