import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  CommonActions,
  CommonRoutesEnum,
  DEFAULT_PAGE_SIZE,
  LoggerService,
  selectNestedRouteParam,
} from '@surecloud/common';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { RecordService } from '../../services/record/record.service';
import { RecordActions } from '../actions/record.actions';
import { isRecordDataMultipleCellInterface, isRecordDataSingleCellInterface } from '../models/record-input-type-guards';
import { getColumnAttribute } from '../selectors/record.selectors';

/**
 * The Effects/side effects for Record View.
 * @export
 * @class RecordEffects
 */
@Injectable({ providedIn: 'root' })
export class RecordEffects {
  /**
   * When reading an record view.
   * Then load the record view data from the API.
   * @memberof RecordEffects
   */
  initRecordsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.previewDataEnter, CommonActions.loadEntity),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      map(([, entityId]) =>
        RecordActions.readRecordResponse({ input: { entityId, pageSize: DEFAULT_PAGE_SIZE, pageNumber: 0 } })
      )
    )
  );

  /**
   * When reading an end-user records list.
   * Then load the records from the API.
   * @memberof RecordEffects
   */
  loadRecordsForEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.recordListEnter),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityIdForRecords))),
      map(([, entityId]) =>
        RecordActions.readRecordResponse({ input: { entityId, pageSize: DEFAULT_PAGE_SIZE, pageNumber: 0 } })
      )
    )
  );

  /**
   * When leaving a preview data view.
   * Then reset the record data store.
   * @memberof RecordEffects
   */
  resetRecordsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.previewDataLeave, RecordActions.recordListLeave),
      map(() => RecordActions.reset())
    )
  );

  updateGridState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.updateGridState),
      withLatestFrom(
        this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId)),
        this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityIdForRecords))
      ),
      map(([grid, entityId, entityForRecords]) =>
        RecordActions.readRecordResponse({
          input: {
            entityId: entityId || entityForRecords,
            pageNumber: grid.gridState.pagination?.currentPage || 0,
            pageSize: grid.gridState.pagination?.pageSize || DEFAULT_PAGE_SIZE,
            filters: grid.gridState.filters,
            quickFilter: grid.gridState.quickFilter,
            sorts: grid.gridState.sorting,
          },
        })
      )
    )
  );

  /**
   * When reading Records
   * Then load all records for the entity from the API.
   * @memberof RecordEffects
   */
  loadRecordResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.readRecordResponse),
      switchMap(({ input }) =>
        this.recordService.read(input).pipe(
          map((recordResult) => RecordActions.readRecordResponseSuccess({ recordResult })),
          catchError((error: unknown) => of(RecordActions.readRecordResponseFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the record response has been received.
   * Then dispatch RecordActions.readRecordGridInputSuccess with the record grid data.
   * @memberof RecordEffects
   */
  setRecordResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.readRecordResponseSuccess),
      map(({ recordResult }) =>
        RecordActions.readRecordGridInputSuccess({ recordGridInput: recordResult.recordGridInput })
      )
    )
  );

  /**
   * When the record response has been received.
   * Then dispatch CommonActions.readAndSelectEntity with the record response entity.
   * @memberof RecordEffects
   */
  setEntityFromRecordResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.readRecordResponseSuccess),
      map(({ recordResult }) =>
        CommonActions.readAndSelectEntity({
          entity: recordResult.entitySummary,
        })
      )
    )
  );

  /**
   * When a user updates the record.
   * Make a call to update the record on the API.
   * @memberof RecordEffects
   */
  updateRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.recordUpdate, CommonActions.recordUpdate),
      withLatestFrom(this.store.pipe(select(getColumnAttribute))),
      map(([{ recordComponentValue }, columnAttributeIds]) => {
        const { recordId, colId, attributes } = recordComponentValue;
        const columnId = Number(colId);
        const recordData = attributes[columnId];
        const record: {
          recordId: string;
          colId: string;
          value?: string | null;
          values?: (string | null)[];
        } = { recordId, colId: columnAttributeIds[columnId].attributeId };

        if (isRecordDataSingleCellInterface(recordData)) {
          record.value = recordData.singleAttribute.value || null;
        }
        if (isRecordDataMultipleCellInterface(recordData)) {
          record.values = recordData.multiAttributes.map(({ value }) => value);
        }
        return {
          record,
          recordComponentValue,
        };
      }),
      switchMap(({ record, recordComponentValue }) => {
        if (record.value)
          return this.recordService.update(record.recordId, record.colId, record.value).pipe(
            map(() => RecordActions.updateRecordSuccess({ recordComponentValue })),
            catchError((error: unknown) => of(RecordActions.updateRecordFailure({ error: `${error}` })))
          );
        // THIS TEMPORARY COE. DO NOT COPY!
        // This will need to be changed once we implement multi cell
        return of(recordComponentValue).pipe(map(() => RecordActions.updateRecordSuccess({ recordComponentValue })));
      })
    )
  );

  /**
   * When add Record button is pressed from a page with "entityId" as a route param
   * Then dispatch an event to add a Record.
   * @memberof RecordEffects
   */
  createNewRecordWithEntityId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.createNewRecord),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      map(([{ requestId }, entityId]) => RecordActions.createRecord({ requestId, entityId }))
    )
  );

  /**
   * When add Record button is pressed from a page with "entityIdForRecords" as a route param
   * Then dispatch an event to add a Record.
   * @memberof RecordEffects
   */
  createNewRecordWithEntityIdForRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.createNewRecordWithEntityIdForRecords),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityIdForRecords))),
      map(([{ requestId }, entityId]) => RecordActions.createRecord({ requestId, entityId }))
    )
  );

  /**
   * When add Record
   * Then call the service to add a Record.
   * @memberof RecordEffects
   */
  createRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.createRecord),
      switchMap(({ requestId, entityId }) =>
        this.recordService.create(entityId, requestId).pipe(
          map((record) => RecordActions.createRecordSuccess({ record })),
          catchError((error: unknown) => of(RecordActions.createRecordFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When Record is created
   * Then nvaigate to Record view.
   * @memberof RecordEffects
   */
  recordCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordActions.createRecordSuccess),
        tap(({ record }) => {
          this.router.navigate(['/', CommonRoutesEnum.RecordViewRoot, record.recordId]);
        })
      ),
    {
      dispatch: false,
    }
  );

  /**
   * When Delete Records button is pressed
   * Then dispatch an event to delete Records
   * @memberof RecordEffects
   */
  showDeleteRecordsConfirmationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.startDeleteRecords, CommonActions.startDeleteRecords),
      map(({ recordIds }) => {
        const recordsCount = recordIds.length;
        const recordOrRecords = recordIds.length === 1 ? 'Record' : 'Records';
        const title = $localize`Confirm Delete ${recordOrRecords}`;
        const content = $localize`Are you sure you want to delete ${recordsCount} ${recordOrRecords}?`;

        const confirmAction = RecordActions.deleteRecords({ recordIds });

        return CommonActions.showConfirmationModal({ title, content, confirmAction });
      })
    )
  );

  /**
   * When a user cancels or confirms the deletion of records.
   * Then close the confirm delete records modal.
   * @memberof RecordEffects
   */
  leaveDeleteRecords$ = createEffect(() =>
    this.actions$.pipe(ofType(RecordActions.completeDeleteRecords), map(CommonActions.closeConfirmationModal))
  );

  /**
   * When delete Records
   * Then call the service to delete Records
   * @memberof RecordEffects
   */
  deleteRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordActions.deleteRecords),
      switchMap(({ recordIds }) =>
        this.recordService.delete(recordIds).pipe(
          map(({ deletedIds }) => RecordActions.completeDeleteRecords({ recordIds: deletedIds })),
          catchError((error: unknown) => of(RecordActions.deleteRecordsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When export records
   * Then call the service to create an export and get the file url for download
   * @memberof RecordEffects
   */
  exportRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.exportRecords),
      switchMap(({ entityId, attributeIds, exportName, recordIds }) =>
        this.recordService.export(entityId, attributeIds, exportName, recordIds).pipe(
          map((url) => RecordActions.exportRecordsSuccess({ url })),
          catchError((error: unknown) => of(RecordActions.exportRecordsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When export records succeeds
   * Then open the file url returned.
   * @memberof RecordEffects
   */
  downloadExportedRecords$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordActions.exportRecordsSuccess),
        tap(({ url }) => {
          this.windowRef.open(url, '_blank');
        })
      ),
    { dispatch: false }
  );

  /**
   * When Record View API called failed.
   * Then log the error.
   * @memberof RecordEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RecordActions.readRecordResponseFailure,
          RecordActions.updateRecordFailure,
          RecordActions.createRecordFailure,
          RecordActions.deleteRecordsFailure
        ),
        tap(({ error, type }) => this.logger.logEvent('Record', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of RecordViewEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {LoggerService} logger The common logger service.
   * @param {RecordService} recordService The record API service.
   * @param {Store} store The NGRX store.
   * @param {Router} router The Angular Router service.
   * @param {Window} windowRef The window object.
   * @memberof RecordEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly logger: LoggerService,
    private readonly recordService: RecordService,
    private readonly store: Store,
    private readonly router: Router,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {}
}
