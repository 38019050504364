import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SectionModule } from '../../section/section.module';
import { BaseSectionComponent } from '../section-renderer-base.component';

/**
 * Section Placeholder Component
 * @export
 * @class SectionPlaceholderComponent
 * @extends {BaseSectionComponent}
 */
@Component({
  standalone: true,
  template: `
    <sc-section [heading]="sectionName" [id]="sectionId">
      <p i18n>A placeholder for a configurable section</p>
    </sc-section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'sc-common-section-placeholder',
  imports: [SectionModule],
})
export class SectionPlaceholderComponent extends BaseSectionComponent {}
