import { z } from 'zod';

/**
 * Handy parser for zod error messages.
 * @param {z.ZodIssue[]} zodIssues - incoming zod issues
 * @return {string} - a string of readable errors.
 */
export const makeHumanReadableZodErrors = (zodIssues: z.ZodIssue[]): string => {
  const humanReadableErrors = zodIssues.map(
    (issue: z.ZodIssue, index: number) =>
      `Issue (${index + 1}). On the field "${issue.path.join('.')}" validation failed because a ${
        'expected' in issue ? issue.expected : '[Unspecified]'
      } was expected. A "${issue.message ? issue.message : '[Unspecified]'}" warning was also received.`
  );
  return humanReadableErrors.join('\n');
};
