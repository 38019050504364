import { EnvironmentConfigInterface, environmentConfigZodSchema, makeHumanReadableZodErrors } from '@surecloud/common';

/**
 * Type check the runtime value for environment config.
 * @param {unknown} map - object passed in to check
 * @return {boolean} result of the type check
 */
export const isValidEnvironmentConfigGuard = (map: unknown): map is EnvironmentConfigInterface =>
  environmentConfigZodSchema.safeParse(map).success;

/**
 * Get the zod errors for a failed validation on the environment config.
 * @param {unknown} map - object passed in to check
 * @return {string} pretty errors
 */
export const getIsValidEnvironmentConfigErrors = (map: unknown): string => {
  const zodResult = environmentConfigZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};
