export enum CommonRoutesEnum {
  AttributeList = 'attributeList',
  ApplicationList = 'applicationList',
  TempApplicationList = 'tempApplicationList',
  ApplicationId = 'applicationId',
  CustomHierarchyRoot = 'customHierarchy',
  CustomHierarchyId = 'customHierarchyId',
  DashboardRoot = 'dashboard',
  DashboardId = 'dashboardId',
  DashboardComponentId = 'dashboardComponentId',
  EntityId = 'entityId',
  EntityIdForRecords = 'entityIdForRecords',
  EntityRoot = 'entity',
  ErrorRoot = 'error',
  GroupRoot = 'group',
  GroupId = 'groupId',
  LinkedEntities = 'linkedEntities',
  NotificationRoot = 'notification',
  NotificationList = 'notificationList',
  NotificationId = 'notificationId',
  PreviewData = 'previewData',
  OptionListsRoot = 'optionLists',
  OptionListId = 'optionListId',
  Overview = 'overview',
  QuestionLibraryRoot = 'questionLibrary',
  QuestionSetId = 'questionSetId',
  QuestionSetRoot = 'questionSet',
  QuestionSetList = 'questionSetList',
  RecordList = 'recordList',
  RecordViewRoot = 'record',
  RiskMatrixRoot = 'riskMatrix',
  RoleRoot = 'role',
  RoleId = 'roleId',
  UserManagementRoot = 'userManagement',
  ViewBuilderComponentId = 'viewComponentId',
  ViewBuilderQuestion = 'question',
  ViewBuilderRoot = 'viewBuilder',
  ViewBuilderSettings = 'settings',
  ViewBuilderTable = 'table',
  ViewBuilderTableTab = 'tableTab',
  ViewBuilderTableTabId = 'tableTabId',
  ViewBuilderTableTabFilter = 'filter',
  ViewBuilderTableTabFilterId = 'filterId',
  ViewId = 'viewId',
  ViewList = 'viewList',
  WorkflowRoot = 'workflow',
  GuestLink = 'guestLink',
  IDPLogin = 'idpLogin',
  IntegrationListRoot = 'integration',
  IntegrationId = 'integrationId',
}
