import { Injectable } from '@angular/core';
import { RoleApiInterface } from '@surecloud/api-types';
import { LoggerService, NormalisationOutputStrategyInterface, Normalise, RoleInterface } from '@surecloud/common';
import { schema } from 'normalizr';
import {
  NormalisedRoleList,
  getNormalisedRoleListError,
  isNormalisedRoleListGuard,
} from './normalise-get-roles.validations';

const ROLES_KEY = 'roles';

/**
 * Service encapsulating the schema and configuration for flattening the getRoles(s) API response.
 * @export
 * @class NormaliseGetRolesService
 */
@Injectable({
  providedIn: 'root',
})
export class NormaliseGetRolesService {
  /**
   * Role schema.
   * @memberof NormaliseGetRolesService
   */
  roleSchema = new schema.Entity(
    ROLES_KEY,
    {},
    {
      idAttribute: (value): string => value.roleId,
      processStrategy: (value): RoleInterface => ({
        ...value,
      }),
    }
  );

  /**
   * Role list schema.
   * @memberof NormaliseGetRolesService
   */
  roleListSchema = [this.roleSchema];

  /**
   * An array of output strategies to the process the flattened data.
   * @type {NormalisationOutputStrategyInterface[]}
   * @memberof NormaliseGetRolesService
   */
  outputStrategies: NormalisationOutputStrategyInterface[] = [
    {
      key: ROLES_KEY,
    },
  ];

  /**
   * Creates an instance of NormaliseGetRolesService.
   * @param {LoggerService} logger The application logging service.
   * @memberof NormaliseGetRolesService
   */
  constructor(private readonly logger: LoggerService) {}

  /**
   * Flatten the data structure of the roles response.
   * @param {RoleApiInterface[]} roleList Roles API response.
   * @return {NormalisedRoleList} Normalised roles data structure.
   * @memberof NormaliseGetRolesService
   */
  flatten(roleList: RoleApiInterface[]): NormalisedRoleList {
    const { roles } = Normalise.flatten(roleList, this.roleListSchema, this.outputStrategies);

    const normaliseResult = { roles };

    if (!isNormalisedRoleListGuard(normaliseResult)) {
      this.logger.logEvent('Role Feature', 'Normalise Role List Data', getNormalisedRoleListError(normaliseResult));

      throw new Error('The result of the normalised Role List does not match the expected format.');
    }

    return normaliseResult;
  }
}
