/**
 * Converts a score to text and colour
 */
export const SCORE_TO_TEXT_AND_COLOUR: Record<string, { text: string; colour: string }> = {
  '1': { text: '1', colour: 'forest-green' },
  '2': { text: '2', colour: 'apple' },
  '3': { text: '3', colour: 'clemantine' },
  '4': { text: '4', colour: 'terracotta' },
  '5': { text: '5', colour: 'crimson' },
  default: { text: '-', colour: 'default' },
};
