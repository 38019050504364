import { ScIconType, UrgencyTypeEnum } from '@surecloud/common';
import { ScIconColour } from '../../../icon/icons/icon.interface';

/**
 * Converts a Due Type Enum into a Sc Icon data
 */
export const DUE_TO_ICON_MAP: Record<string, { key: ScIconType | null; colour: ScIconColour | null }> = {
  [UrgencyTypeEnum.Due]: { key: 'alert', colour: 'orange-400' },
  [UrgencyTypeEnum.Overdue]: { key: 'alert-circle-outline', colour: 'red-300' },
  [UrgencyTypeEnum.None]: { key: 'clock', colour: 'brand-500' },
  '': { key: null, colour: null },
};

/**
 * Converts a Due Type Enum into a string
 */
export const DUE_TO_STRING_MAP: Record<string, string> = {
  [UrgencyTypeEnum.Due]: $localize`due`,
  [UrgencyTypeEnum.Overdue]: $localize`overdue`,
  [UrgencyTypeEnum.None]: $localize`none`,
  '': $localize`default`,
};

/**
 * Converts a Due Type Enum into a Aria Label
 */
export const DUE_TO_ARIA_LABEL_MAP: Record<string, string> = {
  [UrgencyTypeEnum.Due]: $localize`The priority is due`,
  [UrgencyTypeEnum.Overdue]: $localize`The priority is overdue`,
  [UrgencyTypeEnum.None]: $localize`The priority is none`,
  '': '',
};
