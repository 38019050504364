import {
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  DocumentNode,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client/core';
import { Operation } from '@apollo/client/link/core/types';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloErrorService, Auth0AndGuestUserAuthService } from '@surecloud/common';
import { HttpLink } from 'apollo-angular/http';
import { createHttpOrUploadLink } from './apollo-http-or-upload-link';
import { createSSEChainLink } from './apollo-sse-link';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

/**
 * Checks if a query is a subscription.
 * @param {DocumentNode} query The query to check.
 * @return {boolean} Returns if the query is a subscription or not.
 */
export function isSubscription(query: DocumentNode): boolean {
  const definition = getMainDefinition(query);

  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}

export const isSubscriptionFunction = (op: Operation): boolean => isSubscription(op.query);

/**
 * Creates a chain link based on the query operation.
 * @export
 * @param {ApolloErrorService} errorService The service for error handling
 * @param {HttpLink} httpLink The HTTP link.
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The auth0 service wrapper.
 * @param {string} apiUri The API URL.
 * @param {string} subscriptionsUri The Subscriptions URL.
 * @return {ApolloLink} The Apollo link.
 */
export function createChainLink(
  errorService: ApolloErrorService,
  httpLink: HttpLink,
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  apiUri: string,
  subscriptionsUri: string
): ApolloLink {
  return split(
    isSubscriptionFunction,
    createSSEChainLink(errorService, auth0AndGuestUserAuthService, subscriptionsUri),
    createHttpOrUploadLink(errorService, httpLink, auth0AndGuestUserAuthService, apiUri)
  );
}

/**
 * A Factory to create the Angular Apollo client Config
 * @export
 * @param {ApolloErrorService} errorService The service for error handling
 * @param {HttpLink} httpLink The HTTP link.
 * @param {Auth0AndGuestUserAuthService} auth0AndGuestUserAuthService The Open Auth service.
 * @param {string} apiUri The API URL.
 * @param {string} subscriptionsUri The Subscriptions URL.
 * @return {ApolloClientOptions<NormalizedCacheObject>} The Apollo client options.
 */
export function createApolloClient(
  errorService: ApolloErrorService,
  httpLink: HttpLink,
  auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
  apiUri: string,
  subscriptionsUri: string
): ApolloClientOptions<NormalizedCacheObject> {
  return {
    link: createChainLink(errorService, httpLink, auth0AndGuestUserAuthService, apiUri, subscriptionsUri),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions,
  };
}
