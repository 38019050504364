<highcharts-chart
  [Highcharts]="Highcharts"
  [constructorType]="'chart'"
  [options]="chartOptions"
  [callbackFunction]="chartCallback"
  [(update)]="update"
  [oneToOne]="true"
  class="block"
  [ngStyle]="{ 'height.px': height, 'width.px': width }">
</highcharts-chart>
