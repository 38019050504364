import { MutationOptions } from '@apollo/client';
import {
  EntityAttributePermissionCreateInputApiInterface,
  EntityAttributePermissionDeleteInputApiInterface,
  EntityAttributePermissionUpdateInputApiInterface,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';

/**
 * Create entity Attribute permission GraphQL mutation.
 */
const ENTITY_ATTRIBUTE_PERMISSION_CREATE_MUTATION = gql`
  mutation entityAttributePermissionCreateMutation($input: EntityAttributePermissionCreateInput!) {
    entityAttributePermissionCreate(input: $input) {
      createdId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for creating an entity Attribute permission.
 * @param {EntityAttributePermissionCreateInputApiInterface} input The input to create a new entity Attribute permission.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityAttributePermissionCreateMutation = (
  input: EntityAttributePermissionCreateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_PERMISSION_CREATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Delete entity Attribute permission GraphQL mutation.
 */
const ENTITY_ATTRIBUTE_PERMISSION_DELETE_MUTATION = gql`
  mutation entityAttributePermissionDeleteMutation($input: EntityAttributePermissionDeleteInput!) {
    entityAttributePermissionDelete(input: $input) {
      deletedIds
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for deleting an entity Attribute permission.
 * @param {EntityAttributePermissionDeleteInputApiInterface} input The input to delete an entity Attribute permission.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityAttributePermissionDeleteMutation = (
  input: EntityAttributePermissionDeleteInputApiInterface
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_PERMISSION_DELETE_MUTATION,
  variables: { input },
});

/**
 * Update entity Attribute permission GraphQL mutation.
 */
const ENTITY_ATTRIBUTE_PERMISSION_UPDATE_MUTATION = gql`
  mutation entityAttributePermissionUpdateMutation($input: EntityAttributePermissionUpdateInput!) {
    entityAttributePermissionUpdate(input: $input) {
      entityAttributePermission {
        entityAttributePermissionId
        readPermission
        updatePermission
        role {
          roleId
          name
        }
      }
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for updating an entity Attribute permission.
 * @param {EntityAttributePermissionUpdateInputApiInterface} input The input to update an entity Attribute permission.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityAttributePermissionUpdateMutation = (
  input: EntityAttributePermissionUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_PERMISSION_UPDATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});
