import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Position, TooltipDirective, TooltipModule } from '@progress/kendo-angular-tooltip';
import { trackByIndex } from '@surecloud/common';
import { IconButtonComponent } from '../../button/icon-button.component';
import { MatrixCellStatusEnum } from '../matrix-cell.enum';
import { MatrixCellInterface, MatrixInterface } from '../matrix.interface';
import { RatingCardColourType } from '../rating-card.type';
import { RatingScalingColourComponent } from '../rating-scaling-colour/rating-scaling-colour.component';
import { MatrixCellComponent } from './matrix-cell/matrix-cell.component';

/**
 * Matrix builder component
 * @export
 * @class MatrixBuilderComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'sc-matrix-builder',
  templateUrl: './matrix-builder.component.html',
  styleUrls: ['./matrix-builder.component.scss'],
  standalone: true,
  imports: [CommonModule, MatrixCellComponent, RatingScalingColourComponent, TooltipModule, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixBuilderComponent implements AfterViewInit {
  /**
   * Matrix data
   * @type {MatrixInterface}
   * @memberof MatrixBuilderComponent
   */
  @Input() matrix: MatrixInterface | undefined;

  /**
   * The rating scale metadata
   * @type {RatingCardColourType}
   * @memberof MatrixBuilderComponent
   */
  @Input() ratingList: RatingCardColourType[] = [];

  /**
   * If is displaying the matrix in edit mode
   * @memberof MatrixBuilderComponent
   */
  @Input() isEditable = false;

  /**
   * Display the max size of the matrix
   * @type {number}
   * @memberof MatrixBuilderComponent
   */
  @Input() matrixMaxSize = 7;

  /**
   * Display the min size of the matrix
   * @type {number}
   * @memberof MatrixBuilderComponent
   */
  @Input() matrixMinSize = 1;

  /**
   * Output an addRow event.
   * @memberof MatrixBuilderComponent
   */
  @Output() addRow = new EventEmitter<void>();

  /**
   * Output an addColumn event.
   * @memberof MatrixBuilderComponent
   */
  @Output() addColumn = new EventEmitter<void>();

  /**
   * Output a deleteRow event.
   * @memberof MatrixBuilderComponent
   */
  @Output() deleteRow = new EventEmitter<string>();

  /**
   * Output a deleteCol event.
   * @memberof MatrixBuilderComponent
   */
  @Output() deleteCol = new EventEmitter<string>();

  /**
   * Output a setCellColour event.
   * @memberof MatrixBuilderComponent
   */
  @Output() assignCell = new EventEmitter<{
    selectedCells: string[];
    ratingToAssign: RatingCardColourType;
  }>();

  /**
   * Query list of row labels
   * @type {QueryList<ElementRef>}
   * @memberof MatrixBuilderComponent
   */
  @ViewChildren('rowLabel') rowLabels!: QueryList<ElementRef>;

  /**
   * Tooltip directive
   * @type {TooltipDirective}
   * @memberof MatrixBuilderComponent
   */
  @ViewChild(TooltipDirective) tooltipDir!: TooltipDirective;

  /**
   * Matrix cell status enum
   * @memberof MatrixBuilderComponent
   */
  statusEnum = MatrixCellStatusEnum;

  /**
   * Min row label width
   * @memberof MatrixBuilderComponent
   */
  minRowLabelWidth = 0;

  /**
   * Local variable to track cell selections.
   * @memberof MatrixBuilderComponent
   */
  private selectedCells: MatrixCellInterface[] = [];

  /**
   * The maximum height of a column base on 1 line of text
   * @memberof MatrixBuilderComponent
   */
  readonly colMaxHeight = 12;

  /**
   * The maximum height of a row base on 3 line of text
   * @memberof MatrixBuilderComponent
   */
  readonly rowMaxHeight = 30;

  /**
   *
   * @private
   * @memberof MatrixBuilderComponent
   */
  readonly emptyLabel = $localize`No label`;

  /**
   * Track by cell id
   * @static
   * @param {number} _ The index
   * @param {MatrixCellType} cell The cell
   * @memberof MatrixBuilderComponent
   */
  public static readonly trackByCellId = (_: number, cell: MatrixCellInterface): string => cell.cellId;

  /**
   * Track by function
   * @memberof MatrixBuilderComponent
   */
  trackByCellIdFunction = MatrixBuilderComponent.trackByCellId;

  /**
   * Track by function
   * @memberof MatrixBuilderComponent
   */
  trackByFunction = trackByIndex;

  /**
   * Creates an instance of MatrixBuilderComponent.
   * @param {ChangeDetectorRef} cdr Change detector reference
   * @memberof MatrixBuilderComponent
   */
  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * After view init check the bigger label width
   * @memberof MatrixBuilderComponent
   */
  ngAfterViewInit(): void {
    this.minRowLabelWidth = this.calculateBiggerLabelWidth();
    this.cdr.detectChanges();
  }

  /**
   * Show tooltip if ellipsis
   * @param {Element} eventTarget The event target
   * @param {Position} position The tooltip position
   * @param {number} maxHeight The max height
   * @memberof MatrixBuilderComponent
   */
  showTooltipIfEllipsis(eventTarget: Element, position: Position, maxHeight: number): void {
    this.tooltipDir.position = position;
    this.tooltipDir.showOn = 'hover';
    const element = eventTarget as HTMLElement;
    element.style.cursor = 'pointer';

    if (element.offsetHeight > maxHeight) {
      this.tooltipDir.show(eventTarget);
    }
  }

  /**
   * Hide tooltip
   * @param {Element} eventTarget The event target
   * @memberof MatrixBuilderComponent
   */
  hideTooltip(eventTarget: Element): void {
    const element = eventTarget as HTMLElement;
    this.tooltipDir.showOn = 'none';
    element.style.cursor = 'default';
    this.tooltipDir.hide();
  }

  /**
   * Delete last column
   * @memberof MatrixBuilderComponent
   */
  deleteLastColumn(): void {
    if (this.matrix) {
      const lastRow = this.matrix.rows.length - 1;
      const lastCol = this.matrix.rows[lastRow].columns.length - 1;
      this.deleteCol.emit(this.matrix.rows[lastRow].columns[lastCol].columnId);
    }
  }

  /**
   * Respond to cell selections by maintaining a local record.
   * @memberof MatrixBuilderComponent
   */
  selectCell(cell: MatrixCellInterface): void {
    const foundCellIndex = this.selectedCells.findIndex((existingCell) => cell.cellId === existingCell.cellId);
    if (foundCellIndex > -1) {
      this.selectedCells.splice(foundCellIndex, 1);
    } else {
      this.selectedCells.push(cell);
    }
  }

  /**
   * When a cell colour is selected for cells output the colour and selections.
   * @memberof MatrixBuilderComponent
   */
  assignToCells(ratingToAssign: RatingCardColourType): void {
    const selectedCellIds = this.selectedCells.map((cell) => cell.cellId);
    this.assignCell.emit({ selectedCells: selectedCellIds, ratingToAssign });
  }

  /**
   * Remove row from the matrix
   * @return {*}  {number}
   * @memberof MatrixBuilderComponent
   */
  calculateBiggerLabelWidth(): number {
    let biggerLabelWidth = 0;

    this.rowLabels.forEach((label) => {
      const labelWidth = label.nativeElement.offsetWidth;
      if (labelWidth > biggerLabelWidth) {
        biggerLabelWidth = labelWidth;
      }
    });

    return biggerLabelWidth;
  }
}
