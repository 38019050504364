import { Dictionary } from '@ngrx/entity';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { UNTITLED } from '@surecloud/common';
import {
  DefineEntityAttributeRuleConditionType,
  EntityAttributeRuleInterface,
  EntityAttributeRuleLinkedAttributeInterface,
  hasEntityAttributeRuleDateConditionProperty,
  hasEntityAttributeRuleDateLinkedAttributeConditionProperty,
  hasEntityAttributeRuleDatePeriodConditionProperty,
  hasEntityAttributeRuleLimitProperty,
  hasEntityAttributeRuleLinkedAttributeIdProperty,
} from '../../models/rule/rule.models';
import { RULE_FEATURE_KEY, StateInterface, ruleAdapter } from '../../reducers/rule/rule.reducer';
import * as AttributeSelectors from '../attribute/attribute.selectors';

// Lookup the 'Entity Attribute Rule' feature state managed by NgRx
export const getRuleState = createFeatureSelector<StateInterface>(RULE_FEATURE_KEY);

const { selectAll, selectEntities } = ruleAdapter.getSelectors();

/**
 * Get all rules.
 */
export const getRuleList = createSelector(getRuleState, (state: StateInterface) => selectAll(state));

/**
 * Get the selected rule ID.
 */
export const getSelectedId = createSelector(getRuleState, (state: StateInterface) => state.selectedId);

/**
 * Get rules for the selected attribute.
 */
export const getSelectedAttributeRules = createSelector(
  getRuleList,
  AttributeSelectors.getSelectedId,
  (rules, selectedAttributeId) => rules.filter((rule) => rule.attributeId === selectedAttributeId)
);

/**
 * Get rules for the selected attribute.
 */
export const getSelectedAttributeRulesConditionList = createSelector(getSelectedAttributeRules, (rules) =>
  rules
    .map((rule) => rule.conditionType)
    .filter((conditionType): conditionType is DefineEntityAttributeRuleConditionType => !!conditionType)
);

/**
 * Get the rule entity map from rule state.
 */
export const getRuleEntities = createSelector(getRuleState, (state: StateInterface) => selectEntities(state));

/**
 * Get the selected rule data.
 */
export const getSelectedRule = createSelector(getRuleEntities, getSelectedId, (rules, selectedId) =>
  selectedId ? rules[selectedId] : undefined
);

/**
 * Get the rule options.
 */
export const getRuleOptions = createSelector(getRuleState, (state: StateInterface) => state.options);

/**
 * Get rules for the selected attribute.
 */
export const getSelectedAttributeRulesForDataValidation = createSelector(
  getRuleList,
  AttributeSelectors.getSelectedId,
  AttributeSelectors.getAttributeEntities,
  (rules, selectedAttributeId, attributeList) =>
    rules
      .filter((rule) => rule.attributeId === selectedAttributeId)
      .map((rule) => {
        const description = rule.conditionType || $localize`${UNTITLED}`;
        let tag = '';
        if (hasEntityAttributeRuleLinkedAttributeIdProperty(rule) && rule.linkedAttributeId) {
          tag = attributeList[rule.linkedAttributeId]?.name || $localize`${UNTITLED}`;
        }

        if (hasEntityAttributeRuleLimitProperty(rule)) {
          tag = `${rule.limit}`;
        }

        if (hasEntityAttributeRuleDatePeriodConditionProperty(rule)) {
          const translatedUnit = rule.temporalUnit ? $localize`${rule.temporalUnit}` : $localize`${UNTITLED}`;
          tag = rule.amount ? `${rule.amount} ${translatedUnit}` : $localize`${UNTITLED}`;
        }
        if (hasEntityAttributeRuleDateConditionProperty(rule) && rule.date) {
          tag = `${new Date(rule.date).toLocaleDateString()}`;
        }

        if (hasEntityAttributeRuleDateLinkedAttributeConditionProperty(rule) && rule.linkedAttributeId) {
          tag = attributeList[rule.linkedAttributeId]?.name || $localize`${UNTITLED}`;
        }

        return {
          ruleId: rule.ruleId,
          tag,
          description,
        };
      })
);

/**
 * Get the selected rules attributes for the selected attribute.
 */
export const getSelectedValidationAttributeIdsForDataValidation = createSelector(
  getRuleList,
  AttributeSelectors.getSelectedId,
  AttributeSelectors.getAttributeEntities,
  (rules, selectedAttributeId) =>
    rules
      .filter(
        (rule): rule is EntityAttributeRuleLinkedAttributeInterface =>
          rule.attributeId === selectedAttributeId &&
          hasEntityAttributeRuleLinkedAttributeIdProperty(rule) &&
          !!rule.linkedAttributeId
      )
      .map((rule) => rule.linkedAttributeId)
);

/**
 * Get a rule by rule ID.
 *
 * @param {string} ruleId The ID of the rule to get.
 * @return {EntityAttributeRuleInterface | undefined} The rule if it exists.
 */
export const getRuleById = (
  ruleId: string
): MemoizedSelector<
  object,
  EntityAttributeRuleInterface | undefined,
  (s1: Dictionary<EntityAttributeRuleInterface>) => EntityAttributeRuleInterface | undefined
> => createSelector(getRuleEntities, (rules) => rules[ruleId]);
