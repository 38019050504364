import { RendererTypeUnion } from './component-renderer-config.interface';
import { RendererControlConfigInterface } from './component-renderer-control-config.interface';
import * as fromControls from './controls';

/**
 * Renderer control configuration
 */
export const RENDERER_ALL_CONTROLS_CONFIG: Record<RendererTypeUnion, RendererControlConfigInterface> = {
  TEXT: fromControls.TEXT_CONTROL_CONFIG,
  TEXT_AREA: fromControls.TEXT_AREA_CONTROL_CONFIG,
  NUMBER: fromControls.NUMBER_CONTROL_CONFIG,
  TABLE: fromControls.TABLE_CONTROL_CONFIG,
  INFORMATION: fromControls.TABLE_CONTROL_CONFIG,
  SEQUENCE: fromControls.SEQUENCE_CONTROL_CONFIG,
  DATE: fromControls.DATE_CONTROL_CONFIG,
  TOGGLE: fromControls.TOGGLE_CONTROL_CONFIG,
  URL: fromControls.URL_CONTROL_CONFIG,
  CALCULATION: fromControls.CALCULATION_CONTROL_CONFIG,
  USER: fromControls.TEXT_CONTROL_CONFIG,
  DOCUMENT: fromControls.DOCUMENT_CONTROL_CONFIG,
  OPTION_LIST: fromControls.OPTIONLIST_CONTROL_CONFIG,
  HIERARCHY: fromControls.TEXT_CONTROL_CONFIG,
  EMAIL: fromControls.EMAIL_CONTROL_CONFIG,
  STATE: fromControls.TEXT_CONTROL_CONFIG,
  NO_TYPE: fromControls.NO_TYPE_CONTROL_CONFIG,
  QUESTION: fromControls.QUESTION_CONTROL_CONFIG,
  NAME: fromControls.TEXT_CONTROL_CONFIG,
  OWNER: fromControls.TEXT_CONTROL_CONFIG,
  CONTEXT: fromControls.TEXT_CONTROL_CONFIG,
  DROPDOWN: fromControls.TEXT_CONTROL_CONFIG,
};
