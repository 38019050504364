import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RecordTaskApiInterface } from '@surecloud/api-types';
import { NormalisedTaskList } from '../../services/normalise-get-tasks/normalise-get-tasks.validation';
import { TaskInterface, TaskTotalsInterface } from '../models/task/task.models';

/**
 * Task API actions map.
 */
const TASK_API_ACTIONS_MAP = {
  'Read Task': props<{ recordId: string; taskId: string }>(),
  'Read Task Data Success': props<{ normalisedTaskList: NormalisedTaskList }>(),
  'Read Task Success': props<{ task: TaskInterface }>(),
  'Read Task Failure': props<{ error: string }>(),

  'Read Tasks': emptyProps(),
  'Read Tasks Data Success': props<{ normalisedTaskList: NormalisedTaskList }>(),
  'Read Tasks Success': props<{ tasks: TaskInterface[] }>(),
  'Read Tasks Failure': props<{ error: string }>(),

  'Read Task Totals': emptyProps(),
  'Read Task Totals Success': props<{ tasks: RecordTaskApiInterface[] }>(),
  'Read Task Totals Failure': props<{ error: string }>(),

  'Update Task': props<{ task: TaskInterface }>(),
  'Update Task Success': props<{ task: TaskInterface }>(),
  'Update Task Failure': props<{ error: string }>(),
};

/**
 * Task API actions.
 *
 * @export
 */
export const TaskApiActions = createActionGroup({
  source: 'Task/API',
  events: TASK_API_ACTIONS_MAP,
});

/**
 * Task Effects actions map.
 */
const TASK_EFFECTS_ACTIONS_MAP = {
  'Set Task Totals': props<TaskTotalsInterface>(),
};

/**
 * Task Effects actions.
 *
 * @export
 */
export const TaskEffectsActions = createActionGroup({
  source: 'Task/Effects',
  events: TASK_EFFECTS_ACTIONS_MAP,
});
