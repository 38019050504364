/**
 * Enum to describe the different window sizes.
 */
export enum WindowSizeEnum {
  ExtraLarge = 'EXTRA_LARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  ExtraSmall = 'EXTRA_SMALL',
}

/**
 * Window Sizes.
 * NOTE: These window sizes MUST be declared in descending order as we loop these sizes and find the first match.
 */
export const WINDOW_SIZES: { [size in WindowSizeEnum]: number } = {
  [WindowSizeEnum.ExtraLarge]: 1400,
  [WindowSizeEnum.Large]: 1280,
  [WindowSizeEnum.Medium]: 768,
  [WindowSizeEnum.Small]: 480,
  [WindowSizeEnum.ExtraSmall]: 0,
};
