import { DefaultContext } from '@apollo/client';

/**
 * Makes a graphql context with Aurora request id header
 * @param {string | null} requestId the request id
 * @return {DefaultContext} the context
 */
export const makeContextWithRequestIdHeader = (requestId: string | null): DefaultContext => ({
  context: {
    headers: {
      'Aurora-Request-Id': requestId || null,
    },
  },
});
