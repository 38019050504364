import { Injectable } from '@angular/core';
import {
  EntityAttributeFormatCreatePayloadApiInterface,
  EntityAttributeFormatDeleteInputApiInterface,
  EntityAttributeFormatDeletePayloadApiInterface,
  EntityAttributeFormatUpdatePayloadApiInterface,
} from '@surecloud/api-types';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import {
  EntityAttributeCreateFormatInterface,
  EntityAttributeFormatInterface,
  EntityAttributeFormatUpdatedInterface,
} from '../../+state/models/format/format.models';
import {
  apolloEntityAttributeFormatCreateMutation,
  apolloEntityAttributeFormatDeleteMutation,
  apolloEntityAttributeFormatUpdateMutation,
} from '../../mutations/format/format.mutations';

@Injectable({
  providedIn: 'root',
})
export class EntityAttributeFormatService {
  /**
   * Creates an instance of EntityAttributeFormatService.
   * @param {Apollo} apollo The Angular Apollo service.
   * @memberof EntityAttributeFormatService
   */
  constructor(private readonly apollo: Apollo) {}

  /**
   * Create an error message for this service's API calls.
   * @private
   * @static
   * @param {string} type The type of error message to create.
   * @return {string} The error message.
   * @memberof EntityAttributeFormatService
   */
  private static createErrorMessage(type: string): string {
    return `No ${type} entity attribute format response data from ${type} format on entity attribute returned from API`;
  }

  /**
   * Update an entity's attribute format.
   * @param {EntityAttributeFormatInterface} format The entity's attribute format to update.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityAttributeFormatInterface>} The update entity's attribute format.
   * @memberof EntityAttributeFormatService
   */
  update(format: EntityAttributeFormatInterface, requestId: string | null = null ): Observable<EntityAttributeFormatInterface> {
    const { attributeId, ...entityAttributeFormatUpdateInput } = format;

    return this.apollo
      .mutate<{ entityAttributeFormatUpdate: EntityAttributeFormatUpdatePayloadApiInterface }>(
        apolloEntityAttributeFormatUpdateMutation(entityAttributeFormatUpdateInput, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeFormatUpdate
            ? of(format)
            : throwError(() => EntityAttributeFormatService.createErrorMessage('update'))
        )
      );
  }

  /**
   * Create a format on an entity's attribute.
   * @param {EntityAttributeCreateFormatInterface} format The format to create.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityAttributeFormatInterface>} The created format.
   * @memberof EntityAttributeFormatService
   */
  create(format: EntityAttributeCreateFormatInterface, requestId: string | null = null): Observable<EntityAttributeFormatInterface> {
    return this.apollo
      .mutate<{ entityAttributeFormatCreate: EntityAttributeFormatCreatePayloadApiInterface }>(
        apolloEntityAttributeFormatCreateMutation(format, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeFormatCreate
            ? of({ ...format, formatId: data.entityAttributeFormatCreate.createdId, type: null })
            : throwError(() => EntityAttributeFormatService.createErrorMessage('create'))
        )
      );
  }

  /**
   * Delete a format on an entity's attribute.
   * @param {EntityAttributeFormatInterface} format The format to delete.
   * @return {Observable<EntityAttributeFormatUpdatedInterface>} The ID of the deleted entity attribute format.
   * @memberof EntityAttributeFormatService
   */
  delete(format: EntityAttributeFormatInterface): Observable<EntityAttributeFormatUpdatedInterface> {
    const payload: EntityAttributeFormatDeleteInputApiInterface = {
      entityId: format.entityId,
      formatId: format.formatId,
    };

    return this.apollo
      .mutate<{ entityAttributeFormatDelete: EntityAttributeFormatDeletePayloadApiInterface }>(
        apolloEntityAttributeFormatDeleteMutation(payload)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeFormatDelete
            ? of({ formatId: data.entityAttributeFormatDelete.deletedId })
            : throwError(() => EntityAttributeFormatService.createErrorMessage('delete'))
        )
      );
  }
}
