import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CommonRoutesEnum } from '@surecloud/common';

@Injectable({
  providedIn: 'root',
})
export class IdpUrlParserService {
  /**
   * The key used to store & retrieve the state in the URL.
   */
  STATE_KEY = 'state';

  /**
   * The key used to store & retrieve the redirect_uri in the URL.
   */
  REDIRECT_URI_KEY = 'redirect_uri';

  /**
   * The path to the IDP login page.
   */
  IDP_LOGIN_PATH = `/${CommonRoutesEnum.IDPLogin}`;

  /**
   * Creates an instance of IdpUrlParserService.
   * @param {Window} windowRef - the browser window object
   */
  constructor(@Inject(WINDOW) readonly windowRef: Window) {}

  /**
   * Parses the URL and removes the state and redirect_uri query parameters.
   * Keeping the organization and code params to be picked up by the IDP login guard.
   */
  parse(): void {
    const url = new URL(decodeURIComponent(this.windowRef.location.href));

    if (url.pathname === this.IDP_LOGIN_PATH) {
      url.searchParams.delete(this.STATE_KEY);
      url.searchParams.delete(this.REDIRECT_URI_KEY);
      // Preferred way to update the URL without reloading the page.
      this.windowRef.history.replaceState({}, '', url.toString());
    }
  }
}
