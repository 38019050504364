import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trackByIndex } from '@surecloud/common';
import { RatingCardColourType } from '../rating-card.type';

/**
 * Rating Scaling Colour Component
 * @export
 * @class RatingScalingColourComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-rating-scaling-colour',
  templateUrl: './rating-scaling-colour.component.html',
  styleUrls: ['./rating-scaling-colour.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class RatingScalingColourComponent {
  /**
   * Rating list
   * @type {RatingCardColourType[]}
   * @memberof RatingScalingColourComponent
   */
  @Input() ratingList: RatingCardColourType[] = [];

  /**
   * Is editing
   * @type {boolean}
   * @memberof RatingScalingColourComponent
   */
  @Input() isEditing = true;

  /**
   * Selected colour
   * @memberof RatingScalingColourComponent
   */
  @Output() colourSelected = new EventEmitter<RatingCardColourType>();

  /**
   * Track by function
   * @memberof RatingScalingColourComponent
   */
  trackByFunction = trackByIndex;
}
