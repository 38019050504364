import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommonRoutesEnum, LoggerService, selectNestedRouteParam } from '@surecloud/common';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { LinkedEntityService } from '../../../services/linked-entity/linked-entity.service';
import { EntityActions } from '../../actions/entity.actions';
import { LinkedEntityTagActions } from '../../actions/linked-entity-tag.actions';
import {
  LinkedEntityTagCreateInterface,
  LinkedEntityTagRemoveInterface,
} from '../../models/linked-entity-tag/linked-entity-tag.model';

/**
 * The Effects/side effects for a Linked Entity Tag.
 *
 * @export
 * @class LinkedEntityTagEffects
 */
@Injectable({ providedIn: 'root' })
export class LinkedEntityTagEffects {
  /**
   * When all entity view data has loaded successfully,
   * Then populate the Linked Entity Tag state.
   *
   * @memberof LinkedEntityTagEffects
   */
  loadLinkedEntityTagsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        LinkedEntityTagActions.readLinkedEntityTagSuccess({ tags: normalisedEntityList.tags || [] })
      )
    )
  );

  /**
   * Initiate the procress of adding a tag on a linked entity,
   *
   * @memberof LinkedEntityTagEffects
   */
  startCreateLinkedEntityTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityTagActions.completeAddLinkedEntityTag),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      map(([{ label, linkedEntityId }, entityId]) => {
        const tag: LinkedEntityTagCreateInterface = {
          label,
          linkedEntityId,
          entityId,
        };
        return LinkedEntityTagActions.addLinkedEntityTag({ tag });
      })
    )
  );

  /**
   * Initiate the procress of adding a tag on a linked entity,
   *
   * @memberof LinkedEntityTagEffects
   */
  createLinkedEntityTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityTagActions.addLinkedEntityTag),
      switchMap(({ tag }) =>
        this.service.createTag({ entityId: tag.entityId, label: tag.label, linkedEntityId: tag.linkedEntityId }).pipe(
          map((createdTag) => LinkedEntityTagActions.addLinkedEntityTagSuccess(createdTag)),
          catchError((error: unknown) => of(LinkedEntityTagActions.addLinkedEntityTagFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * Initiate the procress of removing a tag on a linked entity,
   *
   * @memberof LinkedEntityTagEffects
   */
  startRemoveLinkedEntityTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityTagActions.completeDeleteLinkedEntityTag),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      map(([{ tagId, linkedEntityId }, entityId]) => {
        const tag: LinkedEntityTagRemoveInterface = {
          tagId,
          linkedEntityId,
          entityId,
        };
        return LinkedEntityTagActions.deleteLinkedEntityTag({ tag });
      })
    )
  );

  /**
   * Initiate the procress of deleting a tag on a linked entity,
   *
   * @memberof LinkedEntityTagEffects
   */
  deleteLinkedEntityTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityTagActions.deleteLinkedEntityTag),
      switchMap(({ tag }) =>
        this.service.deleteTag(tag).pipe(
          map(({ deletedId }) => LinkedEntityTagActions.deleteLinkedEntityTagSuccess({ deletedId })),
          catchError((error: unknown) => of(LinkedEntityTagActions.deleteLinkedEntityTagFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When Linked Entity Tag API called failed.
   * Then log the error.
   *
   * @memberof LinkedEntityTagEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LinkedEntityTagActions.deleteLinkedEntityTagFailure, LinkedEntityTagActions.addLinkedEntityTagFailure),
        tap(({ error, type }) => this.logger.logEvent('Linked Entity Tag', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of LinkedEntityTagEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param service
   * @param {Store} store The NGRX store.
   * @param {LoggerService} logger The common logger service.
   * @memberof LinkedEntityTagEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly service: LinkedEntityService,
    private readonly store: Store,
    private readonly logger: LoggerService
  ) {}
}
