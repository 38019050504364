export const DEFAULT_COLOUR_PICKER_COLOURS = [
  '#2E7D32',
  '#4CAF50',
  '#FFC107',
  '#FF6F00',
  '#F44336',
  '#B71C1C',
  '#5793C1',
  '#129BB0',
  '#F06292',
  '#BA68C8',
  '#B07EA7',
  '#8989BB',
];
