import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonRoutesEnum } from '@surecloud/common';
import { SidebarLayoutOverlayComponentService } from '@surecloud/design';
import { TaskApiActions, TaskInterface } from '@surecloud/task-state';
import { map, tap, withLatestFrom } from 'rxjs';
import { ToolbarTasksPageActions } from '../../actions/toolbar-task.actions';
import { ToolbarUserMenuPageActions } from '../../actions/toolbar-user-menu.actions';

/**
 * The Effects/side effects for Toolbar Tasks.
 * @export
 * @class ToolbarTaskEffects
 */
@Injectable({ providedIn: 'root' })
export class ToolbarTaskEffects {
  /**
   * When the user toggles the toolbar, it opens or closes the tasks sidebar
   * @memberof ToolbarTaskEffects
   */
  toggleToolbarTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolbarTasksPageActions.toggle),
      withLatestFrom(this.overlay.stream$),
      map(([{ open }, show]) => {
        const toggle = open || !show;
        this.overlay.update(toggle);
        return toggle ? ToolbarTasksPageActions.open() : ToolbarTasksPageActions.close();
      })
    )
  );

  /**
   * When the Toolbar User Menu overlay is opened.
   * Then close the Toolbar Tasks overlay
   * @memberof ToolbarTaskEffects
   */
  closeToolbarTasks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToolbarUserMenuPageActions.open),
        withLatestFrom(this.overlay.stream$),
        tap(([, show]) => {
          if (show) {
            this.overlay.update(false);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * When a user enters the Task List component.
   * Then ask to load the slimline Task data for the Task and new Task totals.
   * @memberof ToolbarTaskEffects
   */
  readTaskTotalsFromPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolbarTasksPageActions.enter),
      map(() => TaskApiActions.readTaskTotals())
    )
  );

  /**
   * When a user opens the Task List component.
   * Then ask to load the Task data.
   * @memberof ToolbarTaskEffects
   */
  readTaskDataFromPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolbarTasksPageActions.open),
      map(() => TaskApiActions.readTasks())
    )
  );

  /**
   * When a user has selected a Task.
   * Then mark that Task as read on the API.
   * @memberof ToolbarTaskEffects
   */
  markTaskAsReadFromPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolbarTasksPageActions.select),
      map(({ task }): TaskInterface => ({ ...task, newTask: false })),
      map((task) => TaskApiActions.updateTask({ task }))
    )
  );

  /**
   * When a Task has been selected.
   * Then navigate a user to that Task's record.
   * @memberof ToolbarTaskEffects
   */
  navigateToRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToolbarTasksPageActions.select),
        tap(({ task }) => {
          // eslint-disable-next-line no-void
          void this.router.navigate([CommonRoutesEnum.RecordViewRoot, task.recordId]);
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of ToolbarTaskEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {Router} router The Angular Router.
   * @param {SidebarLayoutOverlayComponentService} overlay The sidebar overlay service
   * @memberof ToolbarTaskEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly overlay: SidebarLayoutOverlayComponentService
  ) {}
}
