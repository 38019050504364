<div
  class="sc-input-text-list sc-mb-8"
  [ngStyle]="{
    'height': items.length ? height + 'px' : 'auto',
  }">
  <ng-scrollbar>
    <form class="sc-input-text-list__form" [formGroup]="form">
      <div
        *ngFor="let control of form.controls | keyvalue; let index = index; trackBy: trackByFunction"
        class="flex sc-mb-8 sc-input-text-list__item">
        <sc-matrix-side-panel-block
          label="{{ metadata[control.key].label ? metadata[control.key].label : index + 1 }}"
          class="sc-mr-8"
          [background]="metadata[control.key].background"
          [colour]="metadata[control.key].colour">
        </sc-matrix-side-panel-block>
        <sc-input-text
          class="w-full"
          placeholder="Input text"
          i18n-placeholder
          [controlName]="control.key"
          [maxLength]="maxLength">
        </sc-input-text>
        <sc-delete-button
          class="sc-ml-8"
          size="large"
          (deleteClicked)="remove(control.key)"
          *ngIf="index | isInputTextListItemDeletable : items.length : minimum">
        </sc-delete-button>
      </div>
    </form>
  </ng-scrollbar>
</div>
<div [class.sc-mr-4]="items.length">
  <sc-loading-button
    i18n
    size="large"
    themeColour="green"
    class="block w-full sc-mb-24"
    [disabled]="items.length === maximum"
    [error]="error"
    [fullWidth]="true"
    [loading]="loading"
    (startLoad)="addItem.emit()">
    Add New {{ label }}
  </sc-loading-button>
</div>
