import { DatePickerComponent } from '../../../date-picker/date-picker.component';
import { validationDateControlComponentCreator } from '../creators/date-control-creator.config';
import { ValidationRendererControlConfigInterface } from '../validation-component-renderer-control-config.interface';

/**
 * Control configuration for DATE renderer component
 *
 */
export const DATE_CONTROL_CONFIG: ValidationRendererControlConfigInterface = {
  component: DatePickerComponent,
  creatorFn: validationDateControlComponentCreator,
};
