import { InputUrlComponent } from '../../input-url/input-url.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for URL renderer component
 *
 */
export const URL_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: InputUrlComponent,
  creatorFn: formControlComponentCreator,
};
