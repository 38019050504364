import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlContainer, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { trackByIndex } from '@surecloud/common';
import Quill from 'quill';
import Toolbar from 'quill/modules/toolbar';
import { Subject, debounceTime } from 'rxjs';
import { IconButtonComponent } from '../button/icon-button.component';
import { DeleteButtonComponent } from '../delete-button/delete-button.component';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';
import { FormatTypesBlot } from './blots/format-types/format-types.blot';
import {
  EDITOR_BACKGROUND_COLOURS,
  EDITOR_DEFAULT_FORMAT,
  EDITOR_FORMATS,
  EDITOR_NO_HIGHTLIGHT,
} from './editor.component.constants';
import { ExtendedSnowTheme } from './theme/extended-snow.theme';

/**
 * Generic Editor Component that wraps [PrimeNG](https://primeng.org/editor).
 * Further documentation on for the underlying [Quill JS Library](https://quilljs.com/)
 * @implements {OnInit}
 * @export
 * @class EditorComponent
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconButtonComponent,
    DeleteButtonComponent,
    LabelModule,
    InputsModule,
  ],
  selector: 'sc-editor',
  standalone: true,
  styleUrls: ['./editor.component.scss'],
  templateUrl: './editor.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
export class EditorComponent extends ControlValueAccessorConnector<string> implements AfterViewInit, OnInit {
  /**
   * Current instance of the Quill editor for the component.
   * @private
   * @type {(Quill | undefined)}
   * @memberof EditorComponent
   */
  private editor: Quill | undefined;

  /**
   * Sets the name of the form control, this needs to match the form control name on the parent form group.
   * @memberof EditorComponent
   */
  @Input() controlName = '';

  /**
   * Sets the description of the component.
   * @memberof EditorComponent
   */
  @Input() description = '';

  /**
   * The height of the editor.
   * Has to be in pixels otherwise PrimeNG does not set it.
   * @memberof EditorComponent
   */
  @Input() height = 300;

  /**
   * Sets the label for the component.
   * @memberof EditorComponent
   */
  @Input() label = '';

  /**
   * Please holder for the editor when the editor is empty.
   * @memberof EditorComponent
   */
  @Input() placeholder = '';

  /**
   * Is the editor read only?
   * @memberof EditorComponent
   */
  @Input() readonly = false;

  /**
   * Constants we need to expose to the view template.
   * @memberof EditorComponent
   */
  CONSTANTS = {
    EDITOR_BACKGROUND_COLOURS,
    EDITOR_DEFAULT_FORMAT,
    EDITOR_FORMATS,
  };

  /**
   * Track by function for Angular loops.
   * @memberof EditorComponent
   */
  trackByFunction = trackByIndex;

  /**
   * Subject to handle text changes.
   * @private
   * @type {Subject<void>}
   * @memberof EditorComponent
   */
  private textChange$ = new Subject<void>();

  /**
   * The editor container reference.
   * @type {ElementRef}
   * @memberof EditorComponent
   */
  @ViewChild('editor', { static: true }) editorContainer!: ElementRef;

  /**
   * Creates an instance of EditorComponent.
   * @param {ControlContainer} controlContainer The control container.
   * @param {DomSanitizer} sanitizer The DomSanitizer service.
   * @memberof EditorComponent
   */
  constructor(@Optional() controlContainer: ControlContainer, private sanitizer: DomSanitizer) {
    super(controlContainer);
  }

  /**
   * When the component starts.
   * Then register our custom Blots with Quill.
   * And set the parentFormGroup.
   * @memberof EditorComponent
   */
  ngOnInit(): void {
    Quill.register({ 'modules/toolbar': Toolbar });
    Quill.register({ 'themes/extendedSnow': ExtendedSnowTheme });
    Quill.register({ 'formats/sc-formats': FormatTypesBlot });
  }

  ngAfterViewInit(): void {
    this.editor = new Quill(`#${this.controlName}-editor`, {
      theme: 'extendedSnow',
      modules: {
        toolbar: `#${this.controlName}-toolbar`,
      },
      placeholder: this.placeholder,
      readOnly: this.readonly,
    });

    const toolbar = this.editor.getModule('toolbar') as Toolbar;

    if (this.editor && toolbar) {
      toolbar.addHandler('background', this.formatBackground.bind(this));
    }

    const initialContent = this.control.value;

    // Set the initial value of the control to the current content of the editor
    if (this.editor && initialContent) {
      this.editor.clipboard.dangerouslyPasteHTML(initialContent);
    }

    // Handle text changes with a debounce time for better performance
    this.textChange$.pipe(debounceTime(300)).subscribe(() => {
      if (this.editor) {
        // Now you can access the innerHTML of the editor
        const content = this.editor.root.innerHTML;
        this.control.setValue(content);
      }
    });

    // Listen for text-change (works for text change or format change) events from Quill
    this.editor.on('text-change', () => {
      this.textChange$.next();
    });
  }

  /**
   * Set the background highlight format in the editor.
   * Can be no colour in which case we stop formatting.
   * @param {string} colour The colour to set the background highlight to.
   * @memberof EditorComponent
   */
  formatBackground(colour: string): void {
    const value = colour === EDITOR_NO_HIGHTLIGHT ? false : colour;

    if (this.editor) {
      this.editor.format('background', value);
    }
  }

  /**
   * Clear any formatting on the selection / line.
   * @memberof EditorComponent
   */
  formatClear(): void {
    if (this.editor) {
      const range = this.editor.getSelection() ?? { index: 0, length: 0 };
      this.editor.removeFormat(range.index, range.length);
    }
  }

  /**
   * Format a link.
   * Will remove link formatting if already formatted.
   * @memberof EditorComponent
   */
  formatLink(): void {
    if (this.editor) {
      const format = this.editor.getFormat()['link'] ? false : 'http://example.com';

      this.editor.format('link', format);
    }
  }

  /**
   * Format a ordered or bullet list.
   * Will remove listformatting if already formatted.
   * @param {('bullet' | 'ordered')} type The type of list to format.
   * @memberof EditorComponent
   */
  formatList(type: 'bullet' | 'ordered'): void {
    if (this.editor) {
      const currentFormat = this.editor.getFormat()['list'];
      const format = currentFormat && currentFormat === type ? false : type;

      this.editor.format('list', format);
    }
  }

  /**
   * Format text to either bold, italic or underlined.
   * Will remove formatting if already formatting this way.
   * @param {('bold' | 'italic' | 'underline')} format The type of format to apply.
   * @memberof EditorComponent
   */
  formatText(format: 'bold' | 'italic' | 'underline'): void {
    if (this.editor) {
      const isFormatted = this.editor.getFormat()[format];
      this.editor.format(format, !isFormatted);
    }
  }
}
