import { UserAvatarType } from './user-avatar.model';

/**
 * Type Guard for User Avatar
 *
 * @param {unknown} userAvatar - The User Avatar to check.
 * @return {boolean} - true or false
 */
export const isUserAvatarGuard = (userAvatar: unknown): userAvatar is UserAvatarType => userAvatar !== undefined && (userAvatar as UserAvatarType)?.value !== undefined && (userAvatar as UserAvatarType)?.display !== undefined && (userAvatar as UserAvatarType)?.colour !== undefined && (userAvatar as UserAvatarType)?.initials !== undefined;

/**
 * Type Guard for Arrays
 *
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfUserAvatarGuard = (value: unknown): value is Array<UserAvatarType> =>
  Array.isArray(value) && value.every((item) => isUserAvatarGuard(item) );
