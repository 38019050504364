import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSize, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { ToggleComponent } from '../toggle/toggle.component';
/**
 * Surecloud named toggle Component that wraps the toggle component.
 *
 * @export
 * @class NamedToggleComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sc-named-toggle',
  standalone: true,
  styleUrls: ['./named-toggle.component.scss'],
  templateUrl: './named-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    E2eHookDirective,
    ToggleComponent,
    LabelModule,
    InputsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NamedToggleComponent implements OnInit {
  /**
   * Sets the text value for the label.
   *
   * @memberof NamedToggleComponent
   */
  @Input() label = '';

  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof NamedToggleComponent
   */
  @Input() controlName = '';

  /**
   * Sets the size of the named-toggle.
   *
   * @type {InputSize}
   * @memberof NamedToggleComponent
   */
  @Input() size: InputSize = 'small';

  /**
   * The test hook to pass in.
   *
   * @memberof NamedToggleComponent
   */
  @Input()
  testhook!: string;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   *
   * @type {FormGroup}
   * @memberof NamedToggleComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   *Sets the description text
   *
   * @type {string}
   * @memberof NamedToggleComponent
   */
  @Input()
  description = '';

  /**
   * Sets the additional classes
   *
   * @type {string}
   * @memberof NamedToggleComponent
   */
  @Input()
  additionalClasses = '';

  /**
   * Sets the readonly state.
   *
   * @type {boolean}
   * @memberof NamedToggleComponent
   */
  @Input() readonly = false;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   *
   * @memberof NamedToggleComponent
   */
  @Input() marginBottom = false;

  /**
   * Creates an instance of NamedToggleComponent.
   *
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof NamedToggleComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
