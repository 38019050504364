import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserMenuComponentService } from '@surecloud/design';
import { map, tap, withLatestFrom } from 'rxjs';
import { ToolbarTasksPageActions } from '../../actions/toolbar-task.actions';
import { ToolbarUserMenuPageActions } from '../../actions/toolbar-user-menu.actions';

/**
 * The Effects/side effects for Toolbar user menu.
 * @export
 * @class ToolbarUserMenuEffects
 */
@Injectable({ providedIn: 'root' })
export class ToolbarUserMenuEffects {
  /**
   * When the user toggles the toolbar, it opens or closes the UserMenu sidebar
   * @memberof ToolbarUserMenuEffects
   */
  toggleToolbarUserMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolbarUserMenuPageActions.toggle),
      withLatestFrom(this.overlay.stream$),
      map(([, show]) => {
        const offset = { x: 374, y: 50 };
        const position = { left: this.window.innerWidth - offset.x, top: offset.y };
        const value = !show ? position : false;

        this.overlay.update(value);
        return show ? ToolbarUserMenuPageActions.close() : ToolbarUserMenuPageActions.open();
      })
    )
  );

  /**
   * When the Toolbar Tasks overlay is opened.
   * Then close the Toolbar User Menu overlay.
   * @memberof ToolbarUserMenuEffects
   */
  closeToolbarUserMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToolbarTasksPageActions.open),
        withLatestFrom(this.overlay.stream$),
        tap(([, show]) => {
          if (show) {
            this.overlay.update(false);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of ToolbarUserMenuEffects.
   * @param {Window} window The window object.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {UserMenuComponentService} overlay The user menu overlay service
   * @memberof ToolbarUserMenuEffects
   */
  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly actions$: Actions,
    private readonly overlay: UserMenuComponentService
  ) {}
}
