import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonIconModule } from '../../icon/icons/common-icon.module';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { UserAvatarType } from '../user-avatar.model';

@Component({
  selector: 'sc-user-avatar-chip',
  templateUrl: './user-avatar-chip.component.html',
  styleUrls: ['./user-avatar-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UserAvatarComponent, CommonIconModule],
})
export class UserAvatarChipComponent {
  /**
   * Set the user data to create a chip
   *
   * @type {UserAvatarType}
   * @memberof UserAvatarChipComponent
   */
  @Input() userAvatarChip!: UserAvatarType;

  /**
   * Set if chip can be removable
   *
   * @memberof UserAvatarChipComponent
   */
  @Input() isRemovable = true;

  /**
   * Set the border around element
   *
   * @memberof UserAvatarChipComponent
   */
  @Input() hasBorder = true;

  /**
   * Emitte the user when icon click
   *
   * @memberof UserAvatarChipComponent
   */
  @Output() remove = new EventEmitter<UserAvatarType>();
}
