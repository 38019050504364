import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NormalisedPriorityTasks } from '../../services/normalise-get-priority-tasks/normalise-get-priority-tasks.validation';
import { PriorityTasksInterface } from '../models/priority-tasks/priority-tasks.models';

/**
 * PriorityTasks API actions map.
 */
const PRIORITYTASKS_API_ACTIONS_MAP = {
  'Read Priority Tasks': emptyProps(),
  'Read Priority Tasks Data Success': props<{ normalisedPriorityTasks: NormalisedPriorityTasks }>(),
  'Read Priority Tasks Success': props<{ priorityTasks: PriorityTasksInterface }>(),
  'Read Priority Tasks Failure': props<{ error: string }>(),
};

/**
 * PriorityTasks API actions.
 *
 * @export
 */
const PriorityTasksApiActions = createActionGroup({
  source: 'PriorityTasks/API',
  events: PRIORITYTASKS_API_ACTIONS_MAP,
});

/**
 * PriorityTasks page actions
 */
export const PriorityTasksPageActions = {
};

/**
 * Combined PriorityTasks API and PriorityTasks Page actions.
 */
export const PriorityTasksActions = {
  ...PriorityTasksApiActions,
  ...PriorityTasksPageActions,
};
