<div class="flex flex-column justify-content-between h-full">
  <div class="sc-modal__content flex-grow-1 flex">
    <sc-icon
      *ngIf="remove"
      key="alert-circle-outline"
      [width]="40"
      [height]="40"
      colour="red-500"
      class="sc-mr-16"></sc-icon>
    <p>{{ content }}</p>
  </div>
  <div class="sc-modal-buttons flex justify-content-end sc-pt-16">
    <button sc-text-button i18n size="large" themeColour="primary" fillMode="outline" (click)="cancel()">Cancel</button>
    <button
      sc-text-button
      i18n
      class="ml-2"
      fillMode="fill"
      size="large"
      [themeColour]="remove ? 'red' : 'primary'"
      (click)="confirm()">
      {{ confirmButtonLabel }}
    </button>
  </div>
</div>
