<div [formGroup]="form" class="flex flex-column w-full" scCommonE2eHook="{{ testhook }}:edit-score">
  <h4 i18n class="sc-text-brand-500 sc-mb-16">Edited Score</h4>
  <div
    class="sc-border-dashed--neutral-40 sc-border-radius-8 sc-mh-72 sc-p-6 sc-mb-16 flex flex-row justify-content-center">
    <div class="flex flex-column sc-mh-60 align-items-center">
      <p class="sc-font-button-regular sc-mb-12" i18n>Original Score</p>
      <sc-score [score]="originalScore"></sc-score>
    </div>
    <sc-icon
      class="align-self-end sc-mb-5 sc-mx-8"
      colour="brand-500"
      key="arrow-right"
      [height]="14"
      [width]="16"></sc-icon>
    <div class="flex flex-column sc-mh-60 align-items-center">
      <p class="sc-font-button-regular sc-mb-12" i18n>New Score</p>
      <sc-score [score]="newScore"></sc-score>
    </div>
  </div>
  <div *ngIf="!readonly" class="flex flex-row sc-mb-16 justify-content-center align-items-center">
    <p class="sc-font-table-entry sc-mr-8" i18n>Reset to original</p>
    <sc-icon-button
      class="sc-mr-14"
      themeColour="outline"
      shape="rounded"
      size="small"
      icon="sync"
      (click)="resetClicked.emit()"></sc-icon-button>
    <p class="sc-font-table-entry sc-mr-8" i18n>Edit this score</p>
    <sc-icon-button
      themeColour="outline"
      shape="rounded"
      size="small"
      icon="edit"
      (click)="editClick($event)"></sc-icon-button>
  </div>
  <sc-input-text
    label="Justification"
    i18n-label
    i18n-description
    i18n-placeholder
    [readonly]="true"
    controlName="justification"></sc-input-text>
</div>
