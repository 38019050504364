import { Injectable } from '@angular/core';
import { PriorityTasksApiInterfaceQueryService } from '@surecloud/api-types';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { NormalisedPriorityTasks } from '../normalise-get-priority-tasks/normalise-get-priority-tasks.validation';
import { NormaliseGetPriorityTasksService } from '../normalise-get-priority-tasks/normalise-get-priority-tasks.service';

export const PRIORITY_TASKS_READ_ALL_ERROR = 'No response data from read all Priority Tasks returned from API.';

/**
 * PriorityTasks API service.
 * @export
 * @class PriorityTasksService
 */
@Injectable({
  providedIn: 'root',
})
export class PriorityTasksService {
  /**
   * Creates an instance of PriorityTasksService
   * @param {PriorityTasksApiInterfaceQueryService} priorityTasksApiInterfaceQueryService The service to fetch all PriorityTaskss.
   * @param {NormaliseGetPriorityTasksService} normaliseGetPriorityTasksService The PriorityTasks data normalising service.
   * @memberof PriorityTasksService
   */
  constructor(
    private readonly priorityTasksApiInterfaceQueryService: PriorityTasksApiInterfaceQueryService,
    private readonly normaliseGetPriorityTasksService: NormaliseGetPriorityTasksService
  ) {}

  /**
   * Query to get all PriorityTaskss.
   * @return {Observable<NormalisedPriorityTasks>} The PriorityTasks normalised data.
   * @memberof PriorityTasksService
   */
  readAll(): Observable<NormalisedPriorityTasks> {
    return this.priorityTasksApiInterfaceQueryService
      .fetch()
      .pipe(
        switchMap(({ data }) =>
          data?.priorityTasks
            ? of(this.normaliseGetPriorityTasksService.extract(data.priorityTasks))
            : throwError(() => PRIORITY_TASKS_READ_ALL_ERROR)
        )
      );
  }
}
