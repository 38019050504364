import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseFacade } from '@surecloud/common';
import * as PriorityTasksSelectors from '../selectors/priority-tasks/priority-tasks.selectors';

/**
 * The facade for the PriorityTasks feature sits between the component layer and the
 * state layer and is responsible for providing store observables to and dispatching actions from the view layer.
 *
 * @export
 * @class PriorityTasksFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class PriorityTasksFeatureFacade extends BaseFacade {
  /**
   * Priority Tasks.
   *
   * @memberof PriorityTasksFeatureFacade
   */
  priorityTasks$ = this.store.pipe(select(PriorityTasksSelectors.getPriorityTasks));

  /**
   * Boolean to determine if the priority tasks have been loaded.
   *
   * @memberof PriorityTasksFeatureFacade
   */
  loaded$ = this.store.pipe(select(PriorityTasksSelectors.getPriorityTasksLoaded));

  /**
   * Priority Tasks mapped for ag-grid
   *
   * @memberof PriorityTasksFeatureFacade
   */
  tasksForGrid$ = this.store.pipe(select(PriorityTasksSelectors.getTasksForGrid));

  /**
   * Total priority tasks
   *
   * @memberof PriorityTasksFeatureFacade
   */
  totalTasks$ = this.store.pipe(select(PriorityTasksSelectors.getTotalIncomplete));

  /**
   * Creates an instance of PriorityTasksFeatureFacade.
   *
   * @param {Store} store The NGRX application state store.
   * @memberof PriorityTasksFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
