import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonSize, ButtonsModule } from '@progress/kendo-angular-buttons';
import { Observable, Subject, debounceTime, takeUntil } from 'rxjs';

import { ScButtonFillMode, ScButtonThemeColour } from './button.constants';
import { TextButtonComponent } from './text-button.component';

/**
 * Loading button state
 * @export
 * @enum LoadingButtonStateEnum
 */
export enum LoadingButtonStateEnum {
  NORMAL = 'normal',
  LOADING = 'loading',
  ERROR = 'error',
}

/**
 * The duration the error state is kept for
 */
const ERROR_STATE_DURATION = 3000;

/**
 * Surecloud Loading Button Component.
 * @example
 * <sc-loading-button [size]="large" (startLoad)="onClickMe()"></sc-loading-button>
 * @export
 * @class LoadingButtonComponent
 */
@Component({
  selector: 'sc-loading-button',
  standalone: true,
  imports: [TextButtonComponent, ButtonsModule, CommonModule],
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LoadingButtonComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Sets the button type.
   * @memberof LoadingButtonComponent
   */
  @Input() buttonType = 'button';
  /**
   * Sets the preset size of the loading button.
   * @type {ButtonSize}
   * @memberof LoadingButtonComponent
   */
  @Input() size: ButtonSize = 'medium';

  /**
   * Sets if the button is disabled
   * @memberof LoadingButtonComponent
   */
  @Input() disabled = false;

  /**
   * Sets the preset size of the text button.
   * @type {ScButtonFillMode}
   * @memberof TextButtonComponent
   */
  @Input() fillMode: ScButtonFillMode = 'fill';

  /**
   * Sets the theme colour of the text button.
   * @type {ScButtonThemeColour}
   * @memberof TextButtonComponent
   */
  @Input() themeColour: ScButtonThemeColour = 'brand';

  /**
   * Used to reset event state
   * @memberof LoadingButtonComponent
   * @type {Observable<void>}
   */
  @Input() loading? = false;

  /**
   * Receives errors
   * @memberof LoadingButtonComponent
   * @type {Observable<string>}
   */
  @Input() error?: string;

  /**
   * Should the button be full width.
   * @memberof LoadingButtonComponent
   * @type {Observable<string>}
   */
  @Input() fullWidth = false;

  /**
   * Event is emitted once button is clicked
   * @memberof LoadingButtonComponent
   * @type {EventEmitter<Event>}
   */
  @Output() startLoad: EventEmitter<Event> = new EventEmitter();

  /**
   * The state of the component
   * @memberof LoadingButtonComponent
   * @type {LoadingButtonStateEnum }
   */
  state = LoadingButtonStateEnum.NORMAL;

  /**
   * Used to reset component state
   * @memberof LoadingButtonComponent
   * @type {Subject<void>}
   */
  private resetError$ = new Subject<void>();

  /**
   * Emits when the component has been destroyed. Use this to tear down all the component observables.
   * @memberof LoadingButtonComponent
   * @type {Subject<void>}
   */
  private destroyed$ = new Subject<void>();

  constructor(private readonly changes: ChangeDetectorRef) {}

  /**
   * Initialize component.
   * @memberof LoadingButtonComponent
   */
  ngOnInit(): void {
    this.resetError$
      .pipe(debounceTime(ERROR_STATE_DURATION), takeUntil(this.destroyed$))
      .subscribe(() => this.handleErrorReset());
  }

  /**
   * Handle error changes.
   * @param {SimpleChanges} changes The changes.
   * @memberof LoadingButtonComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if ('error' in changes || 'loading' in changes) {
      this.updateState();
    }
  }

  /**
   * Update state on changes.
   * @memberof LoadingButtonComponent
   */
  private updateState(): void {
    if (this.loading) {
      this.state = LoadingButtonStateEnum.LOADING;
    } else if (this.error) {
      this.state = LoadingButtonStateEnum.ERROR;
      this.resetError$.next();
    } else {
      this.state = LoadingButtonStateEnum.NORMAL;
    }
  }

  /**
   * Unsubscribe from subscriptions.
   * @memberof LoadingButtonComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Resets the component to normal state unless it is already in loading state
   * @param {Event} $event the pointer event
   * @memberof LoadingButtonComponent
   */
  handleClick($event: Event): void {
    if (!this.loading) {
      this.startLoad.emit($event);
    }
  }

  /**
   * Resets the component to normal state unless it is already in loading state
   * @memberof LoadingButtonComponent
   */
  private handleErrorReset(): void {
    this.error = undefined;
    if (this.state !== LoadingButtonStateEnum.LOADING) {
      this.state = LoadingButtonStateEnum.NORMAL;
    }
    this.changes.detectChanges();
  }
}
