import { isStringNumberGuard } from '@surecloud/common';
import { TextMatcherParams } from 'ag-grid-community';
import { startOfDay } from 'date-fns';
import { isArrayOfUserAvatarGuard } from '../user-selects/is-user-avatar.guard';
import { UserAvatarType } from '../user-selects/user-avatar.model';

// Comparators
/** @type {string} separator for numbers with a prefix used by the BE */
const PREFIX_SEPARATOR = '-';

/**
 * Compares two numbers.
 * @param { number } valueA The first number to compare.
 * @param { number } valueB The second number to compare.
 * @return { number } A negative number if valueA is less than valueB, a positive number if valueA is greater than valueB, or 0 if they are equal.
 */
export const numericComparator = (valueA: number, valueB: number): number => valueA - valueB;

/**
 * Compares two strings that have a prefix followed by a number.
 * The comparison is based on the numeric value of the numbers.
 * If the strings do not have a prefix - they are compared as numbers.
 * @param {string} valueA - The first string to compare.
 * @param {string} valueB - The second string to compare.
 * @return {number} A negative number if valueA is less than valueB, a positive number if valueA is greater than valueB, or 0 if they are equal.
 */
export const numberWithPrefixComparator = (valueA: string, valueB: string): number => {
  // check if the values have the separator and if there is none pass the values as they are
  if (isStringNumberGuard(valueA) && isStringNumberGuard(valueB)) {
    return numericComparator(Number(valueA), Number(valueB));
  }

  // split the values by the separator and get the last element of the array
  const valueAWithoutPrefix = valueA.split(PREFIX_SEPARATOR).at(-1);
  const valueBWithoutPrefix = valueB.split(PREFIX_SEPARATOR).at(-1);
  const valueANumber = Number(valueAWithoutPrefix) || 0;
  const valueBNumber = Number(valueBWithoutPrefix) || 0;

  return numericComparator(valueANumber, valueBNumber);
};

/**
 * Compares two arrays of UserAvatarType. The comparison is based on the display property of the first element of the array.
 * @param {UserAvatarType[]} valueA - The first array of UserAvatarType to compare.
 * @param {UserAvatarType[]} valueB - The second array of UserAvatarType to compare.
 * @return {number} A negative number if valueA is less than valueB, a positive number if valueA is greater than valueB, or 0 if they are equal.
 */
export const userAvatarComparator = (valueA: UserAvatarType[], valueB: UserAvatarType[]): number => {
  const valA = valueA[0]?.display || '';
  const valB = valueB[0]?.display || '';

  if (valA === valB) {
    return 0;
  }

  if (valA === '') {
    return -1;
  }

  if (valB === '') {
    return 1;
  }

  return valA.localeCompare(valB);
};

// Text matchers
/**
 * Text matcher for user avatar column.
 * @param {TextMatcherParams} params - The params object coming from ag-grid.
 * @return {boolean} True if the filterText is found in the user avatar column.
 */
export const userAvatarTextMatcher = (params: TextMatcherParams): boolean => {
  const { value, filterText } = params;
  if (!isArrayOfUserAvatarGuard(value) || !filterText) {
    return false;
  }
  const user = value.map(({ display, initials }) => `${display} ${initials}`).join(' ');
  return user.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
};

export const dateComparator = (filterDate: Date, cellValue: string): number => {
  const cellDate = startOfDay(new Date(cellValue));
  const filterLocalDate = startOfDay(filterDate);

  if (cellDate < filterLocalDate) {
    return -1;
  }

  if (cellDate > filterLocalDate) {
    return 1;
  }
  return 0;
};
