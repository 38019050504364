<section class="sc-categories">
  <div class="sc-border-dashed--neutral-40 sc-border-radius-16 sc-mb-16 sc-pb-8">
    <div class="flex justify-content-between align-items-center sc-mx-12 sc-mt-8">
      <h2 class="sc-text sc-font-subtitle">{{ heading }}</h2>
      <p *ngIf="label && total > 0" class="sc-text-grey-600 sc-font-guidance-text" i18n>
        {{ completed }} of {{ total }} {{ label }}
      </p>
    </div>
    <p-carousel [value]="items" [numVisible]="8" [numScroll]="8" [circular]="false">
      <ng-template let-item pTemplate="category">
        <div class="flex flex-column">
          <div class="flex flex-column align-items-center justify-content-center">
            <div
              class="sc-category flex flex-column justify-content-center align-items-center"
              [ngClass]="{ 'cursor-pointer': item.categoryId !== activeCategoryId }"
              (click)="setActiveCategory(item)"
              (keydown)="setActiveCategory(item)">
              <kendo-circularprogressbar
                class="sc-progressbar"
                [ngClass]="{
                  'sc-progressbar__full': item.percentage === 100,
                  'sc-progressbar__empty': item.percentage === 0,
                  'sc-progressbar__below-half': item.percentage > 0 && item.percentage < 50,
                  'sc-progressbar__over-half': item.percentage > 49 && item.percentage < 100
                }"
                style="width: 68px; height: 68px"
                [progressColor]="colors"
                [value]="item.percentage">
                <ng-template kendoCircularProgressbarCenterTemplate let-value="item">
                  <p class="sc-font-button-small sc-text">{{ item.percentage }}%</p>
                </ng-template>
                -
              </kendo-circularprogressbar>
              <div
                class="sc-category__label flex flex-column align-items-center justify-content-center"
                [ngClass]="{ 'sc-category__label--active': item.categoryId === activeCategoryId }">
                <p>{{ item.label }}</p>
                <div class="active-bar"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</section>
