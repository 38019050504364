import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgZone, OnChanges } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Align } from '@progress/kendo-angular-popup';
import {
  BreadcrumbInterface,
  BreadcrumbItemInterface,
  fromPageState,
  PageStateFacade,
  StandaloneRouteNamesEnum,
  TruncatePipe,
  UNTITLED,
} from '@surecloud/common';
import {
  IconButtonComponent,
  POPUP_CLOSE_ON_ANY_CLICK,
  PopupCloseOnType,
  PopupComponent,
  SvgIconsModule,
} from '@surecloud/design';
import { NgScrollbarModule } from 'ngx-scrollbar';
/**
 * Surecloud Breadcrumb Component.
 * This is a clone of the Design v1 component with new design changes.
 * @export
 * @class BreadcrumbDesignV2Component
 */
@Component({
  selector: 'design-v2-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IconButtonComponent,
    NgScrollbarModule,
    PopupComponent,
    RouterModule,
    SvgIconsModule,
    TruncatePipe,
  ],
})
export class BreadcrumbDesignV2Component implements OnChanges {
  /**
   * Maximum number of breadcrumbs to display before the dropdown menu is shown.
   * @private
   * @memberof BreadcrumbDesignV2Component
   */
  private readonly MAXIMUM_VISIBLE_BREADCRUMBS = 3;

  /**
   * Breadcrumb items that are visible by default are filtered here.
   * @type {(BreadcrumbInterface | null)}
   * @memberof BreadcrumbDesignV2Component
   */
  public breadcrumbItems: BreadcrumbInterface | null = null;

  /**
   * Breadcrumb items that are not visible by default are filtered here.
   * @type {BreadcrumbItemInterface[]}
   * @memberof BreadcrumbDesignV2Component
   */
  public dropdownItems: BreadcrumbItemInterface[] = [];

  /**
   * Constants that need to be provided to the view.
   * @type {{
   *     ALIGN_ANCHOR: Align;
   *     ALIGN_POPUP: Align;
   *     MAX_DROPDOWN_LENGTH: number;
   *     MAX_LENGTH: number;
   *     POPUP_CLOSE_ON_ANY_CLICK: PopupCloseOnType;
   *     UNTITLED: string;
   *   }}
   * @memberof BreadcrumbDesignV2Component
   */
  public CONSTANTS: {
    ALIGN_ANCHOR: Align;
    ALIGN_POPUP: Align;
    MAX_DROPDOWN_LENGTH: number;
    MAX_LENGTH: number;
    POPUP_CLOSE_ON_ANY_CLICK: PopupCloseOnType;
    UNTITLED: string;
  } = {
    ALIGN_ANCHOR: { horizontal: 'left', vertical: 'bottom' },
    ALIGN_POPUP: { horizontal: 'left', vertical: 'top' },
    MAX_DROPDOWN_LENGTH: 55,
    MAX_LENGTH: 100,
    POPUP_CLOSE_ON_ANY_CLICK,
    UNTITLED,
  };

  /**
   * Breadcrumb data to display.
   * @type {BreadcrumbInterface | null}
   * @memberof BreadcrumbDesignV2Component
   */
  @Input() breadcrumb: BreadcrumbInterface | null = null;

  /**
   * If the homepage URL is different from the default a custom one may be input.
   * @memberof BreadcrumbDesignV2Component
   */
  @Input() homepage = `/${StandaloneRouteNamesEnum.Home}`;

  /**
   * Expose the track by function to the view template.
   * @memberof BreadcrumbDesignV2Component
   */
  public trackByFunction = BreadcrumbDesignV2Component.trackByFn;

  /**
   * Track by function for Angular template loops.
   * @private
   * @static
   * @param {number} index The index value of the breadcrumb in the array.
   * @param {BreadcrumbItemInterface} breadcrumb The breadcrumb item to track.
   * @return {string} String to track the breadcrumb by.
   * @memberof BreadcrumbDesignV2Component
   */
  private static trackByFn(index: number, breadcrumb: BreadcrumbItemInterface): string {
    return breadcrumb.id;
  }

  /**
   * Creates an instance of BreadcrumbDesignV2Component.
   * @param {Router} router The Angular router service.
   * @param {PageStateFacade} pageStateFacade The page state facade.
   * @param {NgZone} ngZone The Angular zone service.
   * @memberof BreadcrumbDesignV2Component
   */
  constructor(private router: Router, private pageStateFacade: PageStateFacade, private ngZone: NgZone) {}

  /**
   * When the breadcrumb input changes we need to filter the breadcrumbs to display only the first, second last and last breadcrumb.
   * The rest are placed in the dropdown menu.
   * @memberof BreadcrumbDesignV2Component
   */
  ngOnChanges(): void {
    const { breadcrumb } = this;

    this.breadcrumbItems = null;
    this.dropdownItems = [];

    if (breadcrumb) {
      const isLargeBreadcrumb = breadcrumb.length > this.MAXIMUM_VISIBLE_BREADCRUMBS;
      const [firstBreadcrumbItem, ...restOfBreadcrumb] = breadcrumb;

      this.breadcrumbItems = isLargeBreadcrumb
        ? [firstBreadcrumbItem, ...restOfBreadcrumb.slice(-2, restOfBreadcrumb.length)]
        : breadcrumb;
      this.dropdownItems = isLargeBreadcrumb ? restOfBreadcrumb.slice(0, -2) : [];
    }
  }

  /**
   * Navigate to a route.
   * @param {string[]} route The route to navigate to.
   * @param {number} index The index of the breadcrumb item.
   * @memberof BreadcrumbDesignV2Component
   */
  navigateToRoute(route: string[], index: number): void {
    this.pageStateFacade.dispatch(fromPageState.BreadcrumbActions.removeBreadcrumbs({ index }));
    this.router.navigate(route);
  }
}
