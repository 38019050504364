import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownListComponent, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective, SelectDropdownComponentOptionInterface, selectOptionsType } from '@surecloud/common';
import { BehaviorSubject } from 'rxjs';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Wrapper component around the [Kendo UI DropDownList](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/).
 * @example
 * <sc-select-dropdown
 *  [controlName]="formControlName"
 *  [description]="Description for select dropdown"
 *  [label]="Label for select dropdown"
 *  [staticWidth]="200"
 *  [options]="['Option 1', 'Option 2', 'Option 3']"
 *  [readonly]="true">
 * </sc-select-dropdown>
 * @export
 * @class SelectDropdownComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-select-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    DropDownListModule,
    LabelModule,
    ReactiveFormsModule,
    CommonIconModule,
  ],
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDropdownComponent implements OnInit {
  /**
   * Text to display an additional description to the user.
   * @memberof SelectDropdownComponent
   */
  @Input() description = '';

  /**
   *
   *Sets the name of the control, this needs to match the form control name on the parent form group.
   * @memberof SelectDropdownComponent
   */
  @Input() controlName = '';

  /**
   *  Sets if we want a success validation style to display.
   * @memberof SelectDropdownComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Automation testhook to add to the select dropdown.
   * @memberof SelectDropdownComponent
   */
  @Input() testhook = 'select-dropdown';

  /**
   * Add a custom class the the pop that contains the dropdown list.
   * @memberof SelectDropdownComponent
   */
  @Input() popupClass = '';

  /**
   * The label text for the select dropdown.
   * @memberof SelectDropdownComponent
   */
  @Input() label = '';

  /**
   * The parent form group of the select dropdown.
   * @type {FormGroup}
   * @memberof SelectDropdownComponent
   */
  @Input()
  parentFormGroup?: FormGroup;

  selectOptions: selectOptionsType;
  get options(): selectOptionsType {
    return this.selectOptions;
  }

  /**
   * The list of [options to show](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/) in the select dropdown.
   * Setter so we can disable the input if options are undefined to allow it to be removed from form validation.
   * @memberof SelectDropdownComponent
   */
  @Input() set options(options: selectOptionsType) {
    this.selectOptions = options;
    if (!this.options) {
      this.parentFormGroup?.controls[this.controlName]?.disable();
    }
  }

  /**
   * The list of [options to show](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/) in the select dropdown.
   * @type {SelectDropdownComponentOptionInterface}
   * @memberof SelectDropdownComponent
   */
  @Input() defaultItem: SelectDropdownComponentOptionInterface | undefined;

  /**
   * The text property to assign for the option if a SelectDropdownComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/#toc-arrays-of-complex-data)
   * @memberof SelectDropdownComponent
   */
  @Input() optionTextProperty = 'text';

  /**
   * The value property to assign for the option if a SelectDropdownComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/#toc-arrays-of-complex-data)
   * @memberof SelectDropdownComponent
   */
  @Input() optionValueProperty = 'value';

  /**
   * Sets the select dropdown readonly mode.
   * @memberof SelectDropdownComponent
   */
  @Input() readonly = false;

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof SelectDropdownComponent
   */
  @Input() staticWidth!: string;

  /**
   * The input is 100% height by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof SelectDropdownComponent
   */
  @Input() staticHeight!: string;

  /**
   * Sets the value type to primitive by default.
   * If we want save the whole option object as the value then set it to false.
   * @memberof SelectDropdownComponent
   */
  @Input() valuePrimitive = true;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   * @memberof SelectDropdownComponent
   */
  @Input() marginBottom = false;

  /**
   * Boolean if the control should use the ViewContainerRef
   * @memberof SelectDropdownComponent
   */
  @Input() useViewContainerRef = true;

  /**
   * Boolean if the control should use the Clear button option
   * @memberof SelectDropdownComponent
   */
  @Input() canClear = true;

  @ViewChild('dropdownlist') dropdownlist!: DropDownListComponent;

  /**
   * The Angular ViewContainerRef helper
   * @type {(ViewContainerRef | undefined)} A view container instance
   * @memberof SelectDropdownComponent
   */
  viewContainerRef: ViewContainerRef | undefined;

  /**
   * Keep track of when the popup is open or closed so we can apply styling.
   * @memberof SelectDropdownComponent
   */
  public popupOpen = false;

  /**
   * Observable stream to open and close the Select Dropdown.
   * @type {BehaviorSubject<boolean>}
   * @memberof SelectDropdownComponent
   */
  public open$: BehaviorSubject<boolean> = new BehaviorSubject(this.popupOpen);

  /**
   * Creates an instance of SelectDropdownComponent.
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @memberof SelectDropdownComponent
   */
  constructor(private formGroupDirective: FormGroupDirective, public viewContainerRefDefault: ViewContainerRef) {}

  /**
   * Runs when the component is started.
   * Sets the parent form group form the component.
   * @memberof SelectDropdownComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
    if (this.useViewContainerRef) {
      this.viewContainerRef = this.viewContainerRefDefault;
    }
  }

  /**
   * Checks if the item is disabled effectively making it non selectable
   * This is a solution suggested as a workaround to get a placeholder on single select dropdowns
   * https://feedback.telerik.com/kendo-angular-ui/1407766-placeholder-for-dropdownlist
   * @memberof SelectDropdownComponent
   */
  // eslint-disable-next-line class-methods-use-this
  public itemDisabled(itemArgs: { dataItem: { value: string }; index: number }): boolean {
    return itemArgs.dataItem.value === null;
  }

  /**
   * Clears dropdown value
   */
  public clear(): void {
    this.parentFormGroup?.get(this.controlName)?.reset();
  }

  /**
   * Runs when the dropdown closes
   * @memberof SelectDropdownComponent
   */
  public closed(): void {
    this.popupOpen = false;
    this.open$.next(this.popupOpen);
  }

  /**
   * Runs when the dropdown opens
   * @memberof SelectDropdownComponent
   */
  public opened(): void {
    this.popupOpen = true;
    this.open$.next(this.popupOpen);
  }

  /**
   * Toggle the select dropdown as the status parameter
   * @param {boolean} status the status of the toggle function
   * @memberof SelectDropdownComponent
   */
  public toggle(status: boolean): void {
    this.popupOpen = status;
    this.open$.next(this.popupOpen);
    this.dropdownlist.toggle(status);
  }
}
