import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { PopupModule } from '@progress/kendo-angular-popup';
import { GlobalNavLinkInterface, StandaloneRouteNamesEnum, trackByIndex } from '@surecloud/common';
import { Subject, takeUntil } from 'rxjs';
import { IconButtonComponent } from '../button/icon-button.component';
import { TextButtonComponent } from '../button/text-button.component';
import { SvgIconsModule } from '../icon/icons/svg-icons.module';
import { InputTextComponent } from '../input-text/input-text.component';
import { TaskListComponent } from '../task-list/task-list.component';

const HOME_LINK: GlobalNavLinkInterface = {
  route: `/${StandaloneRouteNamesEnum.Home}`,
  label: $localize`Home`,
  isEndOfSection: false,
};

/**
 * Surecloud Global Nav Component.
 * @export
 * @class GlobalNavComponent
 */
@Component({
  selector: 'sc-global-nav',
  standalone: true,
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SvgIconsModule,
    CommonModule,
    ReactiveFormsModule,
    PopupModule,
    IconButtonComponent,
    TextButtonComponent,
    InputTextComponent,
    RouterModule,
    TaskListComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalNavComponent implements AfterViewInit, OnDestroy {
  /**
   * The home link for the global nav.
   * @memberof GlobalNavComponent
   */
  homeLink = HOME_LINK;
  /**
   * Temp reference to the popupAnchor element to which the popup aligns to.
   * @type {ElementRef}
   * @memberof GlobalNavComponent
   */
  @ViewChild('menuAnchor', { read: ElementRef })
  public menuAnchor!: ElementRef;

  /**
   * The reference to a queryList for elements marked with #menu
   * @type {ElementRef}
   * @memberof GlobalNavComponent
   */
  @ViewChildren('menu', { read: ElementRef })
  public menus!: QueryList<GlobalNavComponent>;

  /**
   * The reference to the menu element.
   * @type {ElementRef | undefined}
   * @memberof GlobalNavComponent
   */
  menu: ElementRef | undefined;

  /**
   * The global navigation link list.
   * @type {GlobalNavLinkInterface[]}
   * @memberof GlobalNavComponent
   */
  @Input()
  globalNavLinks: GlobalNavLinkInterface[] | null = null;

  /**
   * The number of new Tasks that haven't been read yet.
   * @type {number | null}
   * @memberof GlobalNavComponent
   */
  @Input() numberOfNewTasks: number | null = 0;

  /**
   * Flag to show/hide global menu button.
   * @type {boolean}
   * @memberof GlobalNavComponent
   */
  @Input() canViewGlobalMenu = false;

  /**
   * Flag to show/hide user menu button.
   * @type {boolean}
   * @memberof GlobalNavComponent
   */
  @Input() canViewUserMenu = false;

  /**
   * Flag to show/hide tasks button.
   * @type {boolean}
   * @memberof GlobalNavComponent
   */
  @Input() canViewTasks = false;

  /**
   * The active route.
   * @type {string}
   * @memberof GlobalNavComponent
   */
  @Input() activeRouteLabel = '';

  /**
   * Whether the global nav is standalone or not.
   * So styles can be tweaked accordingly.
   * @memberof GlobalNavComponent
   */
  @Input() isStandalone = false;

  /**
   * When a user clicks on the help icon button.
   * Then emit the helpClicked event.
   * @type {EventEmitter}
   * @memberof GlobalNavComponent
   */
  @Output() helpClicked: EventEmitter<Event> = new EventEmitter();

  /**
   * When a user clicks on the task icon button.
   * Then emit the taskClicked event.
   * @type {EventEmitter}
   * @memberof GlobalNavComponent
   */
  @Output() taskClicked: EventEmitter<Event> = new EventEmitter();

  /**
   * When a user clicks on the user icon button.
   * Then emit the userClicked event.
   * @type {EventEmitter}
   * @memberof GlobalNavComponent
   */
  @Output() userClicked = new EventEmitter();

  /**
   * Expose the track by function to the view template.
   * @memberof GlobalNavComponent
   */
  trackByFunction = trackByIndex;

  /**
   * The form for the search input.
   * @memberof GlobalNavComponent
   */
  public searchForm = this.fb.group({
    search: this.fb.control(''),
  });

  /**
   * The name of the organisation to display.
   * @memberof GlobalNavComponent
   */
  public orgName = $localize`SureCloud`;

  /**
   * The destroy subject for the component.
   * @memberof GlobalNavComponent
   */
  private destroyed$ = new Subject<void>();

  /**
   * Creates an instance of GridNumberCellEditorComponent.
   * @param {FormBuilder} fb Instance of the form builder the component it placed in
   * @param {Router} router The router instance
   * @memberof GlobalNavComponent
   */
  constructor(private fb: FormBuilder, private router: Router) {}

  /**
   * After the view has been initialised.
   * Listen for changes to the menus query list and set the menu element.
   * @memberof GlobalNavComponent
   */
  ngAfterViewInit(): void {
    this.menus.changes.pipe(takeUntil(this.destroyed$)).subscribe((menus) => {
      this.menu = menus.first;
    });
  }

  /**
   * When the component is destroyed.
   * @memberof GlobalNavComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * For the display of the designer menu from the top navigation.
   * @memberof GlobalNavComponent
   */
  public show = false;

  /**
   * Toggle for the designer menu popup.
   * @memberof GlobalNavComponent
   */
  public onToggle(): void {
    this.show = !this.show;
  }

  /**
   * Handle home button click.
   * @memberof GlobalNavComponent
   */
  public homeClicked(): void {
    this.router.navigate([StandaloneRouteNamesEnum.Home]);
  }

  /**
   * If a click is detected outside of this component then close the popup/dropdown menu.
   * @param {PointerEvent} event The click event.
   * @memberof GlobalNavComponent
   */
  @HostListener('document:click', ['$event'])
  public documentClick(event: PointerEvent): void {
    if (!this.menuAnchor) return;
    if (!this.menuAnchor.nativeElement.contains(event.target) && !this.menu?.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }

  /**
   * If the Escape key is pressed then close the popup.
   * @param {KeyboardEvent} event The keyboard event.
   * @memberof GlobalNavComponent
   */
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.show = false;
    }
  }
}
