import { Injectable } from '@angular/core';
import { BehaviorSubjectService } from '@surecloud/common';

/**
 * Sidebar Overlay Component Service.
 * Uses this service to show and hide the Sidebar Overlay Component.
 * @export
 * @class SidebarLayoutOverlayComponentService
 * @extends {BehaviorSubjectService<boolean>}
 */
@Injectable({
  providedIn: 'root',
})
export class SidebarLayoutOverlayComponentService extends BehaviorSubjectService<boolean> {
  /**
   * Creates an instance of SidebarLayoutOverlayComponentService.
   * The Sidebar Overlay Component is hidden by default.
   * @memberof SidebarLayoutOverlayComponentService
   */
  constructor() {
    super(false);
  }
}
