import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { E2eHookDirective, NotRequiredNumber, NotRequiredString } from '@surecloud/common';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from '../button/icon-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { InputTextComponent } from '../input-text/input-text.component';
import { ScoreComponent } from '../score/score.component';
import { TitleDescriptionComponent } from '../title-description/title-description.component';

@Component({
  selector: 'sc-edited-score',
  templateUrl: './edited-score.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitleDescriptionComponent,
    ScoreComponent,
    IconButtonComponent,
    InputTextComponent,
    ReactiveFormsModule,
    E2eHookDirective,
    CommonIconModule,
    CommonModule,
  ],
})
export class EditedScoreComponent implements OnChanges {
  /**
   * The edited score component original score
   * @type {(NotRequiredNumber )}
   * @memberof EditedScoreComponent
   */
  @Input() originalScore: NotRequiredNumber;

  /**
   * The edited score component new score
   * @type {(NotRequiredNumber )}
   * @memberof EditedScoreComponent
   */
  @Input() newScore: NotRequiredNumber;

  /**
   * The edited score component justification
   * @type {(NotRequiredString)}
   * @memberof EditedScoreComponent
   */
  @Input() justification: NotRequiredString = '';

  /**
   * Automation testhook to add to the edited score component.
   * @type {(string)}
   * @memberof EditedScoreComponent
   */
  @Input() testhook = 'edited-score';

  /**
   * The edited score component read only
   * @type {boolean}
   * @memberof EditedScoreComponent
   */
  @Input() readonly = false;

  /**
   * The click event for the reset button.
   * @memberof EditedScoreComponent
   */
  @Output() resetClicked = new EventEmitter();

  /**
   * The click event for the edit button.
   * @memberof EditedScoreComponent
   */
  @Output() editClicked = new EventEmitter<Event>();

  /**
   * The edited score form group.
   * @type {FormGroup}
   * @memberof EditedScoreComponent
   */
  form: FormGroup<{
    justification: FormControl<string>;
  }> = this.formBuilder.group({
    justification: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: true,
      }
    ),
  });

  /**
   * Creates an instance of EditedScoreComponent .
   * @param {FormBuilder} formBuilder  The Angular form builder.
   * @memberof EditedScoreComponent
   */
  constructor(private readonly formBuilder: FormBuilder) {}

  /**
   * When the input changes.
   * Then setup form values
   * @memberof EditedScoreComponent
   */
  ngOnChanges(): void {
    this.setFormValues();
  }

  /**
   * Set's the form values
   * @private
   * @memberof EditedScoreComponent
   */
  private setFormValues(): void {
    if (this.justification) {
      this.form.controls.justification.patchValue(this.justification, { emitEvent: false });
    }
  }

  /**
   * Edit click event
   * @param {Event} event event that triggers the button click
   * @memberof EditedScoreComponent
   */
  editClick(event: Event): void {
    this.editClicked.emit(event);
  }
}
