import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NormalisedRecentRecords } from '../../services/normalise-get-recent-records/normalise-get-recent-records.validation';
import { RecentRecordInterface } from '../models/recent-records/recent-records.models';

/**
 * Recent Records API actions map.
 */
const RECENT_RECORDS_API_ACTIONS_MAP = {
  'Read Recent Records': emptyProps(),
  'Read Recent Records Data Success': props<{ normalisedRecentRecords: NormalisedRecentRecords }>(),
  'Read Recent Records Success': props<{ recentRecords: RecentRecordInterface[] }>(),
  'Read Recent Records Failure': props<{ error: string }>(),
};

/**
 * Recent Records API actions.
 */
const RecentRecordsApiActions = createActionGroup({
  source: 'RecentRecords/API',
  events: RECENT_RECORDS_API_ACTIONS_MAP,
});

/**
 * Combined Recent Records API and RecentRecords Page actions.
 */
export const RecentRecordsActions = {
  ...RecentRecordsApiActions,
};
