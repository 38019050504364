import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';

/**
 * Makes the global document attribute configuration
 */
export const GLOBAL_DOCUMENT_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Document,
  key: EntityAttributeTypeEnum.Document,
  type: EntityAttributeTypeEnum.Document,
  label: $localize`Document`,
  uniqueSections: [],
});
