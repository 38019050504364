<div #hierarchy>
  <div
    *ngIf="previousNodes.length > 0; else topLevel"
    class="sc-hierarchy__current-node-clickable sc-text sc-p-10"
    tabindex="0"
    (click)="goToPreviousNode($event)"
    (keydown.enter)="goToPreviousNode()">
    <sc-icon class="sc-mr-12" key="chevron-left" colour="brand-500" [height]="10" [width]="5"></sc-icon
    >{{ currentNode.name }}
  </div>
  <ng-template #topLevel
    ><div class="sc-hierarchy__current-node sc-p-10 sc-text">
      {{ currentNode.name }}
    </div></ng-template
  >
  <div class="sc-hierarchy__divider"></div>
  <ul class="flex flex-column">
    <li
      *ngFor="let node of currentNode.nodes; trackBy: trackByFunction"
      class="sc-hierarchy__node flex align-items-center sc-p-10 sc-text"
      [ngClass]="{ 'sc-hierarchy__node--selected': node.selected }"
      tabindex="0"
      (click)="selectNode(node, $event)"
      (keydown.enter)="selectNode(node)">
      <div class="sc-hierarchy__node-icon flex justify-content-center align-items-center sc-mr-4" *ngIf="node.icon">
        <sc-icon [key]="node.icon" colour="brand-500" [height]="20" [width]="18"></sc-icon>
      </div>
      {{ node.name }}
      <sc-icon
        *ngIf="!node.isSelectable"
        class="sc-ml-auto"
        key="chevron-right"
        colour="grey-600"
        [height]="10"
        [width]="5"></sc-icon>
    </li>
  </ul>
</div>
