import { createAction, props } from '@ngrx/store';
import {
  EntityAttributeCreateInterface,
  EntityAttributeInterface,
  EntityAttributeTypeEnum,
  EntityAttributeUpdatedInterface,
  EntityCalculationAttributeUpdatedInterface,
  RequestIdInterface,
} from '@surecloud/common';

/**
 * An object grouping all the Attribute Feature Actions
 * @export
 */
export const EntityAttributeActions = {
  /**
   * Attribute Actions Related to the Entity Page
   * [Entity Page]
   */
  startDeleteAttribute: createAction(
    '[Entity Page] Start Delete Attribute',
    props<{ attribute: EntityAttributeInterface }>()
  ),
  completeDeleteAttribute: createAction(
    '[Entity Page] Complete Delete Attribute',
    props<{ attribute: EntityAttributeInterface }>()
  ),

  /**
   * Attribute Actions Related to the Entity Attribute List Page
   * [Entity Attribute List Page]
   */
  navigateToAttribute: createAction(
    '[Entity Attribute List Page] Navigate to Attribute',
    props<{ attributeId: string }>()
  ),

  /**
   * Attribute Actions Related to the Entity Attribute Page
   * [Entity Attribute Page]
   */
  attributeEnter: createAction('[Entity Attribute Page] Enter'),
  attributeLeave: createAction('[Entity Attribute Page] Leave'),
  selectAttribute: createAction('[Entity Attribute Page] Select Attribute', props<EntityAttributeUpdatedInterface>()),
  completeUpdateAttribute: createAction(
    '[Entity Attribute Page] Complete Update Attribute',
    props<{ attribute: EntityAttributeInterface }>()
  ),
  cancelUpdateAttribute: createAction('[Entity Attribute Page] Cancel Update Attribute'),
  startSelectAttributeType: createAction(
    '[Entity Attribute Page] Start Select Attribute Type',
    props<{ attributeType: EntityAttributeTypeEnum; attribute: EntityAttributeInterface }>()
  ),
  completeSelectAttributeType: createAction(
    '[Entity Attribute Page] Complete Select Attribute Type',
    props<{ attribute: EntityAttributeInterface }>()
  ),
  selectAttributeType: createAction(
    '[Entity Attribute Page] Select Attribute Type',
    props<{ attributeType: EntityAttributeTypeEnum | null }>()
  ),
  updateAttributeFromPage: createAction(
    '[Entity Attribute Page] Update Attribute',
    props<{ attribute: EntityAttributeInterface }>()
  ),
  reset: createAction('[Entity Attribute Page] Reset'),

  /** General attribute actions  */
  updateAttributeFromEffect: createAction(
    '[Entity] Update Attribute',
    props<{ attribute: EntityAttributeInterface }>()
  ),

  /**
   * Attribute Actions Related to the Entity Attribute API
   * [Entity Attribute/API]
   */
  readAttributeList: createAction('[Entity/API] Read Attribute List'),
  readAttributeListSuccess: createAction(
    '[Entity/API] Read Attribute List Success',
    props<{ attributeList: EntityAttributeInterface[] }>()
  ),
  readAttributeListFailure: createAction('[Entity/API] Read Attribute List Failure', props<{ error: string }>()),
  createAttribute: createAction(
    '[Entity/API] Create Attribute',
    props<{ attribute: EntityAttributeCreateInterface } & RequestIdInterface>()
  ),
  createAttributeSuccess: createAction(
    '[Entity/API] Create Attribute Success',
    props<{ attribute: EntityAttributeInterface }>()
  ),
  createAttributeFailure: createAction('[Entity/API] Create Attribute Failure', props<{ error: string }>()),
  readAttribute: createAction('[Entity/API] Read Attribute', props<EntityAttributeUpdatedInterface>()),
  readAttributeSuccess: createAction(
    '[Entity/API] Read Attribute Success',
    props<{ attribute: EntityAttributeInterface }>()
  ),
  readAttributeFailure: createAction('[Entity/API] Read Attribute Failure', props<{ error: string }>()),
  updateAttribute: createAction('[Entity/API] Update Attribute', props<{ attribute: EntityAttributeInterface }>()),
  updateAttributeSuccess: createAction(
    '[Entity/API] Update Attribute Success',
    props<EntityAttributeUpdatedInterface | EntityCalculationAttributeUpdatedInterface>()
  ),
  updateAttributeFailure: createAction('[Entity/API] Update Attribute Failure', props<{ error: string }>()),
  deleteAttribute: createAction('[Entity/API] Delete Attribute', props<EntityAttributeUpdatedInterface>()),
  deleteAttributeSuccess: createAction(
    '[Entity/API] Delete Attribute Success',
    props<EntityAttributeUpdatedInterface>()
  ),
  deleteAttributeFailure: createAction('[Entity/API] Delete Attribute Failure', props<{ error: string }>()),
};
