import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PAGE_STATE_FEATURE_KEY, StateInterface } from './page-state.reducer';

// Lookup the 'Page State' feature state managed by NgRx
export const getPageState = createFeatureSelector<StateInterface>(PAGE_STATE_FEATURE_KEY);

/**
 * Get Dashboard Edit Mode state.
 */
export const getDashboardEditMode = createSelector(getPageState, (state) => state.dashboardEditMode);

/**
 * Get all breadcrumbs.
 */
export const getBreadcrumbs = createSelector(getPageState, (state: StateInterface) => state.breadcrumbs || []);

/**
 * Get the latest breadcrumb tab id.
 */
export const getLatestBreadcrumbTabId = createSelector(getPageState, (state: StateInterface) => {
  const breadcrumbs = state.breadcrumbs || [];
  return breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].tabId : undefined;
});
