import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  DocumentUploadStatusEnum,
  DocumentVirusScanStatusEnum,
  isDocumentUploadStatusEnum,
  isNumberGuard,
} from '@surecloud/common';
import { IconButtonComponent } from '../../button/icon-button.component';
import { CommonIconModule } from '../../icon/icons/common-icon.module';
import { FileSizePipe } from '../../utils/pipes/file-size.pipe';
import { convertDocumentUploadStatusToNumber, convertFileItemStatusEnumToCSSClass, FileItemStatusEnum } from './file-item-status.model';

/**
 *  Surecloud File Item Component
 * @export
 * @class FileItemComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
  standalone: true,
  imports: [CommonModule, IconButtonComponent, CommonIconModule, FileSizePipe],
})
export class FileItemComponent implements OnChanges {
  /**
   * Sets the file name
   * @type {string}
   * @memberof FileItemComponent
   */
  @Input() name!: string;

  /**
   * Sets the file status.
   * @type {number}
   * @memberof FileItemComponent
   */
  @Input() status: number | undefined | DocumentUploadStatusEnum | null = undefined;

  /**
   * Sets the file scan status.
   * @type {number}
   * @memberof FileItemComponent
   */
  @Input() virusScanStatus: DocumentVirusScanStatusEnum | undefined = undefined;

  /**
   * Sets the readonly value for the file item.
   * @type {boolean}
   * @memberof FileItemComponent
   */
  @Input() readonly = false;

  /**
   * Checks if a file has a documentId, meaning it can be savely removed.
   * @type {boolean}
   * @memberof FileItemComponent
   */
  @Input() hasDocumentId = false;

  /**
   * Sets the readonly value for the file item.
   * @type {boolean}
   * @memberof FileItemComponent
   */
  @Input() size: number | undefined;

  /**
   * Emits when file item has been removed
   * @memberof FileItemComponent
   */
  @Output() remove = new EventEmitter<void>();

  /**
   * Emits when file item has been downloaded
   * @memberof FileItemComponent
   */
  @Output() download = new EventEmitter<void>();

  /**
   * Text to display the file status
   * @memberof FileItemComponent
   */
  fileItemMessage!: string | undefined;

  /**
   * The style to the text file status message
   * @memberof FileItemComponent
   */
  fileItemMessageStyle = 'none';

  /**
   * Show button to download file
   * @memberof FileItemComponent
   */
  showButtonVirusScanStatus = false;

  /**
   * Show button to download/remove file
   * @memberof FileItemComponent
   */
  showButtonUploadStatus = false;

  /**
   * The file item status
   * @memberof FileItemComponent
   */
  fileItemStatus: FileItemStatusEnum | null = null;

  /**
   * Change file message status when it changes
   * @memberof FileItemComponent
   */
  ngOnChanges(): void {
    if (this.virusScanStatus && isDocumentUploadStatusEnum(this.status)) {
      this.fileItemStatus = convertDocumentUploadStatusToNumber(this.status, this.virusScanStatus);
      this.fileItemMessageStyle = convertFileItemStatusEnumToCSSClass(this.fileItemStatus);
      this.setFileItemMessage(this.fileItemStatus);
      return;
    }

    if (
      this.status === null ||
      this.status === undefined ||
      !isNumberGuard(this.status)
    ) {
      this.fileItemMessageStyle = 'none';
      this.fileItemMessage = undefined;
      return;
    }

    this.fileItemMessageStyle = convertFileItemStatusEnumToCSSClass(this.status);
    this.setFileItemMessage(this.status);

  }

  /**
   * Set's the fileItemMessage based on the status
   * @param {number} status The status of the file
   * @memberof FileItemComponent
   */
  private setFileItemMessage(status: FileItemStatusEnum): void {
    switch (status) {
      case FileItemStatusEnum.Failed:
        this.fileItemMessage = $localize`File failed to upload.`;
        this.showButtonUploadStatus = false;
        this.showButtonVirusScanStatus = false;
        break;
      case FileItemStatusEnum.SuccessfullyUploaded:
        this.fileItemMessage = $localize`File successfully uploaded.`;
        this.showButtonUploadStatus = true;
        this.showButtonVirusScanStatus = true;
        break;
      case FileItemStatusEnum.InProgress:
        this.fileItemMessage = $localize`Uploading...`;
        this.showButtonUploadStatus = false;
        this.showButtonVirusScanStatus = false;
        break;
      case FileItemStatusEnum.VirusScanInProgress:
        this.fileItemMessage = $localize`File scan in progress...`;
        this.showButtonUploadStatus = true;
        this.showButtonVirusScanStatus = false;
        break;
      case FileItemStatusEnum.VirusScanFailed:
        this.fileItemMessage = $localize`File failed virus scan.`;
        this.showButtonUploadStatus = true;
        this.showButtonVirusScanStatus = false;
        break;
      case FileItemStatusEnum.Completed:
        this.fileItemMessage = undefined;
        this.showButtonUploadStatus = true;
        this.showButtonVirusScanStatus = true;
        break;
      default:
        this.fileItemMessage = undefined;
        this.showButtonUploadStatus = false;
        this.showButtonVirusScanStatus = false;
        break;
    }
  }
}
