<form class="k-form" [formGroup]="parentFormGroup">
  <sc-input-number
    class="sc-mb-24"
    [controlName]="inputControlName"
    [label]="inputLabel"
    [description]="inputDescription"
    i18n-description
    i18n-label>
  </sc-input-number>
  <sc-select-dropdown
    class="block sc-mb-24"
    [label]="dropdownLabel"
    i18n-label
    [controlName]="dropdownControlName"
    [description]="dropdownDescription"
    i18n-description
    [options]="options">
  </sc-select-dropdown>
  <sc-select-dropdown
    class="block sc-mb-24"
    [label]="secondDropdownLabel"
    i18n-label
    [controlName]="secondDropdownControlName"
    [description]="secondDropdownDescription"
    i18n-description
    [options]="secondDropdownOptions">
  </sc-select-dropdown>
</form>
