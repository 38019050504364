<div
  class="sc-integration-card sc-p-8"
  [ngClass]="multiRow ? 'sc-integration-card--multi-row' : 'sc-integration-card--single-row'"
  [class.sc-integration-card--disabled]="disabled">
  <div class="sc-integration-card__chip-status">
    <sc-integration-chip-status
      [status]="integration.status"
      [quantityConnection]="integration.quantityConnection"></sc-integration-chip-status>
  </div>

  <div class="sc-integration-card__app sc-mb-8">
    <div class="logo"><sc-icon [key]="integration.icon" width="50px"></sc-icon></div>
    <div class="info sc-pr-8 sc-pb-8">
      <h3 class="sc-font-heading-md">{{ integration.title }}</h3>
      <p class="sc-integration-card__app--description">{{ integration.description }}</p>
    </div>
  </div>

  <div class="sc-integration-card__category-chip sc-py-8">
    <ng-container *ngFor="let category of integration.categories; trackBy: trackByIndex; let i = index">
      <sc-tag *ngIf="i < visibleCategoriesCount" [theme]="categoryThemes[category.colour]">
        <span class="sc-integration-card__category-chip--chip-name">{{ category.name }}</span>
      </sc-tag>
    </ng-container>

    <div #anchor>
      <sc-tag
        *ngIf="integration.categories.length > visibleCategoriesCount"
        (click)="showExtraCategories = !showExtraCategories"
        i18n
        [theme]="showExtraCategories ? 'grey' : 'parchment'">
        <span
          class="sc-integration-card__category-chip--chip-name"
          [ngClass]="{ 'sc-text-white': showExtraCategories }">
          +{{ integration.categories.length - visibleCategoriesCount }} {{ MORE_LABEL }}
        </span>
      </sc-tag>
    </div>

    <kendo-popup
      [anchor]="anchor"
      *ngIf="integration.categories.length > visibleCategoriesCount && showExtraCategories">
      <div class="sc-integration-card__category-chip--popup d-flex sc-p-8">
        <h4 class="category-title sc-p-10 sc-pb-4" i18n>Categories</h4>
        <div class="divider sc-mb-2"></div>
        <div class="tags d-flex flex-wrap sc-pt-8">
          <sc-tag *ngFor="let tag of extraTags; trackBy: trackByIndex" [theme]="tag.theme">
            <span class="sc-integration-card__category-chip--chip-name">{{ tag.name }}</span>
          </sc-tag>
        </div>
      </div>
    </kendo-popup>
  </div>
</div>
