import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseFacade, RecordGridInputInterface } from '@surecloud/common';
import { Observable } from 'rxjs';
import * as RecordSelectors from '../selectors/record.selectors';

/**
 * The facade for the Record feature sits between the component layer and the
 * state layer and is responsible for providing store observables to,
 * and dispatching actions from the view layer
 * @export
 * @class RecordFeatureFacade
 * @extends {BaseFacade}
 */
@Injectable({
  providedIn: 'root',
})
export class RecordFeatureFacade extends BaseFacade {
  /**
   * Getting the records and columns attributes
   *
   */
  recordResult$: Observable<RecordGridInputInterface> = this.store.pipe(select(RecordSelectors.getRecordGridInput));

  /**
   * Creates an instance of RecordFeatureFacade.
   * @param {Store} store The NGRX application state store.
   * @memberof RecordFeatureFacade
   */
  constructor(protected override readonly store: Store) {
    super(store);
  }
}
