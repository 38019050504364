<sc-title-description class="block sc-mb-24" [heading]="heading" [description]="description" *ngIf="heading">
</sc-title-description>
<sc-advanced-grid
  [rowData]="rowData"
  [tabs]="tabs"
  [columnDefs]="columnDefs"
  [rowSelection]="rowSelection"
  [activeTab]="activeTab"
  [height]="height"
  [primaryButtonName]="primaryButtonName"
  [showPrimaryButton]="canAddRow"
  [canDelete]="canDeleteRow"
  [canSelect]="canSelectRow"
  (activeTabChange)="activeTab = $event; gridTabChanged.emit($event)"
  (primaryButtonClicked)="gridAddRowClicked.emit($event)"
  (cellValueChanged)="gridCellEdited.emit($event)"
  (rowsDeleted)="gridRowsDeleted.emit($event)"
  (cellClicked)="gridCellClicked.emit($event)">
</sc-advanced-grid>
