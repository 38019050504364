<kendo-formfield
  class="block sc-select-dropdown sc-select-dropdown--user"
  showErrors="initial"
  [ngClass]="{ 'sc-select-dropdown--selected': control.value }">
  <kendo-label
    i18n-text
    class="sc-label flex-column"
    [text]="label"
    [ngClass]="{ 'sc-label--no-success-validation': !enableSuccessValidationStyle }">
    <span class="sc-user-avatar-field">
      <kendo-combobox
        #dropdown
        valueField="value"
        textField="display"
        [suggest]="true"
        [filterable]="true"
        [allowCustom]="true"
        [clearButton]="true"
        [valuePrimitive]="true"
        [data]="options"
        [readonly]="readonly"
        [formControl]="control"
        [placeholder]="placeholder"
        [style.width.px]="staticWidth"
        [attr.aria-expanded]="!dropdown.isOpen"
        [ngClass]="{
          'sc-input--readonly': readonly && !control.disabled,
          'combobox-dropdown-open': popupOpen,
          'sc-select-dropdown--open': popupOpen && selectedUser
        }"
        [popupSettings]="{
          popupClass: 'sc-scrollbar sc-select-dropdown__popup sc-select-dropdown__popup--user'
        }"
        (open)="popupOpen = true"
        (close)="popupOpen = false"
        (filterChange)="filterChange.emit($event)"
        (keydown.backspace)="selectedUser ? dropdown.clearValue() : undefined"
        (keydown.enter)="valueValidation()"
        (blur)="valueValidation()"
        (selectionChange)="selectionChange($event)">
        <ng-template kendoComboBoxItemTemplate let-user>
          <div class="flex align-items-center sc-user-avatar-select__select-list-item">
            <sc-user-avatar [initials]="user?.initials" [hexColour]="user?.colour"></sc-user-avatar>
            <div class="flex-column">
              <div>{{ user?.display }}</div>
              <div class="sc-user-avatar-select__subtitle">{{ user?.subtitle }}</div>
            </div>
          </div>
        </ng-template>
      </kendo-combobox>
      <span class="sc-user-avatar-field__user-chip" *ngIf="selectedUser">
        <sc-user-avatar
          class="flex h-full align-items-center"
          size="sm"
          [initials]="selectedUser.initials"
          [hexColour]="selectedUser.colour">
        </sc-user-avatar>
      </span>
    </span>
    <div class="flex">
      <div
        i18n
        *ngIf="enableSuccessValidationStyle && control.valid"
        class="sc-input__validation sc-input__validation--success sc-font-button-regular sc-mt-8 sc-mr-10">
        <div class="sc-pt-2 sc-pl-2">
          <sc-icon key="checkmark-outline" size="small" colour="green-300" class="sc-mr-4"></sc-icon>
        </div>
        Success
      </div>
      <div i18n class="sc-input__validation sc-input__validation--error sc-font-button-regular sc-mt-8 sc-mr-10">
        <div class="sc-pt-2 sc-pl-2">
          <sc-icon key="checkmark-outline" size="small" colour="red-300" class="sc-mr-4"></sc-icon>
        </div>
        Incomplete
      </div>
      <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
    </div>
  </kendo-label>
</kendo-formfield>
