import { format, isAfter } from 'date-fns';

export const TIME_MORNING = $localize`morning`;
export const TIME_AFTERNOON = $localize`afternoon`;
export const TIME_EVENING = $localize`evening`;

type TimeOfDayUnion = typeof TIME_MORNING | typeof TIME_AFTERNOON | typeof TIME_EVENING;

const getDateWithHour = (forDate: Date, hours: number): Date => {
  const date = new Date(forDate.getTime());
  date.setSeconds(0);
  date.setMinutes(0);
  date.setMilliseconds(0);
  date.setHours(hours);

  return date;
};

/**
 * @return {string} The time of day (Morning, Afternoon, Evening)
 * @param {Date} forDate The date to find the time of day for
 */
export const getTimeOfDay = (forDate: Date): TimeOfDayUnion => {
  const isAfter12pm = isAfter(forDate, getDateWithHour(forDate, 12));
  const isAfter6pm = isAfter(forDate, getDateWithHour(forDate, 18));

  if (isAfter6pm) return TIME_EVENING;
  if (isAfter12pm) return TIME_AFTERNOON;
  return TIME_MORNING;
};

/**
 * @return {string} The formatted date in the example format (Friday 20th January 2023)
 * @param {Date} date The date to format
 */
export const formatHumanReadable = (date: Date): string => format(date, 'EEEE do MMMM yyyy');

/**
 * Formats a date to a string without the hour
 * @param {Date} date The date to format
 * @return {string} The formatted date in the example format (2023-01-20)
 */
export const getDateString = (date: Date): string => {
  if (!date?.getTime()) {
    return '';
  }

  return format(date, 'yyyy-MM-dd');
};
