import { AbstractControl } from '@angular/forms';
import { Observable, filter } from 'rxjs';

/**
 * Custom RxJS operator for stopping the stream when the value is invalid.
 *
 * @export
 * @param {AbstractControl} formControl
 * @return {<T>(source: Observable<T>) => Observable<T>} - The source observable
 */
export function filterFormControlValid(formControl: AbstractControl): <T>(source: Observable<T>) => Observable<T> {
  return function filterByFormControlValidationProperty<T>(source: Observable<T>): Observable<T> {
    return source.pipe(filter(() => formControl.valid));
  };
}
