<div class="sc-step-banner flex align-items-center">
  <ng-container *ngFor="let step of steps; let index = index; let last = last; trackBy: trackByFunction">
    <div
      class="sc-step-banner__step flex align-items-center"
      [ngClass]="{
        'current-step': currentStep === index,
        'done-step': index < currentStep,
        'justify-content-center' : steps.length < 2
      }">
      <i *ngIf="index < currentStep" class="step-icon">
        <sc-icon key="checkmark-fill" size="large" colour="grey-600" [height]="22" [width]="22"></sc-icon>
      </i>
      <div class="step-index sc-p-10 sc-mr-8">{{ index + 1 }}</div>
      <div i18n class="step-label">{{ step.label }}</div>
      <div class="divider" *ngIf="!last"></div>
    </div>
  </ng-container>
</div>
