import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PriorityTasksActions } from '../../actions/priority-tasks.actions';
import { PriorityTasksInterface } from '../../models/priority-tasks/priority-tasks.models';

export const PRIORITY_TASKS_FEATURE_KEY = 'priorityTasks';

export interface StateInterface extends EntityState<PriorityTasksInterface> {
  priorityTasks: PriorityTasksInterface | null; // Priority Tasks - we only load 1 set at a time
  loaded: boolean;
  error?: string | null;
}

export interface PriorityTasksPartialStateInterface {
  readonly [PRIORITY_TASKS_FEATURE_KEY]: StateInterface;
}

export const priorityTasksAdapter: EntityAdapter<PriorityTasksInterface> = createEntityAdapter<PriorityTasksInterface>();

export const initialState: StateInterface = priorityTasksAdapter.getInitialState({
  priorityTasks: null,
  loaded: false,
});

/**
 * PriorityTasks reducer.
 */
const priorityTasksReducer = createReducer(
  initialState,

  on(PriorityTasksActions.readPriorityTasks, (state: StateInterface) => ({ ...state, loaded: false, error: null })),
  on(PriorityTasksActions.readPriorityTasksSuccess, (state: StateInterface, { priorityTasks }) => ({
    ...state,
    priorityTasks,
    loaded: true,
  })),
  on(PriorityTasksActions.readPriorityTasksFailure, (state: StateInterface, { error }: { error: string }) => ({
    ...state,
    error,
  })),
);

/**
 * Perform reducer logic on the PriorityTasks NGRX state store for a specific PriorityTasks actions.
 *
 * @param {(StateInterface | undefined)} state The NGRX application state store.
 * @param {Action} action The NGRX PriorityTasks action.
 * @return {StateInterface} The new NGRX application state store after the reducer has run.
 * @export
 */
export function reducer(state: StateInterface | undefined, action: Action): StateInterface {
  return priorityTasksReducer(state, action);
}
