import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonRoutesEnum } from '@surecloud/common';
import { catchError, from, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { LinkedRecordService } from '../../../services/linked-records/linked-record.service';
import { RecordLinkedActions } from '../../actions/linked-records.actions';

/**
 * The Effects/side effects for LinkedRecords.
 * @export
 * @class LinkedRecordsEffects
 */
@Injectable({ providedIn: 'root' })
export class LinkedRecordsEffects {
  /**
   * When the Record Linked List is loaded,
   * Request all Linked Records
   * @memberof LinkedRecordsEffects
   */
  readRecordLinkedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.readLinkedRecordList),
      switchMap(({ linkedRecordInputList }) =>
        from(linkedRecordInputList).pipe(
          mergeMap((data) =>
            this.linkedRecordService.read(data).pipe(
              map((normalisedRecordLink) => RecordLinkedActions.readLinkedRecordsDataSuccess({ normalisedRecordLink })),
              catchError((error: unknown) =>
                of(RecordLinkedActions.readLinkedRecordsDataFailure({ error: `${error}` }))
              )
            )
          )
        )
      )
    )
  );

  /**
   * When a user selects a new Record View Table tab,
   * Request Linked Records for that Tab
   * @memberof LinkedRecordsEffects
   */
  readLinkedRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.readLinkedRecord),
      switchMap(({ linkedRecordInput }) =>
        this.linkedRecordService.read(linkedRecordInput).pipe(
          map((normalisedRecordLink) => RecordLinkedActions.readLinkedRecordsDataSuccess({ normalisedRecordLink })),
          catchError((error: unknown) => of(RecordLinkedActions.readLinkedRecordsDataFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the linked records data is read
   * Then store all the linked records
   * @memberof LinkedRecordsEffects
   */
  loadLinkedRecordsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.readLinkedRecordsDataSuccess),
      map(({ normalisedRecordLink }) =>
        RecordLinkedActions.readLinkedRecordsSuccess({ recordLink: normalisedRecordLink.link })
      )
    )
  );

  /**
   * When the User confirm the records to unlink
   * Then the records are sent to the API,
   * And the records are stored locally
   * @memberof LinkedRecordsEffects
   */
  unlinkRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.unlinkRecords),
      switchMap(({ unlinkRecords, tabId }) =>
        this.linkedRecordService.unlink(unlinkRecords).pipe(
          map(() => ({ recordsIds: unlinkRecords.linkedRecordIds })),
          map(({ recordsIds }) => RecordLinkedActions.unlinkRecordsSuccess({ recordsIds, tabId })),
          catchError((error: unknown) => of(RecordLinkedActions.unlinkRecordsFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When create Record Linked button is pressed
   * Then dispatch an event to add a Record.
   * @memberof LinkedRecordsEffects
   */
  createNewLinkedRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordLinkedActions.createNewLinkedRecord),
      switchMap(({ requestId, tabId, linkId, recordId }) =>
        this.linkedRecordService.create({ linkId, recordId }, requestId).pipe(
          map((record) => ({ ...record, tabId })),
          map((record) => RecordLinkedActions.createLinkedRecordSuccess({ recordId: record.recordId })),
          catchError((error: unknown) => of(RecordLinkedActions.createLinkedRecordFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user create a new linked record page.
   * Then navigate a user to that record page.
   * @memberof LinkedRecordsEffects
   */
  navigateToRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordLinkedActions.createLinkedRecordSuccess),
        tap(({ recordId }) => {
          this.router.navigate([CommonRoutesEnum.RecordViewRoot, recordId]);
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of LinkedRecordsEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {LinkedRecordService} linkedRecordService The Linked Records service.
   * @param {Router} router The Angular Router.
   * @memberof LinkedRecordsEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly linkedRecordService: LinkedRecordService,
    private readonly router: Router
  ) {}
}
