<ng-container *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
  <div class="sc-dropdown-hierarchy">
    <kendo-label [text]="label" class="sc-label">
      <div #anchor>
        <kendo-dropdownlist
          (keydown.enter)="toggleDropdown()"
          (click)="toggleDropdown()"
          class="sc-dropdown-hierarchy__dropdown"
          [formControlName]="controlName"
          [data]="dropdownItems"
          textField="name"
          valueField="id"
          size="none"
          rounded="none"
          [ngClass]="{ 'sc-dropdown-hierarchy--open': dropdownVisibility }"
          [valuePrimitive]="true"
          [popupSettings]="{
            popupClass: 'hidden'
          }">
        </kendo-dropdownlist>
      </div>
      <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
    </kendo-label>
    <kendo-popup
      positionMode="absolute"
      #popup
      [anchor]="anchor"
      [animate]="false"
      (anchorViewportLeave)="dropdownVisibility = false"
      *ngIf="dropdownVisibility"
      class="sc-dropdown-hierarchy__popup sc-w-inherit">
      <ng-scrollbar>
        <sc-hierarchy
          [currentNode]="currentNode"
          [topLevelNode]="topLevelNode"
          (selected)="selected($event)"
          (destroyed)="dropdownMenuDestroyed.emit()"
          (requestNewCurrentNode)="requestNewCurrentNode.emit($event)"
          (hierarchyPath)="hierarchyPath.emit($event)">
        </sc-hierarchy>
      </ng-scrollbar>
    </kendo-popup>
  </div>
</ng-container>
