import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { NormalisedHomepageApplications } from '../../services/normalise-get-homepage-applications/normalise-get-homepage-applications.validation';
import { HomepageApplicationInterface } from '../models/homepage-applications/homepage-applications.models';

/**
 * Homepage Applications API actions map.
 */
const HOMEPAGE_APPLICATIONS_API_ACTIONS_MAP = {
  'Read Homepage Applications': emptyProps(),
  'Read Homepage Applications Data Success': props<{
    normalisedHomepageApplications: NormalisedHomepageApplications;
  }>(),
  'Read Homepage Applications Success': props<{
    homepageApplications: HomepageApplicationInterface[];
    tabId?: string;
  }>(),
  'Read Homepage Applications Failure': props<{ error: string }>(),
  'Select Homepage Application Success': props<{ applicationName: string }>(),
  'UnSelect Homepage Application Tab': emptyProps(),
};

/**
 * Homepage Applications API actions.
 */
const HomepageApplicationsApiActions = createActionGroup({
  source: 'HomepageApplications/API',
  events: HOMEPAGE_APPLICATIONS_API_ACTIONS_MAP,
});

/**
 * Homepage Applications page actions
 */
export const HomepageApplicationsPageActions = {
  selectHomepageApplication: createAction(
    '[Home Page] Select Homepage Application',
    props<{ applicationName: string }>()
  ),
};

/**
 * Combined Homepage Applications API and HomepageApplications Page actions.
 */
export const HomepageApplicationsActions = {
  ...HomepageApplicationsApiActions,
  ...HomepageApplicationsPageActions,
};
