import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { E2eHookDirective, NotRequiredBoolean, NotRequiredNumber } from '@surecloud/common';
import { SCORE_TO_TEXT_AND_COLOUR } from './score.mapper';

/**
 * Surecloud Score Component
 * @example
 * <sc-score [score]="score"
 * </sc-score>
 * @export
 * @class ScoreComponent
 */
@Component({
  selector: 'sc-score',
  standalone: true,
  imports: [CommonModule, InputsModule, E2eHookDirective],
  templateUrl: './score.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnChanges {
  /**
   * The value of the score
   * @type {(NotRequiredNumber)}
   * @memberof ScoreComponent
   */
  @Input() score: NotRequiredNumber;

  /**
   * Is the score disabled
   * @type {(NotRequiredBoolean)}
   * @memberof ScoreComponent
   */
  @Input() disabled: NotRequiredBoolean = false;

  /**
   * Automation testhook to add to the score.
   * @memberof ScoreComponent
   */
  @Input() testhook = 'score';

  /**
   * The component scss classes
   * @type {({ text: string; colour: string; } | undefined)}
   * @memberof ScoreComponent
   */
  className: { text: string; colour: string } | undefined;

  /**
   * When the score input of the component changes.
   * Then set these the classnames to reflect this changes.
   * @memberof ScoreComponent
   */
  ngOnChanges(): void {
    if (!this.score || this.score < 0 || this.score > 5 || this.disabled) {
      this.className = SCORE_TO_TEXT_AND_COLOUR['default'];
    } else {
      this.className = SCORE_TO_TEXT_AND_COLOUR[`${this.score}`];
    }
  }
}
