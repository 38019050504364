import {
  EntityAttributeRulTemporalUnitTypeEnum,
  EntityAttributeRuleConditionTypeEnum,
  EntityAttributeRuleTemporalOperatorTypeEnum,
  EntityAttributeRuleValueTypeEnum,
} from '@surecloud/common';

/**
 * Types of entity attribute rules that will have limit as an additional property.
 */
type EntityAttributeRuleLimitUnionType =
  | EntityAttributeRuleConditionTypeEnum.MaximumCharacterLimit
  | EntityAttributeRuleConditionTypeEnum.MinimumCharacterLimit
  | EntityAttributeRuleConditionTypeEnum.GreaterThan
  | EntityAttributeRuleConditionTypeEnum.LessThan;

/**
 * Types of entity attribute rules that will have linkedAttributeId as an additional property.
 */
type EntityAttributeRuleLinkedAttributeUnionType =
  | EntityAttributeRuleConditionTypeEnum.GreaterThan
  | EntityAttributeRuleConditionTypeEnum.LessThan;

export type DefineEntityAttributeRuleConditionType = Exclude<
  | EntityAttributeRuleConditionTypeEnum.GreaterThan
  | EntityAttributeRuleConditionTypeEnum.LessThan
  | EntityAttributeRuleConditionTypeEnum.MaximumCharacterLimit
  | EntityAttributeRuleConditionTypeEnum.MinimumCharacterLimit
  | EntityAttributeRuleConditionTypeEnum.Unique
  | EntityAttributeRuleConditionTypeEnum.Before
  | EntityAttributeRuleConditionTypeEnum.After
  | null,
  null
>;

/**
 * Types of entity attribute rules that will have date condition as an additional property.
 */
export type EntityAttributeRuleDateConditionUnionType =
  | EntityAttributeRuleConditionTypeEnum.Before
  | EntityAttributeRuleConditionTypeEnum.After;

/**
 * Types of entity attribute rules that will have date value type as an additional property.
 */
export type EntityAttributeRuleDateValueTypeUnionType =
  | EntityAttributeRuleValueTypeEnum.Period
  | EntityAttributeRuleValueTypeEnum.Date
  | EntityAttributeRuleValueTypeEnum.Attribute;

/**
 * Entity attribute rule interface for rules that have the additional linkedAttributeId property.
 *
 * @interface EntityAttributeRuleLimitInterface
 */
export interface EntityAttributeRuleLinkedAttributeInterface {
  attributeId: string;
  entityId: string;
  linkedAttributeId: string | null;
  ruleId: string;
  conditionType: EntityAttributeRuleLinkedAttributeUnionType;
  valueType: EntityAttributeRuleValueTypeEnum.Attribute;
}

/**
 * Entity attribute rule interface for rules that have the additional date property.
 *
 * @interface EntityAttributeRuleLimitInterface
 */
export interface EntityAttributeRuleDateAttributeInterface {
  attributeId: string;
  entityId: string;
  linkedAttributeId: string | null;
  ruleId: string;
  amount: number | null;
  date: string | null;
  temporalUnit: EntityAttributeRulTemporalUnitTypeEnum | null;
  temporalOperator: EntityAttributeRuleTemporalOperatorTypeEnum | null;
  conditionType: EntityAttributeRuleDateConditionUnionType;
  valueType: EntityAttributeRuleDateValueTypeUnionType;
}

/**
 * Entity attribute rule interface for rules that have the additional limit property.
 *
 * @interface EntityAttributeRuleLimitInterface
 */
export interface EntityAttributeRuleLimitInterface {
  attributeId: string;
  entityId: string;
  limit: number | null;
  ruleId: string;
  conditionType: EntityAttributeRuleLimitUnionType;
  valueType: EntityAttributeRuleValueTypeEnum.Number;
}

/**
 * Entity attribute rule interface for rules that have the unique property.
 *
 * @interface EntityAttributeRuleLimitInterface
 */
interface EntityAttributeRuleUniqueInterface {
  attributeId: string;
  entityId: string;
  ruleId: string;
  conditionType: EntityAttributeRuleConditionTypeEnum.Unique;
  valueType: null;
}

/**
 * The entity attribute rule interface.
 */
export type EntityAttributeRuleInterface =
  | EntityAttributeRuleLimitInterface
  | EntityAttributeRuleLinkedAttributeInterface
  | EntityAttributeRuleUniqueInterface
  | EntityAttributeRuleDateAttributeInterface
  | {
      attributeId: string;
      entityId: string;
      ruleId: string;
      conditionType: null;
      valueType: null;
    };

/**
 * Type guard to check if a entity attribute rule has the limit property on it.
 *
 * @param {EntityAttributeRuleInterface} rule The rule to check if a limit property is available on.
 * @return {boolean} Whether the rule is of type EntityAttributeRuleLimitInterface.
 */
export const hasEntityAttributeRuleLimitProperty = (rule: unknown): rule is EntityAttributeRuleLimitInterface =>
  ((rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.GreaterThan ||
    (rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.LessThan ||
    (rule as EntityAttributeRuleInterface).conditionType ===
      EntityAttributeRuleConditionTypeEnum.MaximumCharacterLimit ||
    (rule as EntityAttributeRuleInterface).conditionType ===
      EntityAttributeRuleConditionTypeEnum.MinimumCharacterLimit) &&
  (rule as EntityAttributeRuleInterface).valueType === EntityAttributeRuleValueTypeEnum.Number;

/**
 * Type guard to check if a entity attribute rule has the linkedAttributeId property on it.
 *
 * @param {EntityAttributeRuleInterface} rule The rule to check if a linkedAttributeId property is available on.
 * @return {boolean} Whether the rule is of type EntityAttributeRuleLinkedAttributeInterface.
 */
export const hasEntityAttributeRuleLinkedAttributeIdProperty = (
  rule: unknown
): rule is EntityAttributeRuleLinkedAttributeInterface =>
  ((rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.GreaterThan ||
    (rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.LessThan) &&
  (rule as EntityAttributeRuleInterface).valueType === EntityAttributeRuleValueTypeEnum.Attribute;

/**
 * Type guard to check if a entity attribute rule has the linkedAttributeId property on it.
 *
 * @param {EntityAttributeRuleInterface} rule The rule to check if a linkedAttributeId property is available on.
 * @return {boolean} Whether the rule is of type EntityAttributeRuleLinkedAttributeInterface.
 */
export const hasEntityAttributeRuleUniqueProperty = (rule: unknown): rule is EntityAttributeRuleUniqueInterface =>
  (rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.Unique;

/**
 * Type guard to check if a entity attribute rule has the date property on it.
 *
 * @param {EntityAttributeRuleInterface} rule The rule to check if a date property is available on.
 * @return {boolean} Whether the rule is of type EntityAttributeRulePeriodConditionUnionType.
 */
export const hasEntityAttributeRuleDateConditionProperty = (
  rule: unknown
): rule is EntityAttributeRuleDateAttributeInterface =>
  ((rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.Before ||
    (rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.After) &&
  (rule as EntityAttributeRuleInterface).valueType === EntityAttributeRuleValueTypeEnum.Date;

/**
 * Type guard to check if a entity attribute rule has the period property on it.
 *
 * @param {EntityAttributeRuleInterface} rule The rule to check if a date property is available on.
 * @return {boolean} Whether the rule is of type EntityAttributeRulePeriodConditionUnionType.
 */
export const hasEntityAttributeRuleDatePeriodConditionProperty = (
  rule: unknown
): rule is EntityAttributeRuleDateAttributeInterface =>
  ((rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.Before ||
    (rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.After) &&
  (rule as EntityAttributeRuleInterface).valueType === EntityAttributeRuleValueTypeEnum.Period;

/**
 * Type guard to check if a entity attribute rule has the linked attribute property on it.
 *
 * @param {EntityAttributeRuleInterface} rule The rule to check if a date property is available on.
 * @return {boolean} Whether the rule is of type EntityAttributeRulePeriodConditionUnionType.
 */
export const hasEntityAttributeRuleDateLinkedAttributeConditionProperty = (
  rule: unknown
): rule is EntityAttributeRuleDateAttributeInterface =>
  ((rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.Before ||
    (rule as EntityAttributeRuleInterface).conditionType === EntityAttributeRuleConditionTypeEnum.After) &&
  (rule as EntityAttributeRuleInterface).valueType === EntityAttributeRuleValueTypeEnum.Attribute;

/**
 * The interface for creating a entity attribute rule.
 *
 * @export
 * @interface EntityAttributeCreateRuleInterface
 */
export interface EntityAttributeCreateRuleInterface {
  entityId: string;
  attributeId: string;
}

/**
 * Interface returned whenever a rule is updated.
 *
 * @property {string} ruleId The rule ID that was updated.
 * @export
 * @interface EntityAttributeRuleUpdatedInterface
 */
export interface EntityAttributeRuleUpdatedInterface {
  ruleId: string;
}

export interface EntityAttributeRuleDataValidationInterface {
  ruleId: string;
  description: string;
  tag: string;
}
