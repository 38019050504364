import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationListItemInterface } from './navigation-list.component.interface';

/**
 * Generic Navigation List component.
 * @export
 * @class NavigationListComponent
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'sc-navigation-list',
  standalone: true,
  templateUrl: './navigation-list.component.html',
})
export class NavigationListComponent {
  /**
   * The navigation items to render.
   * @type {NavigationListItemInterface[]}
   * @memberof NavigationListComponent
   */
  @Input()
  navigationItems: NavigationListItemInterface[] | null = null;

  /**
   * Message to render if no navigation items are present.
   * @memberof NavigationListComponent
   */
  @Input()
  emptyMessage = $localize`No navigation items available`;

  /**
   * Navigation heading.
   * @memberof NavigationListComponent
   */
  @Input()
  heading = $localize`Navigation`;

  /**
   * The active navigation ID.
   * @type {(string | null)}
   * @memberof NavigationListComponent
   */
  @Input()
  activeNavigationItemId: string | null = null;

  /**
   * When a navigation item is clicked we will emit an active navigation item event.
   * @type {EventEmitter<NavigationListItemInterface>}
   * @memberof NavigationListComponent
   */
  @Output()
  activeNavigationItem: EventEmitter<NavigationListItemInterface> = new EventEmitter();

  /**
   * Expose the track by Record View Section function to the view template.
   * @memberof NavigationListComponent
   */
  trackByNavigationItem = NavigationListComponent.trackByNavigationItemFn;

  /**
   * Track the navigation items in the Angular for loop with this function.
   * @private
   * @static
   * @param {number} index The index in the loop.
   * @param {NavigationListItemInterface} item The navigation item we are looping over.
   * @return {string} The ID of the navigation item being looped over.
   * @memberof NavigationListComponent
   */
  private static trackByNavigationItemFn = (index: number, item: NavigationListItemInterface): string => `${item.id}`;

  /**
   * When a user clicks on a navigation item.
   * And it is not the current active navigation item.
   * Then dispatch the activeNavigationItem event.
   * @param {NavigationListItemInterface} navigationItem The navigation item that was clicked on.
   * @memberof NavigationListComponent
   */
  setActiveNavigationItem(navigationItem: NavigationListItemInterface): void {
    if (navigationItem.id !== this.activeNavigationItemId) {
      this.activeNavigationItem.emit(navigationItem);
    }
  }
}
