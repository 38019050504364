/**
 * Urgency Type enum
 *
 * @export
 * @enum {number}
 */
export enum UrgencyTypeEnum {
  Due = 'DUE',
  None = 'NONE',
  Overdue = 'OVERDUE'
}
