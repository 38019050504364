/* eslint-disable class-methods-use-this */
import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService, makeUUID } from '@surecloud/common';
import { mapStackTrace } from 'sourcemapped-stacktrace';

import { ErrorDetails } from '../model/error.models';
import { ERROR_FILENAME_REGEX, ERROR_LINE_NUMBER_REGEX } from '../regex/error.regex';
import { ErrorService } from '../service/error.service';
import { actionsLog } from './actions-log';
/**
 * Angular Global Error handler implementation
 * @export
 * @abstract
 * @class GlobalErrorHandler
 */
@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService, private errorService: ErrorService) {}

  /**
   * Handle error.
   * @param {Error} error The JS error object to handle.
   * @memberof GlobalErrorHandler
   */
  handleError(error: Error): void {
    mapStackTrace(error.stack, (mappedStack) => {
      const errorDetails = this.getError(mappedStack, error.message);
      this.loggerService.logError(errorDetails);
      if (errorDetails) this.errorService.logError(errorDetails);
    });
  }

  /**
   * Pass any action to the dispatch method of the store.
   * @param {string[]} stack The array of the error stack.
   * @param {string} message The error message
   * @return {ErrorDetails} the error details object
   * @memberof GlobalErrorHandler
   */
  getError(stack: string[], message: string): ErrorDetails | undefined {
    if (!stack?.[0]) return undefined;

    const firstInStack = stack[0];
    const lineMatch = ERROR_LINE_NUMBER_REGEX.exec(firstInStack);

    let line = 'No line number found';

    if (lineMatch) {
      [line] = lineMatch;
    }

    const fileMatch = ERROR_FILENAME_REGEX.exec(firstInStack);

    let fileName = 'No filename found';

    if (fileMatch) {
      [fileName] = fileMatch;
    }

    const { userAgent } = navigator;

    return {
      guid: makeUUID(),
      dateTime: new Date().toISOString(),
      userAgent,
      actions: actionsLog.get(),
      fileName,
      line,
      message,
      stackTrace: stack.join('\n'),
      url: window.location.href,
    };
  }
}
