<div class="mb-24" [formGroup]="form">
  <sc-user-avatar-multiselect
    formControlName="multiSelect"
    *ngIf="multiple"
    [description]="description"
    [label]="label ?? UNTITLED"
    [options]="userList"
    [readonly]="readonly"
    (filterChange)="filterChange($event)"></sc-user-avatar-multiselect>
  <sc-user-avatar-select
    formControlName="select"
    *ngIf="!multiple"
    [description]="description"
    [label]="label ?? UNTITLED"
    [options]="userList"
    [readonly]="readonly"
    (filterChange)="filterChange($event)"></sc-user-avatar-select>
</div>
