/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType, createAction, props } from '@ngrx/store';
import { RequestIdInterface } from '../../interfaces/request-id.interface';
import { EntityInterface } from '../../models/entity/entity.models';
import { RecordAttributeUnion } from '../../models/record-data.model';

/**
 * An object grouping common actions
 * @export
 */
export const CommonActions = {
  loadEntity: createAction('[Common] Load Entity'),

  loadEntityList: createAction('[Common] Load Entity List'),

  unselectEntity: createAction('[Common] Unselect Entity'),

  readEntitySuccess: createAction('[Common] Read Entity Success', props<{ entities: EntityInterface[] }>()),
  readAndSelectEntity: createAction('[Common] Read & Select Entity', props<{ entity: EntityInterface }>()),

  loadPriorityTasks: createAction('[Common] Load Priority Tasks'),

  recordUpdate: createAction(
    '[Common] Update Record',
    props<{
      recordComponentValue: {
        recordId: string;
        colId: string;
        attributes: RecordAttributeUnion[];
        viewerCanDelete: boolean;
      };
    }>()
  ),

  createNewRecordWithEntityIdForRecords: createAction(
    '[Common] Create Record With Entity Id For Records',
    props<RequestIdInterface>()
  ),

  startDeleteRecords: createAction('[Common] Delete Records', props<{ recordIds: string[] }>()),

  showConfirmationModal: createAction(
    '[Modal] Show Modal',
    props<{
      title: string;
      content: string;
      confirmAction: ActionType<any>;
      cancelAction?: ActionType<any>;
    }>()
  ),
  closeConfirmationModal: createAction('[Modal] Close'),

  exportRecords: createAction(
    '[Common] Export Records',
    props<{ entityId: string; attributeIds: string[]; exportName: string; recordIds: string[] }>()
  ),
};
