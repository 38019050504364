import { QuestionComponent } from '../../question/question.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { questionComponentCreator } from '../creators/question.creator';

/**
 * Control configuration for QUESTION renderer component
 *
 */
export const QUESTION_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: QuestionComponent,
  creatorFn: questionComponentCreator,
};

