import { MutationOptions } from '@apollo/client';
import {
  EntityPermissionCreateInputApiInterface,
  EntityPermissionDeleteInputApiInterface,
  EntityPermissionUpdateInputApiInterface,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';

/**
 * Create entity permission GraphQL mutation.
 */
const ENTITY_PERMISSION_CREATE_MUTATION = gql`
  mutation entityPermissionCreateMutation($input: EntityPermissionCreateInput!) {
    entityPermissionCreate(input: $input) {
      createdId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for creating an entity permission.
 * @param {EntityPermissionCreateInputApiInterface} input The input to create a new entity permission.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityPermissionCreateMutation = (
  input: EntityPermissionCreateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_PERMISSION_CREATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Delete entity permission GraphQL mutation.
 */
const ENTITY_PERMISSION_DELETE_MUTATION = gql`
  mutation entityPermissionDeleteMutation($input: EntityPermissionDeleteInput!) {
    entityPermissionDelete(input: $input) {
      deletedId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for deleting an entity permission.
 * @param {EntityPermissionDeleteInputApiInterface} input The input to delete an entity permission.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityPermissionDeleteMutation = (
  input: EntityPermissionDeleteInputApiInterface
): MutationOptions => ({
  mutation: ENTITY_PERMISSION_DELETE_MUTATION,
  variables: { input },
});

/**
 * Update entity permission GraphQL mutation.
 */
const ENTITY_PERMISSION_UPDATE_MUTATION = gql`
  mutation entityPermissionUpdateMutation($input: EntityPermissionUpdateInput!) {
    entityPermissionUpdate(input: $input) {
      permission {
        entityPermissionId
        createPermission
        readPermission
        updatePermission
        deletePermission
        role {
          roleId
          name
        }
      }
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for updating an entity permission.
 * @param {EntityPermissionUpdateInputApiInterface} input The input to update an entity permission.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityPermissionUpdateMutation = (
  input: EntityPermissionUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_PERMISSION_UPDATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});
