<sc-file-picker
  #filePicker
  [formControl]="inputFileControl"
  [multiple]="false"
  label="Import"
  i18n-label
  [allowedExtensions]="ALLOWED_EXTENSIONS"
  description="Select the excel document to use for data import"
  i18n-description>
</sc-file-picker>
<div class="flex justify-content-end sc-pt-16">
  <button class="sc-mr-8" sc-text-button i18n size="medium" themeColour="brand" fillMode="outline" (click)="cancel()">
    Cancel
  </button>
  <sc-loading-button
    i18n
    size="medium"
    themeColour="primary"
    [disabled]="!inputFileControl.dirty || filePicker.invalidFile"
    [loading]="loading"
    (startLoad)="import()">
    Import
  </sc-loading-button>
</div>
