import { CommonRoutesEnum } from '../routes/common-routes.enum';

/**
 * Record View routes.
 * @export
 * @enum {number}
 */
export const RecordViewRoutesEnum = {
  Root: CommonRoutesEnum.RecordViewRoot,
  RecordId: 'recordId',
  Success: 'success',
};

/**
 * Enum of any Record View Query Params used within the feature.
 * Mostly used for pagingating and filtering the Record View Questions.
 * @export
 * @enum {number}
 */
export enum RecordViewQueryParamsEnum {
  QuestionComponentId = 'questionComponentId',
}
