import { LogicConditionType } from '../models/logic-condition.models';

/**
 * The logic for displaying a title in a logic block.
 * @param {number} conditionIndex The index of the condition.
 * @param {number} parentIndex THe index of the parent.
 * @return {boolean} Returns.
 */
export const shouldShowLogicBlockTitle = (conditionIndex: number, parentIndex?: number): boolean =>
  !!(parentIndex && parentIndex > 0 && conditionIndex === 0);

/**
 * Filter logic conditions based on a parent ID.
 * @param {LogicConditionType[]} logicConditions The array of logic conditions.
 * @param {string} parentId The parent ID to filter the array with.
 * @return {LogicConditionType[]} The filtered logic conditions.
 */
export const getLogicConditionsForParentId = (
  logicConditions: LogicConditionType[],
  parentId: string
): LogicConditionType[] => logicConditions.filter((logicCondition) => logicCondition.parentId === parentId);
