import { EntityAttributeTypeEnum } from '../interfaces/entity-attribute-type/entity-attribute-type.enum';
import { ScIconType } from '../interfaces/icon.interface';

export const NO_ATTRIBUTE_TYPE = 'NO_ATTRIBUTE_TYPE';

/**  @const {Record<EntityAttributeTypeEnum | typeof NO_ATTRIBUTE_TYPE, ScIcon>} ATTRIBUTE_ICON_MAP  name of the icon for the attribute type */
export const ATTRIBUTE_ICON_MAP: Record<`${EntityAttributeTypeEnum}` | typeof NO_ATTRIBUTE_TYPE, ScIconType> = {
  [EntityAttributeTypeEnum.Calculation]: 'calculation',
  [EntityAttributeTypeEnum.Date]: 'calendar',
  [EntityAttributeTypeEnum.Document]: 'file-text-outline',
  [EntityAttributeTypeEnum.Email]: 'email',
  [EntityAttributeTypeEnum.Hierarchy]: 'hierarchy',
  [EntityAttributeTypeEnum.Number]: 'number',
  [EntityAttributeTypeEnum.OptionList]: 'unordered-list',
  [EntityAttributeTypeEnum.Sequence]: 'sequence',
  [EntityAttributeTypeEnum.State]: 'state',
  [EntityAttributeTypeEnum.Text]: 'text',
  [EntityAttributeTypeEnum.Toggle]: 'toggle',
  [EntityAttributeTypeEnum.Url]: 'web',
  [EntityAttributeTypeEnum.User]: 'user',
  [EntityAttributeTypeEnum.Context]: 'text',
  [EntityAttributeTypeEnum.Name]: 'user',
  [EntityAttributeTypeEnum.Owner]: 'user',
  [NO_ATTRIBUTE_TYPE]: 'alert',
};
