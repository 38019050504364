import { Injectable } from '@angular/core';
import {
  LinkableRecordsApiInterfaceQueryService,
  LinkedRecordsInputApiInterface,
  RecordLinkInputApiInterface,
  RecordLinkMutationApiInterfaceMutationService,
} from '@surecloud/api-types';
import { RecordInterface, makeContextWithRequestIdHeader } from '@surecloud/common';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { TableRecordLinkInterface } from '../normalise-get-link-records/get-link.records.interface';
import { NormaliseGetLinkedRecordsService } from '../normalise-get-link-records/normalise-get-link-records.service';

export const RECORD_LINK_ERROR = 'No link record response data from link record returned from API';

/**
 * Linkable Records API service.
 * @export
 * @class LinkableRecordService
 */
@Injectable({
  providedIn: 'root',
})
export class LinkableRecordService {
  /**
   * Creates an instance of LinkableRecordService.
   * @param {RecordLinkMutationApiInterfaceMutationService} recordLinkMutationApiInterfaceMutationService - The service for linking records
   * @param {LinkableRecordsApiInterfaceQueryService} linkableRecordsApiInterfaceQueryService The service for querying linkable records
   * @param {NormaliseGetLinkedRecordsService} normaliseGetLinkedRecordsService The linked record normalise service.
   * @memberof LinkableRecordService
   */
  constructor(
    private readonly recordLinkMutationApiInterfaceMutationService: RecordLinkMutationApiInterfaceMutationService,
    private readonly linkableRecordsApiInterfaceQueryService: LinkableRecordsApiInterfaceQueryService,
    private readonly normaliseGetLinkedRecordsService: NormaliseGetLinkedRecordsService
  ) {}

  /**
   * Query for all linkable records by a record
   * @param {LinkedRecordsInputApiInterface} linkedRecordsInput The linked records API input data.
   * @return {Observable<TableRecordLinkInterface>} Record link data.
   * @memberof LinkableRecordService
   */
  read(linkedRecordsInput: LinkedRecordsInputApiInterface): Observable<TableRecordLinkInterface> {
    return this.linkableRecordsApiInterfaceQueryService
      .fetch({ input: linkedRecordsInput })
      .pipe(
        switchMap(({ data }) =>
          data?.linkableRecords
            ? of(
                this.normaliseGetLinkedRecordsService.flatten(
                  linkedRecordsInput.tabId as string,
                  data.linkableRecords.records as RecordInterface[],
                  false,
                  data.linkableRecords.viewerCanCreate
                )
              )
            : throwError(
                () =>
                  `No response data from get Linkable Records from Record ${linkedRecordsInput.recordId} returned from API`
              )
        )
      );
  }

  /**
   * Link's records to a record
   * @param {RecordLinkInputApiInterface} linkRecords The link records API input data.
   * @param {string | null} requestId The requestId.
   * @return {Observable<{ recordId: string }>} The record ID.
   * @memberof LinkableRecordService
   */
  link(linkRecords: RecordLinkInputApiInterface, requestId: string | null = null): Observable<{ recordId: string }> {
    return this.recordLinkMutationApiInterfaceMutationService
      .mutate({ input: linkRecords }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.recordLink?.record
            ? of({ recordId: data.recordLink.record.recordId })
            : throwError(() => RECORD_LINK_ERROR)
        )
      );
  }
}
