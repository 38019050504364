import {
  BaseRuleActionInterface,
  makeCreateRecordRuleAction,
  makeCreateTaskRuleAction,
  makeInviteUserGuestRuleAction,
  makeSendNotificationRuleAction,
  makeSetAttributeRuleAction,
  RuleActionInterface,
  RuleActionTypeEnum,
} from './rule-action.models';

/**
 * confirmTypeAndGetSetAttributeRuleAction
 * @param {RuleActionInterface} ruleAction - A RuleAction
 * @return {RuleActionInterface} - the new RuleAction
 */
export const confirmTypeAndGetSetAttributeRuleAction = (ruleAction: RuleActionInterface): RuleActionInterface => {
  // Initialise values
  if (ruleAction.type !== RuleActionTypeEnum.SetAttribute) {
    const { rootId, parentId, actionId, canRecur } = ruleAction;
    const baseRuleActionData: BaseRuleActionInterface = {
      rootId,
      parentId,
      actionId,
      canRecur,
      recurring: false,
      recurringType: null,
      recurringDailyType: null,
      recurringDailyUnit: null,
      recurringLimitType: null,
      recurringLimitUnit: null,
    };
    return makeSetAttributeRuleAction(baseRuleActionData);
  }
  // Existing values
  return {
    ...ruleAction,
    destinationAttributeId: ruleAction.destinationAttributeId,
    sourceAttributeId: ruleAction.sourceAttributeId,
    sourceEntityId: ruleAction.sourceEntityId,
    setAttributeDateUnit: ruleAction.setAttributeDateUnit,
    setAttributeDateDirection: ruleAction.setAttributeDateDirection,
    setAttributeDateAmount: ruleAction.setAttributeDateAmount,
    setAttributeDateType: ruleAction.setAttributeDateType,
    type: RuleActionTypeEnum.SetAttribute,
  };
};

/**
 * confirmTypeAndGetCheckRecordRuleAction
 * @param {RuleActionInterface} ruleAction - A RuleAction
 * @return {RuleActionInterface} - the new RuleAction
 */
export const confirmTypeAndGetCheckRecordRuleAction = (ruleAction: RuleActionInterface): RuleActionInterface => {
  // Initialise values
  if (ruleAction.type !== RuleActionTypeEnum.CreateRecord) {
    const { rootId, parentId, actionId, canRecur } = ruleAction;
    const baseRuleActionData: BaseRuleActionInterface = {
      rootId,
      parentId,
      actionId,
      canRecur,
      recurring: false,
      recurringType: null,
      recurringDailyType: null,
      recurringDailyUnit: null,
      recurringLimitType: null,
      recurringLimitUnit: null,
    };
    return makeCreateRecordRuleAction(baseRuleActionData);
  }
  // Existing values
  return {
    ...ruleAction,
    entityId: ruleAction.entityId,
    type: RuleActionTypeEnum.CreateRecord,
  };
};

/**
 * Confirms the type of action is not currently a Send Notification action
 * And return a new Action with updated Send Notification type.
 * @param {RuleActionInterface} ruleAction - A RuleAction
 * @return {RuleActionInterface} - the new RuleAction
 */

export const confirmTypeAndGetSendNotificationRuleAction = (ruleAction: RuleActionInterface): RuleActionInterface => {
  // Initialise values
  if (ruleAction.type !== RuleActionTypeEnum.SendNotification) {
    const { rootId, parentId, actionId, canRecur } = ruleAction;
    const baseRuleActionData: BaseRuleActionInterface = {
      rootId,
      parentId,
      actionId,
      canRecur,
      recurring: false,
      recurringType: null,
      recurringDailyType: null,
      recurringDailyUnit: null,
      recurringLimitType: null,
      recurringLimitUnit: null,
    };
    return makeSendNotificationRuleAction(baseRuleActionData);
  }
  // Existing values
  return {
    ...ruleAction,
    notificationId: ruleAction.notificationId,
    recipientAttributeId: ruleAction.recipientAttributeId,
    type: RuleActionTypeEnum.SendNotification,
  };
};

export const confirmTypeAndGetInviteGuestUserAction = (ruleAction: RuleActionInterface): RuleActionInterface => {
  // Initialise values
  if (ruleAction.type !== RuleActionTypeEnum.InviteGuestUser) {
    const { rootId, parentId, actionId, canRecur } = ruleAction;
    const baseRuleActionData: BaseRuleActionInterface = {
      rootId,
      parentId,
      actionId,
      canRecur,
      recurring: false,
      recurringType: null,
      recurringDailyType: null,
      recurringDailyUnit: null,
      recurringLimitType: null,
      recurringLimitUnit: null,
    };
    return makeInviteUserGuestRuleAction(baseRuleActionData);
  }
  // Existing values
  return {
    ...ruleAction,
    notificationId: ruleAction.notificationId,
    recipientAttributeId: ruleAction.recipientAttributeId,
    type: RuleActionTypeEnum.InviteGuestUser,
  };
};
/**
 * Confirms the type of action is not currently a Create Task action
 * And return a new Action with updated Create Task type.
 * @param {RuleActionInterface} ruleAction - A RuleAction
 * @return {RuleActionInterface} - the new RuleAction
 */
export const confirmTypeAndGetCreateTaskRuleAction = (ruleAction: RuleActionInterface): RuleActionInterface => {
  // Initialise values
  if (ruleAction.type !== RuleActionTypeEnum.CreateTask) {
    const { rootId, parentId, actionId, canRecur } = ruleAction;
    const baseRuleActionData: BaseRuleActionInterface = {
      rootId,
      parentId,
      actionId,
      canRecur,
      recurring: false,
      recurringType: null,
      recurringDailyType: null,
      recurringDailyUnit: null,
      recurringLimitType: null,
      recurringLimitUnit: null,
    };
    return makeCreateTaskRuleAction(baseRuleActionData);
  }
  // Existing values
  return {
    ...ruleAction,
    assigneeId: ruleAction.assigneeId,
    description: ruleAction.description,
    summary: ruleAction.summary,
    type: RuleActionTypeEnum.CreateTask,
  };
};

/**
 * formatRuleActionForNewType
 * Helper function to reformat a RuleAction when the type changes (each type has specific properties).
 * @param {RuleActionInterface} ruleAction - A RuleAction
 * @param {RuleActionTypeEnum | null} newRuleType - The new type.
 * @return {RuleActionInterface} - the new RuleAction
 */
export const formatRuleActionForNewType = (
  ruleAction: RuleActionInterface,
  newRuleType: RuleActionTypeEnum | null
): RuleActionInterface => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (newRuleType) {
    case RuleActionTypeEnum.SetAttribute: {
      return confirmTypeAndGetSetAttributeRuleAction(ruleAction);
    }
    case RuleActionTypeEnum.CreateRecord: {
      return confirmTypeAndGetCheckRecordRuleAction(ruleAction);
    }
    case RuleActionTypeEnum.CreateTask: {
      return confirmTypeAndGetCreateTaskRuleAction(ruleAction);
    }
    case RuleActionTypeEnum.SendNotification: {
      return confirmTypeAndGetSendNotificationRuleAction(ruleAction);
    }
    case RuleActionTypeEnum.InviteGuestUser: {
      return confirmTypeAndGetInviteGuestUserAction(ruleAction);
    }
    default: {
      return ruleAction;
    }
  }
};
