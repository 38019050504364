<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<nav
  class="sc-global-nav w-full sc-bg-brand-500 flex align-items-center justify-content-between sc-py-8 sc-pr-24 sc-pl-8"
  [ngClass]="{ 'sc-global-nav--standalone': isStandalone }">
  <sc-icon-button
    class="sc-mr-4 sc-shift-up-2"
    size="x-large"
    themeColour="global-nav"
    icon="home"
    (click)="homeClicked()">
  </sc-icon-button>
  <!--
      ORG NAME / HOME LINK
     -->
  <a
    i18n
    [routerLink]="homeLink.route"
    [attr.aria-label]="homeLink.label"
    class="sc-font-heading-lg align-self-center text-white sc-mr-24 sc-global-nav__org-name"
    [attr.data-menu]="homeLink.label"
    >{{ orgName }}</a
  >
  <!--
    CENTER NAVIGATION
   -->
  <div class="flex align-items-center flex-grow-1">
    <ul class="flex align-items-center justify-content-between">
      <li i18n *ngFor="let link of globalNavLinks; trackBy: trackByFunction">
        <a
          *ngIf="link.route"
          [routerLink]="link.route"
          class="toolbars__global-nav-main-link"
          [ngClass]="{
            'toolbars__global-nav-main-link--active': activeRouteLabel === link.label
          }"
          [attr.data-menu]="link.label"
          >{{ link.label }}</a
        >
        <!--
          NOTE - Currently only "Designer" has a submenu. Change needed to accommodate further submenus.
         -->
        <ng-container *ngIf="!link.route">
          <button
            #menuAnchor
            class="toolbars__global-nav-main-link"
            [ngClass]="{
              'toolbars__global-nav-main-link--active': activeRouteLabel === link.label
            }"
            (keydown.enter)="onToggle()"
            (click)="onToggle()"
            [attr.data-menu]="link.label">
            {{ link.label }}
          </button>
          <!--
            Designer Submenu
           -->
          <kendo-popup
            #menu
            *ngIf="show"
            popupClass="toolbars__global-nav sc-border-radius-8 sc-border"
            [anchor]="menuAnchor"
            [animate]="false"
            [margin]="{ horizontal: 0, vertical: 8 }"
            (anchorViewportLeave)="show = false">
            <ul class="list-none">
              <li *ngFor="let subLink of link.links; trackBy: trackByFunction">
                <a
                  [routerLink]="subLink.route"
                  routerLinkActive="toolbars__global-nav-link--active"
                  class="toolbars__global-nav-link block sc-p-12"
                  [class.sc-border-bottom--grey-100]="link.isEndOfSection">
                  {{ subLink.label }}
                </a>
              </li>
            </ul>
          </kendo-popup>
        </ng-container>
      </li>
    </ul>
  </div>
  <!--
    RIGHT-HAND-SIDE MENU
  -->
  <div class="flex align-items-center">
    <sc-icon-button
      class="sc-px-4"
      size="x-large"
      themeColour="global-nav"
      icon="question-circle"
      (click)="helpClicked.emit($event)">
    </sc-icon-button>
    <sc-icon-button
      *ngIf="canViewTasks"
      [ngClass]="{ 'sc-global-nav__icon-notification': numberOfNewTasks && numberOfNewTasks > 0 }"
      [attr.data-value]="numberOfNewTasks && numberOfNewTasks > 9 ? '9+' : numberOfNewTasks"
      class="sc-px-4"
      size="x-large"
      themeColour="global-nav"
      icon="inbox"
      (click)="taskClicked.emit($event)">
    </sc-icon-button>
    <sc-icon-button
      *ngIf="canViewUserMenu"
      class="sc-pl-4"
      size="x-large"
      themeColour="global-nav"
      icon="user-circle"
      (click)="userClicked.emit($event)">
    </sc-icon-button>
  </div>
</nav>
