import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';

/**
 * Surecloud No Type Component.
 *
 * @export
 * @class NoTypeComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-no-type',
  standalone: true,
  imports: [LabelModule, TextBoxModule, InputsModule, FormsModule, ReactiveFormsModule],
  styleUrls: ['./no-type.component.scss'],
  templateUrl: './no-type.component.html',
})
export class NoTypeComponent {
  /**
   * Sets the text value for the label.
   *
   * @memberof NoTypeComponent
   */
  @Input() label = '';

  /**
   * Sets the text value for the placeholder.
   *
   * @memberof NoTypeComponent
   */
  @Input() placeholder = '';
}
