import { Injectable } from '@angular/core';
import { LinkedEntityTypeApiInterface } from '@surecloud/api-types';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { LinkedEntityTagInterface } from '../../+state/models/linked-entity-tag/linked-entity-tag.model';
import { LinkedEntityInterface } from '../../+state/models/linked-entity/linked-entity.model';
import {
  apolloLinkedEntityCreateMutation,
  apolloLinkedEntityDeleteMutation,
  apolloLinkedEntityTagCreateMutation,
  apolloLinkedEntityTagDeleteMutation,
  apolloLinkedEntityUpdateMutation,
} from '../../mutations/linked-entity/linked-entity.mutations';
import {
  LinkedEntityCreateInputApiInterface,
  LinkedEntityDeleteInputApiInterface,
  LinkedEntityTagCreateInputApiInterface,
  LinkedEntityTagDeleteInputApiInterface,
  LinkedEntityUpdateInputApiInterface,
} from './linked-entity.input.interface';
import {
  LinkedEntityCreatePayloadApiInterface,
  LinkedEntityDeletePayloadApiInterface,
  LinkedEntityTagCreatePayloadApiInterface,
  LinkedEntityTagDeletePayloadApiInterface,
  LinkedEntityUpdatePayloadApiInterface,
} from './linked-entity.input.payload.interface';

@Injectable({
  providedIn: 'root',
})
export class LinkedEntityService {
  /**
   * Creates an instance of LinkedEntityService.
   * @param {Apollo} apollo The Apollo service.
   * @memberof LinkedEntityService
   */
  constructor(private readonly apollo: Apollo) {}

  /**
   * Create a linked entity.
   * @param {LinkedEntityCreateInputApiInterface} linkedEntity the lineked entities to create.
   * @param {string | null} requestId The requestId.
   * @return {Observable<LinkedEntityInterface[]>} The created entity returned from the API.
   * @memberof LinkedEntityService
   */
  create(linkedEntity: LinkedEntityCreateInputApiInterface, requestId: string | null = null): Observable<LinkedEntityInterface[]> {
    return this.apollo
      .mutate<LinkedEntityCreatePayloadApiInterface>(apolloLinkedEntityCreateMutation(linkedEntity, requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.entityLinkCreate
            ? of(
                data.entityLinkCreate.createdIds.map((linkedEntityId) => ({
                  linkedEntityId,
                  entityId: linkedEntity.entityId,
                  type: LinkedEntityTypeApiInterface.MultipleApiInterface,
                }))
              )
            : throwError(() => 'No response data from create linked entity returned from API')
        )
      );
  }

  /**
   * Delete a linked entity.
   * @param {LinkedEntityDeleteInputApiInterface} linkedEntity the linked entities to delete.
   * @return {Observable<{deletedIds: string[]}}>} The deleted linked entities ids returned from the API.
   * @memberof LinkedEntityService
   */
  delete(linkedEntity: LinkedEntityDeleteInputApiInterface): Observable<{ deletedIds: string[] }> {
    return this.apollo
      .mutate<LinkedEntityDeletePayloadApiInterface>(apolloLinkedEntityDeleteMutation(linkedEntity))
      .pipe(
        switchMap(({ data }) =>
          data?.entityLinkDelete
            ? of(data.entityLinkDelete)
            : throwError(() => 'No response data from delete linked entity returned from API')
        )
      );
  }

  /**
   * Update a linked entity type
   * @param {LinkedEntityUpdateInputApiInterface} linkedEntity the linked entity to update
   * @param {string | null} requestId The requestId.
   * @return {Observable<{ linkedEntityId: string; type: LinkedEntityTypeApiInterface }>} the updated linked id data
   * @memberof LinkedEntityService
   */
  update(
    linkedEntity: LinkedEntityUpdateInputApiInterface, requestId: string | null = null
  ): Observable<{ linkedEntityId: string; type: LinkedEntityTypeApiInterface }> {
    return this.apollo
      .mutate<LinkedEntityUpdatePayloadApiInterface>(apolloLinkedEntityUpdateMutation(linkedEntity, requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.entityLinkUpdate
            ? of(data.entityLinkUpdate.linkedEntity)
            : throwError(() => 'No response data from update linked entity returned from API')
        )
      );
  }

  /**
   * Create a linked entity tag.
   * @param {LinkedEntityTagCreateInputApiInterface} linkedEntityTag the linked entity tag to create.
   * @param {string | null} requestId The requestId.
   * @return {Observable<LinkedEntityTagInterface>} The created linked entity tag returned from the API.
   * @memberof LinkedEntityService
   */
  createTag(linkedEntityTag: LinkedEntityTagCreateInputApiInterface, requestId: string | null = null): Observable<LinkedEntityTagInterface> {
    return this.apollo
      .mutate<LinkedEntityTagCreatePayloadApiInterface>(apolloLinkedEntityTagCreateMutation(linkedEntityTag, requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.entityLinkTagCreate
            ? of({
                tagId: data.entityLinkTagCreate.createdId,
                linkedEntityId: linkedEntityTag.linkedEntityId,
                entityId: linkedEntityTag.entityId,
                label: linkedEntityTag.label,
              })
            : throwError(() => 'No response data from create linked entity tag returned from API')
        )
      );
  }

  /**
   * Create a linked entity tag.
   * @param {LinkedEntityTagDeleteInputApiInterface} linkedEntityTag the lineked entity tag to delete.
   * @return {Observable<{deletedId: string}>} The deleted tag id returned from the API.
   * @memberof LinkedEntityService
   */
  deleteTag(linkedEntityTag: LinkedEntityTagDeleteInputApiInterface): Observable<{ deletedId: string }> {
    return this.apollo
      .mutate<LinkedEntityTagDeletePayloadApiInterface>(apolloLinkedEntityTagDeleteMutation(linkedEntityTag))
      .pipe(
        switchMap(({ data }) =>
          data?.entityLinkTagDelete
            ? of({
                deletedId: data.entityLinkTagDelete.deletedId,
              })
            : throwError(() => 'No response data from delete linked entity tag returned from API')
        )
      );
  }
}
