<div class="sc-modal-buttons flex justify-content-end sc-pt-16">
  <button
    sc-text-button
    i18n
    [disabled]="secondaryButtonDisabled"
    [size]="secondaryButtonSize"
    [themeColour]="secondaryButtonTheme"
    [fillMode]="secondaryButtonFillmode"
    [border]="secondaryButtonBorder"
    (click)="secondaryClicked.emit()"
    *ngIf="secondaryButtonShow">
    {{ secondaryButtonTitle }}
  </button>
  <button
    sc-text-button
    i18n
    class="sc-ml-8"
    [disabled]="primaryButtonDisabled"
    [size]="primaryButtonSize"
    [themeColour]="primaryButtonTheme"
    [fillMode]="primaryButtonFillmode"
    [border]="primaryButtonBorder"
    (click)="primaryClicked.emit()"
    *ngIf="primaryButtonShow">
    {{ primaryButtonTitle }}
  </button>
</div>
