import { SectionRendererConfigInterface, SectionRendererInterface } from '@surecloud/common';
import { makeAttributeConfigSectionId } from './global-attribute-config.model';

/**
 *
 * @param {string} text - The text property of an option.
 * @param {string} value - The value property of an option.
 * @export
 * @interface GlobalAttributeUtilsOptionInterface
 */
export interface GlobalAttributeUtilsOptionInterface {
  text: string;
  value: string;
}

/**
 *
 * @param {string} id - The navigation id.
 * @param {string} label - The navigation label.
 * @export
 * @interface GlobalAttributeUtilsNavigationLinkInterface
 */
export interface GlobalAttributeUtilsNavigationLinkInterface {
  id: string;
  label: string;
  defaultVisibility?: boolean;
}

/**
 * Utils Class for The Attribute Global Configs.
 * @export
 * @class GlobalAttributeUtils
 */
export class GlobalAttributeUtils {
  private typeOptions: GlobalAttributeUtilsOptionInterface[] = [];
  private sectionNavMap!: Map<string, GlobalAttributeUtilsNavigationLinkInterface[]>;
  private typeToSectionsMap!: Map<string, SectionRendererInterface[]>;
  private configs: SectionRendererConfigInterface[] = [];
  private propertiesSectionConfig = { id: makeAttributeConfigSectionId('properties'), label: $localize`Properties` };
  private permissionsSectionConfig = { id: makeAttributeConfigSectionId('permissions'), label: $localize`Permissions` };

  /**
   * Creates an instance of GlobalAttributeUtils.
   * @param {SectionRendererConfigInterface[]} globalAttributeConfigs - The config array.
   * @memberof GlobalAttributeUtils
   */
  constructor(private globalAttributeConfigs: SectionRendererConfigInterface[]) {
    this.configs = this.globalAttributeConfigs;
    this.makeOptionsFromTypes();
    this.makeSectionNavMap();
    this.makeTypeToSectionsMap();
  }

  /**
   * Public method to get the type options from the global configs.
   * @return { GlobalAttributeUtilsOptionInterface[]}  {GlobalAttributeUtilsOptionInterface[]} The global attribute utilities option.
   * @memberof GlobalAttributeUtils
   */
  public getTypeOptions(): GlobalAttributeUtilsOptionInterface[] {
    return this.typeOptions;
  }

  /**
   * Public method to get the section navigation from global config.
   * @param {string} typeKey - the type key to lookup the section nav for each type.
   * @return {*}  {GlobalAttributeUtilsNavigationLinkInterface[]}
   * @memberof GlobalAttributeUtils
   */
  public getSectionNav(typeKey: string): GlobalAttributeUtilsNavigationLinkInterface[] {
    return this.sectionNavMap.get(typeKey) || [];
  }

  /**
   * Public method to get the sections from global config.
   * @param {string} typeKey - the type key to lookup the section nav for each type.
   * @return {*}  {SectionRendererInterface[]}
   * @memberof GlobalAttributeUtils
   */
  public getSectionsForType(typeKey: string): SectionRendererInterface[] {
    return this.typeToSectionsMap.get(typeKey) || [];
  }

  /**
   * Public method to merge the dynamic sections with the preset ones in the correct order.
   * @param {GlobalAttributeUtilsNavigationLinkInterface[]} sections - the dynamic sections.
   * @return {*}  {GlobalAttributeUtilsNavigationLinkInterface[]}
   * @memberof GlobalAttributeUtils
   */
  public mergeDefaultNavigationSections(
    sections: GlobalAttributeUtilsNavigationLinkInterface[]
  ): GlobalAttributeUtilsNavigationLinkInterface[] {
    return [this.propertiesSectionConfig, ...sections, this.permissionsSectionConfig];
  }

  /**
   * Private method to create the typeToSection Map.
   * @private
   * @memberof GlobalAttributeUtils
   */
  private makeTypeToSectionsMap(): void {
    this.typeToSectionsMap = this.configs.reduce((sectionMap, config) => {
      const { uniqueSections, type } = config;
      if (!sectionMap.get(type)) {
        sectionMap.set(type, uniqueSections);
      }
      return sectionMap;
    }, new Map<string, SectionRendererInterface[]>());
  }

  /**
   * Private method to create the attribute type options from the global configs.
   * @private
   * @memberof GlobalAttributeUtils
   */
  private makeOptionsFromTypes(): void {
    this.typeOptions = this.configs.map<GlobalAttributeUtilsOptionInterface>(({ label, type }) => ({
      text: label,
      value: type,
    }));
  }

  /**
   * Private method to create the section navigation map from types.
   * @private
   * @memberof GlobalAttributeUtils
   */
  private makeSectionNavMap(): void {
    this.sectionNavMap = this.configs.reduce((map, item) => {
      const navLinks = item.uniqueSections.map(({ sectionName, sectionId, defaultVisibility }) => ({
        label: sectionName,
        id: sectionId,
        defaultVisibility,
      }));
      map.set(item.key, navLinks);
      return map;
    }, new Map());
  }

  /**
   * Static method to get the sections as nav links.
   * @param {SectionRendererInterface[]} sections - The sections to convert to nav links.
   * @return {GlobalAttributeUtilsNavigationLinkInterface[]} The nav links.
   */
  static getSectionsAsNavLinks(sections: SectionRendererInterface[]): GlobalAttributeUtilsNavigationLinkInterface[] {
    return sections.map(({ sectionName, sectionId }) => ({ label: sectionName, id: sectionId }));
  }
}
