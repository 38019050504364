import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { REQUEST_ERROR_FEATURE_KEY, reducer } from './+state/reducers/request-error.reducer';

/**
 * Error Module.
 * @export
 * @class RecordModule
 */
@NgModule({
  imports: [StoreModule.forFeature(REQUEST_ERROR_FEATURE_KEY, reducer)],
  providers: [],
})
export class ErrorModule {}
