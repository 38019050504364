import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommonActions, CommonRoutesEnum, LoggerService, selectNestedRouteParam } from '@surecloud/common';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { LinkedEntityService } from '../../../services/linked-entity/linked-entity.service';
import { EntityActions } from '../../actions/entity.actions';
import { LinkedEntityActions } from '../../actions/linked-entity.actions';

/**
 * The Effects/side effects for a Linked Entity.
 * @export
 * @class LinkedEntityEffects
 */
@Injectable({ providedIn: 'root' })
export class LinkedEntityEffects {

  /**
   * When a user enter a different entity.
   * Then reset the Linked entity data.
   * @memberof LinkedEntityEffects
   */
  leavePageWithLinkedEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entityReEnter),
      map(() => LinkedEntityActions.resetLinkedEntity())
    )
  );
  
  /**
   * When all entity view data has loaded successfully,
   * Then populate the Linked Entity state.
   * @memberof LinkedEntityEffects
   */
  loadLinkedEntitiesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.readOneEntityDataSuccess),
      map(({ normalisedEntityList }) =>
        LinkedEntityActions.readLinkedEntitySuccess({ links: normalisedEntityList.links || [] })
      )
    )
  );

  /**
   * Create linked entities
   * @memberof LinkedEntityEffects
   */
  createLinkedEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityActions.createLinkedEntities),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      switchMap(([{ linkedEntityIds }, entityId]) =>
        this.service.create({ entityId, linkedEntityIds }).pipe(
          map((linkedEntities) =>
            LinkedEntityActions.createLinkedEntitiesSuccess({
              createdIds: linkedEntities.map((linkedEntity) => linkedEntity.linkedEntityId),
              linkedEntities,
            })
          ),
          catchError((error: unknown) => of(LinkedEntityActions.createLinkedEntitiesFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When Delete Linked Entities button is pressed
   * Then dispatch an event to delete Linked Entities
   * @memberof LinkedEntityEffects
   */
  showDeleteLinkedEntityConfirmationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityActions.startDeleteLinkedEntities),
      map(({ linkedEntityIds }) => {
        const entityOrEntities = linkedEntityIds.length === 1 ? 'Linked Entity' : 'Linked Entities';
        const title = $localize`Confirm Delete ${entityOrEntities}`;
        const content = $localize`Are you sure you want to delete ${linkedEntityIds.length} ${entityOrEntities}?`;

        return CommonActions.showConfirmationModal({
          title,
          content,
          confirmAction: LinkedEntityActions.deleteLinkedEntities({ linkedEntityIds }),
        });
      })
    )
  );

  /**
   * Delete linked entities
   * @memberof LinkedEntityEffects
   */
  deleteLinkedEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityActions.deleteLinkedEntities),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      switchMap(([{ linkedEntityIds }, entityId]) =>
        this.service.delete({ entityId, linkedEntityIds }).pipe(
          map((deletedId) => LinkedEntityActions.deleteLinkedEntitiesSuccess(deletedId)),
          catchError((error: unknown) => of(LinkedEntityActions.deleteLinkedEntitiesFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * Update linked entities
   * @memberof LinkedEntityEffects
   */
  updateLinkedEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedEntityActions.updateLinkedEntities),
      withLatestFrom(this.store.select(selectNestedRouteParam(CommonRoutesEnum.EntityId))),
      switchMap(([{ linkedEntityId, linkedEntityType }, entityId]) =>
        this.service.update({ linkedEntityId, type: linkedEntityType, entityId }).pipe(
          map(({ type }) =>
            LinkedEntityActions.updateLinkedEntitiesSuccess({ linkedEntity: { linkedEntityId, type, entityId } })
          ),
          catchError((error: unknown) => of(LinkedEntityActions.updateLinkedEntitiesFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When a user cancels or confirms the deletion of linked entity.
   * Then close the confirm delete linked entity modal.
   * @memberof LinkedEntityEffects
   */
  leaveDeleteLinkedEntity$ = createEffect(() =>
    this.actions$.pipe(ofType(LinkedEntityActions.deleteLinkedEntities), map(CommonActions.closeConfirmationModal))
  );

  /**
   * When Linked Entity API called failed.
   * Then log the error.
   * @memberof LinkedEntityEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LinkedEntityActions.createLinkedEntitiesFailure, LinkedEntityActions.deleteLinkedEntitiesFailure),
        tap(({ error, type }) => this.logger.logEvent('Linked Entity', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of LinkedEntityEffects.
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {LoggerService} logger The common logger service.
   * @param {LinkedEntityService} service the linked entity service
   * @param {Store} store The NGRX store.
   * @memberof LinkedEntityEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly logger: LoggerService,
    private readonly service: LinkedEntityService,
    private readonly store: Store
  ) {}
}
