import { Inject, ModuleWithProviders, NgModule, Optional, Self } from '@angular/core';
import { ScIconInterface } from '@surecloud/common';
import { IconRegistry } from './icon-registry.service';
import { IconComponent } from './icon.component';
import { ScSvgIconModuleConfigInterface } from './icon.interface';
import { SVG_ICONS, SVG_ICONS_CONFIG, SVG_MISSING_ICON_FALLBACK } from './icon.tokens';

/**
 * Inner Icon Module, shouldn't be exported directly
 * @export
 * @class SvgIconsModule
 */
@NgModule({
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class SvgIconsModule {
  /**
   * ForRoot Icon module function
   * @static
   * @param {Partial<ScSvgIconModuleConfigInterface>} [config] config
   * @return {*}  {ModuleWithProviders<SvgIconsModule>}
   * @memberof SvgIconsModule
   */
  static forRoot(config: Partial<ScSvgIconModuleConfigInterface> = {}): ModuleWithProviders<SvgIconsModule> {
    return {
      ngModule: SvgIconsModule,
      providers: [
        {
          provide: SVG_ICONS_CONFIG,
          useValue: config,
        },
      ],
    };
  }

  /**
   * ForChild Icon module function
   * @static
   * @param {(ScIconInterface | ScIconInterface[])} icons provided Icons
   * @return {*}  {ModuleWithProviders<SvgIconsModule>}
   * @memberof SvgIconsModule
   */
  static forChild(icons: ScIconInterface | ScIconInterface[]): ModuleWithProviders<SvgIconsModule> {
    return {
      ngModule: SvgIconsModule,
      providers: [{ provide: SVG_ICONS, useValue: icons, multi: true }],
    };
  }

  constructor(
    @Optional() @Self() @Inject(SVG_ICONS) icons: ScIconInterface[] | ScIconInterface[][],
    @Optional() @Inject(SVG_MISSING_ICON_FALLBACK) missingIconFallback: ScIconInterface,
    private service: IconRegistry
  ) {
    if (icons) {
      this.service.register(([] as ScIconInterface[]).concat(...icons));
    }

    if (missingIconFallback) {
      this.service.register(missingIconFallback);
    }
  }
}
