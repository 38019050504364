import { Injectable } from '@angular/core';
import {
  EntityAttributeCreateMutationApiInterfaceMutationService,
  EntityAttributeDeleteInputApiInterface,
  EntityAttributeDeleteMutationApiInterfaceMutationService,
  EntityAttributeUpdateMutationApiInterfaceMutationService,
} from '@surecloud/api-types';
import {
  EntityAttributeCreateInterface,
  EntityAttributeInterface,
  EntityAttributeIntersectionInterface,
  EntityAttributeUpdatedInterface,
  EntityCalculationAttributeUpdatedInterface,
  OptionalExcluding,
  makeContextWithRequestIdHeader,
  makeEntityAttribute,
} from '@surecloud/common';
import { Observable, of, switchMap, throwError } from 'rxjs';

/**
 * Service to get the validation and format data for an attribute.
 * @export
 * @class EntityAttributeService
 */
@Injectable({
  providedIn: 'root',
})
export class EntityAttributeService {
  constructor(
    private readonly entityAttributeCreateMutationApiInterfaceMutationService: EntityAttributeCreateMutationApiInterfaceMutationService,
    private readonly entityAttributeUpdateMutationApiInterfaceMutationService: EntityAttributeUpdateMutationApiInterfaceMutationService,
    private readonly entityAttributeDeleteMutationApiInterfaceMutationService: EntityAttributeDeleteMutationApiInterfaceMutationService
  ) {}
  /**
   * Create a new attribute on an entity.
   * Attributes are created with no properties. So we create the attribute and then update it.
   * @param {EntityAttributeCreateInterface} attribute The attribute to create on an entity.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityAttributeInterface>} The newly created entity attribute.
   * @memberof EntityAttributeService
   */
  create(
    attribute: EntityAttributeCreateInterface,
    requestId: string | null = null
  ): Observable<EntityAttributeInterface> {
    return this.entityAttributeCreateMutationApiInterfaceMutationService
      .mutate({ input: attribute }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeCreate
            ? of(makeEntityAttribute(null, null, data.entityAttributeCreate.createdId, attribute.entityId, null))
            : throwError(() => 'No created attribute response data from create attribute on entity returned from API')
        )
      );
  }

  /**
   * Update an attribute on an entity.
   * @param {EntityAttributeInterface} attribute The attribute to update.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityAttributeUpdatedInterface>} ID of the updated attribute.
   * @memberof EntityAttributeService
   */
  update(
    attribute: OptionalExcluding<EntityAttributeIntersectionInterface, 'attributeId' | 'entityId'>,
    requestId: string | null = null
  ): Observable<EntityAttributeUpdatedInterface | EntityCalculationAttributeUpdatedInterface> {
    return this.entityAttributeUpdateMutationApiInterfaceMutationService
      .mutate(
        {
          input: attribute,
        },
        makeContextWithRequestIdHeader(requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeUpdate
            ? of(data.entityAttributeUpdate.attribute)
            : throwError(() => 'No updated attribute response data from update attribute on entity returned from API')
        )
      );
  }

  /**
   * Delete an attribute from an entity.
   * @param {EntityAttributeInterface} attribute The attribute to delete from the entity.
   * @return {Observable<EntityAttributeUpdatedInterface>} Generic delete API response with delete ID.
   * @memberof EntityAttributeService
   */
  delete(attribute: EntityAttributeInterface): Observable<EntityAttributeUpdatedInterface> {
    const payload: EntityAttributeDeleteInputApiInterface = {
      attributeId: attribute.attributeId,
      entityId: attribute.entityId,
    };

    return this.entityAttributeDeleteMutationApiInterfaceMutationService
      .mutate({ input: payload })
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeDelete
            ? of({ attributeId: data.entityAttributeDelete.deletedId })
            : throwError(() => 'No response data from delete attribute on entity returned from API')
        )
      );
  }
}
