import { FontStyleTypes, FontTypes, FontWeightTypes, LoadFontInterface } from '@surecloud/common';

const name: FontTypes = 'Nunito Sans';
const url = '/assets/fonts/nunito-sans/full/';
const fonts: [string, FontStyleTypes, FontWeightTypes][] = [
  ['nunito-sans-regular.full.woff2', 'normal', '400'],
  ['nunito-sans-semibold.full.woff2', 'normal', '600'],
  ['nunito-sans-bold.full.woff2', 'normal', '800'],
  ['nunito-sans-extrabold.full.woff2', 'normal', '900'],
  ['nunito-sans-italic.full.woff2', 'italic', '400'],
  ['nunito-sans-semibold-italic.full.woff2', 'italic', '600'],
  ['nunito-sans-bold-italic.full.woff2', 'italic', '800'],
  ['nunito-sans-extrabold-italic.full.woff2', 'italic', '900'],
];

/**
 * Array of fonts to load for the application
 */
export const coreApplicationFonts: LoadFontInterface[] = fonts.map(
  (font): LoadFontInterface => ({
    name,
    url: `${url}${font[0]}`,
    options: {
      style: font[1],
      weight: font[2],
    },
  })
);
