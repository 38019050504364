import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ScTagSize } from '../tag/tag.constants';

@Component({
  standalone: true,
  selector: 'sc-user-avatar',
  template: `<span [class]="classes" [style.background-color]="colourLightened" [style.border-color]="hexColour">{{
    initials
  }}</span>`,
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnChanges {
  /**
   * Sets the size of the user avatar.
   *
   * @type {ScTagSize}
   * @memberof UserAvatarComponent
   */
  @Input() size!: ScTagSize;

  /**
   * The colour value, should be a HEX code.
   *
   * @memberof UserAvatarComponent
   */
  @Input() hexColour = '';

  /**
   * The text string of two char initials to display
   * in the center of the user avatar circle.
   *
   * @memberof UserAvatarComponent
   */
  @Input() initials = '';

  /**
   * The lightened version of 'colour' value
   * to be used as the background colour.
   *
   * @memberof UserAvatarComponent
   */
  colourLightened = '';

  get classes(): string {
    return `user-avatar ${this.size}`;
  }

  /**
   * Initialise the component by setting the lightened version of the hexColour variable
   * for use as the background colour.
   *
   * @return {void}  {void}
   * @memberof UserAvatarComponent
   */
  ngOnChanges(): void {
    if (!this.hexColour) return;
    this.colourLightened = `${this.hexColour}20`;
  }
}
