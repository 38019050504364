/* eslint-disable max-lines-per-function */
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { VALIDATION_RENDERER_ALL_CONTROLS_CONFIG } from './validation-component-renderer-all-controls.config';
import { ValidationRendererAllComponentUnion } from './validation-component-renderer-component.interface';
import {
  ValidationRendererControlConfigUnion,
  ValidationRendererTypeEnum,
  ValidationRendererViewFormInterface,
} from './validation-component-renderer-config.interface';
import {
  isValidationRendereDateConfig,
  isValidationRendereDropdownConfig,
  isValidationRendererNumberAndDropdownConfig,
  isValidationRendererNumberConfig,
} from './validation-component-renderer.guards';

@Injectable({
  providedIn: 'root',
})
export class ValidationComponentRendererService {
  /**
   * Help the view create the component.
   *
   * @param {ViewContainerRef} viewContainerRef - The viewContainerRef to create a component within.
   * @param {ValidationRendererControlConfigUnion | undefined} componentData - Component configuration from the view.
   * @param {ValidationRendererViewFormInterface | undefined} control - The control created in the view.
   * @param {boolean} readonly - whether the component should render in readonly mode.
   * @return {ComponentRef<ValidationRendererAllComponentUnion> | undefined} - component reference to send back to the view.
   */
  // eslint-disable-next-line class-methods-use-this
  public createComponent(
    viewContainerRef: ViewContainerRef,
    componentData?: ValidationRendererControlConfigUnion,
    control?: ValidationRendererViewFormInterface,
    readonly = false
  ): ComponentRef<ValidationRendererAllComponentUnion> | undefined {
    if (isValidationRendererNumberConfig(componentData)) {
      const { creatorFn, component } = VALIDATION_RENDERER_ALL_CONTROLS_CONFIG[ValidationRendererTypeEnum.Number];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }
    if (isValidationRendereDateConfig(componentData)) {
      const { creatorFn, component } = VALIDATION_RENDERER_ALL_CONTROLS_CONFIG[ValidationRendererTypeEnum.Date];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    if (isValidationRendererNumberAndDropdownConfig(componentData)) {
      const { creatorFn, component } =
        VALIDATION_RENDERER_ALL_CONTROLS_CONFIG[ValidationRendererTypeEnum.NumberAndDropdown];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    if (isValidationRendereDropdownConfig(componentData)) {
      const { creatorFn, component } = VALIDATION_RENDERER_ALL_CONTROLS_CONFIG[ValidationRendererTypeEnum.Dropdown];
      return creatorFn(viewContainerRef, componentData, component, control, readonly);
    }

    return undefined;
  }
}

