import { createFeatureSelector, createSelector } from '@ngrx/store';
import { formatAdapter, FORMAT_FEATURE_KEY, StateInterface } from '../../reducers/format/format.reducer';

import * as AttributeSelectors from '../attribute/attribute.selectors';

// Lookup the 'Entity Attribute Format' feature state managed by NgRx
export const getFormatState = createFeatureSelector<StateInterface>(FORMAT_FEATURE_KEY);

const { selectAll, selectEntities } = formatAdapter.getSelectors();

/**
 * Get all formats.
 */
export const getFormatList = createSelector(getFormatState, (state: StateInterface) => selectAll(state));

/**
 * Get the selected format ID.
 */
export const getSelectedId = createSelector(getFormatState, (state: StateInterface) => state.selectedId);

/**
 * Get formats for the selected attribute.
 */
export const getSelectedAttributeFormats = createSelector(
  getFormatList,
  AttributeSelectors.getSelectedId,
  (formats, selectedAttributeId) => formats.filter((format) => format.attributeId === selectedAttributeId)
);

/**
 * Get the format entity map from format state.
 */
export const getFormatEntities = createSelector(getFormatState, (state: StateInterface) => selectEntities(state));

/**
 * Get the selected format data.
 */
export const getSelectedFormat = createSelector(getFormatEntities, getSelectedId, (formats, selectedId) =>
  selectedId ? formats[selectedId] : undefined
);

/**
 * Get the format options.
 */
export const getFormatOptions = createSelector(getFormatState, (state: StateInterface) => state.options);
