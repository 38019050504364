import { DocumentUploadStatusEnum } from '../models/record/document/document.model.enum';

/**
 * Type Guard for if is a DocumentUploadStatusEnum
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isDocumentUploadStatusEnum = (value: unknown): value is DocumentUploadStatusEnum =>
  (value as DocumentUploadStatusEnum) &&
  Object.values(DocumentUploadStatusEnum).includes(value as DocumentUploadStatusEnum);
