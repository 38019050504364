import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, TextAreaModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';

/**
 * Surecloud input textarea Component that wraps the TextareaBoxComponent with a optional label.
 *
 * @export
 * @class InputTextareaComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-input-textarea',
  standalone: true,
  imports: [
    LabelModule,
    TextAreaModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    E2eHookDirective,
    CommonIconModule,
  ],
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputTextareaComponent implements OnInit {
  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof InputTextareaComponent
   */
  @Input() controlName = '';

  /**
   * Textarea to display an additional description to the user.
   *
   * @memberof InputTextareaComponent
   */
  @Input() description = '';

  /**
   * Sets the textarea value for the label.
   *
   * @memberof InputTextareaComponent
   */
  @Input() label = '';

  /**
   * Sets the placholder value for the input.
   *
   * @memberof InputTextareaComponent
   */
  @Input() placeholder = '';

  /**
   *  Sets if we want a success validation style to display.
   *
   * @memberof InputTextareaComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Sets the readonly value for the input.
   *
   * @memberof InputTextareaComponent
   */
  @Input() readonly = false;

  /**
   * Sets a px width for the input. If this is not set the width will be set to 100% by default from styles.
   *
   * @memberof InputTextareaComponent
   */
  @Input()
  staticWidth!: string;

  /**
   * The test hook to pass in.
   *
   * @memberof InputTextareaComponent
   */
  @Input()
  testhook!: string;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   *
   * @memberof InputTextareaComponent
   */
  @Input()
  marginBottom = false;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   *
   * @type {FormGroup}
   * @memberof InputTextareaComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Emits when the input textarea component has been blurred.
   *
   * @type {EventEmitter<void>}
   * @memberof InputTextareaComponent
   */
  @Output()
  blurred: EventEmitter<void> = new EventEmitter();

  /**
   * Creates an instance of InputTextareaComponent.
   *
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof InputTextareaComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
