<div class="card p-fluid sc-mt-2">
  <p-chips
    field="label"
    styleClass="grid-tags-renderer"
    [allowDuplicate]="false"
    [ngModel]="values"
    (keypress)="keyPress($event)"
    (onAdd)="valueAdded($event)"
    (onRemove)="valueRemoved($event)">
  </p-chips>
</div>
