import { MutationOptions } from '@apollo/client';
import {
  EntityAttributeRuleCreateInputApiInterface,
  EntityAttributeRuleDeleteInputApiInterface,
} from '@surecloud/api-types';
import { makeContextWithRequestIdHeader } from '@surecloud/common';
import { gql } from 'apollo-angular';
import { EntityAttributeRuleUpdateInputApiInterface } from '../../services/rule/rule.service.input.interface';

/**
 * Create entity attribute validation rule GraphQL mutation
 */
const ENTITY_ATTRIBUTE_RULE_CREATE_MUTATION = gql`
  mutation entityAttributeRuleCreateMutation($input: EntityAttributeRuleCreateInput!) {
    entityAttributeRuleCreate(input: $input) {
      createdId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for an creating an entity's attribute validatio rule.
 * @param {EntityAttributeRuleCreateInputApiInterface} input Details about the validation rule to create.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityAttributeRuleCreateMutation = (
  input: EntityAttributeRuleCreateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_RULE_CREATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Update entity attribute validation rule GraphQL mutation
 */
const ENTITY_ATTRIBUTE_RULE_UPDATE_MUTATION = gql`
  mutation entityAttributeRuleUpdateMutation($input: EntityAttributeRuleUpdateInput!) {
    entityAttributeRuleUpdate(input: $input) {
      rule {
        ruleId
      }
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for an updating an entity's attribute validation rule.
 * @param {EntityAttributeRuleUpdateInputApiInterface} input Details about the validation rule to update.
 * @param {string | null} requestId The requestId.
 * @return {MutationOptions} The GraphQL mutation options
 */
export const apolloEntityAttributeRuleUpdateMutation = (
  input: EntityAttributeRuleUpdateInputApiInterface,
  requestId: string | null
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_RULE_UPDATE_MUTATION,
  variables: { input },
  ...makeContextWithRequestIdHeader(requestId),
});

/**
 * Delete entity attribute validation rule GraphQL mutation
 */
const ENTITY_ATTRIBUTE_RULE_DELETE_MUTATION = gql`
  mutation entityAttributeRuleDeleteMutation($input: EntityAttributeRuleDeleteInput!) {
    entityAttributeRuleDelete(input: $input) {
      deletedId
    }
  }
`;

/**
 * Function to create the GraphQL mutation options for deleting and entity's attribute validation rule.
 * @param {EntityAttributeRuleDeleteInputApiInterface} input Details about the rule to delete.
 * @return {MutationOptions} The GraphQL mutation options.
 */
export const apolloEntityAttributeRuleDeleteMutation = (
  input: EntityAttributeRuleDeleteInputApiInterface
): MutationOptions => ({
  mutation: ENTITY_ATTRIBUTE_RULE_DELETE_MUTATION,
  variables: { input },
});
