import { GridDocumentInterface } from './grid-document.interface';

/**
 * Type Guard for GridDocument
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isGridDocumentGuard = (value: unknown): value is GridDocumentInterface => (value as GridDocumentInterface).value !== undefined;

/**
 * Type Guard for GridDocument Arrays
 * @param {unknown} value - The value to check.
 * @return {boolean} - true or false
 */
export const isArrayOfGridDocumentsGuard = (value: unknown): value is Array<GridDocumentInterface> =>
  Array.isArray(value) && value.every((gridDocument) => isGridDocumentGuard(gridDocument));
