import { Injectable } from '@angular/core';
import { EntityAttributeApiInterface, RecordResultApiInterface } from '@surecloud/api-types';
import {
  EntityAttributeTypeEnum,
  isEntityAttributeType,
} from '../../interfaces/entity-attribute-type/entity-attribute-type.enum';
import {
  CustomRecordResultInterface,
  RecordColumnAttributeInterface,
  RecordGridInputInterface,
  shallowCheckFirstIsRecord,
} from '../../interfaces/record-result.interface';
import { EntityInterface } from '../../models/entity/entity.models';
import { LoggerService } from '../logger/logger.service';

/**
 * This service will help with processing a RecordResult.
 * Must be provided in the feature module it's used in.
 * @export
 * @class RecordResultHelper
 */
@Injectable({
  providedIn: 'root',
})
export class RecordResultHelper {
  constructor(private logger: LoggerService) {}
  /**
   * Convert the Report Chart Records API data structure into what we need on the UI.
   * @param {RecordResultApiInterface} apiRecordResult The API Report Chart Record data structure.
   * @return {RecordGridInputInterface} The UI Report Chart Record data structure.
   * @memberof RecordResultHelper
   */
  convertRawRecordResultToRecordGridInput(apiRecordResult: RecordResultApiInterface | null): RecordGridInputInterface {
    let recordResult: RecordGridInputInterface = {
      columnAttributes: [],
      records: [],
      viewerCanCreate: false,
      totalItems: 0,
    };
    if (!apiRecordResult) return recordResult;
    const { records, columnAttributes, viewerCanCreate, totalItems } = apiRecordResult;

    recordResult.columnAttributes = this.convertEntityAttributesToRecordColumnAttributes(columnAttributes);
    recordResult.totalItems = totalItems || 0;
    recordResult.viewerCanCreate = viewerCanCreate;

    if (shallowCheckFirstIsRecord(records)) {
      recordResult = { ...recordResult, records };
    } else if (records.length > 0) {
      this.logger.logEvent(
        'RecordResultHelper',
        'Problem found with the structure of the first record in this collection.',
        records[0],
        'Verify the structure of the records passes the shallowCheckFirstIsRecord check.'
      );
    }
    return recordResult;
  }

  /**
   * Convert a collection of Entity Attributes to Record Column Attributes for use in a record grid.
   * @param {EntityAttributeApiInterface[]} columnAttributes the api column attributes.
   * @return {RecordColumnAttributeInterface[]} the returned UI column attributes.
   * @memberof RecordResultHelper
   */
  convertEntityAttributesToRecordColumnAttributes(
    columnAttributes: EntityAttributeApiInterface[]
  ): RecordColumnAttributeInterface[] {
    return columnAttributes.reduce<RecordColumnAttributeInterface[]>((colAttributes, attribute) => {
      const { type, name, attributeId, canBeMultiple } = attribute;

      if (isEntityAttributeType(type)) {
        colAttributes.push({
          name: name || '',
          attributeId,
          type: type === EntityAttributeTypeEnum.Owner ? EntityAttributeTypeEnum.User : type,
          canBeMultiple: canBeMultiple || false,
        });
      } else {
        this.logger.logEvent(
          'RecordResultHelper',
          'Problem converting raw RecordResult to RecordGridInput.',
          attribute,
          'Attribute type not found for record column. So the column was not rendered.'
        );
      }
      return colAttributes;
    }, []);
  }

  /**
   * Format the record result data before sending back.
   * @param {RecordResultApiInterface} recordResult The record result input
   * @return {CustomRecordResultInterface} The format we want.
   * @memberof RecordResultHelper
   */
  formatRecordResult(recordResult: RecordResultApiInterface): CustomRecordResultInterface {
    const { entityName, entityDescription, entityId: returnedEntityId } = recordResult;
    const entitySummary: EntityInterface = {
      name: entityName || '',
      description: entityDescription,
      entityId: returnedEntityId || 'id-not-returned-from-record-result',
      created: '',
    };
    return {
      entitySummary,
      recordGridInput: this.convertRawRecordResultToRecordGridInput(recordResult),
    };
  }
}
