import { Injectable } from '@angular/core';
import { ModalComponentServiceOptionsInterface } from './modal.component.service.interface';
import { ModalService } from './modal.service';
import { ModalInterface } from './modal.service.interface';

/**
 * Service to open a modal with a component.
 * Reference to the instance of the component is available on this service.
 *
 * @export
 * @class ModalComponentService
 * @template T The type of component this service will open in the modal.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalComponentService<T> {
  /**
   * Reference to the current opened component modal and additional ModalInterface options.
   *
   * @memberof ModalComponentService
   */
  public reference: ModalInterface<T> | null = null;

  /**
   * Creates an instance of ModalComponentService.
   *
   * @param {ModalService} modal The common modal service.
   * @memberof ModalComponentService
   */
  constructor(private readonly modal: ModalService) {}

  /**
   * Open the modal with a component passed in as an option.
   *
   * @param {ModalComponentServiceOptionsInterface} options Open with modal with the options provided.
   * @memberof ModalComponentService
   */
  open(options: ModalComponentServiceOptionsInterface<T>): void {
    this.reference = this.modal.open<T>({
      content: options.component,
      height: options.height ?? 637,
      title: options.title,
      width: options.width ?? 850,
    });
  }
}
