import { z } from 'zod';

/**
 * Normalise Get Question Sets Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetQuestionSetService.
 */

/**
 * Question Set Zod schema.
 */
export const questionSetZodSchema = z.object({
  attributeId: z.string().nullable(),
  entityId: z.string(),
  name: z.string().nullable(),
  questionSetId: z.string(),
  reviewerRoleIds: z.array(z.string()),
});

/**
 * Normalised Question Set list Zod schema.
 */
export const normalisedQuestionSetListZodSchema = z.object({ questionSets: z.array(questionSetZodSchema) });

/**
 * Normalised Question Set list data interface.
 */
export type NormalisedQuestionSetList = z.infer<typeof normalisedQuestionSetListZodSchema>;

/**
 * Guard to check the normalised Question Set list data matches the schema.
 *
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedQuestionSetList.
 */
export const isNormalisedQuestionSetListGuard = (map: unknown): map is NormalisedQuestionSetList =>
  normalisedQuestionSetListZodSchema.safeParse(map).success;

/**
 * When the normalised Question Set list data does not match the schema
 * then get the error as to show where the problem is.
 *
 * @param {unknown} map Map of the data to check.
 * @return {z.SafeParseReturnType<unknown, NormalisedQuestionSetList>} The error on where the map of the data does not match a NormalisedQuestionSetList.
 */
export const getNormalisedQuestionSetListError = (
  map: unknown
): z.SafeParseReturnType<unknown, NormalisedQuestionSetList> => normalisedQuestionSetListZodSchema.safeParse(map);
