/**
 * Permission type that maps to the array of permissions on a User Profile.
 * @export
 * @enum {number}
 */
export enum UserProfilePermissionTypesEnum {
  Create = 'createPermissions',
  Delete = 'deletePermissions',
  Read = 'readPermissions',
  Update = 'updatePermissions',
}
