import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  E2eHookDirective,
  EntityAttributeRuleConditionTypeEnum,
  EntityAttributeRuleValueTypeEnum,
  HierarchyNodeInterface,
  SelectDropdownComponentOptionInterface,
  ViewContainerDirective,
} from '@surecloud/common';
import { Subject, merge, takeUntil } from 'rxjs';
import { DropdownHierarchyComponent } from '../../dropdown-hierarchy/dropdown-hierarchy.component';
import { DropDownHierarchyInterface } from '../../dropdown-hierarchy/dropdown-hierarchy.interface';
import { SelectDropdownComponent } from '../../select-dropdown/select-dropdown.component';
import { ValidationRendererAllComponentUnion } from '../validation-component-renderer/validation-component-renderer-component.interface';
import { ValidationRendererControlConfigUnion } from '../validation-component-renderer/validation-component-renderer-config.interface';
import {
  isValidationRendereDropdownConfig,
  isValidationRendererDateControlComponentRef,
  isValidationRendererFormControlComponentRef,
  isValidationRendererNumberAndDropdownControlComponentRef,
} from '../validation-component-renderer/validation-component-renderer.guards';
import { ValidationComponentRendererService } from '../validation-component-renderer/validation-component-renderer.service';
import {
  conditionToFormNameMap,
  conditionToValueTypeOptionsMap,
  makeDropdownConditionOptions,
} from '../validation-conditions.config';
import { EntityAttributeTypeAndOptionsInterface } from '../validation-conditions.interface';

/**
 * Component that renderer the editing of a condition
 * @export
 * @class ConfigureConditionComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'sc-configure-condition',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    E2eHookDirective,
    SelectDropdownComponent,
    ViewContainerDirective,
    DropdownHierarchyComponent,
  ],
  templateUrl: './configure-condition.component.html',
})
export class ConfigureConditionComponent implements OnInit, OnDestroy {
  /**
   * Sets the type of the rule and the condition options to exclude
   * @memberof ConfigureConditionComponent
   */
  @Input() set typeAndExcludeOptions(typeAndExcludeOptions: EntityAttributeTypeAndOptionsInterface) {
    this.conditionOptions = makeDropdownConditionOptions(typeAndExcludeOptions);
  }

  /**
   * Sets the value of the condition
   * @memberof ConfigureConditionComponent
   */
  @Input() conditionValue?: EntityAttributeRuleConditionTypeEnum;

  /**
   * Sets the value type value of the condition
   * @memberof ConfigureConditionComponent
   */
  @Input() valueTypeValue?: EntityAttributeRuleValueTypeEnum;

  /**
   * Sets the value for the number input form
   * @memberof ConfigureConditionComponent
   */
  @Input() numberValue?: number;

  /**
   * Sets the value for the date input form
   * @memberof ConfigureConditionComponent
   */
  @Input() dateValue?: string;

  /**
   * Sets the value for the dropdown input form
   * @memberof ConfigureConditionComponent
   */
  @Input() dropdownValue?: string;

  /**
   * Sets the value for the dropdown period input form
   * @memberof ConfigureConditionComponent
   */
  @Input() dropdownPeriodOperatorValue?: string;

  /**
   * Sets the values for the dropdown input form
   * @memberof ConfigureConditionComponent
   */
  @Input() options?: SelectDropdownComponentOptionInterface[];

  /**
   * Hierarchy Dropdown current node
   * @type {HierarchyNodeInterface}
   * @memberof ConfigureConditionComponent
   */
  @Input() currentNode!: HierarchyNodeInterface;

  /**
   * Hierarchy Dropdown top level node
   * @type {HierarchyNodeInterface}
   * @memberof ConfigureConditionComponent
   */
  @Input() topLevelNode!: HierarchyNodeInterface;

  /**
   * The starting default value if there is one for the Hierarchy Dropdown.
   * @type {DropDownHierarchyInterface}
   * @memberof ConfigureConditionComponent
   */
  @Input() defaultValue?: DropDownHierarchyInterface;

  /**
   * The event triggered when we want to get and set a new current node for the Hierarch Dropdown.
   * @memberof DropdownHierarchyComponent
   */
  @Output() requestNewCurrentNode = new EventEmitter<string>();

  /**
   * Emit an event when the popup hierarch dropdown menu is destroyed so the toplevel data can be reset.
   * @memberof ConfigureConditionComponent
   */
  @Output() dropdownMenuDestroyed = new EventEmitter();

  /**
   * Emit an event when the popup hierarch dropdown menu is destroyed so the toplevel data can be reset.
   * @memberof ConfigureConditionComponent
   */
  @Output() formValues = new EventEmitter<
    FormGroup<{
      condition: FormControl<EntityAttributeRuleConditionTypeEnum | null>;
      valueType: FormControl<EntityAttributeRuleValueTypeEnum | null>;
      number: FormControl<number | null>;
      dropdown: FormControl<string | null>;
      dropdownHierarchy: FormControl<DropDownHierarchyInterface | null>;
      date: FormControl<string | null>;
      dropdownPeriodOperator: FormControl<string | null>;
    }>
  >();

  /**
   * View Container Directive that will provide the host element to insert the dynamically created component into.
   * @type {ViewContainerDirective}
   * @memberof ConfigureConditionComponent
   */
  @ViewChild(ViewContainerDirective, { static: true }) view!: ViewContainerDirective;

  /**
   * When the component is destroyed.
   * Then emit so that other observables may tear down.
   * @type {Subject<void>}
   * @memberof ConfigureConditionComponent
   */
  destroyed$: Subject<void> = new Subject();

  /**
   * Reference to the component that is created so that we can destroy it at will.
   * @type {(ComponentRef<ValidationRendererAllComponentUnion> | undefined)}
   * @memberof ConfigureConditionComponent
   */
  componentRef: ComponentRef<ValidationRendererAllComponentUnion> | undefined;

  /**
   * reference the config name for the configure condition component
   * @memberof ConfigureConditionComponent
   */
  configName = '';

  /**
   * The create/edit configure condition form group.
   * @type {FormGroup}
   * @memberof ConfigureConditionComponent
   */
  form: FormGroup<{
    condition: FormControl<EntityAttributeRuleConditionTypeEnum | null>;
    valueType: FormControl<EntityAttributeRuleValueTypeEnum | null>;
    number: FormControl<number | null>;
    dropdown: FormControl<string | null>;
    dropdownHierarchy: FormControl<DropDownHierarchyInterface | null>;
    date: FormControl<string | null>;
    dropdownPeriodOperator: FormControl<string | null>;
  }> = this.formBuilder.group({
    condition: new FormControl<EntityAttributeRuleConditionTypeEnum | null>(null, {
      nonNullable: false,
    }),
    valueType: new FormControl<EntityAttributeRuleValueTypeEnum | null>(null, {
      nonNullable: false,
    }),
    number: new FormControl<number | null>(0, {
      nonNullable: false,
    }),
    dropdown: new FormControl<string | null>(null, {
      nonNullable: false,
    }),
    dropdownPeriodOperator: new FormControl<string | null>(null, {
      nonNullable: false,
    }),
    dropdownHierarchy: new FormControl<DropDownHierarchyInterface | null>(null, {
      nonNullable: false,
    }),
    date: new FormControl<string | null>(null, {
      nonNullable: false,
    }),
  });

  /**
   * Conditions dropdown options
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof ConfigureConditionComponent
   */
  conditionOptions: SelectDropdownComponentOptionInterface[] = [];

  /**
   * Attribute dropdown options
   * @type {SelectDropdownComponentOptionInterface[]}
   * @memberof ConfigureConditionComponent
   */
  attributeDropdownOptions: SelectDropdownComponentOptionInterface[] = [];

  /**
   * Configure condition value type options
   * @type {Record<string, SelectDropdownComponentOptionInterface[]>}
   * @memberof ConfigureConditionComponent
   */
  valueTypeOptions: Record<string, SelectDropdownComponentOptionInterface[]> = conditionToValueTypeOptionsMap;

  /**
   * Condition to form map
   * @type {Record<string, ValidationRendererControlConfigUnion | string | null>}
   * @memberof ConfigureConditionComponent
   */
  conditionToFormNameMap: Record<string, ValidationRendererControlConfigUnion | string | null> = conditionToFormNameMap;

  /**
   * Dropdown Hierarchy Date Attribute Description
   * @memberof ConfigureConditionComponent
   */
  dateDescription = $localize`Select a date attribute`;

  /**
   * Dropdown Hierarchy Number Attribute Description
   * @memberof ConfigureConditionComponent
   */
  numberDescription = $localize`Select a number attribute`;

  /**
   * Creates an instance of ConfigureConditionComponent .
   * @param {FormBuilder} formBuilder  The Angular form builder.
   * @param {ValidationComponentRendererService} componentCreator The component creator service.
   * @memberof ConfigureConditionComponent
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private componentCreator: ValidationComponentRendererService
  ) {}

  /**
   * When the component is initialised.
   * Then setup form control
   * @memberof ConfigureConditionComponent
   */
  ngOnInit(): void {
    this.setFormValues();

    merge(this.form.controls.condition.valueChanges, this.form.controls.valueType.valueChanges)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.destroyComponent();
        this.renderComponent();
      });

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.formValues.emit(this.form);
    });
  }

  /**
   * When the component is destroyed.
   * Then emit so other observables can tear down.
   * @memberof ConfigureConditionComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Destroy a Dynamic Created Component.
   * @private
   * @memberof ConfigureConditionComponent
   */
  private destroyComponent(): void {
    if (!this.componentRef) return;
    if (isValidationRendererNumberAndDropdownControlComponentRef(this.componentRef)) {
      this.form.controls.number.patchValue(0, { emitEvent: false });
      this.form.controls.dropdown.patchValue('', { emitEvent: false });
      this.form.controls.dropdownPeriodOperator.patchValue('', { emitEvent: false });
    } else if (isValidationRendererFormControlComponentRef(this.componentRef)) {
      this.form.controls.number.patchValue(0, { emitEvent: false });
    } else if (isValidationRendererDateControlComponentRef(this.componentRef)) {
      this.form.controls.date.patchValue(null, { emitEvent: false });
    }
    this.componentRef.destroy();
  }

  /**
   * Return a dynamic created component
   * @private
   * @return {void}  {void}
   * @memberof ConfigureConditionComponent
   */
  private renderComponent(): void {
    this.configName = `${this.form.controls.condition.value ?? ''}.${this.form.controls.valueType.value ?? ''}`;

    const config = conditionToFormNameMap[this.configName];

    if (!config || typeof config === 'string') {
      return;
    }

    if (isValidationRendereDropdownConfig(config) && this.options) {
      config.options = this.options;
    }

    this.componentRef = this.componentCreator.createComponent(this.view.viewContainerRef, config, {
      controlId: config.type.toLowerCase(),
      group: this.form,
    });
  }

  /**
   * Set's the form values
   * @private
   * @memberof ConfigureConditionComponent
   */
  private setFormValues(): void {
    if (this.conditionValue) {
      this.form.controls.condition.patchValue(this.conditionValue, { emitEvent: false });
    }
    if (this.valueTypeValue) {
      this.form.controls.valueType.patchValue(this.valueTypeValue, { emitEvent: false });
    }
    if (this.numberValue) {
      this.form.controls.number.patchValue(this.numberValue, { emitEvent: false });
    }
    if (this.dropdownValue) {
      this.form.controls.dropdown.patchValue(this.dropdownValue, { emitEvent: false });
    }
    if (this.dropdownPeriodOperatorValue) {
      this.form.controls.dropdownPeriodOperator.patchValue(this.dropdownPeriodOperatorValue, { emitEvent: false });
    }
    if (this.dateValue) {
      this.form.controls.date.patchValue(this.dateValue, { emitEvent: false });
    }
    if (this.defaultValue) {
      this.form.controls.dropdownHierarchy.patchValue(this.defaultValue, { emitEvent: false });
    }

    this.renderComponent();
  }
}
