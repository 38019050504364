import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import {
  CellClickedEvent,
  CellEditRequestEvent,
  CellValueChangedEvent,
  ColDef,
  ColTypeDef,
  ExcelStyle,
  GridReadyEvent,
  GridSizeChangedEvent,
  ProcessRowParams,
  RowDragEndEvent,
  RowDragMoveEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { TextButtonComponent } from '../../button/text-button.component';
import { CommonIconModule } from '../../icon/icons/common-icon.module';
import { DEFAULT_COLUMN_DEF } from '../default-col-def';
import { globalColumnTypes } from '../global-column-types';
import { GridNavigationCellComponent } from '../navigation-cell/grid-navigation-cell.component';
import { GridAttributeTypeRendererComponent } from '../renderers/attribute-type/grid-attribute-type-renderer.component';
import { GridCheckboxRendererComponent } from '../renderers/checkbox/grid-checkbox-renderer.component';

/**
 * Surecloud basic grid wrapper around ag-grid.
 */
@Component({
  selector: 'sc-basic-grid',
  templateUrl: './basic-grid.component.html',
  styleUrls: ['./basic-grid.component.scss'],
  standalone: true,
  imports: [
    AgGridModule,
    GridCheckboxRendererComponent,
    TextButtonComponent,
    CommonModule,
    GridNavigationCellComponent,
    GridAttributeTypeRendererComponent,
    CommonIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BasicGridComponent implements OnInit {
  /**
   *The data source
   * @type {unknown[]}
   * @memberof BasicGridComponent
   */
  @Input() rowData: unknown[] = [];

  /**
   * The height of the table.
   * @memberof BasicGridComponent
   */
  @Input() height = 400;

  /**
   * Set this to true when we want to display a footer (Advanced Grid).
   * Applies a class that helps style the grid correctly to line up with a footer.
   * @memberof BasicGridComponent
   */
  @Input() footer = false;

  /**
   *Array of columns to display and their definitions
   * @type {ColDef[]}
   * @memberof BasicGridComponent
   */
  @Input() columnDefs: ColDef[] | undefined = [];

  /**
   * Should the grid NOT scroll to the top when new data has been input.
   * @memberof BasicGridComponent
   */
  @Input()
  suppressScrollOnNewData = true;

  /**
   * filter string to use to restrict data
   * @type {string|null}
   * @memberof BasicGridComponent
   */
  @Input() quickFilterText = '';

  /**
   *The type of row selection, singular or multiple
   * @type {('single' | 'multiple' | undefined)}
   * @memberof BasicGridComponent
   */
  @Input() rowSelection: 'single' | 'multiple' | undefined = 'single';

  /**
   *An object map of custom column types which contain groups of properties that column definitions can inherit by referencing in their type property.
   * @type {Record<string, ColDef>} Custom column type as confifured in globalColumnTypes
   * @memberof BasicGridComponent
   */
  @Input() columnTypes: Record<string, ColTypeDef> = globalColumnTypes;

  /**
   *Theme to be applied
   * @memberof BasicGridComponent
   */
  @Input() theme = 'ag-theme-alpine';

  /**
   * Set this if we want to display the grid but remove functionality so it can be previewed.
   * @memberof BasicGridComponent
   */
  @Input() readonly = false;

  /**
   * Enables row drag and drop functionality
   * @memberof BasicGridComponent
   */
  @Input() rowDrag = false;

  /**
   * Provide a parent context to the grid.
   * @type {{ componentParent: unknown }}
   * @memberof BasicGridComponent
   */
  @Input() context: { componentParent: unknown } = { componentParent: null };

  /**
   * Determines should the loading state be shown.
   * @type {{ componentParent: unknown }}
   * @memberof BasicGridComponent
   */
  @Input() showLoadingState = false;

  /**
   * Table rows length.
   * @type {{ componentParent: unknown }}
   * @memberof BasicGridComponent
   */
  @Input() rowDataLength = 0;

  /**
   * Are the table rows loaded.
   * @type {{ componentParent: unknown }}
   * @memberof BasicGridComponent
   */
  @Input() isLoaded = false;

  /**
   * Should the pagination be enabled.
   * @type {boolean}
   * @memberof BasicGridComponent
   */
  @Input() isPaginationEnabled = false;

  /**
   * The message to display when there are no results.
   * @memberof BasicGridComponent
   */
  @Input() noResultsMessage = $localize`Use the button below to add to this table`;

  /**
   * The default excel export params.
   * @memberof BasicGridComponent
   */
  @Input() defaultExcelExportParams = {};

  /**
   * The excel styles to apply to the export.
   * @memberof BasicGridComponent
   */
  @Input() excelStyles: ExcelStyle[] = [];

  /**
   *Emits when the grid is ready
   * @memberof BasicGridComponent
   */
  @Output() gridReady = new EventEmitter<GridReadyEvent>();

  /**
   *Emits when a cell has been edited
   * @memberof BasicGridComponent
   */
  @Output() cellEdited = new EventEmitter<CellEditRequestEvent>();

  /**
   *Emits when a cell has been cliked
   * @memberof BasicGridComponent
   */
  @Output() cellClicked = new EventEmitter<CellClickedEvent>();

  /**
   *Emits when a cell has been cliked
   * @memberof BasicGridComponent
   */
  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();

  /**
   *Emits when a selection is changed
   * @memberof BasicGridComponent
   */
  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent>();

  /**
   * The mouse has moved while dragging.
   * @memberof BasicGridComponent
   */
  @Output() rowDragMove = new EventEmitter<RowDragMoveEvent>();

  /**
   * The drag has finished over the grid.
   * @memberof BasicGridComponent
   */
  @Output() rowDragEnd = new EventEmitter<RowDragEndEvent>();

  /**
   * Emits when the pagination has changed
   * @memberof BasicGridComponent
   */
  @Output() paginationChanged = new EventEmitter();

  /**
   * Emits when the filter has changed
   * @memberof BasicGridComponent
   */
  @Output() filterChanged = new EventEmitter();

  /**
   * Emits when the sorting has changed
   * @memberof BasicGridComponent
   */
  @Output() sortChanged = new EventEmitter();

  /**
   *Configuraiton for the deault column definitions
   * @memberof BasicGridComponent
   */
  defaultColumnDef = DEFAULT_COLUMN_DEF;

  /**
   *Configuraiton for the sizes that we pass into the view to set.
   * @memberof BasicGridComponent
   */
  sizeConfig = {
    headerHeight: 40,
    rowHeight: 40,
  };

  /**
   *Loading overlay template.
   * @memberof BasicGridComponent
   */
  loadingOverlayTemplate = '<span data-testid="group-rows-loading" i18n-data-testid>Loading...</span>';

  /**
   * Expose the resize function to the view template.
   * @memberof BasicGridComponent
   */
  resizeGrid = BasicGridComponent.resize;

  /**
   * When the width of the grid/table changes make it resize the columns to fit if they can, a horizontal scrollbar will show otherwise.
   * @param {GridSizeChangedEvent} event contains the size changes and the grid reference.
   * @memberof BasicGridComponent
   */
  static resize(event: GridSizeChangedEvent): void {
    event.api.sizeColumnsToFit();
  }

  /**
   * Disable lots of grid functionality for a read only view.
   * We also supress all keyboard inputs except for tabbing.
   * An overlay is also applied in the view to prevent clicks.
   * @private
   * @memberof BasicGridComponent
   */
  private setGridToReadOnly(): void {
    const newCols = this.columnDefs
      ? this.columnDefs.map((col) => ({
          ...col,
          editable: false,
          filter: false,
          suppressMovable: true,
          showDisabledCheckboxes: true,
          suppressKeyboardEvent: (params: { event: { key: string } }) => params.event.key !== 'Tab',
        }))
      : [];

    this.columnDefs = newCols;
  }

  /**
   * When the component is initialised.
   * If readonly is set then setup the grid for a readonly state.
   * @memberof BasicGridComponent
   */
  ngOnInit(): void {
    if (this.readonly) {
      this.setGridToReadOnly();
    }
  }

  /**
   *Fired When the grid is initialised
   * @param {GridReadyEvent} event contains the api, columnapi and type<gridReady>
   * @memberof BasicGridComponent
   */
  onGridReady(event: GridReadyEvent): void {
    event.api.sizeColumnsToFit();
    this.gridReady.emit(event);
    if (this.showLoadingState && !this.isLoaded) {
      event.api.showLoadingOverlay();
    }
  }

  /**
   *Called after a cells value has been changed.  This only fires when readOnlyEdit=true so we can handle the update in the application
   * @param {CellEditRequestEvent} event contains the old and new value with the column defs and all column info
   * @memberof BasicGridComponent
   */
  onCellEditRequest(event: CellEditRequestEvent): void {
    this.cellEdited.emit(event);
  }

  /**
   * Catching the event of the mouse has moved while dragging.
   * @param {RowDragMoveEvent} event - Row Drag Move event
   * @memberof BasicGridComponent
   */
  onRowDragMove(event: RowDragMoveEvent): void {
    this.rowDragMove.emit(event);
  }

  /**
   * Catching the event of the drag has finished over the grid.
   * @param {RowDragEndEvent} event - Row Drag End event
   * @memberof BasicGridComponent
   */
  onRowDragEnd(event: RowDragEndEvent): void {
    this.rowDragEnd.emit(event);
  }

  /**
   * Expose the processRowPostCreate function to the view template.
   * @memberof BasicGridComponent
   */
  processRowPostCreateGrid = BasicGridComponent.processRowPostCreate;

  /**
   * Add data-test attribute to each row.
   * @param {ProcessRowParams} processRowParams
   * @memberof BasicGridComponent
   */
  static processRowPostCreate(processRowParams: ProcessRowParams): void {
    const { node, eRow } = processRowParams;
    const rowIdKey: string | undefined = Object.keys(node.data).find((key: string) => key.toLowerCase().includes('id'));
    const rowId = rowIdKey && node.data[rowIdKey];

    if (rowId) {
      eRow.setAttribute('data-test', rowId);
    }
  }
}
