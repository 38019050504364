import {
  SetAttributeDateDirectionApiInterface,
  SetAttributeDateTypeApiInterface,
  SetAttributeDateUnitApiInterface,
} from '@surecloud/api-types';

/**
 * Base interface for the 'Rule Action' data.
 * @property {string} rootId The root ID that the rule action is attached to.
 * @property {string} parentId The parent ID of the rule action.
 * @property {string} actionId The ID of the rule action.
 * @property {boolean} canRecur If recurring can be set to true.
 * @property {boolean} recurring Is the rule action recurring.
 * @property {RuleActionRecurringTypeEnum | null} recurringType The type of recurring action.
 * @property {RuleActionRecurringDailyTypeEnum | null} recurringDailyType The type of recurring daily action.
 * @property {number | null} recurringDailyUnit The unit of recurring daily action.
 * @property {RuleActionRecurringLimitTypeEnum | null} recurringLimitType The type of recurring limit action.
 * @property {number | null} recurringLimitUnit The unit of recurring limit action.
 * @export
 * @interface BaseRuleActionInterface
 */
export type BaseRuleActionInterface = {
  rootId: string;
  parentId: string;
  actionId: string;
  canRecur: boolean;
  recurring: boolean;
  recurringType: RuleActionRecurringTypeEnum | null;
  recurringDailyType: RuleActionRecurringDailyTypeEnum | null;
  recurringDailyUnit: number | null;
  recurringLimitType: RuleActionRecurringLimitTypeEnum | null;
  recurringLimitUnit: number | null;
};

/**
 * Interface for the 'Rule Action' data.
 * @property {string} rootId The root ID that the rule action is attached to.
 * @property {string} parentId The parent ID of the rule action.
 * @property {string} actionId The ID of the rule action.
 * @property {boolean} recurring Is the rule action recurring.
 * @property {RuleActionRecurringTypeEnum | null} recurringType The type of recurring action.
 * @property {RuleActionRecurringDailyTypeEnum | null} recurringDailyType The type of recurring daily action.
 * @property {number | null} recurringDailyUnit The unit of recurring daily action.
 * @property {RuleActionRecurringLimitTypeEnum | null} recurringLimitType The type of recurring limit action.
 * @property {number | null} recurringLimitUnit The unit of recurring limit action.
 * @property {RuleActionTypeEnum | null} type Rule action type.
 * @property {string} destinationAttributeId The ID of destination attribute
 * @property {string} sourceAttributeId The ID of source attribute
 * @property {string} sourceEntityId The ID of source entity
 * @property {setAttributeDateUnit} setAttributeDateUnit set attribute date unit
 * @property {setAttributeDateDirection} setAttributeDateDirection set attribute date direction
 * @property {setAttributeDateAmount} setAttributeDateAmount set attribute date amount
 * @property {SetAttributeDateTypeApiInterface} setAttributeDateType set attribute date type
 * @export
 * @interface RuleActionInterface
 * @extends {BaseRuleActionInterface}
 */
export type RuleActionInterface = BaseRuleActionInterface &
  (
    | {
        type: null;
      }
    | {
        destinationAttributeId: string | null;
        sourceAttributeId: string | null;
        sourceEntityId: string | null;
        setAttributeDateUnit: SetAttributeDateUnitApiInterface | null;
        setAttributeDateDirection: SetAttributeDateDirectionApiInterface | null;
        setAttributeDateAmount: number | null;
        setAttributeDateType: SetAttributeDateTypeApiInterface | null;
        type: RuleActionTypeEnum.SetAttribute;
      }
    | {
        entityId: string | null;
        type: RuleActionTypeEnum.CreateRecord;
      }
    | {
        type: RuleActionTypeEnum.CreateTask;
        assigneeId: string | null;
        description: string | null;
        summary: string | null;
        dueType: RuleActionDueDateTypeEnum | null;
        dueAttributeId?: string | null;
        dueUnit?: number | null;
        dueMultiplier?: RuleActionDueDateMultiplierTypeEnum | null;
      }
    | {
        type: RuleActionTypeEnum.LockRecord;
        lock: boolean;
        roleIds: string[];
      }
    | {
        notificationId: string | null;
        recipientAttributeId: string | null;
        type: RuleActionTypeEnum.SendNotification;
      }
    | {
        notificationId: string | null;
        type: RuleActionTypeEnum.InviteGuestUser;
        recipientAttributeId: string | null;
        recipientEntityId: string | null;
        roleId: string | null;
      }
  );

/**
 * Rule Action type ENUM
 * @export
 * @enum {string}
 */
export enum RuleActionTypeEnum {
  CreateRecord = 'CREATE_RECORD',
  CreateTask = 'CREATE_TASK',
  LockRecord = 'LOCK_RECORD',
  SetAttribute = 'SET_ATTRIBUTE',
  SendNotification = 'SEND_NOTIFICATION',
  ExecuteIntegration = 'EXECUTE_INTEGRATION',
  InviteGuestUser = 'INVITE_GUEST',
}

/**
 * Rule Action due date multiplier type enum.
 * @export
 * @enum {number}
 */
export enum RuleActionDueDateMultiplierTypeEnum {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

/**
 * Rule Action due date type enum.
 * @export
 * @enum {number}
 */
export enum RuleActionDueDateTypeEnum {
  Attribute = 'DUE_ATTRIBUTE',
  Period = 'DUE_PERIOD',
}

/**
 * Rule Action recurring type enum
 * @export
 * @enum {number}
 */
export enum RuleActionRecurringTypeEnum {
  daily = 'DAILY',
}

/**
 * Rule Action recurring daily type enum
 * @export
 * @enum {number}
 */
export enum RuleActionRecurringDailyTypeEnum {
  EveryXDays = 'EVERY_X_DAYS',
  EveryWeekday = 'EVERY_WEEKDAY',
}

/**
 * Rule Action recurring limit type enum
 * @export
 * @enum {number}
 */
export enum RuleActionRecurringLimitTypeEnum {
  OnTransition = 'ON_TRANSITION',
  AfterXOccurrences = 'AFTER_X_OCCURRENCES',
}

/**
 * Create Task Assignee Rule Action Type enum
 * @export
 * @enum {number}
 */
export enum CreateTaskAssigneeRuleActionTypeEnum {
  Attribute = 'ATTRIBUTE',
  UserGroup = 'USER_GROUP',
}

/**
 * Create Task Due Rule Action Type enum
 * @export
 * @enum {number}
 */
export enum CreateTaskDueRuleActionTypeEnum {
  Attribute = 'ATTRIBUTE',
  Relative = 'RELATIVE',
}

/**
 * Relative CreateTask Dure rule Action Multiplier Enum
 * @export
 * @enum {number}
 */
export enum RelativeCreateTaskDueRuleActionMultiplierEnum {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'Month',
}

/**
 * makeBaseRuleAction
 * @param {BaseRuleActionInterface} baseRuleAction - Base Rule actions
 * @return {RuleActionInterface} - return the base RuleAction
 */
export const makeBaseRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction: RuleActionInterface = {
    ...baseRuleAction,
    type: null,
  };
  return ruleAction;
};

/**
 * makeSetAttributeRuleAction
 * @param {BaseRuleActionInterface} baseRuleAction - Base Rule actions
 * @return {RuleActionInterface} - return the base RuleAction
 */
export const makeSetAttributeRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction = makeBaseRuleAction(baseRuleAction);

  return {
    ...ruleAction,
    type: RuleActionTypeEnum.SetAttribute,
    destinationAttributeId: null,
    sourceAttributeId: null,
    sourceEntityId: null,
    setAttributeDateUnit: null,
    setAttributeDateDirection: null,
    setAttributeDateAmount: null,
    setAttributeDateType: null,
  };
};

/**
 * makeCreateRecordRuleAction
 * @param {BaseRuleActionInterface} baseRuleAction - Base Rule actions
 * @return {RuleActionInterface} - return the base RuleAction
 */
export const makeCreateRecordRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction = makeBaseRuleAction(baseRuleAction);
  return {
    ...ruleAction,
    entityId: null,
    type: RuleActionTypeEnum.CreateRecord,
  };
};

/**
 * makeCreateTaskRuleAction
 * @param {BaseRuleActionInterface} baseRuleAction - Base Rule actions
 * @return {RuleActionInterface} - return the base RuleAction
 */
export const makeCreateTaskRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction = makeBaseRuleAction(baseRuleAction);

  return {
    ...ruleAction,
    assigneeId: null,
    description: null,
    summary: null,
    type: RuleActionTypeEnum.CreateTask,
    dueType: null,
  };
};

/**
 * makeLockRecordRuleAction
 * @param {BaseRuleActionInterface} baseRuleAction - Base Rule actions
 * @return {RuleActionInterface} - return the base RuleAction
 */
export const makeLockRecordRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction = makeBaseRuleAction(baseRuleAction);
  return {
    ...ruleAction,
    lock: false,
    roleIds: [],
    type: RuleActionTypeEnum.LockRecord,
  };
};

/**
 * makeSendNotificationRuleAction
 * @param {BaseRuleActionInterface} baseRuleAction - Base Rule actions
 * @return {RuleActionInterface} - return the base RuleAction
 */
export const makeSendNotificationRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction = makeBaseRuleAction(baseRuleAction);
  return {
    ...ruleAction,
    notificationId: null,
    recipientAttributeId: null,
    type: RuleActionTypeEnum.SendNotification,
  };
};

export const makeInviteUserGuestRuleAction = (baseRuleAction: BaseRuleActionInterface): RuleActionInterface => {
  const ruleAction = makeBaseRuleAction(baseRuleAction);
  return {
    ...ruleAction,
    type: RuleActionTypeEnum.InviteGuestUser,
    notificationId: null,
    recipientAttributeId: null,
    recipientEntityId: null,
    roleId: null,
  };
};
