import { UrgencyTypeEnum, makeHumanReadableZodErrors } from '@surecloud/common';
import { z } from 'zod';

/**
 * Normalise Get Homepage Applications Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data returned from NormaliseGetHomepageApplicationsService.
 */
export const urgencyTypeZodEnum = z.nativeEnum(UrgencyTypeEnum);

/**
 * Application Entity schema.
 */
export const applicationEntitySchema = z.object({
  entityId: z.string(),
  entityName: z.string().nullable(),
  entityDescription: z.string().nullable(),
});

/**
 * Homepage Applications Zod schema.
 */
export const homepageApplicationZodSchema = z.object({
  applicationEntities: z.array(applicationEntitySchema),
  applicationName: z.string(),
});

/**
 * Normalised Homepage Applications Zod schema.
 */
export const normalisedHomepageApplicationsZodSchema = z.object({
  homepageApplications: z.array(homepageApplicationZodSchema),
});

/**
 * Normalised Homepage Applications data interface.
 */
export type NormalisedHomepageApplications = z.infer<typeof normalisedHomepageApplicationsZodSchema>;

/**
 * Guard to check the normalised HomepageApplications data matches the schema.
 *
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedHomepageApplications.
 */
export const isNormalisedHomepageApplicationsGuard = (map: unknown): map is NormalisedHomepageApplications =>
  normalisedHomepageApplicationsZodSchema.safeParse(map).success;

/**
 * When the normalised HomepageApplications data does not match the schema
 * then get the error as to show where the problem is.
 *
 * @param {unknown} map Map of the data to check.
 * @return {string} The error on where the map of the data does not match a NormalisedHomepageApplications.
 */
export const getNormalisedHomepageApplicationsError = (map: unknown): string => {
  const zodResult = normalisedHomepageApplicationsZodSchema.safeParse(map);
  return zodResult.success ? 'No errors.' : makeHumanReadableZodErrors(zodResult.error.issues);
};
