import { Injectable } from '@angular/core';
import { EntityAttributeTypeEnum } from '@surecloud/common';
import { Observable, of } from 'rxjs';
import { EntityAttributeFormatTypesEnum } from '../../+state/models/format/format.model.enum';
import {
  EntityAttributeFormatOptionInterface,
  EntityAttributeFormatOptionsInterface,
} from './format-options.service.interface';

export const constructFormatOptions = (): EntityAttributeFormatOptionsInterface =>
  Object.values(EntityAttributeTypeEnum).reduce((map, type) => {
    const mapClone: EntityAttributeFormatOptionsInterface = { ...map };
    mapClone[type] = [];
    return mapClone;
  }, {} as EntityAttributeFormatOptionsInterface);

/**
 * Entity attribute format options service.
 * @export
 * @class EntityAttributeFormatOptionsService
 */
@Injectable({
  providedIn: 'root',
})
export class EntityAttributeFormatOptionsService {
  /**
   * Format options.
   * @private
   * @type {EntityAttributeFormatOptionsInterface}
   * @memberof EntityAttributeFormatOptionsService
   */
  private readonly formatOptions: EntityAttributeFormatOptionsInterface = {
    ...constructFormatOptions(),
    [EntityAttributeTypeEnum.Number]: [
      {
        controls: [
          {
            key: 'currencyCode',
            label: $localize`Please set the currency code for this attribute`,
            required: true,
            type: 'select',
            options: of([
              {
                text: $localize`Great British Pound`,
                value: 'GBP',
              },
              {
                text: $localize`United States Dollar`,
                value: 'USD',
              },
            ]),
          },
        ],
        name: $localize`Currency`,
        type: EntityAttributeFormatTypesEnum.Currency,
      },
      {
        controls: [
          {
            key: 'decimalPlaces',
            label: $localize`Please set the decimal places for this attribute`,
            min: 1,
            required: true,
            type: 'number',
          },
        ],
        name: $localize`Decimal Places`,
        type: EntityAttributeFormatTypesEnum.DecimalNumber,
      },
      {
        name: $localize`Percentage`,
        type: EntityAttributeFormatTypesEnum.Percentage,
      },
      {
        name: $localize`Whole Number`,
        type: EntityAttributeFormatTypesEnum.WholeNumber,
      },
    ] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Text]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Url]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Email]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Document]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Date]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Sequence]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Calculation]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.OptionList]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Hierarchy]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.User]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.Toggle]: [] as EntityAttributeFormatOptionInterface[],
    [EntityAttributeTypeEnum.State]: [] as EntityAttributeFormatOptionInterface[],
  };

  /**
   * Get an array of entity attribute format options for a specific entity attribute type.
   * @param {EntityAttributeTypeEnum | null} type The type of entity attribute.
   * @return {Observable<EntityAttributeFormatOptionInterface[]>} Array of attribute formatting options for the attribute type.
   * @memberof EntityAttributeFormatOptionsService
   */
  getOptions(type: EntityAttributeTypeEnum | null): Observable<EntityAttributeFormatOptionInterface[]> {
    return of(type ? this.formatOptions[type] : []);
  }
}
