import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ScIconType } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { ScButtonSize, ScButtonThemeColour, ScIconButtonShape } from './button.constants';

/**
 * Surecloud Icon Button Component.
 * @example
 * <sc-icon-button [themeColour]="danger" [size]="large" [shape]="rounded" (click)="onClickMe()"></sc-icon-button>
 * @export
 * @class IconButtonComponent
 */
@Component({
  selector: 'sc-icon-button',
  standalone: true,
  imports: [ButtonsModule, CommonIconModule, CommonModule],
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IconButtonComponent implements OnChanges {
  /**
   * Sets the button type.
   * @memberof IconButtonComponent
   */
  @Input() buttonType = 'button';
  /**
   * Sets the theme colour for the icon button.
   * @type {ScButtonThemeColour}
   * @memberof IconButtonComponent
   */
  @Input() themeColour: ScButtonThemeColour = 'primary';

  /**
   * Sets the preset size of the icon button.
   * @type {ScButtonSize}
   * @memberof IconButtonComponent
   */
  @Input() size: ScButtonSize = 'medium';

  /**
   * Sets the shape of the icon button.
   * @type {ScIconButtonShape}
   * @memberof IconButtonComponent
   */
  @Input() shape: ScIconButtonShape = 'circular';

  /**
   * Allow the use of a custom icon width
   * @type {(number | string)}
   * @memberof IconButtonComponent
   */
  @Input() iconWidth!: number | string;

  /**
   * Allow the use of a custom icon height
   * @type {(number | string)}
   * @memberof IconButtonComponent
   */
  @Input() iconHeight!: number | string;

  /**
   * Sets the icon
   * @type {ScIconType}
   * @memberof IconButtonComponent
   */
  @Input() icon: ScIconType = 'close';

  /**
   * Sets if the button is disabled
   * @memberof IconButtonComponent
   */
  @Input() disabled = false;

  /**
   * icon button theme class
   * @type {string}
   * @memberof IconButtonComponent
   */
  themeClass = '';

  /**
   * Update theme class on changes.
   * @memberof IconButtonComponent
   */
  ngOnChanges(): void {
    const colourClass = this.getThemeColourCSSClass();
    this.themeClass = `${colourClass} ${this.getSizeShapeCSSClass()}`;
  }

  /**
   * Get button themeColour css class
   * @private
   * @return {string} themeColour css class
   * @memberof IconButtonComponent
   */
  private getThemeColourCSSClass(): string {
    return `sc-icon-button-${this.themeColour}`;
  }

  /**
   * Get button size and shape css class
   * @private
   * @return {string} size and shape css class
   * @memberof IconButtonComponent
   */
  private getSizeShapeCSSClass(): string {
    return `sc-${this.shape}-icon-button-${this.size}`;
  }
}
