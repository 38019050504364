import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { PriorityTasksService } from '../../../services/priority-tasks/priority-tasks.service';
import { PriorityTasksActions } from '../../actions/priority-tasks.actions';

/**
 * The Effects/side effects for PriorityTasks.
 *
 * @export
 * @class PriorityTasksEffects
 */
@Injectable({ providedIn: 'root' })
export class PriorityTasksEffects {

  /**
   * When reading a PriorityTasks.
   * Then load all PriorityTasks data from the API.
   *
   * @memberof PriorityTasksEffects
   */
  loadPriorityTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriorityTasksActions.readPriorityTasks),
      mergeMap(() =>
        this.priorityTasksService.readAll().pipe(
          map((normalisedPriorityTasks) =>
            PriorityTasksActions.readPriorityTasksDataSuccess({
              normalisedPriorityTasks,
            })
          ),
          catchError((error: unknown) => of(PriorityTasksActions.readPriorityTasksFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the PriorityTasks has received a response.
   * Then load the result into state.
   *
   * @memberof PriorityTasksEffects
   */
  setPriorityTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriorityTasksActions.readPriorityTasksDataSuccess),
      map(({ normalisedPriorityTasks }) =>
        PriorityTasksActions.readPriorityTasksSuccess({
          priorityTasks: normalisedPriorityTasks.priorityTasks,
        })
      )
    )
  );

  /**
   * Creates an instance of PriorityTasksEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {PriorityTasksService} priorityTasksService The PriorityTasks API service.
   * @memberof PriorityTasksEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly priorityTasksService: PriorityTasksService,
  ) {}
}
