/* eslint-disable */
import { APP_BOOTSTRAP_LISTENER, Inject, InjectionToken, Type } from '@angular/core';
import { EffectSources } from '@ngrx/effects';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

/**
 *
 * @param effects
 * @param sources
 */
export function bootstrapEffects(effects: Type<any>[], sources: EffectSources) {
  return () => {
    effects.forEach((effect) => sources.addEffects(effect));
  };
}

/**
 *
 * @param {...any} instances
 */
export function createInstances(...instances: any[]): any[] {
  return instances;
}

/**
 *
 * @param effects
 */
export function provideBootstrapEffects(effects: Type<any>[]) {
  return [
    effects,
    { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
    },
  ];
}

/**
 * See https://github.com/ngrx/platform/issues/931
 * This utility class is based on the workaround suggested by Brandon Roberts here https://github.com/brandonroberts/effects-issue-example
 * We had to refactor it and use the effects type as injection token for the effects injection to avoid their shadowing when
 * EffectsBootstrapProvider.provide is called multiple times.
 */
export abstract class EffectsBootstrapProvider {
  static provide(effects: Type<any>[]) {
    return [
      effects,
      {
        provide: APP_BOOTSTRAP_LISTENER,
        multi: true,
        useFactory:
          (sources: EffectSources, ..._effects: Type<any>[]) =>
          () =>
            _effects.forEach((effect) => sources.addEffects(effect)),
        deps: [EffectSources, ...effects],
      },
    ];
  }
}

