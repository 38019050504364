import { roleZodSchema } from '@surecloud/common';
import { z } from 'zod';

/**
 * Normalise Get Roles Validation Constants.
 *
 * Collection of constants, types & functions to help
 * with validating the data return from NormaliseGetRolesService.
 */

/**
 * Normalised Role Zod schema.
 */
export const normalisedRoleZodSchema = z.object({
  roles: z.array(roleZodSchema),
});

/**
 * Normalised Role.
 */
export type NormalisedRoleList = z.infer<typeof normalisedRoleZodSchema>;

/**
 * Normalised Role.
 */
export type NormalisedRole = z.infer<typeof roleZodSchema>;

/**
 * Guard to check the normalised role data matches the schema.
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedRoleList.
 */
export const isNormalisedRoleListGuard = (map: unknown): map is NormalisedRoleList =>
  normalisedRoleZodSchema.safeParse(map).success;

/**
 * When the normalised role data does not match the schema
 * then get an error to show there the problem is.
 * @param {unknown} map Map of the data to check.
 * @return {z.SafeParseReturnType<unknown, NormalisedRoleList>} The error on where the map of data does not match a NormalisedRoleList.
 */
export const getNormalisedRoleListError = (map: unknown): z.SafeParseReturnType<unknown, NormalisedRoleList> =>
  normalisedRoleZodSchema.safeParse(map);
