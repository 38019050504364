import { Injectable } from '@angular/core';
import {
  EntityAttributeRuleCreatePayloadApiInterface,
  EntityAttributeRuleDeleteInputApiInterface,
  EntityAttributeRuleDeletePayloadApiInterface,
  EntityAttributeRuleUpdatePayloadApiInterface,
} from '@surecloud/api-types';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import {
  EntityAttributeCreateRuleInterface,
  EntityAttributeRuleInterface,
  EntityAttributeRuleUpdatedInterface,
} from '../../+state/models/rule/rule.models';
import {
  apolloEntityAttributeRuleCreateMutation,
  apolloEntityAttributeRuleDeleteMutation,
  apolloEntityAttributeRuleUpdateMutation,
} from '../../mutations/rules/rules.mutations';

@Injectable({
  providedIn: 'root',
})
export class EntityAttributeRuleService {
  /**
   * Creates an instance of EntityAttributeRuleService.
   * @param {Apollo} apollo The Angular Apollo service.
   * @memberof EntityAttributeRuleService
   */
  constructor(private readonly apollo: Apollo) {}

  /**
   * Create an error message for this service's API calls.
   * @private
   * @static
   * @param {string} type The type of error message to create.
   * @return {string} The error message.
   * @memberof EntityAttributeRuleService
   */
  private static createErrorMessage(type: string): string {
    return `No ${type} entity attribute validation rule response data from ${type} validation rule on entity attribute returned from API`;
  }

  /**
   * Update an entity's attribute validation rule.
   * @param {EntityAttributeRuleInterface} rule The entity's attribute validation rule to update.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityAttributeRuleInterface>} The update entity's attribute rule.
   * @memberof EntityAttributeRuleService
   */
  update(rule: EntityAttributeRuleInterface, requestId: string | null = null): Observable<EntityAttributeRuleInterface> {
    const { attributeId, ...entityAttributeRuleUpdateInput } = rule;

    return this.apollo
      .mutate<{ entityAttributeRuleUpdate: EntityAttributeRuleUpdatePayloadApiInterface }>(
        apolloEntityAttributeRuleUpdateMutation(entityAttributeRuleUpdateInput, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeRuleUpdate
            ? of(rule)
            : throwError(() => EntityAttributeRuleService.createErrorMessage('update'))
        )
      );
  }

  /**
   * Create a rule on an entity's attribute.
   * @param {EntityAttributeCreateRuleInterface} rule The rule to create.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityAttributeRuleInterface>} The created rule.
   * @memberof EntityAttributeRuleService
   */
  create(rule: EntityAttributeCreateRuleInterface, requestId: string | null = null): Observable<EntityAttributeRuleInterface> {
    return this.apollo
      .mutate<{ entityAttributeRuleCreate: EntityAttributeRuleCreatePayloadApiInterface }>(
        apolloEntityAttributeRuleCreateMutation(rule, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeRuleCreate
            ? of({ ...rule, ruleId: data.entityAttributeRuleCreate.createdId, conditionType: null, valueType: null })
            : throwError(() => EntityAttributeRuleService.createErrorMessage('create'))
        )
      );
  }

  /**
   * Delete a rule on an entity's attribute.
   * @param {EntityAttributeRuleInterface} rule The rule to delete.
   * @return {Observable<EntityAttributeRuleUpdatedInterface>} The ID of the delete entity attribute rule.
   * @memberof EntityAttributeRuleService
   */
  delete(rule: EntityAttributeRuleInterface): Observable<EntityAttributeRuleUpdatedInterface> {
    const payload: EntityAttributeRuleDeleteInputApiInterface = {
      entityId: rule.entityId,
      ruleId: rule.ruleId,
    };

    return this.apollo
      .mutate<{ entityAttributeRuleDelete: EntityAttributeRuleDeletePayloadApiInterface }>(
        apolloEntityAttributeRuleDeleteMutation(payload)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityAttributeRuleDelete
            ? of({ ruleId: data.entityAttributeRuleDelete.deletedId })
            : throwError(() => EntityAttributeRuleService.createErrorMessage('delete'))
        )
      );
  }
}
