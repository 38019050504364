import { gql, QueryOptions } from '@apollo/client/core';

/**
 * GraphQL query for getting all the entities.
 */
const ENTITIES_QUERY = gql`
  query entities {
    entities {
      entityId
      name
      description
      created
      attributes {
        attributeId
        guidanceText
        name
        type
        ... on TextEntityAttribute {
          large
        }
        ... on OptionListEntityAttribute {
          multiple
          extendable
          optionListId
        }
        ... on SequenceEntityAttribute {
          prefix
        }
        ... on UserEntityAttribute {
          userMode
          multiple
        }
        ... on CalculationEntityAttribute {
          trendAnalysis
          trendDirection
          formula
        }
        ... on NameEntityAttribute {
          calculation
          calculated
        }
        ... on HierarchyEntityAttribute {
          customHierarchyId
          levels
        }
        permissions {
          entityAttributePermissionId
          readPermission
          updatePermission
          role {
            roleId
            name
          }
        }
        formats {
          formatId
          type
          ... on CurrencyEntityAttributeFormat {
            currencyCode
          }
          ... on DecimalNumberEntityAttributeFormat {
            decimalPlaces
          }
        }
        rules {
          ruleId
          conditionType
          valueType
          ... on MaximumCharacterLimitEntityAttributeRule {
            limit
          }
          ... on MinimumCharacterLimitEntityAttributeRule {
            limit
          }
          ... on GreaterThanEntityAttributeRule {
            limit
            linkedAttributeId
          }
          ... on LessThanEntityAttributeRule {
            limit
            linkedAttributeId
          }
          ... on AfterEntityAttributeRule {
            amount
            date
            linkedAttributeId
            temporalOperator
            temporalUnit
          }
          ... on BeforeEntityAttributeRule {
            amount
            date
            linkedAttributeId
            temporalOperator
            temporalUnit
          }
        }
      }
      permissions {
        entityPermissionId
        createPermission
        readPermission
        updatePermission
        deletePermission
        role {
          roleId
          name
        }
      }
    }
  }
`;

/**
 * Function to create the GraphQL query options for getting all the entities.
 * @return {QueryOptions} The GraphQL query options.
 */
export const apolloEntitiesQuery = (): QueryOptions => ({
  query: ENTITIES_QUERY,
});

/**
 * GraphQL query for getting an entity.
 */
const ENTITY_QUERY = gql`
  query entity($entityId: UUID!) {
    entity(entityId: $entityId) {
      entityId
      name
      created
      description
      attributes {
        attributeId
        guidanceText
        name
        type
        ... on BasicEntityAttribute {
          placeholder
        }
        ... on TextEntityAttribute {
          placeholder
          large
          placeholder
        }
        ... on BasicEntityAttribute {
          placeholder
        }
        ... on OptionListEntityAttribute {
          multiple
          extendable
          optionListId
          placeholder
        }
        ... on HierarchyEntityAttribute {
          customHierarchyId
          levels
        }
        ... on SequenceEntityAttribute {
          prefix
        }
        ... on UserEntityAttribute {
          userMode
          multiple
          placeholder
          roleIds
          hierarchy
        }
        ... on CalculationEntityAttribute {
          trendAnalysis
          trendDirection
          formula
        }
        ... on NameEntityAttribute {
          calculation
          calculated
          placeholder
        }
        permissions {
          entityAttributePermissionId
          readPermission
          updatePermission
          role {
            roleId
            name
          }
        }
        formats {
          formatId
          type
          ... on CurrencyEntityAttributeFormat {
            currencyCode
          }
          ... on DecimalNumberEntityAttributeFormat {
            decimalPlaces
          }
        }
        rules {
          ruleId
          conditionType
          valueType
          ... on MaximumCharacterLimitEntityAttributeRule {
            limit
          }
          ... on MinimumCharacterLimitEntityAttributeRule {
            limit
          }
          ... on GreaterThanEntityAttributeRule {
            limit
            linkedAttributeId
          }
          ... on LessThanEntityAttributeRule {
            limit
            linkedAttributeId
          }
          ... on AfterEntityAttributeRule {
            amount
            date
            linkedAttributeId
            temporalOperator
            temporalUnit
          }
          ... on BeforeEntityAttributeRule {
            amount
            date
            linkedAttributeId
            temporalOperator
            temporalUnit
          }
        }
      }
      links {
        linkedEntityId
        type
        tags {
          tagId
          label
        }
        entities: entity {
          entityId
          name
          created
          attributes {
            attributeId
            guidanceText
            name
            type
          }
        }
      }
      permissions {
        entityPermissionId
        createPermission
        readPermission
        updatePermission
        deletePermission
        role {
          roleId
          name
        }
      }
      questionSets {
        attributeId
        entityId
        name
        questionSetId
        reviewerRoleIds
      }
    }
  }
`;

/**
 * Function to create the GraphQL query options for getting an entity.
 * @param {string} entityId The ID of the entity to get.
 * @return {QueryOptions} The GraphQL query options.
 */
export const apolloEntityQuery = (entityId: string): QueryOptions => ({
  query: ENTITY_QUERY,
  variables: { entityId },
});
