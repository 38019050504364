import { Observable, filter } from 'rxjs';

/**
 * Custom RxJS Operator to filter by whether the value is defined or not.
 *
 * @return {Observable<T>} observable stream - the amended stream.
 */
export function filterValueDefined(): <T>(source: Observable<T>) => Observable<T> {
  return function filterByValueDefined<T>(source: Observable<T>): Observable<T> {
    return source.pipe(filter((value) => value !== undefined));
  };
}
