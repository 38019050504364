import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SCGridSchemaTypeEnum, trackByIndex } from '@surecloud/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UserAvatarChipComponent } from '../../../user-selects/user-avatar-chip/user-avatar-chip.component';
import { UserAvatarType } from '../../../user-selects/user-avatar.model';

/**
 * AgGrid rendering component for rendering a readonly user avatar column cell.
 * The data structure of your column definition field must be of type GridUserAvatarInterface.
 *
 * @export
 * @class GridUserAvatarRendererComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  selector: 'sc-grid-user-avatar',
  standalone: true,
  templateUrl: './grid-user-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./grid-user-avatar.component.scss'],
  imports: [CommonModule, UserAvatarChipComponent],
})
export class GridUserAvatarRendererComponent implements ICellRendererAngularComp {
  /**
   *  Array of users to render.
   *
   * @type {UserAvatarType[]}
   * @memberof GridUserAvatarRendererComponent
   */
  users!: UserAvatarType[];

  /**
   * Set if is multiple users or single
   *
   * @type {boolean}
   * @memberof GridUserAvatarRendererComponent
   */
  isMultiple!: boolean;

  /**
   * Track by function to pass to any Angular View ngFor loops.
   *
   * @memberof GridUserAvatarRendererComponent
   */
  trackByIndex = trackByIndex;

  /**
   * Handles the ag init and sets the values for the users.
   * The data structure of your column definition field must be of type GridUserAvatarInterface.
   *
   * @param {ICellRendererParams} params
   * @memberof GridUserAvatarRendererComponent
   */
  agInit(params: ICellRendererParams): void {
    this.isMultiple = params.colDef?.type === SCGridSchemaTypeEnum.MultipleUserAvatar;
    this.users = params.value ? params.value : [];
  }

  /**
   * Refresh implementation to satisfy ICellRendererAngularComp
   *
   * @return {*}  {boolean}
   * @memberof GridUserAvatarRendererComponent
   */
  // eslint-disable-next-line class-methods-use-this
  refresh(): boolean {
    return true;
  }
}
