import { ScIconType } from '@surecloud/common';
import { ComponentDragListItemTypeEnum } from './components-drag-list.components.enum';

/**
 * Selector for the draggable component item.
 */
export const COMPONENTS_DRAG_LIST_ITEM_SELECTOR = 'component-drag-list__item';

/**
 * Icons for the types of components that can be shown in the components drag list.
 */
export const COMPONENTS_DRAG_LIST_ITEM_ICON_MAP: { [key in ComponentDragListItemTypeEnum]: ScIconType } = {
  [ComponentDragListItemTypeEnum.Information]: 'burger',
  [ComponentDragListItemTypeEnum.Report]: 'pie-chart-outline',
  [ComponentDragListItemTypeEnum.Table]: 'table',
};

/**
 * Labels for the types of components that can be shown in the components drag list.
 */
export const COMPONENTS_DRAG_LIST_ITEM_LABEL_MAP: { [key in ComponentDragListItemTypeEnum]: string } = {
  [ComponentDragListItemTypeEnum.Information]: $localize`Information`,
  [ComponentDragListItemTypeEnum.Report]: $localize`Report`,
  [ComponentDragListItemTypeEnum.Table]: $localize`Table`,
};
