import { RoleInterface } from '@surecloud/common';

/**
 * Entity Permission interface.
 *
 * @export
 * @property {string} entityId The entity ID.
 * @property {string} entityPermissionId The entity permission ID.
 * @property {boolean} createPermission Whether the role can create new entities.
 * @property {boolean} readPermission Whether the role can view the entity.
 * @property {boolean} updatePermission Whether the role can update the entity.
 * @property {boolean} deletePermission Whether the role can delete the entity.
 * @property {RoleInterface} role The role associated with the entity permission.
 * @interface EntityPermissionInterface
 */
export interface EntityPermissionInterface {
  entityId: string;
  entityPermissionId: string;
  createPermission: boolean;
  readPermission: boolean;
  updatePermission: boolean;
  deletePermission: boolean;
  role?: RoleInterface | null;
}

/**
 * Entity Permission Create Input Interface.
 *
 * @export
 * @property {string} entityId The entity ID.
 * @interface EntityPermissionCreateInputInterface
 */
export interface EntityPermissionCreateInputInterface {
  entityId: string;
}

/**
 * Entity Permission Delete Input Interface.
 *
 * @export
 * @property {string} entityId The entity ID.
 * @property {string} entityPermissionId The entity permission ID.
 * @interface EntityPermissionDeleteInputInterface
 */
export interface EntityPermissionDeleteInputInterface {
  entityId: string;
  entityPermissionId: string;
}

/**
 * Utility function to make a Entity Permission
 *
 * @param {string} entityPermissionId The ID of the Entity Permission.
 * @param {string} entityId The entityId of the Entity Permission.
 * @return {EntityPermissionInterface} A Entity Permission interface.
 */
export const makeEntityPermission = (entityPermissionId: string, entityId: string): EntityPermissionInterface => ({
  entityPermissionId,
  entityId,
  createPermission: false,
  deletePermission: false,
  readPermission: false,
  updatePermission: false,
  role: null,
});

/**
 * isEntityPermission - checks if object is an  Entity Permission.
 *
 * @param {unknown} value The object.
 * @return {boolean} Whether it is a  Entity Permission or not.
 */
export const isEntityPermission = (value: unknown): value is EntityPermissionInterface => {
  const entityPermission = value as EntityPermissionInterface;
  return entityPermission?.entityPermissionId !== undefined;
};

