/* eslint-disable @nx/enforce-module-boundaries */
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { AuthSharedModule } from '@surecloud/auth';
import {
  Auth0AndGuestUserAuthService,
  Auth0AndGuestUserAuthServiceStub,
  ENV_CONFIG_TOKEN,
  ENV_URL_TOKEN,
  EnvironmentConfigInterface,
  fromPageState,
  PageTitleStrategy,
  ToastService,
} from '@surecloud/common';
import { APP_API_URI, APP_SUBSCRIPTIONS_URI, DataModule } from '@surecloud/data';
import { ToastComponent } from '@surecloud/design';
import { ErrorModule, GlobalErrorHandler, log } from '@surecloud/error';
import { FeatureFlagModule } from '@surecloud/feature-flag';
import { ModalModule } from '@surecloud/modal';
import { StandaloneModule } from '@surecloud/standalone';
import { UserProfileModule } from '@surecloud/user-profile';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { CoreAppComponent } from './core-app.component';
import { getApiUri, getSubscriptionsUri } from './core-app.constants';
import { coreAppRoutes } from './core-app.routes';
import { EmptyComponent } from './empty.component';
import { ENV_URL } from './env.token';
import { EnvironmentConfigService } from './services/environment-config/environment-config.service';
import { IdpUrlParserService } from './services/idp-url-parser/idp-url-parser.service';
import { initialiseURLFactory } from './services/initialise-url/initialise-url.factory';

@NgModule({
  declarations: [CoreAppComponent],
  imports: [
    BrowserModule,
    /*  Need to discuss if we are happy to use this on first review.
        It allows us to inherit the parent route data
        so when setting breadcrumb data on a route,
        you only have to do it at the parent level and all children will inherit it
        unless they set their own breadcrumbs in which case it will then override it for the current child route.
        Info here: https://angular.io/api/router/RouterConfigOptions#paramsInheritanceStrategy
     */
    RouterModule.forRoot(coreAppRoutes, { paramsInheritanceStrategy: 'always' }),
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
        metaReducers: [log],
      }
    ),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 20, // Retains last 20 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([]),
    RouterModule,
    DialogModule,
    NotificationModule,
    StandaloneModule,
    ErrorModule,
    ModalModule,
    UserProfileModule,
    AuthSharedModule,
    DataModule.forRoot(),
    FeatureFlagModule,
    ToastModule,
    EmptyComponent,
    ToastComponent,
    StoreModule.forFeature(fromPageState.PAGE_STATE_FEATURE_KEY, fromPageState.reducer),
  ],
  providers: [
    {
      provide: APP_API_URI,
      useValue: getApiUri(),
    },
    {
      provide: APP_SUBSCRIPTIONS_URI,
      useValue: getSubscriptionsUri(),
    },
    {
      provide: ENV_URL_TOKEN,
      useExisting: ENV_URL,
    },
    {
      provide: ENV_CONFIG_TOKEN,
      useFactory: (configService: EnvironmentConfigService): EnvironmentConfigInterface =>
        configService.getEnvironmentConfig(),
      deps: [EnvironmentConfigService],
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: Auth0AndGuestUserAuthService,
      useClass: environment.cypressMode ? Auth0AndGuestUserAuthServiceStub : Auth0AndGuestUserAuthService,
    },
    ToastService,
    MessageService,
    {
      /**
       * Parse the URL and remove the state and redirect_uri query parameters,
       *
       * Why? Angular doesn't match the idpLogin route if it contains nested query
       * parameters. This is a workaround to remove them before Angular loads everything.
       * Note - a custom url serialiser didn't help this situation.
       */
      provide: APP_INITIALIZER,
      useFactory: initialiseURLFactory,
      deps: [IdpUrlParserService],
      multi: true,
    },
  ],
  bootstrap: [CoreAppComponent],
})
export class CoreAppModule {}
