import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService } from '@surecloud/common';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { UserProfileActions } from '../actions/user-profile.actions';

/**
 * The Effects/side effects for User Profile.
 *
 * @export
 * @class UserProfileEffects
 */
@Injectable({ providedIn: 'root' })
export class UserProfileEffects {
  /**
   * When reading a User Profile.
   * Then load the user profile data from the API.
   *
   * @memberof UserProfileEffects
   */
  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.readUserProfile),
      switchMap(() =>
        this.service.read().pipe(
          map((normalisedUserProfile) =>
            UserProfileActions.readUserProfileDataSuccess({
              normalisedUserProfile,
            })
          ),
          catchError((error: unknown) => of(UserProfileActions.readUserProfileFailure({ error: `${error}` })))
        )
      )
    )
  );

  /**
   * When the User Profile has received a response.
   * Then load the result into state.
   *
   * @memberof UserProfileEffects
   */
  setUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.readUserProfileDataSuccess),
      map(({ normalisedUserProfile }) =>
        UserProfileActions.readUserProfileSuccess({
          userProfile: normalisedUserProfile,
        })
      )
    )
  );

  /**
   * When a User Profile API call failed.
   * Then log the error.
   *
   * @memberof UserProfileEffects
   */
  notifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.readUserProfileFailure),
        tap(({ error, type }) => this.logger.logEvent('User Profile', type, error))
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of UserProfileEffects.
   *
   * @param {Actions} actions$ The NGRX Store actions.
   * @param {UserProfileService} service The User Profile API service.
   * @param {LoggerService} logger The common logger service.
   * @memberof UserProfileEffects
   */
  constructor(
    private readonly actions$: Actions,
    private readonly service: UserProfileService,
    private readonly logger: LoggerService
  ) {}
}
