import { createFeatureSelector, createSelector } from '@ngrx/store';
import { logicConditionAdapter, LOGIC_CONDITION_FEATURE_KEY, StateInterface } from './logic-condition.reducer';

// Lookup the 'Logic Condition' feature state managed by NgRx
export const getLogicConditionState = createFeatureSelector<StateInterface>(LOGIC_CONDITION_FEATURE_KEY);

const { selectAll, selectEntities } = logicConditionAdapter.getSelectors();

/**
 * Get all Logic condition.
 */
export const getLogicConditionList = createSelector(getLogicConditionState, (state: StateInterface) =>
  selectAll(state)
);
export const getLogicConditionLoaded = createSelector(getLogicConditionState, (state: StateInterface) => state.loaded);
export const getLogicConditionError = createSelector(getLogicConditionState, (state: StateInterface) => state.error);

export const getLogicConditionEntities = createSelector(getLogicConditionState, (state: StateInterface) =>
  selectEntities(state)
);

export const getLogicConditionSelectedId = createSelector(
  getLogicConditionState,
  (state: StateInterface) => state.selectedId
);

export const getSelectedLogicCondition = createSelector(
  getLogicConditionEntities,
  getLogicConditionSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
