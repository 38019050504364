import { z } from 'zod';
import { UserGroupTypeEnum } from '../../../models/user-group.enum';

export const userGroupTypeZodEnum = z.nativeEnum(UserGroupTypeEnum);

/**
 * User groups Zod schema.
 */
export const userGroupsZodSchema = z.object({
  id: z.string(),
  type: userGroupTypeZodEnum,
  colour: z.string(),
  initials: z.string(),
  name: z.string(),
});

/**
 * Normalised User Groups list Zod schema.
 */
export const normalisedUserGroupsListZodSchema = z.array(userGroupsZodSchema);

/**
 * Normalised User list data interface.
 */
export type NormalisedUserGroupsList = z.infer<typeof normalisedUserGroupsListZodSchema>;

/**
 * Guard to check the normalised User Group list data matches the schema.
 * @param {unknown} map Map of the data to check.
 * @return {boolean} Is the map of data a NormalisedUserList.
 */
export const isNormalisedUserGroupsListGuard = (map: unknown): map is NormalisedUserGroupsList =>
  normalisedUserGroupsListZodSchema.safeParse(map).success;

/**
 * When the normalised User Groups list data does not match the schema.
 * Then get an error to show where the problem is.
 * @param {unknown} map Map of the data to check.
 * @return {z.SafeParseReturnType<unknown, NormalisedUserGroupsList>} The error on where the map of data does not match a NormalisedUserGroupsList.
 */
export const getNormalisedUserGroupsListError = (
  map: unknown
): z.SafeParseReturnType<unknown, NormalisedUserGroupsList> => normalisedUserGroupsListZodSchema.safeParse(map);
