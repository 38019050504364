import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextComponent } from '../input-text/input-text.component';
import { EMAIL_PATTERN } from './input-email.constants';

/**
 * Surecloud input email Component that wraps the TextBoxComponent with a optional label.
 *
 * @export
 * @class InputEmailComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-input-email',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, InputTextComponent],
  templateUrl: './input-email.component.html',
})
export class InputEmailComponent implements OnInit {
  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof InputEmailComponent
   */
  @Input() controlName = '';

  /**
   * Text to display an additional description to the user.
   *
   * @memberof InputEmailComponent
   */
  @Input() description = '';

  /**
   * Sets the text value for the label.
   *
   * @memberof InputEmailComponent
   */
  @Input() label = '';

  /**
   * Sets the placholder value for the input.
   *
   * @memberof InputEmailComponent
   */
  @Input() placeholder = '';

  /**
   * Sets the readonly value for the input.
   *
   * @memberof InputEmailComponent
   */
  @Input() readonly = false;

  /**
   *  Sets if we want a success validation style to display.
   *
   * @memberof InputEmailComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Sets a px width for the input. If this is not set the width will be set to 100% by default from styles.
   *
   * @memberof InputEmailComponent
   */
  @Input()
  staticWidth!: string;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   *
   * @memberof InputEmailComponent
   */
  @Input() marginBottom = false;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   *
   * @type {FormGroup}
   * @memberof InputEmailComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Emits when the input email component has been blurred.
   *
   * @type {EventEmitter<void>}
   * @memberof InputEmailComponent
   */
  @Output()
  blurred: EventEmitter<void> = new EventEmitter();

  /**
   * Creates an instance of InputEmailComponent.
   *
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof InputEmailComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   * Add an email validator reg ex
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
    const control = this.parentFormGroup.controls[this.controlName];
    control.addValidators(Validators.pattern(EMAIL_PATTERN));
  }
}

