import { InputNumberComponent } from '../../../input-number/input-number.component';
import { validationFormControlComponentCreator } from '../creators/form-control-creator.config';
import { ValidationRendererControlConfigInterface } from '../validation-component-renderer-control-config.interface';

/**
 * Control configuration for NUMBER renderer component
 *
 */
export const NUMBER_CONTROL_CONFIG: ValidationRendererControlConfigInterface = {
  component: InputNumberComponent,
  creatorFn: validationFormControlComponentCreator,
};
