import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to calculate the size of a file
 * @export
 * @class FileSizePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'filesize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  /**
   * Transform the number of bytes to file size
   * @param {number} bytes
   * @return {*}  {string}
   * @memberof FileSizePipe
   */
  transform(bytes: number): string {
    if (bytes === 0) return '0 Bytes';

    const sizeCounter = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** sizeCounter).toFixed(2))} ${this.sizes[sizeCounter]}`;
  }
}
