import { EntityAttributeFormatTypesEnum } from './format.model.enum';

/**
 * Format of type currency interace.
 *
 * @interface EntityAttributeFormatCurrencyInterface
 */
interface EntityAttributeFormatCurrencyInterface {
  attributeId: string;
  currencyCode: string | null;
  entityId: string;
  formatId: string;
  type: EntityAttributeFormatTypesEnum.Currency;
}

/**
 * Format of type decimal interface
 *
 * @interface EntityAttributeFormatDecimalInterface
 */
interface EntityAttributeFormatDecimalInterface {
  attributeId: string;
  entityId: string;
  decimalPlaces: number | null;
  formatId: string;
  type: EntityAttributeFormatTypesEnum.DecimalNumber;
}

/**
 * Entity attribute format interface.
 *
 * @property {string} attributeId The attribute ID the format belongs to.
 * @property {string} entityId The entity ID the format attribute belongs to.
 * @property {string} formatId The format's ID.
 * @export
 * @interface EntityAttributeFormatInterface
 */
export type EntityAttributeFormatInterface =
  | EntityAttributeFormatCurrencyInterface
  | EntityAttributeFormatDecimalInterface
  | {
      attributeId: string;
      entityId: string;
      formatId: string;
      type: EntityAttributeFormatTypesEnum.Percentage | EntityAttributeFormatTypesEnum.WholeNumber;
    }
  | {
      attributeId: string;
      entityId: string;
      formatId: string;
      type: null;
    };

/**
 * Type guard to check if a entity attribute format has the currencyCode property on it.
 *
 * @param {EntityAttributeFormatInterface} format The format to check if the currencyCode property is available on.
 * @return {boolean} Whether the format is of type EntityAttributeFormatCurrencyInterface.
 */
export const hasEntityAttributeFormatCurrencyProperty = (
  format: EntityAttributeFormatInterface
): format is EntityAttributeFormatCurrencyInterface => format.type === EntityAttributeFormatTypesEnum.Currency;

/**
 * Type guard to check if a entity attribute format has the decimalPlaces property on it.
 *
 * @param {EntityAttributeFormatInterface} format The format to check if the decimalPlaces property is available on.
 * @return {boolean} Whether the format is of type EntityAttributeFormatDecimalInterface.
 */
export const hasEntityAttributeFormatDecimalProperty = (
  format: EntityAttributeFormatInterface
): format is EntityAttributeFormatDecimalInterface => format.type === EntityAttributeFormatTypesEnum.DecimalNumber;

/**
 * The interface for creating a entity attribute format.
 *
 * @export
 * @interface EntityAttributeCreateFormatInterface
 */
export interface EntityAttributeCreateFormatInterface {
  entityId: string;
  attributeId: string;
}

/**
 * Interface returned whenever a format is updated.
 *
 * @property {string} formatId The format ID that was updated.
 * @export
 * @interface EntityAttributeFormatUpdatedInterface
 */
export interface EntityAttributeFormatUpdatedInterface {
  formatId: string;
}
