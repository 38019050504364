import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import type {} from 'css-font-loading-module';
import { forkJoin, from, Observable, tap } from 'rxjs';
import { LoadFontInterface } from './font.service.interface';

/**
 * Currently in our applications - feature light, stripped down versions of custom fonts are loaded via a link in the <head>:
 * <link rel="preload" href="..." as="font" type="font/woff2" crossorigin />
 * and through CSS with `@font-face`.
 *
 * This service is used to lazy load a much bigger font file with all the font features available after the inital page render.
 * https://www.zachleat.com/web/css-tricks-web-fonts/
 *
 * @export
 * @class FontService
 */
@Injectable({
  providedIn: 'root',
})
export class FontService {
  /**
   * Creates an instance of FontService.
   *
   * @param {Document} document The Document Object Model.
   * @memberof FontService
   */
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Lazy load an array of fonts and add them to the document.
   *
   * @param {LoadFontInterface[]} fonts Array of fonts to be loaded and added to the document.
   * @return {Observable<FontFace[]>} Observable array of the loaded FontFaces.
   * @memberof FontService
   */
  load(fonts: LoadFontInterface[]): Observable<FontFace[]> {
    // Create an array of FontFace loading observables
    const observables: Observable<FontFace>[] = fonts.map((font: LoadFontInterface) =>
      from(new FontFace(font.name, `url(${font.url}) format('woff2')`, font.options).load())
    );

    return forkJoin(observables).pipe(
      tap((loaded: FontFace[]) => {
        // If the browser does not support the font API it just uses the initial light weight font and font synthesis that was loaded via CSS.
        // Otherwise here we lazy load in the fully featured fonts.
        if ('fonts' in this.document) {
          loaded.forEach((font: FontFace) => this.document.fonts.add(font));
        }
      })
    );
  }
}
