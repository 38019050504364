<div class="flex justify-content-center">
  <sc-popup [closeOn]="popupCloseOnSetting" size="medium" popupClass="sc-popup-menu sc-py-8">
    <ul>
      <li *ngFor="let item of menuItems; trackBy: trackById" [ngClass]="{ 'sc-mt-8': item.footerItem }">
        <div *ngIf="item.footerItem" class="sc-popup-menu__divider sc-mb-8"></div>
        <button
          [id]="item.id"
          [disabled]="item.disabled"
          class="sc-popup-menu__item sc-font-body-text sc-text flex align-items-center sc-w-100"
          tabindex="0"
          (click)="itemClicked.emit(item.id)"
          (keydown)="itemClicked.emit(item.id)">
          <div
            class="sc-popup-menu__icon-wrapper flex justify-content-center align-items-center sc-mr-4"
            [ngClass]="item.disabled ? '' : item.colourClass">
            <sc-icon [key]="item.icon" [size]="item.size"></sc-icon>
          </div>
          {{ item.text }}
        </button>
      </li>
    </ul>
  </sc-popup>
</div>
