import { Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Observable, map, of } from 'rxjs';
import { Auth0AndGuestUserAuthServiceInterface } from './auth0-and-guest-user-auth.service.interface';
import { GuestTokenService } from './guest-token.service';

/**
 * Service for handling authentication with Auth0 and guest user functionality.
 * Wraps Auth0 functionality and adds in Guest User handling.
 *
 * All refs to Auth0 should go through here to avoid confusion.
 * @export
 * @class Auth0AndGuestUserAuthService
 */
@Injectable({
  providedIn: 'root',
})
export class Auth0AndGuestUserAuthService implements Auth0AndGuestUserAuthServiceInterface {
  constructor(private auth0Service: Auth0Service, private guestTokenService: GuestTokenService) {}

  /**
   * Observable that emits a boolean value indicating whether the user is authenticated.
   * @return {Observable<boolean>} An observable that emits a boolean.
   * @memberof Auth0AndGuestUserAuthService
   */
  isAuthenticated$ = this.auth0Service.isAuthenticated$.pipe(
    map((isAuth) => {
      // If the token is present we're are authenticated as a guest.
      if (this.guestTokenService.validTokenPresent()) {
        return true;
      }

      // Not on a guest link, so return the auth0Service's isAuthenticated$ value as normal.
      return isAuth;
    })
  );

  /**
   * Retrieves the access token silently.
   * If the user is on a guest link with a token, it returns the guest link token.
   * Otherwise, it calls the `getAccessTokenSilently` method of the `auth0Service`.
   * @return {Observable<string>} An observable that emits the access token as a string.
   * @memberof Auth0AndGuestUserAuthService
   */
  getAccessTokenSilently(): Observable<string> {
    if (this.guestTokenService.validTokenPresent()) {
      return of(this.guestTokenService.getToken());
    }
    return this.auth0Service.getAccessTokenSilently();
  }

  /**
   * Maps to auth0Service's `logout` method.
   * @memberof Auth0AndGuestUserAuthService
   */
  logout = this.auth0Service.logout.bind(this.auth0Service);

  /**
   * Maps to auth0Service's `loginWithRedirect` method.
   * @memberof Auth0AndGuestUserAuthService
   */
  loginWithRedirect = this.auth0Service.loginWithRedirect.bind(this.auth0Service);

  /**
   * Maps to auth0Service's `error$` property.
   * @memberof Auth0AndGuestUserAuthService
   */
  error$ = this.auth0Service.error$;
}
