<div
  *ngIf="task"
  class="sc-task-summary sc-mx-8 sc-px-8 sc-pt-16 sc-pb-12 design-v2-border-bottom cursor-pointer"
  (click)="select(task)"
  (keypress)="select(task)">
  <p class="sc-task-summary__status" *ngIf="task.new" title="New Task" i18n-title>
    <span i18n class="sc-visually-hidden">New Task</span>
  </p>
  <p class="sc-font-body-text sc-text sc-mb-4" *ngIf="task.description">{{ task.description }}</p>
  <p i18n class="sc-font-body-text sc-text-grey-600" *ngIf="task.date">
    Due
    <span class="design-v2-text-cerise-600 font-semibold">{{ task.date | date : 'dd/MM/yyyy' }}</span>
  </p>
  <div class="sc-pt-4" *ngIf="task.tags?.length">
    <sc-tag *ngFor="let tag of task.tags; trackBy: trackByFunction" size="md" theme="light-grey" [attr.title]="tag">
      {{ tag }}
    </sc-tag>
  </div>
</div>
