/* eslint-disable */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { LicenseManager } from 'ag-grid-enterprise';
import { CoreAppModule } from './app/core-app.module';
import { EnvironmentConfigService } from './app/services/environment-config/environment-config.service';
import { environment } from './environments/environment';

/**
 * Pre-load the Environment Config before anything else happens.
 *
 * Allows us to guarantee an environment config token to the rest of the app.
 */
const loadEnvironmentConfig = () => {
  EnvironmentConfigService.loadEnvironmentConfig()
    .then(() => {
      platformBrowserDynamic()
        .bootstrapModule(CoreAppModule)
        .catch((err) => console.error(err));
    })
    .catch((err) => {
      console.error(err);
    });
};

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-052033}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SureCloud}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Surecioud_Platform}_only_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Surecioud_Platform}_need_to_be_licensed___{Surecioud_Platform}_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_February_2025}____[v3]_[01]_MTczOTQwNDgwMDAwMA==3807fd76f5b0834aa9ec012500a4c716'
);

if (environment.production) {
  enableProdMode();
}
if (environment.mockApi) {
  import('@surecloud/mock-api').then(({ startWorker }) => {
    startWorker(environment.cypressMode).then(() => {
      loadEnvironmentConfig();
    });
  });
} else {
  loadEnvironmentConfig();
}
