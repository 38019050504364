import { EntityAttributeTypeEnum, makeGlobalSectionRendererConfig } from '@surecloud/common';
import { makeAttributeConfigComponent, makeAttributeConfigSectionId } from '../global-attribute-config.model';

/**
 * Makes the global Hierarchy attribute configuration
 */
export const GLOBAL_HIERARCHY_ATTRIBUTE_CONFIG = makeGlobalSectionRendererConfig({
  name: EntityAttributeTypeEnum.Hierarchy,
  key: EntityAttributeTypeEnum.Hierarchy,
  type: EntityAttributeTypeEnum.Hierarchy,
  label: $localize`Hierarchy`,
  uniqueSections: [
    {
      sectionComponent: makeAttributeConfigComponent('CustomHierarchy'),
      sectionId: makeAttributeConfigSectionId('custom-hierarchy'),
      sectionName: $localize`Hierarchy Properties`,
    },
  ],
});
