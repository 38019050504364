import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FileRestrictions, SelectEvent, UploadsModule } from '@progress/kendo-angular-upload';
import {
  E2eHookDirective,
  FileUploadInterface,
  ToastService,
  isArrayOfFilesOrFileInterfacesOrEmptyGuard,
  isFileGuard,
  stringToISO88591,
  trackByIndex,
} from '@surecloud/common';
import { Subject, takeUntil } from 'rxjs';
import { TextButtonComponent } from '../button/text-button.component';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';
import { FileItemComponent } from './file-item/file-item.component';
import { FileListComponent } from './file-list/file-list.component';

/**
 * Surecloud File Picker Component that wraps the [Kendo UploadComponent](https://www.telerik.com/kendo-angular-ui/components//uploads/upload/).
 * @export
 * @class FilePickerComponent
 */
@Component({
  selector: 'sc-file-picker',
  standalone: true,
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilePickerComponent),
      multi: true,
    },
    ToastService,
  ],
  imports: [
    CommonModule,
    E2eHookDirective,
    UploadsModule,
    LabelModule,
    HttpClientModule,
    InputsModule,
    TextButtonComponent,
    ReactiveFormsModule,
    FileItemComponent,
    FileListComponent,
  ],
})
export class FilePickerComponent
  extends ControlValueAccessorConnector<(FileUploadInterface | File)[] | File>
  implements OnInit, OnDestroy
{
  /**
   * Sets the text value for the label.
   * @memberof FilePickerComponent
   */
  @Input() label = $localize`File Picker`;

  /**
   * Sets the description text
   * @type {string}
   * @memberof FilePickerComponent
   */
  @Input() description = '';

  /**
   * Allow single or multiple upload files
   * @type {boolean}
   * @memberof FilePickerComponent
   */
  @Input() multiple = false;

  /**
   * Allow single or multiple upload files
   * @type {boolean}
   * @memberof FilePickerComponent
   */
  @Input() readonly = false;

  /**
   * Sets the text value for the allowed file types.
   * @memberof FilePickerComponent
   */
  @Input() allowedExtensions: string[] = [$localize`[Not specified]`];

  /**
   * The test hook to pass in.
   * @memberof FilePickerComponent
   */
  @Input() testhook!: string;

  /**
   * Emits when a file is removed.
   * @memberof FilePickerComponent
   */
  @Output() fileRemoved = new EventEmitter<FileUploadInterface>();

  /**
   * Emits when a file is download.
   * @memberof FilePickerComponent
   */
  @Output() fileDownload = new EventEmitter<FileUploadInterface>();

  /**
   * When the component is destroyed.
   * Then this observable will emit.
   * And other observables can tear down.
   * @private
   * @type {Subject<boolean>}
   * @memberof FilePickerComponent
   */
  private destroyed$: Subject<boolean> = new Subject();

  /**
   * The function we use to clean the filename of any characters we don't want.
   * @private
   * @memberof FilePickerComponent
   */
  private cleanFileName = stringToISO88591;

  /**
   * An array of allowed file types
   * @type {FileRestrictions}
   * @memberof FilePickerComponent
   */
  public fileRestrictions: FileRestrictions = {};

  /**
   * Message with list of allowed file types
   * @memberof FilePickerComponent
   */
  public filesAllowedText = '';

  /**
   * The invalid file flag
   * @memberof FilePickerComponent
   */
  invalidFile = false;

  /**
   * The temporary value to store the value of the control
   * @private
   * @type {(FileUploadInterface | File)[] | File}
   * @memberof FilePickerComponent
   */
  private temporaryValue: (FileUploadInterface | File)[] | File = [];

  /**
   * The text to show on the select button
   * @memberof FilePickerComponent
   */
  selectFileText = $localize`Select Files`;

  /**
   * Track by function for angular template loops.
   * @memberof FilePickerComponent
   */
  trackByFunction = trackByIndex;

  /**
   * Creates an instance of FilePickerComponent.
   * @param {ControlContainer} controlContainer The control container.
   * @param {ToastService} toastService The PrimeNG Message Service wrapper.
   * @memberof FilePickerComponent
   */
  constructor(@Optional() controlContainer: ControlContainer, private toastService: ToastService) {
    super(controlContainer);
  }

  /**
   * Handles the text and extensions file and empty array files
   * @memberof FilePickerComponent
   */
  ngOnInit(): void {
    this.filesAllowedText = $localize`Only ${this.allowedExtensions.toString()} files types are allowed.`;
    this.fileRestrictions = {
      allowedExtensions: this.allowedExtensions,
    };

    if (!this.control.value) this.control.setValue([], { emitEvent: false });
    this.temporaryValue = this.control.value;

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (value) {
        this.temporaryValue = value;
      } else {
        // There's a bug that sets the control value to null when filesEvent.files.length === 0 on onSelectEvent, even due we use preventDefault()
        // This is a workaround to set the value back to the temporary value.
        this.control.patchValue(this.temporaryValue, { emitEvent: false });
      }
    });
  }

  /**
   * When the component is destroyed.
   * @memberof FilePickerComponent
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * Sets file selection
   * @param {SelectEvent} fileSelectEvent The list of the selected files.
   * @memberof FilePickerComponent
   */
  public onSelectEvent(fileSelectEvent: SelectEvent): void {
    const filesEvent = fileSelectEvent;
    const duplicateFiles = this.multiple ? this.invalidFileNameList(fileSelectEvent) : [];

    if (duplicateFiles.length > 0) {
      filesEvent.files = filesEvent.files.filter((file) => !duplicateFiles.includes(file.name.toLowerCase()));
    }

    if (filesEvent.files.length === 0) {
      fileSelectEvent.preventDefault();
      return;
    }

    if (this.multiple) {
      fileSelectEvent.preventDefault();
      this.multipleSelectEvent(filesEvent);
    } else {
      this.singleSelectEvent(filesEvent);
    }
  }

  /**
   * Remove file selected
   * @param {FileUploadInterface} selectedValue - the selected value to remove
   * @memberof FilePickerComponent
   */
  public onRemoveFile(selectedValue: FileUploadInterface): void {
    const { value } = this.control;

    if (!value?.length) return;

    // Remove the file from the control so the renderer factory can update the view.
    if (!this.multiple) {
      this.control.patchValue(selectedValue, { emitEvent: true });
    }

    this.fileRemoved.emit(selectedValue);
  }

  /**
   * Add a single selected file
   * @param {SelectEvent} fileSelectEvent The selected files.
   * @memberof FilePickerComponent
   */
  private singleSelectEvent(fileSelectEvent: SelectEvent): void {
    const { extension } = fileSelectEvent.files[0];

    if (extension && this.allowedExtensions.includes(extension.toLocaleLowerCase())) {
      const { rawFile } = fileSelectEvent.files[0];
      const newValue = rawFile && new File([rawFile], this.cleanFileName(rawFile.name), { type: rawFile.type });
      this.invalidFile = false;
      this.control.setValue(newValue, { emitEvent: false });
    } else {
      this.invalidFile = true;
      this.handleInternalError(`The file type is not allowed`);
    }
  }

  /**
   * Add multiple selected files
   * @param {SelectEvent} fileSelectEvent The list of the selected files.
   * @memberof FilePickerComponent
   */
  private multipleSelectEvent(fileSelectEvent: SelectEvent): void {
    const disallowedFileExtensions: string[] = fileSelectEvent.files
      .filter((file) => !file.extension || !this.allowedExtensions.includes(file.extension.toLowerCase()))
      .map((file) => file?.extension?.toLowerCase() || '');

    if (disallowedFileExtensions.length > 0) {
      const firstMessage = $localize`The following files extensions are not allowed:`;
      this.handleInternalError(`${firstMessage} ${disallowedFileExtensions.join(', ')}.`);
    }

    const files: File[] = fileSelectEvent.files
      .filter((file) => file.extension && this.allowedExtensions.includes(file.extension.toLowerCase()))
      .map((file) => file.rawFile)
      .filter((file): file is File => isFileGuard(file))
      .map((file) => new File([file], this.cleanFileName(file.name), { type: file.type }));

    if (files.length > 0) {
      this.control.patchValue(files, { emitEvent: false });
    }
  }

  /**
   * Handles the invalid file names
   * @param {SelectEvent} fileSelectEvent The list of the selected files.
   * @return {string[]} The list of invalid file names.
   * @memberof FilePickerComponent
   */
  private invalidFileNameList(fileSelectEvent: SelectEvent): string[] {
    const { value } = this.formControl;

    if (!value) {
      return [];
    }

    if (isArrayOfFilesOrFileInterfacesOrEmptyGuard(value)) {
      return this.handleDuplicateFileListSelectEvent(fileSelectEvent, value);
    }

    if (isFileGuard(value)) {
      return this.handleDuplicateFileSelectEvent(fileSelectEvent, value);
    }

    return [];
  }

  /**
   * Handles the event of selecting duplicate files from a list.
   * @param {SelectEvent} fileSelectEvent - The event triggered when files are selected.
   * @param {(FileUploadInterface | File)[]} value - The list of files to check for duplicates.
   * @return {string[]} - The list of duplicate file names.
   */
  private handleDuplicateFileListSelectEvent(
    fileSelectEvent: SelectEvent,
    value: (FileUploadInterface | File)[]
  ): string[] {
    const selectedFiles = fileSelectEvent.files.map((file) => `${file.name.toLowerCase()}`);
    const existingFiles = value.map((file) => {
      if (isFileGuard(file)) {
        return file.name.toLowerCase();
      }
      return file.fileName.toLowerCase();
    });
    const duplicateFiles = selectedFiles.filter((file) => existingFiles.includes(file));

    if (duplicateFiles.length > 0) {
      // Handle duplicate files here
      const firstMessage = $localize`The following files are already selected:`;
      const secondMessage = $localize`Please select different files or rename them.`;
      this.handleInternalError(`${firstMessage} ${duplicateFiles.join(', ')}. ${secondMessage}`);
      return duplicateFiles;
    }
    return [];
  }

  /**
   * Handles the event of selecting a duplicate file.
   * @param {SelectEvent} fileSelectEvent - The event triggered when a file is selected.
   * @param {File} value - The file to check for duplication.
   * @return {string[]} - The list of duplicate file names.
   */
  private handleDuplicateFileSelectEvent(fileSelectEvent: SelectEvent, value: File): string[] {
    const selectedFiles = fileSelectEvent.files.map((file) => `${file.name.toLowerCase()}`);

    const duplicateFiles = selectedFiles.filter((file) => file === value.name.toLowerCase());

    if (duplicateFiles.length > 0) {
      // Handle duplicate files here
      const firstMessage = $localize`The following file is already selected:`;
      const secondMessage = $localize`Please select different file or rename it.`;
      this.handleInternalError(`${firstMessage} ${duplicateFiles.join('')}. ${secondMessage}`);
      return duplicateFiles;
    }
    return [];
  }

  /**
   * Handles internal errors by showing a notification with the error message.
   * @param {string} detail - The error message details to display.
   */
  private handleInternalError(detail: string): void {
    this.toastService.handleMessage(detail);
    this.control.setErrors({ invalid: true });
  }
}
