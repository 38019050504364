import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import {
  Auth0AndGuestUserAuthService,
  ENV_CONFIG_TOKEN,
  ENV_URL_TOKEN,
  EnvironmentConfigInterface,
  LoggerService,
} from '@surecloud/common';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { AuthOrganisationIdInterface } from '../models/auth.models';
import { apolloAuth0OrganisationIdQuery } from '../queries/auth.queries';

/**
 * Service that handles all auth functionality & wrapper for Auth0 SDK Service.
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(ENV_URL_TOKEN) private envUrl: string,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(ENV_CONFIG_TOKEN) readonly runtimeEnvConfig: EnvironmentConfigInterface,
    private logger: LoggerService,
    private auth0AndGuestUserAuthService: Auth0AndGuestUserAuthService,
    private apollo: Apollo
  ) {}

  /**
   * Wrap the Auth0 isAuthenticated$ observable to tell us if we're
   * authenticated
   * @return {Observable<boolean>} Observable streaming a boolean of whether the user is authenticated.
   * @memberof AuthService
   */
  isAuthenticated(): Observable<boolean> {
    return this.auth0AndGuestUserAuthService.isAuthenticated$;
  }

  /**
   * Take the tenant entered by the user and do a query to check if the org exists
   * @param {string} tenant The tenant name.
   * @return {Observable<string>} Apollo query result for the tenant's organisation ID.
   * @memberof AuthService
   */
  tenantLookup(tenant: string): Observable<string> {
    return this.apollo
      .query<AuthOrganisationIdInterface>(apolloAuth0OrganisationIdQuery(tenant))
      .pipe(map((result) => result.data?.auth0OrganisationId));
  }

  /**
   * Take the tenant entered by the user and do Auth0 loginWithRedirect using
   * this tenant (very tbc)
   * @param {string} orgId The tenant's organisation ID.
   * @return {Observable<void>} Observable to be worked on?
   * @memberof AuthService
   */
  tenantLogin(orgId: string): Observable<void> {
    this.initAuthErrorListener();
    this.logger.logEvent('AuthModule', 'Tenant Login, redirect', { orgId });
    return this.auth0AndGuestUserAuthService.loginWithRedirect({
      authorizationParams: {
        organization: orgId,
      },
    });
  }

  /**
   * Wrap the Auth0 SDK logout functionality
   * @memberof AuthService
   */
  logout(): void {
    this.auth0AndGuestUserAuthService.logout({
      logoutParams: {
        returnTo: this.runtimeEnvConfig.auth0.redirectUri,
      },
    });
  }

  /**
   * Placeholder listener for Auth0 SDK errors
   * @private
   * @memberof AuthService
   */
  private initAuthErrorListener(): void {
    this.auth0AndGuestUserAuthService.error$.subscribe();
  }
}
