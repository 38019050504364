// DiagramGroup
export interface ParentElementInterface {
  name: string;
  parentId: string;
  children: ChildElementInterface[];
}

// DiagramStep
export interface ChildElementInterface {
  name: string;
  childId: string;
  connection: ConnectionInterface[];
}

// DiagramNode
export interface NodeInterface {
  nodeId: string;
}

// DiagramConnection
export interface ConnectionInterface {
  connectionId: string;
  destination: string;
  label: string | null;
}

// DiagramLine
export interface LineInterface {
  source: PortInterface;
  target: PortInterface;
  rowIndex: number | null;
  isForward: boolean;
  distanceByIndex: number;
  label: string | null;
  linkId: string;
}

// DiagramStepPort
export interface PortInterface {
  nodeId: string;
  portId: PortNameEnum | null;
}
// DiagramStepPortName
export enum PortNameEnum {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
}
