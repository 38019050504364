import { Injectable } from '@angular/core';
import {
  EntityPermissionCreateInputApiInterface,
  EntityPermissionCreatePayloadApiInterface,
  EntityPermissionDeleteInputApiInterface,
  EntityPermissionDeletePayloadApiInterface,
  EntityPermissionUpdateInputApiInterface,
  EntityPermissionUpdatePayloadApiInterface,
} from '@surecloud/api-types';
import { Apollo } from 'apollo-angular';
import { Observable, of, switchMap, throwError } from 'rxjs';
import {
  EntityPermissionCreateInputInterface,
  EntityPermissionDeleteInputInterface,
  EntityPermissionInterface,
  makeEntityPermission,
} from '../../../+state/models/permission/permission-entity/permission-entity.models';
import {
  apolloEntityPermissionCreateMutation,
  apolloEntityPermissionDeleteMutation,
  apolloEntityPermissionUpdateMutation,
} from '../../../mutations/permission/permission-entity/permission-entity.mutations';

export const ENTITY_PERMISSION_CREATE_ERROR = 'No response data from create entity permission returned from API.';
export const ENTITY_PERMISSION_DELETE_ERROR = 'No response data from delete entity permission returned from API.';
export const ENTITY_PERMISSION_UPDATE_ERROR = 'No response data from update entity permission returned from API.';

/**
 * Entity Permission API service.
 */
@Injectable({
  providedIn: 'root',
})
export class EntityPermissionService {
  /**
   * Creates an instance of EntityPermissionService.
   * @param {Apollo} apollo The Apollo service.
   * @memberof EntityPermissionService
   */
  constructor(private readonly apollo: Apollo) {}

  /**
   * Create a new entity permission.
   * @param {EntityPermissionCreateInputInterface} entityPermissionCreate The input to create a new entity permission.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityPermissionInterface>} The created entity permission
   * @memberof EntityPermissionService
   */
  create(entityPermissionCreate: EntityPermissionCreateInputInterface, requestId: string | null = null): Observable<EntityPermissionInterface> {
    const entityPermissionCreateInput: EntityPermissionCreateInputApiInterface = {
      ...entityPermissionCreate,
    };

    return this.apollo
      .mutate<{ entityPermissionCreate: EntityPermissionCreatePayloadApiInterface }>(
        apolloEntityPermissionCreateMutation(entityPermissionCreateInput, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityPermissionCreate?.createdId
            ? of(makeEntityPermission(data.entityPermissionCreate.createdId, entityPermissionCreate.entityId))
            : throwError(() => ENTITY_PERMISSION_CREATE_ERROR)
        )
      );
  }

  /**
   * Delete an entity permission.
   * @param {EntityPermissionDeleteInputInterface} entityPermissionDelete The input to delete an entity permission.
   * @return {Observable<{entityPermissionId: string}>} The deleted entity permission ID.
   * @memberof EntityPermissionService
   */
  delete(entityPermissionDelete: EntityPermissionDeleteInputInterface): Observable<{ entityPermissionId: string }> {
    const entityPermissionDeleteInput: EntityPermissionDeleteInputApiInterface = {
      ...entityPermissionDelete,
    };

    return this.apollo
      .mutate<{ entityPermissionDelete: EntityPermissionDeletePayloadApiInterface }>(
        apolloEntityPermissionDeleteMutation(entityPermissionDeleteInput)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityPermissionDelete?.deletedId
            ? of({ entityPermissionId: data.entityPermissionDelete.deletedId })
            : throwError(() => ENTITY_PERMISSION_DELETE_ERROR)
        )
      );
  }

  /**
   * Update an entity permission.
   * @param {EntityPermissionInterface} entityPermission The input to update an entity permission.
   * @param {string | null} requestId The requestId.
   * @return {Observable<EntityPermissionInterface>} The updated entity permission.
   * @memberof EntityPermissionService
   */
  update(entityPermission: EntityPermissionInterface, requestId: string | null = null): Observable<EntityPermissionInterface> {
    const { role, ...apiEntityRolePermission } = entityPermission;
    const entityPermissionUpdateInput: EntityPermissionUpdateInputApiInterface = {
      ...apiEntityRolePermission,
      roleId: role?.roleId,
    };
    return this.apollo
      .mutate<{ entityPermissionUpdate: EntityPermissionUpdatePayloadApiInterface }>(
        apolloEntityPermissionUpdateMutation(entityPermissionUpdateInput, requestId)
      )
      .pipe(
        switchMap(({ data }) =>
          data?.entityPermissionUpdate ? of({ ...entityPermission }) : throwError(() => ENTITY_PERMISSION_UPDATE_ERROR)
        )
      );
  }
}
