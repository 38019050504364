import { Injectable } from '@angular/core';
import { UserGroupsApiInterfaceQueryService } from '@surecloud/api-types';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { removeNullOrUndefinedArrayItems } from '../../classes/remove-null-or-undefined-array-items';
import { EntityAttributeUserModeTypeEnum } from '../../models/entity/attribute/attribute.model.enum';
import { isUserModeApiEnum } from './is-user-mode-api.guard';
import { NormaliseGetUserGroupsService } from './normalise-get-user-groups/normalise-get-user-groups.service';
import { NormalisedUserGroupsList } from './normalise-get-user-groups/normalise-get-user-groups.validation';

export const USER_GROUPS_READ_ALL_ERROR = 'No response data from read all User Groups returned from API.';
export const USER_GROUPS_FILTER_ERROR = 'The user group filter does not match the expected format.';

/**
 * User Groups API service.
 * @export
 * @class UserService
 */
@Injectable({
  providedIn: 'root',
})
export class UserGroupsService {
  /**
   * Creates an instance of UserGroupsService.
   * @param {UserGroupsApiInterfaceQueryService} userGroupsApiInterfaceQueryService The service that fetch the User Groups
   * @param {NormaliseGetUserGroupsService} normaliseGetUserGroupsService The user group data flattening service.
   * @memberof UserGroupsService
   */
  constructor(
    private readonly userGroupsApiInterfaceQueryService: UserGroupsApiInterfaceQueryService,
    private normaliseGetUserGroupsService: NormaliseGetUserGroupsService
  ) {}

  /**
   * Query to get all User Groups.
   * @param {string} query the user group query
   * @param {EntityAttributeUserModeTypeEnum | null} filter the user group mode filter
   * @return {Observable<NormalisedUserGroupsList>} The User Group normalised data.
   * @memberof UserGroupsService
   */
  readAll(
    query: string,
    filter: EntityAttributeUserModeTypeEnum | null,
  ): Observable<NormalisedUserGroupsList> {
    if (isUserModeApiEnum(filter)) {
      return this.userGroupsApiInterfaceQueryService
        .fetch({ userGroupsInput: { query, filter } })
        .pipe(
          switchMap(({ data }) =>
            data?.userGroups
              ? of(
                  this.normaliseGetUserGroupsService.extract(
                    removeNullOrUndefinedArrayItems(data.userGroups.userGroups)
                  )
                )
              : throwError(() => USER_GROUPS_READ_ALL_ERROR)
          )
        );
    }
    return throwError(() => USER_GROUPS_FILTER_ERROR);
  }
}
