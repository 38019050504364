import { createFeatureSelector, createSelector } from '@ngrx/store';
import {} from '../../models/rule-action.models';
import { RULE_ACTION_FEATURE_KEY, StateInterface, ruleActionAdapter } from './rule-action.reducer';
// Lookup the 'Rule Action' feature state managed by NgRx
export const getRuleActionState = createFeatureSelector<StateInterface>(RULE_ACTION_FEATURE_KEY);

const { selectAll, selectEntities } = ruleActionAdapter.getSelectors();

/**
 * Get all rule actions.
 */
export const getRuleActionList = createSelector(getRuleActionState, (state: StateInterface) => selectAll(state));
export const getRuleActionLoaded = createSelector(getRuleActionState, (state: StateInterface) => state.loaded);
export const getRuleActionError = createSelector(getRuleActionState, (state: StateInterface) => state.error);

/**
 * Get rule action entities.
 */
export const getRuleActionEntities = createSelector(getRuleActionState, (state: StateInterface) =>
  selectEntities(state)
);

/**
 * Get Selected id of rule action.
 */
export const getRuleActionSelectedId = createSelector(getRuleActionState, (state: StateInterface) => state.selectedId);

/**
 * Get Selected rule action.
 */
export const getSelectedRuleAction = createSelector(
  getRuleActionEntities,
  getRuleActionSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

/**
 * Get Selected rule action.
 */
export const getCanRecur = createSelector(getSelectedRuleAction, (action) => action?.canRecur);
