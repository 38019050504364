import { QuestionAttributeTypeApiInterface } from '@surecloud/api-types';

/**
 * Question enum value type.
 */
export type QuestionAttributeTypeEnumType =
  | QuestionAttributeTypeApiInterface.DateApiInterface
  | QuestionAttributeTypeApiInterface.DocumentApiInterface
  | QuestionAttributeTypeApiInterface.NumberApiInterface
  | QuestionAttributeTypeApiInterface.OptionListApiInterface
  | QuestionAttributeTypeApiInterface.TextApiInterface
  | QuestionAttributeTypeApiInterface.ToggleApiInterface;

/**
 * Question enum definition
 * @export
 * @enum {string}
 */
export const QuestionAttributeTypeEnum: { [property: string]: QuestionAttributeTypeEnumType } = {
  Date: QuestionAttributeTypeApiInterface.DateApiInterface,
  Document: QuestionAttributeTypeApiInterface.DocumentApiInterface,
  Number: QuestionAttributeTypeApiInterface.NumberApiInterface,
  OptionList: QuestionAttributeTypeApiInterface.OptionListApiInterface,
  Text: QuestionAttributeTypeApiInterface.TextApiInterface,
  Toggle: QuestionAttributeTypeApiInterface.ToggleApiInterface,
};

/**
 * Question enum label definition
 * @export
 * @enum {string}
 */
export const QuestionAttributeTypeLabelEnum: { [property: keyof typeof QuestionAttributeTypeEnum]: string } = {
  Date: $localize`Date`,
  Document: $localize`Document`,
  Number: $localize`Number`,
  OptionList: $localize`Option List`,
  Text: $localize`Text`,
  Toggle: $localize`Toggle`,
};
