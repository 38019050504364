import { CanActivateCoreGuard } from '@surecloud/auth';
import {
  ApplicationRouteInterface,
  CommonRoutesEnum,
  makeUUID,
  NavigationLinkLabels,
  PageTitleType,
  StandaloneRouteNamesEnum,
} from '@surecloud/common';
import { StandaloneApplicationListComponent } from './application-list/application-list.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomepageComponent } from './homepage/homepage.component';
import { StandaloneRecordListComponent } from './record-list/standalone-record-list.component';

export const STANDALONE_HOME_ROUTE: ApplicationRouteInterface = {
  canActivate: [CanActivateCoreGuard],
  path: StandaloneRouteNamesEnum.Home,
  pathMatch: 'full',
  component: HomepageComponent,
  title: $localize`Homepage - Dashboard` as PageTitleType,
  data: {
    breadcrumbData: [],
  },
};

export const STANDALONE_ERROR_ROUTE: ApplicationRouteInterface = {
  path: StandaloneRouteNamesEnum.Error,
  pathMatch: 'full',
  component: ErrorPageComponent,
  title: $localize`Error` as PageTitleType,
  data: {
    breadcrumbData: [
      {
        id: makeUUID(),
        label: $localize`Error`,
      },
    ],
  },
};

export const STANDALONE_RECORD_LIST_ROUTE: ApplicationRouteInterface = {
  path: `${StandaloneRouteNamesEnum.RecordList}/:${CommonRoutesEnum.EntityIdForRecords}`,
  canActivate: [CanActivateCoreGuard],
  pathMatch: 'full',
  component: StandaloneRecordListComponent,
  data: {
    activeRouteLabel: NavigationLinkLabels.MyApplications,
  },
};

export const STANDALONE_APPLICATION_LIST_ROUTE: ApplicationRouteInterface = {
  path: CommonRoutesEnum.ApplicationList,
  canActivate: [CanActivateCoreGuard],
  pathMatch: 'full',
  component: StandaloneApplicationListComponent,
  data: {
    activeRouteLabel: NavigationLinkLabels.MyApplications,
  },
};
