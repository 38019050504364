import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';

/**
 * Wrapper component around the [Kendo UI Checkbox](https://www.telerik.com/kendo-angular-ui/components/inputs/checkbox/).
 * @export
 * @class CheckboxComponent
 * @extends {ControlValueAccessorConnector<boolean>}
 */
@Component({
  selector: 'sc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputsModule, LabelModule, E2eHookDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends ControlValueAccessorConnector<boolean> {
  /**
   * Sets the text value for the label.
   * @memberof InputTextComponent
   */
  @Input() label = '';

  /**
   * Automation testhook to add to the checkbox component.
   * @memberof SelectHierarchyComponent
   */
  @Input()
  public testhook = 'checkbox';
}
