import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlContainer, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { E2eHookDirective } from '@surecloud/common';
import { IconButtonComponent } from '../button/icon-button.component';
import { DeleteButtonComponent } from '../delete-button/delete-button.component';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';
import { SingleLinkOptionInterface } from './single-link.component.interface';

@Component({
  selector: 'sc-single-link',
  templateUrl: './single-link.component.html',
  styleUrls: ['./single-link.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconButtonComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LabelModule,
    DropDownListModule,
    InputsModule,
    E2eHookDirective,
    CommonIconModule,
    DeleteButtonComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleLinkComponent),
      multi: true,
    },
  ],
})
export class SingleLinkComponent<
  T = string
> extends ControlValueAccessorConnector<SingleLinkOptionInterface<T> | null> {
  /**
   * Text to display an additional description to the user.
   * @memberof SingleLinkComponent
   */
  @Input() description = '';

  /**
   * Sets the text value for the label.
   * @memberof SingleLinkComponent
   */
  @Input() label = '';

  /**
   * Options for the dropdown.
   * There will only ever be single options for the dropdown and this is the selected value.
   * @memberof SingleLinkComponent
   */
  @Input() options: SingleLinkOptionInterface<T>[] = [];

  /**
   * Sets the placeholder value for the input.
   * @memberof SingleLinkComponent
   */
  @Input() placeholder = '';

  /**
   * If the input is readonly
   * @memberof SingleLinkComponent
   */
  @Input() readonly = false;

  /**
   * Sets a px width for the input. If this is not set the width will be set to 100% by default from styles.
   * @memberof SingleLinkComponent
   */
  @Input() staticWidth: string | undefined;

  /**
   * The test hook to pass in.
   * @memberof SingleLinkComponent
   */
  @Input() testhook = 'single-link';

  /**
   * Emits when the user clicks the link button.
   * @memberof SingleLinkComponent
   */
  @Output() clicked = new EventEmitter<void>();

  /**
   * Emits when the user the delete button.
   * @memberof SingleLinkComponent
   */
  @Output() deleted = new EventEmitter<void>();

  /**
   * Emits when the user clicks the navigate button.
   * @memberof SingleLinkComponent
   */
  @Output() navigate = new EventEmitter<string>();

  /**
   * Creates an instance of SingleLinkComponent.
   * @param { ControlContainer } controlContainer The control container.
   * @memberof SingleLinkComponent
   */
  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
