<div [class]="classes">
  <span>
    <ng-content></ng-content>
  </span>
  <sc-icon
    *ngIf="isRemovable"
    class="align-self-start sc-tags__icon sc-tags__icon--{{ size }}"
    key="close"
    (click)="remove.emit()"></sc-icon>
</div>
