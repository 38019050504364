import { DirectionInterface } from '../../calculation/calculation.type';

/**
 * Maps the EntityAttributeCalculationTrendDirectionEnum to the DirectionInterface
 *
 * @param {string | null} direction the trend direction of the trend
 * @return {DirectionInterface} the direction enum
 */
export const getDirection = (direction: DirectionInterface | null): DirectionInterface => {
  switch (direction) {
    case 'DECREASED':
      return 'DECREASED';
    case 'INCREASED':
      return 'INCREASED';
    default:
      return 'STABLE';
  }
};
