import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  DateInputSize,
  DatePickerModule,
  DatePickerComponent as KendoDatePickerComponent,
} from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlService } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { isDateGuard, isStringGuard } from '@surecloud/common';
import { CommonIconModule } from '../icon/icons/common-icon.module';
import { ControlValueAccessorConnector } from '../utils/classes/control-value-accessor';

/**
 * Wrapper component around the [Kendo UI DatePicker](https://www.telerik.com/kendo-angular-ui/components/dateinputs/datepicker/).
 * @export
 * @class DatePickerComponent
 * @extends {ControlValueAccessorConnector<string>}
 */
@Component({
  selector: 'sc-date-picker',
  standalone: true,
  imports: [CommonModule, InputsModule, LabelModule, ReactiveFormsModule, DatePickerModule, CommonIconModule],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent extends ControlValueAccessorConnector<string> implements OnInit {
  /**
   * The integrated Kendo DatePicker component
   * so that we can control its toggling functionality from within the custom DatePickerComponent.
   * @private
   * @type {KendoDatePickerComponent}
   * @memberof DatePickerComponent
   */
  @ViewChild('datePicker', { static: true }) public datePicker!: KendoDatePickerComponent;

  /**
   * The date format to use in the component.
   * @memberof DatePickerComponent
   */
  @Input() format = 'dd / MM / yyyy';

  /**
   * The date format in the placeholder. It is visible upon clicking into the input field.
   * @memberof DatePickerComponent
   */
  @Input() formatPlaceholder = {
    year: 'YYYY',
    month: 'MM',
    day: 'DD',
    hour: 'h',
    minute: 'm',
    second: 's',
    millisecond: 'S',
  };

  /**
   *  Sets if we want a success validation style to display.
   * @memberof DatePickerComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * The form control name if using a reactive form.
   * @memberof DatePickerComponent
   */
  @Input() controlName = '';

  /**
   * Text to display an additional description to the user.
   * @memberof DatePickerComponent
   */
  @Input() description = '';

  /**
   * The label text for the input.
   * @memberof DatePickerComponent
   */
  @Input() label = '';

  /**
   * The placeholder text for the input.
   * @memberof DatePickerComponent
   */
  @Input() placeholder = 'DD / MM / YYYY';

  /**
   * Set the input to readonly mode.
   * @memberof DatePickerComponent
   */
  @Input() readonly = false;

  /**
   * Sets if we want the usual form margin bottom on the form field.
   * @memberof DatePickerComponent
   */
  @Input() marginBottom = false;

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   * @type {string}
   * @memberof DatePickerComponent
   */
  @Input() staticWidth!: string;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   * @type {FormGroup}
   * @memberof DatePickerComponent
   */
  @Input() parentFormGroup!: FormGroup;

  /**
   * The kendo sizing for the date picker input.
   * @type {DateInputSize}
   * @memberof DatePickerComponent
   */
  public size: DateInputSize = 'none';

  /**
   * The date value being provided to the Kendo DatePicker component.
   * @type {Date}
   * @memberof DatePickerComponent
   */
  public datePickerValue: Date | null = null;

  /**
   * Creates an instance of DatePickerComponent.
   * @param {ControlContainer} controlContainer The control container.
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @param {IntlService} intl The Kendo Angular [IntlService](https://www.telerik.com/kendo-angular-ui/components/intl/service/) used below for date formatting.
   * @memberof DatePickerComponent
   */
  constructor(
    @Optional() controlContainer: ControlContainer,
    private formGroupDirective: FormGroupDirective,
    private intl: IntlService
  ) {
    super(controlContainer);
  }

  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;

    // The Kendo Date Picker only takes a Date value instance for the component.
    // So convert the parent forms string value to a Date instance and pass it to the Kendo Date Picker.
    // This avoids have to convert it to a Date everywhere in the application this component is used.
    const formValue = this.control.value;
    this.datePickerValue = formValue && isStringGuard(formValue) ? this.intl.parseDate(formValue) : null;
  }

  /**
   * When the value of the date has changed.
   * Update the top level form with the changes.
   * @param {Date | null} date The updated date value.
   * @memberof DatePickerComponent
   */
  handleDateChange(date: Date | null): void {
    const apiDateFormat = 'yyyy-MM-dd';
    const newDate: string | null = date && isDateGuard(date) ? this.intl.toString(date, apiDateFormat) : null;
    this.control.setValue(newDate);
  }

  /**
   * Open/close the calendar of the Kendo component through the custom Datepicker instance.
   * @param {boolean} value The updated date value.
   * @memberof DatePickerComponent
   */
  toggleCalendar(value: boolean): void {
    this.datePicker.toggle(value);
  }
}
