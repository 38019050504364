import { AuthClientConfig as Auth0ClientConfig, AuthConfig as Auth0Config } from '@auth0/auth0-angular';
import { EnvironmentConfigInterface } from '@surecloud/common';
/**
 * Configure the Auth0 module dynamically
 * @param {EnvironmentConfigInterface} envConfig - the environment config
 * @param {Auth0ClientConfig} authClientConfig = auth0 config service
 * @return {Promise<unknown>}
 */
export const initialiseAuth0Factory =
  (envConfig: EnvironmentConfigInterface, authClientConfig: Auth0ClientConfig): (() => Promise<void>) =>
  () =>
    new Promise<void>((resolve) => {
      const authConfig: Auth0Config = {
        ...envConfig.auth0,
        authorizationParams: {
          redirect_uri: envConfig.auth0.redirectUri,
          audience: envConfig.auth0.audience,
        },
      };

      authClientConfig.set(authConfig);
      resolve();
    });
