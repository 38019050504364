<div class="sc-basic-grid">
  <ag-grid-angular
    *ngIf="rowData"
    class="{{ theme }} ag-theme-sc-grid sc-scrollbar"
    [ngClass]="{ 'ag-theme-sc-grid--footer': footer }"
    [ngStyle]="{ 'height.px': height }"
    [headerHeight]="sizeConfig.headerHeight"
    [rowHeight]="sizeConfig.rowHeight"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColumnDef"
    [columnTypes]="columnTypes"
    [quickFilterText]="quickFilterText"
    [rowDragManaged]="rowDrag"
    [rowSelection]="rowSelection"
    [suppressRowClickSelection]="true"
    [suppressColumnVirtualisation]="true"
    [suppressScrollOnNewData]="suppressScrollOnNewData"
    [context]="context"
    [processRowPostCreate]="processRowPostCreateGrid"
    [overlayLoadingTemplate]="loadingOverlayTemplate"
    [suppressNoRowsOverlay]="showLoadingState"
    [defaultExcelExportParams]="defaultExcelExportParams"
    [excelStyles]="excelStyles"
    [pagination]="isPaginationEnabled"
    [suppressPaginationPanel]="isPaginationEnabled"
    (filterChanged)="filterChanged.emit($event)"
    (sortChanged)="sortChanged.emit($event)"
    (paginationChanged)="paginationChanged.emit($event)"
    (selectionChanged)="selectionChanged.emit($event)"
    (gridReady)="onGridReady($event)"
    (cellEditRequest)="onCellEditRequest($event)"
    (cellClicked)="cellClicked.emit($event)"
    (cellValueChanged)="cellValueChanged.emit($event)"
    (gridSizeChanged)="resizeGrid($event)"
    (rowDragMove)="onRowDragMove($event)"
    (rowDragEnd)="onRowDragEnd($event)">
  </ag-grid-angular>
  <div *ngIf="readonly" class="sc-basic-grid__readonly-overlay"></div>
  <span
    i18n
    *ngIf="!rowDataLength && isLoaded"
    class="sc-overlay-no-rows-center flex flex-column justify-content-center align-items-center"
    data-testid="no-group-rows-loaded"
    i18n-data-testid>
    <sc-icon class="sc-mb-16" [key]="'no-rows'" [height]="111" [width]="140"></sc-icon>
    <p class="sc-font-heading-md-semi-bold sc-text-brand-500 sc-mb-8">No rows to show</p>
    <p class="sc-font-information-body sc-text-lighter">{{ noResultsMessage }}</p>
  </span>
  <span
    i18n
    *ngIf="!!rowDataLength && isLoaded"
    class="sc-overlay-rows-loaded-center"
    data-testid="group-rows-loaded"
    i18n-data-testid
    >Rows loaded</span
  >
</div>
