<div class="sc-advanced-grid-pagination flex align-items-center">
  <p i18n>Page Size:</p>
  <sc-select-dropdown
    [options]="pageSizeOptions"
    [parentFormGroup]="pageSizeFormGroup"
    staticWidth="58"
    staticHeight="28"
    [controlName]="'pageSize'"
    class="sc-advanced-grid-pagination__page-size"
    [canClear]="false">
  </sc-select-dropdown>

  <p i18n class="sc-advanced-grid-pagination__info">
    {{ paginationInfo }}
  </p>

  <sc-icon-button
    (click)="goToFirstPage.emit()"
    [disabled]="currentPage === 1"
    icon="double-arrow-left"
    themeColour="white"
    size="small"
    shape="rounded"
    class="sc-advanced-grid-pagination__first-page-btn"></sc-icon-button>
  <sc-icon-button
    (click)="goToPreviousPage.emit()"
    [disabled]="currentPage === 1"
    icon="chevron-left"
    themeColour="white"
    size="small"
    shape="rounded"
    class="sc-advanced-grid-pagination__previous-page-btn"></sc-icon-button>

  <div class="sc-advanced-grid-pagination__pages flex justify-content-center">
    <ng-container *ngFor="let pageNumber of pageNumbers; let i = index; trackBy: trackByIndex">
      <p
        (click)="goToPage(pageNumber, i)"
        (keydown.enter)="goToPage(pageNumber, i)"
        [class.sc-advanced-grid-pagination__pages--active]="pageNumber === currentPage"
        [class.sc-cursor-pointer]="pageNumber !== ''"
        tabindex="0"
        class="page-number">
        {{ pageNumber }}
      </p>
    </ng-container>
  </div>
  <sc-icon-button
    (click)="goToNextPage.emit()"
    [disabled]="endIndex === totalItems"
    icon="chevron-right"
    themeColour="white"
    size="small"
    shape="rounded"
    class="sc-advanced-grid-pagination__next-page-btn"></sc-icon-button>
  <sc-icon-button
    (click)="goToLastPage.emit()"
    [disabled]="endIndex === totalItems"
    icon="double-arrow-right"
    themeColour="white"
    size="small"
    shape="rounded"
    class="sc-advanced-grid-pagination__last-page-btn"></sc-icon-button>
</div>
