import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  GridStateInterface,
  NAVIGATE_ROW_COLUMN,
  PaginationTypeEnum,
  RecordColumnAttributeInterface,
  RecordGridInputInterface,
  RecordInterface,
  SCGridSchemaTypeEnum,
  SELECT_ROW_COLUMN,
  TabInterface,
} from '@surecloud/common';
import { CellClickedEvent, CellValueChangedEvent, ColDef } from 'ag-grid-community';

import { LoadingButtonStateInterface } from '../../../button/loading-button-state.interface';
import { AdvancedGridComponent } from '../../advanced-grid/advanced-grid.component';
import { AdvancedGridButtonType } from '../../advanced-grid/button/advanced-grid-button.component';
import { GridCheckboxRendererComponent } from '../../renderers/checkbox/grid-checkbox-renderer.component';
import { filterOutSelectAndNavigateColumnTypes } from './filter-select-and-navigate-row-types';
import { parseRecordColumnType } from './parse-record-column-type';
import { isRecordDataMultipleCellInterface, isRecordDataSingleCellInterface } from './record-grid-guards';
import { RecordGridRowInterface } from './record-grid.model';

/**
 * Surecloud record grid wrapper around sureCloud advanced grid.
 * @export
 * @class RecordGridComponent
 */
@Component({
  selector: 'sc-record-grid',
  templateUrl: './record-grid.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AdvancedGridComponent],
})
export class RecordGridComponent implements OnChanges {
  /**
   * Set this if we want to display the grid but remove functionality so it can be previewed.
   * @memberof RecordGridComponent
   */
  @Input() readonly = false;

  /**
   * The height of the table.
   * @type {number}
   * @memberof RecordGridComponent
   */
  @Input() height = 300;

  /**
   * The type of row selection, singular or multiple
   * @type {('single' | 'multiple' | undefined)}
   * @memberof RecordGridComponent
   */
  @Input() rowSelection: 'single' | 'multiple' | undefined = 'single';

  /**
   * Filter string to use to restrict data
   * @type {string|null}
   * @memberof RecordGridComponent
   */
  @Input() quickFilterText = '';

  /**
   * Should the grid NOT scroll to the top when new data has been input.
   * @memberof RecordGridComponent
   */
  @Input() suppressScrollOnNewData = true;

  /**
   * Array of tabs.
   * @type {(TabInterface[]  | undefined)}
   * @memberof RecordGridComponent
   */
  @Input() tabs: TabInterface[] | undefined;

  /**
   * The name of the Primary Button
   * @memberof RecordGridComponent
   */
  @Input() primaryButtonName = '';

  /**
   * The name of the Secondary Button
   * @memberof RecordGridComponent
   */
  @Input() secondaryButtonName = '';

  /**
   * Show the Primary Button.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof RecordGridComponent
   */
  @Input() showPrimaryButton: boolean | null = true;

  /**
   * Show the Secondary Button.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof RecordGridComponent
   */
  @Input() showSecondaryButton: boolean | null = false;

  /**
   * The type of the primary button.
   * @type {AdvancedGridButtonType}
   * @memberof RecordGridComponent
   */
  @Input() primaryButtonType: AdvancedGridButtonType = 'text';

  /**
   * The type of the secondary button.
   * @type {AdvancedGridButtonType}
   * @memberof RecordGridComponent
   */
  @Input() secondaryButtonType: AdvancedGridButtonType = 'text';

  /**
   * The state of the primary button if it's loading type, should be undefined otherwise
   * @type {LoadingButtonStateInterface}
   * @memberof RecordGridComponent
   */
  @Input() primaryLoadingButtonState?: LoadingButtonStateInterface;

  /**
   * The state of the secondary button if it's loading type, should be undefined otherwise
   * @type {LoadingButtonStateInterface}
   * @memberof RecordGridComponent
   */
  @Input() secondaryLoadingButtonState?: LoadingButtonStateInterface;

  /**
   * Show delete button
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof RecordGridComponent
   */
  @Input() canDelete: boolean | null = true;

  /**
   * Show import controls
   * @type {(boolean | null)}
   * @memberof RecordGridComponent
   */
  @Input() canImport: boolean | null = false;

  /**
   * Show export controls
   * Default is true because we assume if you can see the grid, then you have read permission and export.
   * @type {boolean | null} - handle null to allow passing of observable with strict null checking.
   * @memberof RecordGridComponent
   */
  @Input() canExport: boolean | null = false;

  /**
   * The name to add into the title of the exported file.
   * example: 'aurora_[exportName]_export_2021-01-01.xlsx'
   */
  @Input() exportName = '';

  /**
   * Provide a parent context to the grid.
   * @type {{ componentParent: unknown }}
   * @memberof RecordGridComponent
   */
  @Input() context: { componentParent: unknown } = { componentParent: null };

  /**
   * Get record grid data
   * @param {RecordGridInputInterface} recordGridInput data
   * @memberof RecordGridComponent
   */
  @Input() gridRecordData!: RecordGridInputInterface | null;

  /**
   * Show the row select column on every row.
   * @memberof RecordGridComponent
   */
  @Input() selectableRows = false;

  /**
   * Show the Navigation column on every row.
   * @memberof RecordGridComponent
   */
  @Input() navigableRows = false;

  /**
   * Enable/Disabled row checkbox by permission.
   * @memberof RecordGridComponent
   */
  @Input() addRowsPermission = true;

  /**
   * Two way data bind the active tab between this component and it's parent.
   * @type {number}
   * @memberof RecordGridComponent
   */
  @Input() activeTab: number | undefined;

  /**
   * Can select all rows
   * @type {boolean}
   * @memberof RecordGridComponent
   */
  @Input() canSelectAll = false;

  /**
   * Pagination type enables pagination on the grid
   * @type {PaginationTypeEnum}
   * @memberof RecordGridComponent
   */
  @Input() paginationType: PaginationTypeEnum | null = null;

  /**
   * Emit the active tab change event.
   * @type {EventEmitter<number>}
   * @memberof RecordGridComponent
   */
  @Output() activeTabChange = new EventEmitter<number>();

  /**
   * Handles the cell edited event
   * @memberof RecordGridComponent
   */
  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();

  /**
   * Handles the clicked from the primary button
   * @memberof RecordGridComponent
   */
  @Output() primaryButtonClicked = new EventEmitter();

  /**
   * Handles the clicked from the secondary button
   * @memberof RecordGridComponent
   */
  @Output() secondaryButtonClicked = new EventEmitter();

  /**
   * Handles the rows deleted event
   * @memberof RecordGridComponent
   */
  @Output() rowsDeleted = new EventEmitter<unknown[]>();

  /**
   * Handles the row selection change event
   * @memberof RecordGridComponent
   */
  @Output() selectionChanged = new EventEmitter<unknown[]>();

  /**
   * Handles the cell clicked event
   * @memberof RecordGridComponent
   */
  @Output() cellClicked = new EventEmitter<CellClickedEvent>();

  /**
   * Handles the export all event
   * @memberof RecordGridComponent
   */
  @Output() exportAll = new EventEmitter<void>();

  /**
   * Handles the export selection event
   * @memberof RecordGridComponent
   */
  @Output() exportSelection = new EventEmitter<unknown[]>();

  /**
   * Handles the import clicked event
   * @memberof RecordGridComponent
   */
  @Output() importClicked = new EventEmitter<void>();

  /**
   * Handles the grid state change event
   * @memberof RecordGridComponent
   */
  @Output() gridStateChanged = new EventEmitter<GridStateInterface>();

  /**
   * The data source
   * @type {RecordGridRowInterface[]}
   * @memberof RecordGridComponent
   */
  rowData: RecordGridRowInterface[] = [];

  /**
   * Array of columns to display and their definitions
   * @type {(ColDef<RecordColumnAttributeInterface[]>[])}
   * @memberof RecordGridComponent
   */
  columnDefs: ColDef<RecordColumnAttributeInterface[]>[] = [];

  /**
   * Handle changes to the Input data.
   * @return {void}
   */
  ngOnChanges(): void {
    this.setColumns();
    this.setRows();
  }

  /**
   *
   * Set columns based on columnAttributes property
   * @memberof RecordGridComponent
   */
  public setColumns(): void {
    const colDefs: ColDef[] = [];

    if (this.selectableRows && !this.canSelectAll) {
      colDefs.push({
        field: SELECT_ROW_COLUMN,
        type: SCGridSchemaTypeEnum.CheckboxEditable,
        headerName: '',
      });
    }

    if (this.selectableRows && this.canSelectAll) {
      colDefs.push({
        field: SELECT_ROW_COLUMN,
        type: SCGridSchemaTypeEnum.HeaderSelect,
        hide: false,
        editable: false,
        headerComponent: GridCheckboxRendererComponent,
      });
    }

    if (this.navigableRows) {
      colDefs.push({
        field: NAVIGATE_ROW_COLUMN,
        type: SCGridSchemaTypeEnum.Navigate,
        headerName: '',
      });
    }

    if (!this.gridRecordData) return;

    this.gridRecordData.columnAttributes.forEach(({ attributeId, name, type, canBeMultiple }) => {
      const columnType = parseRecordColumnType(type);
      colDefs.push({
        colId: attributeId,
        field: name,
        headerName: name,
        type:
          columnType === SCGridSchemaTypeEnum.MultipleUserAvatar && !canBeMultiple
            ? SCGridSchemaTypeEnum.SingleUserAvatar
            : columnType,
      });
    });

    // Only update if the columnDefs have changed
    // This is to prevent the grid from re-rendering when the columnDefs haven't changed
    // In case of multi tab grids, the columnDefs will get updated on tab change
    if (JSON.stringify(this.columnDefs) !== JSON.stringify(colDefs)) {
      this.columnDefs = colDefs;
    }
  }

  /**
   * Set rows based on record data
   * @memberof RecordGridComponent
   */
  public setRows(): void {
    if (this.gridRecordData) {
      this.rowData = this.gridRecordData.records.map((record) => this.makeRow(record));
    }
  }

  /**
   * Set rows based on record properties
   * @public
   * @param {RecordInterface} record - the record data
   * @return {RecordGridRowInterface} - record grid row
   * @memberof RecordGridComponent
   */
  public makeRow(record: RecordInterface): RecordGridRowInterface {
    const row: RecordGridRowInterface = {
      recordId: record.recordId,
      selectRow: { value: false, disabled: this.addRowsPermission ? !record.viewerCanDelete : false },
    };

    this.columnDefs?.filter(filterOutSelectAndNavigateColumnTypes).forEach((col, index) => {
      const cell = record.attributes[index];

      if (col.headerName && cell && isRecordDataSingleCellInterface(cell)) {
        row[col.headerName] = cell.singleAttribute.display;
      }

      if (col.headerName && cell && isRecordDataMultipleCellInterface(cell)) {
        row[col.headerName] = cell.multiAttributes;
      }
    });

    return row;
  }
}
