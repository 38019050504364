<ng-container *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
  <kendo-label [text]="label" class="sc-label sc-select-dropdown sc-select-dropdown--grouped">
    <kendo-dropdownlist
      scCommonE2eHook="{{ testhook }}:form:select"
      [data]="groupedData"
      [textField]="optionTextProperty"
      [valueField]="optionValueProperty"
      [itemDisabled]="itemDisabled"
      size="none"
      [style.width.px]="staticWidth"
      [popupSettings]="{
        appendTo: viewContainerRef,
        popupClass: 'sc-select-dropdown__popup sc-select-dropdown__popup--grouped'
      }"></kendo-dropdownlist>
    <kendo-formhint *ngIf="description">{{ description }}</kendo-formhint>
  </kendo-label>
</ng-container>
