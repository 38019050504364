<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="file-picker-item flex flex-column justify-content-center">
  <div class="flex justify-content-between align-items-center sc-mb-10">
    <div class="file-picker-item__content flex">
      <sc-icon key="file-text-outline" size="medium" colour="brand-500" [height]="24" [width]="24"></sc-icon>
      <span class="file-picker-item__name">{{ name }}</span>
    </div>
    <sc-icon-button
      *ngIf="hasDocumentId && showButtonUploadStatus && showButtonVirusScanStatus"
      class="sc-mr-8"
      shape="rounded"
      size="small"
      themeColour="primary"
      icon="eye"
      (click)="download.emit()">
    </sc-icon-button>

    <sc-icon-button
      *ngIf="!readonly && hasDocumentId && showButtonUploadStatus"
      shape="rounded"
      size="small"
      themeColour="primary"
      icon="close"
      (click)="remove.emit()">
    </sc-icon-button>
  </div>
  <div *ngIf="fileItemMessage; else sizeFileTemplate">
    <div class="file-picker-item__status" [ngClass]="fileItemMessageStyle">
      <span>{{ fileItemMessage }}</span>
    </div>
  </div>

  <ng-template #sizeFileTemplate>
    <div *ngIf="size" class="file-picker-item__size sc-text-grey-600">
      {{ size | filesize }}
    </div>
  </ng-template>
</div>
