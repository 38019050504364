import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CommonRoutesEnum } from '../../routes/common-routes.enum';

export interface GuestAuthInfoInterface {
  token: string;
  isValid: boolean;
  recordId: string;
}
/**
 * Service for handling URL tokens.
 * @export
 * @class GuestTokenService
 */
@Injectable({ providedIn: 'root' })
export class GuestTokenService {
  /**
   * The key used to store & retrieve the guest token in local storage.
   * @memberof GuestTokenService
   */
  private TOKEN_KEY = 'auroraGuestToken';

  /**
   * Creates an instance of GuestTokenService.
   * @param {Window} windowRef - the browser window object
   * @memberof GuestTokenService
   */
  constructor(@Inject(WINDOW) readonly windowRef: Window) {}

  /**
   * Checks if a token is present in the guestLink URL.
   * Whenever there is one, we will store it before checking it.
   * This way what is checked is always the latest token.
   * @return {boolean} Is the token present and valid.
   * @memberof GuestTokenService
   */
  public validTokenPresent(): boolean {
    const guestAuthInfo = this.retrieveGuestAuthInfo();
    if (!guestAuthInfo) return false;
    const { token, isValid } = guestAuthInfo;
    return !!token && isValid;
  }

  /**
   * Initialises the token from the current URL.
   * @return {(string | undefined)} The token from the URL if present.
   * @memberof GuestTokenService
   */
  public initToken(): string | undefined {
    if (this.isGuestLink()) {
      return this.getTokenFromCurrentUrl();
    }
    return undefined;
  }

  /**
   * Retrieves the current guest link token.
   * @return {string} The guest link token.
   * @memberof GuestTokenService
   */
  public getToken(): string {
    const authInfo = this.retrieveGuestAuthInfo();
    if (!authInfo) return '';
    return authInfo.token || '';
  }

  /**
   * Retrieves the record ID from the current guest token.
   * @return {(string | null)} The record ID from the guest token if present.
   * @memberof GuestTokenService
   */
  public getAuthorisedRecordId(): string | null {
    const authInfo = this.retrieveGuestAuthInfo();
    if (!authInfo?.recordId) return null;
    return authInfo.recordId;
  }

  /**
   * Retrieves the URL paths from the current window location.
   * @return {string[]} An array of URL paths.
   * @memberof GuestTokenService
   */
  private getUrlPaths(): string[] {
    const rawUrl = this.windowRef.location.href;
    const url = new URL(rawUrl);
    return url.pathname.split('/');
  }

  /**
   * Retrieves the index of the guest link in the URL paths.
   * @return {number} The index of the guest link, or -1 if not found.
   * @memberof GuestTokenService
   */
  private getGuestLinkIndex(): number {
    const paths = this.getUrlPaths();
    return paths.indexOf(CommonRoutesEnum.GuestLink);
  }

  /**
   * Retrieves the token from the current URL.
   * @return {string} The token from the current URL.
   * @memberof GuestTokenService
   */
  private getTokenFromCurrentUrl(): string {
    const paths = this.getUrlPaths();
    const guestLinkIndex = this.getGuestLinkIndex();
    return paths[guestLinkIndex + 1];
  }

  /**
   * Checks if the current URL is a guest link.
   * @return {boolean} True if the current URL is a guest link, false otherwise.
   * @memberof GuestTokenService
   */
  private isGuestLink(): boolean {
    return this.getGuestLinkIndex() > -1;
  }

  /**
   * Saves the current guest token.
   * @param {string} token - the string token.
   * @param {boolean} isValid - whether the token is valid.
   * @param {string} recordId - the record ID.
   * @memberof GuestTokenService
   */
  public saveToken(token: string, isValid: boolean, recordId: string): void {
    const tokenInfo = JSON.stringify({ token, isValid, recordId });
    sessionStorage.setItem(this.TOKEN_KEY, tokenInfo);
  }

  /**
   * Retrieves the token from session storage.
   * @private
   * @return {(GuestAuthInfoInterface | null)} The guest token if present.
   * @memberof GuestTokenService
   */
  private retrieveGuestAuthInfo(): GuestAuthInfoInterface | null {
    const stringInfo = sessionStorage.getItem(this.TOKEN_KEY);
    if (stringInfo) {
      return JSON.parse(stringInfo);
    }
    return null;
  }
}
