import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSize, InputsModule } from '@progress/kendo-angular-inputs';
import { E2eHookDirective } from '@surecloud/common';
/**
 * Surecloud toggle Component that wraps the kendo switch component.
 *
 * @export
 * @class ToggleComponent
 * @implements {OnInit}
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule, InputsModule, ReactiveFormsModule, E2eHookDirective],
  styleUrls: ['./toggle.component.scss'],
  templateUrl: './toggle.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ToggleComponent implements OnInit {
  /**
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof ToggleComponent
   */
  @Input() controlName = '';

  /**
   * If using text within the toggle this allows for the 'on' text to be set.
   *
   * @memberof ToggleComponent
   */
  @Input() onLabel = '';

  /**
   * If using text within the toggle this allows for the 'off' text to be set.
   *
   * @memberof ToggleComponent
   */
  @Input() offLabel = '';

  /**
   * Sets the size of the toggle.
   *
   * @type {InputSize}
   * @memberof ToggleComponent
   */
  @Input() size: InputSize = 'medium';

  /**
   * Sets the readonly state.
   *
   * @type {boolean}
   * @memberof ToggleComponent
   */
  @Input() readonly = false;

  /**
   * The test hook to pass in.
   *
   * @memberof ToggleComponent
   */
  @Input()
  testhook!: string;

  /**
   * The parent FormGroup of the input.
   * Note: This can also be the nested FormGroup if dealing with a nested FormControl - in this use case you must `@Input` the nested FormGroup
   * otherwise the FormGroupDirective finds the top level FormGroup on the form and your nested FormControl will not be found.
   *
   * @type {FormGroup}
   * @memberof ToggleComponent
   */
  @Input()
  parentFormGroup!: FormGroup;

  /**
   * Creates an instance of ToggleComponent.
   *
   * @param {FormGroupDirective} formGroupDirective Instance of the parent form the component is placed in.
   * @memberof ToggleComponent
   */
  constructor(private formGroupDirective: FormGroupDirective) {}

  /**
   * Store the parent form group so we can use it in our template.
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
  }
}
