import { Injectable } from '@angular/core';
import {
  ColumnFilterInputApiInterface,
  ExportRecordsApiInterfaceQueryService,
  RecordCreateApiInterfaceMutationService,
  RecordDeleteApiInterfaceMutationService,
  RecordResultApiInterface,
  RecordsApiInterfaceQueryService,
  RecordUpdateApiInterfaceMutationService,
} from '@surecloud/api-types';
import {
  CustomRecordResultInterface,
  LoggerService,
  makeContextWithRequestIdHeader,
  RecordGridInputInterface,
  RecordInputInterface,
  RecordInterface,
  RecordResultHelper,
} from '@surecloud/common';
import { map, Observable, of, switchMap, throwError } from 'rxjs';
import {
  getRecordApiResponseError,
  isRecordApiResponseGuard,
} from '../normalise-get-record/normalise-get-record.validations';

/**
 * Record API service.
 * @export
 * @class RecordService
 */
@Injectable({
  providedIn: 'root',
})
export class RecordService {
  /**
   * Creates an instance of RecordService.
   * @param {RecordCreateApiInterfaceMutationService} recordCreateApiInterfaceMutationService The service to create Records.
   * @param {RecordUpdateApiInterfaceMutationService} recordUpdateApiInterfaceMutationService The service to update Records.
   * @param {RecordDeleteApiInterfaceMutationService} recordDeleteApiInterfaceMutationService The service to delete Records.
   * @param {RecordsApiInterfaceQueryService} recordsApiInterfaceQueryService The service to fetch Records.
   * @param {LoggerService} logger The logger service
   * @param {RecordResultHelper} recordResultHelper This service to help with processing a RecordResult
   * @param {ExportRecordsApiInterfaceQueryService} exportRecordsApiInterfaceQueryService The service to export Records.
   * @memberof RecordService
   */
  constructor(
    private readonly recordCreateApiInterfaceMutationService: RecordCreateApiInterfaceMutationService,
    private readonly recordUpdateApiInterfaceMutationService: RecordUpdateApiInterfaceMutationService,
    private readonly recordDeleteApiInterfaceMutationService: RecordDeleteApiInterfaceMutationService,
    private readonly recordsApiInterfaceQueryService: RecordsApiInterfaceQueryService,
    private readonly logger: LoggerService,
    private readonly recordResultHelper: RecordResultHelper,
    private readonly exportRecordsApiInterfaceQueryService: ExportRecordsApiInterfaceQueryService
  ) {}

  /**
   * Get a Record by entity id
   * @param {RecordInputInterface} input The input to fetch the Record
   * @return {*}  {Observable<RecordGridInputInterface>}
   * @memberof RecordService
   */
  read(input: RecordInputInterface): Observable<CustomRecordResultInterface> {
    return this.recordsApiInterfaceQueryService
      .fetch({
        input: {
          entityId: input.entityId,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          filters: input.filters as ColumnFilterInputApiInterface[],
          quickFilter: input.quickFilter,
          sorts: input.sorts,
        },
      })
      .pipe(
        switchMap(({ data }) =>
          data?.records
            ? of(this.recordResultHelper.formatRecordResult(data.records as RecordResultApiInterface))
            : throwError(() => `No response data from get Record ${input.entityId} returned from API`)
        ),
        map((result) => {
          this.validateRecordGridInput(result.recordGridInput);
          return result;
        })
      );
  }

  /**
   * Query a record by entityId
   * @param {string} entityId The entity ID to create a Record for.
   * @param {string | null} requestId The requestId.
   * @return {Observable<RecordInterface>} The created Record Interface.
   * @memberof RecordService
   */
  create(entityId: string, requestId: string | null = null): Observable<RecordInterface> {
    return this.recordCreateApiInterfaceMutationService
      .mutate({ entityId }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.recordCreate?.record
            ? of({
                recordId: data.recordCreate.record.recordId,
                viewerCanDelete: data.recordCreate.record.viewerCanDelete,
                attributes: [],
              })
            : throwError(() => 'No created record response data from create record from API')
        )
      );
  }

  /**
   * Update a record value by recordId and attributeId
   * @param {string} recordId The Record ID to update
   * @param {string} attributeId The attribute ID to update
   * @param {string} value The new value of the Record
   * @param {string | null} requestId The requestId.
   * @return {*}  {Observable<{ recordId: string }>}
   * @memberof RecordService
   */
  update(
    recordId: string,
    attributeId: string,
    value: string,
    requestId: string | null = null
  ): Observable<{ recordId: string; attributeId: string; value: string }> {
    return this.recordUpdateApiInterfaceMutationService
      .mutate({ recordId, attributeId, value }, makeContextWithRequestIdHeader(requestId))
      .pipe(
        switchMap(({ data }) =>
          data?.recordUpdate?.record?.recordId
            ? of({ recordId: data.recordUpdate.record.recordId, attributeId, value })
            : throwError(() => 'No updated record response data from update record from API')
        )
      );
  }

  /**
   * Delete records by recordId
   * @param {string[]} recordIds the recordIds to delete
   * @return {*}  {Observable<{ deletedIds: string[] }>}
   * @memberof RecordService
   */
  delete(recordIds: string[]): Observable<{ deletedIds: string[] }> {
    return this.recordDeleteApiInterfaceMutationService
      .mutate({ recordIds })
      .pipe(
        switchMap(({ data }) =>
          data?.recordDelete?.deletedIds
            ? of({ deletedIds: data.recordDelete.deletedIds })
            : throwError(() => 'No deleted records response data from delete record from API')
        )
      );
  }

  /**
   * Export records by entityId & attributeIds.
   * @param {string} entityId the entityId.
   * @param {string[]} attributeIds the attributeIds.
   * @param {string} exportName the exportName.
   * @param {string[]} recordIds the recordIds.
   * @return {*}  {Observable<RecordGridInputInterface>}
   * @memberof RecordService
   */
  export(entityId: string, attributeIds: string[], exportName: string, recordIds: string[]): Observable<string> {
    return this.exportRecordsApiInterfaceQueryService
      .fetch({ input: { entityId, attributeIds, exportName, recordIds } })
      .pipe(
        switchMap(({ data }) =>
          data?.exportRecords
            ? of(data.exportRecords.url)
            : throwError(() => `No response data from export records for ${entityId} returned from API`)
        )
      );
  }

  /**
   * Validate the records from the API
   * @param {RecordGridInputInterface} recordGridInput the records to validate
   * @return {*}  {RecordGridInputInterface}
   * @memberof RecordService
   */
  validateRecordGridInput(recordGridInput: RecordGridInputInterface): RecordGridInputInterface {
    if (!isRecordApiResponseGuard(recordGridInput)) {
      this.logger.logEvent('Record Feature', 'Normalise Record Data', getRecordApiResponseError(recordGridInput));

      throw new Error('The result of the normalised Record does not match the expected format.');
    }
    return recordGridInput;
  }
}
