import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { groupBy } from '@progress/kendo-data-query/dist/npm/grouping/group.operators';
import { E2eHookDirective } from '@surecloud/common';
import { HierarchyComponent } from '../hierarchy/hierarchy.component';
import { DropDownGroupedInterface } from './dropdown-grouped.interface';

/**
 * Surecloud Dropdown Grouped Component.
 *
 * @export
 * @implements {OnInit}
 * @class DropdownGroupedComponent
 */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'sc-dropdown-grouped',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    E2eHookDirective,
    InputsModule,
    LabelModule,
    ReactiveFormsModule,
    HierarchyComponent,
    PopupModule,
    DropDownsModule,
  ],
  templateUrl: './dropdown-grouped.component.html',
  styleUrls: ['./dropdown-grouped.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownGroupedComponent implements OnInit {
  /**
   * The native dropdown items for the kendo-dropdownlist.
   *
   * @type {DropDownGroupedInterface[]}
   * @memberof DropdownGroupedComponent
   */
  @Input()
  data: DropDownGroupedInterface[] = [];

  /**
   * Groups the data by category
   *
   * @memberof DropdownGroupedComponent
   */
  groupedData: DropDownGroupedInterface[] = [];

  /**
   * Text to display an additional description to the user.
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() description = '';

  /**
   *
   * Sets the name of the control, this needs to match the form control name on the parent form group.
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() controlName = '';

  /**
   *  Sets if we want a success validation style to display.
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() enableSuccessValidationStyle = false;

  /**
   * Automation testhook to add to the select dropdown.
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() testhook = 'dropdown-grouped';

  /**
   * The label text for the select dropdown.
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() label = '';

  /**
   * The parent form group of the select dropdown.
   *
   * @type {FormGroup}
   * @memberof DropdownGroupedComponent
   */
  @Input()
  parentFormGroup?: FormGroup;

  /**
   * The text property to assign for the option if a DropdownGroupedComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/#toc-arrays-of-complex-data)
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() optionTextProperty = 'name';

  /**
   * The value property to assign for the option if a DropdownGroupedComponentOptionInterface is not used for options.
   * [Kendo documentation](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/data-binding/#toc-arrays-of-complex-data)
   *
   * @memberof DropdownGroupedComponent
   */
  @Input() optionValueProperty = 'name';

  /**
   * The input is 100% width by default. Use this to set it to a specific pixel value.
   *
   * @type {string}
   * @memberof DropdownGroupedComponent
   */
  @Input() staticWidth!: string;

  /**
   * Sets the placholder value for the input.
   *
   * @memberof InputTextComponent
   */
  @Input() placeholder = '';

  /**
   * Creates an instance of DropdownGroupedComponent.
   *
   * @param {FormGroupDirective} formGroupDirective The Angular [FormGroupDirective](https://angular.io/api/forms/FormGroupDirective).
   * @memberof DropdownGroupedComponent
   */
  constructor(private formGroupDirective: FormGroupDirective, public viewContainerRef: ViewContainerRef) {}

  /**
   * Runs when the component is started.
   * Sets the parent formgroup form the component.
   *
   * @memberof DropdownGroupedComponent
   */
  ngOnInit(): void {
    this.parentFormGroup = this.parentFormGroup || this.formGroupDirective.control;
    this.groupedData = groupBy(this.data, [{ field: 'category' }]) as DropDownGroupedInterface[];
  }

  /**
   * makes the items disabled
   *
   * @return {*}  {boolean}
   * @memberof DropdownGroupedComponent
   */
  // eslint-disable-next-line class-methods-use-this
  public itemDisabled(itemArgs: { dataItem: DropDownGroupedInterface; index: number }): boolean {
    return itemArgs.dataItem.itemDisabled;
  }
}
