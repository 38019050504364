import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsBootstrapProvider } from '@surecloud/vendor';
import { TaskEffects } from './+state/effects/task/task.effects';
import { reducer, TASK_FEATURE_KEY } from './+state/reducers/task/task.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(TASK_FEATURE_KEY, reducer)],
  providers: [EffectsBootstrapProvider.provide([TaskEffects])],
})
export class TaskStateModule {}
