import { InputNumberComponent } from '../../input-number/input-number.component';
import { RendererControlConfigInterface } from '../component-renderer-control-config.interface';
import { formControlComponentCreator } from '../creators/form-control-creator.config';

/**
 * Control configuration for NUMBER renderer component
 *
 */
export const NUMBER_CONTROL_CONFIG: RendererControlConfigInterface = {
  component: InputNumberComponent,
  creatorFn: formControlComponentCreator,
};
