const ATTRIBUTE_SECTIONS = {
  PROPERTIES_SECTION_NAME: $localize`Properties`,
  PERMISSIONS_SECTION_NAME: $localize`Permissions`,
  DATA_VALIDATION_SECTION_NAME: $localize`Data Validation`,
  USER_FILTER_SECTION_NAME: $localize`Filter Users`,
};

const ATTRIBUTE_SECTIONS_IDS = {
  PROPERTIES: 'attribute-properties',
  PERMISSIONS: 'attribute-permissions',
  DATA_VALIDATION: 'attribute-data-validation',
  USER_FILTER: 'attribute-user-filtering',
};

const FIXED_ATTRIBUTE_TYPES = ['STATE', 'OWNER', 'CONTEXT', 'NAME'];

export const ATTRIBUTE_FORM_FIELDS = {
  SECTIONS: ATTRIBUTE_SECTIONS,
  SECTION_IDS: ATTRIBUTE_SECTIONS_IDS,
  FIXED_ATTRIBUTES: FIXED_ATTRIBUTE_TYPES,
};
